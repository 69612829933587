/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: any;
  float8: any;
  interval: any;
  jsonb: any;
  numeric: any;
  smallint: any;
  time: any;
  timestamp: any;
  timestamptz: any;
  timetz: any;
  uuid: any;
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export interface IBooleanComparisonExp {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export interface IIntComparisonExp {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export interface IStringComparisonExp {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
}

/** columns and relationships of "achievement_types" */
export interface IAchievementTypes {
  __typename?: 'achievement_types';
  name: Scalars['String'];
}

/** aggregated selection of "achievement_types" */
export interface IAchievementTypesAggregate {
  __typename?: 'achievement_types_aggregate';
  aggregate?: Maybe<IAchievementTypesAggregateFields>;
  nodes: Array<IAchievementTypes>;
}

/** aggregate fields of "achievement_types" */
export interface IAchievementTypesAggregateFields {
  __typename?: 'achievement_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IAchievementTypesMaxFields>;
  min?: Maybe<IAchievementTypesMinFields>;
}


/** aggregate fields of "achievement_types" */
export interface IAchievementTypesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IAchievementTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "achievement_types". All fields are combined with a logical 'AND'. */
export interface IAchievementTypesBoolExp {
  _and?: InputMaybe<Array<IAchievementTypesBoolExp>>;
  _not?: InputMaybe<IAchievementTypesBoolExp>;
  _or?: InputMaybe<Array<IAchievementTypesBoolExp>>;
  name?: InputMaybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "achievement_types" */
export enum IAchievementTypesConstraint {
  /** unique or primary key constraint */
  ACHIEVEMENT_TYPES_PKEY = 'achievement_types_pkey'
}

export enum IAchievementTypesEnum {
  DICTIONARY = 'DICTIONARY',
  LESSON = 'LESSON',
  QUIZ = 'QUIZ',
  SOERAH = 'SOERAH',
  TRANSLATION = 'TRANSLATION'
}

/** Boolean expression to compare columns of type "achievement_types_enum". All fields are combined with logical 'AND'. */
export interface IAchievementTypesEnumComparisonExp {
  _eq?: InputMaybe<IAchievementTypesEnum>;
  _in?: InputMaybe<Array<IAchievementTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<IAchievementTypesEnum>;
  _nin?: InputMaybe<Array<IAchievementTypesEnum>>;
}

/** input type for inserting data into table "achievement_types" */
export interface IAchievementTypesInsertInput {
  name?: InputMaybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface IAchievementTypesMaxFields {
  __typename?: 'achievement_types_max_fields';
  name?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IAchievementTypesMinFields {
  __typename?: 'achievement_types_min_fields';
  name?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "achievement_types" */
export interface IAchievementTypesMutationResponse {
  __typename?: 'achievement_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IAchievementTypes>;
}

/** input type for inserting object relation for remote table "achievement_types" */
export interface IAchievementTypesObjRelInsertInput {
  data: IAchievementTypesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IAchievementTypesOnConflict>;
}

/** on_conflict condition type for table "achievement_types" */
export interface IAchievementTypesOnConflict {
  constraint: IAchievementTypesConstraint;
  update_columns?: Array<IAchievementTypesUpdateColumn>;
  where?: InputMaybe<IAchievementTypesBoolExp>;
}

/** Ordering options when selecting data from "achievement_types". */
export interface IAchievementTypesOrderBy {
  name?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: achievement_types */
export interface IAchievementTypesPkColumnsInput {
  name: Scalars['String'];
}

/** select columns of table "achievement_types" */
export enum IAchievementTypesSelectColumn {
  /** column name */
  NAME = 'name'
}

/** input type for updating data in table "achievement_types" */
export interface IAchievementTypesSetInput {
  name?: InputMaybe<Scalars['String']>;
}

/** update columns of table "achievement_types" */
export enum IAchievementTypesUpdateColumn {
  /** column name */
  NAME = 'name'
}

/** columns and relationships of "achievements" */
export interface IAchievements {
  __typename?: 'achievements';
  /** An array relationship */
  accomplished_achievements: Array<IAchievementsAccomplished>;
  /** An aggregate relationship */
  accomplished_achievements_aggregate: IAchievementsAccomplishedAggregate;
  /** An object relationship */
  achievementTypeByAchievementType: IAchievementTypes;
  achievement_type: IAchievementTypesEnum;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  lesson_id: Scalars['uuid'];
  sequence?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
}


/** columns and relationships of "achievements" */
export interface IAchievementsAccomplishedAchievementsArgs {
  distinct_on?: InputMaybe<Array<IAchievementsAccomplishedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAchievementsAccomplishedOrderBy>>;
  where?: InputMaybe<IAchievementsAccomplishedBoolExp>;
}


/** columns and relationships of "achievements" */
export interface IAchievementsAccomplishedAchievementsAggregateArgs {
  distinct_on?: InputMaybe<Array<IAchievementsAccomplishedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAchievementsAccomplishedOrderBy>>;
  where?: InputMaybe<IAchievementsAccomplishedBoolExp>;
}

/** columns and relationships of "achievements_accomplished" */
export interface IAchievementsAccomplished {
  __typename?: 'achievements_accomplished';
  /** An object relationship */
  achievement: IAchievements;
  achievement_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  user_child_id: Scalars['uuid'];
  /** An object relationship */
  users_child: IUsersChildren;
}

/** aggregated selection of "achievements_accomplished" */
export interface IAchievementsAccomplishedAggregate {
  __typename?: 'achievements_accomplished_aggregate';
  aggregate?: Maybe<IAchievementsAccomplishedAggregateFields>;
  nodes: Array<IAchievementsAccomplished>;
}

/** aggregate fields of "achievements_accomplished" */
export interface IAchievementsAccomplishedAggregateFields {
  __typename?: 'achievements_accomplished_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IAchievementsAccomplishedMaxFields>;
  min?: Maybe<IAchievementsAccomplishedMinFields>;
}


/** aggregate fields of "achievements_accomplished" */
export interface IAchievementsAccomplishedAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IAchievementsAccomplishedSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "achievements_accomplished" */
export interface IAchievementsAccomplishedAggregateOrderBy {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IAchievementsAccomplishedMaxOrderBy>;
  min?: InputMaybe<IAchievementsAccomplishedMinOrderBy>;
}

/** input type for inserting array relation for remote table "achievements_accomplished" */
export interface IAchievementsAccomplishedArrRelInsertInput {
  data: Array<IAchievementsAccomplishedInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IAchievementsAccomplishedOnConflict>;
}

/** Boolean expression to filter rows from the table "achievements_accomplished". All fields are combined with a logical 'AND'. */
export interface IAchievementsAccomplishedBoolExp {
  _and?: InputMaybe<Array<IAchievementsAccomplishedBoolExp>>;
  _not?: InputMaybe<IAchievementsAccomplishedBoolExp>;
  _or?: InputMaybe<Array<IAchievementsAccomplishedBoolExp>>;
  achievement?: InputMaybe<IAchievementsBoolExp>;
  achievement_id?: InputMaybe<IUuidComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  user_child_id?: InputMaybe<IUuidComparisonExp>;
  users_child?: InputMaybe<IUsersChildrenBoolExp>;
}

/** unique or primary key constraints on table "achievements_accomplished" */
export enum IAchievementsAccomplishedConstraint {
  /** unique or primary key constraint */
  ACHIEVEMENTS_ACCOMPLISHED_PKEY = 'achievements_accomplished_pkey'
}

/** input type for inserting data into table "achievements_accomplished" */
export interface IAchievementsAccomplishedInsertInput {
  achievement?: InputMaybe<IAchievementsObjRelInsertInput>;
  achievement_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  user_child_id?: InputMaybe<Scalars['uuid']>;
  users_child?: InputMaybe<IUsersChildrenObjRelInsertInput>;
}

/** aggregate max on columns */
export interface IAchievementsAccomplishedMaxFields {
  __typename?: 'achievements_accomplished_max_fields';
  achievement_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  user_child_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "achievements_accomplished" */
export interface IAchievementsAccomplishedMaxOrderBy {
  achievement_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  user_child_id?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IAchievementsAccomplishedMinFields {
  __typename?: 'achievements_accomplished_min_fields';
  achievement_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  user_child_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "achievements_accomplished" */
export interface IAchievementsAccomplishedMinOrderBy {
  achievement_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  user_child_id?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "achievements_accomplished" */
export interface IAchievementsAccomplishedMutationResponse {
  __typename?: 'achievements_accomplished_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IAchievementsAccomplished>;
}

/** on_conflict condition type for table "achievements_accomplished" */
export interface IAchievementsAccomplishedOnConflict {
  constraint: IAchievementsAccomplishedConstraint;
  update_columns?: Array<IAchievementsAccomplishedUpdateColumn>;
  where?: InputMaybe<IAchievementsAccomplishedBoolExp>;
}

/** Ordering options when selecting data from "achievements_accomplished". */
export interface IAchievementsAccomplishedOrderBy {
  achievement?: InputMaybe<IAchievementsOrderBy>;
  achievement_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  user_child_id?: InputMaybe<IOrderBy>;
  users_child?: InputMaybe<IUsersChildrenOrderBy>;
}

/** primary key columns input for table: achievements_accomplished */
export interface IAchievementsAccomplishedPkColumnsInput {
  achievement_id: Scalars['uuid'];
  user_child_id: Scalars['uuid'];
}

/** select columns of table "achievements_accomplished" */
export enum IAchievementsAccomplishedSelectColumn {
  /** column name */
  ACHIEVEMENT_ID = 'achievement_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  USER_CHILD_ID = 'user_child_id'
}

/** input type for updating data in table "achievements_accomplished" */
export interface IAchievementsAccomplishedSetInput {
  achievement_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  user_child_id?: InputMaybe<Scalars['uuid']>;
}

/** update columns of table "achievements_accomplished" */
export enum IAchievementsAccomplishedUpdateColumn {
  /** column name */
  ACHIEVEMENT_ID = 'achievement_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  USER_CHILD_ID = 'user_child_id'
}

/** aggregated selection of "achievements" */
export interface IAchievementsAggregate {
  __typename?: 'achievements_aggregate';
  aggregate?: Maybe<IAchievementsAggregateFields>;
  nodes: Array<IAchievements>;
}

/** aggregate fields of "achievements" */
export interface IAchievementsAggregateFields {
  __typename?: 'achievements_aggregate_fields';
  avg?: Maybe<IAchievementsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IAchievementsMaxFields>;
  min?: Maybe<IAchievementsMinFields>;
  stddev?: Maybe<IAchievementsStddevFields>;
  stddev_pop?: Maybe<IAchievementsStddevPopFields>;
  stddev_samp?: Maybe<IAchievementsStddevSampFields>;
  sum?: Maybe<IAchievementsSumFields>;
  var_pop?: Maybe<IAchievementsVarPopFields>;
  var_samp?: Maybe<IAchievementsVarSampFields>;
  variance?: Maybe<IAchievementsVarianceFields>;
}


/** aggregate fields of "achievements" */
export interface IAchievementsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IAchievementsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "achievements" */
export interface IAchievementsAggregateOrderBy {
  avg?: InputMaybe<IAchievementsAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IAchievementsMaxOrderBy>;
  min?: InputMaybe<IAchievementsMinOrderBy>;
  stddev?: InputMaybe<IAchievementsStddevOrderBy>;
  stddev_pop?: InputMaybe<IAchievementsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IAchievementsStddevSampOrderBy>;
  sum?: InputMaybe<IAchievementsSumOrderBy>;
  var_pop?: InputMaybe<IAchievementsVarPopOrderBy>;
  var_samp?: InputMaybe<IAchievementsVarSampOrderBy>;
  variance?: InputMaybe<IAchievementsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "achievements" */
export interface IAchievementsArrRelInsertInput {
  data: Array<IAchievementsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IAchievementsOnConflict>;
}

/** aggregate avg on columns */
export interface IAchievementsAvgFields {
  __typename?: 'achievements_avg_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "achievements" */
export interface IAchievementsAvgOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "achievements". All fields are combined with a logical 'AND'. */
export interface IAchievementsBoolExp {
  _and?: InputMaybe<Array<IAchievementsBoolExp>>;
  _not?: InputMaybe<IAchievementsBoolExp>;
  _or?: InputMaybe<Array<IAchievementsBoolExp>>;
  accomplished_achievements?: InputMaybe<IAchievementsAccomplishedBoolExp>;
  achievementTypeByAchievementType?: InputMaybe<IAchievementTypesBoolExp>;
  achievement_type?: InputMaybe<IAchievementTypesEnumComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  sequence?: InputMaybe<IIntComparisonExp>;
  title?: InputMaybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "achievements" */
export enum IAchievementsConstraint {
  /** unique or primary key constraint */
  ACHIEVEMENTS_PKEY = 'achievements_pkey'
}

/** input type for incrementing numeric columns in table "achievements" */
export interface IAchievementsIncInput {
  sequence?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "achievements" */
export interface IAchievementsInsertInput {
  accomplished_achievements?: InputMaybe<IAchievementsAccomplishedArrRelInsertInput>;
  achievementTypeByAchievementType?: InputMaybe<IAchievementTypesObjRelInsertInput>;
  achievement_type?: InputMaybe<IAchievementTypesEnum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson?: InputMaybe<ILessonsObjRelInsertInput>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  sequence?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface IAchievementsMaxFields {
  __typename?: 'achievements_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "achievements" */
export interface IAchievementsMaxOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  title?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IAchievementsMinFields {
  __typename?: 'achievements_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "achievements" */
export interface IAchievementsMinOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  title?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "achievements" */
export interface IAchievementsMutationResponse {
  __typename?: 'achievements_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IAchievements>;
}

/** input type for inserting object relation for remote table "achievements" */
export interface IAchievementsObjRelInsertInput {
  data: IAchievementsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IAchievementsOnConflict>;
}

/** on_conflict condition type for table "achievements" */
export interface IAchievementsOnConflict {
  constraint: IAchievementsConstraint;
  update_columns?: Array<IAchievementsUpdateColumn>;
  where?: InputMaybe<IAchievementsBoolExp>;
}

/** Ordering options when selecting data from "achievements". */
export interface IAchievementsOrderBy {
  accomplished_achievements_aggregate?: InputMaybe<IAchievementsAccomplishedAggregateOrderBy>;
  achievementTypeByAchievementType?: InputMaybe<IAchievementTypesOrderBy>;
  achievement_type?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  title?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: achievements */
export interface IAchievementsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "achievements" */
export enum IAchievementsSelectColumn {
  /** column name */
  ACHIEVEMENT_TYPE = 'achievement_type',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  TITLE = 'title'
}

/** input type for updating data in table "achievements" */
export interface IAchievementsSetInput {
  achievement_type?: InputMaybe<IAchievementTypesEnum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  sequence?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface IAchievementsStddevFields {
  __typename?: 'achievements_stddev_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "achievements" */
export interface IAchievementsStddevOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IAchievementsStddevPopFields {
  __typename?: 'achievements_stddev_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "achievements" */
export interface IAchievementsStddevPopOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IAchievementsStddevSampFields {
  __typename?: 'achievements_stddev_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "achievements" */
export interface IAchievementsStddevSampOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IAchievementsSumFields {
  __typename?: 'achievements_sum_fields';
  sequence?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "achievements" */
export interface IAchievementsSumOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** update columns of table "achievements" */
export enum IAchievementsUpdateColumn {
  /** column name */
  ACHIEVEMENT_TYPE = 'achievement_type',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  TITLE = 'title'
}

/** aggregate var_pop on columns */
export interface IAchievementsVarPopFields {
  __typename?: 'achievements_var_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "achievements" */
export interface IAchievementsVarPopOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IAchievementsVarSampFields {
  __typename?: 'achievements_var_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "achievements" */
export interface IAchievementsVarSampOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IAchievementsVarianceFields {
  __typename?: 'achievements_variance_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "achievements" */
export interface IAchievementsVarianceOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** columns and relationships of "achievements_view" */
export interface IAchievementsView {
  __typename?: 'achievements_view';
  course_id?: Maybe<Scalars['uuid']>;
  dictionary_achieved?: Maybe<Scalars['Boolean']>;
  goals_achieved?: Maybe<Scalars['Boolean']>;
  has_achievements?: Maybe<Scalars['Boolean']>;
  lesson_completed?: Maybe<Scalars['Boolean']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  quiz_achieved?: Maybe<Scalars['Boolean']>;
  sequence?: Maybe<Scalars['Int']>;
  soerah_achieved?: Maybe<Scalars['Boolean']>;
  translation_achieved?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  user_child?: Maybe<IUsersChildren>;
  user_child_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "achievements_view" */
export interface IAchievementsViewAggregate {
  __typename?: 'achievements_view_aggregate';
  aggregate?: Maybe<IAchievementsViewAggregateFields>;
  nodes: Array<IAchievementsView>;
}

/** aggregate fields of "achievements_view" */
export interface IAchievementsViewAggregateFields {
  __typename?: 'achievements_view_aggregate_fields';
  avg?: Maybe<IAchievementsViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IAchievementsViewMaxFields>;
  min?: Maybe<IAchievementsViewMinFields>;
  stddev?: Maybe<IAchievementsViewStddevFields>;
  stddev_pop?: Maybe<IAchievementsViewStddevPopFields>;
  stddev_samp?: Maybe<IAchievementsViewStddevSampFields>;
  sum?: Maybe<IAchievementsViewSumFields>;
  var_pop?: Maybe<IAchievementsViewVarPopFields>;
  var_samp?: Maybe<IAchievementsViewVarSampFields>;
  variance?: Maybe<IAchievementsViewVarianceFields>;
}


/** aggregate fields of "achievements_view" */
export interface IAchievementsViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IAchievementsViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IAchievementsViewAvgFields {
  __typename?: 'achievements_view_avg_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "achievements_view". All fields are combined with a logical 'AND'. */
export interface IAchievementsViewBoolExp {
  _and?: InputMaybe<Array<IAchievementsViewBoolExp>>;
  _not?: InputMaybe<IAchievementsViewBoolExp>;
  _or?: InputMaybe<Array<IAchievementsViewBoolExp>>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  dictionary_achieved?: InputMaybe<IBooleanComparisonExp>;
  goals_achieved?: InputMaybe<IBooleanComparisonExp>;
  has_achievements?: InputMaybe<IBooleanComparisonExp>;
  lesson_completed?: InputMaybe<IBooleanComparisonExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  quiz_achieved?: InputMaybe<IBooleanComparisonExp>;
  sequence?: InputMaybe<IIntComparisonExp>;
  soerah_achieved?: InputMaybe<IBooleanComparisonExp>;
  translation_achieved?: InputMaybe<IBooleanComparisonExp>;
  user_child?: InputMaybe<IUsersChildrenBoolExp>;
  user_child_id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface IAchievementsViewMaxFields {
  __typename?: 'achievements_view_max_fields';
  course_id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  user_child_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IAchievementsViewMinFields {
  __typename?: 'achievements_view_min_fields';
  course_id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  user_child_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "achievements_view". */
export interface IAchievementsViewOrderBy {
  course_id?: InputMaybe<IOrderBy>;
  dictionary_achieved?: InputMaybe<IOrderBy>;
  goals_achieved?: InputMaybe<IOrderBy>;
  has_achievements?: InputMaybe<IOrderBy>;
  lesson_completed?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  quiz_achieved?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  soerah_achieved?: InputMaybe<IOrderBy>;
  translation_achieved?: InputMaybe<IOrderBy>;
  user_child?: InputMaybe<IUsersChildrenOrderBy>;
  user_child_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "achievements_view" */
export enum IAchievementsViewSelectColumn {
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  DICTIONARY_ACHIEVED = 'dictionary_achieved',
  /** column name */
  GOALS_ACHIEVED = 'goals_achieved',
  /** column name */
  HAS_ACHIEVEMENTS = 'has_achievements',
  /** column name */
  LESSON_COMPLETED = 'lesson_completed',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  NAME = 'name',
  /** column name */
  QUIZ_ACHIEVED = 'quiz_achieved',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  SOERAH_ACHIEVED = 'soerah_achieved',
  /** column name */
  TRANSLATION_ACHIEVED = 'translation_achieved',
  /** column name */
  USER_CHILD_ID = 'user_child_id'
}

/** aggregate stddev on columns */
export interface IAchievementsViewStddevFields {
  __typename?: 'achievements_view_stddev_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IAchievementsViewStddevPopFields {
  __typename?: 'achievements_view_stddev_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IAchievementsViewStddevSampFields {
  __typename?: 'achievements_view_stddev_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IAchievementsViewSumFields {
  __typename?: 'achievements_view_sum_fields';
  sequence?: Maybe<Scalars['Int']>;
}

/** aggregate var_pop on columns */
export interface IAchievementsViewVarPopFields {
  __typename?: 'achievements_view_var_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IAchievementsViewVarSampFields {
  __typename?: 'achievements_view_var_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IAchievementsViewVarianceFields {
  __typename?: 'achievements_view_variance_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "active_storage_attachments" */
export interface IActiveStorageAttachments {
  __typename?: 'active_storage_attachments';
  blob_id: Scalars['bigint'];
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  record_id: Scalars['uuid'];
  record_type: Scalars['String'];
}

/** aggregated selection of "active_storage_attachments" */
export interface IActiveStorageAttachmentsAggregate {
  __typename?: 'active_storage_attachments_aggregate';
  aggregate?: Maybe<IActiveStorageAttachmentsAggregateFields>;
  nodes: Array<IActiveStorageAttachments>;
}

/** aggregate fields of "active_storage_attachments" */
export interface IActiveStorageAttachmentsAggregateFields {
  __typename?: 'active_storage_attachments_aggregate_fields';
  avg?: Maybe<IActiveStorageAttachmentsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IActiveStorageAttachmentsMaxFields>;
  min?: Maybe<IActiveStorageAttachmentsMinFields>;
  stddev?: Maybe<IActiveStorageAttachmentsStddevFields>;
  stddev_pop?: Maybe<IActiveStorageAttachmentsStddevPopFields>;
  stddev_samp?: Maybe<IActiveStorageAttachmentsStddevSampFields>;
  sum?: Maybe<IActiveStorageAttachmentsSumFields>;
  var_pop?: Maybe<IActiveStorageAttachmentsVarPopFields>;
  var_samp?: Maybe<IActiveStorageAttachmentsVarSampFields>;
  variance?: Maybe<IActiveStorageAttachmentsVarianceFields>;
}


/** aggregate fields of "active_storage_attachments" */
export interface IActiveStorageAttachmentsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IActiveStorageAttachmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IActiveStorageAttachmentsAvgFields {
  __typename?: 'active_storage_attachments_avg_fields';
  blob_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "active_storage_attachments". All fields are combined with a logical 'AND'. */
export interface IActiveStorageAttachmentsBoolExp {
  _and?: InputMaybe<Array<IActiveStorageAttachmentsBoolExp>>;
  _not?: InputMaybe<IActiveStorageAttachmentsBoolExp>;
  _or?: InputMaybe<Array<IActiveStorageAttachmentsBoolExp>>;
  blob_id?: InputMaybe<IBigintComparisonExp>;
  created_at?: InputMaybe<ITimestampComparisonExp>;
  id?: InputMaybe<IBigintComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  record_id?: InputMaybe<IUuidComparisonExp>;
  record_type?: InputMaybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "active_storage_attachments" */
export enum IActiveStorageAttachmentsConstraint {
  /** unique or primary key constraint */
  ACTIVE_STORAGE_ATTACHMENTS_PKEY = 'active_storage_attachments_pkey',
  /** unique or primary key constraint */
  INDEX_ACTIVE_STORAGE_ATTACHMENTS_UNIQUENESS = 'index_active_storage_attachments_uniqueness'
}

/** input type for incrementing numeric columns in table "active_storage_attachments" */
export interface IActiveStorageAttachmentsIncInput {
  blob_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
}

/** input type for inserting data into table "active_storage_attachments" */
export interface IActiveStorageAttachmentsInsertInput {
  blob_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  record_id?: InputMaybe<Scalars['uuid']>;
  record_type?: InputMaybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface IActiveStorageAttachmentsMaxFields {
  __typename?: 'active_storage_attachments_max_fields';
  blob_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  record_id?: Maybe<Scalars['uuid']>;
  record_type?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IActiveStorageAttachmentsMinFields {
  __typename?: 'active_storage_attachments_min_fields';
  blob_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  record_id?: Maybe<Scalars['uuid']>;
  record_type?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "active_storage_attachments" */
export interface IActiveStorageAttachmentsMutationResponse {
  __typename?: 'active_storage_attachments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IActiveStorageAttachments>;
}

/** on_conflict condition type for table "active_storage_attachments" */
export interface IActiveStorageAttachmentsOnConflict {
  constraint: IActiveStorageAttachmentsConstraint;
  update_columns?: Array<IActiveStorageAttachmentsUpdateColumn>;
  where?: InputMaybe<IActiveStorageAttachmentsBoolExp>;
}

/** Ordering options when selecting data from "active_storage_attachments". */
export interface IActiveStorageAttachmentsOrderBy {
  blob_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  record_id?: InputMaybe<IOrderBy>;
  record_type?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: active_storage_attachments */
export interface IActiveStorageAttachmentsPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "active_storage_attachments" */
export enum IActiveStorageAttachmentsSelectColumn {
  /** column name */
  BLOB_ID = 'blob_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  RECORD_ID = 'record_id',
  /** column name */
  RECORD_TYPE = 'record_type'
}

/** input type for updating data in table "active_storage_attachments" */
export interface IActiveStorageAttachmentsSetInput {
  blob_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  record_id?: InputMaybe<Scalars['uuid']>;
  record_type?: InputMaybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface IActiveStorageAttachmentsStddevFields {
  __typename?: 'active_storage_attachments_stddev_fields';
  blob_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IActiveStorageAttachmentsStddevPopFields {
  __typename?: 'active_storage_attachments_stddev_pop_fields';
  blob_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IActiveStorageAttachmentsStddevSampFields {
  __typename?: 'active_storage_attachments_stddev_samp_fields';
  blob_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IActiveStorageAttachmentsSumFields {
  __typename?: 'active_storage_attachments_sum_fields';
  blob_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
}

/** update columns of table "active_storage_attachments" */
export enum IActiveStorageAttachmentsUpdateColumn {
  /** column name */
  BLOB_ID = 'blob_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  RECORD_ID = 'record_id',
  /** column name */
  RECORD_TYPE = 'record_type'
}

/** aggregate var_pop on columns */
export interface IActiveStorageAttachmentsVarPopFields {
  __typename?: 'active_storage_attachments_var_pop_fields';
  blob_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IActiveStorageAttachmentsVarSampFields {
  __typename?: 'active_storage_attachments_var_samp_fields';
  blob_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IActiveStorageAttachmentsVarianceFields {
  __typename?: 'active_storage_attachments_variance_fields';
  blob_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "active_storage_blobs" */
export interface IActiveStorageBlobs {
  __typename?: 'active_storage_blobs';
  byte_size: Scalars['bigint'];
  checksum: Scalars['String'];
  content_type?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamp'];
  filename: Scalars['String'];
  id: Scalars['bigint'];
  key: Scalars['String'];
  metadata?: Maybe<Scalars['String']>;
  service_name: Scalars['String'];
}

/** aggregated selection of "active_storage_blobs" */
export interface IActiveStorageBlobsAggregate {
  __typename?: 'active_storage_blobs_aggregate';
  aggregate?: Maybe<IActiveStorageBlobsAggregateFields>;
  nodes: Array<IActiveStorageBlobs>;
}

/** aggregate fields of "active_storage_blobs" */
export interface IActiveStorageBlobsAggregateFields {
  __typename?: 'active_storage_blobs_aggregate_fields';
  avg?: Maybe<IActiveStorageBlobsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IActiveStorageBlobsMaxFields>;
  min?: Maybe<IActiveStorageBlobsMinFields>;
  stddev?: Maybe<IActiveStorageBlobsStddevFields>;
  stddev_pop?: Maybe<IActiveStorageBlobsStddevPopFields>;
  stddev_samp?: Maybe<IActiveStorageBlobsStddevSampFields>;
  sum?: Maybe<IActiveStorageBlobsSumFields>;
  var_pop?: Maybe<IActiveStorageBlobsVarPopFields>;
  var_samp?: Maybe<IActiveStorageBlobsVarSampFields>;
  variance?: Maybe<IActiveStorageBlobsVarianceFields>;
}


/** aggregate fields of "active_storage_blobs" */
export interface IActiveStorageBlobsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IActiveStorageBlobsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IActiveStorageBlobsAvgFields {
  __typename?: 'active_storage_blobs_avg_fields';
  byte_size?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "active_storage_blobs". All fields are combined with a logical 'AND'. */
export interface IActiveStorageBlobsBoolExp {
  _and?: InputMaybe<Array<IActiveStorageBlobsBoolExp>>;
  _not?: InputMaybe<IActiveStorageBlobsBoolExp>;
  _or?: InputMaybe<Array<IActiveStorageBlobsBoolExp>>;
  byte_size?: InputMaybe<IBigintComparisonExp>;
  checksum?: InputMaybe<IStringComparisonExp>;
  content_type?: InputMaybe<IStringComparisonExp>;
  created_at?: InputMaybe<ITimestampComparisonExp>;
  filename?: InputMaybe<IStringComparisonExp>;
  id?: InputMaybe<IBigintComparisonExp>;
  key?: InputMaybe<IStringComparisonExp>;
  metadata?: InputMaybe<IStringComparisonExp>;
  service_name?: InputMaybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "active_storage_blobs" */
export enum IActiveStorageBlobsConstraint {
  /** unique or primary key constraint */
  ACTIVE_STORAGE_BLOBS_PKEY = 'active_storage_blobs_pkey',
  /** unique or primary key constraint */
  INDEX_ACTIVE_STORAGE_BLOBS_ON_KEY = 'index_active_storage_blobs_on_key'
}

/** input type for incrementing numeric columns in table "active_storage_blobs" */
export interface IActiveStorageBlobsIncInput {
  byte_size?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
}

/** input type for inserting data into table "active_storage_blobs" */
export interface IActiveStorageBlobsInsertInput {
  byte_size?: InputMaybe<Scalars['bigint']>;
  checksum?: InputMaybe<Scalars['String']>;
  content_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  key?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
  service_name?: InputMaybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface IActiveStorageBlobsMaxFields {
  __typename?: 'active_storage_blobs_max_fields';
  byte_size?: Maybe<Scalars['bigint']>;
  checksum?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  key?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  service_name?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IActiveStorageBlobsMinFields {
  __typename?: 'active_storage_blobs_min_fields';
  byte_size?: Maybe<Scalars['bigint']>;
  checksum?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  key?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  service_name?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "active_storage_blobs" */
export interface IActiveStorageBlobsMutationResponse {
  __typename?: 'active_storage_blobs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IActiveStorageBlobs>;
}

/** on_conflict condition type for table "active_storage_blobs" */
export interface IActiveStorageBlobsOnConflict {
  constraint: IActiveStorageBlobsConstraint;
  update_columns?: Array<IActiveStorageBlobsUpdateColumn>;
  where?: InputMaybe<IActiveStorageBlobsBoolExp>;
}

/** Ordering options when selecting data from "active_storage_blobs". */
export interface IActiveStorageBlobsOrderBy {
  byte_size?: InputMaybe<IOrderBy>;
  checksum?: InputMaybe<IOrderBy>;
  content_type?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  filename?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  key?: InputMaybe<IOrderBy>;
  metadata?: InputMaybe<IOrderBy>;
  service_name?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: active_storage_blobs */
export interface IActiveStorageBlobsPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "active_storage_blobs" */
export enum IActiveStorageBlobsSelectColumn {
  /** column name */
  BYTE_SIZE = 'byte_size',
  /** column name */
  CHECKSUM = 'checksum',
  /** column name */
  CONTENT_TYPE = 'content_type',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  FILENAME = 'filename',
  /** column name */
  ID = 'id',
  /** column name */
  KEY = 'key',
  /** column name */
  METADATA = 'metadata',
  /** column name */
  SERVICE_NAME = 'service_name'
}

/** input type for updating data in table "active_storage_blobs" */
export interface IActiveStorageBlobsSetInput {
  byte_size?: InputMaybe<Scalars['bigint']>;
  checksum?: InputMaybe<Scalars['String']>;
  content_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  key?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
  service_name?: InputMaybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface IActiveStorageBlobsStddevFields {
  __typename?: 'active_storage_blobs_stddev_fields';
  byte_size?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IActiveStorageBlobsStddevPopFields {
  __typename?: 'active_storage_blobs_stddev_pop_fields';
  byte_size?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IActiveStorageBlobsStddevSampFields {
  __typename?: 'active_storage_blobs_stddev_samp_fields';
  byte_size?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IActiveStorageBlobsSumFields {
  __typename?: 'active_storage_blobs_sum_fields';
  byte_size?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
}

/** update columns of table "active_storage_blobs" */
export enum IActiveStorageBlobsUpdateColumn {
  /** column name */
  BYTE_SIZE = 'byte_size',
  /** column name */
  CHECKSUM = 'checksum',
  /** column name */
  CONTENT_TYPE = 'content_type',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  FILENAME = 'filename',
  /** column name */
  ID = 'id',
  /** column name */
  KEY = 'key',
  /** column name */
  METADATA = 'metadata',
  /** column name */
  SERVICE_NAME = 'service_name'
}

/** aggregate var_pop on columns */
export interface IActiveStorageBlobsVarPopFields {
  __typename?: 'active_storage_blobs_var_pop_fields';
  byte_size?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IActiveStorageBlobsVarSampFields {
  __typename?: 'active_storage_blobs_var_samp_fields';
  byte_size?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IActiveStorageBlobsVarianceFields {
  __typename?: 'active_storage_blobs_variance_fields';
  byte_size?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "additional_lessons" */
export interface IAdditionalLessons {
  __typename?: 'additional_lessons';
  /** An array relationship */
  additional_lessons_group_types: Array<IAdditionalLessonsGroupTypes>;
  /** An aggregate relationship */
  additional_lessons_group_types_aggregate: IAdditionalLessonsGroupTypesAggregate;
  available_from_lesson: Scalars['Int'];
  content: Scalars['String'];
  /** An object relationship */
  course?: Maybe<ICourses>;
  course_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  published: Scalars['Boolean'];
  subtitle?: Maybe<Scalars['String']>;
}


/** columns and relationships of "additional_lessons" */
export interface IAdditionalLessonsAdditionalLessonsGroupTypesArgs {
  distinct_on?: InputMaybe<Array<IAdditionalLessonsGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAdditionalLessonsGroupTypesOrderBy>>;
  where?: InputMaybe<IAdditionalLessonsGroupTypesBoolExp>;
}


/** columns and relationships of "additional_lessons" */
export interface IAdditionalLessonsAdditionalLessonsGroupTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<IAdditionalLessonsGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAdditionalLessonsGroupTypesOrderBy>>;
  where?: InputMaybe<IAdditionalLessonsGroupTypesBoolExp>;
}

/** aggregated selection of "additional_lessons" */
export interface IAdditionalLessonsAggregate {
  __typename?: 'additional_lessons_aggregate';
  aggregate?: Maybe<IAdditionalLessonsAggregateFields>;
  nodes: Array<IAdditionalLessons>;
}

/** aggregate fields of "additional_lessons" */
export interface IAdditionalLessonsAggregateFields {
  __typename?: 'additional_lessons_aggregate_fields';
  avg?: Maybe<IAdditionalLessonsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IAdditionalLessonsMaxFields>;
  min?: Maybe<IAdditionalLessonsMinFields>;
  stddev?: Maybe<IAdditionalLessonsStddevFields>;
  stddev_pop?: Maybe<IAdditionalLessonsStddevPopFields>;
  stddev_samp?: Maybe<IAdditionalLessonsStddevSampFields>;
  sum?: Maybe<IAdditionalLessonsSumFields>;
  var_pop?: Maybe<IAdditionalLessonsVarPopFields>;
  var_samp?: Maybe<IAdditionalLessonsVarSampFields>;
  variance?: Maybe<IAdditionalLessonsVarianceFields>;
}


/** aggregate fields of "additional_lessons" */
export interface IAdditionalLessonsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IAdditionalLessonsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IAdditionalLessonsAvgFields {
  __typename?: 'additional_lessons_avg_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "additional_lessons". All fields are combined with a logical 'AND'. */
export interface IAdditionalLessonsBoolExp {
  _and?: InputMaybe<Array<IAdditionalLessonsBoolExp>>;
  _not?: InputMaybe<IAdditionalLessonsBoolExp>;
  _or?: InputMaybe<Array<IAdditionalLessonsBoolExp>>;
  additional_lessons_group_types?: InputMaybe<IAdditionalLessonsGroupTypesBoolExp>;
  available_from_lesson?: InputMaybe<IIntComparisonExp>;
  content?: InputMaybe<IStringComparisonExp>;
  course?: InputMaybe<ICoursesBoolExp>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  published?: InputMaybe<IBooleanComparisonExp>;
  subtitle?: InputMaybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "additional_lessons" */
export enum IAdditionalLessonsConstraint {
  /** unique or primary key constraint */
  ADDITIONAL_LESSONS_PKEY = 'additional_lessons_pkey'
}

/** columns and relationships of "additional_lessons_group_types" */
export interface IAdditionalLessonsGroupTypes {
  __typename?: 'additional_lessons_group_types';
  /** An object relationship */
  additional_lesson: IAdditionalLessons;
  additional_lesson_id: Scalars['uuid'];
  /** An object relationship */
  group_type: IGroupTypes;
  group_type_id: Scalars['uuid'];
  id: Scalars['uuid'];
}

/** aggregated selection of "additional_lessons_group_types" */
export interface IAdditionalLessonsGroupTypesAggregate {
  __typename?: 'additional_lessons_group_types_aggregate';
  aggregate?: Maybe<IAdditionalLessonsGroupTypesAggregateFields>;
  nodes: Array<IAdditionalLessonsGroupTypes>;
}

/** aggregate fields of "additional_lessons_group_types" */
export interface IAdditionalLessonsGroupTypesAggregateFields {
  __typename?: 'additional_lessons_group_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IAdditionalLessonsGroupTypesMaxFields>;
  min?: Maybe<IAdditionalLessonsGroupTypesMinFields>;
}


/** aggregate fields of "additional_lessons_group_types" */
export interface IAdditionalLessonsGroupTypesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IAdditionalLessonsGroupTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "additional_lessons_group_types" */
export interface IAdditionalLessonsGroupTypesAggregateOrderBy {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IAdditionalLessonsGroupTypesMaxOrderBy>;
  min?: InputMaybe<IAdditionalLessonsGroupTypesMinOrderBy>;
}

/** input type for inserting array relation for remote table "additional_lessons_group_types" */
export interface IAdditionalLessonsGroupTypesArrRelInsertInput {
  data: Array<IAdditionalLessonsGroupTypesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IAdditionalLessonsGroupTypesOnConflict>;
}

/** Boolean expression to filter rows from the table "additional_lessons_group_types". All fields are combined with a logical 'AND'. */
export interface IAdditionalLessonsGroupTypesBoolExp {
  _and?: InputMaybe<Array<IAdditionalLessonsGroupTypesBoolExp>>;
  _not?: InputMaybe<IAdditionalLessonsGroupTypesBoolExp>;
  _or?: InputMaybe<Array<IAdditionalLessonsGroupTypesBoolExp>>;
  additional_lesson?: InputMaybe<IAdditionalLessonsBoolExp>;
  additional_lesson_id?: InputMaybe<IUuidComparisonExp>;
  group_type?: InputMaybe<IGroupTypesBoolExp>;
  group_type_id?: InputMaybe<IUuidComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
}

/** unique or primary key constraints on table "additional_lessons_group_types" */
export enum IAdditionalLessonsGroupTypesConstraint {
  /** unique or primary key constraint */
  ADDITIONAL_LESSONS_GROUP_TYPE_GROUP_TYPE_ID_ADDITIONAL_LESS_KEY = 'additional_lessons_group_type_group_type_id_additional_less_key',
  /** unique or primary key constraint */
  ADDITIONAL_LESSONS_GROUP_TYPES_PKEY = 'additional_lessons_group_types_pkey'
}

/** input type for inserting data into table "additional_lessons_group_types" */
export interface IAdditionalLessonsGroupTypesInsertInput {
  additional_lesson?: InputMaybe<IAdditionalLessonsObjRelInsertInput>;
  additional_lesson_id?: InputMaybe<Scalars['uuid']>;
  group_type?: InputMaybe<IGroupTypesObjRelInsertInput>;
  group_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface IAdditionalLessonsGroupTypesMaxFields {
  __typename?: 'additional_lessons_group_types_max_fields';
  additional_lesson_id?: Maybe<Scalars['uuid']>;
  group_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "additional_lessons_group_types" */
export interface IAdditionalLessonsGroupTypesMaxOrderBy {
  additional_lesson_id?: InputMaybe<IOrderBy>;
  group_type_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IAdditionalLessonsGroupTypesMinFields {
  __typename?: 'additional_lessons_group_types_min_fields';
  additional_lesson_id?: Maybe<Scalars['uuid']>;
  group_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "additional_lessons_group_types" */
export interface IAdditionalLessonsGroupTypesMinOrderBy {
  additional_lesson_id?: InputMaybe<IOrderBy>;
  group_type_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "additional_lessons_group_types" */
export interface IAdditionalLessonsGroupTypesMutationResponse {
  __typename?: 'additional_lessons_group_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IAdditionalLessonsGroupTypes>;
}

/** on_conflict condition type for table "additional_lessons_group_types" */
export interface IAdditionalLessonsGroupTypesOnConflict {
  constraint: IAdditionalLessonsGroupTypesConstraint;
  update_columns?: Array<IAdditionalLessonsGroupTypesUpdateColumn>;
  where?: InputMaybe<IAdditionalLessonsGroupTypesBoolExp>;
}

/** Ordering options when selecting data from "additional_lessons_group_types". */
export interface IAdditionalLessonsGroupTypesOrderBy {
  additional_lesson?: InputMaybe<IAdditionalLessonsOrderBy>;
  additional_lesson_id?: InputMaybe<IOrderBy>;
  group_type?: InputMaybe<IGroupTypesOrderBy>;
  group_type_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: additional_lessons_group_types */
export interface IAdditionalLessonsGroupTypesPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "additional_lessons_group_types" */
export enum IAdditionalLessonsGroupTypesSelectColumn {
  /** column name */
  ADDITIONAL_LESSON_ID = 'additional_lesson_id',
  /** column name */
  GROUP_TYPE_ID = 'group_type_id',
  /** column name */
  ID = 'id'
}

/** input type for updating data in table "additional_lessons_group_types" */
export interface IAdditionalLessonsGroupTypesSetInput {
  additional_lesson_id?: InputMaybe<Scalars['uuid']>;
  group_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
}

/** update columns of table "additional_lessons_group_types" */
export enum IAdditionalLessonsGroupTypesUpdateColumn {
  /** column name */
  ADDITIONAL_LESSON_ID = 'additional_lesson_id',
  /** column name */
  GROUP_TYPE_ID = 'group_type_id',
  /** column name */
  ID = 'id'
}

/** input type for incrementing numeric columns in table "additional_lessons" */
export interface IAdditionalLessonsIncInput {
  available_from_lesson?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "additional_lessons" */
export interface IAdditionalLessonsInsertInput {
  additional_lessons_group_types?: InputMaybe<IAdditionalLessonsGroupTypesArrRelInsertInput>;
  available_from_lesson?: InputMaybe<Scalars['Int']>;
  content?: InputMaybe<Scalars['String']>;
  course?: InputMaybe<ICoursesObjRelInsertInput>;
  course_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  subtitle?: InputMaybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface IAdditionalLessonsMaxFields {
  __typename?: 'additional_lessons_max_fields';
  available_from_lesson?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  course_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IAdditionalLessonsMinFields {
  __typename?: 'additional_lessons_min_fields';
  available_from_lesson?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  course_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "additional_lessons" */
export interface IAdditionalLessonsMutationResponse {
  __typename?: 'additional_lessons_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IAdditionalLessons>;
}

/** input type for inserting object relation for remote table "additional_lessons" */
export interface IAdditionalLessonsObjRelInsertInput {
  data: IAdditionalLessonsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IAdditionalLessonsOnConflict>;
}

/** on_conflict condition type for table "additional_lessons" */
export interface IAdditionalLessonsOnConflict {
  constraint: IAdditionalLessonsConstraint;
  update_columns?: Array<IAdditionalLessonsUpdateColumn>;
  where?: InputMaybe<IAdditionalLessonsBoolExp>;
}

/** Ordering options when selecting data from "additional_lessons". */
export interface IAdditionalLessonsOrderBy {
  additional_lessons_group_types_aggregate?: InputMaybe<IAdditionalLessonsGroupTypesAggregateOrderBy>;
  available_from_lesson?: InputMaybe<IOrderBy>;
  content?: InputMaybe<IOrderBy>;
  course?: InputMaybe<ICoursesOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  published?: InputMaybe<IOrderBy>;
  subtitle?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: additional_lessons */
export interface IAdditionalLessonsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "additional_lessons" */
export enum IAdditionalLessonsSelectColumn {
  /** column name */
  AVAILABLE_FROM_LESSON = 'available_from_lesson',
  /** column name */
  CONTENT = 'content',
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  PUBLISHED = 'published',
  /** column name */
  SUBTITLE = 'subtitle'
}

/** input type for updating data in table "additional_lessons" */
export interface IAdditionalLessonsSetInput {
  available_from_lesson?: InputMaybe<Scalars['Int']>;
  content?: InputMaybe<Scalars['String']>;
  course_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  subtitle?: InputMaybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface IAdditionalLessonsStddevFields {
  __typename?: 'additional_lessons_stddev_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IAdditionalLessonsStddevPopFields {
  __typename?: 'additional_lessons_stddev_pop_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IAdditionalLessonsStddevSampFields {
  __typename?: 'additional_lessons_stddev_samp_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IAdditionalLessonsSumFields {
  __typename?: 'additional_lessons_sum_fields';
  available_from_lesson?: Maybe<Scalars['Int']>;
}

/** update columns of table "additional_lessons" */
export enum IAdditionalLessonsUpdateColumn {
  /** column name */
  AVAILABLE_FROM_LESSON = 'available_from_lesson',
  /** column name */
  CONTENT = 'content',
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  PUBLISHED = 'published',
  /** column name */
  SUBTITLE = 'subtitle'
}

/** aggregate var_pop on columns */
export interface IAdditionalLessonsVarPopFields {
  __typename?: 'additional_lessons_var_pop_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IAdditionalLessonsVarSampFields {
  __typename?: 'additional_lessons_var_samp_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IAdditionalLessonsVarianceFields {
  __typename?: 'additional_lessons_variance_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "additional_lessons_view" */
export interface IAdditionalLessonsView {
  __typename?: 'additional_lessons_view';
  /** An object relationship */
  additional_lesson?: Maybe<IAdditionalLessons>;
  additional_lesson_id?: Maybe<Scalars['uuid']>;
  available_from_lesson?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  /** An object relationship */
  course?: Maybe<ICourses>;
  course_id?: Maybe<Scalars['uuid']>;
  is_available?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  subscription?: Maybe<ISubscriptions>;
  subscription_id?: Maybe<Scalars['uuid']>;
  subtitle?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "additional_lessons_view" */
export interface IAdditionalLessonsViewAggregate {
  __typename?: 'additional_lessons_view_aggregate';
  aggregate?: Maybe<IAdditionalLessonsViewAggregateFields>;
  nodes: Array<IAdditionalLessonsView>;
}

/** aggregate fields of "additional_lessons_view" */
export interface IAdditionalLessonsViewAggregateFields {
  __typename?: 'additional_lessons_view_aggregate_fields';
  avg?: Maybe<IAdditionalLessonsViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IAdditionalLessonsViewMaxFields>;
  min?: Maybe<IAdditionalLessonsViewMinFields>;
  stddev?: Maybe<IAdditionalLessonsViewStddevFields>;
  stddev_pop?: Maybe<IAdditionalLessonsViewStddevPopFields>;
  stddev_samp?: Maybe<IAdditionalLessonsViewStddevSampFields>;
  sum?: Maybe<IAdditionalLessonsViewSumFields>;
  var_pop?: Maybe<IAdditionalLessonsViewVarPopFields>;
  var_samp?: Maybe<IAdditionalLessonsViewVarSampFields>;
  variance?: Maybe<IAdditionalLessonsViewVarianceFields>;
}


/** aggregate fields of "additional_lessons_view" */
export interface IAdditionalLessonsViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IAdditionalLessonsViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IAdditionalLessonsViewAvgFields {
  __typename?: 'additional_lessons_view_avg_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "additional_lessons_view". All fields are combined with a logical 'AND'. */
export interface IAdditionalLessonsViewBoolExp {
  _and?: InputMaybe<Array<IAdditionalLessonsViewBoolExp>>;
  _not?: InputMaybe<IAdditionalLessonsViewBoolExp>;
  _or?: InputMaybe<Array<IAdditionalLessonsViewBoolExp>>;
  additional_lesson?: InputMaybe<IAdditionalLessonsBoolExp>;
  additional_lesson_id?: InputMaybe<IUuidComparisonExp>;
  available_from_lesson?: InputMaybe<IIntComparisonExp>;
  content?: InputMaybe<IStringComparisonExp>;
  course?: InputMaybe<ICoursesBoolExp>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  is_available?: InputMaybe<IBooleanComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  published?: InputMaybe<IBooleanComparisonExp>;
  subscription?: InputMaybe<ISubscriptionsBoolExp>;
  subscription_id?: InputMaybe<IUuidComparisonExp>;
  subtitle?: InputMaybe<IStringComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface IAdditionalLessonsViewMaxFields {
  __typename?: 'additional_lessons_view_max_fields';
  additional_lesson_id?: Maybe<Scalars['uuid']>;
  available_from_lesson?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  course_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  subtitle?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IAdditionalLessonsViewMinFields {
  __typename?: 'additional_lessons_view_min_fields';
  additional_lesson_id?: Maybe<Scalars['uuid']>;
  available_from_lesson?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  course_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  subtitle?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "additional_lessons_view". */
export interface IAdditionalLessonsViewOrderBy {
  additional_lesson?: InputMaybe<IAdditionalLessonsOrderBy>;
  additional_lesson_id?: InputMaybe<IOrderBy>;
  available_from_lesson?: InputMaybe<IOrderBy>;
  content?: InputMaybe<IOrderBy>;
  course?: InputMaybe<ICoursesOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  is_available?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  published?: InputMaybe<IOrderBy>;
  subscription?: InputMaybe<ISubscriptionsOrderBy>;
  subscription_id?: InputMaybe<IOrderBy>;
  subtitle?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "additional_lessons_view" */
export enum IAdditionalLessonsViewSelectColumn {
  /** column name */
  ADDITIONAL_LESSON_ID = 'additional_lesson_id',
  /** column name */
  AVAILABLE_FROM_LESSON = 'available_from_lesson',
  /** column name */
  CONTENT = 'content',
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  IS_AVAILABLE = 'is_available',
  /** column name */
  NAME = 'name',
  /** column name */
  PUBLISHED = 'published',
  /** column name */
  SUBSCRIPTION_ID = 'subscription_id',
  /** column name */
  SUBTITLE = 'subtitle',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate stddev on columns */
export interface IAdditionalLessonsViewStddevFields {
  __typename?: 'additional_lessons_view_stddev_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IAdditionalLessonsViewStddevPopFields {
  __typename?: 'additional_lessons_view_stddev_pop_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IAdditionalLessonsViewStddevSampFields {
  __typename?: 'additional_lessons_view_stddev_samp_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IAdditionalLessonsViewSumFields {
  __typename?: 'additional_lessons_view_sum_fields';
  available_from_lesson?: Maybe<Scalars['Int']>;
}

/** aggregate var_pop on columns */
export interface IAdditionalLessonsViewVarPopFields {
  __typename?: 'additional_lessons_view_var_pop_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IAdditionalLessonsViewVarSampFields {
  __typename?: 'additional_lessons_view_var_samp_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IAdditionalLessonsViewVarianceFields {
  __typename?: 'additional_lessons_view_variance_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "ambassador_lessons_view" */
export interface IAmbassadorLessonsView {
  __typename?: 'ambassador_lessons_view';
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  lesson_id?: Maybe<Scalars['uuid']>;
  ulp_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user_lesson_progresses?: Maybe<IUserLessonProgresses>;
}

/** aggregated selection of "ambassador_lessons_view" */
export interface IAmbassadorLessonsViewAggregate {
  __typename?: 'ambassador_lessons_view_aggregate';
  aggregate?: Maybe<IAmbassadorLessonsViewAggregateFields>;
  nodes: Array<IAmbassadorLessonsView>;
}

/** aggregate fields of "ambassador_lessons_view" */
export interface IAmbassadorLessonsViewAggregateFields {
  __typename?: 'ambassador_lessons_view_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IAmbassadorLessonsViewMaxFields>;
  min?: Maybe<IAmbassadorLessonsViewMinFields>;
}


/** aggregate fields of "ambassador_lessons_view" */
export interface IAmbassadorLessonsViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IAmbassadorLessonsViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "ambassador_lessons_view". All fields are combined with a logical 'AND'. */
export interface IAmbassadorLessonsViewBoolExp {
  _and?: InputMaybe<Array<IAmbassadorLessonsViewBoolExp>>;
  _not?: InputMaybe<IAmbassadorLessonsViewBoolExp>;
  _or?: InputMaybe<Array<IAmbassadorLessonsViewBoolExp>>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  ulp_id?: InputMaybe<IUuidComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
  user_lesson_progresses?: InputMaybe<IUserLessonProgressesBoolExp>;
}

/** aggregate max on columns */
export interface IAmbassadorLessonsViewMaxFields {
  __typename?: 'ambassador_lessons_view_max_fields';
  lesson_id?: Maybe<Scalars['uuid']>;
  ulp_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IAmbassadorLessonsViewMinFields {
  __typename?: 'ambassador_lessons_view_min_fields';
  lesson_id?: Maybe<Scalars['uuid']>;
  ulp_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "ambassador_lessons_view". */
export interface IAmbassadorLessonsViewOrderBy {
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  ulp_id?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  user_lesson_progresses?: InputMaybe<IUserLessonProgressesOrderBy>;
}

/** select columns of table "ambassador_lessons_view" */
export enum IAmbassadorLessonsViewSelectColumn {
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  ULP_ID = 'ulp_id',
  /** column name */
  USER_ID = 'user_id'
}

/** columns and relationships of "ambassadors" */
export interface IAmbassadors {
  __typename?: 'ambassadors';
  approach: Scalars['String'];
  city: Scalars['String'];
  created_at: Scalars['timestamp'];
  expectation: Scalars['String'];
  facebook?: Maybe<Scalars['String']>;
  facebook_followers?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  instagram?: Maybe<Scalars['String']>;
  instagram_followers?: Maybe<Scalars['Int']>;
  motivation: Scalars['String'];
  name: Scalars['String'];
  other?: Maybe<Scalars['String']>;
  other_followers?: Maybe<Scalars['Int']>;
  phone: Scalars['Int'];
  relationship: Scalars['String'];
  tiktok?: Maybe<Scalars['String']>;
  tiktok_followers?: Maybe<Scalars['Int']>;
  /** An object relationship */
  user: IUsers;
  user_id: Scalars['uuid'];
}

/** aggregated selection of "ambassadors" */
export interface IAmbassadorsAggregate {
  __typename?: 'ambassadors_aggregate';
  aggregate?: Maybe<IAmbassadorsAggregateFields>;
  nodes: Array<IAmbassadors>;
}

/** aggregate fields of "ambassadors" */
export interface IAmbassadorsAggregateFields {
  __typename?: 'ambassadors_aggregate_fields';
  avg?: Maybe<IAmbassadorsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IAmbassadorsMaxFields>;
  min?: Maybe<IAmbassadorsMinFields>;
  stddev?: Maybe<IAmbassadorsStddevFields>;
  stddev_pop?: Maybe<IAmbassadorsStddevPopFields>;
  stddev_samp?: Maybe<IAmbassadorsStddevSampFields>;
  sum?: Maybe<IAmbassadorsSumFields>;
  var_pop?: Maybe<IAmbassadorsVarPopFields>;
  var_samp?: Maybe<IAmbassadorsVarSampFields>;
  variance?: Maybe<IAmbassadorsVarianceFields>;
}


/** aggregate fields of "ambassadors" */
export interface IAmbassadorsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IAmbassadorsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IAmbassadorsAvgFields {
  __typename?: 'ambassadors_avg_fields';
  facebook_followers?: Maybe<Scalars['Float']>;
  instagram_followers?: Maybe<Scalars['Float']>;
  other_followers?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['Float']>;
  tiktok_followers?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "ambassadors". All fields are combined with a logical 'AND'. */
export interface IAmbassadorsBoolExp {
  _and?: InputMaybe<Array<IAmbassadorsBoolExp>>;
  _not?: InputMaybe<IAmbassadorsBoolExp>;
  _or?: InputMaybe<Array<IAmbassadorsBoolExp>>;
  approach?: InputMaybe<IStringComparisonExp>;
  city?: InputMaybe<IStringComparisonExp>;
  created_at?: InputMaybe<ITimestampComparisonExp>;
  expectation?: InputMaybe<IStringComparisonExp>;
  facebook?: InputMaybe<IStringComparisonExp>;
  facebook_followers?: InputMaybe<IIntComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  instagram?: InputMaybe<IStringComparisonExp>;
  instagram_followers?: InputMaybe<IIntComparisonExp>;
  motivation?: InputMaybe<IStringComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  other?: InputMaybe<IStringComparisonExp>;
  other_followers?: InputMaybe<IIntComparisonExp>;
  phone?: InputMaybe<IIntComparisonExp>;
  relationship?: InputMaybe<IStringComparisonExp>;
  tiktok?: InputMaybe<IStringComparisonExp>;
  tiktok_followers?: InputMaybe<IIntComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** unique or primary key constraints on table "ambassadors" */
export enum IAmbassadorsConstraint {
  /** unique or primary key constraint */
  AMBASSADORS_PKEY = 'ambassadors_pkey',
  /** unique or primary key constraint */
  AMBASSADORS_USER_ID_KEY = 'ambassadors_user_id_key'
}

/** input type for incrementing numeric columns in table "ambassadors" */
export interface IAmbassadorsIncInput {
  facebook_followers?: InputMaybe<Scalars['Int']>;
  instagram_followers?: InputMaybe<Scalars['Int']>;
  other_followers?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['Int']>;
  tiktok_followers?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "ambassadors" */
export interface IAmbassadorsInsertInput {
  approach?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  expectation?: InputMaybe<Scalars['String']>;
  facebook?: InputMaybe<Scalars['String']>;
  facebook_followers?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  instagram?: InputMaybe<Scalars['String']>;
  instagram_followers?: InputMaybe<Scalars['Int']>;
  motivation?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Scalars['String']>;
  other_followers?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['Int']>;
  relationship?: InputMaybe<Scalars['String']>;
  tiktok?: InputMaybe<Scalars['String']>;
  tiktok_followers?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<IUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface IAmbassadorsMaxFields {
  __typename?: 'ambassadors_max_fields';
  approach?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  expectation?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  facebook_followers?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  instagram?: Maybe<Scalars['String']>;
  instagram_followers?: Maybe<Scalars['Int']>;
  motivation?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  other_followers?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['Int']>;
  relationship?: Maybe<Scalars['String']>;
  tiktok?: Maybe<Scalars['String']>;
  tiktok_followers?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IAmbassadorsMinFields {
  __typename?: 'ambassadors_min_fields';
  approach?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  expectation?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  facebook_followers?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  instagram?: Maybe<Scalars['String']>;
  instagram_followers?: Maybe<Scalars['Int']>;
  motivation?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  other_followers?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['Int']>;
  relationship?: Maybe<Scalars['String']>;
  tiktok?: Maybe<Scalars['String']>;
  tiktok_followers?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** response of any mutation on the table "ambassadors" */
export interface IAmbassadorsMutationResponse {
  __typename?: 'ambassadors_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IAmbassadors>;
}

/** on_conflict condition type for table "ambassadors" */
export interface IAmbassadorsOnConflict {
  constraint: IAmbassadorsConstraint;
  update_columns?: Array<IAmbassadorsUpdateColumn>;
  where?: InputMaybe<IAmbassadorsBoolExp>;
}

/** Ordering options when selecting data from "ambassadors". */
export interface IAmbassadorsOrderBy {
  approach?: InputMaybe<IOrderBy>;
  city?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  expectation?: InputMaybe<IOrderBy>;
  facebook?: InputMaybe<IOrderBy>;
  facebook_followers?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  instagram?: InputMaybe<IOrderBy>;
  instagram_followers?: InputMaybe<IOrderBy>;
  motivation?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  other?: InputMaybe<IOrderBy>;
  other_followers?: InputMaybe<IOrderBy>;
  phone?: InputMaybe<IOrderBy>;
  relationship?: InputMaybe<IOrderBy>;
  tiktok?: InputMaybe<IOrderBy>;
  tiktok_followers?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: ambassadors */
export interface IAmbassadorsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "ambassadors" */
export enum IAmbassadorsSelectColumn {
  /** column name */
  APPROACH = 'approach',
  /** column name */
  CITY = 'city',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  EXPECTATION = 'expectation',
  /** column name */
  FACEBOOK = 'facebook',
  /** column name */
  FACEBOOK_FOLLOWERS = 'facebook_followers',
  /** column name */
  ID = 'id',
  /** column name */
  INSTAGRAM = 'instagram',
  /** column name */
  INSTAGRAM_FOLLOWERS = 'instagram_followers',
  /** column name */
  MOTIVATION = 'motivation',
  /** column name */
  NAME = 'name',
  /** column name */
  OTHER = 'other',
  /** column name */
  OTHER_FOLLOWERS = 'other_followers',
  /** column name */
  PHONE = 'phone',
  /** column name */
  RELATIONSHIP = 'relationship',
  /** column name */
  TIKTOK = 'tiktok',
  /** column name */
  TIKTOK_FOLLOWERS = 'tiktok_followers',
  /** column name */
  USER_ID = 'user_id'
}

/** input type for updating data in table "ambassadors" */
export interface IAmbassadorsSetInput {
  approach?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  expectation?: InputMaybe<Scalars['String']>;
  facebook?: InputMaybe<Scalars['String']>;
  facebook_followers?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  instagram?: InputMaybe<Scalars['String']>;
  instagram_followers?: InputMaybe<Scalars['Int']>;
  motivation?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Scalars['String']>;
  other_followers?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['Int']>;
  relationship?: InputMaybe<Scalars['String']>;
  tiktok?: InputMaybe<Scalars['String']>;
  tiktok_followers?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate stddev on columns */
export interface IAmbassadorsStddevFields {
  __typename?: 'ambassadors_stddev_fields';
  facebook_followers?: Maybe<Scalars['Float']>;
  instagram_followers?: Maybe<Scalars['Float']>;
  other_followers?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['Float']>;
  tiktok_followers?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IAmbassadorsStddevPopFields {
  __typename?: 'ambassadors_stddev_pop_fields';
  facebook_followers?: Maybe<Scalars['Float']>;
  instagram_followers?: Maybe<Scalars['Float']>;
  other_followers?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['Float']>;
  tiktok_followers?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IAmbassadorsStddevSampFields {
  __typename?: 'ambassadors_stddev_samp_fields';
  facebook_followers?: Maybe<Scalars['Float']>;
  instagram_followers?: Maybe<Scalars['Float']>;
  other_followers?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['Float']>;
  tiktok_followers?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IAmbassadorsSumFields {
  __typename?: 'ambassadors_sum_fields';
  facebook_followers?: Maybe<Scalars['Int']>;
  instagram_followers?: Maybe<Scalars['Int']>;
  other_followers?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['Int']>;
  tiktok_followers?: Maybe<Scalars['Int']>;
}

/** update columns of table "ambassadors" */
export enum IAmbassadorsUpdateColumn {
  /** column name */
  APPROACH = 'approach',
  /** column name */
  CITY = 'city',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  EXPECTATION = 'expectation',
  /** column name */
  FACEBOOK = 'facebook',
  /** column name */
  FACEBOOK_FOLLOWERS = 'facebook_followers',
  /** column name */
  ID = 'id',
  /** column name */
  INSTAGRAM = 'instagram',
  /** column name */
  INSTAGRAM_FOLLOWERS = 'instagram_followers',
  /** column name */
  MOTIVATION = 'motivation',
  /** column name */
  NAME = 'name',
  /** column name */
  OTHER = 'other',
  /** column name */
  OTHER_FOLLOWERS = 'other_followers',
  /** column name */
  PHONE = 'phone',
  /** column name */
  RELATIONSHIP = 'relationship',
  /** column name */
  TIKTOK = 'tiktok',
  /** column name */
  TIKTOK_FOLLOWERS = 'tiktok_followers',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate var_pop on columns */
export interface IAmbassadorsVarPopFields {
  __typename?: 'ambassadors_var_pop_fields';
  facebook_followers?: Maybe<Scalars['Float']>;
  instagram_followers?: Maybe<Scalars['Float']>;
  other_followers?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['Float']>;
  tiktok_followers?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IAmbassadorsVarSampFields {
  __typename?: 'ambassadors_var_samp_fields';
  facebook_followers?: Maybe<Scalars['Float']>;
  instagram_followers?: Maybe<Scalars['Float']>;
  other_followers?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['Float']>;
  tiktok_followers?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IAmbassadorsVarianceFields {
  __typename?: 'ambassadors_variance_fields';
  facebook_followers?: Maybe<Scalars['Float']>;
  instagram_followers?: Maybe<Scalars['Float']>;
  other_followers?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['Float']>;
  tiktok_followers?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "annual_schedule_attachments" */
export interface IAnnualScheduleAttachments {
  __typename?: 'annual_schedule_attachments';
  /** An object relationship */
  annual_schedule?: Maybe<IAnnualSchedules>;
  annual_schedule_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
}

/** aggregated selection of "annual_schedule_attachments" */
export interface IAnnualScheduleAttachmentsAggregate {
  __typename?: 'annual_schedule_attachments_aggregate';
  aggregate?: Maybe<IAnnualScheduleAttachmentsAggregateFields>;
  nodes: Array<IAnnualScheduleAttachments>;
}

/** aggregate fields of "annual_schedule_attachments" */
export interface IAnnualScheduleAttachmentsAggregateFields {
  __typename?: 'annual_schedule_attachments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IAnnualScheduleAttachmentsMaxFields>;
  min?: Maybe<IAnnualScheduleAttachmentsMinFields>;
}


/** aggregate fields of "annual_schedule_attachments" */
export interface IAnnualScheduleAttachmentsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IAnnualScheduleAttachmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "annual_schedule_attachments" */
export interface IAnnualScheduleAttachmentsAggregateOrderBy {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IAnnualScheduleAttachmentsMaxOrderBy>;
  min?: InputMaybe<IAnnualScheduleAttachmentsMinOrderBy>;
}

/** input type for inserting array relation for remote table "annual_schedule_attachments" */
export interface IAnnualScheduleAttachmentsArrRelInsertInput {
  data: Array<IAnnualScheduleAttachmentsInsertInput>;
}

/** Boolean expression to filter rows from the table "annual_schedule_attachments". All fields are combined with a logical 'AND'. */
export interface IAnnualScheduleAttachmentsBoolExp {
  _and?: InputMaybe<Array<IAnnualScheduleAttachmentsBoolExp>>;
  _not?: InputMaybe<IAnnualScheduleAttachmentsBoolExp>;
  _or?: InputMaybe<Array<IAnnualScheduleAttachmentsBoolExp>>;
  annual_schedule?: InputMaybe<IAnnualSchedulesBoolExp>;
  annual_schedule_id?: InputMaybe<IUuidComparisonExp>;
  created_at?: InputMaybe<ITimestampComparisonExp>;
  filename?: InputMaybe<IStringComparisonExp>;
  key?: InputMaybe<IStringComparisonExp>;
}

/** input type for inserting data into table "annual_schedule_attachments" */
export interface IAnnualScheduleAttachmentsInsertInput {
  annual_schedule?: InputMaybe<IAnnualSchedulesObjRelInsertInput>;
  annual_schedule_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  filename?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface IAnnualScheduleAttachmentsMaxFields {
  __typename?: 'annual_schedule_attachments_max_fields';
  annual_schedule_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "annual_schedule_attachments" */
export interface IAnnualScheduleAttachmentsMaxOrderBy {
  annual_schedule_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  filename?: InputMaybe<IOrderBy>;
  key?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IAnnualScheduleAttachmentsMinFields {
  __typename?: 'annual_schedule_attachments_min_fields';
  annual_schedule_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "annual_schedule_attachments" */
export interface IAnnualScheduleAttachmentsMinOrderBy {
  annual_schedule_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  filename?: InputMaybe<IOrderBy>;
  key?: InputMaybe<IOrderBy>;
}

/** Ordering options when selecting data from "annual_schedule_attachments". */
export interface IAnnualScheduleAttachmentsOrderBy {
  annual_schedule?: InputMaybe<IAnnualSchedulesOrderBy>;
  annual_schedule_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  filename?: InputMaybe<IOrderBy>;
  key?: InputMaybe<IOrderBy>;
}

/** select columns of table "annual_schedule_attachments" */
export enum IAnnualScheduleAttachmentsSelectColumn {
  /** column name */
  ANNUAL_SCHEDULE_ID = 'annual_schedule_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  FILENAME = 'filename',
  /** column name */
  KEY = 'key'
}

/** columns and relationships of "annual_schedules" */
export interface IAnnualSchedules {
  __typename?: 'annual_schedules';
  /** An array relationship */
  annual_schedule_attachments: Array<IAnnualScheduleAttachments>;
  /** An aggregate relationship */
  annual_schedule_attachments_aggregate: IAnnualScheduleAttachmentsAggregate;
  /** An object relationship */
  course?: Maybe<ICourses>;
  course_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
}


/** columns and relationships of "annual_schedules" */
export interface IAnnualSchedulesAnnualScheduleAttachmentsArgs {
  distinct_on?: InputMaybe<Array<IAnnualScheduleAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAnnualScheduleAttachmentsOrderBy>>;
  where?: InputMaybe<IAnnualScheduleAttachmentsBoolExp>;
}


/** columns and relationships of "annual_schedules" */
export interface IAnnualSchedulesAnnualScheduleAttachmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<IAnnualScheduleAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAnnualScheduleAttachmentsOrderBy>>;
  where?: InputMaybe<IAnnualScheduleAttachmentsBoolExp>;
}

/** aggregated selection of "annual_schedules" */
export interface IAnnualSchedulesAggregate {
  __typename?: 'annual_schedules_aggregate';
  aggregate?: Maybe<IAnnualSchedulesAggregateFields>;
  nodes: Array<IAnnualSchedules>;
}

/** aggregate fields of "annual_schedules" */
export interface IAnnualSchedulesAggregateFields {
  __typename?: 'annual_schedules_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IAnnualSchedulesMaxFields>;
  min?: Maybe<IAnnualSchedulesMinFields>;
}


/** aggregate fields of "annual_schedules" */
export interface IAnnualSchedulesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IAnnualSchedulesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "annual_schedules". All fields are combined with a logical 'AND'. */
export interface IAnnualSchedulesBoolExp {
  _and?: InputMaybe<Array<IAnnualSchedulesBoolExp>>;
  _not?: InputMaybe<IAnnualSchedulesBoolExp>;
  _or?: InputMaybe<Array<IAnnualSchedulesBoolExp>>;
  annual_schedule_attachments?: InputMaybe<IAnnualScheduleAttachmentsBoolExp>;
  course?: InputMaybe<ICoursesBoolExp>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  title?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "annual_schedules" */
export enum IAnnualSchedulesConstraint {
  /** unique or primary key constraint */
  ANNUAL_SCHEDULES_PKEY = 'annual_schedules_pkey'
}

/** input type for inserting data into table "annual_schedules" */
export interface IAnnualSchedulesInsertInput {
  annual_schedule_attachments?: InputMaybe<IAnnualScheduleAttachmentsArrRelInsertInput>;
  course?: InputMaybe<ICoursesObjRelInsertInput>;
  course_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface IAnnualSchedulesMaxFields {
  __typename?: 'annual_schedules_max_fields';
  course_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface IAnnualSchedulesMinFields {
  __typename?: 'annual_schedules_min_fields';
  course_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** response of any mutation on the table "annual_schedules" */
export interface IAnnualSchedulesMutationResponse {
  __typename?: 'annual_schedules_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IAnnualSchedules>;
}

/** input type for inserting object relation for remote table "annual_schedules" */
export interface IAnnualSchedulesObjRelInsertInput {
  data: IAnnualSchedulesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IAnnualSchedulesOnConflict>;
}

/** on_conflict condition type for table "annual_schedules" */
export interface IAnnualSchedulesOnConflict {
  constraint: IAnnualSchedulesConstraint;
  update_columns?: Array<IAnnualSchedulesUpdateColumn>;
  where?: InputMaybe<IAnnualSchedulesBoolExp>;
}

/** Ordering options when selecting data from "annual_schedules". */
export interface IAnnualSchedulesOrderBy {
  annual_schedule_attachments_aggregate?: InputMaybe<IAnnualScheduleAttachmentsAggregateOrderBy>;
  course?: InputMaybe<ICoursesOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  title?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: annual_schedules */
export interface IAnnualSchedulesPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "annual_schedules" */
export enum IAnnualSchedulesSelectColumn {
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "annual_schedules" */
export interface IAnnualSchedulesSetInput {
  course_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** update columns of table "annual_schedules" */
export enum IAnnualSchedulesUpdateColumn {
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** Add check later: users_child_id OR admin_user_id should be filled */
export interface IAnswers {
  __typename?: 'answers';
  admin_user_id?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  question_id: Scalars['uuid'];
  users_child_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  vxc?: Maybe<IQuestions>;
}

/** aggregated selection of "answers" */
export interface IAnswersAggregate {
  __typename?: 'answers_aggregate';
  aggregate?: Maybe<IAnswersAggregateFields>;
  nodes: Array<IAnswers>;
}

/** aggregate fields of "answers" */
export interface IAnswersAggregateFields {
  __typename?: 'answers_aggregate_fields';
  avg?: Maybe<IAnswersAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IAnswersMaxFields>;
  min?: Maybe<IAnswersMinFields>;
  stddev?: Maybe<IAnswersStddevFields>;
  stddev_pop?: Maybe<IAnswersStddevPopFields>;
  stddev_samp?: Maybe<IAnswersStddevSampFields>;
  sum?: Maybe<IAnswersSumFields>;
  var_pop?: Maybe<IAnswersVarPopFields>;
  var_samp?: Maybe<IAnswersVarSampFields>;
  variance?: Maybe<IAnswersVarianceFields>;
}


/** aggregate fields of "answers" */
export interface IAnswersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IAnswersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "answers" */
export interface IAnswersAggregateOrderBy {
  avg?: InputMaybe<IAnswersAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IAnswersMaxOrderBy>;
  min?: InputMaybe<IAnswersMinOrderBy>;
  stddev?: InputMaybe<IAnswersStddevOrderBy>;
  stddev_pop?: InputMaybe<IAnswersStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IAnswersStddevSampOrderBy>;
  sum?: InputMaybe<IAnswersSumOrderBy>;
  var_pop?: InputMaybe<IAnswersVarPopOrderBy>;
  var_samp?: InputMaybe<IAnswersVarSampOrderBy>;
  variance?: InputMaybe<IAnswersVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "answers" */
export interface IAnswersArrRelInsertInput {
  data: Array<IAnswersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IAnswersOnConflict>;
}

/** aggregate avg on columns */
export interface IAnswersAvgFields {
  __typename?: 'answers_avg_fields';
  admin_user_id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "answers" */
export interface IAnswersAvgOrderBy {
  admin_user_id?: InputMaybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "answers". All fields are combined with a logical 'AND'. */
export interface IAnswersBoolExp {
  _and?: InputMaybe<Array<IAnswersBoolExp>>;
  _not?: InputMaybe<IAnswersBoolExp>;
  _or?: InputMaybe<Array<IAnswersBoolExp>>;
  admin_user_id?: InputMaybe<IBigintComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  question_id?: InputMaybe<IUuidComparisonExp>;
  users_child_id?: InputMaybe<IUuidComparisonExp>;
  vxc?: InputMaybe<IQuestionsBoolExp>;
}

/** unique or primary key constraints on table "answers" */
export enum IAnswersConstraint {
  /** unique or primary key constraint */
  ANSWERS_PKEY = 'answers_pkey'
}

/** input type for incrementing numeric columns in table "answers" */
export interface IAnswersIncInput {
  admin_user_id?: InputMaybe<Scalars['bigint']>;
}

/** input type for inserting data into table "answers" */
export interface IAnswersInsertInput {
  admin_user_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  question_id?: InputMaybe<Scalars['uuid']>;
  users_child_id?: InputMaybe<Scalars['uuid']>;
  vxc?: InputMaybe<IQuestionsObjRelInsertInput>;
}

/** aggregate max on columns */
export interface IAnswersMaxFields {
  __typename?: 'answers_max_fields';
  admin_user_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  question_id?: Maybe<Scalars['uuid']>;
  users_child_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "answers" */
export interface IAnswersMaxOrderBy {
  admin_user_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  question_id?: InputMaybe<IOrderBy>;
  users_child_id?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IAnswersMinFields {
  __typename?: 'answers_min_fields';
  admin_user_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  question_id?: Maybe<Scalars['uuid']>;
  users_child_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "answers" */
export interface IAnswersMinOrderBy {
  admin_user_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  question_id?: InputMaybe<IOrderBy>;
  users_child_id?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "answers" */
export interface IAnswersMutationResponse {
  __typename?: 'answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IAnswers>;
}

/** on_conflict condition type for table "answers" */
export interface IAnswersOnConflict {
  constraint: IAnswersConstraint;
  update_columns?: Array<IAnswersUpdateColumn>;
  where?: InputMaybe<IAnswersBoolExp>;
}

/** Ordering options when selecting data from "answers". */
export interface IAnswersOrderBy {
  admin_user_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  question_id?: InputMaybe<IOrderBy>;
  users_child_id?: InputMaybe<IOrderBy>;
  vxc?: InputMaybe<IQuestionsOrderBy>;
}

/** primary key columns input for table: answers */
export interface IAnswersPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "answers" */
export enum IAnswersSelectColumn {
  /** column name */
  ADMIN_USER_ID = 'admin_user_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  ID = 'id',
  /** column name */
  QUESTION_ID = 'question_id',
  /** column name */
  USERS_CHILD_ID = 'users_child_id'
}

/** input type for updating data in table "answers" */
export interface IAnswersSetInput {
  admin_user_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  question_id?: InputMaybe<Scalars['uuid']>;
  users_child_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate stddev on columns */
export interface IAnswersStddevFields {
  __typename?: 'answers_stddev_fields';
  admin_user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "answers" */
export interface IAnswersStddevOrderBy {
  admin_user_id?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IAnswersStddevPopFields {
  __typename?: 'answers_stddev_pop_fields';
  admin_user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "answers" */
export interface IAnswersStddevPopOrderBy {
  admin_user_id?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IAnswersStddevSampFields {
  __typename?: 'answers_stddev_samp_fields';
  admin_user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "answers" */
export interface IAnswersStddevSampOrderBy {
  admin_user_id?: InputMaybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IAnswersSumFields {
  __typename?: 'answers_sum_fields';
  admin_user_id?: Maybe<Scalars['bigint']>;
}

/** order by sum() on columns of table "answers" */
export interface IAnswersSumOrderBy {
  admin_user_id?: InputMaybe<IOrderBy>;
}

/** update columns of table "answers" */
export enum IAnswersUpdateColumn {
  /** column name */
  ADMIN_USER_ID = 'admin_user_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  ID = 'id',
  /** column name */
  QUESTION_ID = 'question_id',
  /** column name */
  USERS_CHILD_ID = 'users_child_id'
}

/** aggregate var_pop on columns */
export interface IAnswersVarPopFields {
  __typename?: 'answers_var_pop_fields';
  admin_user_id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "answers" */
export interface IAnswersVarPopOrderBy {
  admin_user_id?: InputMaybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IAnswersVarSampFields {
  __typename?: 'answers_var_samp_fields';
  admin_user_id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "answers" */
export interface IAnswersVarSampOrderBy {
  admin_user_id?: InputMaybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IAnswersVarianceFields {
  __typename?: 'answers_variance_fields';
  admin_user_id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "answers" */
export interface IAnswersVarianceOrderBy {
  admin_user_id?: InputMaybe<IOrderBy>;
}

/** columns and relationships of "available_second_parts_view" */
export interface IAvailableSecondPartsView {
  __typename?: 'available_second_parts_view';
  created_at?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  group_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  lesson_id?: Maybe<Scalars['uuid']>;
  questions_enabled?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "available_second_parts_view" */
export interface IAvailableSecondPartsViewAggregate {
  __typename?: 'available_second_parts_view_aggregate';
  aggregate?: Maybe<IAvailableSecondPartsViewAggregateFields>;
  nodes: Array<IAvailableSecondPartsView>;
}

/** aggregate fields of "available_second_parts_view" */
export interface IAvailableSecondPartsViewAggregateFields {
  __typename?: 'available_second_parts_view_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IAvailableSecondPartsViewMaxFields>;
  min?: Maybe<IAvailableSecondPartsViewMinFields>;
}


/** aggregate fields of "available_second_parts_view" */
export interface IAvailableSecondPartsViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IAvailableSecondPartsViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "available_second_parts_view". All fields are combined with a logical 'AND'. */
export interface IAvailableSecondPartsViewBoolExp {
  _and?: InputMaybe<Array<IAvailableSecondPartsViewBoolExp>>;
  _not?: InputMaybe<IAvailableSecondPartsViewBoolExp>;
  _or?: InputMaybe<Array<IAvailableSecondPartsViewBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  end_time?: InputMaybe<ITimestamptzComparisonExp>;
  group_type_id?: InputMaybe<IUuidComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  questions_enabled?: InputMaybe<IBooleanComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface IAvailableSecondPartsViewMaxFields {
  __typename?: 'available_second_parts_view_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  group_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IAvailableSecondPartsViewMinFields {
  __typename?: 'available_second_parts_view_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  group_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "available_second_parts_view". */
export interface IAvailableSecondPartsViewOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  end_time?: InputMaybe<IOrderBy>;
  group_type_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  questions_enabled?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "available_second_parts_view" */
export enum IAvailableSecondPartsViewSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  END_TIME = 'end_time',
  /** column name */
  GROUP_TYPE_ID = 'group_type_id',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  QUESTIONS_ENABLED = 'questions_enabled',
  /** column name */
  USER_ID = 'user_id'
}

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export interface IBigintComparisonExp {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
}

/** columns and relationships of "course_groups" */
export interface ICourseGroups {
  __typename?: 'course_groups';
  /** An object relationship */
  course?: Maybe<ICourses>;
  course_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  day_of_week: Scalars['Int'];
  /** An object relationship */
  group_type: IGroupTypes;
  group_type_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An array relationship */
  lesson_days: Array<ILessonDays>;
  /** An aggregate relationship */
  lesson_days_aggregate: ILessonDaysAggregate;
  name: Scalars['String'];
  questions_enabled: Scalars['Boolean'];
  starts_at?: Maybe<Scalars['timetz']>;
  starts_on?: Maybe<Scalars['date']>;
  /** An array relationship */
  subscriptions: Array<ISubscriptions>;
  /** An aggregate relationship */
  subscriptions_aggregate: ISubscriptionsAggregate;
  updated_at: Scalars['timestamptz'];
}


/** columns and relationships of "course_groups" */
export interface ICourseGroupsLessonDaysArgs {
  distinct_on?: InputMaybe<Array<ILessonDaysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonDaysOrderBy>>;
  where?: InputMaybe<ILessonDaysBoolExp>;
}


/** columns and relationships of "course_groups" */
export interface ICourseGroupsLessonDaysAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonDaysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonDaysOrderBy>>;
  where?: InputMaybe<ILessonDaysBoolExp>;
}


/** columns and relationships of "course_groups" */
export interface ICourseGroupsSubscriptionsArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionsOrderBy>>;
  where?: InputMaybe<ISubscriptionsBoolExp>;
}


/** columns and relationships of "course_groups" */
export interface ICourseGroupsSubscriptionsAggregateArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionsOrderBy>>;
  where?: InputMaybe<ISubscriptionsBoolExp>;
}

/** aggregated selection of "course_groups" */
export interface ICourseGroupsAggregate {
  __typename?: 'course_groups_aggregate';
  aggregate?: Maybe<ICourseGroupsAggregateFields>;
  nodes: Array<ICourseGroups>;
}

/** aggregate fields of "course_groups" */
export interface ICourseGroupsAggregateFields {
  __typename?: 'course_groups_aggregate_fields';
  avg?: Maybe<ICourseGroupsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ICourseGroupsMaxFields>;
  min?: Maybe<ICourseGroupsMinFields>;
  stddev?: Maybe<ICourseGroupsStddevFields>;
  stddev_pop?: Maybe<ICourseGroupsStddevPopFields>;
  stddev_samp?: Maybe<ICourseGroupsStddevSampFields>;
  sum?: Maybe<ICourseGroupsSumFields>;
  var_pop?: Maybe<ICourseGroupsVarPopFields>;
  var_samp?: Maybe<ICourseGroupsVarSampFields>;
  variance?: Maybe<ICourseGroupsVarianceFields>;
}


/** aggregate fields of "course_groups" */
export interface ICourseGroupsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ICourseGroupsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "course_groups" */
export interface ICourseGroupsAggregateOrderBy {
  avg?: InputMaybe<ICourseGroupsAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<ICourseGroupsMaxOrderBy>;
  min?: InputMaybe<ICourseGroupsMinOrderBy>;
  stddev?: InputMaybe<ICourseGroupsStddevOrderBy>;
  stddev_pop?: InputMaybe<ICourseGroupsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ICourseGroupsStddevSampOrderBy>;
  sum?: InputMaybe<ICourseGroupsSumOrderBy>;
  var_pop?: InputMaybe<ICourseGroupsVarPopOrderBy>;
  var_samp?: InputMaybe<ICourseGroupsVarSampOrderBy>;
  variance?: InputMaybe<ICourseGroupsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "course_groups" */
export interface ICourseGroupsArrRelInsertInput {
  data: Array<ICourseGroupsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ICourseGroupsOnConflict>;
}

/** aggregate avg on columns */
export interface ICourseGroupsAvgFields {
  __typename?: 'course_groups_avg_fields';
  day_of_week?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "course_groups" */
export interface ICourseGroupsAvgOrderBy {
  day_of_week?: InputMaybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "course_groups". All fields are combined with a logical 'AND'. */
export interface ICourseGroupsBoolExp {
  _and?: InputMaybe<Array<ICourseGroupsBoolExp>>;
  _not?: InputMaybe<ICourseGroupsBoolExp>;
  _or?: InputMaybe<Array<ICourseGroupsBoolExp>>;
  course?: InputMaybe<ICoursesBoolExp>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  day_of_week?: InputMaybe<IIntComparisonExp>;
  group_type?: InputMaybe<IGroupTypesBoolExp>;
  group_type_id?: InputMaybe<IUuidComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  lesson_days?: InputMaybe<ILessonDaysBoolExp>;
  name?: InputMaybe<IStringComparisonExp>;
  questions_enabled?: InputMaybe<IBooleanComparisonExp>;
  starts_at?: InputMaybe<ITimetzComparisonExp>;
  starts_on?: InputMaybe<IDateComparisonExp>;
  subscriptions?: InputMaybe<ISubscriptionsBoolExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "course_groups" */
export enum ICourseGroupsConstraint {
  /** unique or primary key constraint */
  GROUP_PKEY = 'group_pkey'
}

/** input type for incrementing numeric columns in table "course_groups" */
export interface ICourseGroupsIncInput {
  day_of_week?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "course_groups" */
export interface ICourseGroupsInsertInput {
  course?: InputMaybe<ICoursesObjRelInsertInput>;
  course_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day_of_week?: InputMaybe<Scalars['Int']>;
  group_type?: InputMaybe<IGroupTypesObjRelInsertInput>;
  group_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson_days?: InputMaybe<ILessonDaysArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
  questions_enabled?: InputMaybe<Scalars['Boolean']>;
  starts_at?: InputMaybe<Scalars['timetz']>;
  starts_on?: InputMaybe<Scalars['date']>;
  subscriptions?: InputMaybe<ISubscriptionsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface ICourseGroupsMaxFields {
  __typename?: 'course_groups_max_fields';
  course_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day_of_week?: Maybe<Scalars['Int']>;
  group_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  starts_at?: Maybe<Scalars['timetz']>;
  starts_on?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by max() on columns of table "course_groups" */
export interface ICourseGroupsMaxOrderBy {
  course_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  day_of_week?: InputMaybe<IOrderBy>;
  group_type_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  starts_at?: InputMaybe<IOrderBy>;
  starts_on?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface ICourseGroupsMinFields {
  __typename?: 'course_groups_min_fields';
  course_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day_of_week?: Maybe<Scalars['Int']>;
  group_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  starts_at?: Maybe<Scalars['timetz']>;
  starts_on?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by min() on columns of table "course_groups" */
export interface ICourseGroupsMinOrderBy {
  course_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  day_of_week?: InputMaybe<IOrderBy>;
  group_type_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  starts_at?: InputMaybe<IOrderBy>;
  starts_on?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "course_groups" */
export interface ICourseGroupsMutationResponse {
  __typename?: 'course_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ICourseGroups>;
}

/** input type for inserting object relation for remote table "course_groups" */
export interface ICourseGroupsObjRelInsertInput {
  data: ICourseGroupsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ICourseGroupsOnConflict>;
}

/** on_conflict condition type for table "course_groups" */
export interface ICourseGroupsOnConflict {
  constraint: ICourseGroupsConstraint;
  update_columns?: Array<ICourseGroupsUpdateColumn>;
  where?: InputMaybe<ICourseGroupsBoolExp>;
}

/** Ordering options when selecting data from "course_groups". */
export interface ICourseGroupsOrderBy {
  course?: InputMaybe<ICoursesOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  day_of_week?: InputMaybe<IOrderBy>;
  group_type?: InputMaybe<IGroupTypesOrderBy>;
  group_type_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson_days_aggregate?: InputMaybe<ILessonDaysAggregateOrderBy>;
  name?: InputMaybe<IOrderBy>;
  questions_enabled?: InputMaybe<IOrderBy>;
  starts_at?: InputMaybe<IOrderBy>;
  starts_on?: InputMaybe<IOrderBy>;
  subscriptions_aggregate?: InputMaybe<ISubscriptionsAggregateOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: course_groups */
export interface ICourseGroupsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "course_groups" */
export enum ICourseGroupsSelectColumn {
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DAY_OF_WEEK = 'day_of_week',
  /** column name */
  GROUP_TYPE_ID = 'group_type_id',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  QUESTIONS_ENABLED = 'questions_enabled',
  /** column name */
  STARTS_AT = 'starts_at',
  /** column name */
  STARTS_ON = 'starts_on',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "course_groups" */
export interface ICourseGroupsSetInput {
  course_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day_of_week?: InputMaybe<Scalars['Int']>;
  group_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  questions_enabled?: InputMaybe<Scalars['Boolean']>;
  starts_at?: InputMaybe<Scalars['timetz']>;
  starts_on?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface ICourseGroupsStddevFields {
  __typename?: 'course_groups_stddev_fields';
  day_of_week?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "course_groups" */
export interface ICourseGroupsStddevOrderBy {
  day_of_week?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface ICourseGroupsStddevPopFields {
  __typename?: 'course_groups_stddev_pop_fields';
  day_of_week?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "course_groups" */
export interface ICourseGroupsStddevPopOrderBy {
  day_of_week?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface ICourseGroupsStddevSampFields {
  __typename?: 'course_groups_stddev_samp_fields';
  day_of_week?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "course_groups" */
export interface ICourseGroupsStddevSampOrderBy {
  day_of_week?: InputMaybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface ICourseGroupsSumFields {
  __typename?: 'course_groups_sum_fields';
  day_of_week?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "course_groups" */
export interface ICourseGroupsSumOrderBy {
  day_of_week?: InputMaybe<IOrderBy>;
}

/** update columns of table "course_groups" */
export enum ICourseGroupsUpdateColumn {
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DAY_OF_WEEK = 'day_of_week',
  /** column name */
  GROUP_TYPE_ID = 'group_type_id',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  QUESTIONS_ENABLED = 'questions_enabled',
  /** column name */
  STARTS_AT = 'starts_at',
  /** column name */
  STARTS_ON = 'starts_on',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface ICourseGroupsVarPopFields {
  __typename?: 'course_groups_var_pop_fields';
  day_of_week?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "course_groups" */
export interface ICourseGroupsVarPopOrderBy {
  day_of_week?: InputMaybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface ICourseGroupsVarSampFields {
  __typename?: 'course_groups_var_samp_fields';
  day_of_week?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "course_groups" */
export interface ICourseGroupsVarSampOrderBy {
  day_of_week?: InputMaybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface ICourseGroupsVarianceFields {
  __typename?: 'course_groups_variance_fields';
  day_of_week?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "course_groups" */
export interface ICourseGroupsVarianceOrderBy {
  day_of_week?: InputMaybe<IOrderBy>;
}

/** columns and relationships of "course_types" */
export interface ICourseTypes {
  __typename?: 'course_types';
  name: Scalars['String'];
}

/** aggregated selection of "course_types" */
export interface ICourseTypesAggregate {
  __typename?: 'course_types_aggregate';
  aggregate?: Maybe<ICourseTypesAggregateFields>;
  nodes: Array<ICourseTypes>;
}

/** aggregate fields of "course_types" */
export interface ICourseTypesAggregateFields {
  __typename?: 'course_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ICourseTypesMaxFields>;
  min?: Maybe<ICourseTypesMinFields>;
}


/** aggregate fields of "course_types" */
export interface ICourseTypesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ICourseTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "course_types". All fields are combined with a logical 'AND'. */
export interface ICourseTypesBoolExp {
  _and?: InputMaybe<Array<ICourseTypesBoolExp>>;
  _not?: InputMaybe<ICourseTypesBoolExp>;
  _or?: InputMaybe<Array<ICourseTypesBoolExp>>;
  name?: InputMaybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "course_types" */
export enum ICourseTypesConstraint {
  /** unique or primary key constraint */
  COURSE_TYPES_PKEY = 'course_types_pkey'
}

export enum ICourseTypesEnum {
  AMBASSADEURS = 'AMBASSADEURS',
  DERTIG_DAGEN = 'DERTIG_DAGEN',
  KORAN_LEREN_LEZEN = 'KORAN_LEREN_LEZEN',
  KORAN_VAN_KAFT_TOT_KAFT = 'KORAN_VAN_KAFT_TOT_KAFT',
  KORAN_VOOR_KIDS = 'KORAN_VOOR_KIDS'
}

/** Boolean expression to compare columns of type "course_types_enum". All fields are combined with logical 'AND'. */
export interface ICourseTypesEnumComparisonExp {
  _eq?: InputMaybe<ICourseTypesEnum>;
  _in?: InputMaybe<Array<ICourseTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ICourseTypesEnum>;
  _nin?: InputMaybe<Array<ICourseTypesEnum>>;
}

/** input type for inserting data into table "course_types" */
export interface ICourseTypesInsertInput {
  name?: InputMaybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface ICourseTypesMaxFields {
  __typename?: 'course_types_max_fields';
  name?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface ICourseTypesMinFields {
  __typename?: 'course_types_min_fields';
  name?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "course_types" */
export interface ICourseTypesMutationResponse {
  __typename?: 'course_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ICourseTypes>;
}

/** input type for inserting object relation for remote table "course_types" */
export interface ICourseTypesObjRelInsertInput {
  data: ICourseTypesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ICourseTypesOnConflict>;
}

/** on_conflict condition type for table "course_types" */
export interface ICourseTypesOnConflict {
  constraint: ICourseTypesConstraint;
  update_columns?: Array<ICourseTypesUpdateColumn>;
  where?: InputMaybe<ICourseTypesBoolExp>;
}

/** Ordering options when selecting data from "course_types". */
export interface ICourseTypesOrderBy {
  name?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: course_types */
export interface ICourseTypesPkColumnsInput {
  name: Scalars['String'];
}

/** select columns of table "course_types" */
export enum ICourseTypesSelectColumn {
  /** column name */
  NAME = 'name'
}

/** input type for updating data in table "course_types" */
export interface ICourseTypesSetInput {
  name?: InputMaybe<Scalars['String']>;
}

/** update columns of table "course_types" */
export enum ICourseTypesUpdateColumn {
  /** column name */
  NAME = 'name'
}

/** columns and relationships of "courses" */
export interface ICourses {
  __typename?: 'courses';
  /** An object relationship */
  annual_schedule?: Maybe<IAnnualSchedules>;
  /** An object relationship */
  courseTypeByCourseType: ICourseTypes;
  /** An array relationship */
  course_groups: Array<ICourseGroups>;
  /** An aggregate relationship */
  course_groups_aggregate: ICourseGroupsAggregate;
  course_type: ICourseTypesEnum;
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  has_achievements: Scalars['Boolean'];
  id: Scalars['uuid'];
  image_url: Scalars['String'];
  is_open: Scalars['Boolean'];
  /** An array relationship */
  lessons: Array<ILessons>;
  /** An aggregate relationship */
  lessons_aggregate: ILessonsAggregate;
  /** An array relationship */
  lessons_view: Array<ILessonsView>;
  /** An aggregate relationship */
  lessons_view_aggregate: ILessonsViewAggregate;
  /** An array relationship */
  lessons_with_lock: Array<ILessonsWithLock>;
  /** An aggregate relationship */
  lessons_with_lock_aggregate: ILessonsWithLockAggregate;
  max_profiles_count?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  /** An object relationship */
  possible_subscription?: Maybe<ISubscriptionsByUser>;
  semi_stream: Scalars['Boolean'];
  /** An array relationship */
  semi_stream_lessons: Array<ISemiStreamLessons>;
  /** An aggregate relationship */
  semi_stream_lessons_aggregate: ISemiStreamLessonsAggregate;
  /** An array relationship */
  subscriptions: Array<ISubscriptions>;
  /** An aggregate relationship */
  subscriptions_aggregate: ISubscriptionsAggregate;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user_course_usage?: Maybe<IUserCourseUsage>;
  wp_product_id?: Maybe<Scalars['Int']>;
}


/** columns and relationships of "courses" */
export interface ICoursesCourseGroupsArgs {
  distinct_on?: InputMaybe<Array<ICourseGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ICourseGroupsOrderBy>>;
  where?: InputMaybe<ICourseGroupsBoolExp>;
}


/** columns and relationships of "courses" */
export interface ICoursesCourseGroupsAggregateArgs {
  distinct_on?: InputMaybe<Array<ICourseGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ICourseGroupsOrderBy>>;
  where?: InputMaybe<ICourseGroupsBoolExp>;
}


/** columns and relationships of "courses" */
export interface ICoursesLessonsArgs {
  distinct_on?: InputMaybe<Array<ILessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsOrderBy>>;
  where?: InputMaybe<ILessonsBoolExp>;
}


/** columns and relationships of "courses" */
export interface ICoursesLessonsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsOrderBy>>;
  where?: InputMaybe<ILessonsBoolExp>;
}


/** columns and relationships of "courses" */
export interface ICoursesLessonsViewArgs {
  distinct_on?: InputMaybe<Array<ILessonsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsViewOrderBy>>;
  where?: InputMaybe<ILessonsViewBoolExp>;
}


/** columns and relationships of "courses" */
export interface ICoursesLessonsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsViewOrderBy>>;
  where?: InputMaybe<ILessonsViewBoolExp>;
}


/** columns and relationships of "courses" */
export interface ICoursesLessonsWithLockArgs {
  distinct_on?: InputMaybe<Array<ILessonsWithLockSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsWithLockOrderBy>>;
  where?: InputMaybe<ILessonsWithLockBoolExp>;
}


/** columns and relationships of "courses" */
export interface ICoursesLessonsWithLockAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonsWithLockSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsWithLockOrderBy>>;
  where?: InputMaybe<ILessonsWithLockBoolExp>;
}


/** columns and relationships of "courses" */
export interface ICoursesSemiStreamLessonsArgs {
  distinct_on?: InputMaybe<Array<ISemiStreamLessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISemiStreamLessonsOrderBy>>;
  where?: InputMaybe<ISemiStreamLessonsBoolExp>;
}


/** columns and relationships of "courses" */
export interface ICoursesSemiStreamLessonsAggregateArgs {
  distinct_on?: InputMaybe<Array<ISemiStreamLessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISemiStreamLessonsOrderBy>>;
  where?: InputMaybe<ISemiStreamLessonsBoolExp>;
}


/** columns and relationships of "courses" */
export interface ICoursesSubscriptionsArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionsOrderBy>>;
  where?: InputMaybe<ISubscriptionsBoolExp>;
}


/** columns and relationships of "courses" */
export interface ICoursesSubscriptionsAggregateArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionsOrderBy>>;
  where?: InputMaybe<ISubscriptionsBoolExp>;
}

/** aggregated selection of "courses" */
export interface ICoursesAggregate {
  __typename?: 'courses_aggregate';
  aggregate?: Maybe<ICoursesAggregateFields>;
  nodes: Array<ICourses>;
}

/** aggregate fields of "courses" */
export interface ICoursesAggregateFields {
  __typename?: 'courses_aggregate_fields';
  avg?: Maybe<ICoursesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ICoursesMaxFields>;
  min?: Maybe<ICoursesMinFields>;
  stddev?: Maybe<ICoursesStddevFields>;
  stddev_pop?: Maybe<ICoursesStddevPopFields>;
  stddev_samp?: Maybe<ICoursesStddevSampFields>;
  sum?: Maybe<ICoursesSumFields>;
  var_pop?: Maybe<ICoursesVarPopFields>;
  var_samp?: Maybe<ICoursesVarSampFields>;
  variance?: Maybe<ICoursesVarianceFields>;
}


/** aggregate fields of "courses" */
export interface ICoursesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ICoursesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface ICoursesAvgFields {
  __typename?: 'courses_avg_fields';
  max_profiles_count?: Maybe<Scalars['Float']>;
  wp_product_id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "courses". All fields are combined with a logical 'AND'. */
export interface ICoursesBoolExp {
  _and?: InputMaybe<Array<ICoursesBoolExp>>;
  _not?: InputMaybe<ICoursesBoolExp>;
  _or?: InputMaybe<Array<ICoursesBoolExp>>;
  annual_schedule?: InputMaybe<IAnnualSchedulesBoolExp>;
  courseTypeByCourseType?: InputMaybe<ICourseTypesBoolExp>;
  course_groups?: InputMaybe<ICourseGroupsBoolExp>;
  course_type?: InputMaybe<ICourseTypesEnumComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  has_achievements?: InputMaybe<IBooleanComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  image_url?: InputMaybe<IStringComparisonExp>;
  is_open?: InputMaybe<IBooleanComparisonExp>;
  lessons?: InputMaybe<ILessonsBoolExp>;
  lessons_view?: InputMaybe<ILessonsViewBoolExp>;
  lessons_with_lock?: InputMaybe<ILessonsWithLockBoolExp>;
  max_profiles_count?: InputMaybe<IIntComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  possible_subscription?: InputMaybe<ISubscriptionsByUserBoolExp>;
  semi_stream?: InputMaybe<IBooleanComparisonExp>;
  semi_stream_lessons?: InputMaybe<ISemiStreamLessonsBoolExp>;
  subscriptions?: InputMaybe<ISubscriptionsBoolExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  user_course_usage?: InputMaybe<IUserCourseUsageBoolExp>;
  wp_product_id?: InputMaybe<IIntComparisonExp>;
}

/** unique or primary key constraints on table "courses" */
export enum ICoursesConstraint {
  /** unique or primary key constraint */
  COURSE_NAME_KEY = 'course_name_key',
  /** unique or primary key constraint */
  COURSE_PKEY = 'course_pkey'
}

/** input type for incrementing numeric columns in table "courses" */
export interface ICoursesIncInput {
  max_profiles_count?: InputMaybe<Scalars['Int']>;
  wp_product_id?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "courses" */
export interface ICoursesInsertInput {
  annual_schedule?: InputMaybe<IAnnualSchedulesObjRelInsertInput>;
  courseTypeByCourseType?: InputMaybe<ICourseTypesObjRelInsertInput>;
  course_groups?: InputMaybe<ICourseGroupsArrRelInsertInput>;
  course_type?: InputMaybe<ICourseTypesEnum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  has_achievements?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_url?: InputMaybe<Scalars['String']>;
  is_open?: InputMaybe<Scalars['Boolean']>;
  lessons?: InputMaybe<ILessonsArrRelInsertInput>;
  lessons_view?: InputMaybe<ILessonsViewArrRelInsertInput>;
  lessons_with_lock?: InputMaybe<ILessonsWithLockArrRelInsertInput>;
  max_profiles_count?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  possible_subscription?: InputMaybe<ISubscriptionsByUserObjRelInsertInput>;
  semi_stream?: InputMaybe<Scalars['Boolean']>;
  semi_stream_lessons?: InputMaybe<ISemiStreamLessonsArrRelInsertInput>;
  subscriptions?: InputMaybe<ISubscriptionsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_course_usage?: InputMaybe<IUserCourseUsageObjRelInsertInput>;
  wp_product_id?: InputMaybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface ICoursesMaxFields {
  __typename?: 'courses_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_url?: Maybe<Scalars['String']>;
  max_profiles_count?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wp_product_id?: Maybe<Scalars['Int']>;
}

/** aggregate min on columns */
export interface ICoursesMinFields {
  __typename?: 'courses_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_url?: Maybe<Scalars['String']>;
  max_profiles_count?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wp_product_id?: Maybe<Scalars['Int']>;
}

/** response of any mutation on the table "courses" */
export interface ICoursesMutationResponse {
  __typename?: 'courses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ICourses>;
}

/** input type for inserting object relation for remote table "courses" */
export interface ICoursesObjRelInsertInput {
  data: ICoursesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ICoursesOnConflict>;
}

/** on_conflict condition type for table "courses" */
export interface ICoursesOnConflict {
  constraint: ICoursesConstraint;
  update_columns?: Array<ICoursesUpdateColumn>;
  where?: InputMaybe<ICoursesBoolExp>;
}

/** Ordering options when selecting data from "courses". */
export interface ICoursesOrderBy {
  annual_schedule?: InputMaybe<IAnnualSchedulesOrderBy>;
  courseTypeByCourseType?: InputMaybe<ICourseTypesOrderBy>;
  course_groups_aggregate?: InputMaybe<ICourseGroupsAggregateOrderBy>;
  course_type?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  has_achievements?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  image_url?: InputMaybe<IOrderBy>;
  is_open?: InputMaybe<IOrderBy>;
  lessons_aggregate?: InputMaybe<ILessonsAggregateOrderBy>;
  lessons_view_aggregate?: InputMaybe<ILessonsViewAggregateOrderBy>;
  lessons_with_lock_aggregate?: InputMaybe<ILessonsWithLockAggregateOrderBy>;
  max_profiles_count?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  possible_subscription?: InputMaybe<ISubscriptionsByUserOrderBy>;
  semi_stream?: InputMaybe<IOrderBy>;
  semi_stream_lessons_aggregate?: InputMaybe<ISemiStreamLessonsAggregateOrderBy>;
  subscriptions_aggregate?: InputMaybe<ISubscriptionsAggregateOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user_course_usage?: InputMaybe<IUserCourseUsageOrderBy>;
  wp_product_id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: courses */
export interface ICoursesPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "courses" */
export enum ICoursesSelectColumn {
  /** column name */
  COURSE_TYPE = 'course_type',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  HAS_ACHIEVEMENTS = 'has_achievements',
  /** column name */
  ID = 'id',
  /** column name */
  IMAGE_URL = 'image_url',
  /** column name */
  IS_OPEN = 'is_open',
  /** column name */
  MAX_PROFILES_COUNT = 'max_profiles_count',
  /** column name */
  NAME = 'name',
  /** column name */
  SEMI_STREAM = 'semi_stream',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  WP_PRODUCT_ID = 'wp_product_id'
}

/** input type for updating data in table "courses" */
export interface ICoursesSetInput {
  course_type?: InputMaybe<ICourseTypesEnum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  has_achievements?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_url?: InputMaybe<Scalars['String']>;
  is_open?: InputMaybe<Scalars['Boolean']>;
  max_profiles_count?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  semi_stream?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wp_product_id?: InputMaybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface ICoursesStddevFields {
  __typename?: 'courses_stddev_fields';
  max_profiles_count?: Maybe<Scalars['Float']>;
  wp_product_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface ICoursesStddevPopFields {
  __typename?: 'courses_stddev_pop_fields';
  max_profiles_count?: Maybe<Scalars['Float']>;
  wp_product_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface ICoursesStddevSampFields {
  __typename?: 'courses_stddev_samp_fields';
  max_profiles_count?: Maybe<Scalars['Float']>;
  wp_product_id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface ICoursesSumFields {
  __typename?: 'courses_sum_fields';
  max_profiles_count?: Maybe<Scalars['Int']>;
  wp_product_id?: Maybe<Scalars['Int']>;
}

/** update columns of table "courses" */
export enum ICoursesUpdateColumn {
  /** column name */
  COURSE_TYPE = 'course_type',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  HAS_ACHIEVEMENTS = 'has_achievements',
  /** column name */
  ID = 'id',
  /** column name */
  IMAGE_URL = 'image_url',
  /** column name */
  IS_OPEN = 'is_open',
  /** column name */
  MAX_PROFILES_COUNT = 'max_profiles_count',
  /** column name */
  NAME = 'name',
  /** column name */
  SEMI_STREAM = 'semi_stream',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  WP_PRODUCT_ID = 'wp_product_id'
}

/** aggregate var_pop on columns */
export interface ICoursesVarPopFields {
  __typename?: 'courses_var_pop_fields';
  max_profiles_count?: Maybe<Scalars['Float']>;
  wp_product_id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface ICoursesVarSampFields {
  __typename?: 'courses_var_samp_fields';
  max_profiles_count?: Maybe<Scalars['Float']>;
  wp_product_id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface ICoursesVarianceFields {
  __typename?: 'courses_variance_fields';
  max_profiles_count?: Maybe<Scalars['Float']>;
  wp_product_id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export interface IDateComparisonExp {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
}

/** columns and relationships of "dictionary_words" */
export interface IDictionaryWords {
  __typename?: 'dictionary_words';
  character: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  meaning: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  word: Scalars['String'];
}

/** aggregated selection of "dictionary_words" */
export interface IDictionaryWordsAggregate {
  __typename?: 'dictionary_words_aggregate';
  aggregate?: Maybe<IDictionaryWordsAggregateFields>;
  nodes: Array<IDictionaryWords>;
}

/** aggregate fields of "dictionary_words" */
export interface IDictionaryWordsAggregateFields {
  __typename?: 'dictionary_words_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IDictionaryWordsMaxFields>;
  min?: Maybe<IDictionaryWordsMinFields>;
}


/** aggregate fields of "dictionary_words" */
export interface IDictionaryWordsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IDictionaryWordsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "dictionary_words". All fields are combined with a logical 'AND'. */
export interface IDictionaryWordsBoolExp {
  _and?: InputMaybe<Array<IDictionaryWordsBoolExp>>;
  _not?: InputMaybe<IDictionaryWordsBoolExp>;
  _or?: InputMaybe<Array<IDictionaryWordsBoolExp>>;
  character?: InputMaybe<IStringComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  meaning?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  word?: InputMaybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "dictionary_words" */
export enum IDictionaryWordsConstraint {
  /** unique or primary key constraint */
  WORD_LISTS_PKEY = 'word_lists_pkey'
}

/** input type for inserting data into table "dictionary_words" */
export interface IDictionaryWordsInsertInput {
  character?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  meaning?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  word?: InputMaybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface IDictionaryWordsMaxFields {
  __typename?: 'dictionary_words_max_fields';
  character?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  meaning?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  word?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IDictionaryWordsMinFields {
  __typename?: 'dictionary_words_min_fields';
  character?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  meaning?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  word?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "dictionary_words" */
export interface IDictionaryWordsMutationResponse {
  __typename?: 'dictionary_words_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IDictionaryWords>;
}

/** on_conflict condition type for table "dictionary_words" */
export interface IDictionaryWordsOnConflict {
  constraint: IDictionaryWordsConstraint;
  update_columns?: Array<IDictionaryWordsUpdateColumn>;
  where?: InputMaybe<IDictionaryWordsBoolExp>;
}

/** Ordering options when selecting data from "dictionary_words". */
export interface IDictionaryWordsOrderBy {
  character?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  meaning?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  word?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: dictionary_words */
export interface IDictionaryWordsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "dictionary_words" */
export enum IDictionaryWordsSelectColumn {
  /** column name */
  CHARACTER = 'character',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  MEANING = 'meaning',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  WORD = 'word'
}

/** input type for updating data in table "dictionary_words" */
export interface IDictionaryWordsSetInput {
  character?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  meaning?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  word?: InputMaybe<Scalars['String']>;
}

/** update columns of table "dictionary_words" */
export enum IDictionaryWordsUpdateColumn {
  /** column name */
  CHARACTER = 'character',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  MEANING = 'meaning',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  WORD = 'word'
}

/** columns and relationships of "event_locations" */
export interface IEventLocations {
  __typename?: 'event_locations';
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  location_name: Scalars['String'];
  zipcode: Scalars['String'];
}

/** aggregated selection of "event_locations" */
export interface IEventLocationsAggregate {
  __typename?: 'event_locations_aggregate';
  aggregate?: Maybe<IEventLocationsAggregateFields>;
  nodes: Array<IEventLocations>;
}

/** aggregate fields of "event_locations" */
export interface IEventLocationsAggregateFields {
  __typename?: 'event_locations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IEventLocationsMaxFields>;
  min?: Maybe<IEventLocationsMinFields>;
}


/** aggregate fields of "event_locations" */
export interface IEventLocationsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IEventLocationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "event_locations". All fields are combined with a logical 'AND'. */
export interface IEventLocationsBoolExp {
  _and?: InputMaybe<Array<IEventLocationsBoolExp>>;
  _not?: InputMaybe<IEventLocationsBoolExp>;
  _or?: InputMaybe<Array<IEventLocationsBoolExp>>;
  address?: InputMaybe<IStringComparisonExp>;
  city?: InputMaybe<IStringComparisonExp>;
  country?: InputMaybe<IStringComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  location_name?: InputMaybe<IStringComparisonExp>;
  zipcode?: InputMaybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "event_locations" */
export enum IEventLocationsConstraint {
  /** unique or primary key constraint */
  EVENT_LOCATIONS_PKEY = 'event_locations_pkey'
}

/** input type for inserting data into table "event_locations" */
export interface IEventLocationsInsertInput {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  location_name?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface IEventLocationsMaxFields {
  __typename?: 'event_locations_max_fields';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  location_name?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IEventLocationsMinFields {
  __typename?: 'event_locations_min_fields';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  location_name?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "event_locations" */
export interface IEventLocationsMutationResponse {
  __typename?: 'event_locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IEventLocations>;
}

/** on_conflict condition type for table "event_locations" */
export interface IEventLocationsOnConflict {
  constraint: IEventLocationsConstraint;
  update_columns?: Array<IEventLocationsUpdateColumn>;
  where?: InputMaybe<IEventLocationsBoolExp>;
}

/** Ordering options when selecting data from "event_locations". */
export interface IEventLocationsOrderBy {
  address?: InputMaybe<IOrderBy>;
  city?: InputMaybe<IOrderBy>;
  country?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  location_name?: InputMaybe<IOrderBy>;
  zipcode?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: event_locations */
export interface IEventLocationsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "event_locations" */
export enum IEventLocationsSelectColumn {
  /** column name */
  ADDRESS = 'address',
  /** column name */
  CITY = 'city',
  /** column name */
  COUNTRY = 'country',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LOCATION_NAME = 'location_name',
  /** column name */
  ZIPCODE = 'zipcode'
}

/** input type for updating data in table "event_locations" */
export interface IEventLocationsSetInput {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  location_name?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
}

/** update columns of table "event_locations" */
export enum IEventLocationsUpdateColumn {
  /** column name */
  ADDRESS = 'address',
  /** column name */
  CITY = 'city',
  /** column name */
  COUNTRY = 'country',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LOCATION_NAME = 'location_name',
  /** column name */
  ZIPCODE = 'zipcode'
}

/** columns and relationships of "event_registrations" */
export interface IEventRegistrations {
  __typename?: 'event_registrations';
  created_at: Scalars['timestamptz'];
  event_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  user: IUsers;
  user_id: Scalars['uuid'];
}

/** aggregated selection of "event_registrations" */
export interface IEventRegistrationsAggregate {
  __typename?: 'event_registrations_aggregate';
  aggregate?: Maybe<IEventRegistrationsAggregateFields>;
  nodes: Array<IEventRegistrations>;
}

/** aggregate fields of "event_registrations" */
export interface IEventRegistrationsAggregateFields {
  __typename?: 'event_registrations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IEventRegistrationsMaxFields>;
  min?: Maybe<IEventRegistrationsMinFields>;
}


/** aggregate fields of "event_registrations" */
export interface IEventRegistrationsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IEventRegistrationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "event_registrations" */
export interface IEventRegistrationsAggregateOrderBy {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IEventRegistrationsMaxOrderBy>;
  min?: InputMaybe<IEventRegistrationsMinOrderBy>;
}

/** input type for inserting array relation for remote table "event_registrations" */
export interface IEventRegistrationsArrRelInsertInput {
  data: Array<IEventRegistrationsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IEventRegistrationsOnConflict>;
}

/** Boolean expression to filter rows from the table "event_registrations". All fields are combined with a logical 'AND'. */
export interface IEventRegistrationsBoolExp {
  _and?: InputMaybe<Array<IEventRegistrationsBoolExp>>;
  _not?: InputMaybe<IEventRegistrationsBoolExp>;
  _or?: InputMaybe<Array<IEventRegistrationsBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  event_id?: InputMaybe<IUuidComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** unique or primary key constraints on table "event_registrations" */
export enum IEventRegistrationsConstraint {
  /** unique or primary key constraint */
  EVENT_REGISTRATIONS_EVENT_ID_USER_ID_KEY = 'event_registrations_event_id_user_id_key',
  /** unique or primary key constraint */
  EVENT_REGISTRATIONS_PKEY = 'event_registrations_pkey'
}

/** input type for inserting data into table "event_registrations" */
export interface IEventRegistrationsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<IUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface IEventRegistrationsMaxFields {
  __typename?: 'event_registrations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "event_registrations" */
export interface IEventRegistrationsMaxOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  event_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IEventRegistrationsMinFields {
  __typename?: 'event_registrations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "event_registrations" */
export interface IEventRegistrationsMinOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  event_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "event_registrations" */
export interface IEventRegistrationsMutationResponse {
  __typename?: 'event_registrations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IEventRegistrations>;
}

/** on_conflict condition type for table "event_registrations" */
export interface IEventRegistrationsOnConflict {
  constraint: IEventRegistrationsConstraint;
  update_columns?: Array<IEventRegistrationsUpdateColumn>;
  where?: InputMaybe<IEventRegistrationsBoolExp>;
}

/** Ordering options when selecting data from "event_registrations". */
export interface IEventRegistrationsOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  event_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: event_registrations */
export interface IEventRegistrationsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "event_registrations" */
export enum IEventRegistrationsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  EVENT_ID = 'event_id',
  /** column name */
  ID = 'id',
  /** column name */
  USER_ID = 'user_id'
}

/** input type for updating data in table "event_registrations" */
export interface IEventRegistrationsSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** update columns of table "event_registrations" */
export enum IEventRegistrationsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  EVENT_ID = 'event_id',
  /** column name */
  ID = 'id',
  /** column name */
  USER_ID = 'user_id'
}

/** columns and relationships of "events" */
export interface IEvents {
  __typename?: 'events';
  cancelled: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  ends_at: Scalars['timetz'];
  event_location_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  event_registrations: Array<IEventRegistrations>;
  /** An aggregate relationship */
  event_registrations_aggregate: IEventRegistrationsAggregate;
  /** An array relationship */
  events_group_types: Array<IEventsGroupTypes>;
  /** An aggregate relationship */
  events_group_types_aggregate: IEventsGroupTypesAggregate;
  id: Scalars['uuid'];
  meeting: Scalars['Boolean'];
  name: Scalars['String'];
  published: Scalars['Boolean'];
  slots: Scalars['Int'];
  starts_at: Scalars['timetz'];
  starts_on: Scalars['date'];
}


/** columns and relationships of "events" */
export interface IEventsEventRegistrationsArgs {
  distinct_on?: InputMaybe<Array<IEventRegistrationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventRegistrationsOrderBy>>;
  where?: InputMaybe<IEventRegistrationsBoolExp>;
}


/** columns and relationships of "events" */
export interface IEventsEventRegistrationsAggregateArgs {
  distinct_on?: InputMaybe<Array<IEventRegistrationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventRegistrationsOrderBy>>;
  where?: InputMaybe<IEventRegistrationsBoolExp>;
}


/** columns and relationships of "events" */
export interface IEventsEventsGroupTypesArgs {
  distinct_on?: InputMaybe<Array<IEventsGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventsGroupTypesOrderBy>>;
  where?: InputMaybe<IEventsGroupTypesBoolExp>;
}


/** columns and relationships of "events" */
export interface IEventsEventsGroupTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<IEventsGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventsGroupTypesOrderBy>>;
  where?: InputMaybe<IEventsGroupTypesBoolExp>;
}

/** aggregated selection of "events" */
export interface IEventsAggregate {
  __typename?: 'events_aggregate';
  aggregate?: Maybe<IEventsAggregateFields>;
  nodes: Array<IEvents>;
}

/** aggregate fields of "events" */
export interface IEventsAggregateFields {
  __typename?: 'events_aggregate_fields';
  avg?: Maybe<IEventsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IEventsMaxFields>;
  min?: Maybe<IEventsMinFields>;
  stddev?: Maybe<IEventsStddevFields>;
  stddev_pop?: Maybe<IEventsStddevPopFields>;
  stddev_samp?: Maybe<IEventsStddevSampFields>;
  sum?: Maybe<IEventsSumFields>;
  var_pop?: Maybe<IEventsVarPopFields>;
  var_samp?: Maybe<IEventsVarSampFields>;
  variance?: Maybe<IEventsVarianceFields>;
}


/** aggregate fields of "events" */
export interface IEventsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IEventsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IEventsAvgFields {
  __typename?: 'events_avg_fields';
  slots?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "events". All fields are combined with a logical 'AND'. */
export interface IEventsBoolExp {
  _and?: InputMaybe<Array<IEventsBoolExp>>;
  _not?: InputMaybe<IEventsBoolExp>;
  _or?: InputMaybe<Array<IEventsBoolExp>>;
  cancelled?: InputMaybe<IBooleanComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  ends_at?: InputMaybe<ITimetzComparisonExp>;
  event_location_id?: InputMaybe<IUuidComparisonExp>;
  event_registrations?: InputMaybe<IEventRegistrationsBoolExp>;
  events_group_types?: InputMaybe<IEventsGroupTypesBoolExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  meeting?: InputMaybe<IBooleanComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  published?: InputMaybe<IBooleanComparisonExp>;
  slots?: InputMaybe<IIntComparisonExp>;
  starts_at?: InputMaybe<ITimetzComparisonExp>;
  starts_on?: InputMaybe<IDateComparisonExp>;
}

/** unique or primary key constraints on table "events" */
export enum IEventsConstraint {
  /** unique or primary key constraint */
  EVENTS_PKEY = 'events_pkey'
}

/** columns and relationships of "events_group_types" */
export interface IEventsGroupTypes {
  __typename?: 'events_group_types';
  /** An object relationship */
  event: IEvents;
  event_id: Scalars['uuid'];
  /** An object relationship */
  group_type: IGroupTypes;
  group_type_id: Scalars['uuid'];
  id: Scalars['uuid'];
}

/** aggregated selection of "events_group_types" */
export interface IEventsGroupTypesAggregate {
  __typename?: 'events_group_types_aggregate';
  aggregate?: Maybe<IEventsGroupTypesAggregateFields>;
  nodes: Array<IEventsGroupTypes>;
}

/** aggregate fields of "events_group_types" */
export interface IEventsGroupTypesAggregateFields {
  __typename?: 'events_group_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IEventsGroupTypesMaxFields>;
  min?: Maybe<IEventsGroupTypesMinFields>;
}


/** aggregate fields of "events_group_types" */
export interface IEventsGroupTypesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IEventsGroupTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "events_group_types" */
export interface IEventsGroupTypesAggregateOrderBy {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IEventsGroupTypesMaxOrderBy>;
  min?: InputMaybe<IEventsGroupTypesMinOrderBy>;
}

/** input type for inserting array relation for remote table "events_group_types" */
export interface IEventsGroupTypesArrRelInsertInput {
  data: Array<IEventsGroupTypesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IEventsGroupTypesOnConflict>;
}

/** Boolean expression to filter rows from the table "events_group_types". All fields are combined with a logical 'AND'. */
export interface IEventsGroupTypesBoolExp {
  _and?: InputMaybe<Array<IEventsGroupTypesBoolExp>>;
  _not?: InputMaybe<IEventsGroupTypesBoolExp>;
  _or?: InputMaybe<Array<IEventsGroupTypesBoolExp>>;
  event?: InputMaybe<IEventsBoolExp>;
  event_id?: InputMaybe<IUuidComparisonExp>;
  group_type?: InputMaybe<IGroupTypesBoolExp>;
  group_type_id?: InputMaybe<IUuidComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
}

/** unique or primary key constraints on table "events_group_types" */
export enum IEventsGroupTypesConstraint {
  /** unique or primary key constraint */
  EVENTS_GROUP_TYPES_EVENT_ID_GROUP_TYPE_ID_KEY = 'events_group_types_event_id_group_type_id_key',
  /** unique or primary key constraint */
  EVENTS_GROUP_TYPES_PKEY = 'events_group_types_pkey'
}

/** input type for inserting data into table "events_group_types" */
export interface IEventsGroupTypesInsertInput {
  event?: InputMaybe<IEventsObjRelInsertInput>;
  event_id?: InputMaybe<Scalars['uuid']>;
  group_type?: InputMaybe<IGroupTypesObjRelInsertInput>;
  group_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface IEventsGroupTypesMaxFields {
  __typename?: 'events_group_types_max_fields';
  event_id?: Maybe<Scalars['uuid']>;
  group_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "events_group_types" */
export interface IEventsGroupTypesMaxOrderBy {
  event_id?: InputMaybe<IOrderBy>;
  group_type_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IEventsGroupTypesMinFields {
  __typename?: 'events_group_types_min_fields';
  event_id?: Maybe<Scalars['uuid']>;
  group_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "events_group_types" */
export interface IEventsGroupTypesMinOrderBy {
  event_id?: InputMaybe<IOrderBy>;
  group_type_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "events_group_types" */
export interface IEventsGroupTypesMutationResponse {
  __typename?: 'events_group_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IEventsGroupTypes>;
}

/** on_conflict condition type for table "events_group_types" */
export interface IEventsGroupTypesOnConflict {
  constraint: IEventsGroupTypesConstraint;
  update_columns?: Array<IEventsGroupTypesUpdateColumn>;
  where?: InputMaybe<IEventsGroupTypesBoolExp>;
}

/** Ordering options when selecting data from "events_group_types". */
export interface IEventsGroupTypesOrderBy {
  event?: InputMaybe<IEventsOrderBy>;
  event_id?: InputMaybe<IOrderBy>;
  group_type?: InputMaybe<IGroupTypesOrderBy>;
  group_type_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: events_group_types */
export interface IEventsGroupTypesPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "events_group_types" */
export enum IEventsGroupTypesSelectColumn {
  /** column name */
  EVENT_ID = 'event_id',
  /** column name */
  GROUP_TYPE_ID = 'group_type_id',
  /** column name */
  ID = 'id'
}

/** input type for updating data in table "events_group_types" */
export interface IEventsGroupTypesSetInput {
  event_id?: InputMaybe<Scalars['uuid']>;
  group_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
}

/** update columns of table "events_group_types" */
export enum IEventsGroupTypesUpdateColumn {
  /** column name */
  EVENT_ID = 'event_id',
  /** column name */
  GROUP_TYPE_ID = 'group_type_id',
  /** column name */
  ID = 'id'
}

/** input type for incrementing numeric columns in table "events" */
export interface IEventsIncInput {
  slots?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "events" */
export interface IEventsInsertInput {
  cancelled?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  ends_at?: InputMaybe<Scalars['timetz']>;
  event_location_id?: InputMaybe<Scalars['uuid']>;
  event_registrations?: InputMaybe<IEventRegistrationsArrRelInsertInput>;
  events_group_types?: InputMaybe<IEventsGroupTypesArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  meeting?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  slots?: InputMaybe<Scalars['Int']>;
  starts_at?: InputMaybe<Scalars['timetz']>;
  starts_on?: InputMaybe<Scalars['date']>;
}

/** aggregate max on columns */
export interface IEventsMaxFields {
  __typename?: 'events_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  ends_at?: Maybe<Scalars['timetz']>;
  event_location_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slots?: Maybe<Scalars['Int']>;
  starts_at?: Maybe<Scalars['timetz']>;
  starts_on?: Maybe<Scalars['date']>;
}

/** aggregate min on columns */
export interface IEventsMinFields {
  __typename?: 'events_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  ends_at?: Maybe<Scalars['timetz']>;
  event_location_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slots?: Maybe<Scalars['Int']>;
  starts_at?: Maybe<Scalars['timetz']>;
  starts_on?: Maybe<Scalars['date']>;
}

/** response of any mutation on the table "events" */
export interface IEventsMutationResponse {
  __typename?: 'events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IEvents>;
}

/** input type for inserting object relation for remote table "events" */
export interface IEventsObjRelInsertInput {
  data: IEventsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IEventsOnConflict>;
}

/** on_conflict condition type for table "events" */
export interface IEventsOnConflict {
  constraint: IEventsConstraint;
  update_columns?: Array<IEventsUpdateColumn>;
  where?: InputMaybe<IEventsBoolExp>;
}

/** Ordering options when selecting data from "events". */
export interface IEventsOrderBy {
  cancelled?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  ends_at?: InputMaybe<IOrderBy>;
  event_location_id?: InputMaybe<IOrderBy>;
  event_registrations_aggregate?: InputMaybe<IEventRegistrationsAggregateOrderBy>;
  events_group_types_aggregate?: InputMaybe<IEventsGroupTypesAggregateOrderBy>;
  id?: InputMaybe<IOrderBy>;
  meeting?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  published?: InputMaybe<IOrderBy>;
  slots?: InputMaybe<IOrderBy>;
  starts_at?: InputMaybe<IOrderBy>;
  starts_on?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: events */
export interface IEventsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "events" */
export enum IEventsSelectColumn {
  /** column name */
  CANCELLED = 'cancelled',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  ENDS_AT = 'ends_at',
  /** column name */
  EVENT_LOCATION_ID = 'event_location_id',
  /** column name */
  ID = 'id',
  /** column name */
  MEETING = 'meeting',
  /** column name */
  NAME = 'name',
  /** column name */
  PUBLISHED = 'published',
  /** column name */
  SLOTS = 'slots',
  /** column name */
  STARTS_AT = 'starts_at',
  /** column name */
  STARTS_ON = 'starts_on'
}

/** input type for updating data in table "events" */
export interface IEventsSetInput {
  cancelled?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  ends_at?: InputMaybe<Scalars['timetz']>;
  event_location_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  meeting?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  slots?: InputMaybe<Scalars['Int']>;
  starts_at?: InputMaybe<Scalars['timetz']>;
  starts_on?: InputMaybe<Scalars['date']>;
}

/** aggregate stddev on columns */
export interface IEventsStddevFields {
  __typename?: 'events_stddev_fields';
  slots?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IEventsStddevPopFields {
  __typename?: 'events_stddev_pop_fields';
  slots?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IEventsStddevSampFields {
  __typename?: 'events_stddev_samp_fields';
  slots?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IEventsSumFields {
  __typename?: 'events_sum_fields';
  slots?: Maybe<Scalars['Int']>;
}

/** update columns of table "events" */
export enum IEventsUpdateColumn {
  /** column name */
  CANCELLED = 'cancelled',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  ENDS_AT = 'ends_at',
  /** column name */
  EVENT_LOCATION_ID = 'event_location_id',
  /** column name */
  ID = 'id',
  /** column name */
  MEETING = 'meeting',
  /** column name */
  NAME = 'name',
  /** column name */
  PUBLISHED = 'published',
  /** column name */
  SLOTS = 'slots',
  /** column name */
  STARTS_AT = 'starts_at',
  /** column name */
  STARTS_ON = 'starts_on'
}

/** aggregate var_pop on columns */
export interface IEventsVarPopFields {
  __typename?: 'events_var_pop_fields';
  slots?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IEventsVarSampFields {
  __typename?: 'events_var_samp_fields';
  slots?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IEventsVarianceFields {
  __typename?: 'events_variance_fields';
  slots?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "events_view" */
export interface IEventsView {
  __typename?: 'events_view';
  cancelled?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  ends_at?: Maybe<Scalars['time']>;
  /** An object relationship */
  event?: Maybe<IEvents>;
  event_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  event_location?: Maybe<IEventLocations>;
  event_location_id?: Maybe<Scalars['uuid']>;
  is_started?: Maybe<Scalars['Boolean']>;
  meeting?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  occupied_slots?: Maybe<Scalars['bigint']>;
  published?: Maybe<Scalars['Boolean']>;
  starts_at?: Maybe<Scalars['time']>;
  starts_on?: Maybe<Scalars['date']>;
  total_slots?: Maybe<Scalars['Int']>;
}

/** aggregated selection of "events_view" */
export interface IEventsViewAggregate {
  __typename?: 'events_view_aggregate';
  aggregate?: Maybe<IEventsViewAggregateFields>;
  nodes: Array<IEventsView>;
}

/** aggregate fields of "events_view" */
export interface IEventsViewAggregateFields {
  __typename?: 'events_view_aggregate_fields';
  avg?: Maybe<IEventsViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IEventsViewMaxFields>;
  min?: Maybe<IEventsViewMinFields>;
  stddev?: Maybe<IEventsViewStddevFields>;
  stddev_pop?: Maybe<IEventsViewStddevPopFields>;
  stddev_samp?: Maybe<IEventsViewStddevSampFields>;
  sum?: Maybe<IEventsViewSumFields>;
  var_pop?: Maybe<IEventsViewVarPopFields>;
  var_samp?: Maybe<IEventsViewVarSampFields>;
  variance?: Maybe<IEventsViewVarianceFields>;
}


/** aggregate fields of "events_view" */
export interface IEventsViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IEventsViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IEventsViewAvgFields {
  __typename?: 'events_view_avg_fields';
  occupied_slots?: Maybe<Scalars['Float']>;
  total_slots?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "events_view". All fields are combined with a logical 'AND'. */
export interface IEventsViewBoolExp {
  _and?: InputMaybe<Array<IEventsViewBoolExp>>;
  _not?: InputMaybe<IEventsViewBoolExp>;
  _or?: InputMaybe<Array<IEventsViewBoolExp>>;
  cancelled?: InputMaybe<IBooleanComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  ends_at?: InputMaybe<ITimeComparisonExp>;
  event?: InputMaybe<IEventsBoolExp>;
  event_id?: InputMaybe<IUuidComparisonExp>;
  event_location?: InputMaybe<IEventLocationsBoolExp>;
  event_location_id?: InputMaybe<IUuidComparisonExp>;
  is_started?: InputMaybe<IBooleanComparisonExp>;
  meeting?: InputMaybe<IBooleanComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  occupied_slots?: InputMaybe<IBigintComparisonExp>;
  published?: InputMaybe<IBooleanComparisonExp>;
  starts_at?: InputMaybe<ITimeComparisonExp>;
  starts_on?: InputMaybe<IDateComparisonExp>;
  total_slots?: InputMaybe<IIntComparisonExp>;
}

/** aggregate max on columns */
export interface IEventsViewMaxFields {
  __typename?: 'events_view_max_fields';
  description?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['uuid']>;
  event_location_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  occupied_slots?: Maybe<Scalars['bigint']>;
  starts_on?: Maybe<Scalars['date']>;
  total_slots?: Maybe<Scalars['Int']>;
}

/** aggregate min on columns */
export interface IEventsViewMinFields {
  __typename?: 'events_view_min_fields';
  description?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['uuid']>;
  event_location_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  occupied_slots?: Maybe<Scalars['bigint']>;
  starts_on?: Maybe<Scalars['date']>;
  total_slots?: Maybe<Scalars['Int']>;
}

/** Ordering options when selecting data from "events_view". */
export interface IEventsViewOrderBy {
  cancelled?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  ends_at?: InputMaybe<IOrderBy>;
  event?: InputMaybe<IEventsOrderBy>;
  event_id?: InputMaybe<IOrderBy>;
  event_location?: InputMaybe<IEventLocationsOrderBy>;
  event_location_id?: InputMaybe<IOrderBy>;
  is_started?: InputMaybe<IOrderBy>;
  meeting?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  occupied_slots?: InputMaybe<IOrderBy>;
  published?: InputMaybe<IOrderBy>;
  starts_at?: InputMaybe<IOrderBy>;
  starts_on?: InputMaybe<IOrderBy>;
  total_slots?: InputMaybe<IOrderBy>;
}

/** select columns of table "events_view" */
export enum IEventsViewSelectColumn {
  /** column name */
  CANCELLED = 'cancelled',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  ENDS_AT = 'ends_at',
  /** column name */
  EVENT_ID = 'event_id',
  /** column name */
  EVENT_LOCATION_ID = 'event_location_id',
  /** column name */
  IS_STARTED = 'is_started',
  /** column name */
  MEETING = 'meeting',
  /** column name */
  NAME = 'name',
  /** column name */
  OCCUPIED_SLOTS = 'occupied_slots',
  /** column name */
  PUBLISHED = 'published',
  /** column name */
  STARTS_AT = 'starts_at',
  /** column name */
  STARTS_ON = 'starts_on',
  /** column name */
  TOTAL_SLOTS = 'total_slots'
}

/** aggregate stddev on columns */
export interface IEventsViewStddevFields {
  __typename?: 'events_view_stddev_fields';
  occupied_slots?: Maybe<Scalars['Float']>;
  total_slots?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IEventsViewStddevPopFields {
  __typename?: 'events_view_stddev_pop_fields';
  occupied_slots?: Maybe<Scalars['Float']>;
  total_slots?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IEventsViewStddevSampFields {
  __typename?: 'events_view_stddev_samp_fields';
  occupied_slots?: Maybe<Scalars['Float']>;
  total_slots?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IEventsViewSumFields {
  __typename?: 'events_view_sum_fields';
  occupied_slots?: Maybe<Scalars['bigint']>;
  total_slots?: Maybe<Scalars['Int']>;
}

/** aggregate var_pop on columns */
export interface IEventsViewVarPopFields {
  __typename?: 'events_view_var_pop_fields';
  occupied_slots?: Maybe<Scalars['Float']>;
  total_slots?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IEventsViewVarSampFields {
  __typename?: 'events_view_var_samp_fields';
  occupied_slots?: Maybe<Scalars['Float']>;
  total_slots?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IEventsViewVarianceFields {
  __typename?: 'events_view_variance_fields';
  occupied_slots?: Maybe<Scalars['Float']>;
  total_slots?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "favorite_lesson_moments" */
export interface IFavoriteLessonMoments {
  __typename?: 'favorite_lesson_moments';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  is_used: Scalars['Boolean'];
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  lesson_id: Scalars['uuid'];
  lesson_part?: Maybe<Scalars['Int']>;
  lesson_time: Scalars['String'];
  surah?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id: Scalars['uuid'];
  /** An object relationship */
  users_child: IUsersChildren;
  users_child_id: Scalars['uuid'];
}

/** aggregated selection of "favorite_lesson_moments" */
export interface IFavoriteLessonMomentsAggregate {
  __typename?: 'favorite_lesson_moments_aggregate';
  aggregate?: Maybe<IFavoriteLessonMomentsAggregateFields>;
  nodes: Array<IFavoriteLessonMoments>;
}

/** aggregate fields of "favorite_lesson_moments" */
export interface IFavoriteLessonMomentsAggregateFields {
  __typename?: 'favorite_lesson_moments_aggregate_fields';
  avg?: Maybe<IFavoriteLessonMomentsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IFavoriteLessonMomentsMaxFields>;
  min?: Maybe<IFavoriteLessonMomentsMinFields>;
  stddev?: Maybe<IFavoriteLessonMomentsStddevFields>;
  stddev_pop?: Maybe<IFavoriteLessonMomentsStddevPopFields>;
  stddev_samp?: Maybe<IFavoriteLessonMomentsStddevSampFields>;
  sum?: Maybe<IFavoriteLessonMomentsSumFields>;
  var_pop?: Maybe<IFavoriteLessonMomentsVarPopFields>;
  var_samp?: Maybe<IFavoriteLessonMomentsVarSampFields>;
  variance?: Maybe<IFavoriteLessonMomentsVarianceFields>;
}


/** aggregate fields of "favorite_lesson_moments" */
export interface IFavoriteLessonMomentsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IFavoriteLessonMomentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IFavoriteLessonMomentsAvgFields {
  __typename?: 'favorite_lesson_moments_avg_fields';
  lesson_part?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "favorite_lesson_moments". All fields are combined with a logical 'AND'. */
export interface IFavoriteLessonMomentsBoolExp {
  _and?: InputMaybe<Array<IFavoriteLessonMomentsBoolExp>>;
  _not?: InputMaybe<IFavoriteLessonMomentsBoolExp>;
  _or?: InputMaybe<Array<IFavoriteLessonMomentsBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  is_used?: InputMaybe<IBooleanComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  lesson_part?: InputMaybe<IIntComparisonExp>;
  lesson_time?: InputMaybe<IStringComparisonExp>;
  surah?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
  users_child?: InputMaybe<IUsersChildrenBoolExp>;
  users_child_id?: InputMaybe<IUuidComparisonExp>;
}

/** unique or primary key constraints on table "favorite_lesson_moments" */
export enum IFavoriteLessonMomentsConstraint {
  /** unique or primary key constraint */
  FAVORITE_LESSON_MOMENTS_PKEY = 'favorite_lesson_moments_pkey'
}

/** input type for incrementing numeric columns in table "favorite_lesson_moments" */
export interface IFavoriteLessonMomentsIncInput {
  lesson_part?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "favorite_lesson_moments" */
export interface IFavoriteLessonMomentsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_used?: InputMaybe<Scalars['Boolean']>;
  lesson?: InputMaybe<ILessonsObjRelInsertInput>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  lesson_part?: InputMaybe<Scalars['Int']>;
  lesson_time?: InputMaybe<Scalars['String']>;
  surah?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<IUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']>;
  users_child?: InputMaybe<IUsersChildrenObjRelInsertInput>;
  users_child_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface IFavoriteLessonMomentsMaxFields {
  __typename?: 'favorite_lesson_moments_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  lesson_part?: Maybe<Scalars['Int']>;
  lesson_time?: Maybe<Scalars['String']>;
  surah?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  users_child_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IFavoriteLessonMomentsMinFields {
  __typename?: 'favorite_lesson_moments_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  lesson_part?: Maybe<Scalars['Int']>;
  lesson_time?: Maybe<Scalars['String']>;
  surah?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  users_child_id?: Maybe<Scalars['uuid']>;
}

/** response of any mutation on the table "favorite_lesson_moments" */
export interface IFavoriteLessonMomentsMutationResponse {
  __typename?: 'favorite_lesson_moments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IFavoriteLessonMoments>;
}

/** on_conflict condition type for table "favorite_lesson_moments" */
export interface IFavoriteLessonMomentsOnConflict {
  constraint: IFavoriteLessonMomentsConstraint;
  update_columns?: Array<IFavoriteLessonMomentsUpdateColumn>;
  where?: InputMaybe<IFavoriteLessonMomentsBoolExp>;
}

/** Ordering options when selecting data from "favorite_lesson_moments". */
export interface IFavoriteLessonMomentsOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  is_used?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  lesson_part?: InputMaybe<IOrderBy>;
  lesson_time?: InputMaybe<IOrderBy>;
  surah?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  users_child?: InputMaybe<IUsersChildrenOrderBy>;
  users_child_id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: favorite_lesson_moments */
export interface IFavoriteLessonMomentsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "favorite_lesson_moments" */
export enum IFavoriteLessonMomentsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  IS_USED = 'is_used',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  LESSON_PART = 'lesson_part',
  /** column name */
  LESSON_TIME = 'lesson_time',
  /** column name */
  SURAH = 'surah',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  USERS_CHILD_ID = 'users_child_id'
}

/** input type for updating data in table "favorite_lesson_moments" */
export interface IFavoriteLessonMomentsSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_used?: InputMaybe<Scalars['Boolean']>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  lesson_part?: InputMaybe<Scalars['Int']>;
  lesson_time?: InputMaybe<Scalars['String']>;
  surah?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  users_child_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate stddev on columns */
export interface IFavoriteLessonMomentsStddevFields {
  __typename?: 'favorite_lesson_moments_stddev_fields';
  lesson_part?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IFavoriteLessonMomentsStddevPopFields {
  __typename?: 'favorite_lesson_moments_stddev_pop_fields';
  lesson_part?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IFavoriteLessonMomentsStddevSampFields {
  __typename?: 'favorite_lesson_moments_stddev_samp_fields';
  lesson_part?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IFavoriteLessonMomentsSumFields {
  __typename?: 'favorite_lesson_moments_sum_fields';
  lesson_part?: Maybe<Scalars['Int']>;
}

/** update columns of table "favorite_lesson_moments" */
export enum IFavoriteLessonMomentsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  IS_USED = 'is_used',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  LESSON_PART = 'lesson_part',
  /** column name */
  LESSON_TIME = 'lesson_time',
  /** column name */
  SURAH = 'surah',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  USERS_CHILD_ID = 'users_child_id'
}

/** aggregate var_pop on columns */
export interface IFavoriteLessonMomentsVarPopFields {
  __typename?: 'favorite_lesson_moments_var_pop_fields';
  lesson_part?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IFavoriteLessonMomentsVarSampFields {
  __typename?: 'favorite_lesson_moments_var_samp_fields';
  lesson_part?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IFavoriteLessonMomentsVarianceFields {
  __typename?: 'favorite_lesson_moments_variance_fields';
  lesson_part?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "final_test_questions" */
export interface IFinalTestQuestions {
  __typename?: 'final_test_questions';
  answer: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  final_test_results: Array<IFinalTestResults>;
  /** An aggregate relationship */
  final_test_results_aggregate: IFinalTestResultsAggregate;
  id: Scalars['uuid'];
  question: Scalars['String'];
  sequence: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
}


/** columns and relationships of "final_test_questions" */
export interface IFinalTestQuestionsFinalTestResultsArgs {
  distinct_on?: InputMaybe<Array<IFinalTestResultsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFinalTestResultsOrderBy>>;
  where?: InputMaybe<IFinalTestResultsBoolExp>;
}


/** columns and relationships of "final_test_questions" */
export interface IFinalTestQuestionsFinalTestResultsAggregateArgs {
  distinct_on?: InputMaybe<Array<IFinalTestResultsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFinalTestResultsOrderBy>>;
  where?: InputMaybe<IFinalTestResultsBoolExp>;
}

/** aggregated selection of "final_test_questions" */
export interface IFinalTestQuestionsAggregate {
  __typename?: 'final_test_questions_aggregate';
  aggregate?: Maybe<IFinalTestQuestionsAggregateFields>;
  nodes: Array<IFinalTestQuestions>;
}

/** aggregate fields of "final_test_questions" */
export interface IFinalTestQuestionsAggregateFields {
  __typename?: 'final_test_questions_aggregate_fields';
  avg?: Maybe<IFinalTestQuestionsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IFinalTestQuestionsMaxFields>;
  min?: Maybe<IFinalTestQuestionsMinFields>;
  stddev?: Maybe<IFinalTestQuestionsStddevFields>;
  stddev_pop?: Maybe<IFinalTestQuestionsStddevPopFields>;
  stddev_samp?: Maybe<IFinalTestQuestionsStddevSampFields>;
  sum?: Maybe<IFinalTestQuestionsSumFields>;
  var_pop?: Maybe<IFinalTestQuestionsVarPopFields>;
  var_samp?: Maybe<IFinalTestQuestionsVarSampFields>;
  variance?: Maybe<IFinalTestQuestionsVarianceFields>;
}


/** aggregate fields of "final_test_questions" */
export interface IFinalTestQuestionsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IFinalTestQuestionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IFinalTestQuestionsAvgFields {
  __typename?: 'final_test_questions_avg_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "final_test_questions". All fields are combined with a logical 'AND'. */
export interface IFinalTestQuestionsBoolExp {
  _and?: InputMaybe<Array<IFinalTestQuestionsBoolExp>>;
  _not?: InputMaybe<IFinalTestQuestionsBoolExp>;
  _or?: InputMaybe<Array<IFinalTestQuestionsBoolExp>>;
  answer?: InputMaybe<IStringComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  final_test_results?: InputMaybe<IFinalTestResultsBoolExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  question?: InputMaybe<IStringComparisonExp>;
  sequence?: InputMaybe<IIntComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "final_test_questions" */
export enum IFinalTestQuestionsConstraint {
  /** unique or primary key constraint */
  FINAL_TEST_QUESTIONS_PKEY = 'final_test_questions_pkey'
}

/** input type for incrementing numeric columns in table "final_test_questions" */
export interface IFinalTestQuestionsIncInput {
  sequence?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "final_test_questions" */
export interface IFinalTestQuestionsInsertInput {
  answer?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  final_test_results?: InputMaybe<IFinalTestResultsArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  question?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface IFinalTestQuestionsMaxFields {
  __typename?: 'final_test_questions_max_fields';
  answer?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  question?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface IFinalTestQuestionsMinFields {
  __typename?: 'final_test_questions_min_fields';
  answer?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  question?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** response of any mutation on the table "final_test_questions" */
export interface IFinalTestQuestionsMutationResponse {
  __typename?: 'final_test_questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IFinalTestQuestions>;
}

/** on_conflict condition type for table "final_test_questions" */
export interface IFinalTestQuestionsOnConflict {
  constraint: IFinalTestQuestionsConstraint;
  update_columns?: Array<IFinalTestQuestionsUpdateColumn>;
  where?: InputMaybe<IFinalTestQuestionsBoolExp>;
}

/** Ordering options when selecting data from "final_test_questions". */
export interface IFinalTestQuestionsOrderBy {
  answer?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  final_test_results_aggregate?: InputMaybe<IFinalTestResultsAggregateOrderBy>;
  id?: InputMaybe<IOrderBy>;
  question?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: final_test_questions */
export interface IFinalTestQuestionsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "final_test_questions" */
export enum IFinalTestQuestionsSelectColumn {
  /** column name */
  ANSWER = 'answer',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  QUESTION = 'question',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "final_test_questions" */
export interface IFinalTestQuestionsSetInput {
  answer?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  question?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface IFinalTestQuestionsStddevFields {
  __typename?: 'final_test_questions_stddev_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IFinalTestQuestionsStddevPopFields {
  __typename?: 'final_test_questions_stddev_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IFinalTestQuestionsStddevSampFields {
  __typename?: 'final_test_questions_stddev_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IFinalTestQuestionsSumFields {
  __typename?: 'final_test_questions_sum_fields';
  sequence?: Maybe<Scalars['Int']>;
}

/** update columns of table "final_test_questions" */
export enum IFinalTestQuestionsUpdateColumn {
  /** column name */
  ANSWER = 'answer',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  QUESTION = 'question',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IFinalTestQuestionsVarPopFields {
  __typename?: 'final_test_questions_var_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IFinalTestQuestionsVarSampFields {
  __typename?: 'final_test_questions_var_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IFinalTestQuestionsVarianceFields {
  __typename?: 'final_test_questions_variance_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "final_test_results" */
export interface IFinalTestResults {
  __typename?: 'final_test_results';
  created_at: Scalars['timestamptz'];
  final_test_question_id: Scalars['uuid'];
  id: Scalars['uuid'];
  result: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  users_child: IUsersChildren;
  users_child_id: Scalars['uuid'];
}

/** aggregated selection of "final_test_results" */
export interface IFinalTestResultsAggregate {
  __typename?: 'final_test_results_aggregate';
  aggregate?: Maybe<IFinalTestResultsAggregateFields>;
  nodes: Array<IFinalTestResults>;
}

/** aggregate fields of "final_test_results" */
export interface IFinalTestResultsAggregateFields {
  __typename?: 'final_test_results_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IFinalTestResultsMaxFields>;
  min?: Maybe<IFinalTestResultsMinFields>;
}


/** aggregate fields of "final_test_results" */
export interface IFinalTestResultsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IFinalTestResultsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "final_test_results" */
export interface IFinalTestResultsAggregateOrderBy {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IFinalTestResultsMaxOrderBy>;
  min?: InputMaybe<IFinalTestResultsMinOrderBy>;
}

/** input type for inserting array relation for remote table "final_test_results" */
export interface IFinalTestResultsArrRelInsertInput {
  data: Array<IFinalTestResultsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IFinalTestResultsOnConflict>;
}

/** Boolean expression to filter rows from the table "final_test_results". All fields are combined with a logical 'AND'. */
export interface IFinalTestResultsBoolExp {
  _and?: InputMaybe<Array<IFinalTestResultsBoolExp>>;
  _not?: InputMaybe<IFinalTestResultsBoolExp>;
  _or?: InputMaybe<Array<IFinalTestResultsBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  final_test_question_id?: InputMaybe<IUuidComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  result?: InputMaybe<IBooleanComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  users_child?: InputMaybe<IUsersChildrenBoolExp>;
  users_child_id?: InputMaybe<IUuidComparisonExp>;
}

/** unique or primary key constraints on table "final_test_results" */
export enum IFinalTestResultsConstraint {
  /** unique or primary key constraint */
  FINAL_TEST_RESULTS_PKEY = 'final_test_results_pkey',
  /** unique or primary key constraint */
  FINAL_TEST_RESULTS_USERS_CHILD_ID_FINAL_TEST_QUESTION_ID_KEY = 'final_test_results_users_child_id_final_test_question_id_key'
}

/** input type for inserting data into table "final_test_results" */
export interface IFinalTestResultsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  final_test_question_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  result?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  users_child?: InputMaybe<IUsersChildrenObjRelInsertInput>;
  users_child_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface IFinalTestResultsMaxFields {
  __typename?: 'final_test_results_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  final_test_question_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  users_child_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "final_test_results" */
export interface IFinalTestResultsMaxOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  final_test_question_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  users_child_id?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IFinalTestResultsMinFields {
  __typename?: 'final_test_results_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  final_test_question_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  users_child_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "final_test_results" */
export interface IFinalTestResultsMinOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  final_test_question_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  users_child_id?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "final_test_results" */
export interface IFinalTestResultsMutationResponse {
  __typename?: 'final_test_results_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IFinalTestResults>;
}

/** on_conflict condition type for table "final_test_results" */
export interface IFinalTestResultsOnConflict {
  constraint: IFinalTestResultsConstraint;
  update_columns?: Array<IFinalTestResultsUpdateColumn>;
  where?: InputMaybe<IFinalTestResultsBoolExp>;
}

/** Ordering options when selecting data from "final_test_results". */
export interface IFinalTestResultsOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  final_test_question_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  result?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  users_child?: InputMaybe<IUsersChildrenOrderBy>;
  users_child_id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: final_test_results */
export interface IFinalTestResultsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "final_test_results" */
export enum IFinalTestResultsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  FINAL_TEST_QUESTION_ID = 'final_test_question_id',
  /** column name */
  ID = 'id',
  /** column name */
  RESULT = 'result',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USERS_CHILD_ID = 'users_child_id'
}

/** input type for updating data in table "final_test_results" */
export interface IFinalTestResultsSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  final_test_question_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  result?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  users_child_id?: InputMaybe<Scalars['uuid']>;
}

/** update columns of table "final_test_results" */
export enum IFinalTestResultsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  FINAL_TEST_QUESTION_ID = 'final_test_question_id',
  /** column name */
  ID = 'id',
  /** column name */
  RESULT = 'result',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USERS_CHILD_ID = 'users_child_id'
}

/** columns and relationships of "final_tests_view" */
export interface IFinalTestsView {
  __typename?: 'final_tests_view';
  answer?: Maybe<Scalars['String']>;
  /** An object relationship */
  final_test_question?: Maybe<IFinalTestQuestions>;
  question?: Maybe<Scalars['String']>;
  question_id?: Maybe<Scalars['uuid']>;
  result?: Maybe<Scalars['Boolean']>;
  sequence?: Maybe<Scalars['Int']>;
  /** An object relationship */
  users_child?: Maybe<IUsersChildren>;
  users_child_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "final_tests_view" */
export interface IFinalTestsViewAggregate {
  __typename?: 'final_tests_view_aggregate';
  aggregate?: Maybe<IFinalTestsViewAggregateFields>;
  nodes: Array<IFinalTestsView>;
}

/** aggregate fields of "final_tests_view" */
export interface IFinalTestsViewAggregateFields {
  __typename?: 'final_tests_view_aggregate_fields';
  avg?: Maybe<IFinalTestsViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IFinalTestsViewMaxFields>;
  min?: Maybe<IFinalTestsViewMinFields>;
  stddev?: Maybe<IFinalTestsViewStddevFields>;
  stddev_pop?: Maybe<IFinalTestsViewStddevPopFields>;
  stddev_samp?: Maybe<IFinalTestsViewStddevSampFields>;
  sum?: Maybe<IFinalTestsViewSumFields>;
  var_pop?: Maybe<IFinalTestsViewVarPopFields>;
  var_samp?: Maybe<IFinalTestsViewVarSampFields>;
  variance?: Maybe<IFinalTestsViewVarianceFields>;
}


/** aggregate fields of "final_tests_view" */
export interface IFinalTestsViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IFinalTestsViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IFinalTestsViewAvgFields {
  __typename?: 'final_tests_view_avg_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "final_tests_view". All fields are combined with a logical 'AND'. */
export interface IFinalTestsViewBoolExp {
  _and?: InputMaybe<Array<IFinalTestsViewBoolExp>>;
  _not?: InputMaybe<IFinalTestsViewBoolExp>;
  _or?: InputMaybe<Array<IFinalTestsViewBoolExp>>;
  answer?: InputMaybe<IStringComparisonExp>;
  final_test_question?: InputMaybe<IFinalTestQuestionsBoolExp>;
  question?: InputMaybe<IStringComparisonExp>;
  question_id?: InputMaybe<IUuidComparisonExp>;
  result?: InputMaybe<IBooleanComparisonExp>;
  sequence?: InputMaybe<IIntComparisonExp>;
  users_child?: InputMaybe<IUsersChildrenBoolExp>;
  users_child_id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface IFinalTestsViewMaxFields {
  __typename?: 'final_tests_view_max_fields';
  answer?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
  question_id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['Int']>;
  users_child_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IFinalTestsViewMinFields {
  __typename?: 'final_tests_view_min_fields';
  answer?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
  question_id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['Int']>;
  users_child_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "final_tests_view". */
export interface IFinalTestsViewOrderBy {
  answer?: InputMaybe<IOrderBy>;
  final_test_question?: InputMaybe<IFinalTestQuestionsOrderBy>;
  question?: InputMaybe<IOrderBy>;
  question_id?: InputMaybe<IOrderBy>;
  result?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  users_child?: InputMaybe<IUsersChildrenOrderBy>;
  users_child_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "final_tests_view" */
export enum IFinalTestsViewSelectColumn {
  /** column name */
  ANSWER = 'answer',
  /** column name */
  QUESTION = 'question',
  /** column name */
  QUESTION_ID = 'question_id',
  /** column name */
  RESULT = 'result',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  USERS_CHILD_ID = 'users_child_id'
}

/** aggregate stddev on columns */
export interface IFinalTestsViewStddevFields {
  __typename?: 'final_tests_view_stddev_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IFinalTestsViewStddevPopFields {
  __typename?: 'final_tests_view_stddev_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IFinalTestsViewStddevSampFields {
  __typename?: 'final_tests_view_stddev_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IFinalTestsViewSumFields {
  __typename?: 'final_tests_view_sum_fields';
  sequence?: Maybe<Scalars['Int']>;
}

/** aggregate var_pop on columns */
export interface IFinalTestsViewVarPopFields {
  __typename?: 'final_tests_view_var_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IFinalTestsViewVarSampFields {
  __typename?: 'final_tests_view_var_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IFinalTestsViewVarianceFields {
  __typename?: 'final_tests_view_variance_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "first_lesson_part_mail_reminders" */
export interface IFirstLessonPartMailReminders {
  __typename?: 'first_lesson_part_mail_reminders';
  datediff?: Maybe<Scalars['float8']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "first_lesson_part_mail_reminders" */
export interface IFirstLessonPartMailRemindersAggregate {
  __typename?: 'first_lesson_part_mail_reminders_aggregate';
  aggregate?: Maybe<IFirstLessonPartMailRemindersAggregateFields>;
  nodes: Array<IFirstLessonPartMailReminders>;
}

/** aggregate fields of "first_lesson_part_mail_reminders" */
export interface IFirstLessonPartMailRemindersAggregateFields {
  __typename?: 'first_lesson_part_mail_reminders_aggregate_fields';
  avg?: Maybe<IFirstLessonPartMailRemindersAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IFirstLessonPartMailRemindersMaxFields>;
  min?: Maybe<IFirstLessonPartMailRemindersMinFields>;
  stddev?: Maybe<IFirstLessonPartMailRemindersStddevFields>;
  stddev_pop?: Maybe<IFirstLessonPartMailRemindersStddevPopFields>;
  stddev_samp?: Maybe<IFirstLessonPartMailRemindersStddevSampFields>;
  sum?: Maybe<IFirstLessonPartMailRemindersSumFields>;
  var_pop?: Maybe<IFirstLessonPartMailRemindersVarPopFields>;
  var_samp?: Maybe<IFirstLessonPartMailRemindersVarSampFields>;
  variance?: Maybe<IFirstLessonPartMailRemindersVarianceFields>;
}


/** aggregate fields of "first_lesson_part_mail_reminders" */
export interface IFirstLessonPartMailRemindersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IFirstLessonPartMailRemindersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IFirstLessonPartMailRemindersAvgFields {
  __typename?: 'first_lesson_part_mail_reminders_avg_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "first_lesson_part_mail_reminders". All fields are combined with a logical 'AND'. */
export interface IFirstLessonPartMailRemindersBoolExp {
  _and?: InputMaybe<Array<IFirstLessonPartMailRemindersBoolExp>>;
  _not?: InputMaybe<IFirstLessonPartMailRemindersBoolExp>;
  _or?: InputMaybe<Array<IFirstLessonPartMailRemindersBoolExp>>;
  datediff?: InputMaybe<IFloat8ComparisonExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface IFirstLessonPartMailRemindersMaxFields {
  __typename?: 'first_lesson_part_mail_reminders_max_fields';
  datediff?: Maybe<Scalars['float8']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IFirstLessonPartMailRemindersMinFields {
  __typename?: 'first_lesson_part_mail_reminders_min_fields';
  datediff?: Maybe<Scalars['float8']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "first_lesson_part_mail_reminders". */
export interface IFirstLessonPartMailRemindersOrderBy {
  datediff?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "first_lesson_part_mail_reminders" */
export enum IFirstLessonPartMailRemindersSelectColumn {
  /** column name */
  DATEDIFF = 'datediff',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate stddev on columns */
export interface IFirstLessonPartMailRemindersStddevFields {
  __typename?: 'first_lesson_part_mail_reminders_stddev_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IFirstLessonPartMailRemindersStddevPopFields {
  __typename?: 'first_lesson_part_mail_reminders_stddev_pop_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IFirstLessonPartMailRemindersStddevSampFields {
  __typename?: 'first_lesson_part_mail_reminders_stddev_samp_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IFirstLessonPartMailRemindersSumFields {
  __typename?: 'first_lesson_part_mail_reminders_sum_fields';
  datediff?: Maybe<Scalars['float8']>;
}

/** aggregate var_pop on columns */
export interface IFirstLessonPartMailRemindersVarPopFields {
  __typename?: 'first_lesson_part_mail_reminders_var_pop_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IFirstLessonPartMailRemindersVarSampFields {
  __typename?: 'first_lesson_part_mail_reminders_var_samp_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IFirstLessonPartMailRemindersVarianceFields {
  __typename?: 'first_lesson_part_mail_reminders_variance_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export interface IFloat8ComparisonExp {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
}

/** columns and relationships of "friend_requests" */
export interface IFriendRequests {
  __typename?: 'friend_requests';
  accepted?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  receiver_id: Scalars['uuid'];
  /** An object relationship */
  receiver_user_child?: Maybe<IUsersChildren>;
  sender_id: Scalars['uuid'];
  /** An object relationship */
  sender_user_child?: Maybe<IUsersChildren>;
  updated_at: Scalars['timestamptz'];
}

/** aggregated selection of "friend_requests" */
export interface IFriendRequestsAggregate {
  __typename?: 'friend_requests_aggregate';
  aggregate?: Maybe<IFriendRequestsAggregateFields>;
  nodes: Array<IFriendRequests>;
}

/** aggregate fields of "friend_requests" */
export interface IFriendRequestsAggregateFields {
  __typename?: 'friend_requests_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IFriendRequestsMaxFields>;
  min?: Maybe<IFriendRequestsMinFields>;
}


/** aggregate fields of "friend_requests" */
export interface IFriendRequestsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IFriendRequestsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "friend_requests" */
export interface IFriendRequestsAggregateOrderBy {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IFriendRequestsMaxOrderBy>;
  min?: InputMaybe<IFriendRequestsMinOrderBy>;
}

/** input type for inserting array relation for remote table "friend_requests" */
export interface IFriendRequestsArrRelInsertInput {
  data: Array<IFriendRequestsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IFriendRequestsOnConflict>;
}

/** Boolean expression to filter rows from the table "friend_requests". All fields are combined with a logical 'AND'. */
export interface IFriendRequestsBoolExp {
  _and?: InputMaybe<Array<IFriendRequestsBoolExp>>;
  _not?: InputMaybe<IFriendRequestsBoolExp>;
  _or?: InputMaybe<Array<IFriendRequestsBoolExp>>;
  accepted?: InputMaybe<IBooleanComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  receiver_id?: InputMaybe<IUuidComparisonExp>;
  receiver_user_child?: InputMaybe<IUsersChildrenBoolExp>;
  sender_id?: InputMaybe<IUuidComparisonExp>;
  sender_user_child?: InputMaybe<IUsersChildrenBoolExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "friend_requests" */
export enum IFriendRequestsConstraint {
  /** unique or primary key constraint */
  FRIENDS_PKEY = 'friends_pkey',
  /** unique or primary key constraint */
  FRIENDS_SENDER_RECEIVER_KEY = 'friends_sender_receiver_key'
}

/** input type for inserting data into table "friend_requests" */
export interface IFriendRequestsInsertInput {
  accepted?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  receiver_id?: InputMaybe<Scalars['uuid']>;
  receiver_user_child?: InputMaybe<IUsersChildrenObjRelInsertInput>;
  sender_id?: InputMaybe<Scalars['uuid']>;
  sender_user_child?: InputMaybe<IUsersChildrenObjRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface IFriendRequestsMaxFields {
  __typename?: 'friend_requests_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  receiver_id?: Maybe<Scalars['uuid']>;
  sender_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by max() on columns of table "friend_requests" */
export interface IFriendRequestsMaxOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  receiver_id?: InputMaybe<IOrderBy>;
  sender_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IFriendRequestsMinFields {
  __typename?: 'friend_requests_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  receiver_id?: Maybe<Scalars['uuid']>;
  sender_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by min() on columns of table "friend_requests" */
export interface IFriendRequestsMinOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  receiver_id?: InputMaybe<IOrderBy>;
  sender_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "friend_requests" */
export interface IFriendRequestsMutationResponse {
  __typename?: 'friend_requests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IFriendRequests>;
}

/** on_conflict condition type for table "friend_requests" */
export interface IFriendRequestsOnConflict {
  constraint: IFriendRequestsConstraint;
  update_columns?: Array<IFriendRequestsUpdateColumn>;
  where?: InputMaybe<IFriendRequestsBoolExp>;
}

/** Ordering options when selecting data from "friend_requests". */
export interface IFriendRequestsOrderBy {
  accepted?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  receiver_id?: InputMaybe<IOrderBy>;
  receiver_user_child?: InputMaybe<IUsersChildrenOrderBy>;
  sender_id?: InputMaybe<IOrderBy>;
  sender_user_child?: InputMaybe<IUsersChildrenOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: friend_requests */
export interface IFriendRequestsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "friend_requests" */
export enum IFriendRequestsSelectColumn {
  /** column name */
  ACCEPTED = 'accepted',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  RECEIVER_ID = 'receiver_id',
  /** column name */
  SENDER_ID = 'sender_id',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "friend_requests" */
export interface IFriendRequestsSetInput {
  accepted?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  receiver_id?: InputMaybe<Scalars['uuid']>;
  sender_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** update columns of table "friend_requests" */
export enum IFriendRequestsUpdateColumn {
  /** column name */
  ACCEPTED = 'accepted',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  RECEIVER_ID = 'receiver_id',
  /** column name */
  SENDER_ID = 'sender_id',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** columns and relationships of "friend_requests_view" */
export interface IFriendRequestsView {
  __typename?: 'friend_requests_view';
  accepted?: Maybe<Scalars['Boolean']>;
  friend_request_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  receiver?: Maybe<IUsersChildren>;
  receiver_avatar?: Maybe<Scalars['String']>;
  /** An object relationship */
  receiver_details?: Maybe<IUsersChildDetails>;
  receiver_details_id?: Maybe<Scalars['Int']>;
  receiver_dream?: Maybe<Scalars['String']>;
  receiver_dream_job?: Maybe<Scalars['String']>;
  receiver_favorite_animal?: Maybe<Scalars['String']>;
  receiver_favorite_book?: Maybe<Scalars['String']>;
  receiver_favorite_color?: Maybe<Scalars['String']>;
  receiver_favorite_film?: Maybe<Scalars['String']>;
  receiver_favorite_food?: Maybe<Scalars['String']>;
  receiver_favorite_lesson?: Maybe<Scalars['String']>;
  receiver_favorite_school_subject?: Maybe<Scalars['String']>;
  receiver_favorite_soerah?: Maybe<Scalars['String']>;
  receiver_favorite_sport?: Maybe<Scalars['String']>;
  receiver_hero?: Maybe<Scalars['String']>;
  receiver_hobbies?: Maybe<Scalars['String']>;
  receiver_id?: Maybe<Scalars['uuid']>;
  receiver_joining_date?: Maybe<Scalars['timestamptz']>;
  receiver_learned_achievements?: Maybe<Scalars['String']>;
  receiver_name?: Maybe<Scalars['String']>;
  receiver_stars?: Maybe<Scalars['bigint']>;
  receiver_thankfull_for?: Maybe<Scalars['String']>;
  receiver_username?: Maybe<Scalars['String']>;
  receiver_wish?: Maybe<Scalars['String']>;
  /** An object relationship */
  sender?: Maybe<IUsersChildren>;
  sender_avatar?: Maybe<Scalars['String']>;
  /** An object relationship */
  sender_details?: Maybe<IUsersChildDetails>;
  sender_details_id?: Maybe<Scalars['Int']>;
  sender_dream?: Maybe<Scalars['String']>;
  sender_dream_job?: Maybe<Scalars['String']>;
  sender_favorite_animal?: Maybe<Scalars['String']>;
  sender_favorite_book?: Maybe<Scalars['String']>;
  sender_favorite_color?: Maybe<Scalars['String']>;
  sender_favorite_film?: Maybe<Scalars['String']>;
  sender_favorite_food?: Maybe<Scalars['String']>;
  sender_favorite_lesson?: Maybe<Scalars['String']>;
  sender_favorite_school_subject?: Maybe<Scalars['String']>;
  sender_favorite_soerah?: Maybe<Scalars['String']>;
  sender_favorite_sport?: Maybe<Scalars['String']>;
  sender_hero?: Maybe<Scalars['String']>;
  sender_hobbies?: Maybe<Scalars['String']>;
  sender_id?: Maybe<Scalars['uuid']>;
  sender_joining_date?: Maybe<Scalars['timestamptz']>;
  sender_learned_achievements?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
  sender_stars?: Maybe<Scalars['bigint']>;
  sender_thankfull_for?: Maybe<Scalars['String']>;
  sender_username?: Maybe<Scalars['String']>;
  sender_wish?: Maybe<Scalars['String']>;
}

/** aggregated selection of "friend_requests_view" */
export interface IFriendRequestsViewAggregate {
  __typename?: 'friend_requests_view_aggregate';
  aggregate?: Maybe<IFriendRequestsViewAggregateFields>;
  nodes: Array<IFriendRequestsView>;
}

/** aggregate fields of "friend_requests_view" */
export interface IFriendRequestsViewAggregateFields {
  __typename?: 'friend_requests_view_aggregate_fields';
  avg?: Maybe<IFriendRequestsViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IFriendRequestsViewMaxFields>;
  min?: Maybe<IFriendRequestsViewMinFields>;
  stddev?: Maybe<IFriendRequestsViewStddevFields>;
  stddev_pop?: Maybe<IFriendRequestsViewStddevPopFields>;
  stddev_samp?: Maybe<IFriendRequestsViewStddevSampFields>;
  sum?: Maybe<IFriendRequestsViewSumFields>;
  var_pop?: Maybe<IFriendRequestsViewVarPopFields>;
  var_samp?: Maybe<IFriendRequestsViewVarSampFields>;
  variance?: Maybe<IFriendRequestsViewVarianceFields>;
}


/** aggregate fields of "friend_requests_view" */
export interface IFriendRequestsViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IFriendRequestsViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IFriendRequestsViewAvgFields {
  __typename?: 'friend_requests_view_avg_fields';
  receiver_details_id?: Maybe<Scalars['Float']>;
  receiver_stars?: Maybe<Scalars['Float']>;
  sender_details_id?: Maybe<Scalars['Float']>;
  sender_stars?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "friend_requests_view". All fields are combined with a logical 'AND'. */
export interface IFriendRequestsViewBoolExp {
  _and?: InputMaybe<Array<IFriendRequestsViewBoolExp>>;
  _not?: InputMaybe<IFriendRequestsViewBoolExp>;
  _or?: InputMaybe<Array<IFriendRequestsViewBoolExp>>;
  accepted?: InputMaybe<IBooleanComparisonExp>;
  friend_request_id?: InputMaybe<IUuidComparisonExp>;
  receiver?: InputMaybe<IUsersChildrenBoolExp>;
  receiver_avatar?: InputMaybe<IStringComparisonExp>;
  receiver_details?: InputMaybe<IUsersChildDetailsBoolExp>;
  receiver_details_id?: InputMaybe<IIntComparisonExp>;
  receiver_dream?: InputMaybe<IStringComparisonExp>;
  receiver_dream_job?: InputMaybe<IStringComparisonExp>;
  receiver_favorite_animal?: InputMaybe<IStringComparisonExp>;
  receiver_favorite_book?: InputMaybe<IStringComparisonExp>;
  receiver_favorite_color?: InputMaybe<IStringComparisonExp>;
  receiver_favorite_film?: InputMaybe<IStringComparisonExp>;
  receiver_favorite_food?: InputMaybe<IStringComparisonExp>;
  receiver_favorite_lesson?: InputMaybe<IStringComparisonExp>;
  receiver_favorite_school_subject?: InputMaybe<IStringComparisonExp>;
  receiver_favorite_soerah?: InputMaybe<IStringComparisonExp>;
  receiver_favorite_sport?: InputMaybe<IStringComparisonExp>;
  receiver_hero?: InputMaybe<IStringComparisonExp>;
  receiver_hobbies?: InputMaybe<IStringComparisonExp>;
  receiver_id?: InputMaybe<IUuidComparisonExp>;
  receiver_joining_date?: InputMaybe<ITimestamptzComparisonExp>;
  receiver_learned_achievements?: InputMaybe<IStringComparisonExp>;
  receiver_name?: InputMaybe<IStringComparisonExp>;
  receiver_stars?: InputMaybe<IBigintComparisonExp>;
  receiver_thankfull_for?: InputMaybe<IStringComparisonExp>;
  receiver_username?: InputMaybe<IStringComparisonExp>;
  receiver_wish?: InputMaybe<IStringComparisonExp>;
  sender?: InputMaybe<IUsersChildrenBoolExp>;
  sender_avatar?: InputMaybe<IStringComparisonExp>;
  sender_details?: InputMaybe<IUsersChildDetailsBoolExp>;
  sender_details_id?: InputMaybe<IIntComparisonExp>;
  sender_dream?: InputMaybe<IStringComparisonExp>;
  sender_dream_job?: InputMaybe<IStringComparisonExp>;
  sender_favorite_animal?: InputMaybe<IStringComparisonExp>;
  sender_favorite_book?: InputMaybe<IStringComparisonExp>;
  sender_favorite_color?: InputMaybe<IStringComparisonExp>;
  sender_favorite_film?: InputMaybe<IStringComparisonExp>;
  sender_favorite_food?: InputMaybe<IStringComparisonExp>;
  sender_favorite_lesson?: InputMaybe<IStringComparisonExp>;
  sender_favorite_school_subject?: InputMaybe<IStringComparisonExp>;
  sender_favorite_soerah?: InputMaybe<IStringComparisonExp>;
  sender_favorite_sport?: InputMaybe<IStringComparisonExp>;
  sender_hero?: InputMaybe<IStringComparisonExp>;
  sender_hobbies?: InputMaybe<IStringComparisonExp>;
  sender_id?: InputMaybe<IUuidComparisonExp>;
  sender_joining_date?: InputMaybe<ITimestamptzComparisonExp>;
  sender_learned_achievements?: InputMaybe<IStringComparisonExp>;
  sender_name?: InputMaybe<IStringComparisonExp>;
  sender_stars?: InputMaybe<IBigintComparisonExp>;
  sender_thankfull_for?: InputMaybe<IStringComparisonExp>;
  sender_username?: InputMaybe<IStringComparisonExp>;
  sender_wish?: InputMaybe<IStringComparisonExp>;
}

/** aggregate max on columns */
export interface IFriendRequestsViewMaxFields {
  __typename?: 'friend_requests_view_max_fields';
  friend_request_id?: Maybe<Scalars['uuid']>;
  receiver_avatar?: Maybe<Scalars['String']>;
  receiver_details_id?: Maybe<Scalars['Int']>;
  receiver_dream?: Maybe<Scalars['String']>;
  receiver_dream_job?: Maybe<Scalars['String']>;
  receiver_favorite_animal?: Maybe<Scalars['String']>;
  receiver_favorite_book?: Maybe<Scalars['String']>;
  receiver_favorite_color?: Maybe<Scalars['String']>;
  receiver_favorite_film?: Maybe<Scalars['String']>;
  receiver_favorite_food?: Maybe<Scalars['String']>;
  receiver_favorite_lesson?: Maybe<Scalars['String']>;
  receiver_favorite_school_subject?: Maybe<Scalars['String']>;
  receiver_favorite_soerah?: Maybe<Scalars['String']>;
  receiver_favorite_sport?: Maybe<Scalars['String']>;
  receiver_hero?: Maybe<Scalars['String']>;
  receiver_hobbies?: Maybe<Scalars['String']>;
  receiver_id?: Maybe<Scalars['uuid']>;
  receiver_joining_date?: Maybe<Scalars['timestamptz']>;
  receiver_learned_achievements?: Maybe<Scalars['String']>;
  receiver_name?: Maybe<Scalars['String']>;
  receiver_stars?: Maybe<Scalars['bigint']>;
  receiver_thankfull_for?: Maybe<Scalars['String']>;
  receiver_username?: Maybe<Scalars['String']>;
  receiver_wish?: Maybe<Scalars['String']>;
  sender_avatar?: Maybe<Scalars['String']>;
  sender_details_id?: Maybe<Scalars['Int']>;
  sender_dream?: Maybe<Scalars['String']>;
  sender_dream_job?: Maybe<Scalars['String']>;
  sender_favorite_animal?: Maybe<Scalars['String']>;
  sender_favorite_book?: Maybe<Scalars['String']>;
  sender_favorite_color?: Maybe<Scalars['String']>;
  sender_favorite_film?: Maybe<Scalars['String']>;
  sender_favorite_food?: Maybe<Scalars['String']>;
  sender_favorite_lesson?: Maybe<Scalars['String']>;
  sender_favorite_school_subject?: Maybe<Scalars['String']>;
  sender_favorite_soerah?: Maybe<Scalars['String']>;
  sender_favorite_sport?: Maybe<Scalars['String']>;
  sender_hero?: Maybe<Scalars['String']>;
  sender_hobbies?: Maybe<Scalars['String']>;
  sender_id?: Maybe<Scalars['uuid']>;
  sender_joining_date?: Maybe<Scalars['timestamptz']>;
  sender_learned_achievements?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
  sender_stars?: Maybe<Scalars['bigint']>;
  sender_thankfull_for?: Maybe<Scalars['String']>;
  sender_username?: Maybe<Scalars['String']>;
  sender_wish?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IFriendRequestsViewMinFields {
  __typename?: 'friend_requests_view_min_fields';
  friend_request_id?: Maybe<Scalars['uuid']>;
  receiver_avatar?: Maybe<Scalars['String']>;
  receiver_details_id?: Maybe<Scalars['Int']>;
  receiver_dream?: Maybe<Scalars['String']>;
  receiver_dream_job?: Maybe<Scalars['String']>;
  receiver_favorite_animal?: Maybe<Scalars['String']>;
  receiver_favorite_book?: Maybe<Scalars['String']>;
  receiver_favorite_color?: Maybe<Scalars['String']>;
  receiver_favorite_film?: Maybe<Scalars['String']>;
  receiver_favorite_food?: Maybe<Scalars['String']>;
  receiver_favorite_lesson?: Maybe<Scalars['String']>;
  receiver_favorite_school_subject?: Maybe<Scalars['String']>;
  receiver_favorite_soerah?: Maybe<Scalars['String']>;
  receiver_favorite_sport?: Maybe<Scalars['String']>;
  receiver_hero?: Maybe<Scalars['String']>;
  receiver_hobbies?: Maybe<Scalars['String']>;
  receiver_id?: Maybe<Scalars['uuid']>;
  receiver_joining_date?: Maybe<Scalars['timestamptz']>;
  receiver_learned_achievements?: Maybe<Scalars['String']>;
  receiver_name?: Maybe<Scalars['String']>;
  receiver_stars?: Maybe<Scalars['bigint']>;
  receiver_thankfull_for?: Maybe<Scalars['String']>;
  receiver_username?: Maybe<Scalars['String']>;
  receiver_wish?: Maybe<Scalars['String']>;
  sender_avatar?: Maybe<Scalars['String']>;
  sender_details_id?: Maybe<Scalars['Int']>;
  sender_dream?: Maybe<Scalars['String']>;
  sender_dream_job?: Maybe<Scalars['String']>;
  sender_favorite_animal?: Maybe<Scalars['String']>;
  sender_favorite_book?: Maybe<Scalars['String']>;
  sender_favorite_color?: Maybe<Scalars['String']>;
  sender_favorite_film?: Maybe<Scalars['String']>;
  sender_favorite_food?: Maybe<Scalars['String']>;
  sender_favorite_lesson?: Maybe<Scalars['String']>;
  sender_favorite_school_subject?: Maybe<Scalars['String']>;
  sender_favorite_soerah?: Maybe<Scalars['String']>;
  sender_favorite_sport?: Maybe<Scalars['String']>;
  sender_hero?: Maybe<Scalars['String']>;
  sender_hobbies?: Maybe<Scalars['String']>;
  sender_id?: Maybe<Scalars['uuid']>;
  sender_joining_date?: Maybe<Scalars['timestamptz']>;
  sender_learned_achievements?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
  sender_stars?: Maybe<Scalars['bigint']>;
  sender_thankfull_for?: Maybe<Scalars['String']>;
  sender_username?: Maybe<Scalars['String']>;
  sender_wish?: Maybe<Scalars['String']>;
}

/** Ordering options when selecting data from "friend_requests_view". */
export interface IFriendRequestsViewOrderBy {
  accepted?: InputMaybe<IOrderBy>;
  friend_request_id?: InputMaybe<IOrderBy>;
  receiver?: InputMaybe<IUsersChildrenOrderBy>;
  receiver_avatar?: InputMaybe<IOrderBy>;
  receiver_details?: InputMaybe<IUsersChildDetailsOrderBy>;
  receiver_details_id?: InputMaybe<IOrderBy>;
  receiver_dream?: InputMaybe<IOrderBy>;
  receiver_dream_job?: InputMaybe<IOrderBy>;
  receiver_favorite_animal?: InputMaybe<IOrderBy>;
  receiver_favorite_book?: InputMaybe<IOrderBy>;
  receiver_favorite_color?: InputMaybe<IOrderBy>;
  receiver_favorite_film?: InputMaybe<IOrderBy>;
  receiver_favorite_food?: InputMaybe<IOrderBy>;
  receiver_favorite_lesson?: InputMaybe<IOrderBy>;
  receiver_favorite_school_subject?: InputMaybe<IOrderBy>;
  receiver_favorite_soerah?: InputMaybe<IOrderBy>;
  receiver_favorite_sport?: InputMaybe<IOrderBy>;
  receiver_hero?: InputMaybe<IOrderBy>;
  receiver_hobbies?: InputMaybe<IOrderBy>;
  receiver_id?: InputMaybe<IOrderBy>;
  receiver_joining_date?: InputMaybe<IOrderBy>;
  receiver_learned_achievements?: InputMaybe<IOrderBy>;
  receiver_name?: InputMaybe<IOrderBy>;
  receiver_stars?: InputMaybe<IOrderBy>;
  receiver_thankfull_for?: InputMaybe<IOrderBy>;
  receiver_username?: InputMaybe<IOrderBy>;
  receiver_wish?: InputMaybe<IOrderBy>;
  sender?: InputMaybe<IUsersChildrenOrderBy>;
  sender_avatar?: InputMaybe<IOrderBy>;
  sender_details?: InputMaybe<IUsersChildDetailsOrderBy>;
  sender_details_id?: InputMaybe<IOrderBy>;
  sender_dream?: InputMaybe<IOrderBy>;
  sender_dream_job?: InputMaybe<IOrderBy>;
  sender_favorite_animal?: InputMaybe<IOrderBy>;
  sender_favorite_book?: InputMaybe<IOrderBy>;
  sender_favorite_color?: InputMaybe<IOrderBy>;
  sender_favorite_film?: InputMaybe<IOrderBy>;
  sender_favorite_food?: InputMaybe<IOrderBy>;
  sender_favorite_lesson?: InputMaybe<IOrderBy>;
  sender_favorite_school_subject?: InputMaybe<IOrderBy>;
  sender_favorite_soerah?: InputMaybe<IOrderBy>;
  sender_favorite_sport?: InputMaybe<IOrderBy>;
  sender_hero?: InputMaybe<IOrderBy>;
  sender_hobbies?: InputMaybe<IOrderBy>;
  sender_id?: InputMaybe<IOrderBy>;
  sender_joining_date?: InputMaybe<IOrderBy>;
  sender_learned_achievements?: InputMaybe<IOrderBy>;
  sender_name?: InputMaybe<IOrderBy>;
  sender_stars?: InputMaybe<IOrderBy>;
  sender_thankfull_for?: InputMaybe<IOrderBy>;
  sender_username?: InputMaybe<IOrderBy>;
  sender_wish?: InputMaybe<IOrderBy>;
}

/** select columns of table "friend_requests_view" */
export enum IFriendRequestsViewSelectColumn {
  /** column name */
  ACCEPTED = 'accepted',
  /** column name */
  FRIEND_REQUEST_ID = 'friend_request_id',
  /** column name */
  RECEIVER_AVATAR = 'receiver_avatar',
  /** column name */
  RECEIVER_DETAILS_ID = 'receiver_details_id',
  /** column name */
  RECEIVER_DREAM = 'receiver_dream',
  /** column name */
  RECEIVER_DREAM_JOB = 'receiver_dream_job',
  /** column name */
  RECEIVER_FAVORITE_ANIMAL = 'receiver_favorite_animal',
  /** column name */
  RECEIVER_FAVORITE_BOOK = 'receiver_favorite_book',
  /** column name */
  RECEIVER_FAVORITE_COLOR = 'receiver_favorite_color',
  /** column name */
  RECEIVER_FAVORITE_FILM = 'receiver_favorite_film',
  /** column name */
  RECEIVER_FAVORITE_FOOD = 'receiver_favorite_food',
  /** column name */
  RECEIVER_FAVORITE_LESSON = 'receiver_favorite_lesson',
  /** column name */
  RECEIVER_FAVORITE_SCHOOL_SUBJECT = 'receiver_favorite_school_subject',
  /** column name */
  RECEIVER_FAVORITE_SOERAH = 'receiver_favorite_soerah',
  /** column name */
  RECEIVER_FAVORITE_SPORT = 'receiver_favorite_sport',
  /** column name */
  RECEIVER_HERO = 'receiver_hero',
  /** column name */
  RECEIVER_HOBBIES = 'receiver_hobbies',
  /** column name */
  RECEIVER_ID = 'receiver_id',
  /** column name */
  RECEIVER_JOINING_DATE = 'receiver_joining_date',
  /** column name */
  RECEIVER_LEARNED_ACHIEVEMENTS = 'receiver_learned_achievements',
  /** column name */
  RECEIVER_NAME = 'receiver_name',
  /** column name */
  RECEIVER_STARS = 'receiver_stars',
  /** column name */
  RECEIVER_THANKFULL_FOR = 'receiver_thankfull_for',
  /** column name */
  RECEIVER_USERNAME = 'receiver_username',
  /** column name */
  RECEIVER_WISH = 'receiver_wish',
  /** column name */
  SENDER_AVATAR = 'sender_avatar',
  /** column name */
  SENDER_DETAILS_ID = 'sender_details_id',
  /** column name */
  SENDER_DREAM = 'sender_dream',
  /** column name */
  SENDER_DREAM_JOB = 'sender_dream_job',
  /** column name */
  SENDER_FAVORITE_ANIMAL = 'sender_favorite_animal',
  /** column name */
  SENDER_FAVORITE_BOOK = 'sender_favorite_book',
  /** column name */
  SENDER_FAVORITE_COLOR = 'sender_favorite_color',
  /** column name */
  SENDER_FAVORITE_FILM = 'sender_favorite_film',
  /** column name */
  SENDER_FAVORITE_FOOD = 'sender_favorite_food',
  /** column name */
  SENDER_FAVORITE_LESSON = 'sender_favorite_lesson',
  /** column name */
  SENDER_FAVORITE_SCHOOL_SUBJECT = 'sender_favorite_school_subject',
  /** column name */
  SENDER_FAVORITE_SOERAH = 'sender_favorite_soerah',
  /** column name */
  SENDER_FAVORITE_SPORT = 'sender_favorite_sport',
  /** column name */
  SENDER_HERO = 'sender_hero',
  /** column name */
  SENDER_HOBBIES = 'sender_hobbies',
  /** column name */
  SENDER_ID = 'sender_id',
  /** column name */
  SENDER_JOINING_DATE = 'sender_joining_date',
  /** column name */
  SENDER_LEARNED_ACHIEVEMENTS = 'sender_learned_achievements',
  /** column name */
  SENDER_NAME = 'sender_name',
  /** column name */
  SENDER_STARS = 'sender_stars',
  /** column name */
  SENDER_THANKFULL_FOR = 'sender_thankfull_for',
  /** column name */
  SENDER_USERNAME = 'sender_username',
  /** column name */
  SENDER_WISH = 'sender_wish'
}

/** aggregate stddev on columns */
export interface IFriendRequestsViewStddevFields {
  __typename?: 'friend_requests_view_stddev_fields';
  receiver_details_id?: Maybe<Scalars['Float']>;
  receiver_stars?: Maybe<Scalars['Float']>;
  sender_details_id?: Maybe<Scalars['Float']>;
  sender_stars?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IFriendRequestsViewStddevPopFields {
  __typename?: 'friend_requests_view_stddev_pop_fields';
  receiver_details_id?: Maybe<Scalars['Float']>;
  receiver_stars?: Maybe<Scalars['Float']>;
  sender_details_id?: Maybe<Scalars['Float']>;
  sender_stars?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IFriendRequestsViewStddevSampFields {
  __typename?: 'friend_requests_view_stddev_samp_fields';
  receiver_details_id?: Maybe<Scalars['Float']>;
  receiver_stars?: Maybe<Scalars['Float']>;
  sender_details_id?: Maybe<Scalars['Float']>;
  sender_stars?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IFriendRequestsViewSumFields {
  __typename?: 'friend_requests_view_sum_fields';
  receiver_details_id?: Maybe<Scalars['Int']>;
  receiver_stars?: Maybe<Scalars['bigint']>;
  sender_details_id?: Maybe<Scalars['Int']>;
  sender_stars?: Maybe<Scalars['bigint']>;
}

/** aggregate var_pop on columns */
export interface IFriendRequestsViewVarPopFields {
  __typename?: 'friend_requests_view_var_pop_fields';
  receiver_details_id?: Maybe<Scalars['Float']>;
  receiver_stars?: Maybe<Scalars['Float']>;
  sender_details_id?: Maybe<Scalars['Float']>;
  sender_stars?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IFriendRequestsViewVarSampFields {
  __typename?: 'friend_requests_view_var_samp_fields';
  receiver_details_id?: Maybe<Scalars['Float']>;
  receiver_stars?: Maybe<Scalars['Float']>;
  sender_details_id?: Maybe<Scalars['Float']>;
  sender_stars?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IFriendRequestsViewVarianceFields {
  __typename?: 'friend_requests_view_variance_fields';
  receiver_details_id?: Maybe<Scalars['Float']>;
  receiver_stars?: Maybe<Scalars['Float']>;
  sender_details_id?: Maybe<Scalars['Float']>;
  sender_stars?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "friends_view" */
export interface IFriendsView {
  __typename?: 'friends_view';
  avatar?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  stars?: Maybe<Scalars['bigint']>;
  username?: Maybe<Scalars['String']>;
}

/** aggregated selection of "friends_view" */
export interface IFriendsViewAggregate {
  __typename?: 'friends_view_aggregate';
  aggregate?: Maybe<IFriendsViewAggregateFields>;
  nodes: Array<IFriendsView>;
}

/** aggregate fields of "friends_view" */
export interface IFriendsViewAggregateFields {
  __typename?: 'friends_view_aggregate_fields';
  avg?: Maybe<IFriendsViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IFriendsViewMaxFields>;
  min?: Maybe<IFriendsViewMinFields>;
  stddev?: Maybe<IFriendsViewStddevFields>;
  stddev_pop?: Maybe<IFriendsViewStddevPopFields>;
  stddev_samp?: Maybe<IFriendsViewStddevSampFields>;
  sum?: Maybe<IFriendsViewSumFields>;
  var_pop?: Maybe<IFriendsViewVarPopFields>;
  var_samp?: Maybe<IFriendsViewVarSampFields>;
  variance?: Maybe<IFriendsViewVarianceFields>;
}


/** aggregate fields of "friends_view" */
export interface IFriendsViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IFriendsViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IFriendsViewAvgFields {
  __typename?: 'friends_view_avg_fields';
  stars?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "friends_view". All fields are combined with a logical 'AND'. */
export interface IFriendsViewBoolExp {
  _and?: InputMaybe<Array<IFriendsViewBoolExp>>;
  _not?: InputMaybe<IFriendsViewBoolExp>;
  _or?: InputMaybe<Array<IFriendsViewBoolExp>>;
  avatar?: InputMaybe<IStringComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  stars?: InputMaybe<IBigintComparisonExp>;
  username?: InputMaybe<IStringComparisonExp>;
}

/** aggregate max on columns */
export interface IFriendsViewMaxFields {
  __typename?: 'friends_view_max_fields';
  avatar?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  stars?: Maybe<Scalars['bigint']>;
  username?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IFriendsViewMinFields {
  __typename?: 'friends_view_min_fields';
  avatar?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  stars?: Maybe<Scalars['bigint']>;
  username?: Maybe<Scalars['String']>;
}

/** Ordering options when selecting data from "friends_view". */
export interface IFriendsViewOrderBy {
  avatar?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  stars?: InputMaybe<IOrderBy>;
  username?: InputMaybe<IOrderBy>;
}

/** select columns of table "friends_view" */
export enum IFriendsViewSelectColumn {
  /** column name */
  AVATAR = 'avatar',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  STARS = 'stars',
  /** column name */
  USERNAME = 'username'
}

/** aggregate stddev on columns */
export interface IFriendsViewStddevFields {
  __typename?: 'friends_view_stddev_fields';
  stars?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IFriendsViewStddevPopFields {
  __typename?: 'friends_view_stddev_pop_fields';
  stars?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IFriendsViewStddevSampFields {
  __typename?: 'friends_view_stddev_samp_fields';
  stars?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IFriendsViewSumFields {
  __typename?: 'friends_view_sum_fields';
  stars?: Maybe<Scalars['bigint']>;
}

/** aggregate var_pop on columns */
export interface IFriendsViewVarPopFields {
  __typename?: 'friends_view_var_pop_fields';
  stars?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IFriendsViewVarSampFields {
  __typename?: 'friends_view_var_samp_fields';
  stars?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IFriendsViewVarianceFields {
  __typename?: 'friends_view_variance_fields';
  stars?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "group_type_attachments" */
export interface IGroupTypeAttachments {
  __typename?: 'group_type_attachments';
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  /** An object relationship */
  group_type?: Maybe<IGroupTypes>;
  group_type_id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
}

/** aggregated selection of "group_type_attachments" */
export interface IGroupTypeAttachmentsAggregate {
  __typename?: 'group_type_attachments_aggregate';
  aggregate?: Maybe<IGroupTypeAttachmentsAggregateFields>;
  nodes: Array<IGroupTypeAttachments>;
}

/** aggregate fields of "group_type_attachments" */
export interface IGroupTypeAttachmentsAggregateFields {
  __typename?: 'group_type_attachments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IGroupTypeAttachmentsMaxFields>;
  min?: Maybe<IGroupTypeAttachmentsMinFields>;
}


/** aggregate fields of "group_type_attachments" */
export interface IGroupTypeAttachmentsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IGroupTypeAttachmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "group_type_attachments". All fields are combined with a logical 'AND'. */
export interface IGroupTypeAttachmentsBoolExp {
  _and?: InputMaybe<Array<IGroupTypeAttachmentsBoolExp>>;
  _not?: InputMaybe<IGroupTypeAttachmentsBoolExp>;
  _or?: InputMaybe<Array<IGroupTypeAttachmentsBoolExp>>;
  created_at?: InputMaybe<ITimestampComparisonExp>;
  filename?: InputMaybe<IStringComparisonExp>;
  group_type?: InputMaybe<IGroupTypesBoolExp>;
  group_type_id?: InputMaybe<IUuidComparisonExp>;
  key?: InputMaybe<IStringComparisonExp>;
}

/** aggregate max on columns */
export interface IGroupTypeAttachmentsMaxFields {
  __typename?: 'group_type_attachments_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  group_type_id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IGroupTypeAttachmentsMinFields {
  __typename?: 'group_type_attachments_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  group_type_id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
}

/** Ordering options when selecting data from "group_type_attachments". */
export interface IGroupTypeAttachmentsOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  filename?: InputMaybe<IOrderBy>;
  group_type?: InputMaybe<IGroupTypesOrderBy>;
  group_type_id?: InputMaybe<IOrderBy>;
  key?: InputMaybe<IOrderBy>;
}

/** select columns of table "group_type_attachments" */
export enum IGroupTypeAttachmentsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  FILENAME = 'filename',
  /** column name */
  GROUP_TYPE_ID = 'group_type_id',
  /** column name */
  KEY = 'key'
}

/** columns and relationships of "group_types" */
export interface IGroupTypes {
  __typename?: 'group_types';
  /** An array relationship */
  additional_lessons_group_types: Array<IAdditionalLessonsGroupTypes>;
  /** An aggregate relationship */
  additional_lessons_group_types_aggregate: IAdditionalLessonsGroupTypesAggregate;
  /** An array relationship */
  course_groups: Array<ICourseGroups>;
  /** An aggregate relationship */
  course_groups_aggregate: ICourseGroupsAggregate;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  events_group_types: Array<IEventsGroupTypes>;
  /** An aggregate relationship */
  events_group_types_aggregate: IEventsGroupTypesAggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  notification_group_types: Array<INotificationGroupTypes>;
  /** An aggregate relationship */
  notification_group_types_aggregate: INotificationGroupTypesAggregate;
  sequence: Scalars['Int'];
  title: Scalars['String'];
  /** An array relationship */
  vacation_group_types: Array<IVacationGroupTypes>;
  /** An aggregate relationship */
  vacation_group_types_aggregate: IVacationGroupTypesAggregate;
}


/** columns and relationships of "group_types" */
export interface IGroupTypesAdditionalLessonsGroupTypesArgs {
  distinct_on?: InputMaybe<Array<IAdditionalLessonsGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAdditionalLessonsGroupTypesOrderBy>>;
  where?: InputMaybe<IAdditionalLessonsGroupTypesBoolExp>;
}


/** columns and relationships of "group_types" */
export interface IGroupTypesAdditionalLessonsGroupTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<IAdditionalLessonsGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAdditionalLessonsGroupTypesOrderBy>>;
  where?: InputMaybe<IAdditionalLessonsGroupTypesBoolExp>;
}


/** columns and relationships of "group_types" */
export interface IGroupTypesCourseGroupsArgs {
  distinct_on?: InputMaybe<Array<ICourseGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ICourseGroupsOrderBy>>;
  where?: InputMaybe<ICourseGroupsBoolExp>;
}


/** columns and relationships of "group_types" */
export interface IGroupTypesCourseGroupsAggregateArgs {
  distinct_on?: InputMaybe<Array<ICourseGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ICourseGroupsOrderBy>>;
  where?: InputMaybe<ICourseGroupsBoolExp>;
}


/** columns and relationships of "group_types" */
export interface IGroupTypesEventsGroupTypesArgs {
  distinct_on?: InputMaybe<Array<IEventsGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventsGroupTypesOrderBy>>;
  where?: InputMaybe<IEventsGroupTypesBoolExp>;
}


/** columns and relationships of "group_types" */
export interface IGroupTypesEventsGroupTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<IEventsGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventsGroupTypesOrderBy>>;
  where?: InputMaybe<IEventsGroupTypesBoolExp>;
}


/** columns and relationships of "group_types" */
export interface IGroupTypesNotificationGroupTypesArgs {
  distinct_on?: InputMaybe<Array<INotificationGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INotificationGroupTypesOrderBy>>;
  where?: InputMaybe<INotificationGroupTypesBoolExp>;
}


/** columns and relationships of "group_types" */
export interface IGroupTypesNotificationGroupTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<INotificationGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INotificationGroupTypesOrderBy>>;
  where?: InputMaybe<INotificationGroupTypesBoolExp>;
}


/** columns and relationships of "group_types" */
export interface IGroupTypesVacationGroupTypesArgs {
  distinct_on?: InputMaybe<Array<IVacationGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IVacationGroupTypesOrderBy>>;
  where?: InputMaybe<IVacationGroupTypesBoolExp>;
}


/** columns and relationships of "group_types" */
export interface IGroupTypesVacationGroupTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<IVacationGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IVacationGroupTypesOrderBy>>;
  where?: InputMaybe<IVacationGroupTypesBoolExp>;
}

/** aggregated selection of "group_types" */
export interface IGroupTypesAggregate {
  __typename?: 'group_types_aggregate';
  aggregate?: Maybe<IGroupTypesAggregateFields>;
  nodes: Array<IGroupTypes>;
}

/** aggregate fields of "group_types" */
export interface IGroupTypesAggregateFields {
  __typename?: 'group_types_aggregate_fields';
  avg?: Maybe<IGroupTypesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IGroupTypesMaxFields>;
  min?: Maybe<IGroupTypesMinFields>;
  stddev?: Maybe<IGroupTypesStddevFields>;
  stddev_pop?: Maybe<IGroupTypesStddevPopFields>;
  stddev_samp?: Maybe<IGroupTypesStddevSampFields>;
  sum?: Maybe<IGroupTypesSumFields>;
  var_pop?: Maybe<IGroupTypesVarPopFields>;
  var_samp?: Maybe<IGroupTypesVarSampFields>;
  variance?: Maybe<IGroupTypesVarianceFields>;
}


/** aggregate fields of "group_types" */
export interface IGroupTypesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IGroupTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IGroupTypesAvgFields {
  __typename?: 'group_types_avg_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "group_types". All fields are combined with a logical 'AND'. */
export interface IGroupTypesBoolExp {
  _and?: InputMaybe<Array<IGroupTypesBoolExp>>;
  _not?: InputMaybe<IGroupTypesBoolExp>;
  _or?: InputMaybe<Array<IGroupTypesBoolExp>>;
  additional_lessons_group_types?: InputMaybe<IAdditionalLessonsGroupTypesBoolExp>;
  course_groups?: InputMaybe<ICourseGroupsBoolExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  events_group_types?: InputMaybe<IEventsGroupTypesBoolExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  notification_group_types?: InputMaybe<INotificationGroupTypesBoolExp>;
  sequence?: InputMaybe<IIntComparisonExp>;
  title?: InputMaybe<IStringComparisonExp>;
  vacation_group_types?: InputMaybe<IVacationGroupTypesBoolExp>;
}

/** unique or primary key constraints on table "group_types" */
export enum IGroupTypesConstraint {
  /** unique or primary key constraint */
  GROUP_TYPES_PKEY = 'group_types_pkey',
  /** unique or primary key constraint */
  GROUP_TYPES_TITLE_SEQUENCE_KEY = 'group_types_title_sequence_key'
}

/** input type for incrementing numeric columns in table "group_types" */
export interface IGroupTypesIncInput {
  sequence?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "group_types" */
export interface IGroupTypesInsertInput {
  additional_lessons_group_types?: InputMaybe<IAdditionalLessonsGroupTypesArrRelInsertInput>;
  course_groups?: InputMaybe<ICourseGroupsArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  events_group_types?: InputMaybe<IEventsGroupTypesArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  notification_group_types?: InputMaybe<INotificationGroupTypesArrRelInsertInput>;
  sequence?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  vacation_group_types?: InputMaybe<IVacationGroupTypesArrRelInsertInput>;
}

/** aggregate max on columns */
export interface IGroupTypesMaxFields {
  __typename?: 'group_types_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IGroupTypesMinFields {
  __typename?: 'group_types_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "group_types" */
export interface IGroupTypesMutationResponse {
  __typename?: 'group_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IGroupTypes>;
}

/** input type for inserting object relation for remote table "group_types" */
export interface IGroupTypesObjRelInsertInput {
  data: IGroupTypesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IGroupTypesOnConflict>;
}

/** on_conflict condition type for table "group_types" */
export interface IGroupTypesOnConflict {
  constraint: IGroupTypesConstraint;
  update_columns?: Array<IGroupTypesUpdateColumn>;
  where?: InputMaybe<IGroupTypesBoolExp>;
}

/** Ordering options when selecting data from "group_types". */
export interface IGroupTypesOrderBy {
  additional_lessons_group_types_aggregate?: InputMaybe<IAdditionalLessonsGroupTypesAggregateOrderBy>;
  course_groups_aggregate?: InputMaybe<ICourseGroupsAggregateOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  events_group_types_aggregate?: InputMaybe<IEventsGroupTypesAggregateOrderBy>;
  id?: InputMaybe<IOrderBy>;
  notification_group_types_aggregate?: InputMaybe<INotificationGroupTypesAggregateOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  title?: InputMaybe<IOrderBy>;
  vacation_group_types_aggregate?: InputMaybe<IVacationGroupTypesAggregateOrderBy>;
}

/** primary key columns input for table: group_types */
export interface IGroupTypesPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "group_types" */
export enum IGroupTypesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  TITLE = 'title'
}

/** input type for updating data in table "group_types" */
export interface IGroupTypesSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sequence?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface IGroupTypesStddevFields {
  __typename?: 'group_types_stddev_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IGroupTypesStddevPopFields {
  __typename?: 'group_types_stddev_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IGroupTypesStddevSampFields {
  __typename?: 'group_types_stddev_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IGroupTypesSumFields {
  __typename?: 'group_types_sum_fields';
  sequence?: Maybe<Scalars['Int']>;
}

/** update columns of table "group_types" */
export enum IGroupTypesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  TITLE = 'title'
}

/** aggregate var_pop on columns */
export interface IGroupTypesVarPopFields {
  __typename?: 'group_types_var_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IGroupTypesVarSampFields {
  __typename?: 'group_types_var_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IGroupTypesVarianceFields {
  __typename?: 'group_types_variance_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** Boolean expression to compare columns of type "interval". All fields are combined with logical 'AND'. */
export interface IIntervalComparisonExp {
  _eq?: InputMaybe<Scalars['interval']>;
  _gt?: InputMaybe<Scalars['interval']>;
  _gte?: InputMaybe<Scalars['interval']>;
  _in?: InputMaybe<Array<Scalars['interval']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['interval']>;
  _lte?: InputMaybe<Scalars['interval']>;
  _neq?: InputMaybe<Scalars['interval']>;
  _nin?: InputMaybe<Array<Scalars['interval']>>;
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export interface IJsonbComparisonExp {
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
}

/** columns and relationships of "kids_maaida" */
export interface IKidsMaaida {
  __typename?: 'kids_maaida';
  auth0_id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  has_maaida_and_kids?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "kids_maaida" */
export interface IKidsMaaidaAggregate {
  __typename?: 'kids_maaida_aggregate';
  aggregate?: Maybe<IKidsMaaidaAggregateFields>;
  nodes: Array<IKidsMaaida>;
}

/** aggregate fields of "kids_maaida" */
export interface IKidsMaaidaAggregateFields {
  __typename?: 'kids_maaida_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IKidsMaaidaMaxFields>;
  min?: Maybe<IKidsMaaidaMinFields>;
}


/** aggregate fields of "kids_maaida" */
export interface IKidsMaaidaAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IKidsMaaidaSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "kids_maaida". All fields are combined with a logical 'AND'. */
export interface IKidsMaaidaBoolExp {
  _and?: InputMaybe<Array<IKidsMaaidaBoolExp>>;
  _not?: InputMaybe<IKidsMaaidaBoolExp>;
  _or?: InputMaybe<Array<IKidsMaaidaBoolExp>>;
  auth0_id?: InputMaybe<IStringComparisonExp>;
  email?: InputMaybe<IStringComparisonExp>;
  has_maaida_and_kids?: InputMaybe<IBooleanComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface IKidsMaaidaMaxFields {
  __typename?: 'kids_maaida_max_fields';
  auth0_id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IKidsMaaidaMinFields {
  __typename?: 'kids_maaida_min_fields';
  auth0_id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "kids_maaida". */
export interface IKidsMaaidaOrderBy {
  auth0_id?: InputMaybe<IOrderBy>;
  email?: InputMaybe<IOrderBy>;
  has_maaida_and_kids?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
}

/** select columns of table "kids_maaida" */
export enum IKidsMaaidaSelectColumn {
  /** column name */
  AUTH0_ID = 'auth0_id',
  /** column name */
  EMAIL = 'email',
  /** column name */
  HAS_MAAIDA_AND_KIDS = 'has_maaida_and_kids',
  /** column name */
  ID = 'id'
}

/** columns and relationships of "knowledge_test_questions" */
export interface IKnowledgeTestQuestions {
  __typename?: 'knowledge_test_questions';
  answer: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  knowledge_test?: Maybe<IKnowledgeTests>;
  knowledge_test_id: Scalars['uuid'];
  sequence: Scalars['Int'];
  subject: Scalars['String'];
  updated_at: Scalars['timestamptz'];
}

/** aggregated selection of "knowledge_test_questions" */
export interface IKnowledgeTestQuestionsAggregate {
  __typename?: 'knowledge_test_questions_aggregate';
  aggregate?: Maybe<IKnowledgeTestQuestionsAggregateFields>;
  nodes: Array<IKnowledgeTestQuestions>;
}

/** aggregate fields of "knowledge_test_questions" */
export interface IKnowledgeTestQuestionsAggregateFields {
  __typename?: 'knowledge_test_questions_aggregate_fields';
  avg?: Maybe<IKnowledgeTestQuestionsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IKnowledgeTestQuestionsMaxFields>;
  min?: Maybe<IKnowledgeTestQuestionsMinFields>;
  stddev?: Maybe<IKnowledgeTestQuestionsStddevFields>;
  stddev_pop?: Maybe<IKnowledgeTestQuestionsStddevPopFields>;
  stddev_samp?: Maybe<IKnowledgeTestQuestionsStddevSampFields>;
  sum?: Maybe<IKnowledgeTestQuestionsSumFields>;
  var_pop?: Maybe<IKnowledgeTestQuestionsVarPopFields>;
  var_samp?: Maybe<IKnowledgeTestQuestionsVarSampFields>;
  variance?: Maybe<IKnowledgeTestQuestionsVarianceFields>;
}


/** aggregate fields of "knowledge_test_questions" */
export interface IKnowledgeTestQuestionsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IKnowledgeTestQuestionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "knowledge_test_questions" */
export interface IKnowledgeTestQuestionsAggregateOrderBy {
  avg?: InputMaybe<IKnowledgeTestQuestionsAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IKnowledgeTestQuestionsMaxOrderBy>;
  min?: InputMaybe<IKnowledgeTestQuestionsMinOrderBy>;
  stddev?: InputMaybe<IKnowledgeTestQuestionsStddevOrderBy>;
  stddev_pop?: InputMaybe<IKnowledgeTestQuestionsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IKnowledgeTestQuestionsStddevSampOrderBy>;
  sum?: InputMaybe<IKnowledgeTestQuestionsSumOrderBy>;
  var_pop?: InputMaybe<IKnowledgeTestQuestionsVarPopOrderBy>;
  var_samp?: InputMaybe<IKnowledgeTestQuestionsVarSampOrderBy>;
  variance?: InputMaybe<IKnowledgeTestQuestionsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "knowledge_test_questions" */
export interface IKnowledgeTestQuestionsArrRelInsertInput {
  data: Array<IKnowledgeTestQuestionsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IKnowledgeTestQuestionsOnConflict>;
}

/** aggregate avg on columns */
export interface IKnowledgeTestQuestionsAvgFields {
  __typename?: 'knowledge_test_questions_avg_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "knowledge_test_questions" */
export interface IKnowledgeTestQuestionsAvgOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "knowledge_test_questions". All fields are combined with a logical 'AND'. */
export interface IKnowledgeTestQuestionsBoolExp {
  _and?: InputMaybe<Array<IKnowledgeTestQuestionsBoolExp>>;
  _not?: InputMaybe<IKnowledgeTestQuestionsBoolExp>;
  _or?: InputMaybe<Array<IKnowledgeTestQuestionsBoolExp>>;
  answer?: InputMaybe<IStringComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  knowledge_test?: InputMaybe<IKnowledgeTestsBoolExp>;
  knowledge_test_id?: InputMaybe<IUuidComparisonExp>;
  sequence?: InputMaybe<IIntComparisonExp>;
  subject?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "knowledge_test_questions" */
export enum IKnowledgeTestQuestionsConstraint {
  /** unique or primary key constraint */
  KNOWLEDGE_TEST_QUESTIONS_PKEY = 'knowledge_test_questions_pkey'
}

/** input type for incrementing numeric columns in table "knowledge_test_questions" */
export interface IKnowledgeTestQuestionsIncInput {
  sequence?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "knowledge_test_questions" */
export interface IKnowledgeTestQuestionsInsertInput {
  answer?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  knowledge_test?: InputMaybe<IKnowledgeTestsObjRelInsertInput>;
  knowledge_test_id?: InputMaybe<Scalars['uuid']>;
  sequence?: InputMaybe<Scalars['Int']>;
  subject?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface IKnowledgeTestQuestionsMaxFields {
  __typename?: 'knowledge_test_questions_max_fields';
  answer?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  knowledge_test_id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by max() on columns of table "knowledge_test_questions" */
export interface IKnowledgeTestQuestionsMaxOrderBy {
  answer?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  knowledge_test_id?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  subject?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IKnowledgeTestQuestionsMinFields {
  __typename?: 'knowledge_test_questions_min_fields';
  answer?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  knowledge_test_id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by min() on columns of table "knowledge_test_questions" */
export interface IKnowledgeTestQuestionsMinOrderBy {
  answer?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  knowledge_test_id?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  subject?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "knowledge_test_questions" */
export interface IKnowledgeTestQuestionsMutationResponse {
  __typename?: 'knowledge_test_questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IKnowledgeTestQuestions>;
}

/** on_conflict condition type for table "knowledge_test_questions" */
export interface IKnowledgeTestQuestionsOnConflict {
  constraint: IKnowledgeTestQuestionsConstraint;
  update_columns?: Array<IKnowledgeTestQuestionsUpdateColumn>;
  where?: InputMaybe<IKnowledgeTestQuestionsBoolExp>;
}

/** Ordering options when selecting data from "knowledge_test_questions". */
export interface IKnowledgeTestQuestionsOrderBy {
  answer?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  knowledge_test?: InputMaybe<IKnowledgeTestsOrderBy>;
  knowledge_test_id?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  subject?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: knowledge_test_questions */
export interface IKnowledgeTestQuestionsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "knowledge_test_questions" */
export enum IKnowledgeTestQuestionsSelectColumn {
  /** column name */
  ANSWER = 'answer',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  KNOWLEDGE_TEST_ID = 'knowledge_test_id',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  SUBJECT = 'subject',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "knowledge_test_questions" */
export interface IKnowledgeTestQuestionsSetInput {
  answer?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  knowledge_test_id?: InputMaybe<Scalars['uuid']>;
  sequence?: InputMaybe<Scalars['Int']>;
  subject?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface IKnowledgeTestQuestionsStddevFields {
  __typename?: 'knowledge_test_questions_stddev_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "knowledge_test_questions" */
export interface IKnowledgeTestQuestionsStddevOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IKnowledgeTestQuestionsStddevPopFields {
  __typename?: 'knowledge_test_questions_stddev_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "knowledge_test_questions" */
export interface IKnowledgeTestQuestionsStddevPopOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IKnowledgeTestQuestionsStddevSampFields {
  __typename?: 'knowledge_test_questions_stddev_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "knowledge_test_questions" */
export interface IKnowledgeTestQuestionsStddevSampOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IKnowledgeTestQuestionsSumFields {
  __typename?: 'knowledge_test_questions_sum_fields';
  sequence?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "knowledge_test_questions" */
export interface IKnowledgeTestQuestionsSumOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** update columns of table "knowledge_test_questions" */
export enum IKnowledgeTestQuestionsUpdateColumn {
  /** column name */
  ANSWER = 'answer',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  KNOWLEDGE_TEST_ID = 'knowledge_test_id',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  SUBJECT = 'subject',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IKnowledgeTestQuestionsVarPopFields {
  __typename?: 'knowledge_test_questions_var_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "knowledge_test_questions" */
export interface IKnowledgeTestQuestionsVarPopOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IKnowledgeTestQuestionsVarSampFields {
  __typename?: 'knowledge_test_questions_var_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "knowledge_test_questions" */
export interface IKnowledgeTestQuestionsVarSampOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IKnowledgeTestQuestionsVarianceFields {
  __typename?: 'knowledge_test_questions_variance_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "knowledge_test_questions" */
export interface IKnowledgeTestQuestionsVarianceOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** columns and relationships of "knowledge_tests" */
export interface IKnowledgeTests {
  __typename?: 'knowledge_tests';
  available_from_lesson: Scalars['Int'];
  content: Scalars['String'];
  course_type: ICourseTypesEnum;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  knowledge_test_questions: Array<IKnowledgeTestQuestions>;
  /** An aggregate relationship */
  knowledge_test_questions_aggregate: IKnowledgeTestQuestionsAggregate;
  sequence: Scalars['Int'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
}


/** columns and relationships of "knowledge_tests" */
export interface IKnowledgeTestsKnowledgeTestQuestionsArgs {
  distinct_on?: InputMaybe<Array<IKnowledgeTestQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IKnowledgeTestQuestionsOrderBy>>;
  where?: InputMaybe<IKnowledgeTestQuestionsBoolExp>;
}


/** columns and relationships of "knowledge_tests" */
export interface IKnowledgeTestsKnowledgeTestQuestionsAggregateArgs {
  distinct_on?: InputMaybe<Array<IKnowledgeTestQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IKnowledgeTestQuestionsOrderBy>>;
  where?: InputMaybe<IKnowledgeTestQuestionsBoolExp>;
}

/** aggregated selection of "knowledge_tests" */
export interface IKnowledgeTestsAggregate {
  __typename?: 'knowledge_tests_aggregate';
  aggregate?: Maybe<IKnowledgeTestsAggregateFields>;
  nodes: Array<IKnowledgeTests>;
}

/** aggregate fields of "knowledge_tests" */
export interface IKnowledgeTestsAggregateFields {
  __typename?: 'knowledge_tests_aggregate_fields';
  avg?: Maybe<IKnowledgeTestsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IKnowledgeTestsMaxFields>;
  min?: Maybe<IKnowledgeTestsMinFields>;
  stddev?: Maybe<IKnowledgeTestsStddevFields>;
  stddev_pop?: Maybe<IKnowledgeTestsStddevPopFields>;
  stddev_samp?: Maybe<IKnowledgeTestsStddevSampFields>;
  sum?: Maybe<IKnowledgeTestsSumFields>;
  var_pop?: Maybe<IKnowledgeTestsVarPopFields>;
  var_samp?: Maybe<IKnowledgeTestsVarSampFields>;
  variance?: Maybe<IKnowledgeTestsVarianceFields>;
}


/** aggregate fields of "knowledge_tests" */
export interface IKnowledgeTestsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IKnowledgeTestsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IKnowledgeTestsAvgFields {
  __typename?: 'knowledge_tests_avg_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "knowledge_tests". All fields are combined with a logical 'AND'. */
export interface IKnowledgeTestsBoolExp {
  _and?: InputMaybe<Array<IKnowledgeTestsBoolExp>>;
  _not?: InputMaybe<IKnowledgeTestsBoolExp>;
  _or?: InputMaybe<Array<IKnowledgeTestsBoolExp>>;
  available_from_lesson?: InputMaybe<IIntComparisonExp>;
  content?: InputMaybe<IStringComparisonExp>;
  course_type?: InputMaybe<ICourseTypesEnumComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  knowledge_test_questions?: InputMaybe<IKnowledgeTestQuestionsBoolExp>;
  sequence?: InputMaybe<IIntComparisonExp>;
  subtitle?: InputMaybe<IStringComparisonExp>;
  title?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "knowledge_tests" */
export enum IKnowledgeTestsConstraint {
  /** unique or primary key constraint */
  KNOWLEDGE_TESTS_PKEY = 'knowledge_tests_pkey'
}

/** input type for incrementing numeric columns in table "knowledge_tests" */
export interface IKnowledgeTestsIncInput {
  available_from_lesson?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "knowledge_tests" */
export interface IKnowledgeTestsInsertInput {
  available_from_lesson?: InputMaybe<Scalars['Int']>;
  content?: InputMaybe<Scalars['String']>;
  course_type?: InputMaybe<ICourseTypesEnum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  knowledge_test_questions?: InputMaybe<IKnowledgeTestQuestionsArrRelInsertInput>;
  sequence?: InputMaybe<Scalars['Int']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface IKnowledgeTestsMaxFields {
  __typename?: 'knowledge_tests_max_fields';
  available_from_lesson?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['Int']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface IKnowledgeTestsMinFields {
  __typename?: 'knowledge_tests_min_fields';
  available_from_lesson?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['Int']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** response of any mutation on the table "knowledge_tests" */
export interface IKnowledgeTestsMutationResponse {
  __typename?: 'knowledge_tests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IKnowledgeTests>;
}

/** input type for inserting object relation for remote table "knowledge_tests" */
export interface IKnowledgeTestsObjRelInsertInput {
  data: IKnowledgeTestsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IKnowledgeTestsOnConflict>;
}

/** on_conflict condition type for table "knowledge_tests" */
export interface IKnowledgeTestsOnConflict {
  constraint: IKnowledgeTestsConstraint;
  update_columns?: Array<IKnowledgeTestsUpdateColumn>;
  where?: InputMaybe<IKnowledgeTestsBoolExp>;
}

/** Ordering options when selecting data from "knowledge_tests". */
export interface IKnowledgeTestsOrderBy {
  available_from_lesson?: InputMaybe<IOrderBy>;
  content?: InputMaybe<IOrderBy>;
  course_type?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  knowledge_test_questions_aggregate?: InputMaybe<IKnowledgeTestQuestionsAggregateOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  subtitle?: InputMaybe<IOrderBy>;
  title?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: knowledge_tests */
export interface IKnowledgeTestsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "knowledge_tests" */
export enum IKnowledgeTestsSelectColumn {
  /** column name */
  AVAILABLE_FROM_LESSON = 'available_from_lesson',
  /** column name */
  CONTENT = 'content',
  /** column name */
  COURSE_TYPE = 'course_type',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  SUBTITLE = 'subtitle',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "knowledge_tests" */
export interface IKnowledgeTestsSetInput {
  available_from_lesson?: InputMaybe<Scalars['Int']>;
  content?: InputMaybe<Scalars['String']>;
  course_type?: InputMaybe<ICourseTypesEnum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sequence?: InputMaybe<Scalars['Int']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface IKnowledgeTestsStddevFields {
  __typename?: 'knowledge_tests_stddev_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IKnowledgeTestsStddevPopFields {
  __typename?: 'knowledge_tests_stddev_pop_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IKnowledgeTestsStddevSampFields {
  __typename?: 'knowledge_tests_stddev_samp_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IKnowledgeTestsSumFields {
  __typename?: 'knowledge_tests_sum_fields';
  available_from_lesson?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
}

/** update columns of table "knowledge_tests" */
export enum IKnowledgeTestsUpdateColumn {
  /** column name */
  AVAILABLE_FROM_LESSON = 'available_from_lesson',
  /** column name */
  CONTENT = 'content',
  /** column name */
  COURSE_TYPE = 'course_type',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  SUBTITLE = 'subtitle',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IKnowledgeTestsVarPopFields {
  __typename?: 'knowledge_tests_var_pop_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IKnowledgeTestsVarSampFields {
  __typename?: 'knowledge_tests_var_samp_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IKnowledgeTestsVarianceFields {
  __typename?: 'knowledge_tests_variance_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "knowledge_tests_view" */
export interface IKnowledgeTestsView {
  __typename?: 'knowledge_tests_view';
  available_from_lesson?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  course_id?: Maybe<Scalars['uuid']>;
  is_available?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  knowledge_test?: Maybe<IKnowledgeTests>;
  knowledge_test_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  knowledge_test_questions: Array<IKnowledgeTestQuestions>;
  /** An aggregate relationship */
  knowledge_test_questions_aggregate: IKnowledgeTestQuestionsAggregate;
  sequence?: Maybe<Scalars['Int']>;
  /** An object relationship */
  subscription?: Maybe<ISubscriptions>;
  subscription_id?: Maybe<Scalars['uuid']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id?: Maybe<Scalars['uuid']>;
}


/** columns and relationships of "knowledge_tests_view" */
export interface IKnowledgeTestsViewKnowledgeTestQuestionsArgs {
  distinct_on?: InputMaybe<Array<IKnowledgeTestQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IKnowledgeTestQuestionsOrderBy>>;
  where?: InputMaybe<IKnowledgeTestQuestionsBoolExp>;
}


/** columns and relationships of "knowledge_tests_view" */
export interface IKnowledgeTestsViewKnowledgeTestQuestionsAggregateArgs {
  distinct_on?: InputMaybe<Array<IKnowledgeTestQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IKnowledgeTestQuestionsOrderBy>>;
  where?: InputMaybe<IKnowledgeTestQuestionsBoolExp>;
}

/** aggregated selection of "knowledge_tests_view" */
export interface IKnowledgeTestsViewAggregate {
  __typename?: 'knowledge_tests_view_aggregate';
  aggregate?: Maybe<IKnowledgeTestsViewAggregateFields>;
  nodes: Array<IKnowledgeTestsView>;
}

/** aggregate fields of "knowledge_tests_view" */
export interface IKnowledgeTestsViewAggregateFields {
  __typename?: 'knowledge_tests_view_aggregate_fields';
  avg?: Maybe<IKnowledgeTestsViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IKnowledgeTestsViewMaxFields>;
  min?: Maybe<IKnowledgeTestsViewMinFields>;
  stddev?: Maybe<IKnowledgeTestsViewStddevFields>;
  stddev_pop?: Maybe<IKnowledgeTestsViewStddevPopFields>;
  stddev_samp?: Maybe<IKnowledgeTestsViewStddevSampFields>;
  sum?: Maybe<IKnowledgeTestsViewSumFields>;
  var_pop?: Maybe<IKnowledgeTestsViewVarPopFields>;
  var_samp?: Maybe<IKnowledgeTestsViewVarSampFields>;
  variance?: Maybe<IKnowledgeTestsViewVarianceFields>;
}


/** aggregate fields of "knowledge_tests_view" */
export interface IKnowledgeTestsViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IKnowledgeTestsViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IKnowledgeTestsViewAvgFields {
  __typename?: 'knowledge_tests_view_avg_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "knowledge_tests_view". All fields are combined with a logical 'AND'. */
export interface IKnowledgeTestsViewBoolExp {
  _and?: InputMaybe<Array<IKnowledgeTestsViewBoolExp>>;
  _not?: InputMaybe<IKnowledgeTestsViewBoolExp>;
  _or?: InputMaybe<Array<IKnowledgeTestsViewBoolExp>>;
  available_from_lesson?: InputMaybe<IIntComparisonExp>;
  content?: InputMaybe<IStringComparisonExp>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  is_available?: InputMaybe<IBooleanComparisonExp>;
  knowledge_test?: InputMaybe<IKnowledgeTestsBoolExp>;
  knowledge_test_id?: InputMaybe<IUuidComparisonExp>;
  knowledge_test_questions?: InputMaybe<IKnowledgeTestQuestionsBoolExp>;
  sequence?: InputMaybe<IIntComparisonExp>;
  subscription?: InputMaybe<ISubscriptionsBoolExp>;
  subscription_id?: InputMaybe<IUuidComparisonExp>;
  subtitle?: InputMaybe<IStringComparisonExp>;
  title?: InputMaybe<IStringComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface IKnowledgeTestsViewMaxFields {
  __typename?: 'knowledge_tests_view_max_fields';
  available_from_lesson?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  course_id?: Maybe<Scalars['uuid']>;
  knowledge_test_id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['Int']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IKnowledgeTestsViewMinFields {
  __typename?: 'knowledge_tests_view_min_fields';
  available_from_lesson?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  course_id?: Maybe<Scalars['uuid']>;
  knowledge_test_id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['Int']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "knowledge_tests_view". */
export interface IKnowledgeTestsViewOrderBy {
  available_from_lesson?: InputMaybe<IOrderBy>;
  content?: InputMaybe<IOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  is_available?: InputMaybe<IOrderBy>;
  knowledge_test?: InputMaybe<IKnowledgeTestsOrderBy>;
  knowledge_test_id?: InputMaybe<IOrderBy>;
  knowledge_test_questions_aggregate?: InputMaybe<IKnowledgeTestQuestionsAggregateOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  subscription?: InputMaybe<ISubscriptionsOrderBy>;
  subscription_id?: InputMaybe<IOrderBy>;
  subtitle?: InputMaybe<IOrderBy>;
  title?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "knowledge_tests_view" */
export enum IKnowledgeTestsViewSelectColumn {
  /** column name */
  AVAILABLE_FROM_LESSON = 'available_from_lesson',
  /** column name */
  CONTENT = 'content',
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  IS_AVAILABLE = 'is_available',
  /** column name */
  KNOWLEDGE_TEST_ID = 'knowledge_test_id',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  SUBSCRIPTION_ID = 'subscription_id',
  /** column name */
  SUBTITLE = 'subtitle',
  /** column name */
  TITLE = 'title',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate stddev on columns */
export interface IKnowledgeTestsViewStddevFields {
  __typename?: 'knowledge_tests_view_stddev_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IKnowledgeTestsViewStddevPopFields {
  __typename?: 'knowledge_tests_view_stddev_pop_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IKnowledgeTestsViewStddevSampFields {
  __typename?: 'knowledge_tests_view_stddev_samp_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IKnowledgeTestsViewSumFields {
  __typename?: 'knowledge_tests_view_sum_fields';
  available_from_lesson?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
}

/** aggregate var_pop on columns */
export interface IKnowledgeTestsViewVarPopFields {
  __typename?: 'knowledge_tests_view_var_pop_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IKnowledgeTestsViewVarSampFields {
  __typename?: 'knowledge_tests_view_var_samp_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IKnowledgeTestsViewVarianceFields {
  __typename?: 'knowledge_tests_view_variance_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "learning_assignment_attachments" */
export interface ILearningAssignmentAttachments {
  __typename?: 'learning_assignment_attachments';
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  /** An object relationship */
  learning_assignment?: Maybe<ILearningAssignments>;
  learning_assignment_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "learning_assignment_attachments" */
export interface ILearningAssignmentAttachmentsAggregate {
  __typename?: 'learning_assignment_attachments_aggregate';
  aggregate?: Maybe<ILearningAssignmentAttachmentsAggregateFields>;
  nodes: Array<ILearningAssignmentAttachments>;
}

/** aggregate fields of "learning_assignment_attachments" */
export interface ILearningAssignmentAttachmentsAggregateFields {
  __typename?: 'learning_assignment_attachments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ILearningAssignmentAttachmentsMaxFields>;
  min?: Maybe<ILearningAssignmentAttachmentsMinFields>;
}


/** aggregate fields of "learning_assignment_attachments" */
export interface ILearningAssignmentAttachmentsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ILearningAssignmentAttachmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "learning_assignment_attachments". All fields are combined with a logical 'AND'. */
export interface ILearningAssignmentAttachmentsBoolExp {
  _and?: InputMaybe<Array<ILearningAssignmentAttachmentsBoolExp>>;
  _not?: InputMaybe<ILearningAssignmentAttachmentsBoolExp>;
  _or?: InputMaybe<Array<ILearningAssignmentAttachmentsBoolExp>>;
  created_at?: InputMaybe<ITimestampComparisonExp>;
  filename?: InputMaybe<IStringComparisonExp>;
  key?: InputMaybe<IStringComparisonExp>;
  learning_assignment?: InputMaybe<ILearningAssignmentsBoolExp>;
  learning_assignment_id?: InputMaybe<IUuidComparisonExp>;
}

/** input type for inserting data into table "learning_assignment_attachments" */
export interface ILearningAssignmentAttachmentsInsertInput {
  created_at?: InputMaybe<Scalars['timestamp']>;
  filename?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  learning_assignment?: InputMaybe<ILearningAssignmentsObjRelInsertInput>;
  learning_assignment_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface ILearningAssignmentAttachmentsMaxFields {
  __typename?: 'learning_assignment_attachments_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  learning_assignment_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface ILearningAssignmentAttachmentsMinFields {
  __typename?: 'learning_assignment_attachments_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  learning_assignment_id?: Maybe<Scalars['uuid']>;
}

/** input type for inserting object relation for remote table "learning_assignment_attachments" */
export interface ILearningAssignmentAttachmentsObjRelInsertInput {
  data: ILearningAssignmentAttachmentsInsertInput;
}

/** Ordering options when selecting data from "learning_assignment_attachments". */
export interface ILearningAssignmentAttachmentsOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  filename?: InputMaybe<IOrderBy>;
  key?: InputMaybe<IOrderBy>;
  learning_assignment?: InputMaybe<ILearningAssignmentsOrderBy>;
  learning_assignment_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "learning_assignment_attachments" */
export enum ILearningAssignmentAttachmentsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  FILENAME = 'filename',
  /** column name */
  KEY = 'key',
  /** column name */
  LEARNING_ASSIGNMENT_ID = 'learning_assignment_id'
}

/** columns and relationships of "learning_assignments" */
export interface ILearningAssignments {
  __typename?: 'learning_assignments';
  available_from_lesson: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  learning_assignment_attachments?: Maybe<ILearningAssignmentAttachments>;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
}

/** aggregated selection of "learning_assignments" */
export interface ILearningAssignmentsAggregate {
  __typename?: 'learning_assignments_aggregate';
  aggregate?: Maybe<ILearningAssignmentsAggregateFields>;
  nodes: Array<ILearningAssignments>;
}

/** aggregate fields of "learning_assignments" */
export interface ILearningAssignmentsAggregateFields {
  __typename?: 'learning_assignments_aggregate_fields';
  avg?: Maybe<ILearningAssignmentsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ILearningAssignmentsMaxFields>;
  min?: Maybe<ILearningAssignmentsMinFields>;
  stddev?: Maybe<ILearningAssignmentsStddevFields>;
  stddev_pop?: Maybe<ILearningAssignmentsStddevPopFields>;
  stddev_samp?: Maybe<ILearningAssignmentsStddevSampFields>;
  sum?: Maybe<ILearningAssignmentsSumFields>;
  var_pop?: Maybe<ILearningAssignmentsVarPopFields>;
  var_samp?: Maybe<ILearningAssignmentsVarSampFields>;
  variance?: Maybe<ILearningAssignmentsVarianceFields>;
}


/** aggregate fields of "learning_assignments" */
export interface ILearningAssignmentsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ILearningAssignmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface ILearningAssignmentsAvgFields {
  __typename?: 'learning_assignments_avg_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "learning_assignments". All fields are combined with a logical 'AND'. */
export interface ILearningAssignmentsBoolExp {
  _and?: InputMaybe<Array<ILearningAssignmentsBoolExp>>;
  _not?: InputMaybe<ILearningAssignmentsBoolExp>;
  _or?: InputMaybe<Array<ILearningAssignmentsBoolExp>>;
  available_from_lesson?: InputMaybe<IIntComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  learning_assignment_attachments?: InputMaybe<ILearningAssignmentAttachmentsBoolExp>;
  title?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "learning_assignments" */
export enum ILearningAssignmentsConstraint {
  /** unique or primary key constraint */
  LEARNING_ASSIGNMENTS_PKEY = 'learning_assignments_pkey'
}

/** input type for incrementing numeric columns in table "learning_assignments" */
export interface ILearningAssignmentsIncInput {
  available_from_lesson?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "learning_assignments" */
export interface ILearningAssignmentsInsertInput {
  available_from_lesson?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  learning_assignment_attachments?: InputMaybe<ILearningAssignmentAttachmentsObjRelInsertInput>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface ILearningAssignmentsMaxFields {
  __typename?: 'learning_assignments_max_fields';
  available_from_lesson?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface ILearningAssignmentsMinFields {
  __typename?: 'learning_assignments_min_fields';
  available_from_lesson?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** response of any mutation on the table "learning_assignments" */
export interface ILearningAssignmentsMutationResponse {
  __typename?: 'learning_assignments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ILearningAssignments>;
}

/** input type for inserting object relation for remote table "learning_assignments" */
export interface ILearningAssignmentsObjRelInsertInput {
  data: ILearningAssignmentsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ILearningAssignmentsOnConflict>;
}

/** on_conflict condition type for table "learning_assignments" */
export interface ILearningAssignmentsOnConflict {
  constraint: ILearningAssignmentsConstraint;
  update_columns?: Array<ILearningAssignmentsUpdateColumn>;
  where?: InputMaybe<ILearningAssignmentsBoolExp>;
}

/** Ordering options when selecting data from "learning_assignments". */
export interface ILearningAssignmentsOrderBy {
  available_from_lesson?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  learning_assignment_attachments?: InputMaybe<ILearningAssignmentAttachmentsOrderBy>;
  title?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: learning_assignments */
export interface ILearningAssignmentsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "learning_assignments" */
export enum ILearningAssignmentsSelectColumn {
  /** column name */
  AVAILABLE_FROM_LESSON = 'available_from_lesson',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "learning_assignments" */
export interface ILearningAssignmentsSetInput {
  available_from_lesson?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface ILearningAssignmentsStddevFields {
  __typename?: 'learning_assignments_stddev_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface ILearningAssignmentsStddevPopFields {
  __typename?: 'learning_assignments_stddev_pop_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface ILearningAssignmentsStddevSampFields {
  __typename?: 'learning_assignments_stddev_samp_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface ILearningAssignmentsSumFields {
  __typename?: 'learning_assignments_sum_fields';
  available_from_lesson?: Maybe<Scalars['Int']>;
}

/** update columns of table "learning_assignments" */
export enum ILearningAssignmentsUpdateColumn {
  /** column name */
  AVAILABLE_FROM_LESSON = 'available_from_lesson',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface ILearningAssignmentsVarPopFields {
  __typename?: 'learning_assignments_var_pop_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface ILearningAssignmentsVarSampFields {
  __typename?: 'learning_assignments_var_samp_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface ILearningAssignmentsVarianceFields {
  __typename?: 'learning_assignments_variance_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "learning_assignments_view" */
export interface ILearningAssignmentsView {
  __typename?: 'learning_assignments_view';
  available_from_lesson?: Maybe<Scalars['Int']>;
  is_available?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  learning_assignment?: Maybe<ILearningAssignments>;
  learning_assignment_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  subscription?: Maybe<ISubscriptions>;
  subscription_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "learning_assignments_view" */
export interface ILearningAssignmentsViewAggregate {
  __typename?: 'learning_assignments_view_aggregate';
  aggregate?: Maybe<ILearningAssignmentsViewAggregateFields>;
  nodes: Array<ILearningAssignmentsView>;
}

/** aggregate fields of "learning_assignments_view" */
export interface ILearningAssignmentsViewAggregateFields {
  __typename?: 'learning_assignments_view_aggregate_fields';
  avg?: Maybe<ILearningAssignmentsViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ILearningAssignmentsViewMaxFields>;
  min?: Maybe<ILearningAssignmentsViewMinFields>;
  stddev?: Maybe<ILearningAssignmentsViewStddevFields>;
  stddev_pop?: Maybe<ILearningAssignmentsViewStddevPopFields>;
  stddev_samp?: Maybe<ILearningAssignmentsViewStddevSampFields>;
  sum?: Maybe<ILearningAssignmentsViewSumFields>;
  var_pop?: Maybe<ILearningAssignmentsViewVarPopFields>;
  var_samp?: Maybe<ILearningAssignmentsViewVarSampFields>;
  variance?: Maybe<ILearningAssignmentsViewVarianceFields>;
}


/** aggregate fields of "learning_assignments_view" */
export interface ILearningAssignmentsViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ILearningAssignmentsViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface ILearningAssignmentsViewAvgFields {
  __typename?: 'learning_assignments_view_avg_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "learning_assignments_view". All fields are combined with a logical 'AND'. */
export interface ILearningAssignmentsViewBoolExp {
  _and?: InputMaybe<Array<ILearningAssignmentsViewBoolExp>>;
  _not?: InputMaybe<ILearningAssignmentsViewBoolExp>;
  _or?: InputMaybe<Array<ILearningAssignmentsViewBoolExp>>;
  available_from_lesson?: InputMaybe<IIntComparisonExp>;
  is_available?: InputMaybe<IBooleanComparisonExp>;
  learning_assignment?: InputMaybe<ILearningAssignmentsBoolExp>;
  learning_assignment_id?: InputMaybe<IUuidComparisonExp>;
  subscription?: InputMaybe<ISubscriptionsBoolExp>;
  subscription_id?: InputMaybe<IUuidComparisonExp>;
  title?: InputMaybe<IStringComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface ILearningAssignmentsViewMaxFields {
  __typename?: 'learning_assignments_view_max_fields';
  available_from_lesson?: Maybe<Scalars['Int']>;
  learning_assignment_id?: Maybe<Scalars['uuid']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface ILearningAssignmentsViewMinFields {
  __typename?: 'learning_assignments_view_min_fields';
  available_from_lesson?: Maybe<Scalars['Int']>;
  learning_assignment_id?: Maybe<Scalars['uuid']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "learning_assignments_view". */
export interface ILearningAssignmentsViewOrderBy {
  available_from_lesson?: InputMaybe<IOrderBy>;
  is_available?: InputMaybe<IOrderBy>;
  learning_assignment?: InputMaybe<ILearningAssignmentsOrderBy>;
  learning_assignment_id?: InputMaybe<IOrderBy>;
  subscription?: InputMaybe<ISubscriptionsOrderBy>;
  subscription_id?: InputMaybe<IOrderBy>;
  title?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "learning_assignments_view" */
export enum ILearningAssignmentsViewSelectColumn {
  /** column name */
  AVAILABLE_FROM_LESSON = 'available_from_lesson',
  /** column name */
  IS_AVAILABLE = 'is_available',
  /** column name */
  LEARNING_ASSIGNMENT_ID = 'learning_assignment_id',
  /** column name */
  SUBSCRIPTION_ID = 'subscription_id',
  /** column name */
  TITLE = 'title',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate stddev on columns */
export interface ILearningAssignmentsViewStddevFields {
  __typename?: 'learning_assignments_view_stddev_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface ILearningAssignmentsViewStddevPopFields {
  __typename?: 'learning_assignments_view_stddev_pop_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface ILearningAssignmentsViewStddevSampFields {
  __typename?: 'learning_assignments_view_stddev_samp_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface ILearningAssignmentsViewSumFields {
  __typename?: 'learning_assignments_view_sum_fields';
  available_from_lesson?: Maybe<Scalars['Int']>;
}

/** aggregate var_pop on columns */
export interface ILearningAssignmentsViewVarPopFields {
  __typename?: 'learning_assignments_view_var_pop_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface ILearningAssignmentsViewVarSampFields {
  __typename?: 'learning_assignments_view_var_samp_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface ILearningAssignmentsViewVarianceFields {
  __typename?: 'learning_assignments_view_variance_fields';
  available_from_lesson?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "lesson_attachments" */
export interface ILessonAttachments {
  __typename?: 'lesson_attachments';
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  file_type?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  lesson_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "lesson_attachments" */
export interface ILessonAttachmentsAggregate {
  __typename?: 'lesson_attachments_aggregate';
  aggregate?: Maybe<ILessonAttachmentsAggregateFields>;
  nodes: Array<ILessonAttachments>;
}

/** aggregate fields of "lesson_attachments" */
export interface ILessonAttachmentsAggregateFields {
  __typename?: 'lesson_attachments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ILessonAttachmentsMaxFields>;
  min?: Maybe<ILessonAttachmentsMinFields>;
}


/** aggregate fields of "lesson_attachments" */
export interface ILessonAttachmentsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ILessonAttachmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "lesson_attachments". All fields are combined with a logical 'AND'. */
export interface ILessonAttachmentsBoolExp {
  _and?: InputMaybe<Array<ILessonAttachmentsBoolExp>>;
  _not?: InputMaybe<ILessonAttachmentsBoolExp>;
  _or?: InputMaybe<Array<ILessonAttachmentsBoolExp>>;
  content_type?: InputMaybe<IStringComparisonExp>;
  created_at?: InputMaybe<ITimestampComparisonExp>;
  file_type?: InputMaybe<IStringComparisonExp>;
  filename?: InputMaybe<IStringComparisonExp>;
  key?: InputMaybe<IStringComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface ILessonAttachmentsMaxFields {
  __typename?: 'lesson_attachments_max_fields';
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  file_type?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  lesson_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface ILessonAttachmentsMinFields {
  __typename?: 'lesson_attachments_min_fields';
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  file_type?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  lesson_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "lesson_attachments". */
export interface ILessonAttachmentsOrderBy {
  content_type?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  file_type?: InputMaybe<IOrderBy>;
  filename?: InputMaybe<IOrderBy>;
  key?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "lesson_attachments" */
export enum ILessonAttachmentsSelectColumn {
  /** column name */
  CONTENT_TYPE = 'content_type',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  FILE_TYPE = 'file_type',
  /** column name */
  FILENAME = 'filename',
  /** column name */
  KEY = 'key',
  /** column name */
  LESSON_ID = 'lesson_id'
}

/** columns and relationships of "lesson_days" */
export interface ILessonDays {
  __typename?: 'lesson_days';
  /** An object relationship */
  course_group: ICourseGroups;
  course_group_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  lesson: ILessons;
  lesson_id: Scalars['uuid'];
  starts_on: Scalars['date'];
}

/** aggregated selection of "lesson_days" */
export interface ILessonDaysAggregate {
  __typename?: 'lesson_days_aggregate';
  aggregate?: Maybe<ILessonDaysAggregateFields>;
  nodes: Array<ILessonDays>;
}

/** aggregate fields of "lesson_days" */
export interface ILessonDaysAggregateFields {
  __typename?: 'lesson_days_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ILessonDaysMaxFields>;
  min?: Maybe<ILessonDaysMinFields>;
}


/** aggregate fields of "lesson_days" */
export interface ILessonDaysAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ILessonDaysSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "lesson_days" */
export interface ILessonDaysAggregateOrderBy {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<ILessonDaysMaxOrderBy>;
  min?: InputMaybe<ILessonDaysMinOrderBy>;
}

/** input type for inserting array relation for remote table "lesson_days" */
export interface ILessonDaysArrRelInsertInput {
  data: Array<ILessonDaysInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ILessonDaysOnConflict>;
}

/** Boolean expression to filter rows from the table "lesson_days". All fields are combined with a logical 'AND'. */
export interface ILessonDaysBoolExp {
  _and?: InputMaybe<Array<ILessonDaysBoolExp>>;
  _not?: InputMaybe<ILessonDaysBoolExp>;
  _or?: InputMaybe<Array<ILessonDaysBoolExp>>;
  course_group?: InputMaybe<ICourseGroupsBoolExp>;
  course_group_id?: InputMaybe<IUuidComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  starts_on?: InputMaybe<IDateComparisonExp>;
}

/** unique or primary key constraints on table "lesson_days" */
export enum ILessonDaysConstraint {
  /** unique or primary key constraint */
  LESSON_DAYS_COURSE_GROUP_ID_LESSON_ID_KEY = 'lesson_days_course_group_id_lesson_id_key',
  /** unique or primary key constraint */
  LESSON_DAYS_PKEY = 'lesson_days_pkey'
}

/** input type for inserting data into table "lesson_days" */
export interface ILessonDaysInsertInput {
  course_group?: InputMaybe<ICourseGroupsObjRelInsertInput>;
  course_group_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson?: InputMaybe<ILessonsObjRelInsertInput>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  starts_on?: InputMaybe<Scalars['date']>;
}

/** aggregate max on columns */
export interface ILessonDaysMaxFields {
  __typename?: 'lesson_days_max_fields';
  course_group_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  starts_on?: Maybe<Scalars['date']>;
}

/** order by max() on columns of table "lesson_days" */
export interface ILessonDaysMaxOrderBy {
  course_group_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  starts_on?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface ILessonDaysMinFields {
  __typename?: 'lesson_days_min_fields';
  course_group_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  starts_on?: Maybe<Scalars['date']>;
}

/** order by min() on columns of table "lesson_days" */
export interface ILessonDaysMinOrderBy {
  course_group_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  starts_on?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "lesson_days" */
export interface ILessonDaysMutationResponse {
  __typename?: 'lesson_days_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ILessonDays>;
}

/** on_conflict condition type for table "lesson_days" */
export interface ILessonDaysOnConflict {
  constraint: ILessonDaysConstraint;
  update_columns?: Array<ILessonDaysUpdateColumn>;
  where?: InputMaybe<ILessonDaysBoolExp>;
}

/** Ordering options when selecting data from "lesson_days". */
export interface ILessonDaysOrderBy {
  course_group?: InputMaybe<ICourseGroupsOrderBy>;
  course_group_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  starts_on?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: lesson_days */
export interface ILessonDaysPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "lesson_days" */
export enum ILessonDaysSelectColumn {
  /** column name */
  COURSE_GROUP_ID = 'course_group_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  STARTS_ON = 'starts_on'
}

/** input type for updating data in table "lesson_days" */
export interface ILessonDaysSetInput {
  course_group_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  starts_on?: InputMaybe<Scalars['date']>;
}

/** update columns of table "lesson_days" */
export enum ILessonDaysUpdateColumn {
  /** column name */
  COURSE_GROUP_ID = 'course_group_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  STARTS_ON = 'starts_on'
}

/** columns and relationships of "lesson_messages" */
export interface ILessonMessages {
  __typename?: 'lesson_messages';
  /** An object relationship */
  course?: Maybe<ICourses>;
  course_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  lesson_id: Scalars['uuid'];
  message: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
}

/** aggregated selection of "lesson_messages" */
export interface ILessonMessagesAggregate {
  __typename?: 'lesson_messages_aggregate';
  aggregate?: Maybe<ILessonMessagesAggregateFields>;
  nodes: Array<ILessonMessages>;
}

/** aggregate fields of "lesson_messages" */
export interface ILessonMessagesAggregateFields {
  __typename?: 'lesson_messages_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ILessonMessagesMaxFields>;
  min?: Maybe<ILessonMessagesMinFields>;
}


/** aggregate fields of "lesson_messages" */
export interface ILessonMessagesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ILessonMessagesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "lesson_messages". All fields are combined with a logical 'AND'. */
export interface ILessonMessagesBoolExp {
  _and?: InputMaybe<Array<ILessonMessagesBoolExp>>;
  _not?: InputMaybe<ILessonMessagesBoolExp>;
  _or?: InputMaybe<Array<ILessonMessagesBoolExp>>;
  course?: InputMaybe<ICoursesBoolExp>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  message?: InputMaybe<IStringComparisonExp>;
  title?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "lesson_messages" */
export enum ILessonMessagesConstraint {
  /** unique or primary key constraint */
  LESSON_MESSAGES_PKEY = 'lesson_messages_pkey'
}

/** input type for inserting data into table "lesson_messages" */
export interface ILessonMessagesInsertInput {
  course?: InputMaybe<ICoursesObjRelInsertInput>;
  course_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson?: InputMaybe<ILessonsObjRelInsertInput>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface ILessonMessagesMaxFields {
  __typename?: 'lesson_messages_max_fields';
  course_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface ILessonMessagesMinFields {
  __typename?: 'lesson_messages_min_fields';
  course_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** response of any mutation on the table "lesson_messages" */
export interface ILessonMessagesMutationResponse {
  __typename?: 'lesson_messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ILessonMessages>;
}

/** on_conflict condition type for table "lesson_messages" */
export interface ILessonMessagesOnConflict {
  constraint: ILessonMessagesConstraint;
  update_columns?: Array<ILessonMessagesUpdateColumn>;
  where?: InputMaybe<ILessonMessagesBoolExp>;
}

/** Ordering options when selecting data from "lesson_messages". */
export interface ILessonMessagesOrderBy {
  course?: InputMaybe<ICoursesOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  message?: InputMaybe<IOrderBy>;
  title?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: lesson_messages */
export interface ILessonMessagesPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "lesson_messages" */
export enum ILessonMessagesSelectColumn {
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  MESSAGE = 'message',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "lesson_messages" */
export interface ILessonMessagesSetInput {
  course_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** update columns of table "lesson_messages" */
export enum ILessonMessagesUpdateColumn {
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  MESSAGE = 'message',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** columns and relationships of "lesson_partitions" */
export interface ILessonPartitions {
  __typename?: 'lesson_partitions';
  content: Scalars['String'];
  created_at: Scalars['timestamp'];
  id: Scalars['uuid'];
  lesson_id: Scalars['uuid'];
  sequence: Scalars['Int'];
}

/** aggregated selection of "lesson_partitions" */
export interface ILessonPartitionsAggregate {
  __typename?: 'lesson_partitions_aggregate';
  aggregate?: Maybe<ILessonPartitionsAggregateFields>;
  nodes: Array<ILessonPartitions>;
}

/** aggregate fields of "lesson_partitions" */
export interface ILessonPartitionsAggregateFields {
  __typename?: 'lesson_partitions_aggregate_fields';
  avg?: Maybe<ILessonPartitionsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ILessonPartitionsMaxFields>;
  min?: Maybe<ILessonPartitionsMinFields>;
  stddev?: Maybe<ILessonPartitionsStddevFields>;
  stddev_pop?: Maybe<ILessonPartitionsStddevPopFields>;
  stddev_samp?: Maybe<ILessonPartitionsStddevSampFields>;
  sum?: Maybe<ILessonPartitionsSumFields>;
  var_pop?: Maybe<ILessonPartitionsVarPopFields>;
  var_samp?: Maybe<ILessonPartitionsVarSampFields>;
  variance?: Maybe<ILessonPartitionsVarianceFields>;
}


/** aggregate fields of "lesson_partitions" */
export interface ILessonPartitionsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ILessonPartitionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "lesson_partitions" */
export interface ILessonPartitionsAggregateOrderBy {
  avg?: InputMaybe<ILessonPartitionsAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<ILessonPartitionsMaxOrderBy>;
  min?: InputMaybe<ILessonPartitionsMinOrderBy>;
  stddev?: InputMaybe<ILessonPartitionsStddevOrderBy>;
  stddev_pop?: InputMaybe<ILessonPartitionsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ILessonPartitionsStddevSampOrderBy>;
  sum?: InputMaybe<ILessonPartitionsSumOrderBy>;
  var_pop?: InputMaybe<ILessonPartitionsVarPopOrderBy>;
  var_samp?: InputMaybe<ILessonPartitionsVarSampOrderBy>;
  variance?: InputMaybe<ILessonPartitionsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "lesson_partitions" */
export interface ILessonPartitionsArrRelInsertInput {
  data: Array<ILessonPartitionsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ILessonPartitionsOnConflict>;
}

/** aggregate avg on columns */
export interface ILessonPartitionsAvgFields {
  __typename?: 'lesson_partitions_avg_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "lesson_partitions" */
export interface ILessonPartitionsAvgOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "lesson_partitions". All fields are combined with a logical 'AND'. */
export interface ILessonPartitionsBoolExp {
  _and?: InputMaybe<Array<ILessonPartitionsBoolExp>>;
  _not?: InputMaybe<ILessonPartitionsBoolExp>;
  _or?: InputMaybe<Array<ILessonPartitionsBoolExp>>;
  content?: InputMaybe<IStringComparisonExp>;
  created_at?: InputMaybe<ITimestampComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  sequence?: InputMaybe<IIntComparisonExp>;
}

/** unique or primary key constraints on table "lesson_partitions" */
export enum ILessonPartitionsConstraint {
  /** unique or primary key constraint */
  LESSON_PARTITIONS_PKEY = 'lesson_partitions_pkey'
}

/** input type for incrementing numeric columns in table "lesson_partitions" */
export interface ILessonPartitionsIncInput {
  sequence?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "lesson_partitions" */
export interface ILessonPartitionsInsertInput {
  content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  sequence?: InputMaybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface ILessonPartitionsMaxFields {
  __typename?: 'lesson_partitions_max_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['Int']>;
}

/** order by max() on columns of table "lesson_partitions" */
export interface ILessonPartitionsMaxOrderBy {
  content?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface ILessonPartitionsMinFields {
  __typename?: 'lesson_partitions_min_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['Int']>;
}

/** order by min() on columns of table "lesson_partitions" */
export interface ILessonPartitionsMinOrderBy {
  content?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "lesson_partitions" */
export interface ILessonPartitionsMutationResponse {
  __typename?: 'lesson_partitions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ILessonPartitions>;
}

/** on_conflict condition type for table "lesson_partitions" */
export interface ILessonPartitionsOnConflict {
  constraint: ILessonPartitionsConstraint;
  update_columns?: Array<ILessonPartitionsUpdateColumn>;
  where?: InputMaybe<ILessonPartitionsBoolExp>;
}

/** Ordering options when selecting data from "lesson_partitions". */
export interface ILessonPartitionsOrderBy {
  content?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: lesson_partitions */
export interface ILessonPartitionsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "lesson_partitions" */
export enum ILessonPartitionsSelectColumn {
  /** column name */
  CONTENT = 'content',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  SEQUENCE = 'sequence'
}

/** input type for updating data in table "lesson_partitions" */
export interface ILessonPartitionsSetInput {
  content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  sequence?: InputMaybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface ILessonPartitionsStddevFields {
  __typename?: 'lesson_partitions_stddev_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "lesson_partitions" */
export interface ILessonPartitionsStddevOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface ILessonPartitionsStddevPopFields {
  __typename?: 'lesson_partitions_stddev_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "lesson_partitions" */
export interface ILessonPartitionsStddevPopOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface ILessonPartitionsStddevSampFields {
  __typename?: 'lesson_partitions_stddev_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "lesson_partitions" */
export interface ILessonPartitionsStddevSampOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface ILessonPartitionsSumFields {
  __typename?: 'lesson_partitions_sum_fields';
  sequence?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "lesson_partitions" */
export interface ILessonPartitionsSumOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** update columns of table "lesson_partitions" */
export enum ILessonPartitionsUpdateColumn {
  /** column name */
  CONTENT = 'content',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  SEQUENCE = 'sequence'
}

/** aggregate var_pop on columns */
export interface ILessonPartitionsVarPopFields {
  __typename?: 'lesson_partitions_var_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "lesson_partitions" */
export interface ILessonPartitionsVarPopOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface ILessonPartitionsVarSampFields {
  __typename?: 'lesson_partitions_var_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "lesson_partitions" */
export interface ILessonPartitionsVarSampOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface ILessonPartitionsVarianceFields {
  __typename?: 'lesson_partitions_variance_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "lesson_partitions" */
export interface ILessonPartitionsVarianceOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** columns and relationships of "lesson_reactions" */
export interface ILessonReactions {
  __typename?: 'lesson_reactions';
  approved: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  lesson_id: Scalars['uuid'];
  lesson_part: Scalars['Int'];
  reaction: Scalars['String'];
  student_name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id: Scalars['uuid'];
}

/** aggregated selection of "lesson_reactions" */
export interface ILessonReactionsAggregate {
  __typename?: 'lesson_reactions_aggregate';
  aggregate?: Maybe<ILessonReactionsAggregateFields>;
  nodes: Array<ILessonReactions>;
}

/** aggregate fields of "lesson_reactions" */
export interface ILessonReactionsAggregateFields {
  __typename?: 'lesson_reactions_aggregate_fields';
  avg?: Maybe<ILessonReactionsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ILessonReactionsMaxFields>;
  min?: Maybe<ILessonReactionsMinFields>;
  stddev?: Maybe<ILessonReactionsStddevFields>;
  stddev_pop?: Maybe<ILessonReactionsStddevPopFields>;
  stddev_samp?: Maybe<ILessonReactionsStddevSampFields>;
  sum?: Maybe<ILessonReactionsSumFields>;
  var_pop?: Maybe<ILessonReactionsVarPopFields>;
  var_samp?: Maybe<ILessonReactionsVarSampFields>;
  variance?: Maybe<ILessonReactionsVarianceFields>;
}


/** aggregate fields of "lesson_reactions" */
export interface ILessonReactionsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ILessonReactionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface ILessonReactionsAvgFields {
  __typename?: 'lesson_reactions_avg_fields';
  lesson_part?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "lesson_reactions". All fields are combined with a logical 'AND'. */
export interface ILessonReactionsBoolExp {
  _and?: InputMaybe<Array<ILessonReactionsBoolExp>>;
  _not?: InputMaybe<ILessonReactionsBoolExp>;
  _or?: InputMaybe<Array<ILessonReactionsBoolExp>>;
  approved?: InputMaybe<IBooleanComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  lesson_part?: InputMaybe<IIntComparisonExp>;
  reaction?: InputMaybe<IStringComparisonExp>;
  student_name?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** unique or primary key constraints on table "lesson_reactions" */
export enum ILessonReactionsConstraint {
  /** unique or primary key constraint */
  LESSON_REACTIONS_PKEY = 'lesson_reactions_pkey'
}

/** input type for incrementing numeric columns in table "lesson_reactions" */
export interface ILessonReactionsIncInput {
  lesson_part?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "lesson_reactions" */
export interface ILessonReactionsInsertInput {
  approved?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson?: InputMaybe<ILessonsObjRelInsertInput>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  lesson_part?: InputMaybe<Scalars['Int']>;
  reaction?: InputMaybe<Scalars['String']>;
  student_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<IUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface ILessonReactionsMaxFields {
  __typename?: 'lesson_reactions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  lesson_part?: Maybe<Scalars['Int']>;
  reaction?: Maybe<Scalars['String']>;
  student_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface ILessonReactionsMinFields {
  __typename?: 'lesson_reactions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  lesson_part?: Maybe<Scalars['Int']>;
  reaction?: Maybe<Scalars['String']>;
  student_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** response of any mutation on the table "lesson_reactions" */
export interface ILessonReactionsMutationResponse {
  __typename?: 'lesson_reactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ILessonReactions>;
}

/** on_conflict condition type for table "lesson_reactions" */
export interface ILessonReactionsOnConflict {
  constraint: ILessonReactionsConstraint;
  update_columns?: Array<ILessonReactionsUpdateColumn>;
  where?: InputMaybe<ILessonReactionsBoolExp>;
}

/** Ordering options when selecting data from "lesson_reactions". */
export interface ILessonReactionsOrderBy {
  approved?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  lesson_part?: InputMaybe<IOrderBy>;
  reaction?: InputMaybe<IOrderBy>;
  student_name?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: lesson_reactions */
export interface ILessonReactionsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "lesson_reactions" */
export enum ILessonReactionsSelectColumn {
  /** column name */
  APPROVED = 'approved',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  LESSON_PART = 'lesson_part',
  /** column name */
  REACTION = 'reaction',
  /** column name */
  STUDENT_NAME = 'student_name',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id'
}

/** input type for updating data in table "lesson_reactions" */
export interface ILessonReactionsSetInput {
  approved?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  lesson_part?: InputMaybe<Scalars['Int']>;
  reaction?: InputMaybe<Scalars['String']>;
  student_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate stddev on columns */
export interface ILessonReactionsStddevFields {
  __typename?: 'lesson_reactions_stddev_fields';
  lesson_part?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface ILessonReactionsStddevPopFields {
  __typename?: 'lesson_reactions_stddev_pop_fields';
  lesson_part?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface ILessonReactionsStddevSampFields {
  __typename?: 'lesson_reactions_stddev_samp_fields';
  lesson_part?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface ILessonReactionsSumFields {
  __typename?: 'lesson_reactions_sum_fields';
  lesson_part?: Maybe<Scalars['Int']>;
}

/** update columns of table "lesson_reactions" */
export enum ILessonReactionsUpdateColumn {
  /** column name */
  APPROVED = 'approved',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  LESSON_PART = 'lesson_part',
  /** column name */
  REACTION = 'reaction',
  /** column name */
  STUDENT_NAME = 'student_name',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate var_pop on columns */
export interface ILessonReactionsVarPopFields {
  __typename?: 'lesson_reactions_var_pop_fields';
  lesson_part?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface ILessonReactionsVarSampFields {
  __typename?: 'lesson_reactions_var_samp_fields';
  lesson_part?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface ILessonReactionsVarianceFields {
  __typename?: 'lesson_reactions_variance_fields';
  lesson_part?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "lesson_reminders" */
export interface ILessonReminders {
  __typename?: 'lesson_reminders';
  datediff?: Maybe<Scalars['float8']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  starts_on?: Maybe<Scalars['date']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "lesson_reminders" */
export interface ILessonRemindersAggregate {
  __typename?: 'lesson_reminders_aggregate';
  aggregate?: Maybe<ILessonRemindersAggregateFields>;
  nodes: Array<ILessonReminders>;
}

/** aggregate fields of "lesson_reminders" */
export interface ILessonRemindersAggregateFields {
  __typename?: 'lesson_reminders_aggregate_fields';
  avg?: Maybe<ILessonRemindersAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ILessonRemindersMaxFields>;
  min?: Maybe<ILessonRemindersMinFields>;
  stddev?: Maybe<ILessonRemindersStddevFields>;
  stddev_pop?: Maybe<ILessonRemindersStddevPopFields>;
  stddev_samp?: Maybe<ILessonRemindersStddevSampFields>;
  sum?: Maybe<ILessonRemindersSumFields>;
  var_pop?: Maybe<ILessonRemindersVarPopFields>;
  var_samp?: Maybe<ILessonRemindersVarSampFields>;
  variance?: Maybe<ILessonRemindersVarianceFields>;
}


/** aggregate fields of "lesson_reminders" */
export interface ILessonRemindersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ILessonRemindersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface ILessonRemindersAvgFields {
  __typename?: 'lesson_reminders_avg_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "lesson_reminders". All fields are combined with a logical 'AND'. */
export interface ILessonRemindersBoolExp {
  _and?: InputMaybe<Array<ILessonRemindersBoolExp>>;
  _not?: InputMaybe<ILessonRemindersBoolExp>;
  _or?: InputMaybe<Array<ILessonRemindersBoolExp>>;
  datediff?: InputMaybe<IFloat8ComparisonExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  starts_on?: InputMaybe<IDateComparisonExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface ILessonRemindersMaxFields {
  __typename?: 'lesson_reminders_max_fields';
  datediff?: Maybe<Scalars['float8']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  starts_on?: Maybe<Scalars['date']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface ILessonRemindersMinFields {
  __typename?: 'lesson_reminders_min_fields';
  datediff?: Maybe<Scalars['float8']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  starts_on?: Maybe<Scalars['date']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "lesson_reminders". */
export interface ILessonRemindersOrderBy {
  datediff?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  starts_on?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "lesson_reminders" */
export enum ILessonRemindersSelectColumn {
  /** column name */
  DATEDIFF = 'datediff',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  STARTS_ON = 'starts_on',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate stddev on columns */
export interface ILessonRemindersStddevFields {
  __typename?: 'lesson_reminders_stddev_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface ILessonRemindersStddevPopFields {
  __typename?: 'lesson_reminders_stddev_pop_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface ILessonRemindersStddevSampFields {
  __typename?: 'lesson_reminders_stddev_samp_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface ILessonRemindersSumFields {
  __typename?: 'lesson_reminders_sum_fields';
  datediff?: Maybe<Scalars['float8']>;
}

/** aggregate var_pop on columns */
export interface ILessonRemindersVarPopFields {
  __typename?: 'lesson_reminders_var_pop_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface ILessonRemindersVarSampFields {
  __typename?: 'lesson_reminders_var_samp_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface ILessonRemindersVarianceFields {
  __typename?: 'lesson_reminders_variance_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "lesson_second_parts_view" */
export interface ILessonSecondPartsView {
  __typename?: 'lesson_second_parts_view';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  /** An object relationship */
  course?: Maybe<ICourses>;
  /** An object relationship */
  course_group?: Maybe<ICourseGroups>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['interval']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  is_standby?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  not_available?: Maybe<Scalars['Boolean']>;
  second_part?: Maybe<Scalars['String']>;
  start_dt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  subscription?: Maybe<ISubscriptions>;
  subscription_id?: Maybe<Scalars['uuid']>;
  surah?: Maybe<Scalars['String']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "lesson_second_parts_view" */
export interface ILessonSecondPartsViewAggregate {
  __typename?: 'lesson_second_parts_view_aggregate';
  aggregate?: Maybe<ILessonSecondPartsViewAggregateFields>;
  nodes: Array<ILessonSecondPartsView>;
}

/** aggregate fields of "lesson_second_parts_view" */
export interface ILessonSecondPartsViewAggregateFields {
  __typename?: 'lesson_second_parts_view_aggregate_fields';
  avg?: Maybe<ILessonSecondPartsViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ILessonSecondPartsViewMaxFields>;
  min?: Maybe<ILessonSecondPartsViewMinFields>;
  stddev?: Maybe<ILessonSecondPartsViewStddevFields>;
  stddev_pop?: Maybe<ILessonSecondPartsViewStddevPopFields>;
  stddev_samp?: Maybe<ILessonSecondPartsViewStddevSampFields>;
  sum?: Maybe<ILessonSecondPartsViewSumFields>;
  var_pop?: Maybe<ILessonSecondPartsViewVarPopFields>;
  var_samp?: Maybe<ILessonSecondPartsViewVarSampFields>;
  variance?: Maybe<ILessonSecondPartsViewVarianceFields>;
}


/** aggregate fields of "lesson_second_parts_view" */
export interface ILessonSecondPartsViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ILessonSecondPartsViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface ILessonSecondPartsViewAvgFields {
  __typename?: 'lesson_second_parts_view_avg_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "lesson_second_parts_view". All fields are combined with a logical 'AND'. */
export interface ILessonSecondPartsViewBoolExp {
  _and?: InputMaybe<Array<ILessonSecondPartsViewBoolExp>>;
  _not?: InputMaybe<ILessonSecondPartsViewBoolExp>;
  _or?: InputMaybe<Array<ILessonSecondPartsViewBoolExp>>;
  content?: InputMaybe<IStringComparisonExp>;
  content_key?: InputMaybe<IIntComparisonExp>;
  course?: InputMaybe<ICoursesBoolExp>;
  course_group?: InputMaybe<ICourseGroupsBoolExp>;
  course_group_id?: InputMaybe<IUuidComparisonExp>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  duration?: InputMaybe<IIntervalComparisonExp>;
  end_time?: InputMaybe<ITimestamptzComparisonExp>;
  is_standby?: InputMaybe<IBooleanComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  not_available?: InputMaybe<IBooleanComparisonExp>;
  second_part?: InputMaybe<IStringComparisonExp>;
  start_dt?: InputMaybe<ITimestampComparisonExp>;
  subscription?: InputMaybe<ISubscriptionsBoolExp>;
  subscription_id?: InputMaybe<IUuidComparisonExp>;
  surah?: InputMaybe<IStringComparisonExp>;
  unlocked_at?: InputMaybe<ITimestamptzComparisonExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface ILessonSecondPartsViewMaxFields {
  __typename?: 'lesson_second_parts_view_max_fields';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  second_part?: Maybe<Scalars['String']>;
  start_dt?: Maybe<Scalars['timestamp']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  surah?: Maybe<Scalars['String']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface ILessonSecondPartsViewMinFields {
  __typename?: 'lesson_second_parts_view_min_fields';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  second_part?: Maybe<Scalars['String']>;
  start_dt?: Maybe<Scalars['timestamp']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  surah?: Maybe<Scalars['String']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "lesson_second_parts_view". */
export interface ILessonSecondPartsViewOrderBy {
  content?: InputMaybe<IOrderBy>;
  content_key?: InputMaybe<IOrderBy>;
  course?: InputMaybe<ICoursesOrderBy>;
  course_group?: InputMaybe<ICourseGroupsOrderBy>;
  course_group_id?: InputMaybe<IOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  duration?: InputMaybe<IOrderBy>;
  end_time?: InputMaybe<IOrderBy>;
  is_standby?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  not_available?: InputMaybe<IOrderBy>;
  second_part?: InputMaybe<IOrderBy>;
  start_dt?: InputMaybe<IOrderBy>;
  subscription?: InputMaybe<ISubscriptionsOrderBy>;
  subscription_id?: InputMaybe<IOrderBy>;
  surah?: InputMaybe<IOrderBy>;
  unlocked_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "lesson_second_parts_view" */
export enum ILessonSecondPartsViewSelectColumn {
  /** column name */
  CONTENT = 'content',
  /** column name */
  CONTENT_KEY = 'content_key',
  /** column name */
  COURSE_GROUP_ID = 'course_group_id',
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  DURATION = 'duration',
  /** column name */
  END_TIME = 'end_time',
  /** column name */
  IS_STANDBY = 'is_standby',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  NAME = 'name',
  /** column name */
  NOT_AVAILABLE = 'not_available',
  /** column name */
  SECOND_PART = 'second_part',
  /** column name */
  START_DT = 'start_dt',
  /** column name */
  SUBSCRIPTION_ID = 'subscription_id',
  /** column name */
  SURAH = 'surah',
  /** column name */
  UNLOCKED_AT = 'unlocked_at',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate stddev on columns */
export interface ILessonSecondPartsViewStddevFields {
  __typename?: 'lesson_second_parts_view_stddev_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface ILessonSecondPartsViewStddevPopFields {
  __typename?: 'lesson_second_parts_view_stddev_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface ILessonSecondPartsViewStddevSampFields {
  __typename?: 'lesson_second_parts_view_stddev_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface ILessonSecondPartsViewSumFields {
  __typename?: 'lesson_second_parts_view_sum_fields';
  content_key?: Maybe<Scalars['Int']>;
}

/** aggregate var_pop on columns */
export interface ILessonSecondPartsViewVarPopFields {
  __typename?: 'lesson_second_parts_view_var_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface ILessonSecondPartsViewVarSampFields {
  __typename?: 'lesson_second_parts_view_var_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface ILessonSecondPartsViewVarianceFields {
  __typename?: 'lesson_second_parts_view_variance_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "lesson_third_parts_view" */
export interface ILessonThirdPartsView {
  __typename?: 'lesson_third_parts_view';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  /** An object relationship */
  course?: Maybe<ICourses>;
  /** An object relationship */
  course_group?: Maybe<ICourseGroups>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['interval']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  is_standby?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  not_available?: Maybe<Scalars['Boolean']>;
  start_dt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  subscription?: Maybe<ISubscriptions>;
  subscription_id?: Maybe<Scalars['uuid']>;
  surah?: Maybe<Scalars['String']>;
  third_part?: Maybe<Scalars['String']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "lesson_third_parts_view" */
export interface ILessonThirdPartsViewAggregate {
  __typename?: 'lesson_third_parts_view_aggregate';
  aggregate?: Maybe<ILessonThirdPartsViewAggregateFields>;
  nodes: Array<ILessonThirdPartsView>;
}

/** aggregate fields of "lesson_third_parts_view" */
export interface ILessonThirdPartsViewAggregateFields {
  __typename?: 'lesson_third_parts_view_aggregate_fields';
  avg?: Maybe<ILessonThirdPartsViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ILessonThirdPartsViewMaxFields>;
  min?: Maybe<ILessonThirdPartsViewMinFields>;
  stddev?: Maybe<ILessonThirdPartsViewStddevFields>;
  stddev_pop?: Maybe<ILessonThirdPartsViewStddevPopFields>;
  stddev_samp?: Maybe<ILessonThirdPartsViewStddevSampFields>;
  sum?: Maybe<ILessonThirdPartsViewSumFields>;
  var_pop?: Maybe<ILessonThirdPartsViewVarPopFields>;
  var_samp?: Maybe<ILessonThirdPartsViewVarSampFields>;
  variance?: Maybe<ILessonThirdPartsViewVarianceFields>;
}


/** aggregate fields of "lesson_third_parts_view" */
export interface ILessonThirdPartsViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ILessonThirdPartsViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface ILessonThirdPartsViewAvgFields {
  __typename?: 'lesson_third_parts_view_avg_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "lesson_third_parts_view". All fields are combined with a logical 'AND'. */
export interface ILessonThirdPartsViewBoolExp {
  _and?: InputMaybe<Array<ILessonThirdPartsViewBoolExp>>;
  _not?: InputMaybe<ILessonThirdPartsViewBoolExp>;
  _or?: InputMaybe<Array<ILessonThirdPartsViewBoolExp>>;
  content?: InputMaybe<IStringComparisonExp>;
  content_key?: InputMaybe<IIntComparisonExp>;
  course?: InputMaybe<ICoursesBoolExp>;
  course_group?: InputMaybe<ICourseGroupsBoolExp>;
  course_group_id?: InputMaybe<IUuidComparisonExp>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  duration?: InputMaybe<IIntervalComparisonExp>;
  end_time?: InputMaybe<ITimestamptzComparisonExp>;
  is_standby?: InputMaybe<IBooleanComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  not_available?: InputMaybe<IBooleanComparisonExp>;
  start_dt?: InputMaybe<ITimestampComparisonExp>;
  subscription?: InputMaybe<ISubscriptionsBoolExp>;
  subscription_id?: InputMaybe<IUuidComparisonExp>;
  surah?: InputMaybe<IStringComparisonExp>;
  third_part?: InputMaybe<IStringComparisonExp>;
  unlocked_at?: InputMaybe<ITimestamptzComparisonExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface ILessonThirdPartsViewMaxFields {
  __typename?: 'lesson_third_parts_view_max_fields';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  start_dt?: Maybe<Scalars['timestamp']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  surah?: Maybe<Scalars['String']>;
  third_part?: Maybe<Scalars['String']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface ILessonThirdPartsViewMinFields {
  __typename?: 'lesson_third_parts_view_min_fields';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  start_dt?: Maybe<Scalars['timestamp']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  surah?: Maybe<Scalars['String']>;
  third_part?: Maybe<Scalars['String']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "lesson_third_parts_view". */
export interface ILessonThirdPartsViewOrderBy {
  content?: InputMaybe<IOrderBy>;
  content_key?: InputMaybe<IOrderBy>;
  course?: InputMaybe<ICoursesOrderBy>;
  course_group?: InputMaybe<ICourseGroupsOrderBy>;
  course_group_id?: InputMaybe<IOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  duration?: InputMaybe<IOrderBy>;
  end_time?: InputMaybe<IOrderBy>;
  is_standby?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  not_available?: InputMaybe<IOrderBy>;
  start_dt?: InputMaybe<IOrderBy>;
  subscription?: InputMaybe<ISubscriptionsOrderBy>;
  subscription_id?: InputMaybe<IOrderBy>;
  surah?: InputMaybe<IOrderBy>;
  third_part?: InputMaybe<IOrderBy>;
  unlocked_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "lesson_third_parts_view" */
export enum ILessonThirdPartsViewSelectColumn {
  /** column name */
  CONTENT = 'content',
  /** column name */
  CONTENT_KEY = 'content_key',
  /** column name */
  COURSE_GROUP_ID = 'course_group_id',
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  DURATION = 'duration',
  /** column name */
  END_TIME = 'end_time',
  /** column name */
  IS_STANDBY = 'is_standby',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  NAME = 'name',
  /** column name */
  NOT_AVAILABLE = 'not_available',
  /** column name */
  START_DT = 'start_dt',
  /** column name */
  SUBSCRIPTION_ID = 'subscription_id',
  /** column name */
  SURAH = 'surah',
  /** column name */
  THIRD_PART = 'third_part',
  /** column name */
  UNLOCKED_AT = 'unlocked_at',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate stddev on columns */
export interface ILessonThirdPartsViewStddevFields {
  __typename?: 'lesson_third_parts_view_stddev_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface ILessonThirdPartsViewStddevPopFields {
  __typename?: 'lesson_third_parts_view_stddev_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface ILessonThirdPartsViewStddevSampFields {
  __typename?: 'lesson_third_parts_view_stddev_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface ILessonThirdPartsViewSumFields {
  __typename?: 'lesson_third_parts_view_sum_fields';
  content_key?: Maybe<Scalars['Int']>;
}

/** aggregate var_pop on columns */
export interface ILessonThirdPartsViewVarPopFields {
  __typename?: 'lesson_third_parts_view_var_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface ILessonThirdPartsViewVarSampFields {
  __typename?: 'lesson_third_parts_view_var_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface ILessonThirdPartsViewVarianceFields {
  __typename?: 'lesson_third_parts_view_variance_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "lesson_unlock_keys" */
export interface ILessonUnlockKeys {
  __typename?: 'lesson_unlock_keys';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  secret_key: Scalars['Int'];
  /** An object relationship */
  user: IUsers;
  user_id: Scalars['uuid'];
}

/** aggregated selection of "lesson_unlock_keys" */
export interface ILessonUnlockKeysAggregate {
  __typename?: 'lesson_unlock_keys_aggregate';
  aggregate?: Maybe<ILessonUnlockKeysAggregateFields>;
  nodes: Array<ILessonUnlockKeys>;
}

/** aggregate fields of "lesson_unlock_keys" */
export interface ILessonUnlockKeysAggregateFields {
  __typename?: 'lesson_unlock_keys_aggregate_fields';
  avg?: Maybe<ILessonUnlockKeysAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ILessonUnlockKeysMaxFields>;
  min?: Maybe<ILessonUnlockKeysMinFields>;
  stddev?: Maybe<ILessonUnlockKeysStddevFields>;
  stddev_pop?: Maybe<ILessonUnlockKeysStddevPopFields>;
  stddev_samp?: Maybe<ILessonUnlockKeysStddevSampFields>;
  sum?: Maybe<ILessonUnlockKeysSumFields>;
  var_pop?: Maybe<ILessonUnlockKeysVarPopFields>;
  var_samp?: Maybe<ILessonUnlockKeysVarSampFields>;
  variance?: Maybe<ILessonUnlockKeysVarianceFields>;
}


/** aggregate fields of "lesson_unlock_keys" */
export interface ILessonUnlockKeysAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ILessonUnlockKeysSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface ILessonUnlockKeysAvgFields {
  __typename?: 'lesson_unlock_keys_avg_fields';
  secret_key?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "lesson_unlock_keys". All fields are combined with a logical 'AND'. */
export interface ILessonUnlockKeysBoolExp {
  _and?: InputMaybe<Array<ILessonUnlockKeysBoolExp>>;
  _not?: InputMaybe<ILessonUnlockKeysBoolExp>;
  _or?: InputMaybe<Array<ILessonUnlockKeysBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  secret_key?: InputMaybe<IIntComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** unique or primary key constraints on table "lesson_unlock_keys" */
export enum ILessonUnlockKeysConstraint {
  /** unique or primary key constraint */
  LESSON_UNLOCK_KEYS_PKEY = 'lesson_unlock_keys_pkey',
  /** unique or primary key constraint */
  LESSON_UNLOCK_KEYS_USER_ID_KEY = 'lesson_unlock_keys_user_id_key'
}

/** input type for incrementing numeric columns in table "lesson_unlock_keys" */
export interface ILessonUnlockKeysIncInput {
  secret_key?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "lesson_unlock_keys" */
export interface ILessonUnlockKeysInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  secret_key?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<IUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface ILessonUnlockKeysMaxFields {
  __typename?: 'lesson_unlock_keys_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  secret_key?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface ILessonUnlockKeysMinFields {
  __typename?: 'lesson_unlock_keys_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  secret_key?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** response of any mutation on the table "lesson_unlock_keys" */
export interface ILessonUnlockKeysMutationResponse {
  __typename?: 'lesson_unlock_keys_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ILessonUnlockKeys>;
}

/** on_conflict condition type for table "lesson_unlock_keys" */
export interface ILessonUnlockKeysOnConflict {
  constraint: ILessonUnlockKeysConstraint;
  update_columns?: Array<ILessonUnlockKeysUpdateColumn>;
  where?: InputMaybe<ILessonUnlockKeysBoolExp>;
}

/** Ordering options when selecting data from "lesson_unlock_keys". */
export interface ILessonUnlockKeysOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  secret_key?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: lesson_unlock_keys */
export interface ILessonUnlockKeysPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "lesson_unlock_keys" */
export enum ILessonUnlockKeysSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  SECRET_KEY = 'secret_key',
  /** column name */
  USER_ID = 'user_id'
}

/** input type for updating data in table "lesson_unlock_keys" */
export interface ILessonUnlockKeysSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  secret_key?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate stddev on columns */
export interface ILessonUnlockKeysStddevFields {
  __typename?: 'lesson_unlock_keys_stddev_fields';
  secret_key?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface ILessonUnlockKeysStddevPopFields {
  __typename?: 'lesson_unlock_keys_stddev_pop_fields';
  secret_key?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface ILessonUnlockKeysStddevSampFields {
  __typename?: 'lesson_unlock_keys_stddev_samp_fields';
  secret_key?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface ILessonUnlockKeysSumFields {
  __typename?: 'lesson_unlock_keys_sum_fields';
  secret_key?: Maybe<Scalars['Int']>;
}

/** update columns of table "lesson_unlock_keys" */
export enum ILessonUnlockKeysUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  SECRET_KEY = 'secret_key',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate var_pop on columns */
export interface ILessonUnlockKeysVarPopFields {
  __typename?: 'lesson_unlock_keys_var_pop_fields';
  secret_key?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface ILessonUnlockKeysVarSampFields {
  __typename?: 'lesson_unlock_keys_var_samp_fields';
  secret_key?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface ILessonUnlockKeysVarianceFields {
  __typename?: 'lesson_unlock_keys_variance_fields';
  secret_key?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "lesson_verses" */
export interface ILessonVerses {
  __typename?: 'lesson_verses';
  created_at: Scalars['timestamptz'];
  duration?: Maybe<Scalars['Int']>;
  fragment_url?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  lesson_id: Scalars['uuid'];
  /** An object relationship */
  lesson_verses_attachement?: Maybe<ILessonVersesAttachments>;
  pronunciation?: Maybe<Scalars['String']>;
  sequence: Scalars['Int'];
  translation?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  verse?: Maybe<Scalars['String']>;
}

/** aggregated selection of "lesson_verses" */
export interface ILessonVersesAggregate {
  __typename?: 'lesson_verses_aggregate';
  aggregate?: Maybe<ILessonVersesAggregateFields>;
  nodes: Array<ILessonVerses>;
}

/** aggregate fields of "lesson_verses" */
export interface ILessonVersesAggregateFields {
  __typename?: 'lesson_verses_aggregate_fields';
  avg?: Maybe<ILessonVersesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ILessonVersesMaxFields>;
  min?: Maybe<ILessonVersesMinFields>;
  stddev?: Maybe<ILessonVersesStddevFields>;
  stddev_pop?: Maybe<ILessonVersesStddevPopFields>;
  stddev_samp?: Maybe<ILessonVersesStddevSampFields>;
  sum?: Maybe<ILessonVersesSumFields>;
  var_pop?: Maybe<ILessonVersesVarPopFields>;
  var_samp?: Maybe<ILessonVersesVarSampFields>;
  variance?: Maybe<ILessonVersesVarianceFields>;
}


/** aggregate fields of "lesson_verses" */
export interface ILessonVersesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ILessonVersesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "lesson_verses" */
export interface ILessonVersesAggregateOrderBy {
  avg?: InputMaybe<ILessonVersesAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<ILessonVersesMaxOrderBy>;
  min?: InputMaybe<ILessonVersesMinOrderBy>;
  stddev?: InputMaybe<ILessonVersesStddevOrderBy>;
  stddev_pop?: InputMaybe<ILessonVersesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ILessonVersesStddevSampOrderBy>;
  sum?: InputMaybe<ILessonVersesSumOrderBy>;
  var_pop?: InputMaybe<ILessonVersesVarPopOrderBy>;
  var_samp?: InputMaybe<ILessonVersesVarSampOrderBy>;
  variance?: InputMaybe<ILessonVersesVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "lesson_verses" */
export interface ILessonVersesArrRelInsertInput {
  data: Array<ILessonVersesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ILessonVersesOnConflict>;
}

/** columns and relationships of "lesson_verses_attachments" */
export interface ILessonVersesAttachments {
  __typename?: 'lesson_verses_attachments';
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  lesson_verse_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "lesson_verses_attachments" */
export interface ILessonVersesAttachmentsAggregate {
  __typename?: 'lesson_verses_attachments_aggregate';
  aggregate?: Maybe<ILessonVersesAttachmentsAggregateFields>;
  nodes: Array<ILessonVersesAttachments>;
}

/** aggregate fields of "lesson_verses_attachments" */
export interface ILessonVersesAttachmentsAggregateFields {
  __typename?: 'lesson_verses_attachments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ILessonVersesAttachmentsMaxFields>;
  min?: Maybe<ILessonVersesAttachmentsMinFields>;
}


/** aggregate fields of "lesson_verses_attachments" */
export interface ILessonVersesAttachmentsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ILessonVersesAttachmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "lesson_verses_attachments". All fields are combined with a logical 'AND'. */
export interface ILessonVersesAttachmentsBoolExp {
  _and?: InputMaybe<Array<ILessonVersesAttachmentsBoolExp>>;
  _not?: InputMaybe<ILessonVersesAttachmentsBoolExp>;
  _or?: InputMaybe<Array<ILessonVersesAttachmentsBoolExp>>;
  created_at?: InputMaybe<ITimestampComparisonExp>;
  filename?: InputMaybe<IStringComparisonExp>;
  key?: InputMaybe<IStringComparisonExp>;
  lesson_verse_id?: InputMaybe<IUuidComparisonExp>;
}

/** input type for inserting data into table "lesson_verses_attachments" */
export interface ILessonVersesAttachmentsInsertInput {
  created_at?: InputMaybe<Scalars['timestamp']>;
  filename?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  lesson_verse_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface ILessonVersesAttachmentsMaxFields {
  __typename?: 'lesson_verses_attachments_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  lesson_verse_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface ILessonVersesAttachmentsMinFields {
  __typename?: 'lesson_verses_attachments_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  lesson_verse_id?: Maybe<Scalars['uuid']>;
}

/** input type for inserting object relation for remote table "lesson_verses_attachments" */
export interface ILessonVersesAttachmentsObjRelInsertInput {
  data: ILessonVersesAttachmentsInsertInput;
}

/** Ordering options when selecting data from "lesson_verses_attachments". */
export interface ILessonVersesAttachmentsOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  filename?: InputMaybe<IOrderBy>;
  key?: InputMaybe<IOrderBy>;
  lesson_verse_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "lesson_verses_attachments" */
export enum ILessonVersesAttachmentsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  FILENAME = 'filename',
  /** column name */
  KEY = 'key',
  /** column name */
  LESSON_VERSE_ID = 'lesson_verse_id'
}

/** aggregate avg on columns */
export interface ILessonVersesAvgFields {
  __typename?: 'lesson_verses_avg_fields';
  duration?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "lesson_verses" */
export interface ILessonVersesAvgOrderBy {
  duration?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "lesson_verses". All fields are combined with a logical 'AND'. */
export interface ILessonVersesBoolExp {
  _and?: InputMaybe<Array<ILessonVersesBoolExp>>;
  _not?: InputMaybe<ILessonVersesBoolExp>;
  _or?: InputMaybe<Array<ILessonVersesBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  duration?: InputMaybe<IIntComparisonExp>;
  fragment_url?: InputMaybe<IStringComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  lesson_verses_attachement?: InputMaybe<ILessonVersesAttachmentsBoolExp>;
  pronunciation?: InputMaybe<IStringComparisonExp>;
  sequence?: InputMaybe<IIntComparisonExp>;
  translation?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  verse?: InputMaybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "lesson_verses" */
export enum ILessonVersesConstraint {
  /** unique or primary key constraint */
  LESSON_VERSES_PKEY = 'lesson_verses_pkey'
}

/** input type for incrementing numeric columns in table "lesson_verses" */
export interface ILessonVersesIncInput {
  duration?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "lesson_verses" */
export interface ILessonVersesInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['Int']>;
  fragment_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  lesson_verses_attachement?: InputMaybe<ILessonVersesAttachmentsObjRelInsertInput>;
  pronunciation?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  translation?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  verse?: InputMaybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface ILessonVersesMaxFields {
  __typename?: 'lesson_verses_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  duration?: Maybe<Scalars['Int']>;
  fragment_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  pronunciation?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  translation?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verse?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "lesson_verses" */
export interface ILessonVersesMaxOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  duration?: InputMaybe<IOrderBy>;
  fragment_url?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  pronunciation?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  translation?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  verse?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface ILessonVersesMinFields {
  __typename?: 'lesson_verses_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  duration?: Maybe<Scalars['Int']>;
  fragment_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  pronunciation?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  translation?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verse?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "lesson_verses" */
export interface ILessonVersesMinOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  duration?: InputMaybe<IOrderBy>;
  fragment_url?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  pronunciation?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  translation?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  verse?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "lesson_verses" */
export interface ILessonVersesMutationResponse {
  __typename?: 'lesson_verses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ILessonVerses>;
}

/** on_conflict condition type for table "lesson_verses" */
export interface ILessonVersesOnConflict {
  constraint: ILessonVersesConstraint;
  update_columns?: Array<ILessonVersesUpdateColumn>;
  where?: InputMaybe<ILessonVersesBoolExp>;
}

/** Ordering options when selecting data from "lesson_verses". */
export interface ILessonVersesOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  duration?: InputMaybe<IOrderBy>;
  fragment_url?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  lesson_verses_attachement?: InputMaybe<ILessonVersesAttachmentsOrderBy>;
  pronunciation?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  translation?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  verse?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: lesson_verses */
export interface ILessonVersesPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "lesson_verses" */
export enum ILessonVersesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DURATION = 'duration',
  /** column name */
  FRAGMENT_URL = 'fragment_url',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  PRONUNCIATION = 'pronunciation',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  TRANSLATION = 'translation',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  VERSE = 'verse'
}

/** input type for updating data in table "lesson_verses" */
export interface ILessonVersesSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['Int']>;
  fragment_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  pronunciation?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  translation?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  verse?: InputMaybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface ILessonVersesStddevFields {
  __typename?: 'lesson_verses_stddev_fields';
  duration?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "lesson_verses" */
export interface ILessonVersesStddevOrderBy {
  duration?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface ILessonVersesStddevPopFields {
  __typename?: 'lesson_verses_stddev_pop_fields';
  duration?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "lesson_verses" */
export interface ILessonVersesStddevPopOrderBy {
  duration?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface ILessonVersesStddevSampFields {
  __typename?: 'lesson_verses_stddev_samp_fields';
  duration?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "lesson_verses" */
export interface ILessonVersesStddevSampOrderBy {
  duration?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface ILessonVersesSumFields {
  __typename?: 'lesson_verses_sum_fields';
  duration?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "lesson_verses" */
export interface ILessonVersesSumOrderBy {
  duration?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
}

/** update columns of table "lesson_verses" */
export enum ILessonVersesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DURATION = 'duration',
  /** column name */
  FRAGMENT_URL = 'fragment_url',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  PRONUNCIATION = 'pronunciation',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  TRANSLATION = 'translation',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  VERSE = 'verse'
}

/** aggregate var_pop on columns */
export interface ILessonVersesVarPopFields {
  __typename?: 'lesson_verses_var_pop_fields';
  duration?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "lesson_verses" */
export interface ILessonVersesVarPopOrderBy {
  duration?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface ILessonVersesVarSampFields {
  __typename?: 'lesson_verses_var_samp_fields';
  duration?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "lesson_verses" */
export interface ILessonVersesVarSampOrderBy {
  duration?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface ILessonVersesVarianceFields {
  __typename?: 'lesson_verses_variance_fields';
  duration?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "lesson_verses" */
export interface ILessonVersesVarianceOrderBy {
  duration?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
}

/** columns and relationships of "lesson_words" */
export interface ILessonWords {
  __typename?: 'lesson_words';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  lesson_id: Scalars['uuid'];
  meaning: Scalars['String'];
  sequence?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  word: Scalars['String'];
}

/** aggregated selection of "lesson_words" */
export interface ILessonWordsAggregate {
  __typename?: 'lesson_words_aggregate';
  aggregate?: Maybe<ILessonWordsAggregateFields>;
  nodes: Array<ILessonWords>;
}

/** aggregate fields of "lesson_words" */
export interface ILessonWordsAggregateFields {
  __typename?: 'lesson_words_aggregate_fields';
  avg?: Maybe<ILessonWordsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ILessonWordsMaxFields>;
  min?: Maybe<ILessonWordsMinFields>;
  stddev?: Maybe<ILessonWordsStddevFields>;
  stddev_pop?: Maybe<ILessonWordsStddevPopFields>;
  stddev_samp?: Maybe<ILessonWordsStddevSampFields>;
  sum?: Maybe<ILessonWordsSumFields>;
  var_pop?: Maybe<ILessonWordsVarPopFields>;
  var_samp?: Maybe<ILessonWordsVarSampFields>;
  variance?: Maybe<ILessonWordsVarianceFields>;
}


/** aggregate fields of "lesson_words" */
export interface ILessonWordsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ILessonWordsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "lesson_words" */
export interface ILessonWordsAggregateOrderBy {
  avg?: InputMaybe<ILessonWordsAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<ILessonWordsMaxOrderBy>;
  min?: InputMaybe<ILessonWordsMinOrderBy>;
  stddev?: InputMaybe<ILessonWordsStddevOrderBy>;
  stddev_pop?: InputMaybe<ILessonWordsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ILessonWordsStddevSampOrderBy>;
  sum?: InputMaybe<ILessonWordsSumOrderBy>;
  var_pop?: InputMaybe<ILessonWordsVarPopOrderBy>;
  var_samp?: InputMaybe<ILessonWordsVarSampOrderBy>;
  variance?: InputMaybe<ILessonWordsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "lesson_words" */
export interface ILessonWordsArrRelInsertInput {
  data: Array<ILessonWordsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ILessonWordsOnConflict>;
}

/** aggregate avg on columns */
export interface ILessonWordsAvgFields {
  __typename?: 'lesson_words_avg_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "lesson_words" */
export interface ILessonWordsAvgOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "lesson_words". All fields are combined with a logical 'AND'. */
export interface ILessonWordsBoolExp {
  _and?: InputMaybe<Array<ILessonWordsBoolExp>>;
  _not?: InputMaybe<ILessonWordsBoolExp>;
  _or?: InputMaybe<Array<ILessonWordsBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  meaning?: InputMaybe<IStringComparisonExp>;
  sequence?: InputMaybe<IIntComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  word?: InputMaybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "lesson_words" */
export enum ILessonWordsConstraint {
  /** unique or primary key constraint */
  LESSON_WORDS_PKEY = 'lesson_words_pkey'
}

/** input type for incrementing numeric columns in table "lesson_words" */
export interface ILessonWordsIncInput {
  sequence?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "lesson_words" */
export interface ILessonWordsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  meaning?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  word?: InputMaybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface ILessonWordsMaxFields {
  __typename?: 'lesson_words_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  meaning?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  word?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "lesson_words" */
export interface ILessonWordsMaxOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  meaning?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  word?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface ILessonWordsMinFields {
  __typename?: 'lesson_words_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  meaning?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  word?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "lesson_words" */
export interface ILessonWordsMinOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  meaning?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  word?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "lesson_words" */
export interface ILessonWordsMutationResponse {
  __typename?: 'lesson_words_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ILessonWords>;
}

/** on_conflict condition type for table "lesson_words" */
export interface ILessonWordsOnConflict {
  constraint: ILessonWordsConstraint;
  update_columns?: Array<ILessonWordsUpdateColumn>;
  where?: InputMaybe<ILessonWordsBoolExp>;
}

/** Ordering options when selecting data from "lesson_words". */
export interface ILessonWordsOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  meaning?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  word?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: lesson_words */
export interface ILessonWordsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "lesson_words" */
export enum ILessonWordsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  MEANING = 'meaning',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  WORD = 'word'
}

/** input type for updating data in table "lesson_words" */
export interface ILessonWordsSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  meaning?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  word?: InputMaybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface ILessonWordsStddevFields {
  __typename?: 'lesson_words_stddev_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "lesson_words" */
export interface ILessonWordsStddevOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface ILessonWordsStddevPopFields {
  __typename?: 'lesson_words_stddev_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "lesson_words" */
export interface ILessonWordsStddevPopOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface ILessonWordsStddevSampFields {
  __typename?: 'lesson_words_stddev_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "lesson_words" */
export interface ILessonWordsStddevSampOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface ILessonWordsSumFields {
  __typename?: 'lesson_words_sum_fields';
  sequence?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "lesson_words" */
export interface ILessonWordsSumOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** update columns of table "lesson_words" */
export enum ILessonWordsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  MEANING = 'meaning',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  WORD = 'word'
}

/** aggregate var_pop on columns */
export interface ILessonWordsVarPopFields {
  __typename?: 'lesson_words_var_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "lesson_words" */
export interface ILessonWordsVarPopOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface ILessonWordsVarSampFields {
  __typename?: 'lesson_words_var_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "lesson_words" */
export interface ILessonWordsVarSampOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface ILessonWordsVarianceFields {
  __typename?: 'lesson_words_variance_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "lesson_words" */
export interface ILessonWordsVarianceOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** columns and relationships of "lessons" */
export interface ILessons {
  __typename?: 'lessons';
  content?: Maybe<Scalars['String']>;
  /** An object relationship */
  course?: Maybe<ICourses>;
  course_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  description_video?: Maybe<Scalars['String']>;
  duration: Scalars['Int'];
  id: Scalars['uuid'];
  image: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  /** An array relationship */
  lesson_days: Array<ILessonDays>;
  /** An aggregate relationship */
  lesson_days_aggregate: ILessonDaysAggregate;
  lesson_number: Scalars['numeric'];
  /** An array relationship */
  lesson_partitions: Array<ILessonPartitions>;
  /** An aggregate relationship */
  lesson_partitions_aggregate: ILessonPartitionsAggregate;
  /** An array relationship */
  lessons_unlockeds: Array<ILessonsUnlocked>;
  /** An aggregate relationship */
  lessons_unlockeds_aggregate: ILessonsUnlockedAggregate;
  name: Scalars['String'];
  published: Scalars['Boolean'];
  qa_content?: Maybe<Scalars['String']>;
  /** An array relationship */
  questions: Array<IQuestions>;
  /** An aggregate relationship */
  questions_aggregate: IQuestionsAggregate;
  quiz?: Maybe<Scalars['String']>;
  second_part?: Maybe<Scalars['String']>;
  second_part_duration?: Maybe<Scalars['Int']>;
  sequence: Scalars['Int'];
  /** An object relationship */
  subscription?: Maybe<ISubscriptions>;
  surah?: Maybe<Scalars['String']>;
  surah_description?: Maybe<Scalars['String']>;
  surah_second_part?: Maybe<Scalars['String']>;
  surah_third_part?: Maybe<Scalars['String']>;
  third_part?: Maybe<Scalars['String']>;
  third_part_duration?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user_lesson_progress?: Maybe<IUserLessonProgresses>;
  /** An object relationship */
  with_lock?: Maybe<ILessonsWithLock>;
}


/** columns and relationships of "lessons" */
export interface ILessonsLessonDaysArgs {
  distinct_on?: InputMaybe<Array<ILessonDaysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonDaysOrderBy>>;
  where?: InputMaybe<ILessonDaysBoolExp>;
}


/** columns and relationships of "lessons" */
export interface ILessonsLessonDaysAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonDaysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonDaysOrderBy>>;
  where?: InputMaybe<ILessonDaysBoolExp>;
}


/** columns and relationships of "lessons" */
export interface ILessonsLessonPartitionsArgs {
  distinct_on?: InputMaybe<Array<ILessonPartitionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonPartitionsOrderBy>>;
  where?: InputMaybe<ILessonPartitionsBoolExp>;
}


/** columns and relationships of "lessons" */
export interface ILessonsLessonPartitionsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonPartitionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonPartitionsOrderBy>>;
  where?: InputMaybe<ILessonPartitionsBoolExp>;
}


/** columns and relationships of "lessons" */
export interface ILessonsLessonsUnlockedsArgs {
  distinct_on?: InputMaybe<Array<ILessonsUnlockedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsUnlockedOrderBy>>;
  where?: InputMaybe<ILessonsUnlockedBoolExp>;
}


/** columns and relationships of "lessons" */
export interface ILessonsLessonsUnlockedsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonsUnlockedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsUnlockedOrderBy>>;
  where?: InputMaybe<ILessonsUnlockedBoolExp>;
}


/** columns and relationships of "lessons" */
export interface ILessonsQuestionsArgs {
  distinct_on?: InputMaybe<Array<IQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IQuestionsOrderBy>>;
  where?: InputMaybe<IQuestionsBoolExp>;
}


/** columns and relationships of "lessons" */
export interface ILessonsQuestionsAggregateArgs {
  distinct_on?: InputMaybe<Array<IQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IQuestionsOrderBy>>;
  where?: InputMaybe<IQuestionsBoolExp>;
}

/** aggregated selection of "lessons" */
export interface ILessonsAggregate {
  __typename?: 'lessons_aggregate';
  aggregate?: Maybe<ILessonsAggregateFields>;
  nodes: Array<ILessons>;
}

/** aggregate fields of "lessons" */
export interface ILessonsAggregateFields {
  __typename?: 'lessons_aggregate_fields';
  avg?: Maybe<ILessonsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ILessonsMaxFields>;
  min?: Maybe<ILessonsMinFields>;
  stddev?: Maybe<ILessonsStddevFields>;
  stddev_pop?: Maybe<ILessonsStddevPopFields>;
  stddev_samp?: Maybe<ILessonsStddevSampFields>;
  sum?: Maybe<ILessonsSumFields>;
  var_pop?: Maybe<ILessonsVarPopFields>;
  var_samp?: Maybe<ILessonsVarSampFields>;
  variance?: Maybe<ILessonsVarianceFields>;
}


/** aggregate fields of "lessons" */
export interface ILessonsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ILessonsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "lessons" */
export interface ILessonsAggregateOrderBy {
  avg?: InputMaybe<ILessonsAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<ILessonsMaxOrderBy>;
  min?: InputMaybe<ILessonsMinOrderBy>;
  stddev?: InputMaybe<ILessonsStddevOrderBy>;
  stddev_pop?: InputMaybe<ILessonsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ILessonsStddevSampOrderBy>;
  sum?: InputMaybe<ILessonsSumOrderBy>;
  var_pop?: InputMaybe<ILessonsVarPopOrderBy>;
  var_samp?: InputMaybe<ILessonsVarSampOrderBy>;
  variance?: InputMaybe<ILessonsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "lessons" */
export interface ILessonsArrRelInsertInput {
  data: Array<ILessonsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ILessonsOnConflict>;
}

/** aggregate avg on columns */
export interface ILessonsAvgFields {
  __typename?: 'lessons_avg_fields';
  duration?: Maybe<Scalars['Float']>;
  lesson_number?: Maybe<Scalars['Float']>;
  second_part_duration?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  third_part_duration?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "lessons" */
export interface ILessonsAvgOrderBy {
  duration?: InputMaybe<IOrderBy>;
  lesson_number?: InputMaybe<IOrderBy>;
  second_part_duration?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  third_part_duration?: InputMaybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "lessons". All fields are combined with a logical 'AND'. */
export interface ILessonsBoolExp {
  _and?: InputMaybe<Array<ILessonsBoolExp>>;
  _not?: InputMaybe<ILessonsBoolExp>;
  _or?: InputMaybe<Array<ILessonsBoolExp>>;
  content?: InputMaybe<IStringComparisonExp>;
  course?: InputMaybe<ICoursesBoolExp>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  description_video?: InputMaybe<IStringComparisonExp>;
  duration?: InputMaybe<IIntComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  image?: InputMaybe<IStringComparisonExp>;
  label?: InputMaybe<IStringComparisonExp>;
  lesson_days?: InputMaybe<ILessonDaysBoolExp>;
  lesson_number?: InputMaybe<INumericComparisonExp>;
  lesson_partitions?: InputMaybe<ILessonPartitionsBoolExp>;
  lessons_unlockeds?: InputMaybe<ILessonsUnlockedBoolExp>;
  name?: InputMaybe<IStringComparisonExp>;
  published?: InputMaybe<IBooleanComparisonExp>;
  qa_content?: InputMaybe<IStringComparisonExp>;
  questions?: InputMaybe<IQuestionsBoolExp>;
  quiz?: InputMaybe<IStringComparisonExp>;
  second_part?: InputMaybe<IStringComparisonExp>;
  second_part_duration?: InputMaybe<IIntComparisonExp>;
  sequence?: InputMaybe<IIntComparisonExp>;
  subscription?: InputMaybe<ISubscriptionsBoolExp>;
  surah?: InputMaybe<IStringComparisonExp>;
  surah_description?: InputMaybe<IStringComparisonExp>;
  surah_second_part?: InputMaybe<IStringComparisonExp>;
  surah_third_part?: InputMaybe<IStringComparisonExp>;
  third_part?: InputMaybe<IStringComparisonExp>;
  third_part_duration?: InputMaybe<IIntComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  user_lesson_progress?: InputMaybe<IUserLessonProgressesBoolExp>;
  with_lock?: InputMaybe<ILessonsWithLockBoolExp>;
}

/** unique or primary key constraints on table "lessons" */
export enum ILessonsConstraint {
  /** unique or primary key constraint */
  LESSON_PKEY = 'lesson_pkey'
}

/** input type for incrementing numeric columns in table "lessons" */
export interface ILessonsIncInput {
  duration?: InputMaybe<Scalars['Int']>;
  lesson_number?: InputMaybe<Scalars['numeric']>;
  second_part_duration?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  third_part_duration?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "lessons" */
export interface ILessonsInsertInput {
  content?: InputMaybe<Scalars['String']>;
  course?: InputMaybe<ICoursesObjRelInsertInput>;
  course_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  description_video?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  lesson_days?: InputMaybe<ILessonDaysArrRelInsertInput>;
  lesson_number?: InputMaybe<Scalars['numeric']>;
  lesson_partitions?: InputMaybe<ILessonPartitionsArrRelInsertInput>;
  lessons_unlockeds?: InputMaybe<ILessonsUnlockedArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  qa_content?: InputMaybe<Scalars['String']>;
  questions?: InputMaybe<IQuestionsArrRelInsertInput>;
  quiz?: InputMaybe<Scalars['String']>;
  second_part?: InputMaybe<Scalars['String']>;
  second_part_duration?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  subscription?: InputMaybe<ISubscriptionsObjRelInsertInput>;
  surah?: InputMaybe<Scalars['String']>;
  surah_description?: InputMaybe<Scalars['String']>;
  surah_second_part?: InputMaybe<Scalars['String']>;
  surah_third_part?: InputMaybe<Scalars['String']>;
  third_part?: InputMaybe<Scalars['String']>;
  third_part_duration?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_lesson_progress?: InputMaybe<IUserLessonProgressesObjRelInsertInput>;
  with_lock?: InputMaybe<ILessonsWithLockObjRelInsertInput>;
}

/** aggregate max on columns */
export interface ILessonsMaxFields {
  __typename?: 'lessons_max_fields';
  content?: Maybe<Scalars['String']>;
  course_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  description_video?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  lesson_number?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  qa_content?: Maybe<Scalars['String']>;
  quiz?: Maybe<Scalars['String']>;
  second_part?: Maybe<Scalars['String']>;
  second_part_duration?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  surah?: Maybe<Scalars['String']>;
  surah_description?: Maybe<Scalars['String']>;
  surah_second_part?: Maybe<Scalars['String']>;
  surah_third_part?: Maybe<Scalars['String']>;
  third_part?: Maybe<Scalars['String']>;
  third_part_duration?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by max() on columns of table "lessons" */
export interface ILessonsMaxOrderBy {
  content?: InputMaybe<IOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  description_video?: InputMaybe<IOrderBy>;
  duration?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  image?: InputMaybe<IOrderBy>;
  label?: InputMaybe<IOrderBy>;
  lesson_number?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  qa_content?: InputMaybe<IOrderBy>;
  quiz?: InputMaybe<IOrderBy>;
  second_part?: InputMaybe<IOrderBy>;
  second_part_duration?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  surah?: InputMaybe<IOrderBy>;
  surah_description?: InputMaybe<IOrderBy>;
  surah_second_part?: InputMaybe<IOrderBy>;
  surah_third_part?: InputMaybe<IOrderBy>;
  third_part?: InputMaybe<IOrderBy>;
  third_part_duration?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface ILessonsMinFields {
  __typename?: 'lessons_min_fields';
  content?: Maybe<Scalars['String']>;
  course_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  description_video?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  lesson_number?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  qa_content?: Maybe<Scalars['String']>;
  quiz?: Maybe<Scalars['String']>;
  second_part?: Maybe<Scalars['String']>;
  second_part_duration?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  surah?: Maybe<Scalars['String']>;
  surah_description?: Maybe<Scalars['String']>;
  surah_second_part?: Maybe<Scalars['String']>;
  surah_third_part?: Maybe<Scalars['String']>;
  third_part?: Maybe<Scalars['String']>;
  third_part_duration?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by min() on columns of table "lessons" */
export interface ILessonsMinOrderBy {
  content?: InputMaybe<IOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  description_video?: InputMaybe<IOrderBy>;
  duration?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  image?: InputMaybe<IOrderBy>;
  label?: InputMaybe<IOrderBy>;
  lesson_number?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  qa_content?: InputMaybe<IOrderBy>;
  quiz?: InputMaybe<IOrderBy>;
  second_part?: InputMaybe<IOrderBy>;
  second_part_duration?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  surah?: InputMaybe<IOrderBy>;
  surah_description?: InputMaybe<IOrderBy>;
  surah_second_part?: InputMaybe<IOrderBy>;
  surah_third_part?: InputMaybe<IOrderBy>;
  third_part?: InputMaybe<IOrderBy>;
  third_part_duration?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "lessons" */
export interface ILessonsMutationResponse {
  __typename?: 'lessons_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ILessons>;
}

/** input type for inserting object relation for remote table "lessons" */
export interface ILessonsObjRelInsertInput {
  data: ILessonsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ILessonsOnConflict>;
}

/** on_conflict condition type for table "lessons" */
export interface ILessonsOnConflict {
  constraint: ILessonsConstraint;
  update_columns?: Array<ILessonsUpdateColumn>;
  where?: InputMaybe<ILessonsBoolExp>;
}

/** Ordering options when selecting data from "lessons". */
export interface ILessonsOrderBy {
  content?: InputMaybe<IOrderBy>;
  course?: InputMaybe<ICoursesOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  description_video?: InputMaybe<IOrderBy>;
  duration?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  image?: InputMaybe<IOrderBy>;
  label?: InputMaybe<IOrderBy>;
  lesson_days_aggregate?: InputMaybe<ILessonDaysAggregateOrderBy>;
  lesson_number?: InputMaybe<IOrderBy>;
  lesson_partitions_aggregate?: InputMaybe<ILessonPartitionsAggregateOrderBy>;
  lessons_unlockeds_aggregate?: InputMaybe<ILessonsUnlockedAggregateOrderBy>;
  name?: InputMaybe<IOrderBy>;
  published?: InputMaybe<IOrderBy>;
  qa_content?: InputMaybe<IOrderBy>;
  questions_aggregate?: InputMaybe<IQuestionsAggregateOrderBy>;
  quiz?: InputMaybe<IOrderBy>;
  second_part?: InputMaybe<IOrderBy>;
  second_part_duration?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  subscription?: InputMaybe<ISubscriptionsOrderBy>;
  surah?: InputMaybe<IOrderBy>;
  surah_description?: InputMaybe<IOrderBy>;
  surah_second_part?: InputMaybe<IOrderBy>;
  surah_third_part?: InputMaybe<IOrderBy>;
  third_part?: InputMaybe<IOrderBy>;
  third_part_duration?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user_lesson_progress?: InputMaybe<IUserLessonProgressesOrderBy>;
  with_lock?: InputMaybe<ILessonsWithLockOrderBy>;
}

/** primary key columns input for table: lessons */
export interface ILessonsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "lessons" */
export enum ILessonsSelectColumn {
  /** column name */
  CONTENT = 'content',
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  DESCRIPTION_VIDEO = 'description_video',
  /** column name */
  DURATION = 'duration',
  /** column name */
  ID = 'id',
  /** column name */
  IMAGE = 'image',
  /** column name */
  LABEL = 'label',
  /** column name */
  LESSON_NUMBER = 'lesson_number',
  /** column name */
  NAME = 'name',
  /** column name */
  PUBLISHED = 'published',
  /** column name */
  QA_CONTENT = 'qa_content',
  /** column name */
  QUIZ = 'quiz',
  /** column name */
  SECOND_PART = 'second_part',
  /** column name */
  SECOND_PART_DURATION = 'second_part_duration',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  SURAH = 'surah',
  /** column name */
  SURAH_DESCRIPTION = 'surah_description',
  /** column name */
  SURAH_SECOND_PART = 'surah_second_part',
  /** column name */
  SURAH_THIRD_PART = 'surah_third_part',
  /** column name */
  THIRD_PART = 'third_part',
  /** column name */
  THIRD_PART_DURATION = 'third_part_duration',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "lessons" */
export interface ILessonsSetInput {
  content?: InputMaybe<Scalars['String']>;
  course_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  description_video?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  lesson_number?: InputMaybe<Scalars['numeric']>;
  name?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  qa_content?: InputMaybe<Scalars['String']>;
  quiz?: InputMaybe<Scalars['String']>;
  second_part?: InputMaybe<Scalars['String']>;
  second_part_duration?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  surah?: InputMaybe<Scalars['String']>;
  surah_description?: InputMaybe<Scalars['String']>;
  surah_second_part?: InputMaybe<Scalars['String']>;
  surah_third_part?: InputMaybe<Scalars['String']>;
  third_part?: InputMaybe<Scalars['String']>;
  third_part_duration?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface ILessonsStddevFields {
  __typename?: 'lessons_stddev_fields';
  duration?: Maybe<Scalars['Float']>;
  lesson_number?: Maybe<Scalars['Float']>;
  second_part_duration?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  third_part_duration?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "lessons" */
export interface ILessonsStddevOrderBy {
  duration?: InputMaybe<IOrderBy>;
  lesson_number?: InputMaybe<IOrderBy>;
  second_part_duration?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  third_part_duration?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface ILessonsStddevPopFields {
  __typename?: 'lessons_stddev_pop_fields';
  duration?: Maybe<Scalars['Float']>;
  lesson_number?: Maybe<Scalars['Float']>;
  second_part_duration?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  third_part_duration?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "lessons" */
export interface ILessonsStddevPopOrderBy {
  duration?: InputMaybe<IOrderBy>;
  lesson_number?: InputMaybe<IOrderBy>;
  second_part_duration?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  third_part_duration?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface ILessonsStddevSampFields {
  __typename?: 'lessons_stddev_samp_fields';
  duration?: Maybe<Scalars['Float']>;
  lesson_number?: Maybe<Scalars['Float']>;
  second_part_duration?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  third_part_duration?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "lessons" */
export interface ILessonsStddevSampOrderBy {
  duration?: InputMaybe<IOrderBy>;
  lesson_number?: InputMaybe<IOrderBy>;
  second_part_duration?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  third_part_duration?: InputMaybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface ILessonsSumFields {
  __typename?: 'lessons_sum_fields';
  duration?: Maybe<Scalars['Int']>;
  lesson_number?: Maybe<Scalars['numeric']>;
  second_part_duration?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  third_part_duration?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "lessons" */
export interface ILessonsSumOrderBy {
  duration?: InputMaybe<IOrderBy>;
  lesson_number?: InputMaybe<IOrderBy>;
  second_part_duration?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  third_part_duration?: InputMaybe<IOrderBy>;
}

/** columns and relationships of "lessons_unlocked" */
export interface ILessonsUnlocked {
  __typename?: 'lessons_unlocked';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  lesson: ILessons;
  lesson_id: Scalars['uuid'];
  user_child_id: Scalars['uuid'];
  /** An object relationship */
  users_child: IUsersChildren;
}

/** aggregated selection of "lessons_unlocked" */
export interface ILessonsUnlockedAggregate {
  __typename?: 'lessons_unlocked_aggregate';
  aggregate?: Maybe<ILessonsUnlockedAggregateFields>;
  nodes: Array<ILessonsUnlocked>;
}

/** aggregate fields of "lessons_unlocked" */
export interface ILessonsUnlockedAggregateFields {
  __typename?: 'lessons_unlocked_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ILessonsUnlockedMaxFields>;
  min?: Maybe<ILessonsUnlockedMinFields>;
}


/** aggregate fields of "lessons_unlocked" */
export interface ILessonsUnlockedAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ILessonsUnlockedSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "lessons_unlocked" */
export interface ILessonsUnlockedAggregateOrderBy {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<ILessonsUnlockedMaxOrderBy>;
  min?: InputMaybe<ILessonsUnlockedMinOrderBy>;
}

/** input type for inserting array relation for remote table "lessons_unlocked" */
export interface ILessonsUnlockedArrRelInsertInput {
  data: Array<ILessonsUnlockedInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ILessonsUnlockedOnConflict>;
}

/** Boolean expression to filter rows from the table "lessons_unlocked". All fields are combined with a logical 'AND'. */
export interface ILessonsUnlockedBoolExp {
  _and?: InputMaybe<Array<ILessonsUnlockedBoolExp>>;
  _not?: InputMaybe<ILessonsUnlockedBoolExp>;
  _or?: InputMaybe<Array<ILessonsUnlockedBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  user_child_id?: InputMaybe<IUuidComparisonExp>;
  users_child?: InputMaybe<IUsersChildrenBoolExp>;
}

/** unique or primary key constraints on table "lessons_unlocked" */
export enum ILessonsUnlockedConstraint {
  /** unique or primary key constraint */
  LESSONS_UNLOCKED_LESSON_ID_USER_CHILD_ID_KEY = 'lessons_unlocked_lesson_id_user_child_id_key',
  /** unique or primary key constraint */
  LESSONS_UNLOCKED_PKEY = 'lessons_unlocked_pkey'
}

/** input type for inserting data into table "lessons_unlocked" */
export interface ILessonsUnlockedInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  lesson?: InputMaybe<ILessonsObjRelInsertInput>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  user_child_id?: InputMaybe<Scalars['uuid']>;
  users_child?: InputMaybe<IUsersChildrenObjRelInsertInput>;
}

/** aggregate max on columns */
export interface ILessonsUnlockedMaxFields {
  __typename?: 'lessons_unlocked_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  user_child_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "lessons_unlocked" */
export interface ILessonsUnlockedMaxOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  user_child_id?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface ILessonsUnlockedMinFields {
  __typename?: 'lessons_unlocked_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  user_child_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "lessons_unlocked" */
export interface ILessonsUnlockedMinOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  user_child_id?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "lessons_unlocked" */
export interface ILessonsUnlockedMutationResponse {
  __typename?: 'lessons_unlocked_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ILessonsUnlocked>;
}

/** input type for inserting object relation for remote table "lessons_unlocked" */
export interface ILessonsUnlockedObjRelInsertInput {
  data: ILessonsUnlockedInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ILessonsUnlockedOnConflict>;
}

/** on_conflict condition type for table "lessons_unlocked" */
export interface ILessonsUnlockedOnConflict {
  constraint: ILessonsUnlockedConstraint;
  update_columns?: Array<ILessonsUnlockedUpdateColumn>;
  where?: InputMaybe<ILessonsUnlockedBoolExp>;
}

/** Ordering options when selecting data from "lessons_unlocked". */
export interface ILessonsUnlockedOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  user_child_id?: InputMaybe<IOrderBy>;
  users_child?: InputMaybe<IUsersChildrenOrderBy>;
}

/** primary key columns input for table: lessons_unlocked */
export interface ILessonsUnlockedPkColumnsInput {
  lesson_id: Scalars['uuid'];
  user_child_id: Scalars['uuid'];
}

/** select columns of table "lessons_unlocked" */
export enum ILessonsUnlockedSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  USER_CHILD_ID = 'user_child_id'
}

/** input type for updating data in table "lessons_unlocked" */
export interface ILessonsUnlockedSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  user_child_id?: InputMaybe<Scalars['uuid']>;
}

/** update columns of table "lessons_unlocked" */
export enum ILessonsUnlockedUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  USER_CHILD_ID = 'user_child_id'
}

/** update columns of table "lessons" */
export enum ILessonsUpdateColumn {
  /** column name */
  CONTENT = 'content',
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  DESCRIPTION_VIDEO = 'description_video',
  /** column name */
  DURATION = 'duration',
  /** column name */
  ID = 'id',
  /** column name */
  IMAGE = 'image',
  /** column name */
  LABEL = 'label',
  /** column name */
  LESSON_NUMBER = 'lesson_number',
  /** column name */
  NAME = 'name',
  /** column name */
  PUBLISHED = 'published',
  /** column name */
  QA_CONTENT = 'qa_content',
  /** column name */
  QUIZ = 'quiz',
  /** column name */
  SECOND_PART = 'second_part',
  /** column name */
  SECOND_PART_DURATION = 'second_part_duration',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  SURAH = 'surah',
  /** column name */
  SURAH_DESCRIPTION = 'surah_description',
  /** column name */
  SURAH_SECOND_PART = 'surah_second_part',
  /** column name */
  SURAH_THIRD_PART = 'surah_third_part',
  /** column name */
  THIRD_PART = 'third_part',
  /** column name */
  THIRD_PART_DURATION = 'third_part_duration',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface ILessonsVarPopFields {
  __typename?: 'lessons_var_pop_fields';
  duration?: Maybe<Scalars['Float']>;
  lesson_number?: Maybe<Scalars['Float']>;
  second_part_duration?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  third_part_duration?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "lessons" */
export interface ILessonsVarPopOrderBy {
  duration?: InputMaybe<IOrderBy>;
  lesson_number?: InputMaybe<IOrderBy>;
  second_part_duration?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  third_part_duration?: InputMaybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface ILessonsVarSampFields {
  __typename?: 'lessons_var_samp_fields';
  duration?: Maybe<Scalars['Float']>;
  lesson_number?: Maybe<Scalars['Float']>;
  second_part_duration?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  third_part_duration?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "lessons" */
export interface ILessonsVarSampOrderBy {
  duration?: InputMaybe<IOrderBy>;
  lesson_number?: InputMaybe<IOrderBy>;
  second_part_duration?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  third_part_duration?: InputMaybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface ILessonsVarianceFields {
  __typename?: 'lessons_variance_fields';
  duration?: Maybe<Scalars['Float']>;
  lesson_number?: Maybe<Scalars['Float']>;
  second_part_duration?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  third_part_duration?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "lessons" */
export interface ILessonsVarianceOrderBy {
  duration?: InputMaybe<IOrderBy>;
  lesson_number?: InputMaybe<IOrderBy>;
  second_part_duration?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  third_part_duration?: InputMaybe<IOrderBy>;
}

/** columns and relationships of "lessons_view" */
export interface ILessonsView {
  __typename?: 'lessons_view';
  all_not_available?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  /** An object relationship */
  course?: Maybe<ICourses>;
  /** An object relationship */
  course_group?: Maybe<ICourseGroups>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['interval']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  is_standby?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  not_available?: Maybe<Scalars['Boolean']>;
  second_part?: Maybe<Scalars['String']>;
  start_dt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  subscription?: Maybe<ISubscriptions>;
  subscription_id?: Maybe<Scalars['uuid']>;
  surah?: Maybe<Scalars['String']>;
  third_part?: Maybe<Scalars['String']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "lessons_view" */
export interface ILessonsViewAggregate {
  __typename?: 'lessons_view_aggregate';
  aggregate?: Maybe<ILessonsViewAggregateFields>;
  nodes: Array<ILessonsView>;
}

/** aggregate fields of "lessons_view" */
export interface ILessonsViewAggregateFields {
  __typename?: 'lessons_view_aggregate_fields';
  avg?: Maybe<ILessonsViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ILessonsViewMaxFields>;
  min?: Maybe<ILessonsViewMinFields>;
  stddev?: Maybe<ILessonsViewStddevFields>;
  stddev_pop?: Maybe<ILessonsViewStddevPopFields>;
  stddev_samp?: Maybe<ILessonsViewStddevSampFields>;
  sum?: Maybe<ILessonsViewSumFields>;
  var_pop?: Maybe<ILessonsViewVarPopFields>;
  var_samp?: Maybe<ILessonsViewVarSampFields>;
  variance?: Maybe<ILessonsViewVarianceFields>;
}


/** aggregate fields of "lessons_view" */
export interface ILessonsViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ILessonsViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "lessons_view" */
export interface ILessonsViewAggregateOrderBy {
  avg?: InputMaybe<ILessonsViewAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<ILessonsViewMaxOrderBy>;
  min?: InputMaybe<ILessonsViewMinOrderBy>;
  stddev?: InputMaybe<ILessonsViewStddevOrderBy>;
  stddev_pop?: InputMaybe<ILessonsViewStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ILessonsViewStddevSampOrderBy>;
  sum?: InputMaybe<ILessonsViewSumOrderBy>;
  var_pop?: InputMaybe<ILessonsViewVarPopOrderBy>;
  var_samp?: InputMaybe<ILessonsViewVarSampOrderBy>;
  variance?: InputMaybe<ILessonsViewVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "lessons_view" */
export interface ILessonsViewArrRelInsertInput {
  data: Array<ILessonsViewInsertInput>;
}

/** aggregate avg on columns */
export interface ILessonsViewAvgFields {
  __typename?: 'lessons_view_avg_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "lessons_view" */
export interface ILessonsViewAvgOrderBy {
  content_key?: InputMaybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "lessons_view". All fields are combined with a logical 'AND'. */
export interface ILessonsViewBoolExp {
  _and?: InputMaybe<Array<ILessonsViewBoolExp>>;
  _not?: InputMaybe<ILessonsViewBoolExp>;
  _or?: InputMaybe<Array<ILessonsViewBoolExp>>;
  all_not_available?: InputMaybe<IBooleanComparisonExp>;
  content?: InputMaybe<IStringComparisonExp>;
  content_key?: InputMaybe<IIntComparisonExp>;
  course?: InputMaybe<ICoursesBoolExp>;
  course_group?: InputMaybe<ICourseGroupsBoolExp>;
  course_group_id?: InputMaybe<IUuidComparisonExp>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  duration?: InputMaybe<IIntervalComparisonExp>;
  end_time?: InputMaybe<ITimestamptzComparisonExp>;
  is_standby?: InputMaybe<IBooleanComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  not_available?: InputMaybe<IBooleanComparisonExp>;
  second_part?: InputMaybe<IStringComparisonExp>;
  start_dt?: InputMaybe<ITimestampComparisonExp>;
  subscription?: InputMaybe<ISubscriptionsBoolExp>;
  subscription_id?: InputMaybe<IUuidComparisonExp>;
  surah?: InputMaybe<IStringComparisonExp>;
  third_part?: InputMaybe<IStringComparisonExp>;
  unlocked_at?: InputMaybe<ITimestamptzComparisonExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** input type for inserting data into table "lessons_view" */
export interface ILessonsViewInsertInput {
  all_not_available?: InputMaybe<Scalars['Boolean']>;
  content?: InputMaybe<Scalars['String']>;
  content_key?: InputMaybe<Scalars['Int']>;
  course?: InputMaybe<ICoursesObjRelInsertInput>;
  course_group?: InputMaybe<ICourseGroupsObjRelInsertInput>;
  course_group_id?: InputMaybe<Scalars['uuid']>;
  course_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['interval']>;
  end_time?: InputMaybe<Scalars['timestamptz']>;
  is_standby?: InputMaybe<Scalars['Boolean']>;
  lesson?: InputMaybe<ILessonsObjRelInsertInput>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  not_available?: InputMaybe<Scalars['Boolean']>;
  second_part?: InputMaybe<Scalars['String']>;
  start_dt?: InputMaybe<Scalars['timestamp']>;
  subscription?: InputMaybe<ISubscriptionsObjRelInsertInput>;
  subscription_id?: InputMaybe<Scalars['uuid']>;
  surah?: InputMaybe<Scalars['String']>;
  third_part?: InputMaybe<Scalars['String']>;
  unlocked_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface ILessonsViewMaxFields {
  __typename?: 'lessons_view_max_fields';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  second_part?: Maybe<Scalars['String']>;
  start_dt?: Maybe<Scalars['timestamp']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  surah?: Maybe<Scalars['String']>;
  third_part?: Maybe<Scalars['String']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "lessons_view" */
export interface ILessonsViewMaxOrderBy {
  content?: InputMaybe<IOrderBy>;
  content_key?: InputMaybe<IOrderBy>;
  course_group_id?: InputMaybe<IOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  end_time?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  second_part?: InputMaybe<IOrderBy>;
  start_dt?: InputMaybe<IOrderBy>;
  subscription_id?: InputMaybe<IOrderBy>;
  surah?: InputMaybe<IOrderBy>;
  third_part?: InputMaybe<IOrderBy>;
  unlocked_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface ILessonsViewMinFields {
  __typename?: 'lessons_view_min_fields';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  second_part?: Maybe<Scalars['String']>;
  start_dt?: Maybe<Scalars['timestamp']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  surah?: Maybe<Scalars['String']>;
  third_part?: Maybe<Scalars['String']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "lessons_view" */
export interface ILessonsViewMinOrderBy {
  content?: InputMaybe<IOrderBy>;
  content_key?: InputMaybe<IOrderBy>;
  course_group_id?: InputMaybe<IOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  end_time?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  second_part?: InputMaybe<IOrderBy>;
  start_dt?: InputMaybe<IOrderBy>;
  subscription_id?: InputMaybe<IOrderBy>;
  surah?: InputMaybe<IOrderBy>;
  third_part?: InputMaybe<IOrderBy>;
  unlocked_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** Ordering options when selecting data from "lessons_view". */
export interface ILessonsViewOrderBy {
  all_not_available?: InputMaybe<IOrderBy>;
  content?: InputMaybe<IOrderBy>;
  content_key?: InputMaybe<IOrderBy>;
  course?: InputMaybe<ICoursesOrderBy>;
  course_group?: InputMaybe<ICourseGroupsOrderBy>;
  course_group_id?: InputMaybe<IOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  duration?: InputMaybe<IOrderBy>;
  end_time?: InputMaybe<IOrderBy>;
  is_standby?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  not_available?: InputMaybe<IOrderBy>;
  second_part?: InputMaybe<IOrderBy>;
  start_dt?: InputMaybe<IOrderBy>;
  subscription?: InputMaybe<ISubscriptionsOrderBy>;
  subscription_id?: InputMaybe<IOrderBy>;
  surah?: InputMaybe<IOrderBy>;
  third_part?: InputMaybe<IOrderBy>;
  unlocked_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "lessons_view" */
export enum ILessonsViewSelectColumn {
  /** column name */
  ALL_NOT_AVAILABLE = 'all_not_available',
  /** column name */
  CONTENT = 'content',
  /** column name */
  CONTENT_KEY = 'content_key',
  /** column name */
  COURSE_GROUP_ID = 'course_group_id',
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  DURATION = 'duration',
  /** column name */
  END_TIME = 'end_time',
  /** column name */
  IS_STANDBY = 'is_standby',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  NAME = 'name',
  /** column name */
  NOT_AVAILABLE = 'not_available',
  /** column name */
  SECOND_PART = 'second_part',
  /** column name */
  START_DT = 'start_dt',
  /** column name */
  SUBSCRIPTION_ID = 'subscription_id',
  /** column name */
  SURAH = 'surah',
  /** column name */
  THIRD_PART = 'third_part',
  /** column name */
  UNLOCKED_AT = 'unlocked_at',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate stddev on columns */
export interface ILessonsViewStddevFields {
  __typename?: 'lessons_view_stddev_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "lessons_view" */
export interface ILessonsViewStddevOrderBy {
  content_key?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface ILessonsViewStddevPopFields {
  __typename?: 'lessons_view_stddev_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "lessons_view" */
export interface ILessonsViewStddevPopOrderBy {
  content_key?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface ILessonsViewStddevSampFields {
  __typename?: 'lessons_view_stddev_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "lessons_view" */
export interface ILessonsViewStddevSampOrderBy {
  content_key?: InputMaybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface ILessonsViewSumFields {
  __typename?: 'lessons_view_sum_fields';
  content_key?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "lessons_view" */
export interface ILessonsViewSumOrderBy {
  content_key?: InputMaybe<IOrderBy>;
}

/** aggregate var_pop on columns */
export interface ILessonsViewVarPopFields {
  __typename?: 'lessons_view_var_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "lessons_view" */
export interface ILessonsViewVarPopOrderBy {
  content_key?: InputMaybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface ILessonsViewVarSampFields {
  __typename?: 'lessons_view_var_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "lessons_view" */
export interface ILessonsViewVarSampOrderBy {
  content_key?: InputMaybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface ILessonsViewVarianceFields {
  __typename?: 'lessons_view_variance_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "lessons_view" */
export interface ILessonsViewVarianceOrderBy {
  content_key?: InputMaybe<IOrderBy>;
}

/** columns and relationships of "lessons_with_lock" */
export interface ILessonsWithLock {
  __typename?: 'lessons_with_lock';
  /** An array relationship */
  achievements: Array<IAchievements>;
  /** An aggregate relationship */
  achievements_aggregate: IAchievementsAggregate;
  content?: Maybe<Scalars['String']>;
  /** An object relationship */
  course?: Maybe<ICourses>;
  course_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  /** An object relationship */
  lesson_unlocked?: Maybe<ILessonsUnlocked>;
  /** An array relationship */
  lesson_verses: Array<ILessonVerses>;
  /** An aggregate relationship */
  lesson_verses_aggregate: ILessonVersesAggregate;
  /** An array relationship */
  lesson_words: Array<ILessonWords>;
  /** An aggregate relationship */
  lesson_words_aggregate: ILessonWordsAggregate;
  locked?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  sequence?: Maybe<Scalars['Int']>;
  /** An object relationship */
  subscription?: Maybe<ISubscriptions>;
  subscription_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<IUsers>;
  /** An object relationship */
  user_child?: Maybe<IUsersChildren>;
  user_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user_lesson_progress?: Maybe<IUserLessonProgresses>;
  /** An array relationship */
  user_lesson_progresses: Array<IUserLessonProgresses>;
  /** An aggregate relationship */
  user_lesson_progresses_aggregate: IUserLessonProgressesAggregate;
  users_child_id?: Maybe<Scalars['uuid']>;
}


/** columns and relationships of "lessons_with_lock" */
export interface ILessonsWithLockAchievementsArgs {
  distinct_on?: InputMaybe<Array<IAchievementsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAchievementsOrderBy>>;
  where?: InputMaybe<IAchievementsBoolExp>;
}


/** columns and relationships of "lessons_with_lock" */
export interface ILessonsWithLockAchievementsAggregateArgs {
  distinct_on?: InputMaybe<Array<IAchievementsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAchievementsOrderBy>>;
  where?: InputMaybe<IAchievementsBoolExp>;
}


/** columns and relationships of "lessons_with_lock" */
export interface ILessonsWithLockLessonVersesArgs {
  distinct_on?: InputMaybe<Array<ILessonVersesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonVersesOrderBy>>;
  where?: InputMaybe<ILessonVersesBoolExp>;
}


/** columns and relationships of "lessons_with_lock" */
export interface ILessonsWithLockLessonVersesAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonVersesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonVersesOrderBy>>;
  where?: InputMaybe<ILessonVersesBoolExp>;
}


/** columns and relationships of "lessons_with_lock" */
export interface ILessonsWithLockLessonWordsArgs {
  distinct_on?: InputMaybe<Array<ILessonWordsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonWordsOrderBy>>;
  where?: InputMaybe<ILessonWordsBoolExp>;
}


/** columns and relationships of "lessons_with_lock" */
export interface ILessonsWithLockLessonWordsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonWordsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonWordsOrderBy>>;
  where?: InputMaybe<ILessonWordsBoolExp>;
}


/** columns and relationships of "lessons_with_lock" */
export interface ILessonsWithLockUserLessonProgressesArgs {
  distinct_on?: InputMaybe<Array<IUserLessonProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserLessonProgressesOrderBy>>;
  where?: InputMaybe<IUserLessonProgressesBoolExp>;
}


/** columns and relationships of "lessons_with_lock" */
export interface ILessonsWithLockUserLessonProgressesAggregateArgs {
  distinct_on?: InputMaybe<Array<IUserLessonProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserLessonProgressesOrderBy>>;
  where?: InputMaybe<IUserLessonProgressesBoolExp>;
}

/** aggregated selection of "lessons_with_lock" */
export interface ILessonsWithLockAggregate {
  __typename?: 'lessons_with_lock_aggregate';
  aggregate?: Maybe<ILessonsWithLockAggregateFields>;
  nodes: Array<ILessonsWithLock>;
}

/** aggregate fields of "lessons_with_lock" */
export interface ILessonsWithLockAggregateFields {
  __typename?: 'lessons_with_lock_aggregate_fields';
  avg?: Maybe<ILessonsWithLockAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ILessonsWithLockMaxFields>;
  min?: Maybe<ILessonsWithLockMinFields>;
  stddev?: Maybe<ILessonsWithLockStddevFields>;
  stddev_pop?: Maybe<ILessonsWithLockStddevPopFields>;
  stddev_samp?: Maybe<ILessonsWithLockStddevSampFields>;
  sum?: Maybe<ILessonsWithLockSumFields>;
  var_pop?: Maybe<ILessonsWithLockVarPopFields>;
  var_samp?: Maybe<ILessonsWithLockVarSampFields>;
  variance?: Maybe<ILessonsWithLockVarianceFields>;
}


/** aggregate fields of "lessons_with_lock" */
export interface ILessonsWithLockAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ILessonsWithLockSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "lessons_with_lock" */
export interface ILessonsWithLockAggregateOrderBy {
  avg?: InputMaybe<ILessonsWithLockAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<ILessonsWithLockMaxOrderBy>;
  min?: InputMaybe<ILessonsWithLockMinOrderBy>;
  stddev?: InputMaybe<ILessonsWithLockStddevOrderBy>;
  stddev_pop?: InputMaybe<ILessonsWithLockStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ILessonsWithLockStddevSampOrderBy>;
  sum?: InputMaybe<ILessonsWithLockSumOrderBy>;
  var_pop?: InputMaybe<ILessonsWithLockVarPopOrderBy>;
  var_samp?: InputMaybe<ILessonsWithLockVarSampOrderBy>;
  variance?: InputMaybe<ILessonsWithLockVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "lessons_with_lock" */
export interface ILessonsWithLockArrRelInsertInput {
  data: Array<ILessonsWithLockInsertInput>;
}

/** aggregate avg on columns */
export interface ILessonsWithLockAvgFields {
  __typename?: 'lessons_with_lock_avg_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "lessons_with_lock" */
export interface ILessonsWithLockAvgOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "lessons_with_lock". All fields are combined with a logical 'AND'. */
export interface ILessonsWithLockBoolExp {
  _and?: InputMaybe<Array<ILessonsWithLockBoolExp>>;
  _not?: InputMaybe<ILessonsWithLockBoolExp>;
  _or?: InputMaybe<Array<ILessonsWithLockBoolExp>>;
  achievements?: InputMaybe<IAchievementsBoolExp>;
  content?: InputMaybe<IStringComparisonExp>;
  course?: InputMaybe<ICoursesBoolExp>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  image?: InputMaybe<IStringComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_unlocked?: InputMaybe<ILessonsUnlockedBoolExp>;
  lesson_verses?: InputMaybe<ILessonVersesBoolExp>;
  lesson_words?: InputMaybe<ILessonWordsBoolExp>;
  locked?: InputMaybe<IBooleanComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  published?: InputMaybe<IBooleanComparisonExp>;
  sequence?: InputMaybe<IIntComparisonExp>;
  subscription?: InputMaybe<ISubscriptionsBoolExp>;
  subscription_id?: InputMaybe<IUuidComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_child?: InputMaybe<IUsersChildrenBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
  user_lesson_progress?: InputMaybe<IUserLessonProgressesBoolExp>;
  user_lesson_progresses?: InputMaybe<IUserLessonProgressesBoolExp>;
  users_child_id?: InputMaybe<IUuidComparisonExp>;
}

/** input type for inserting data into table "lessons_with_lock" */
export interface ILessonsWithLockInsertInput {
  achievements?: InputMaybe<IAchievementsArrRelInsertInput>;
  content?: InputMaybe<Scalars['String']>;
  course?: InputMaybe<ICoursesObjRelInsertInput>;
  course_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  lesson?: InputMaybe<ILessonsObjRelInsertInput>;
  lesson_unlocked?: InputMaybe<ILessonsUnlockedObjRelInsertInput>;
  lesson_verses?: InputMaybe<ILessonVersesArrRelInsertInput>;
  lesson_words?: InputMaybe<ILessonWordsArrRelInsertInput>;
  locked?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sequence?: InputMaybe<Scalars['Int']>;
  subscription?: InputMaybe<ISubscriptionsObjRelInsertInput>;
  subscription_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<IUsersObjRelInsertInput>;
  user_child?: InputMaybe<IUsersChildrenObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_lesson_progress?: InputMaybe<IUserLessonProgressesObjRelInsertInput>;
  user_lesson_progresses?: InputMaybe<IUserLessonProgressesArrRelInsertInput>;
  users_child_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface ILessonsWithLockMaxFields {
  __typename?: 'lessons_with_lock_max_fields';
  content?: Maybe<Scalars['String']>;
  course_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  users_child_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "lessons_with_lock" */
export interface ILessonsWithLockMaxOrderBy {
  content?: InputMaybe<IOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  image?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  subscription_id?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  users_child_id?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface ILessonsWithLockMinFields {
  __typename?: 'lessons_with_lock_min_fields';
  content?: Maybe<Scalars['String']>;
  course_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  users_child_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "lessons_with_lock" */
export interface ILessonsWithLockMinOrderBy {
  content?: InputMaybe<IOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  image?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  subscription_id?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  users_child_id?: InputMaybe<IOrderBy>;
}

/** input type for inserting object relation for remote table "lessons_with_lock" */
export interface ILessonsWithLockObjRelInsertInput {
  data: ILessonsWithLockInsertInput;
}

/** Ordering options when selecting data from "lessons_with_lock". */
export interface ILessonsWithLockOrderBy {
  achievements_aggregate?: InputMaybe<IAchievementsAggregateOrderBy>;
  content?: InputMaybe<IOrderBy>;
  course?: InputMaybe<ICoursesOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  image?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_unlocked?: InputMaybe<ILessonsUnlockedOrderBy>;
  lesson_verses_aggregate?: InputMaybe<ILessonVersesAggregateOrderBy>;
  lesson_words_aggregate?: InputMaybe<ILessonWordsAggregateOrderBy>;
  locked?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  published?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  subscription?: InputMaybe<ISubscriptionsOrderBy>;
  subscription_id?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_child?: InputMaybe<IUsersChildrenOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  user_lesson_progress?: InputMaybe<IUserLessonProgressesOrderBy>;
  user_lesson_progresses_aggregate?: InputMaybe<IUserLessonProgressesAggregateOrderBy>;
  users_child_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "lessons_with_lock" */
export enum ILessonsWithLockSelectColumn {
  /** column name */
  CONTENT = 'content',
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  ID = 'id',
  /** column name */
  IMAGE = 'image',
  /** column name */
  LOCKED = 'locked',
  /** column name */
  NAME = 'name',
  /** column name */
  PUBLISHED = 'published',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  SUBSCRIPTION_ID = 'subscription_id',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  USERS_CHILD_ID = 'users_child_id'
}

/** aggregate stddev on columns */
export interface ILessonsWithLockStddevFields {
  __typename?: 'lessons_with_lock_stddev_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "lessons_with_lock" */
export interface ILessonsWithLockStddevOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface ILessonsWithLockStddevPopFields {
  __typename?: 'lessons_with_lock_stddev_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "lessons_with_lock" */
export interface ILessonsWithLockStddevPopOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface ILessonsWithLockStddevSampFields {
  __typename?: 'lessons_with_lock_stddev_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "lessons_with_lock" */
export interface ILessonsWithLockStddevSampOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface ILessonsWithLockSumFields {
  __typename?: 'lessons_with_lock_sum_fields';
  sequence?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "lessons_with_lock" */
export interface ILessonsWithLockSumOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate var_pop on columns */
export interface ILessonsWithLockVarPopFields {
  __typename?: 'lessons_with_lock_var_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "lessons_with_lock" */
export interface ILessonsWithLockVarPopOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface ILessonsWithLockVarSampFields {
  __typename?: 'lessons_with_lock_var_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "lessons_with_lock" */
export interface ILessonsWithLockVarSampOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface ILessonsWithLockVarianceFields {
  __typename?: 'lessons_with_lock_variance_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "lessons_with_lock" */
export interface ILessonsWithLockVarianceOrderBy {
  sequence?: InputMaybe<IOrderBy>;
}

/** columns and relationships of "lessons_with_progress" */
export interface ILessonsWithProgress {
  __typename?: 'lessons_with_progress';
  content?: Maybe<Scalars['String']>;
  course_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  finished?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['numeric']>;
  published?: Maybe<Scalars['Boolean']>;
  sequence?: Maybe<Scalars['Int']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  time_progress?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['uuid']>;
  users_child_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "lessons_with_progress" */
export interface ILessonsWithProgressAggregate {
  __typename?: 'lessons_with_progress_aggregate';
  aggregate?: Maybe<ILessonsWithProgressAggregateFields>;
  nodes: Array<ILessonsWithProgress>;
}

/** aggregate fields of "lessons_with_progress" */
export interface ILessonsWithProgressAggregateFields {
  __typename?: 'lessons_with_progress_aggregate_fields';
  avg?: Maybe<ILessonsWithProgressAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ILessonsWithProgressMaxFields>;
  min?: Maybe<ILessonsWithProgressMinFields>;
  stddev?: Maybe<ILessonsWithProgressStddevFields>;
  stddev_pop?: Maybe<ILessonsWithProgressStddevPopFields>;
  stddev_samp?: Maybe<ILessonsWithProgressStddevSampFields>;
  sum?: Maybe<ILessonsWithProgressSumFields>;
  var_pop?: Maybe<ILessonsWithProgressVarPopFields>;
  var_samp?: Maybe<ILessonsWithProgressVarSampFields>;
  variance?: Maybe<ILessonsWithProgressVarianceFields>;
}


/** aggregate fields of "lessons_with_progress" */
export interface ILessonsWithProgressAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ILessonsWithProgressSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface ILessonsWithProgressAvgFields {
  __typename?: 'lessons_with_progress_avg_fields';
  percentage?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  time_progress?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "lessons_with_progress". All fields are combined with a logical 'AND'. */
export interface ILessonsWithProgressBoolExp {
  _and?: InputMaybe<Array<ILessonsWithProgressBoolExp>>;
  _not?: InputMaybe<ILessonsWithProgressBoolExp>;
  _or?: InputMaybe<Array<ILessonsWithProgressBoolExp>>;
  content?: InputMaybe<IStringComparisonExp>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  finished?: InputMaybe<IBooleanComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  image?: InputMaybe<IStringComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  percentage?: InputMaybe<INumericComparisonExp>;
  published?: InputMaybe<IBooleanComparisonExp>;
  sequence?: InputMaybe<IIntComparisonExp>;
  subscription_id?: InputMaybe<IUuidComparisonExp>;
  time_progress?: InputMaybe<INumericComparisonExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
  users_child_id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface ILessonsWithProgressMaxFields {
  __typename?: 'lessons_with_progress_max_fields';
  content?: Maybe<Scalars['String']>;
  course_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['numeric']>;
  sequence?: Maybe<Scalars['Int']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  time_progress?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['uuid']>;
  users_child_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface ILessonsWithProgressMinFields {
  __typename?: 'lessons_with_progress_min_fields';
  content?: Maybe<Scalars['String']>;
  course_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['numeric']>;
  sequence?: Maybe<Scalars['Int']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  time_progress?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['uuid']>;
  users_child_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "lessons_with_progress". */
export interface ILessonsWithProgressOrderBy {
  content?: InputMaybe<IOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  finished?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  image?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  percentage?: InputMaybe<IOrderBy>;
  published?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  subscription_id?: InputMaybe<IOrderBy>;
  time_progress?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  users_child_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "lessons_with_progress" */
export enum ILessonsWithProgressSelectColumn {
  /** column name */
  CONTENT = 'content',
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  FINISHED = 'finished',
  /** column name */
  ID = 'id',
  /** column name */
  IMAGE = 'image',
  /** column name */
  NAME = 'name',
  /** column name */
  PERCENTAGE = 'percentage',
  /** column name */
  PUBLISHED = 'published',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  SUBSCRIPTION_ID = 'subscription_id',
  /** column name */
  TIME_PROGRESS = 'time_progress',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  USERS_CHILD_ID = 'users_child_id'
}

/** aggregate stddev on columns */
export interface ILessonsWithProgressStddevFields {
  __typename?: 'lessons_with_progress_stddev_fields';
  percentage?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  time_progress?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface ILessonsWithProgressStddevPopFields {
  __typename?: 'lessons_with_progress_stddev_pop_fields';
  percentage?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  time_progress?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface ILessonsWithProgressStddevSampFields {
  __typename?: 'lessons_with_progress_stddev_samp_fields';
  percentage?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  time_progress?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface ILessonsWithProgressSumFields {
  __typename?: 'lessons_with_progress_sum_fields';
  percentage?: Maybe<Scalars['numeric']>;
  sequence?: Maybe<Scalars['Int']>;
  time_progress?: Maybe<Scalars['numeric']>;
}

/** aggregate var_pop on columns */
export interface ILessonsWithProgressVarPopFields {
  __typename?: 'lessons_with_progress_var_pop_fields';
  percentage?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  time_progress?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface ILessonsWithProgressVarSampFields {
  __typename?: 'lessons_with_progress_var_samp_fields';
  percentage?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  time_progress?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface ILessonsWithProgressVarianceFields {
  __typename?: 'lessons_with_progress_variance_fields';
  percentage?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  time_progress?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "library_downloads" */
export interface ILibraryDownloads {
  __typename?: 'library_downloads';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
}

/** aggregated selection of "library_downloads" */
export interface ILibraryDownloadsAggregate {
  __typename?: 'library_downloads_aggregate';
  aggregate?: Maybe<ILibraryDownloadsAggregateFields>;
  nodes: Array<ILibraryDownloads>;
}

/** aggregate fields of "library_downloads" */
export interface ILibraryDownloadsAggregateFields {
  __typename?: 'library_downloads_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ILibraryDownloadsMaxFields>;
  min?: Maybe<ILibraryDownloadsMinFields>;
}


/** aggregate fields of "library_downloads" */
export interface ILibraryDownloadsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ILibraryDownloadsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** columns and relationships of "library_downloads_attachments" */
export interface ILibraryDownloadsAttachments {
  __typename?: 'library_downloads_attachments';
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  file_type?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  /** An object relationship */
  library_download?: Maybe<ILibraryDownloads>;
  library_download_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "library_downloads_attachments" */
export interface ILibraryDownloadsAttachmentsAggregate {
  __typename?: 'library_downloads_attachments_aggregate';
  aggregate?: Maybe<ILibraryDownloadsAttachmentsAggregateFields>;
  nodes: Array<ILibraryDownloadsAttachments>;
}

/** aggregate fields of "library_downloads_attachments" */
export interface ILibraryDownloadsAttachmentsAggregateFields {
  __typename?: 'library_downloads_attachments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ILibraryDownloadsAttachmentsMaxFields>;
  min?: Maybe<ILibraryDownloadsAttachmentsMinFields>;
}


/** aggregate fields of "library_downloads_attachments" */
export interface ILibraryDownloadsAttachmentsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ILibraryDownloadsAttachmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "library_downloads_attachments". All fields are combined with a logical 'AND'. */
export interface ILibraryDownloadsAttachmentsBoolExp {
  _and?: InputMaybe<Array<ILibraryDownloadsAttachmentsBoolExp>>;
  _not?: InputMaybe<ILibraryDownloadsAttachmentsBoolExp>;
  _or?: InputMaybe<Array<ILibraryDownloadsAttachmentsBoolExp>>;
  content_type?: InputMaybe<IStringComparisonExp>;
  created_at?: InputMaybe<ITimestampComparisonExp>;
  file_type?: InputMaybe<IStringComparisonExp>;
  filename?: InputMaybe<IStringComparisonExp>;
  key?: InputMaybe<IStringComparisonExp>;
  library_download?: InputMaybe<ILibraryDownloadsBoolExp>;
  library_download_id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface ILibraryDownloadsAttachmentsMaxFields {
  __typename?: 'library_downloads_attachments_max_fields';
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  file_type?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  library_download_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface ILibraryDownloadsAttachmentsMinFields {
  __typename?: 'library_downloads_attachments_min_fields';
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  file_type?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  library_download_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "library_downloads_attachments". */
export interface ILibraryDownloadsAttachmentsOrderBy {
  content_type?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  file_type?: InputMaybe<IOrderBy>;
  filename?: InputMaybe<IOrderBy>;
  key?: InputMaybe<IOrderBy>;
  library_download?: InputMaybe<ILibraryDownloadsOrderBy>;
  library_download_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "library_downloads_attachments" */
export enum ILibraryDownloadsAttachmentsSelectColumn {
  /** column name */
  CONTENT_TYPE = 'content_type',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  FILE_TYPE = 'file_type',
  /** column name */
  FILENAME = 'filename',
  /** column name */
  KEY = 'key',
  /** column name */
  LIBRARY_DOWNLOAD_ID = 'library_download_id'
}

/** Boolean expression to filter rows from the table "library_downloads". All fields are combined with a logical 'AND'. */
export interface ILibraryDownloadsBoolExp {
  _and?: InputMaybe<Array<ILibraryDownloadsBoolExp>>;
  _not?: InputMaybe<ILibraryDownloadsBoolExp>;
  _or?: InputMaybe<Array<ILibraryDownloadsBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  title?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "library_downloads" */
export enum ILibraryDownloadsConstraint {
  /** unique or primary key constraint */
  LIBRARY_DOWNLOADS_PKEY = 'library_downloads_pkey'
}

/** input type for inserting data into table "library_downloads" */
export interface ILibraryDownloadsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface ILibraryDownloadsMaxFields {
  __typename?: 'library_downloads_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface ILibraryDownloadsMinFields {
  __typename?: 'library_downloads_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** response of any mutation on the table "library_downloads" */
export interface ILibraryDownloadsMutationResponse {
  __typename?: 'library_downloads_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ILibraryDownloads>;
}

/** on_conflict condition type for table "library_downloads" */
export interface ILibraryDownloadsOnConflict {
  constraint: ILibraryDownloadsConstraint;
  update_columns?: Array<ILibraryDownloadsUpdateColumn>;
  where?: InputMaybe<ILibraryDownloadsBoolExp>;
}

/** Ordering options when selecting data from "library_downloads". */
export interface ILibraryDownloadsOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  title?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: library_downloads */
export interface ILibraryDownloadsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "library_downloads" */
export enum ILibraryDownloadsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "library_downloads" */
export interface ILibraryDownloadsSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** update columns of table "library_downloads" */
export enum ILibraryDownloadsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** columns and relationships of "message_types" */
export interface IMessageTypes {
  __typename?: 'message_types';
  name: Scalars['String'];
}

/** aggregated selection of "message_types" */
export interface IMessageTypesAggregate {
  __typename?: 'message_types_aggregate';
  aggregate?: Maybe<IMessageTypesAggregateFields>;
  nodes: Array<IMessageTypes>;
}

/** aggregate fields of "message_types" */
export interface IMessageTypesAggregateFields {
  __typename?: 'message_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IMessageTypesMaxFields>;
  min?: Maybe<IMessageTypesMinFields>;
}


/** aggregate fields of "message_types" */
export interface IMessageTypesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IMessageTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "message_types". All fields are combined with a logical 'AND'. */
export interface IMessageTypesBoolExp {
  _and?: InputMaybe<Array<IMessageTypesBoolExp>>;
  _not?: InputMaybe<IMessageTypesBoolExp>;
  _or?: InputMaybe<Array<IMessageTypesBoolExp>>;
  name?: InputMaybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "message_types" */
export enum IMessageTypesConstraint {
  /** unique or primary key constraint */
  MESSAGE_TYPES_PKEY = 'message_types_pkey'
}

export enum IMessageTypesEnum {
  LESSON = 'LESSON',
  PARENT = 'PARENT'
}

/** Boolean expression to compare columns of type "message_types_enum". All fields are combined with logical 'AND'. */
export interface IMessageTypesEnumComparisonExp {
  _eq?: InputMaybe<IMessageTypesEnum>;
  _in?: InputMaybe<Array<IMessageTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<IMessageTypesEnum>;
  _nin?: InputMaybe<Array<IMessageTypesEnum>>;
}

/** input type for inserting data into table "message_types" */
export interface IMessageTypesInsertInput {
  name?: InputMaybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface IMessageTypesMaxFields {
  __typename?: 'message_types_max_fields';
  name?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IMessageTypesMinFields {
  __typename?: 'message_types_min_fields';
  name?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "message_types" */
export interface IMessageTypesMutationResponse {
  __typename?: 'message_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IMessageTypes>;
}

/** on_conflict condition type for table "message_types" */
export interface IMessageTypesOnConflict {
  constraint: IMessageTypesConstraint;
  update_columns?: Array<IMessageTypesUpdateColumn>;
  where?: InputMaybe<IMessageTypesBoolExp>;
}

/** Ordering options when selecting data from "message_types". */
export interface IMessageTypesOrderBy {
  name?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: message_types */
export interface IMessageTypesPkColumnsInput {
  name: Scalars['String'];
}

/** select columns of table "message_types" */
export enum IMessageTypesSelectColumn {
  /** column name */
  NAME = 'name'
}

/** input type for updating data in table "message_types" */
export interface IMessageTypesSetInput {
  name?: InputMaybe<Scalars['String']>;
}

/** update columns of table "message_types" */
export enum IMessageTypesUpdateColumn {
  /** column name */
  NAME = 'name'
}

/** columns and relationships of "messages" */
export interface IMessages {
  __typename?: 'messages';
  created_at: Scalars['timestamptz'];
  from: IMessageTypesEnum;
  id: Scalars['uuid'];
  lesson_id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  opened: Scalars['Boolean'];
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  users_child?: Maybe<IUsersChildren>;
  users_child_id: Scalars['uuid'];
}

/** aggregated selection of "messages" */
export interface IMessagesAggregate {
  __typename?: 'messages_aggregate';
  aggregate?: Maybe<IMessagesAggregateFields>;
  nodes: Array<IMessages>;
}

/** aggregate fields of "messages" */
export interface IMessagesAggregateFields {
  __typename?: 'messages_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IMessagesMaxFields>;
  min?: Maybe<IMessagesMinFields>;
}


/** aggregate fields of "messages" */
export interface IMessagesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IMessagesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "messages". All fields are combined with a logical 'AND'. */
export interface IMessagesBoolExp {
  _and?: InputMaybe<Array<IMessagesBoolExp>>;
  _not?: InputMaybe<IMessagesBoolExp>;
  _or?: InputMaybe<Array<IMessagesBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  from?: InputMaybe<IMessageTypesEnumComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  message?: InputMaybe<IStringComparisonExp>;
  opened?: InputMaybe<IBooleanComparisonExp>;
  title?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  users_child?: InputMaybe<IUsersChildrenBoolExp>;
  users_child_id?: InputMaybe<IUuidComparisonExp>;
}

/** unique or primary key constraints on table "messages" */
export enum IMessagesConstraint {
  /** unique or primary key constraint */
  MESSAGES_PKEY = 'messages_pkey'
}

/** input type for inserting data into table "messages" */
export interface IMessagesInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from?: InputMaybe<IMessageTypesEnum>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  opened?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  users_child?: InputMaybe<IUsersChildrenObjRelInsertInput>;
  users_child_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface IMessagesMaxFields {
  __typename?: 'messages_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  users_child_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IMessagesMinFields {
  __typename?: 'messages_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  users_child_id?: Maybe<Scalars['uuid']>;
}

/** response of any mutation on the table "messages" */
export interface IMessagesMutationResponse {
  __typename?: 'messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IMessages>;
}

/** on_conflict condition type for table "messages" */
export interface IMessagesOnConflict {
  constraint: IMessagesConstraint;
  update_columns?: Array<IMessagesUpdateColumn>;
  where?: InputMaybe<IMessagesBoolExp>;
}

/** Ordering options when selecting data from "messages". */
export interface IMessagesOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  from?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  message?: InputMaybe<IOrderBy>;
  opened?: InputMaybe<IOrderBy>;
  title?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  users_child?: InputMaybe<IUsersChildrenOrderBy>;
  users_child_id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: messages */
export interface IMessagesPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "messages" */
export enum IMessagesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  FROM = 'from',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  MESSAGE = 'message',
  /** column name */
  OPENED = 'opened',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USERS_CHILD_ID = 'users_child_id'
}

/** input type for updating data in table "messages" */
export interface IMessagesSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from?: InputMaybe<IMessageTypesEnum>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  opened?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  users_child_id?: InputMaybe<Scalars['uuid']>;
}

/** update columns of table "messages" */
export enum IMessagesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  FROM = 'from',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  MESSAGE = 'message',
  /** column name */
  OPENED = 'opened',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USERS_CHILD_ID = 'users_child_id'
}

/** columns and relationships of "missed_lessons_view" */
export interface IMissedLessonsView {
  __typename?: 'missed_lessons_view';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  /** An object relationship */
  course_group?: Maybe<ICourseGroups>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_type?: Maybe<Scalars['String']>;
  days_left?: Maybe<Scalars['float8']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['interval']>;
  end_dt?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  origin_end_dt?: Maybe<Scalars['timestamp']>;
  overdue?: Maybe<Scalars['Boolean']>;
  qa_content?: Maybe<Scalars['String']>;
  questions_enabled?: Maybe<Scalars['Boolean']>;
  second_part?: Maybe<Scalars['String']>;
  third_part?: Maybe<Scalars['String']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_child_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_lesson_progresses_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "missed_lessons_view" */
export interface IMissedLessonsViewAggregate {
  __typename?: 'missed_lessons_view_aggregate';
  aggregate?: Maybe<IMissedLessonsViewAggregateFields>;
  nodes: Array<IMissedLessonsView>;
}

/** aggregate fields of "missed_lessons_view" */
export interface IMissedLessonsViewAggregateFields {
  __typename?: 'missed_lessons_view_aggregate_fields';
  avg?: Maybe<IMissedLessonsViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IMissedLessonsViewMaxFields>;
  min?: Maybe<IMissedLessonsViewMinFields>;
  stddev?: Maybe<IMissedLessonsViewStddevFields>;
  stddev_pop?: Maybe<IMissedLessonsViewStddevPopFields>;
  stddev_samp?: Maybe<IMissedLessonsViewStddevSampFields>;
  sum?: Maybe<IMissedLessonsViewSumFields>;
  var_pop?: Maybe<IMissedLessonsViewVarPopFields>;
  var_samp?: Maybe<IMissedLessonsViewVarSampFields>;
  variance?: Maybe<IMissedLessonsViewVarianceFields>;
}


/** aggregate fields of "missed_lessons_view" */
export interface IMissedLessonsViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IMissedLessonsViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IMissedLessonsViewAvgFields {
  __typename?: 'missed_lessons_view_avg_fields';
  content_key?: Maybe<Scalars['Float']>;
  days_left?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "missed_lessons_view". All fields are combined with a logical 'AND'. */
export interface IMissedLessonsViewBoolExp {
  _and?: InputMaybe<Array<IMissedLessonsViewBoolExp>>;
  _not?: InputMaybe<IMissedLessonsViewBoolExp>;
  _or?: InputMaybe<Array<IMissedLessonsViewBoolExp>>;
  content?: InputMaybe<IStringComparisonExp>;
  content_key?: InputMaybe<IIntComparisonExp>;
  course_group?: InputMaybe<ICourseGroupsBoolExp>;
  course_group_id?: InputMaybe<IUuidComparisonExp>;
  course_type?: InputMaybe<IStringComparisonExp>;
  days_left?: InputMaybe<IFloat8ComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  duration?: InputMaybe<IIntervalComparisonExp>;
  end_dt?: InputMaybe<ITimestamptzComparisonExp>;
  end_time?: InputMaybe<ITimestamptzComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  origin_end_dt?: InputMaybe<ITimestampComparisonExp>;
  overdue?: InputMaybe<IBooleanComparisonExp>;
  qa_content?: InputMaybe<IStringComparisonExp>;
  questions_enabled?: InputMaybe<IBooleanComparisonExp>;
  second_part?: InputMaybe<IStringComparisonExp>;
  third_part?: InputMaybe<IStringComparisonExp>;
  unlocked_at?: InputMaybe<ITimestamptzComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_child_id?: InputMaybe<IUuidComparisonExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
  user_lesson_progresses_id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface IMissedLessonsViewMaxFields {
  __typename?: 'missed_lessons_view_max_fields';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_type?: Maybe<Scalars['String']>;
  days_left?: Maybe<Scalars['float8']>;
  description?: Maybe<Scalars['String']>;
  end_dt?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  origin_end_dt?: Maybe<Scalars['timestamp']>;
  qa_content?: Maybe<Scalars['String']>;
  second_part?: Maybe<Scalars['String']>;
  third_part?: Maybe<Scalars['String']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  user_child_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_lesson_progresses_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IMissedLessonsViewMinFields {
  __typename?: 'missed_lessons_view_min_fields';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_type?: Maybe<Scalars['String']>;
  days_left?: Maybe<Scalars['float8']>;
  description?: Maybe<Scalars['String']>;
  end_dt?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  origin_end_dt?: Maybe<Scalars['timestamp']>;
  qa_content?: Maybe<Scalars['String']>;
  second_part?: Maybe<Scalars['String']>;
  third_part?: Maybe<Scalars['String']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  user_child_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_lesson_progresses_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "missed_lessons_view". */
export interface IMissedLessonsViewOrderBy {
  content?: InputMaybe<IOrderBy>;
  content_key?: InputMaybe<IOrderBy>;
  course_group?: InputMaybe<ICourseGroupsOrderBy>;
  course_group_id?: InputMaybe<IOrderBy>;
  course_type?: InputMaybe<IOrderBy>;
  days_left?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  duration?: InputMaybe<IOrderBy>;
  end_dt?: InputMaybe<IOrderBy>;
  end_time?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  origin_end_dt?: InputMaybe<IOrderBy>;
  overdue?: InputMaybe<IOrderBy>;
  qa_content?: InputMaybe<IOrderBy>;
  questions_enabled?: InputMaybe<IOrderBy>;
  second_part?: InputMaybe<IOrderBy>;
  third_part?: InputMaybe<IOrderBy>;
  unlocked_at?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_child_id?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  user_lesson_progresses_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "missed_lessons_view" */
export enum IMissedLessonsViewSelectColumn {
  /** column name */
  CONTENT = 'content',
  /** column name */
  CONTENT_KEY = 'content_key',
  /** column name */
  COURSE_GROUP_ID = 'course_group_id',
  /** column name */
  COURSE_TYPE = 'course_type',
  /** column name */
  DAYS_LEFT = 'days_left',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  DURATION = 'duration',
  /** column name */
  END_DT = 'end_dt',
  /** column name */
  END_TIME = 'end_time',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  NAME = 'name',
  /** column name */
  ORIGIN_END_DT = 'origin_end_dt',
  /** column name */
  OVERDUE = 'overdue',
  /** column name */
  QA_CONTENT = 'qa_content',
  /** column name */
  QUESTIONS_ENABLED = 'questions_enabled',
  /** column name */
  SECOND_PART = 'second_part',
  /** column name */
  THIRD_PART = 'third_part',
  /** column name */
  UNLOCKED_AT = 'unlocked_at',
  /** column name */
  USER_CHILD_ID = 'user_child_id',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  USER_LESSON_PROGRESSES_ID = 'user_lesson_progresses_id'
}

/** aggregate stddev on columns */
export interface IMissedLessonsViewStddevFields {
  __typename?: 'missed_lessons_view_stddev_fields';
  content_key?: Maybe<Scalars['Float']>;
  days_left?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IMissedLessonsViewStddevPopFields {
  __typename?: 'missed_lessons_view_stddev_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
  days_left?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IMissedLessonsViewStddevSampFields {
  __typename?: 'missed_lessons_view_stddev_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
  days_left?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IMissedLessonsViewSumFields {
  __typename?: 'missed_lessons_view_sum_fields';
  content_key?: Maybe<Scalars['Int']>;
  days_left?: Maybe<Scalars['float8']>;
}

/** aggregate var_pop on columns */
export interface IMissedLessonsViewVarPopFields {
  __typename?: 'missed_lessons_view_var_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
  days_left?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IMissedLessonsViewVarSampFields {
  __typename?: 'missed_lessons_view_var_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
  days_left?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IMissedLessonsViewVarianceFields {
  __typename?: 'missed_lessons_view_variance_fields';
  content_key?: Maybe<Scalars['Float']>;
  days_left?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "missed_qa_view" */
export interface IMissedQaView {
  __typename?: 'missed_qa_view';
  content_key?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  qa_content?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "missed_qa_view" */
export interface IMissedQaViewAggregate {
  __typename?: 'missed_qa_view_aggregate';
  aggregate?: Maybe<IMissedQaViewAggregateFields>;
  nodes: Array<IMissedQaView>;
}

/** aggregate fields of "missed_qa_view" */
export interface IMissedQaViewAggregateFields {
  __typename?: 'missed_qa_view_aggregate_fields';
  avg?: Maybe<IMissedQaViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IMissedQaViewMaxFields>;
  min?: Maybe<IMissedQaViewMinFields>;
  stddev?: Maybe<IMissedQaViewStddevFields>;
  stddev_pop?: Maybe<IMissedQaViewStddevPopFields>;
  stddev_samp?: Maybe<IMissedQaViewStddevSampFields>;
  sum?: Maybe<IMissedQaViewSumFields>;
  var_pop?: Maybe<IMissedQaViewVarPopFields>;
  var_samp?: Maybe<IMissedQaViewVarSampFields>;
  variance?: Maybe<IMissedQaViewVarianceFields>;
}


/** aggregate fields of "missed_qa_view" */
export interface IMissedQaViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IMissedQaViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IMissedQaViewAvgFields {
  __typename?: 'missed_qa_view_avg_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "missed_qa_view". All fields are combined with a logical 'AND'. */
export interface IMissedQaViewBoolExp {
  _and?: InputMaybe<Array<IMissedQaViewBoolExp>>;
  _not?: InputMaybe<IMissedQaViewBoolExp>;
  _or?: InputMaybe<Array<IMissedQaViewBoolExp>>;
  content_key?: InputMaybe<IIntComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  end_time?: InputMaybe<ITimestamptzComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  qa_content?: InputMaybe<IStringComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface IMissedQaViewMaxFields {
  __typename?: 'missed_qa_view_max_fields';
  content_key?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  qa_content?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IMissedQaViewMinFields {
  __typename?: 'missed_qa_view_min_fields';
  content_key?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  qa_content?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "missed_qa_view". */
export interface IMissedQaViewOrderBy {
  content_key?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  end_time?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  qa_content?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "missed_qa_view" */
export enum IMissedQaViewSelectColumn {
  /** column name */
  CONTENT_KEY = 'content_key',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  END_TIME = 'end_time',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  NAME = 'name',
  /** column name */
  QA_CONTENT = 'qa_content',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate stddev on columns */
export interface IMissedQaViewStddevFields {
  __typename?: 'missed_qa_view_stddev_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IMissedQaViewStddevPopFields {
  __typename?: 'missed_qa_view_stddev_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IMissedQaViewStddevSampFields {
  __typename?: 'missed_qa_view_stddev_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IMissedQaViewSumFields {
  __typename?: 'missed_qa_view_sum_fields';
  content_key?: Maybe<Scalars['Int']>;
}

/** aggregate var_pop on columns */
export interface IMissedQaViewVarPopFields {
  __typename?: 'missed_qa_view_var_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IMissedQaViewVarSampFields {
  __typename?: 'missed_qa_view_var_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IMissedQaViewVarianceFields {
  __typename?: 'missed_qa_view_variance_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "missed_second_part_view" */
export interface IMissedSecondPartView {
  __typename?: 'missed_second_part_view';
  content_key?: Maybe<Scalars['Int']>;
  /** An object relationship */
  course_group?: Maybe<ICourseGroups>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  days_left?: Maybe<Scalars['float8']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['interval']>;
  end_dt?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  origin_end_dt?: Maybe<Scalars['timestamp']>;
  questions_enabled?: Maybe<Scalars['Boolean']>;
  second_part?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "missed_second_part_view" */
export interface IMissedSecondPartViewAggregate {
  __typename?: 'missed_second_part_view_aggregate';
  aggregate?: Maybe<IMissedSecondPartViewAggregateFields>;
  nodes: Array<IMissedSecondPartView>;
}

/** aggregate fields of "missed_second_part_view" */
export interface IMissedSecondPartViewAggregateFields {
  __typename?: 'missed_second_part_view_aggregate_fields';
  avg?: Maybe<IMissedSecondPartViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IMissedSecondPartViewMaxFields>;
  min?: Maybe<IMissedSecondPartViewMinFields>;
  stddev?: Maybe<IMissedSecondPartViewStddevFields>;
  stddev_pop?: Maybe<IMissedSecondPartViewStddevPopFields>;
  stddev_samp?: Maybe<IMissedSecondPartViewStddevSampFields>;
  sum?: Maybe<IMissedSecondPartViewSumFields>;
  var_pop?: Maybe<IMissedSecondPartViewVarPopFields>;
  var_samp?: Maybe<IMissedSecondPartViewVarSampFields>;
  variance?: Maybe<IMissedSecondPartViewVarianceFields>;
}


/** aggregate fields of "missed_second_part_view" */
export interface IMissedSecondPartViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IMissedSecondPartViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IMissedSecondPartViewAvgFields {
  __typename?: 'missed_second_part_view_avg_fields';
  content_key?: Maybe<Scalars['Float']>;
  days_left?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "missed_second_part_view". All fields are combined with a logical 'AND'. */
export interface IMissedSecondPartViewBoolExp {
  _and?: InputMaybe<Array<IMissedSecondPartViewBoolExp>>;
  _not?: InputMaybe<IMissedSecondPartViewBoolExp>;
  _or?: InputMaybe<Array<IMissedSecondPartViewBoolExp>>;
  content_key?: InputMaybe<IIntComparisonExp>;
  course_group?: InputMaybe<ICourseGroupsBoolExp>;
  course_group_id?: InputMaybe<IUuidComparisonExp>;
  course_type?: InputMaybe<IStringComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  days_left?: InputMaybe<IFloat8ComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  duration?: InputMaybe<IIntervalComparisonExp>;
  end_dt?: InputMaybe<ITimestamptzComparisonExp>;
  end_time?: InputMaybe<ITimestamptzComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  origin_end_dt?: InputMaybe<ITimestampComparisonExp>;
  questions_enabled?: InputMaybe<IBooleanComparisonExp>;
  second_part?: InputMaybe<IStringComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface IMissedSecondPartViewMaxFields {
  __typename?: 'missed_second_part_view_max_fields';
  content_key?: Maybe<Scalars['Int']>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  days_left?: Maybe<Scalars['float8']>;
  description?: Maybe<Scalars['String']>;
  end_dt?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  origin_end_dt?: Maybe<Scalars['timestamp']>;
  second_part?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IMissedSecondPartViewMinFields {
  __typename?: 'missed_second_part_view_min_fields';
  content_key?: Maybe<Scalars['Int']>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  days_left?: Maybe<Scalars['float8']>;
  description?: Maybe<Scalars['String']>;
  end_dt?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  origin_end_dt?: Maybe<Scalars['timestamp']>;
  second_part?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "missed_second_part_view". */
export interface IMissedSecondPartViewOrderBy {
  content_key?: InputMaybe<IOrderBy>;
  course_group?: InputMaybe<ICourseGroupsOrderBy>;
  course_group_id?: InputMaybe<IOrderBy>;
  course_type?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  days_left?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  duration?: InputMaybe<IOrderBy>;
  end_dt?: InputMaybe<IOrderBy>;
  end_time?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  origin_end_dt?: InputMaybe<IOrderBy>;
  questions_enabled?: InputMaybe<IOrderBy>;
  second_part?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "missed_second_part_view" */
export enum IMissedSecondPartViewSelectColumn {
  /** column name */
  CONTENT_KEY = 'content_key',
  /** column name */
  COURSE_GROUP_ID = 'course_group_id',
  /** column name */
  COURSE_TYPE = 'course_type',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DAYS_LEFT = 'days_left',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  DURATION = 'duration',
  /** column name */
  END_DT = 'end_dt',
  /** column name */
  END_TIME = 'end_time',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  NAME = 'name',
  /** column name */
  ORIGIN_END_DT = 'origin_end_dt',
  /** column name */
  QUESTIONS_ENABLED = 'questions_enabled',
  /** column name */
  SECOND_PART = 'second_part',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate stddev on columns */
export interface IMissedSecondPartViewStddevFields {
  __typename?: 'missed_second_part_view_stddev_fields';
  content_key?: Maybe<Scalars['Float']>;
  days_left?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IMissedSecondPartViewStddevPopFields {
  __typename?: 'missed_second_part_view_stddev_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
  days_left?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IMissedSecondPartViewStddevSampFields {
  __typename?: 'missed_second_part_view_stddev_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
  days_left?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IMissedSecondPartViewSumFields {
  __typename?: 'missed_second_part_view_sum_fields';
  content_key?: Maybe<Scalars['Int']>;
  days_left?: Maybe<Scalars['float8']>;
}

/** aggregate var_pop on columns */
export interface IMissedSecondPartViewVarPopFields {
  __typename?: 'missed_second_part_view_var_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
  days_left?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IMissedSecondPartViewVarSampFields {
  __typename?: 'missed_second_part_view_var_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
  days_left?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IMissedSecondPartViewVarianceFields {
  __typename?: 'missed_second_part_view_variance_fields';
  content_key?: Maybe<Scalars['Float']>;
  days_left?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "missed_third_part_view" */
export interface IMissedThirdPartView {
  __typename?: 'missed_third_part_view';
  content_key?: Maybe<Scalars['Int']>;
  /** An object relationship */
  course_group?: Maybe<ICourseGroups>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  days_left?: Maybe<Scalars['float8']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['interval']>;
  end_dt?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  origin_end_dt?: Maybe<Scalars['timestamp']>;
  questions_enabled?: Maybe<Scalars['Boolean']>;
  third_part?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "missed_third_part_view" */
export interface IMissedThirdPartViewAggregate {
  __typename?: 'missed_third_part_view_aggregate';
  aggregate?: Maybe<IMissedThirdPartViewAggregateFields>;
  nodes: Array<IMissedThirdPartView>;
}

/** aggregate fields of "missed_third_part_view" */
export interface IMissedThirdPartViewAggregateFields {
  __typename?: 'missed_third_part_view_aggregate_fields';
  avg?: Maybe<IMissedThirdPartViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IMissedThirdPartViewMaxFields>;
  min?: Maybe<IMissedThirdPartViewMinFields>;
  stddev?: Maybe<IMissedThirdPartViewStddevFields>;
  stddev_pop?: Maybe<IMissedThirdPartViewStddevPopFields>;
  stddev_samp?: Maybe<IMissedThirdPartViewStddevSampFields>;
  sum?: Maybe<IMissedThirdPartViewSumFields>;
  var_pop?: Maybe<IMissedThirdPartViewVarPopFields>;
  var_samp?: Maybe<IMissedThirdPartViewVarSampFields>;
  variance?: Maybe<IMissedThirdPartViewVarianceFields>;
}


/** aggregate fields of "missed_third_part_view" */
export interface IMissedThirdPartViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IMissedThirdPartViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IMissedThirdPartViewAvgFields {
  __typename?: 'missed_third_part_view_avg_fields';
  content_key?: Maybe<Scalars['Float']>;
  days_left?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "missed_third_part_view". All fields are combined with a logical 'AND'. */
export interface IMissedThirdPartViewBoolExp {
  _and?: InputMaybe<Array<IMissedThirdPartViewBoolExp>>;
  _not?: InputMaybe<IMissedThirdPartViewBoolExp>;
  _or?: InputMaybe<Array<IMissedThirdPartViewBoolExp>>;
  content_key?: InputMaybe<IIntComparisonExp>;
  course_group?: InputMaybe<ICourseGroupsBoolExp>;
  course_group_id?: InputMaybe<IUuidComparisonExp>;
  course_type?: InputMaybe<IStringComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  days_left?: InputMaybe<IFloat8ComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  duration?: InputMaybe<IIntervalComparisonExp>;
  end_dt?: InputMaybe<ITimestamptzComparisonExp>;
  end_time?: InputMaybe<ITimestamptzComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  origin_end_dt?: InputMaybe<ITimestampComparisonExp>;
  questions_enabled?: InputMaybe<IBooleanComparisonExp>;
  third_part?: InputMaybe<IStringComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface IMissedThirdPartViewMaxFields {
  __typename?: 'missed_third_part_view_max_fields';
  content_key?: Maybe<Scalars['Int']>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  days_left?: Maybe<Scalars['float8']>;
  description?: Maybe<Scalars['String']>;
  end_dt?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  origin_end_dt?: Maybe<Scalars['timestamp']>;
  third_part?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IMissedThirdPartViewMinFields {
  __typename?: 'missed_third_part_view_min_fields';
  content_key?: Maybe<Scalars['Int']>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  days_left?: Maybe<Scalars['float8']>;
  description?: Maybe<Scalars['String']>;
  end_dt?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  origin_end_dt?: Maybe<Scalars['timestamp']>;
  third_part?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "missed_third_part_view". */
export interface IMissedThirdPartViewOrderBy {
  content_key?: InputMaybe<IOrderBy>;
  course_group?: InputMaybe<ICourseGroupsOrderBy>;
  course_group_id?: InputMaybe<IOrderBy>;
  course_type?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  days_left?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  duration?: InputMaybe<IOrderBy>;
  end_dt?: InputMaybe<IOrderBy>;
  end_time?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  origin_end_dt?: InputMaybe<IOrderBy>;
  questions_enabled?: InputMaybe<IOrderBy>;
  third_part?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "missed_third_part_view" */
export enum IMissedThirdPartViewSelectColumn {
  /** column name */
  CONTENT_KEY = 'content_key',
  /** column name */
  COURSE_GROUP_ID = 'course_group_id',
  /** column name */
  COURSE_TYPE = 'course_type',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DAYS_LEFT = 'days_left',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  DURATION = 'duration',
  /** column name */
  END_DT = 'end_dt',
  /** column name */
  END_TIME = 'end_time',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  NAME = 'name',
  /** column name */
  ORIGIN_END_DT = 'origin_end_dt',
  /** column name */
  QUESTIONS_ENABLED = 'questions_enabled',
  /** column name */
  THIRD_PART = 'third_part',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate stddev on columns */
export interface IMissedThirdPartViewStddevFields {
  __typename?: 'missed_third_part_view_stddev_fields';
  content_key?: Maybe<Scalars['Float']>;
  days_left?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IMissedThirdPartViewStddevPopFields {
  __typename?: 'missed_third_part_view_stddev_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
  days_left?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IMissedThirdPartViewStddevSampFields {
  __typename?: 'missed_third_part_view_stddev_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
  days_left?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IMissedThirdPartViewSumFields {
  __typename?: 'missed_third_part_view_sum_fields';
  content_key?: Maybe<Scalars['Int']>;
  days_left?: Maybe<Scalars['float8']>;
}

/** aggregate var_pop on columns */
export interface IMissedThirdPartViewVarPopFields {
  __typename?: 'missed_third_part_view_var_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
  days_left?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IMissedThirdPartViewVarSampFields {
  __typename?: 'missed_third_part_view_var_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
  days_left?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IMissedThirdPartViewVarianceFields {
  __typename?: 'missed_third_part_view_variance_fields';
  content_key?: Maybe<Scalars['Float']>;
  days_left?: Maybe<Scalars['Float']>;
}

/** mutation root */
export interface IMutationRoot {
  __typename?: 'mutation_root';
  /** delete data from the table: "achievement_types" */
  delete_achievement_types?: Maybe<IAchievementTypesMutationResponse>;
  /** delete single row from the table: "achievement_types" */
  delete_achievement_types_by_pk?: Maybe<IAchievementTypes>;
  /** delete data from the table: "achievements" */
  delete_achievements?: Maybe<IAchievementsMutationResponse>;
  /** delete data from the table: "achievements_accomplished" */
  delete_achievements_accomplished?: Maybe<IAchievementsAccomplishedMutationResponse>;
  /** delete single row from the table: "achievements_accomplished" */
  delete_achievements_accomplished_by_pk?: Maybe<IAchievementsAccomplished>;
  /** delete single row from the table: "achievements" */
  delete_achievements_by_pk?: Maybe<IAchievements>;
  /** delete data from the table: "active_storage_attachments" */
  delete_active_storage_attachments?: Maybe<IActiveStorageAttachmentsMutationResponse>;
  /** delete single row from the table: "active_storage_attachments" */
  delete_active_storage_attachments_by_pk?: Maybe<IActiveStorageAttachments>;
  /** delete data from the table: "active_storage_blobs" */
  delete_active_storage_blobs?: Maybe<IActiveStorageBlobsMutationResponse>;
  /** delete single row from the table: "active_storage_blobs" */
  delete_active_storage_blobs_by_pk?: Maybe<IActiveStorageBlobs>;
  /** delete data from the table: "additional_lessons" */
  delete_additional_lessons?: Maybe<IAdditionalLessonsMutationResponse>;
  /** delete single row from the table: "additional_lessons" */
  delete_additional_lessons_by_pk?: Maybe<IAdditionalLessons>;
  /** delete data from the table: "additional_lessons_group_types" */
  delete_additional_lessons_group_types?: Maybe<IAdditionalLessonsGroupTypesMutationResponse>;
  /** delete single row from the table: "additional_lessons_group_types" */
  delete_additional_lessons_group_types_by_pk?: Maybe<IAdditionalLessonsGroupTypes>;
  /** delete data from the table: "ambassadors" */
  delete_ambassadors?: Maybe<IAmbassadorsMutationResponse>;
  /** delete single row from the table: "ambassadors" */
  delete_ambassadors_by_pk?: Maybe<IAmbassadors>;
  /** delete data from the table: "annual_schedules" */
  delete_annual_schedules?: Maybe<IAnnualSchedulesMutationResponse>;
  /** delete single row from the table: "annual_schedules" */
  delete_annual_schedules_by_pk?: Maybe<IAnnualSchedules>;
  /** delete data from the table: "answers" */
  delete_answers?: Maybe<IAnswersMutationResponse>;
  /** delete single row from the table: "answers" */
  delete_answers_by_pk?: Maybe<IAnswers>;
  /** delete data from the table: "course_groups" */
  delete_course_groups?: Maybe<ICourseGroupsMutationResponse>;
  /** delete single row from the table: "course_groups" */
  delete_course_groups_by_pk?: Maybe<ICourseGroups>;
  /** delete data from the table: "course_types" */
  delete_course_types?: Maybe<ICourseTypesMutationResponse>;
  /** delete single row from the table: "course_types" */
  delete_course_types_by_pk?: Maybe<ICourseTypes>;
  /** delete data from the table: "courses" */
  delete_courses?: Maybe<ICoursesMutationResponse>;
  /** delete single row from the table: "courses" */
  delete_courses_by_pk?: Maybe<ICourses>;
  /** delete data from the table: "dictionary_words" */
  delete_dictionary_words?: Maybe<IDictionaryWordsMutationResponse>;
  /** delete single row from the table: "dictionary_words" */
  delete_dictionary_words_by_pk?: Maybe<IDictionaryWords>;
  /** delete data from the table: "event_locations" */
  delete_event_locations?: Maybe<IEventLocationsMutationResponse>;
  /** delete single row from the table: "event_locations" */
  delete_event_locations_by_pk?: Maybe<IEventLocations>;
  /** delete data from the table: "event_registrations" */
  delete_event_registrations?: Maybe<IEventRegistrationsMutationResponse>;
  /** delete single row from the table: "event_registrations" */
  delete_event_registrations_by_pk?: Maybe<IEventRegistrations>;
  /** delete data from the table: "events" */
  delete_events?: Maybe<IEventsMutationResponse>;
  /** delete single row from the table: "events" */
  delete_events_by_pk?: Maybe<IEvents>;
  /** delete data from the table: "events_group_types" */
  delete_events_group_types?: Maybe<IEventsGroupTypesMutationResponse>;
  /** delete single row from the table: "events_group_types" */
  delete_events_group_types_by_pk?: Maybe<IEventsGroupTypes>;
  /** delete data from the table: "favorite_lesson_moments" */
  delete_favorite_lesson_moments?: Maybe<IFavoriteLessonMomentsMutationResponse>;
  /** delete single row from the table: "favorite_lesson_moments" */
  delete_favorite_lesson_moments_by_pk?: Maybe<IFavoriteLessonMoments>;
  /** delete data from the table: "final_test_questions" */
  delete_final_test_questions?: Maybe<IFinalTestQuestionsMutationResponse>;
  /** delete single row from the table: "final_test_questions" */
  delete_final_test_questions_by_pk?: Maybe<IFinalTestQuestions>;
  /** delete data from the table: "final_test_results" */
  delete_final_test_results?: Maybe<IFinalTestResultsMutationResponse>;
  /** delete single row from the table: "final_test_results" */
  delete_final_test_results_by_pk?: Maybe<IFinalTestResults>;
  /** delete data from the table: "friend_requests" */
  delete_friend_requests?: Maybe<IFriendRequestsMutationResponse>;
  /** delete single row from the table: "friend_requests" */
  delete_friend_requests_by_pk?: Maybe<IFriendRequests>;
  /** delete data from the table: "group_types" */
  delete_group_types?: Maybe<IGroupTypesMutationResponse>;
  /** delete single row from the table: "group_types" */
  delete_group_types_by_pk?: Maybe<IGroupTypes>;
  /** delete data from the table: "knowledge_test_questions" */
  delete_knowledge_test_questions?: Maybe<IKnowledgeTestQuestionsMutationResponse>;
  /** delete single row from the table: "knowledge_test_questions" */
  delete_knowledge_test_questions_by_pk?: Maybe<IKnowledgeTestQuestions>;
  /** delete data from the table: "knowledge_tests" */
  delete_knowledge_tests?: Maybe<IKnowledgeTestsMutationResponse>;
  /** delete single row from the table: "knowledge_tests" */
  delete_knowledge_tests_by_pk?: Maybe<IKnowledgeTests>;
  /** delete data from the table: "learning_assignments" */
  delete_learning_assignments?: Maybe<ILearningAssignmentsMutationResponse>;
  /** delete single row from the table: "learning_assignments" */
  delete_learning_assignments_by_pk?: Maybe<ILearningAssignments>;
  /** delete data from the table: "lesson_days" */
  delete_lesson_days?: Maybe<ILessonDaysMutationResponse>;
  /** delete single row from the table: "lesson_days" */
  delete_lesson_days_by_pk?: Maybe<ILessonDays>;
  /** delete data from the table: "lesson_messages" */
  delete_lesson_messages?: Maybe<ILessonMessagesMutationResponse>;
  /** delete single row from the table: "lesson_messages" */
  delete_lesson_messages_by_pk?: Maybe<ILessonMessages>;
  /** delete data from the table: "lesson_partitions" */
  delete_lesson_partitions?: Maybe<ILessonPartitionsMutationResponse>;
  /** delete single row from the table: "lesson_partitions" */
  delete_lesson_partitions_by_pk?: Maybe<ILessonPartitions>;
  /** delete data from the table: "lesson_reactions" */
  delete_lesson_reactions?: Maybe<ILessonReactionsMutationResponse>;
  /** delete single row from the table: "lesson_reactions" */
  delete_lesson_reactions_by_pk?: Maybe<ILessonReactions>;
  /** delete data from the table: "lesson_unlock_keys" */
  delete_lesson_unlock_keys?: Maybe<ILessonUnlockKeysMutationResponse>;
  /** delete single row from the table: "lesson_unlock_keys" */
  delete_lesson_unlock_keys_by_pk?: Maybe<ILessonUnlockKeys>;
  /** delete data from the table: "lesson_verses" */
  delete_lesson_verses?: Maybe<ILessonVersesMutationResponse>;
  /** delete single row from the table: "lesson_verses" */
  delete_lesson_verses_by_pk?: Maybe<ILessonVerses>;
  /** delete data from the table: "lesson_words" */
  delete_lesson_words?: Maybe<ILessonWordsMutationResponse>;
  /** delete single row from the table: "lesson_words" */
  delete_lesson_words_by_pk?: Maybe<ILessonWords>;
  /** delete data from the table: "lessons" */
  delete_lessons?: Maybe<ILessonsMutationResponse>;
  /** delete single row from the table: "lessons" */
  delete_lessons_by_pk?: Maybe<ILessons>;
  /** delete data from the table: "lessons_unlocked" */
  delete_lessons_unlocked?: Maybe<ILessonsUnlockedMutationResponse>;
  /** delete single row from the table: "lessons_unlocked" */
  delete_lessons_unlocked_by_pk?: Maybe<ILessonsUnlocked>;
  /** delete data from the table: "library_downloads" */
  delete_library_downloads?: Maybe<ILibraryDownloadsMutationResponse>;
  /** delete single row from the table: "library_downloads" */
  delete_library_downloads_by_pk?: Maybe<ILibraryDownloads>;
  /** delete data from the table: "message_types" */
  delete_message_types?: Maybe<IMessageTypesMutationResponse>;
  /** delete single row from the table: "message_types" */
  delete_message_types_by_pk?: Maybe<IMessageTypes>;
  /** delete data from the table: "messages" */
  delete_messages?: Maybe<IMessagesMutationResponse>;
  /** delete single row from the table: "messages" */
  delete_messages_by_pk?: Maybe<IMessages>;
  /** delete data from the table: "news_articles" */
  delete_news_articles?: Maybe<INewsArticlesMutationResponse>;
  /** delete single row from the table: "news_articles" */
  delete_news_articles_by_pk?: Maybe<INewsArticles>;
  /** delete data from the table: "news_articles_view" */
  delete_news_articles_view?: Maybe<INewsArticlesViewMutationResponse>;
  /** delete data from the table: "notification_group_types" */
  delete_notification_group_types?: Maybe<INotificationGroupTypesMutationResponse>;
  /** delete single row from the table: "notification_group_types" */
  delete_notification_group_types_by_pk?: Maybe<INotificationGroupTypes>;
  /** delete data from the table: "notifications" */
  delete_notifications?: Maybe<INotificationsMutationResponse>;
  /** delete single row from the table: "notifications" */
  delete_notifications_by_pk?: Maybe<INotifications>;
  /** delete data from the table: "personal_developments" */
  delete_personal_developments?: Maybe<IPersonalDevelopmentsMutationResponse>;
  /** delete single row from the table: "personal_developments" */
  delete_personal_developments_by_pk?: Maybe<IPersonalDevelopments>;
  /** delete data from the table: "qa_videos" */
  delete_qa_videos?: Maybe<IQaVideosMutationResponse>;
  /** delete single row from the table: "qa_videos" */
  delete_qa_videos_by_pk?: Maybe<IQaVideos>;
  /** delete data from the table: "que_jobs" */
  delete_que_jobs?: Maybe<IQueJobsMutationResponse>;
  /** delete single row from the table: "que_jobs" */
  delete_que_jobs_by_pk?: Maybe<IQueJobs>;
  /** delete data from the table: "questions" */
  delete_questions?: Maybe<IQuestionsMutationResponse>;
  /** delete single row from the table: "questions" */
  delete_questions_by_pk?: Maybe<IQuestions>;
  /** delete data from the table: "recitations" */
  delete_recitations?: Maybe<IRecitationsMutationResponse>;
  /** delete single row from the table: "recitations" */
  delete_recitations_by_pk?: Maybe<IRecitations>;
  /** delete data from the table: "repeat_lesson_progresses" */
  delete_repeat_lesson_progresses?: Maybe<IRepeatLessonProgressesMutationResponse>;
  /** delete single row from the table: "repeat_lesson_progresses" */
  delete_repeat_lesson_progresses_by_pk?: Maybe<IRepeatLessonProgresses>;
  /** delete data from the table: "repeat_second_parts_progresses" */
  delete_repeat_second_parts_progresses?: Maybe<IRepeatSecondPartsProgressesMutationResponse>;
  /** delete single row from the table: "repeat_second_parts_progresses" */
  delete_repeat_second_parts_progresses_by_pk?: Maybe<IRepeatSecondPartsProgresses>;
  /** delete data from the table: "repeat_third_parts_progresses" */
  delete_repeat_third_parts_progresses?: Maybe<IRepeatThirdPartsProgressesMutationResponse>;
  /** delete single row from the table: "repeat_third_parts_progresses" */
  delete_repeat_third_parts_progresses_by_pk?: Maybe<IRepeatThirdPartsProgresses>;
  /** delete data from the table: "schema_migrations" */
  delete_schema_migrations?: Maybe<ISchemaMigrationsMutationResponse>;
  /** delete single row from the table: "schema_migrations" */
  delete_schema_migrations_by_pk?: Maybe<ISchemaMigrations>;
  /** delete data from the table: "subscription_configs" */
  delete_subscription_configs?: Maybe<ISubscriptionConfigsMutationResponse>;
  /** delete single row from the table: "subscription_configs" */
  delete_subscription_configs_by_pk?: Maybe<ISubscriptionConfigs>;
  /** delete data from the table: "subscriptions" */
  delete_subscriptions?: Maybe<ISubscriptionsMutationResponse>;
  /** delete single row from the table: "subscriptions" */
  delete_subscriptions_by_pk?: Maybe<ISubscriptions>;
  /** delete data from the table: "support_topic_entries" */
  delete_support_topic_entries?: Maybe<ISupportTopicEntriesMutationResponse>;
  /** delete single row from the table: "support_topic_entries" */
  delete_support_topic_entries_by_pk?: Maybe<ISupportTopicEntries>;
  /** delete data from the table: "support_topics" */
  delete_support_topics?: Maybe<ISupportTopicsMutationResponse>;
  /** delete single row from the table: "support_topics" */
  delete_support_topics_by_pk?: Maybe<ISupportTopics>;
  /** delete data from the table: "suspensions" */
  delete_suspensions?: Maybe<ISuspensionsMutationResponse>;
  /** delete single row from the table: "suspensions" */
  delete_suspensions_by_pk?: Maybe<ISuspensions>;
  /** delete data from the table: "tmp_lessons" */
  delete_tmp_lessons?: Maybe<ITmpLessonsMutationResponse>;
  /** delete single row from the table: "tmp_lessons" */
  delete_tmp_lessons_by_pk?: Maybe<ITmpLessons>;
  /** delete data from the table: "tmp_progress" */
  delete_tmp_progress?: Maybe<ITmpProgressMutationResponse>;
  /** delete single row from the table: "tmp_progress" */
  delete_tmp_progress_by_pk?: Maybe<ITmpProgress>;
  /** delete data from the table: "unlockables" */
  delete_unlockables?: Maybe<IUnlockablesMutationResponse>;
  /** delete single row from the table: "unlockables" */
  delete_unlockables_by_pk?: Maybe<IUnlockables>;
  /** delete data from the table: "user_children_unlockables" */
  delete_user_children_unlockables?: Maybe<IUserChildrenUnlockablesMutationResponse>;
  /** delete single row from the table: "user_children_unlockables" */
  delete_user_children_unlockables_by_pk?: Maybe<IUserChildrenUnlockables>;
  /** delete data from the table: "user_lesson_parts_progresses" */
  delete_user_lesson_parts_progresses?: Maybe<IUserLessonPartsProgressesMutationResponse>;
  /** delete single row from the table: "user_lesson_parts_progresses" */
  delete_user_lesson_parts_progresses_by_pk?: Maybe<IUserLessonPartsProgresses>;
  /** delete data from the table: "user_lesson_progresses" */
  delete_user_lesson_progresses?: Maybe<IUserLessonProgressesMutationResponse>;
  /** delete single row from the table: "user_lesson_progresses" */
  delete_user_lesson_progresses_by_pk?: Maybe<IUserLessonProgresses>;
  /** delete data from the table: "user_qa_progresses" */
  delete_user_qa_progresses?: Maybe<IUserQaProgressesMutationResponse>;
  /** delete single row from the table: "user_qa_progresses" */
  delete_user_qa_progresses_by_pk?: Maybe<IUserQaProgresses>;
  /** delete data from the table: "user_third_parts_progresses" */
  delete_user_third_parts_progresses?: Maybe<IUserThirdPartsProgressesMutationResponse>;
  /** delete single row from the table: "user_third_parts_progresses" */
  delete_user_third_parts_progresses_by_pk?: Maybe<IUserThirdPartsProgresses>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<IUsersMutationResponse>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<IUsers>;
  /** delete data from the table: "users_child_details" */
  delete_users_child_details?: Maybe<IUsersChildDetailsMutationResponse>;
  /** delete single row from the table: "users_child_details" */
  delete_users_child_details_by_pk?: Maybe<IUsersChildDetails>;
  /** delete data from the table: "users_children" */
  delete_users_children?: Maybe<IUsersChildrenMutationResponse>;
  /** delete single row from the table: "users_children" */
  delete_users_children_by_pk?: Maybe<IUsersChildren>;
  /** delete data from the table: "users_sessions" */
  delete_users_sessions?: Maybe<IUsersSessionsMutationResponse>;
  /** delete single row from the table: "users_sessions" */
  delete_users_sessions_by_pk?: Maybe<IUsersSessions>;
  /** delete data from the table: "vacation_group_types" */
  delete_vacation_group_types?: Maybe<IVacationGroupTypesMutationResponse>;
  /** delete single row from the table: "vacation_group_types" */
  delete_vacation_group_types_by_pk?: Maybe<IVacationGroupTypes>;
  /** delete data from the table: "vacations" */
  delete_vacations?: Maybe<IVacationsMutationResponse>;
  /** delete single row from the table: "vacations" */
  delete_vacations_by_pk?: Maybe<IVacations>;
  /** delete data from the table: "weekly_facts" */
  delete_weekly_facts?: Maybe<IWeeklyFactsMutationResponse>;
  /** delete single row from the table: "weekly_facts" */
  delete_weekly_facts_by_pk?: Maybe<IWeeklyFacts>;
  /** delete data from the table: "wp_orders" */
  delete_wp_orders?: Maybe<IWpOrdersMutationResponse>;
  /** delete single row from the table: "wp_orders" */
  delete_wp_orders_by_pk?: Maybe<IWpOrders>;
  /** delete data from the table: "wp_products" */
  delete_wp_products?: Maybe<IWpProductsMutationResponse>;
  /** delete single row from the table: "wp_products" */
  delete_wp_products_by_pk?: Maybe<IWpProducts>;
  /** delete data from the table: "wp_subscription_orders" */
  delete_wp_subscription_orders?: Maybe<IWpSubscriptionOrdersMutationResponse>;
  /** delete single row from the table: "wp_subscription_orders" */
  delete_wp_subscription_orders_by_pk?: Maybe<IWpSubscriptionOrders>;
  /** insert data into the table: "achievement_types" */
  insert_achievement_types?: Maybe<IAchievementTypesMutationResponse>;
  /** insert a single row into the table: "achievement_types" */
  insert_achievement_types_one?: Maybe<IAchievementTypes>;
  /** insert data into the table: "achievements" */
  insert_achievements?: Maybe<IAchievementsMutationResponse>;
  /** insert data into the table: "achievements_accomplished" */
  insert_achievements_accomplished?: Maybe<IAchievementsAccomplishedMutationResponse>;
  /** insert a single row into the table: "achievements_accomplished" */
  insert_achievements_accomplished_one?: Maybe<IAchievementsAccomplished>;
  /** insert a single row into the table: "achievements" */
  insert_achievements_one?: Maybe<IAchievements>;
  /** insert data into the table: "active_storage_attachments" */
  insert_active_storage_attachments?: Maybe<IActiveStorageAttachmentsMutationResponse>;
  /** insert a single row into the table: "active_storage_attachments" */
  insert_active_storage_attachments_one?: Maybe<IActiveStorageAttachments>;
  /** insert data into the table: "active_storage_blobs" */
  insert_active_storage_blobs?: Maybe<IActiveStorageBlobsMutationResponse>;
  /** insert a single row into the table: "active_storage_blobs" */
  insert_active_storage_blobs_one?: Maybe<IActiveStorageBlobs>;
  /** insert data into the table: "additional_lessons" */
  insert_additional_lessons?: Maybe<IAdditionalLessonsMutationResponse>;
  /** insert data into the table: "additional_lessons_group_types" */
  insert_additional_lessons_group_types?: Maybe<IAdditionalLessonsGroupTypesMutationResponse>;
  /** insert a single row into the table: "additional_lessons_group_types" */
  insert_additional_lessons_group_types_one?: Maybe<IAdditionalLessonsGroupTypes>;
  /** insert a single row into the table: "additional_lessons" */
  insert_additional_lessons_one?: Maybe<IAdditionalLessons>;
  /** insert data into the table: "ambassadors" */
  insert_ambassadors?: Maybe<IAmbassadorsMutationResponse>;
  /** insert a single row into the table: "ambassadors" */
  insert_ambassadors_one?: Maybe<IAmbassadors>;
  /** insert data into the table: "annual_schedules" */
  insert_annual_schedules?: Maybe<IAnnualSchedulesMutationResponse>;
  /** insert a single row into the table: "annual_schedules" */
  insert_annual_schedules_one?: Maybe<IAnnualSchedules>;
  /** insert data into the table: "answers" */
  insert_answers?: Maybe<IAnswersMutationResponse>;
  /** insert a single row into the table: "answers" */
  insert_answers_one?: Maybe<IAnswers>;
  /** insert data into the table: "course_groups" */
  insert_course_groups?: Maybe<ICourseGroupsMutationResponse>;
  /** insert a single row into the table: "course_groups" */
  insert_course_groups_one?: Maybe<ICourseGroups>;
  /** insert data into the table: "course_types" */
  insert_course_types?: Maybe<ICourseTypesMutationResponse>;
  /** insert a single row into the table: "course_types" */
  insert_course_types_one?: Maybe<ICourseTypes>;
  /** insert data into the table: "courses" */
  insert_courses?: Maybe<ICoursesMutationResponse>;
  /** insert a single row into the table: "courses" */
  insert_courses_one?: Maybe<ICourses>;
  /** insert data into the table: "dictionary_words" */
  insert_dictionary_words?: Maybe<IDictionaryWordsMutationResponse>;
  /** insert a single row into the table: "dictionary_words" */
  insert_dictionary_words_one?: Maybe<IDictionaryWords>;
  /** insert data into the table: "event_locations" */
  insert_event_locations?: Maybe<IEventLocationsMutationResponse>;
  /** insert a single row into the table: "event_locations" */
  insert_event_locations_one?: Maybe<IEventLocations>;
  /** insert data into the table: "event_registrations" */
  insert_event_registrations?: Maybe<IEventRegistrationsMutationResponse>;
  /** insert a single row into the table: "event_registrations" */
  insert_event_registrations_one?: Maybe<IEventRegistrations>;
  /** insert data into the table: "events" */
  insert_events?: Maybe<IEventsMutationResponse>;
  /** insert data into the table: "events_group_types" */
  insert_events_group_types?: Maybe<IEventsGroupTypesMutationResponse>;
  /** insert a single row into the table: "events_group_types" */
  insert_events_group_types_one?: Maybe<IEventsGroupTypes>;
  /** insert a single row into the table: "events" */
  insert_events_one?: Maybe<IEvents>;
  /** insert data into the table: "favorite_lesson_moments" */
  insert_favorite_lesson_moments?: Maybe<IFavoriteLessonMomentsMutationResponse>;
  /** insert a single row into the table: "favorite_lesson_moments" */
  insert_favorite_lesson_moments_one?: Maybe<IFavoriteLessonMoments>;
  /** insert data into the table: "final_test_questions" */
  insert_final_test_questions?: Maybe<IFinalTestQuestionsMutationResponse>;
  /** insert a single row into the table: "final_test_questions" */
  insert_final_test_questions_one?: Maybe<IFinalTestQuestions>;
  /** insert data into the table: "final_test_results" */
  insert_final_test_results?: Maybe<IFinalTestResultsMutationResponse>;
  /** insert a single row into the table: "final_test_results" */
  insert_final_test_results_one?: Maybe<IFinalTestResults>;
  /** insert data into the table: "friend_requests" */
  insert_friend_requests?: Maybe<IFriendRequestsMutationResponse>;
  /** insert a single row into the table: "friend_requests" */
  insert_friend_requests_one?: Maybe<IFriendRequests>;
  /** insert data into the table: "group_types" */
  insert_group_types?: Maybe<IGroupTypesMutationResponse>;
  /** insert a single row into the table: "group_types" */
  insert_group_types_one?: Maybe<IGroupTypes>;
  /** insert data into the table: "knowledge_test_questions" */
  insert_knowledge_test_questions?: Maybe<IKnowledgeTestQuestionsMutationResponse>;
  /** insert a single row into the table: "knowledge_test_questions" */
  insert_knowledge_test_questions_one?: Maybe<IKnowledgeTestQuestions>;
  /** insert data into the table: "knowledge_tests" */
  insert_knowledge_tests?: Maybe<IKnowledgeTestsMutationResponse>;
  /** insert a single row into the table: "knowledge_tests" */
  insert_knowledge_tests_one?: Maybe<IKnowledgeTests>;
  /** insert data into the table: "learning_assignments" */
  insert_learning_assignments?: Maybe<ILearningAssignmentsMutationResponse>;
  /** insert a single row into the table: "learning_assignments" */
  insert_learning_assignments_one?: Maybe<ILearningAssignments>;
  /** insert data into the table: "lesson_days" */
  insert_lesson_days?: Maybe<ILessonDaysMutationResponse>;
  /** insert a single row into the table: "lesson_days" */
  insert_lesson_days_one?: Maybe<ILessonDays>;
  /** insert data into the table: "lesson_messages" */
  insert_lesson_messages?: Maybe<ILessonMessagesMutationResponse>;
  /** insert a single row into the table: "lesson_messages" */
  insert_lesson_messages_one?: Maybe<ILessonMessages>;
  /** insert data into the table: "lesson_partitions" */
  insert_lesson_partitions?: Maybe<ILessonPartitionsMutationResponse>;
  /** insert a single row into the table: "lesson_partitions" */
  insert_lesson_partitions_one?: Maybe<ILessonPartitions>;
  /** insert data into the table: "lesson_reactions" */
  insert_lesson_reactions?: Maybe<ILessonReactionsMutationResponse>;
  /** insert a single row into the table: "lesson_reactions" */
  insert_lesson_reactions_one?: Maybe<ILessonReactions>;
  /** insert data into the table: "lesson_unlock_keys" */
  insert_lesson_unlock_keys?: Maybe<ILessonUnlockKeysMutationResponse>;
  /** insert a single row into the table: "lesson_unlock_keys" */
  insert_lesson_unlock_keys_one?: Maybe<ILessonUnlockKeys>;
  /** insert data into the table: "lesson_verses" */
  insert_lesson_verses?: Maybe<ILessonVersesMutationResponse>;
  /** insert a single row into the table: "lesson_verses" */
  insert_lesson_verses_one?: Maybe<ILessonVerses>;
  /** insert data into the table: "lesson_words" */
  insert_lesson_words?: Maybe<ILessonWordsMutationResponse>;
  /** insert a single row into the table: "lesson_words" */
  insert_lesson_words_one?: Maybe<ILessonWords>;
  /** insert data into the table: "lessons" */
  insert_lessons?: Maybe<ILessonsMutationResponse>;
  /** insert a single row into the table: "lessons" */
  insert_lessons_one?: Maybe<ILessons>;
  /** insert data into the table: "lessons_unlocked" */
  insert_lessons_unlocked?: Maybe<ILessonsUnlockedMutationResponse>;
  /** insert a single row into the table: "lessons_unlocked" */
  insert_lessons_unlocked_one?: Maybe<ILessonsUnlocked>;
  /** insert data into the table: "library_downloads" */
  insert_library_downloads?: Maybe<ILibraryDownloadsMutationResponse>;
  /** insert a single row into the table: "library_downloads" */
  insert_library_downloads_one?: Maybe<ILibraryDownloads>;
  /** insert data into the table: "message_types" */
  insert_message_types?: Maybe<IMessageTypesMutationResponse>;
  /** insert a single row into the table: "message_types" */
  insert_message_types_one?: Maybe<IMessageTypes>;
  /** insert data into the table: "messages" */
  insert_messages?: Maybe<IMessagesMutationResponse>;
  /** insert a single row into the table: "messages" */
  insert_messages_one?: Maybe<IMessages>;
  /** insert data into the table: "news_articles" */
  insert_news_articles?: Maybe<INewsArticlesMutationResponse>;
  /** insert a single row into the table: "news_articles" */
  insert_news_articles_one?: Maybe<INewsArticles>;
  /** insert data into the table: "news_articles_view" */
  insert_news_articles_view?: Maybe<INewsArticlesViewMutationResponse>;
  /** insert a single row into the table: "news_articles_view" */
  insert_news_articles_view_one?: Maybe<INewsArticlesView>;
  /** insert data into the table: "notification_group_types" */
  insert_notification_group_types?: Maybe<INotificationGroupTypesMutationResponse>;
  /** insert a single row into the table: "notification_group_types" */
  insert_notification_group_types_one?: Maybe<INotificationGroupTypes>;
  /** insert data into the table: "notifications" */
  insert_notifications?: Maybe<INotificationsMutationResponse>;
  /** insert a single row into the table: "notifications" */
  insert_notifications_one?: Maybe<INotifications>;
  /** insert data into the table: "personal_developments" */
  insert_personal_developments?: Maybe<IPersonalDevelopmentsMutationResponse>;
  /** insert a single row into the table: "personal_developments" */
  insert_personal_developments_one?: Maybe<IPersonalDevelopments>;
  /** insert data into the table: "qa_videos" */
  insert_qa_videos?: Maybe<IQaVideosMutationResponse>;
  /** insert a single row into the table: "qa_videos" */
  insert_qa_videos_one?: Maybe<IQaVideos>;
  /** insert data into the table: "que_jobs" */
  insert_que_jobs?: Maybe<IQueJobsMutationResponse>;
  /** insert a single row into the table: "que_jobs" */
  insert_que_jobs_one?: Maybe<IQueJobs>;
  /** insert data into the table: "questions" */
  insert_questions?: Maybe<IQuestionsMutationResponse>;
  /** insert a single row into the table: "questions" */
  insert_questions_one?: Maybe<IQuestions>;
  /** insert data into the table: "recitations" */
  insert_recitations?: Maybe<IRecitationsMutationResponse>;
  /** insert a single row into the table: "recitations" */
  insert_recitations_one?: Maybe<IRecitations>;
  /** insert data into the table: "repeat_lesson_progresses" */
  insert_repeat_lesson_progresses?: Maybe<IRepeatLessonProgressesMutationResponse>;
  /** insert a single row into the table: "repeat_lesson_progresses" */
  insert_repeat_lesson_progresses_one?: Maybe<IRepeatLessonProgresses>;
  /** insert data into the table: "repeat_second_parts_progresses" */
  insert_repeat_second_parts_progresses?: Maybe<IRepeatSecondPartsProgressesMutationResponse>;
  /** insert a single row into the table: "repeat_second_parts_progresses" */
  insert_repeat_second_parts_progresses_one?: Maybe<IRepeatSecondPartsProgresses>;
  /** insert data into the table: "repeat_third_parts_progresses" */
  insert_repeat_third_parts_progresses?: Maybe<IRepeatThirdPartsProgressesMutationResponse>;
  /** insert a single row into the table: "repeat_third_parts_progresses" */
  insert_repeat_third_parts_progresses_one?: Maybe<IRepeatThirdPartsProgresses>;
  /** insert data into the table: "schema_migrations" */
  insert_schema_migrations?: Maybe<ISchemaMigrationsMutationResponse>;
  /** insert a single row into the table: "schema_migrations" */
  insert_schema_migrations_one?: Maybe<ISchemaMigrations>;
  /** insert data into the table: "subscription_configs" */
  insert_subscription_configs?: Maybe<ISubscriptionConfigsMutationResponse>;
  /** insert a single row into the table: "subscription_configs" */
  insert_subscription_configs_one?: Maybe<ISubscriptionConfigs>;
  /** insert data into the table: "subscriptions" */
  insert_subscriptions?: Maybe<ISubscriptionsMutationResponse>;
  /** insert a single row into the table: "subscriptions" */
  insert_subscriptions_one?: Maybe<ISubscriptions>;
  /** insert data into the table: "support_topic_entries" */
  insert_support_topic_entries?: Maybe<ISupportTopicEntriesMutationResponse>;
  /** insert a single row into the table: "support_topic_entries" */
  insert_support_topic_entries_one?: Maybe<ISupportTopicEntries>;
  /** insert data into the table: "support_topics" */
  insert_support_topics?: Maybe<ISupportTopicsMutationResponse>;
  /** insert a single row into the table: "support_topics" */
  insert_support_topics_one?: Maybe<ISupportTopics>;
  /** insert data into the table: "suspensions" */
  insert_suspensions?: Maybe<ISuspensionsMutationResponse>;
  /** insert a single row into the table: "suspensions" */
  insert_suspensions_one?: Maybe<ISuspensions>;
  /** insert data into the table: "tmp_lessons" */
  insert_tmp_lessons?: Maybe<ITmpLessonsMutationResponse>;
  /** insert a single row into the table: "tmp_lessons" */
  insert_tmp_lessons_one?: Maybe<ITmpLessons>;
  /** insert data into the table: "tmp_progress" */
  insert_tmp_progress?: Maybe<ITmpProgressMutationResponse>;
  /** insert a single row into the table: "tmp_progress" */
  insert_tmp_progress_one?: Maybe<ITmpProgress>;
  /** insert data into the table: "unlockables" */
  insert_unlockables?: Maybe<IUnlockablesMutationResponse>;
  /** insert a single row into the table: "unlockables" */
  insert_unlockables_one?: Maybe<IUnlockables>;
  /** insert data into the table: "user_children_unlockables" */
  insert_user_children_unlockables?: Maybe<IUserChildrenUnlockablesMutationResponse>;
  /** insert a single row into the table: "user_children_unlockables" */
  insert_user_children_unlockables_one?: Maybe<IUserChildrenUnlockables>;
  /** insert data into the table: "user_lesson_parts_progresses" */
  insert_user_lesson_parts_progresses?: Maybe<IUserLessonPartsProgressesMutationResponse>;
  /** insert a single row into the table: "user_lesson_parts_progresses" */
  insert_user_lesson_parts_progresses_one?: Maybe<IUserLessonPartsProgresses>;
  /** insert data into the table: "user_lesson_progresses" */
  insert_user_lesson_progresses?: Maybe<IUserLessonProgressesMutationResponse>;
  /** insert a single row into the table: "user_lesson_progresses" */
  insert_user_lesson_progresses_one?: Maybe<IUserLessonProgresses>;
  /** insert data into the table: "user_qa_progresses" */
  insert_user_qa_progresses?: Maybe<IUserQaProgressesMutationResponse>;
  /** insert a single row into the table: "user_qa_progresses" */
  insert_user_qa_progresses_one?: Maybe<IUserQaProgresses>;
  /** insert data into the table: "user_third_parts_progresses" */
  insert_user_third_parts_progresses?: Maybe<IUserThirdPartsProgressesMutationResponse>;
  /** insert a single row into the table: "user_third_parts_progresses" */
  insert_user_third_parts_progresses_one?: Maybe<IUserThirdPartsProgresses>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<IUsersMutationResponse>;
  /** insert data into the table: "users_child_details" */
  insert_users_child_details?: Maybe<IUsersChildDetailsMutationResponse>;
  /** insert a single row into the table: "users_child_details" */
  insert_users_child_details_one?: Maybe<IUsersChildDetails>;
  /** insert data into the table: "users_children" */
  insert_users_children?: Maybe<IUsersChildrenMutationResponse>;
  /** insert a single row into the table: "users_children" */
  insert_users_children_one?: Maybe<IUsersChildren>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<IUsers>;
  /** insert data into the table: "users_sessions" */
  insert_users_sessions?: Maybe<IUsersSessionsMutationResponse>;
  /** insert a single row into the table: "users_sessions" */
  insert_users_sessions_one?: Maybe<IUsersSessions>;
  /** insert data into the table: "vacation_group_types" */
  insert_vacation_group_types?: Maybe<IVacationGroupTypesMutationResponse>;
  /** insert a single row into the table: "vacation_group_types" */
  insert_vacation_group_types_one?: Maybe<IVacationGroupTypes>;
  /** insert data into the table: "vacations" */
  insert_vacations?: Maybe<IVacationsMutationResponse>;
  /** insert a single row into the table: "vacations" */
  insert_vacations_one?: Maybe<IVacations>;
  /** insert data into the table: "weekly_facts" */
  insert_weekly_facts?: Maybe<IWeeklyFactsMutationResponse>;
  /** insert a single row into the table: "weekly_facts" */
  insert_weekly_facts_one?: Maybe<IWeeklyFacts>;
  /** insert data into the table: "wp_orders" */
  insert_wp_orders?: Maybe<IWpOrdersMutationResponse>;
  /** insert a single row into the table: "wp_orders" */
  insert_wp_orders_one?: Maybe<IWpOrders>;
  /** insert data into the table: "wp_products" */
  insert_wp_products?: Maybe<IWpProductsMutationResponse>;
  /** insert a single row into the table: "wp_products" */
  insert_wp_products_one?: Maybe<IWpProducts>;
  /** insert data into the table: "wp_subscription_orders" */
  insert_wp_subscription_orders?: Maybe<IWpSubscriptionOrdersMutationResponse>;
  /** insert a single row into the table: "wp_subscription_orders" */
  insert_wp_subscription_orders_one?: Maybe<IWpSubscriptionOrders>;
  /** update data of the table: "achievement_types" */
  update_achievement_types?: Maybe<IAchievementTypesMutationResponse>;
  /** update single row of the table: "achievement_types" */
  update_achievement_types_by_pk?: Maybe<IAchievementTypes>;
  /** update data of the table: "achievements" */
  update_achievements?: Maybe<IAchievementsMutationResponse>;
  /** update data of the table: "achievements_accomplished" */
  update_achievements_accomplished?: Maybe<IAchievementsAccomplishedMutationResponse>;
  /** update single row of the table: "achievements_accomplished" */
  update_achievements_accomplished_by_pk?: Maybe<IAchievementsAccomplished>;
  /** update single row of the table: "achievements" */
  update_achievements_by_pk?: Maybe<IAchievements>;
  /** update data of the table: "active_storage_attachments" */
  update_active_storage_attachments?: Maybe<IActiveStorageAttachmentsMutationResponse>;
  /** update single row of the table: "active_storage_attachments" */
  update_active_storage_attachments_by_pk?: Maybe<IActiveStorageAttachments>;
  /** update data of the table: "active_storage_blobs" */
  update_active_storage_blobs?: Maybe<IActiveStorageBlobsMutationResponse>;
  /** update single row of the table: "active_storage_blobs" */
  update_active_storage_blobs_by_pk?: Maybe<IActiveStorageBlobs>;
  /** update data of the table: "additional_lessons" */
  update_additional_lessons?: Maybe<IAdditionalLessonsMutationResponse>;
  /** update single row of the table: "additional_lessons" */
  update_additional_lessons_by_pk?: Maybe<IAdditionalLessons>;
  /** update data of the table: "additional_lessons_group_types" */
  update_additional_lessons_group_types?: Maybe<IAdditionalLessonsGroupTypesMutationResponse>;
  /** update single row of the table: "additional_lessons_group_types" */
  update_additional_lessons_group_types_by_pk?: Maybe<IAdditionalLessonsGroupTypes>;
  /** update data of the table: "ambassadors" */
  update_ambassadors?: Maybe<IAmbassadorsMutationResponse>;
  /** update single row of the table: "ambassadors" */
  update_ambassadors_by_pk?: Maybe<IAmbassadors>;
  /** update data of the table: "annual_schedules" */
  update_annual_schedules?: Maybe<IAnnualSchedulesMutationResponse>;
  /** update single row of the table: "annual_schedules" */
  update_annual_schedules_by_pk?: Maybe<IAnnualSchedules>;
  /** update data of the table: "answers" */
  update_answers?: Maybe<IAnswersMutationResponse>;
  /** update single row of the table: "answers" */
  update_answers_by_pk?: Maybe<IAnswers>;
  /** update data of the table: "course_groups" */
  update_course_groups?: Maybe<ICourseGroupsMutationResponse>;
  /** update single row of the table: "course_groups" */
  update_course_groups_by_pk?: Maybe<ICourseGroups>;
  /** update data of the table: "course_types" */
  update_course_types?: Maybe<ICourseTypesMutationResponse>;
  /** update single row of the table: "course_types" */
  update_course_types_by_pk?: Maybe<ICourseTypes>;
  /** update data of the table: "courses" */
  update_courses?: Maybe<ICoursesMutationResponse>;
  /** update single row of the table: "courses" */
  update_courses_by_pk?: Maybe<ICourses>;
  /** update data of the table: "dictionary_words" */
  update_dictionary_words?: Maybe<IDictionaryWordsMutationResponse>;
  /** update single row of the table: "dictionary_words" */
  update_dictionary_words_by_pk?: Maybe<IDictionaryWords>;
  /** update data of the table: "event_locations" */
  update_event_locations?: Maybe<IEventLocationsMutationResponse>;
  /** update single row of the table: "event_locations" */
  update_event_locations_by_pk?: Maybe<IEventLocations>;
  /** update data of the table: "event_registrations" */
  update_event_registrations?: Maybe<IEventRegistrationsMutationResponse>;
  /** update single row of the table: "event_registrations" */
  update_event_registrations_by_pk?: Maybe<IEventRegistrations>;
  /** update data of the table: "events" */
  update_events?: Maybe<IEventsMutationResponse>;
  /** update single row of the table: "events" */
  update_events_by_pk?: Maybe<IEvents>;
  /** update data of the table: "events_group_types" */
  update_events_group_types?: Maybe<IEventsGroupTypesMutationResponse>;
  /** update single row of the table: "events_group_types" */
  update_events_group_types_by_pk?: Maybe<IEventsGroupTypes>;
  /** update data of the table: "favorite_lesson_moments" */
  update_favorite_lesson_moments?: Maybe<IFavoriteLessonMomentsMutationResponse>;
  /** update single row of the table: "favorite_lesson_moments" */
  update_favorite_lesson_moments_by_pk?: Maybe<IFavoriteLessonMoments>;
  /** update data of the table: "final_test_questions" */
  update_final_test_questions?: Maybe<IFinalTestQuestionsMutationResponse>;
  /** update single row of the table: "final_test_questions" */
  update_final_test_questions_by_pk?: Maybe<IFinalTestQuestions>;
  /** update data of the table: "final_test_results" */
  update_final_test_results?: Maybe<IFinalTestResultsMutationResponse>;
  /** update single row of the table: "final_test_results" */
  update_final_test_results_by_pk?: Maybe<IFinalTestResults>;
  /** update data of the table: "friend_requests" */
  update_friend_requests?: Maybe<IFriendRequestsMutationResponse>;
  /** update single row of the table: "friend_requests" */
  update_friend_requests_by_pk?: Maybe<IFriendRequests>;
  /** update data of the table: "group_types" */
  update_group_types?: Maybe<IGroupTypesMutationResponse>;
  /** update single row of the table: "group_types" */
  update_group_types_by_pk?: Maybe<IGroupTypes>;
  /** update data of the table: "knowledge_test_questions" */
  update_knowledge_test_questions?: Maybe<IKnowledgeTestQuestionsMutationResponse>;
  /** update single row of the table: "knowledge_test_questions" */
  update_knowledge_test_questions_by_pk?: Maybe<IKnowledgeTestQuestions>;
  /** update data of the table: "knowledge_tests" */
  update_knowledge_tests?: Maybe<IKnowledgeTestsMutationResponse>;
  /** update single row of the table: "knowledge_tests" */
  update_knowledge_tests_by_pk?: Maybe<IKnowledgeTests>;
  /** update data of the table: "learning_assignments" */
  update_learning_assignments?: Maybe<ILearningAssignmentsMutationResponse>;
  /** update single row of the table: "learning_assignments" */
  update_learning_assignments_by_pk?: Maybe<ILearningAssignments>;
  /** update data of the table: "lesson_days" */
  update_lesson_days?: Maybe<ILessonDaysMutationResponse>;
  /** update single row of the table: "lesson_days" */
  update_lesson_days_by_pk?: Maybe<ILessonDays>;
  /** update data of the table: "lesson_messages" */
  update_lesson_messages?: Maybe<ILessonMessagesMutationResponse>;
  /** update single row of the table: "lesson_messages" */
  update_lesson_messages_by_pk?: Maybe<ILessonMessages>;
  /** update data of the table: "lesson_partitions" */
  update_lesson_partitions?: Maybe<ILessonPartitionsMutationResponse>;
  /** update single row of the table: "lesson_partitions" */
  update_lesson_partitions_by_pk?: Maybe<ILessonPartitions>;
  /** update data of the table: "lesson_reactions" */
  update_lesson_reactions?: Maybe<ILessonReactionsMutationResponse>;
  /** update single row of the table: "lesson_reactions" */
  update_lesson_reactions_by_pk?: Maybe<ILessonReactions>;
  /** update data of the table: "lesson_unlock_keys" */
  update_lesson_unlock_keys?: Maybe<ILessonUnlockKeysMutationResponse>;
  /** update single row of the table: "lesson_unlock_keys" */
  update_lesson_unlock_keys_by_pk?: Maybe<ILessonUnlockKeys>;
  /** update data of the table: "lesson_verses" */
  update_lesson_verses?: Maybe<ILessonVersesMutationResponse>;
  /** update single row of the table: "lesson_verses" */
  update_lesson_verses_by_pk?: Maybe<ILessonVerses>;
  /** update data of the table: "lesson_words" */
  update_lesson_words?: Maybe<ILessonWordsMutationResponse>;
  /** update single row of the table: "lesson_words" */
  update_lesson_words_by_pk?: Maybe<ILessonWords>;
  /** update data of the table: "lessons" */
  update_lessons?: Maybe<ILessonsMutationResponse>;
  /** update single row of the table: "lessons" */
  update_lessons_by_pk?: Maybe<ILessons>;
  /** update data of the table: "lessons_unlocked" */
  update_lessons_unlocked?: Maybe<ILessonsUnlockedMutationResponse>;
  /** update single row of the table: "lessons_unlocked" */
  update_lessons_unlocked_by_pk?: Maybe<ILessonsUnlocked>;
  /** update data of the table: "library_downloads" */
  update_library_downloads?: Maybe<ILibraryDownloadsMutationResponse>;
  /** update single row of the table: "library_downloads" */
  update_library_downloads_by_pk?: Maybe<ILibraryDownloads>;
  /** update data of the table: "message_types" */
  update_message_types?: Maybe<IMessageTypesMutationResponse>;
  /** update single row of the table: "message_types" */
  update_message_types_by_pk?: Maybe<IMessageTypes>;
  /** update data of the table: "messages" */
  update_messages?: Maybe<IMessagesMutationResponse>;
  /** update single row of the table: "messages" */
  update_messages_by_pk?: Maybe<IMessages>;
  /** update data of the table: "news_articles" */
  update_news_articles?: Maybe<INewsArticlesMutationResponse>;
  /** update single row of the table: "news_articles" */
  update_news_articles_by_pk?: Maybe<INewsArticles>;
  /** update data of the table: "news_articles_view" */
  update_news_articles_view?: Maybe<INewsArticlesViewMutationResponse>;
  /** update data of the table: "notification_group_types" */
  update_notification_group_types?: Maybe<INotificationGroupTypesMutationResponse>;
  /** update single row of the table: "notification_group_types" */
  update_notification_group_types_by_pk?: Maybe<INotificationGroupTypes>;
  /** update data of the table: "notifications" */
  update_notifications?: Maybe<INotificationsMutationResponse>;
  /** update single row of the table: "notifications" */
  update_notifications_by_pk?: Maybe<INotifications>;
  /** update data of the table: "personal_developments" */
  update_personal_developments?: Maybe<IPersonalDevelopmentsMutationResponse>;
  /** update single row of the table: "personal_developments" */
  update_personal_developments_by_pk?: Maybe<IPersonalDevelopments>;
  /** update data of the table: "qa_videos" */
  update_qa_videos?: Maybe<IQaVideosMutationResponse>;
  /** update single row of the table: "qa_videos" */
  update_qa_videos_by_pk?: Maybe<IQaVideos>;
  /** update data of the table: "que_jobs" */
  update_que_jobs?: Maybe<IQueJobsMutationResponse>;
  /** update single row of the table: "que_jobs" */
  update_que_jobs_by_pk?: Maybe<IQueJobs>;
  /** update data of the table: "questions" */
  update_questions?: Maybe<IQuestionsMutationResponse>;
  /** update single row of the table: "questions" */
  update_questions_by_pk?: Maybe<IQuestions>;
  /** update data of the table: "recitations" */
  update_recitations?: Maybe<IRecitationsMutationResponse>;
  /** update single row of the table: "recitations" */
  update_recitations_by_pk?: Maybe<IRecitations>;
  /** update data of the table: "repeat_lesson_progresses" */
  update_repeat_lesson_progresses?: Maybe<IRepeatLessonProgressesMutationResponse>;
  /** update single row of the table: "repeat_lesson_progresses" */
  update_repeat_lesson_progresses_by_pk?: Maybe<IRepeatLessonProgresses>;
  /** update data of the table: "repeat_second_parts_progresses" */
  update_repeat_second_parts_progresses?: Maybe<IRepeatSecondPartsProgressesMutationResponse>;
  /** update single row of the table: "repeat_second_parts_progresses" */
  update_repeat_second_parts_progresses_by_pk?: Maybe<IRepeatSecondPartsProgresses>;
  /** update data of the table: "repeat_third_parts_progresses" */
  update_repeat_third_parts_progresses?: Maybe<IRepeatThirdPartsProgressesMutationResponse>;
  /** update single row of the table: "repeat_third_parts_progresses" */
  update_repeat_third_parts_progresses_by_pk?: Maybe<IRepeatThirdPartsProgresses>;
  /** update data of the table: "schema_migrations" */
  update_schema_migrations?: Maybe<ISchemaMigrationsMutationResponse>;
  /** update single row of the table: "schema_migrations" */
  update_schema_migrations_by_pk?: Maybe<ISchemaMigrations>;
  /** update data of the table: "subscription_configs" */
  update_subscription_configs?: Maybe<ISubscriptionConfigsMutationResponse>;
  /** update single row of the table: "subscription_configs" */
  update_subscription_configs_by_pk?: Maybe<ISubscriptionConfigs>;
  /** update data of the table: "subscriptions" */
  update_subscriptions?: Maybe<ISubscriptionsMutationResponse>;
  /** update single row of the table: "subscriptions" */
  update_subscriptions_by_pk?: Maybe<ISubscriptions>;
  /** update data of the table: "support_topic_entries" */
  update_support_topic_entries?: Maybe<ISupportTopicEntriesMutationResponse>;
  /** update single row of the table: "support_topic_entries" */
  update_support_topic_entries_by_pk?: Maybe<ISupportTopicEntries>;
  /** update data of the table: "support_topics" */
  update_support_topics?: Maybe<ISupportTopicsMutationResponse>;
  /** update single row of the table: "support_topics" */
  update_support_topics_by_pk?: Maybe<ISupportTopics>;
  /** update data of the table: "suspensions" */
  update_suspensions?: Maybe<ISuspensionsMutationResponse>;
  /** update single row of the table: "suspensions" */
  update_suspensions_by_pk?: Maybe<ISuspensions>;
  /** update data of the table: "tmp_lessons" */
  update_tmp_lessons?: Maybe<ITmpLessonsMutationResponse>;
  /** update single row of the table: "tmp_lessons" */
  update_tmp_lessons_by_pk?: Maybe<ITmpLessons>;
  /** update data of the table: "tmp_progress" */
  update_tmp_progress?: Maybe<ITmpProgressMutationResponse>;
  /** update single row of the table: "tmp_progress" */
  update_tmp_progress_by_pk?: Maybe<ITmpProgress>;
  /** update data of the table: "unlockables" */
  update_unlockables?: Maybe<IUnlockablesMutationResponse>;
  /** update single row of the table: "unlockables" */
  update_unlockables_by_pk?: Maybe<IUnlockables>;
  /** update data of the table: "user_children_unlockables" */
  update_user_children_unlockables?: Maybe<IUserChildrenUnlockablesMutationResponse>;
  /** update single row of the table: "user_children_unlockables" */
  update_user_children_unlockables_by_pk?: Maybe<IUserChildrenUnlockables>;
  /** update data of the table: "user_lesson_parts_progresses" */
  update_user_lesson_parts_progresses?: Maybe<IUserLessonPartsProgressesMutationResponse>;
  /** update single row of the table: "user_lesson_parts_progresses" */
  update_user_lesson_parts_progresses_by_pk?: Maybe<IUserLessonPartsProgresses>;
  /** update data of the table: "user_lesson_progresses" */
  update_user_lesson_progresses?: Maybe<IUserLessonProgressesMutationResponse>;
  /** update single row of the table: "user_lesson_progresses" */
  update_user_lesson_progresses_by_pk?: Maybe<IUserLessonProgresses>;
  /** update data of the table: "user_qa_progresses" */
  update_user_qa_progresses?: Maybe<IUserQaProgressesMutationResponse>;
  /** update single row of the table: "user_qa_progresses" */
  update_user_qa_progresses_by_pk?: Maybe<IUserQaProgresses>;
  /** update data of the table: "user_third_parts_progresses" */
  update_user_third_parts_progresses?: Maybe<IUserThirdPartsProgressesMutationResponse>;
  /** update single row of the table: "user_third_parts_progresses" */
  update_user_third_parts_progresses_by_pk?: Maybe<IUserThirdPartsProgresses>;
  /** update data of the table: "users" */
  update_users?: Maybe<IUsersMutationResponse>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<IUsers>;
  /** update data of the table: "users_child_details" */
  update_users_child_details?: Maybe<IUsersChildDetailsMutationResponse>;
  /** update single row of the table: "users_child_details" */
  update_users_child_details_by_pk?: Maybe<IUsersChildDetails>;
  /** update data of the table: "users_children" */
  update_users_children?: Maybe<IUsersChildrenMutationResponse>;
  /** update single row of the table: "users_children" */
  update_users_children_by_pk?: Maybe<IUsersChildren>;
  /** update data of the table: "users_sessions" */
  update_users_sessions?: Maybe<IUsersSessionsMutationResponse>;
  /** update single row of the table: "users_sessions" */
  update_users_sessions_by_pk?: Maybe<IUsersSessions>;
  /** update data of the table: "vacation_group_types" */
  update_vacation_group_types?: Maybe<IVacationGroupTypesMutationResponse>;
  /** update single row of the table: "vacation_group_types" */
  update_vacation_group_types_by_pk?: Maybe<IVacationGroupTypes>;
  /** update data of the table: "vacations" */
  update_vacations?: Maybe<IVacationsMutationResponse>;
  /** update single row of the table: "vacations" */
  update_vacations_by_pk?: Maybe<IVacations>;
  /** update data of the table: "weekly_facts" */
  update_weekly_facts?: Maybe<IWeeklyFactsMutationResponse>;
  /** update single row of the table: "weekly_facts" */
  update_weekly_facts_by_pk?: Maybe<IWeeklyFacts>;
  /** update data of the table: "wp_orders" */
  update_wp_orders?: Maybe<IWpOrdersMutationResponse>;
  /** update single row of the table: "wp_orders" */
  update_wp_orders_by_pk?: Maybe<IWpOrders>;
  /** update data of the table: "wp_products" */
  update_wp_products?: Maybe<IWpProductsMutationResponse>;
  /** update single row of the table: "wp_products" */
  update_wp_products_by_pk?: Maybe<IWpProducts>;
  /** update data of the table: "wp_subscription_orders" */
  update_wp_subscription_orders?: Maybe<IWpSubscriptionOrdersMutationResponse>;
  /** update single row of the table: "wp_subscription_orders" */
  update_wp_subscription_orders_by_pk?: Maybe<IWpSubscriptionOrders>;
}


/** mutation root */
export interface IMutationRootDeleteAchievementTypesArgs {
  where: IAchievementTypesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteAchievementTypesByPkArgs {
  name: Scalars['String'];
}


/** mutation root */
export interface IMutationRootDeleteAchievementsArgs {
  where: IAchievementsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteAchievementsAccomplishedArgs {
  where: IAchievementsAccomplishedBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteAchievementsAccomplishedByPkArgs {
  achievement_id: Scalars['uuid'];
  user_child_id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteAchievementsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteActiveStorageAttachmentsArgs {
  where: IActiveStorageAttachmentsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteActiveStorageAttachmentsByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteActiveStorageBlobsArgs {
  where: IActiveStorageBlobsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteActiveStorageBlobsByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteAdditionalLessonsArgs {
  where: IAdditionalLessonsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteAdditionalLessonsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteAdditionalLessonsGroupTypesArgs {
  where: IAdditionalLessonsGroupTypesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteAdditionalLessonsGroupTypesByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteAmbassadorsArgs {
  where: IAmbassadorsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteAmbassadorsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteAnnualSchedulesArgs {
  where: IAnnualSchedulesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteAnnualSchedulesByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteAnswersArgs {
  where: IAnswersBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteAnswersByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteCourseGroupsArgs {
  where: ICourseGroupsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteCourseGroupsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteCourseTypesArgs {
  where: ICourseTypesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteCourseTypesByPkArgs {
  name: Scalars['String'];
}


/** mutation root */
export interface IMutationRootDeleteCoursesArgs {
  where: ICoursesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteCoursesByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteDictionaryWordsArgs {
  where: IDictionaryWordsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteDictionaryWordsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteEventLocationsArgs {
  where: IEventLocationsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteEventLocationsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteEventRegistrationsArgs {
  where: IEventRegistrationsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteEventRegistrationsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteEventsArgs {
  where: IEventsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteEventsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteEventsGroupTypesArgs {
  where: IEventsGroupTypesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteEventsGroupTypesByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteFavoriteLessonMomentsArgs {
  where: IFavoriteLessonMomentsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteFavoriteLessonMomentsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteFinalTestQuestionsArgs {
  where: IFinalTestQuestionsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteFinalTestQuestionsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteFinalTestResultsArgs {
  where: IFinalTestResultsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteFinalTestResultsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteFriendRequestsArgs {
  where: IFriendRequestsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteFriendRequestsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteGroupTypesArgs {
  where: IGroupTypesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteGroupTypesByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteKnowledgeTestQuestionsArgs {
  where: IKnowledgeTestQuestionsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteKnowledgeTestQuestionsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteKnowledgeTestsArgs {
  where: IKnowledgeTestsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteKnowledgeTestsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteLearningAssignmentsArgs {
  where: ILearningAssignmentsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteLearningAssignmentsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteLessonDaysArgs {
  where: ILessonDaysBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteLessonDaysByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteLessonMessagesArgs {
  where: ILessonMessagesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteLessonMessagesByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteLessonPartitionsArgs {
  where: ILessonPartitionsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteLessonPartitionsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteLessonReactionsArgs {
  where: ILessonReactionsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteLessonReactionsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteLessonUnlockKeysArgs {
  where: ILessonUnlockKeysBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteLessonUnlockKeysByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteLessonVersesArgs {
  where: ILessonVersesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteLessonVersesByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteLessonWordsArgs {
  where: ILessonWordsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteLessonWordsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteLessonsArgs {
  where: ILessonsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteLessonsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteLessonsUnlockedArgs {
  where: ILessonsUnlockedBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteLessonsUnlockedByPkArgs {
  lesson_id: Scalars['uuid'];
  user_child_id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteLibraryDownloadsArgs {
  where: ILibraryDownloadsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteLibraryDownloadsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteMessageTypesArgs {
  where: IMessageTypesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteMessageTypesByPkArgs {
  name: Scalars['String'];
}


/** mutation root */
export interface IMutationRootDeleteMessagesArgs {
  where: IMessagesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteMessagesByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteNewsArticlesArgs {
  where: INewsArticlesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteNewsArticlesByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteNewsArticlesViewArgs {
  where: INewsArticlesViewBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteNotificationGroupTypesArgs {
  where: INotificationGroupTypesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteNotificationGroupTypesByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteNotificationsArgs {
  where: INotificationsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteNotificationsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeletePersonalDevelopmentsArgs {
  where: IPersonalDevelopmentsBoolExp;
}


/** mutation root */
export interface IMutationRootDeletePersonalDevelopmentsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteQaVideosArgs {
  where: IQaVideosBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteQaVideosByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteQueJobsArgs {
  where: IQueJobsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteQueJobsByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteQuestionsArgs {
  where: IQuestionsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteQuestionsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteRecitationsArgs {
  where: IRecitationsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteRecitationsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteRepeatLessonProgressesArgs {
  where: IRepeatLessonProgressesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteRepeatLessonProgressesByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteRepeatSecondPartsProgressesArgs {
  where: IRepeatSecondPartsProgressesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteRepeatSecondPartsProgressesByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteRepeatThirdPartsProgressesArgs {
  where: IRepeatThirdPartsProgressesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteRepeatThirdPartsProgressesByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteSchemaMigrationsArgs {
  where: ISchemaMigrationsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteSchemaMigrationsByPkArgs {
  version: Scalars['String'];
}


/** mutation root */
export interface IMutationRootDeleteSubscriptionConfigsArgs {
  where: ISubscriptionConfigsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteSubscriptionConfigsByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteSubscriptionsArgs {
  where: ISubscriptionsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteSubscriptionsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteSupportTopicEntriesArgs {
  where: ISupportTopicEntriesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteSupportTopicEntriesByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteSupportTopicsArgs {
  where: ISupportTopicsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteSupportTopicsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteSuspensionsArgs {
  where: ISuspensionsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteSuspensionsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteTmpLessonsArgs {
  where: ITmpLessonsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteTmpLessonsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteTmpProgressArgs {
  where: ITmpProgressBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteTmpProgressByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteUnlockablesArgs {
  where: IUnlockablesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteUnlockablesByPkArgs {
  type: Scalars['String'];
}


/** mutation root */
export interface IMutationRootDeleteUserChildrenUnlockablesArgs {
  where: IUserChildrenUnlockablesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteUserChildrenUnlockablesByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteUserLessonPartsProgressesArgs {
  where: IUserLessonPartsProgressesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteUserLessonPartsProgressesByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteUserLessonProgressesArgs {
  where: IUserLessonProgressesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteUserLessonProgressesByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteUserQaProgressesArgs {
  where: IUserQaProgressesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteUserQaProgressesByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteUserThirdPartsProgressesArgs {
  where: IUserThirdPartsProgressesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteUserThirdPartsProgressesByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteUsersArgs {
  where: IUsersBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteUsersByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteUsersChildDetailsArgs {
  where: IUsersChildDetailsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteUsersChildDetailsByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteUsersChildrenArgs {
  where: IUsersChildrenBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteUsersChildrenByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteUsersSessionsArgs {
  where: IUsersSessionsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteUsersSessionsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteVacationGroupTypesArgs {
  where: IVacationGroupTypesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteVacationGroupTypesByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteVacationsArgs {
  where: IVacationsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteVacationsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteWeeklyFactsArgs {
  where: IWeeklyFactsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteWeeklyFactsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteWpOrdersArgs {
  where: IWpOrdersBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteWpOrdersByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteWpProductsArgs {
  where: IWpProductsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteWpProductsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteWpSubscriptionOrdersArgs {
  where: IWpSubscriptionOrdersBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteWpSubscriptionOrdersByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootInsertAchievementTypesArgs {
  objects: Array<IAchievementTypesInsertInput>;
  on_conflict?: InputMaybe<IAchievementTypesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertAchievementTypesOneArgs {
  object: IAchievementTypesInsertInput;
  on_conflict?: InputMaybe<IAchievementTypesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertAchievementsArgs {
  objects: Array<IAchievementsInsertInput>;
  on_conflict?: InputMaybe<IAchievementsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertAchievementsAccomplishedArgs {
  objects: Array<IAchievementsAccomplishedInsertInput>;
  on_conflict?: InputMaybe<IAchievementsAccomplishedOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertAchievementsAccomplishedOneArgs {
  object: IAchievementsAccomplishedInsertInput;
  on_conflict?: InputMaybe<IAchievementsAccomplishedOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertAchievementsOneArgs {
  object: IAchievementsInsertInput;
  on_conflict?: InputMaybe<IAchievementsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertActiveStorageAttachmentsArgs {
  objects: Array<IActiveStorageAttachmentsInsertInput>;
  on_conflict?: InputMaybe<IActiveStorageAttachmentsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertActiveStorageAttachmentsOneArgs {
  object: IActiveStorageAttachmentsInsertInput;
  on_conflict?: InputMaybe<IActiveStorageAttachmentsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertActiveStorageBlobsArgs {
  objects: Array<IActiveStorageBlobsInsertInput>;
  on_conflict?: InputMaybe<IActiveStorageBlobsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertActiveStorageBlobsOneArgs {
  object: IActiveStorageBlobsInsertInput;
  on_conflict?: InputMaybe<IActiveStorageBlobsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertAdditionalLessonsArgs {
  objects: Array<IAdditionalLessonsInsertInput>;
  on_conflict?: InputMaybe<IAdditionalLessonsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertAdditionalLessonsGroupTypesArgs {
  objects: Array<IAdditionalLessonsGroupTypesInsertInput>;
  on_conflict?: InputMaybe<IAdditionalLessonsGroupTypesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertAdditionalLessonsGroupTypesOneArgs {
  object: IAdditionalLessonsGroupTypesInsertInput;
  on_conflict?: InputMaybe<IAdditionalLessonsGroupTypesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertAdditionalLessonsOneArgs {
  object: IAdditionalLessonsInsertInput;
  on_conflict?: InputMaybe<IAdditionalLessonsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertAmbassadorsArgs {
  objects: Array<IAmbassadorsInsertInput>;
  on_conflict?: InputMaybe<IAmbassadorsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertAmbassadorsOneArgs {
  object: IAmbassadorsInsertInput;
  on_conflict?: InputMaybe<IAmbassadorsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertAnnualSchedulesArgs {
  objects: Array<IAnnualSchedulesInsertInput>;
  on_conflict?: InputMaybe<IAnnualSchedulesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertAnnualSchedulesOneArgs {
  object: IAnnualSchedulesInsertInput;
  on_conflict?: InputMaybe<IAnnualSchedulesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertAnswersArgs {
  objects: Array<IAnswersInsertInput>;
  on_conflict?: InputMaybe<IAnswersOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertAnswersOneArgs {
  object: IAnswersInsertInput;
  on_conflict?: InputMaybe<IAnswersOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertCourseGroupsArgs {
  objects: Array<ICourseGroupsInsertInput>;
  on_conflict?: InputMaybe<ICourseGroupsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertCourseGroupsOneArgs {
  object: ICourseGroupsInsertInput;
  on_conflict?: InputMaybe<ICourseGroupsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertCourseTypesArgs {
  objects: Array<ICourseTypesInsertInput>;
  on_conflict?: InputMaybe<ICourseTypesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertCourseTypesOneArgs {
  object: ICourseTypesInsertInput;
  on_conflict?: InputMaybe<ICourseTypesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertCoursesArgs {
  objects: Array<ICoursesInsertInput>;
  on_conflict?: InputMaybe<ICoursesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertCoursesOneArgs {
  object: ICoursesInsertInput;
  on_conflict?: InputMaybe<ICoursesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertDictionaryWordsArgs {
  objects: Array<IDictionaryWordsInsertInput>;
  on_conflict?: InputMaybe<IDictionaryWordsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertDictionaryWordsOneArgs {
  object: IDictionaryWordsInsertInput;
  on_conflict?: InputMaybe<IDictionaryWordsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertEventLocationsArgs {
  objects: Array<IEventLocationsInsertInput>;
  on_conflict?: InputMaybe<IEventLocationsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertEventLocationsOneArgs {
  object: IEventLocationsInsertInput;
  on_conflict?: InputMaybe<IEventLocationsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertEventRegistrationsArgs {
  objects: Array<IEventRegistrationsInsertInput>;
  on_conflict?: InputMaybe<IEventRegistrationsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertEventRegistrationsOneArgs {
  object: IEventRegistrationsInsertInput;
  on_conflict?: InputMaybe<IEventRegistrationsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertEventsArgs {
  objects: Array<IEventsInsertInput>;
  on_conflict?: InputMaybe<IEventsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertEventsGroupTypesArgs {
  objects: Array<IEventsGroupTypesInsertInput>;
  on_conflict?: InputMaybe<IEventsGroupTypesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertEventsGroupTypesOneArgs {
  object: IEventsGroupTypesInsertInput;
  on_conflict?: InputMaybe<IEventsGroupTypesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertEventsOneArgs {
  object: IEventsInsertInput;
  on_conflict?: InputMaybe<IEventsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertFavoriteLessonMomentsArgs {
  objects: Array<IFavoriteLessonMomentsInsertInput>;
  on_conflict?: InputMaybe<IFavoriteLessonMomentsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertFavoriteLessonMomentsOneArgs {
  object: IFavoriteLessonMomentsInsertInput;
  on_conflict?: InputMaybe<IFavoriteLessonMomentsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertFinalTestQuestionsArgs {
  objects: Array<IFinalTestQuestionsInsertInput>;
  on_conflict?: InputMaybe<IFinalTestQuestionsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertFinalTestQuestionsOneArgs {
  object: IFinalTestQuestionsInsertInput;
  on_conflict?: InputMaybe<IFinalTestQuestionsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertFinalTestResultsArgs {
  objects: Array<IFinalTestResultsInsertInput>;
  on_conflict?: InputMaybe<IFinalTestResultsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertFinalTestResultsOneArgs {
  object: IFinalTestResultsInsertInput;
  on_conflict?: InputMaybe<IFinalTestResultsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertFriendRequestsArgs {
  objects: Array<IFriendRequestsInsertInput>;
  on_conflict?: InputMaybe<IFriendRequestsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertFriendRequestsOneArgs {
  object: IFriendRequestsInsertInput;
  on_conflict?: InputMaybe<IFriendRequestsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertGroupTypesArgs {
  objects: Array<IGroupTypesInsertInput>;
  on_conflict?: InputMaybe<IGroupTypesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertGroupTypesOneArgs {
  object: IGroupTypesInsertInput;
  on_conflict?: InputMaybe<IGroupTypesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertKnowledgeTestQuestionsArgs {
  objects: Array<IKnowledgeTestQuestionsInsertInput>;
  on_conflict?: InputMaybe<IKnowledgeTestQuestionsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertKnowledgeTestQuestionsOneArgs {
  object: IKnowledgeTestQuestionsInsertInput;
  on_conflict?: InputMaybe<IKnowledgeTestQuestionsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertKnowledgeTestsArgs {
  objects: Array<IKnowledgeTestsInsertInput>;
  on_conflict?: InputMaybe<IKnowledgeTestsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertKnowledgeTestsOneArgs {
  object: IKnowledgeTestsInsertInput;
  on_conflict?: InputMaybe<IKnowledgeTestsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertLearningAssignmentsArgs {
  objects: Array<ILearningAssignmentsInsertInput>;
  on_conflict?: InputMaybe<ILearningAssignmentsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertLearningAssignmentsOneArgs {
  object: ILearningAssignmentsInsertInput;
  on_conflict?: InputMaybe<ILearningAssignmentsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertLessonDaysArgs {
  objects: Array<ILessonDaysInsertInput>;
  on_conflict?: InputMaybe<ILessonDaysOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertLessonDaysOneArgs {
  object: ILessonDaysInsertInput;
  on_conflict?: InputMaybe<ILessonDaysOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertLessonMessagesArgs {
  objects: Array<ILessonMessagesInsertInput>;
  on_conflict?: InputMaybe<ILessonMessagesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertLessonMessagesOneArgs {
  object: ILessonMessagesInsertInput;
  on_conflict?: InputMaybe<ILessonMessagesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertLessonPartitionsArgs {
  objects: Array<ILessonPartitionsInsertInput>;
  on_conflict?: InputMaybe<ILessonPartitionsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertLessonPartitionsOneArgs {
  object: ILessonPartitionsInsertInput;
  on_conflict?: InputMaybe<ILessonPartitionsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertLessonReactionsArgs {
  objects: Array<ILessonReactionsInsertInput>;
  on_conflict?: InputMaybe<ILessonReactionsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertLessonReactionsOneArgs {
  object: ILessonReactionsInsertInput;
  on_conflict?: InputMaybe<ILessonReactionsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertLessonUnlockKeysArgs {
  objects: Array<ILessonUnlockKeysInsertInput>;
  on_conflict?: InputMaybe<ILessonUnlockKeysOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertLessonUnlockKeysOneArgs {
  object: ILessonUnlockKeysInsertInput;
  on_conflict?: InputMaybe<ILessonUnlockKeysOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertLessonVersesArgs {
  objects: Array<ILessonVersesInsertInput>;
  on_conflict?: InputMaybe<ILessonVersesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertLessonVersesOneArgs {
  object: ILessonVersesInsertInput;
  on_conflict?: InputMaybe<ILessonVersesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertLessonWordsArgs {
  objects: Array<ILessonWordsInsertInput>;
  on_conflict?: InputMaybe<ILessonWordsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertLessonWordsOneArgs {
  object: ILessonWordsInsertInput;
  on_conflict?: InputMaybe<ILessonWordsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertLessonsArgs {
  objects: Array<ILessonsInsertInput>;
  on_conflict?: InputMaybe<ILessonsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertLessonsOneArgs {
  object: ILessonsInsertInput;
  on_conflict?: InputMaybe<ILessonsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertLessonsUnlockedArgs {
  objects: Array<ILessonsUnlockedInsertInput>;
  on_conflict?: InputMaybe<ILessonsUnlockedOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertLessonsUnlockedOneArgs {
  object: ILessonsUnlockedInsertInput;
  on_conflict?: InputMaybe<ILessonsUnlockedOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertLibraryDownloadsArgs {
  objects: Array<ILibraryDownloadsInsertInput>;
  on_conflict?: InputMaybe<ILibraryDownloadsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertLibraryDownloadsOneArgs {
  object: ILibraryDownloadsInsertInput;
  on_conflict?: InputMaybe<ILibraryDownloadsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertMessageTypesArgs {
  objects: Array<IMessageTypesInsertInput>;
  on_conflict?: InputMaybe<IMessageTypesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertMessageTypesOneArgs {
  object: IMessageTypesInsertInput;
  on_conflict?: InputMaybe<IMessageTypesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertMessagesArgs {
  objects: Array<IMessagesInsertInput>;
  on_conflict?: InputMaybe<IMessagesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertMessagesOneArgs {
  object: IMessagesInsertInput;
  on_conflict?: InputMaybe<IMessagesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertNewsArticlesArgs {
  objects: Array<INewsArticlesInsertInput>;
  on_conflict?: InputMaybe<INewsArticlesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertNewsArticlesOneArgs {
  object: INewsArticlesInsertInput;
  on_conflict?: InputMaybe<INewsArticlesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertNewsArticlesViewArgs {
  objects: Array<INewsArticlesViewInsertInput>;
}


/** mutation root */
export interface IMutationRootInsertNewsArticlesViewOneArgs {
  object: INewsArticlesViewInsertInput;
}


/** mutation root */
export interface IMutationRootInsertNotificationGroupTypesArgs {
  objects: Array<INotificationGroupTypesInsertInput>;
  on_conflict?: InputMaybe<INotificationGroupTypesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertNotificationGroupTypesOneArgs {
  object: INotificationGroupTypesInsertInput;
  on_conflict?: InputMaybe<INotificationGroupTypesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertNotificationsArgs {
  objects: Array<INotificationsInsertInput>;
  on_conflict?: InputMaybe<INotificationsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertNotificationsOneArgs {
  object: INotificationsInsertInput;
  on_conflict?: InputMaybe<INotificationsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertPersonalDevelopmentsArgs {
  objects: Array<IPersonalDevelopmentsInsertInput>;
  on_conflict?: InputMaybe<IPersonalDevelopmentsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertPersonalDevelopmentsOneArgs {
  object: IPersonalDevelopmentsInsertInput;
  on_conflict?: InputMaybe<IPersonalDevelopmentsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertQaVideosArgs {
  objects: Array<IQaVideosInsertInput>;
  on_conflict?: InputMaybe<IQaVideosOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertQaVideosOneArgs {
  object: IQaVideosInsertInput;
  on_conflict?: InputMaybe<IQaVideosOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertQueJobsArgs {
  objects: Array<IQueJobsInsertInput>;
  on_conflict?: InputMaybe<IQueJobsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertQueJobsOneArgs {
  object: IQueJobsInsertInput;
  on_conflict?: InputMaybe<IQueJobsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertQuestionsArgs {
  objects: Array<IQuestionsInsertInput>;
  on_conflict?: InputMaybe<IQuestionsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertQuestionsOneArgs {
  object: IQuestionsInsertInput;
  on_conflict?: InputMaybe<IQuestionsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertRecitationsArgs {
  objects: Array<IRecitationsInsertInput>;
  on_conflict?: InputMaybe<IRecitationsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertRecitationsOneArgs {
  object: IRecitationsInsertInput;
  on_conflict?: InputMaybe<IRecitationsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertRepeatLessonProgressesArgs {
  objects: Array<IRepeatLessonProgressesInsertInput>;
  on_conflict?: InputMaybe<IRepeatLessonProgressesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertRepeatLessonProgressesOneArgs {
  object: IRepeatLessonProgressesInsertInput;
  on_conflict?: InputMaybe<IRepeatLessonProgressesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertRepeatSecondPartsProgressesArgs {
  objects: Array<IRepeatSecondPartsProgressesInsertInput>;
  on_conflict?: InputMaybe<IRepeatSecondPartsProgressesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertRepeatSecondPartsProgressesOneArgs {
  object: IRepeatSecondPartsProgressesInsertInput;
  on_conflict?: InputMaybe<IRepeatSecondPartsProgressesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertRepeatThirdPartsProgressesArgs {
  objects: Array<IRepeatThirdPartsProgressesInsertInput>;
  on_conflict?: InputMaybe<IRepeatThirdPartsProgressesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertRepeatThirdPartsProgressesOneArgs {
  object: IRepeatThirdPartsProgressesInsertInput;
  on_conflict?: InputMaybe<IRepeatThirdPartsProgressesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertSchemaMigrationsArgs {
  objects: Array<ISchemaMigrationsInsertInput>;
  on_conflict?: InputMaybe<ISchemaMigrationsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertSchemaMigrationsOneArgs {
  object: ISchemaMigrationsInsertInput;
  on_conflict?: InputMaybe<ISchemaMigrationsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertSubscriptionConfigsArgs {
  objects: Array<ISubscriptionConfigsInsertInput>;
  on_conflict?: InputMaybe<ISubscriptionConfigsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertSubscriptionConfigsOneArgs {
  object: ISubscriptionConfigsInsertInput;
  on_conflict?: InputMaybe<ISubscriptionConfigsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertSubscriptionsArgs {
  objects: Array<ISubscriptionsInsertInput>;
  on_conflict?: InputMaybe<ISubscriptionsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertSubscriptionsOneArgs {
  object: ISubscriptionsInsertInput;
  on_conflict?: InputMaybe<ISubscriptionsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertSupportTopicEntriesArgs {
  objects: Array<ISupportTopicEntriesInsertInput>;
  on_conflict?: InputMaybe<ISupportTopicEntriesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertSupportTopicEntriesOneArgs {
  object: ISupportTopicEntriesInsertInput;
  on_conflict?: InputMaybe<ISupportTopicEntriesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertSupportTopicsArgs {
  objects: Array<ISupportTopicsInsertInput>;
  on_conflict?: InputMaybe<ISupportTopicsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertSupportTopicsOneArgs {
  object: ISupportTopicsInsertInput;
  on_conflict?: InputMaybe<ISupportTopicsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertSuspensionsArgs {
  objects: Array<ISuspensionsInsertInput>;
  on_conflict?: InputMaybe<ISuspensionsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertSuspensionsOneArgs {
  object: ISuspensionsInsertInput;
  on_conflict?: InputMaybe<ISuspensionsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertTmpLessonsArgs {
  objects: Array<ITmpLessonsInsertInput>;
  on_conflict?: InputMaybe<ITmpLessonsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertTmpLessonsOneArgs {
  object: ITmpLessonsInsertInput;
  on_conflict?: InputMaybe<ITmpLessonsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertTmpProgressArgs {
  objects: Array<ITmpProgressInsertInput>;
  on_conflict?: InputMaybe<ITmpProgressOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertTmpProgressOneArgs {
  object: ITmpProgressInsertInput;
  on_conflict?: InputMaybe<ITmpProgressOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertUnlockablesArgs {
  objects: Array<IUnlockablesInsertInput>;
  on_conflict?: InputMaybe<IUnlockablesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertUnlockablesOneArgs {
  object: IUnlockablesInsertInput;
  on_conflict?: InputMaybe<IUnlockablesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertUserChildrenUnlockablesArgs {
  objects: Array<IUserChildrenUnlockablesInsertInput>;
  on_conflict?: InputMaybe<IUserChildrenUnlockablesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertUserChildrenUnlockablesOneArgs {
  object: IUserChildrenUnlockablesInsertInput;
  on_conflict?: InputMaybe<IUserChildrenUnlockablesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertUserLessonPartsProgressesArgs {
  objects: Array<IUserLessonPartsProgressesInsertInput>;
  on_conflict?: InputMaybe<IUserLessonPartsProgressesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertUserLessonPartsProgressesOneArgs {
  object: IUserLessonPartsProgressesInsertInput;
  on_conflict?: InputMaybe<IUserLessonPartsProgressesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertUserLessonProgressesArgs {
  objects: Array<IUserLessonProgressesInsertInput>;
  on_conflict?: InputMaybe<IUserLessonProgressesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertUserLessonProgressesOneArgs {
  object: IUserLessonProgressesInsertInput;
  on_conflict?: InputMaybe<IUserLessonProgressesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertUserQaProgressesArgs {
  objects: Array<IUserQaProgressesInsertInput>;
  on_conflict?: InputMaybe<IUserQaProgressesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertUserQaProgressesOneArgs {
  object: IUserQaProgressesInsertInput;
  on_conflict?: InputMaybe<IUserQaProgressesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertUserThirdPartsProgressesArgs {
  objects: Array<IUserThirdPartsProgressesInsertInput>;
  on_conflict?: InputMaybe<IUserThirdPartsProgressesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertUserThirdPartsProgressesOneArgs {
  object: IUserThirdPartsProgressesInsertInput;
  on_conflict?: InputMaybe<IUserThirdPartsProgressesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertUsersArgs {
  objects: Array<IUsersInsertInput>;
  on_conflict?: InputMaybe<IUsersOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertUsersChildDetailsArgs {
  objects: Array<IUsersChildDetailsInsertInput>;
  on_conflict?: InputMaybe<IUsersChildDetailsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertUsersChildDetailsOneArgs {
  object: IUsersChildDetailsInsertInput;
  on_conflict?: InputMaybe<IUsersChildDetailsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertUsersChildrenArgs {
  objects: Array<IUsersChildrenInsertInput>;
  on_conflict?: InputMaybe<IUsersChildrenOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertUsersChildrenOneArgs {
  object: IUsersChildrenInsertInput;
  on_conflict?: InputMaybe<IUsersChildrenOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertUsersOneArgs {
  object: IUsersInsertInput;
  on_conflict?: InputMaybe<IUsersOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertUsersSessionsArgs {
  objects: Array<IUsersSessionsInsertInput>;
  on_conflict?: InputMaybe<IUsersSessionsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertUsersSessionsOneArgs {
  object: IUsersSessionsInsertInput;
  on_conflict?: InputMaybe<IUsersSessionsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertVacationGroupTypesArgs {
  objects: Array<IVacationGroupTypesInsertInput>;
  on_conflict?: InputMaybe<IVacationGroupTypesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertVacationGroupTypesOneArgs {
  object: IVacationGroupTypesInsertInput;
  on_conflict?: InputMaybe<IVacationGroupTypesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertVacationsArgs {
  objects: Array<IVacationsInsertInput>;
  on_conflict?: InputMaybe<IVacationsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertVacationsOneArgs {
  object: IVacationsInsertInput;
  on_conflict?: InputMaybe<IVacationsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertWeeklyFactsArgs {
  objects: Array<IWeeklyFactsInsertInput>;
  on_conflict?: InputMaybe<IWeeklyFactsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertWeeklyFactsOneArgs {
  object: IWeeklyFactsInsertInput;
  on_conflict?: InputMaybe<IWeeklyFactsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertWpOrdersArgs {
  objects: Array<IWpOrdersInsertInput>;
  on_conflict?: InputMaybe<IWpOrdersOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertWpOrdersOneArgs {
  object: IWpOrdersInsertInput;
  on_conflict?: InputMaybe<IWpOrdersOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertWpProductsArgs {
  objects: Array<IWpProductsInsertInput>;
  on_conflict?: InputMaybe<IWpProductsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertWpProductsOneArgs {
  object: IWpProductsInsertInput;
  on_conflict?: InputMaybe<IWpProductsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertWpSubscriptionOrdersArgs {
  objects: Array<IWpSubscriptionOrdersInsertInput>;
  on_conflict?: InputMaybe<IWpSubscriptionOrdersOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertWpSubscriptionOrdersOneArgs {
  object: IWpSubscriptionOrdersInsertInput;
  on_conflict?: InputMaybe<IWpSubscriptionOrdersOnConflict>;
}


/** mutation root */
export interface IMutationRootUpdateAchievementTypesArgs {
  _set?: InputMaybe<IAchievementTypesSetInput>;
  where: IAchievementTypesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateAchievementTypesByPkArgs {
  _set?: InputMaybe<IAchievementTypesSetInput>;
  pk_columns: IAchievementTypesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateAchievementsArgs {
  _inc?: InputMaybe<IAchievementsIncInput>;
  _set?: InputMaybe<IAchievementsSetInput>;
  where: IAchievementsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateAchievementsAccomplishedArgs {
  _set?: InputMaybe<IAchievementsAccomplishedSetInput>;
  where: IAchievementsAccomplishedBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateAchievementsAccomplishedByPkArgs {
  _set?: InputMaybe<IAchievementsAccomplishedSetInput>;
  pk_columns: IAchievementsAccomplishedPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateAchievementsByPkArgs {
  _inc?: InputMaybe<IAchievementsIncInput>;
  _set?: InputMaybe<IAchievementsSetInput>;
  pk_columns: IAchievementsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateActiveStorageAttachmentsArgs {
  _inc?: InputMaybe<IActiveStorageAttachmentsIncInput>;
  _set?: InputMaybe<IActiveStorageAttachmentsSetInput>;
  where: IActiveStorageAttachmentsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateActiveStorageAttachmentsByPkArgs {
  _inc?: InputMaybe<IActiveStorageAttachmentsIncInput>;
  _set?: InputMaybe<IActiveStorageAttachmentsSetInput>;
  pk_columns: IActiveStorageAttachmentsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateActiveStorageBlobsArgs {
  _inc?: InputMaybe<IActiveStorageBlobsIncInput>;
  _set?: InputMaybe<IActiveStorageBlobsSetInput>;
  where: IActiveStorageBlobsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateActiveStorageBlobsByPkArgs {
  _inc?: InputMaybe<IActiveStorageBlobsIncInput>;
  _set?: InputMaybe<IActiveStorageBlobsSetInput>;
  pk_columns: IActiveStorageBlobsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateAdditionalLessonsArgs {
  _inc?: InputMaybe<IAdditionalLessonsIncInput>;
  _set?: InputMaybe<IAdditionalLessonsSetInput>;
  where: IAdditionalLessonsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateAdditionalLessonsByPkArgs {
  _inc?: InputMaybe<IAdditionalLessonsIncInput>;
  _set?: InputMaybe<IAdditionalLessonsSetInput>;
  pk_columns: IAdditionalLessonsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateAdditionalLessonsGroupTypesArgs {
  _set?: InputMaybe<IAdditionalLessonsGroupTypesSetInput>;
  where: IAdditionalLessonsGroupTypesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateAdditionalLessonsGroupTypesByPkArgs {
  _set?: InputMaybe<IAdditionalLessonsGroupTypesSetInput>;
  pk_columns: IAdditionalLessonsGroupTypesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateAmbassadorsArgs {
  _inc?: InputMaybe<IAmbassadorsIncInput>;
  _set?: InputMaybe<IAmbassadorsSetInput>;
  where: IAmbassadorsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateAmbassadorsByPkArgs {
  _inc?: InputMaybe<IAmbassadorsIncInput>;
  _set?: InputMaybe<IAmbassadorsSetInput>;
  pk_columns: IAmbassadorsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateAnnualSchedulesArgs {
  _set?: InputMaybe<IAnnualSchedulesSetInput>;
  where: IAnnualSchedulesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateAnnualSchedulesByPkArgs {
  _set?: InputMaybe<IAnnualSchedulesSetInput>;
  pk_columns: IAnnualSchedulesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateAnswersArgs {
  _inc?: InputMaybe<IAnswersIncInput>;
  _set?: InputMaybe<IAnswersSetInput>;
  where: IAnswersBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateAnswersByPkArgs {
  _inc?: InputMaybe<IAnswersIncInput>;
  _set?: InputMaybe<IAnswersSetInput>;
  pk_columns: IAnswersPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateCourseGroupsArgs {
  _inc?: InputMaybe<ICourseGroupsIncInput>;
  _set?: InputMaybe<ICourseGroupsSetInput>;
  where: ICourseGroupsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateCourseGroupsByPkArgs {
  _inc?: InputMaybe<ICourseGroupsIncInput>;
  _set?: InputMaybe<ICourseGroupsSetInput>;
  pk_columns: ICourseGroupsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateCourseTypesArgs {
  _set?: InputMaybe<ICourseTypesSetInput>;
  where: ICourseTypesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateCourseTypesByPkArgs {
  _set?: InputMaybe<ICourseTypesSetInput>;
  pk_columns: ICourseTypesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateCoursesArgs {
  _inc?: InputMaybe<ICoursesIncInput>;
  _set?: InputMaybe<ICoursesSetInput>;
  where: ICoursesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateCoursesByPkArgs {
  _inc?: InputMaybe<ICoursesIncInput>;
  _set?: InputMaybe<ICoursesSetInput>;
  pk_columns: ICoursesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateDictionaryWordsArgs {
  _set?: InputMaybe<IDictionaryWordsSetInput>;
  where: IDictionaryWordsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateDictionaryWordsByPkArgs {
  _set?: InputMaybe<IDictionaryWordsSetInput>;
  pk_columns: IDictionaryWordsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateEventLocationsArgs {
  _set?: InputMaybe<IEventLocationsSetInput>;
  where: IEventLocationsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateEventLocationsByPkArgs {
  _set?: InputMaybe<IEventLocationsSetInput>;
  pk_columns: IEventLocationsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateEventRegistrationsArgs {
  _set?: InputMaybe<IEventRegistrationsSetInput>;
  where: IEventRegistrationsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateEventRegistrationsByPkArgs {
  _set?: InputMaybe<IEventRegistrationsSetInput>;
  pk_columns: IEventRegistrationsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateEventsArgs {
  _inc?: InputMaybe<IEventsIncInput>;
  _set?: InputMaybe<IEventsSetInput>;
  where: IEventsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateEventsByPkArgs {
  _inc?: InputMaybe<IEventsIncInput>;
  _set?: InputMaybe<IEventsSetInput>;
  pk_columns: IEventsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateEventsGroupTypesArgs {
  _set?: InputMaybe<IEventsGroupTypesSetInput>;
  where: IEventsGroupTypesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateEventsGroupTypesByPkArgs {
  _set?: InputMaybe<IEventsGroupTypesSetInput>;
  pk_columns: IEventsGroupTypesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateFavoriteLessonMomentsArgs {
  _inc?: InputMaybe<IFavoriteLessonMomentsIncInput>;
  _set?: InputMaybe<IFavoriteLessonMomentsSetInput>;
  where: IFavoriteLessonMomentsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateFavoriteLessonMomentsByPkArgs {
  _inc?: InputMaybe<IFavoriteLessonMomentsIncInput>;
  _set?: InputMaybe<IFavoriteLessonMomentsSetInput>;
  pk_columns: IFavoriteLessonMomentsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateFinalTestQuestionsArgs {
  _inc?: InputMaybe<IFinalTestQuestionsIncInput>;
  _set?: InputMaybe<IFinalTestQuestionsSetInput>;
  where: IFinalTestQuestionsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateFinalTestQuestionsByPkArgs {
  _inc?: InputMaybe<IFinalTestQuestionsIncInput>;
  _set?: InputMaybe<IFinalTestQuestionsSetInput>;
  pk_columns: IFinalTestQuestionsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateFinalTestResultsArgs {
  _set?: InputMaybe<IFinalTestResultsSetInput>;
  where: IFinalTestResultsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateFinalTestResultsByPkArgs {
  _set?: InputMaybe<IFinalTestResultsSetInput>;
  pk_columns: IFinalTestResultsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateFriendRequestsArgs {
  _set?: InputMaybe<IFriendRequestsSetInput>;
  where: IFriendRequestsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateFriendRequestsByPkArgs {
  _set?: InputMaybe<IFriendRequestsSetInput>;
  pk_columns: IFriendRequestsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateGroupTypesArgs {
  _inc?: InputMaybe<IGroupTypesIncInput>;
  _set?: InputMaybe<IGroupTypesSetInput>;
  where: IGroupTypesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateGroupTypesByPkArgs {
  _inc?: InputMaybe<IGroupTypesIncInput>;
  _set?: InputMaybe<IGroupTypesSetInput>;
  pk_columns: IGroupTypesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateKnowledgeTestQuestionsArgs {
  _inc?: InputMaybe<IKnowledgeTestQuestionsIncInput>;
  _set?: InputMaybe<IKnowledgeTestQuestionsSetInput>;
  where: IKnowledgeTestQuestionsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateKnowledgeTestQuestionsByPkArgs {
  _inc?: InputMaybe<IKnowledgeTestQuestionsIncInput>;
  _set?: InputMaybe<IKnowledgeTestQuestionsSetInput>;
  pk_columns: IKnowledgeTestQuestionsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateKnowledgeTestsArgs {
  _inc?: InputMaybe<IKnowledgeTestsIncInput>;
  _set?: InputMaybe<IKnowledgeTestsSetInput>;
  where: IKnowledgeTestsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateKnowledgeTestsByPkArgs {
  _inc?: InputMaybe<IKnowledgeTestsIncInput>;
  _set?: InputMaybe<IKnowledgeTestsSetInput>;
  pk_columns: IKnowledgeTestsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateLearningAssignmentsArgs {
  _inc?: InputMaybe<ILearningAssignmentsIncInput>;
  _set?: InputMaybe<ILearningAssignmentsSetInput>;
  where: ILearningAssignmentsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateLearningAssignmentsByPkArgs {
  _inc?: InputMaybe<ILearningAssignmentsIncInput>;
  _set?: InputMaybe<ILearningAssignmentsSetInput>;
  pk_columns: ILearningAssignmentsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateLessonDaysArgs {
  _set?: InputMaybe<ILessonDaysSetInput>;
  where: ILessonDaysBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateLessonDaysByPkArgs {
  _set?: InputMaybe<ILessonDaysSetInput>;
  pk_columns: ILessonDaysPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateLessonMessagesArgs {
  _set?: InputMaybe<ILessonMessagesSetInput>;
  where: ILessonMessagesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateLessonMessagesByPkArgs {
  _set?: InputMaybe<ILessonMessagesSetInput>;
  pk_columns: ILessonMessagesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateLessonPartitionsArgs {
  _inc?: InputMaybe<ILessonPartitionsIncInput>;
  _set?: InputMaybe<ILessonPartitionsSetInput>;
  where: ILessonPartitionsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateLessonPartitionsByPkArgs {
  _inc?: InputMaybe<ILessonPartitionsIncInput>;
  _set?: InputMaybe<ILessonPartitionsSetInput>;
  pk_columns: ILessonPartitionsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateLessonReactionsArgs {
  _inc?: InputMaybe<ILessonReactionsIncInput>;
  _set?: InputMaybe<ILessonReactionsSetInput>;
  where: ILessonReactionsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateLessonReactionsByPkArgs {
  _inc?: InputMaybe<ILessonReactionsIncInput>;
  _set?: InputMaybe<ILessonReactionsSetInput>;
  pk_columns: ILessonReactionsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateLessonUnlockKeysArgs {
  _inc?: InputMaybe<ILessonUnlockKeysIncInput>;
  _set?: InputMaybe<ILessonUnlockKeysSetInput>;
  where: ILessonUnlockKeysBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateLessonUnlockKeysByPkArgs {
  _inc?: InputMaybe<ILessonUnlockKeysIncInput>;
  _set?: InputMaybe<ILessonUnlockKeysSetInput>;
  pk_columns: ILessonUnlockKeysPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateLessonVersesArgs {
  _inc?: InputMaybe<ILessonVersesIncInput>;
  _set?: InputMaybe<ILessonVersesSetInput>;
  where: ILessonVersesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateLessonVersesByPkArgs {
  _inc?: InputMaybe<ILessonVersesIncInput>;
  _set?: InputMaybe<ILessonVersesSetInput>;
  pk_columns: ILessonVersesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateLessonWordsArgs {
  _inc?: InputMaybe<ILessonWordsIncInput>;
  _set?: InputMaybe<ILessonWordsSetInput>;
  where: ILessonWordsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateLessonWordsByPkArgs {
  _inc?: InputMaybe<ILessonWordsIncInput>;
  _set?: InputMaybe<ILessonWordsSetInput>;
  pk_columns: ILessonWordsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateLessonsArgs {
  _inc?: InputMaybe<ILessonsIncInput>;
  _set?: InputMaybe<ILessonsSetInput>;
  where: ILessonsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateLessonsByPkArgs {
  _inc?: InputMaybe<ILessonsIncInput>;
  _set?: InputMaybe<ILessonsSetInput>;
  pk_columns: ILessonsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateLessonsUnlockedArgs {
  _set?: InputMaybe<ILessonsUnlockedSetInput>;
  where: ILessonsUnlockedBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateLessonsUnlockedByPkArgs {
  _set?: InputMaybe<ILessonsUnlockedSetInput>;
  pk_columns: ILessonsUnlockedPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateLibraryDownloadsArgs {
  _set?: InputMaybe<ILibraryDownloadsSetInput>;
  where: ILibraryDownloadsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateLibraryDownloadsByPkArgs {
  _set?: InputMaybe<ILibraryDownloadsSetInput>;
  pk_columns: ILibraryDownloadsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateMessageTypesArgs {
  _set?: InputMaybe<IMessageTypesSetInput>;
  where: IMessageTypesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateMessageTypesByPkArgs {
  _set?: InputMaybe<IMessageTypesSetInput>;
  pk_columns: IMessageTypesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateMessagesArgs {
  _set?: InputMaybe<IMessagesSetInput>;
  where: IMessagesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateMessagesByPkArgs {
  _set?: InputMaybe<IMessagesSetInput>;
  pk_columns: IMessagesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateNewsArticlesArgs {
  _inc?: InputMaybe<INewsArticlesIncInput>;
  _set?: InputMaybe<INewsArticlesSetInput>;
  where: INewsArticlesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateNewsArticlesByPkArgs {
  _inc?: InputMaybe<INewsArticlesIncInput>;
  _set?: InputMaybe<INewsArticlesSetInput>;
  pk_columns: INewsArticlesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateNewsArticlesViewArgs {
  _inc?: InputMaybe<INewsArticlesViewIncInput>;
  _set?: InputMaybe<INewsArticlesViewSetInput>;
  where: INewsArticlesViewBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateNotificationGroupTypesArgs {
  _set?: InputMaybe<INotificationGroupTypesSetInput>;
  where: INotificationGroupTypesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateNotificationGroupTypesByPkArgs {
  _set?: InputMaybe<INotificationGroupTypesSetInput>;
  pk_columns: INotificationGroupTypesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateNotificationsArgs {
  _set?: InputMaybe<INotificationsSetInput>;
  where: INotificationsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateNotificationsByPkArgs {
  _set?: InputMaybe<INotificationsSetInput>;
  pk_columns: INotificationsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdatePersonalDevelopmentsArgs {
  _inc?: InputMaybe<IPersonalDevelopmentsIncInput>;
  _set?: InputMaybe<IPersonalDevelopmentsSetInput>;
  where: IPersonalDevelopmentsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdatePersonalDevelopmentsByPkArgs {
  _inc?: InputMaybe<IPersonalDevelopmentsIncInput>;
  _set?: InputMaybe<IPersonalDevelopmentsSetInput>;
  pk_columns: IPersonalDevelopmentsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateQaVideosArgs {
  _inc?: InputMaybe<IQaVideosIncInput>;
  _set?: InputMaybe<IQaVideosSetInput>;
  where: IQaVideosBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateQaVideosByPkArgs {
  _inc?: InputMaybe<IQaVideosIncInput>;
  _set?: InputMaybe<IQaVideosSetInput>;
  pk_columns: IQaVideosPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateQueJobsArgs {
  _append?: InputMaybe<IQueJobsAppendInput>;
  _delete_at_path?: InputMaybe<IQueJobsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IQueJobsDeleteElemInput>;
  _delete_key?: InputMaybe<IQueJobsDeleteKeyInput>;
  _inc?: InputMaybe<IQueJobsIncInput>;
  _prepend?: InputMaybe<IQueJobsPrependInput>;
  _set?: InputMaybe<IQueJobsSetInput>;
  where: IQueJobsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateQueJobsByPkArgs {
  _append?: InputMaybe<IQueJobsAppendInput>;
  _delete_at_path?: InputMaybe<IQueJobsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IQueJobsDeleteElemInput>;
  _delete_key?: InputMaybe<IQueJobsDeleteKeyInput>;
  _inc?: InputMaybe<IQueJobsIncInput>;
  _prepend?: InputMaybe<IQueJobsPrependInput>;
  _set?: InputMaybe<IQueJobsSetInput>;
  pk_columns: IQueJobsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateQuestionsArgs {
  _inc?: InputMaybe<IQuestionsIncInput>;
  _set?: InputMaybe<IQuestionsSetInput>;
  where: IQuestionsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateQuestionsByPkArgs {
  _inc?: InputMaybe<IQuestionsIncInput>;
  _set?: InputMaybe<IQuestionsSetInput>;
  pk_columns: IQuestionsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateRecitationsArgs {
  _inc?: InputMaybe<IRecitationsIncInput>;
  _set?: InputMaybe<IRecitationsSetInput>;
  where: IRecitationsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateRecitationsByPkArgs {
  _inc?: InputMaybe<IRecitationsIncInput>;
  _set?: InputMaybe<IRecitationsSetInput>;
  pk_columns: IRecitationsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateRepeatLessonProgressesArgs {
  _set?: InputMaybe<IRepeatLessonProgressesSetInput>;
  where: IRepeatLessonProgressesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateRepeatLessonProgressesByPkArgs {
  _set?: InputMaybe<IRepeatLessonProgressesSetInput>;
  pk_columns: IRepeatLessonProgressesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateRepeatSecondPartsProgressesArgs {
  _set?: InputMaybe<IRepeatSecondPartsProgressesSetInput>;
  where: IRepeatSecondPartsProgressesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateRepeatSecondPartsProgressesByPkArgs {
  _set?: InputMaybe<IRepeatSecondPartsProgressesSetInput>;
  pk_columns: IRepeatSecondPartsProgressesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateRepeatThirdPartsProgressesArgs {
  _set?: InputMaybe<IRepeatThirdPartsProgressesSetInput>;
  where: IRepeatThirdPartsProgressesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateRepeatThirdPartsProgressesByPkArgs {
  _set?: InputMaybe<IRepeatThirdPartsProgressesSetInput>;
  pk_columns: IRepeatThirdPartsProgressesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateSchemaMigrationsArgs {
  _set?: InputMaybe<ISchemaMigrationsSetInput>;
  where: ISchemaMigrationsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateSchemaMigrationsByPkArgs {
  _set?: InputMaybe<ISchemaMigrationsSetInput>;
  pk_columns: ISchemaMigrationsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateSubscriptionConfigsArgs {
  _inc?: InputMaybe<ISubscriptionConfigsIncInput>;
  _set?: InputMaybe<ISubscriptionConfigsSetInput>;
  where: ISubscriptionConfigsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateSubscriptionConfigsByPkArgs {
  _inc?: InputMaybe<ISubscriptionConfigsIncInput>;
  _set?: InputMaybe<ISubscriptionConfigsSetInput>;
  pk_columns: ISubscriptionConfigsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateSubscriptionsArgs {
  _append?: InputMaybe<ISubscriptionsAppendInput>;
  _delete_at_path?: InputMaybe<ISubscriptionsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ISubscriptionsDeleteElemInput>;
  _delete_key?: InputMaybe<ISubscriptionsDeleteKeyInput>;
  _inc?: InputMaybe<ISubscriptionsIncInput>;
  _prepend?: InputMaybe<ISubscriptionsPrependInput>;
  _set?: InputMaybe<ISubscriptionsSetInput>;
  where: ISubscriptionsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateSubscriptionsByPkArgs {
  _append?: InputMaybe<ISubscriptionsAppendInput>;
  _delete_at_path?: InputMaybe<ISubscriptionsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ISubscriptionsDeleteElemInput>;
  _delete_key?: InputMaybe<ISubscriptionsDeleteKeyInput>;
  _inc?: InputMaybe<ISubscriptionsIncInput>;
  _prepend?: InputMaybe<ISubscriptionsPrependInput>;
  _set?: InputMaybe<ISubscriptionsSetInput>;
  pk_columns: ISubscriptionsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateSupportTopicEntriesArgs {
  _set?: InputMaybe<ISupportTopicEntriesSetInput>;
  where: ISupportTopicEntriesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateSupportTopicEntriesByPkArgs {
  _set?: InputMaybe<ISupportTopicEntriesSetInput>;
  pk_columns: ISupportTopicEntriesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateSupportTopicsArgs {
  _set?: InputMaybe<ISupportTopicsSetInput>;
  where: ISupportTopicsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateSupportTopicsByPkArgs {
  _set?: InputMaybe<ISupportTopicsSetInput>;
  pk_columns: ISupportTopicsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateSuspensionsArgs {
  _set?: InputMaybe<ISuspensionsSetInput>;
  where: ISuspensionsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateSuspensionsByPkArgs {
  _set?: InputMaybe<ISuspensionsSetInput>;
  pk_columns: ISuspensionsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateTmpLessonsArgs {
  _set?: InputMaybe<ITmpLessonsSetInput>;
  where: ITmpLessonsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateTmpLessonsByPkArgs {
  _set?: InputMaybe<ITmpLessonsSetInput>;
  pk_columns: ITmpLessonsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateTmpProgressArgs {
  _set?: InputMaybe<ITmpProgressSetInput>;
  where: ITmpProgressBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateTmpProgressByPkArgs {
  _set?: InputMaybe<ITmpProgressSetInput>;
  pk_columns: ITmpProgressPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateUnlockablesArgs {
  _set?: InputMaybe<IUnlockablesSetInput>;
  where: IUnlockablesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateUnlockablesByPkArgs {
  _set?: InputMaybe<IUnlockablesSetInput>;
  pk_columns: IUnlockablesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateUserChildrenUnlockablesArgs {
  _inc?: InputMaybe<IUserChildrenUnlockablesIncInput>;
  _set?: InputMaybe<IUserChildrenUnlockablesSetInput>;
  where: IUserChildrenUnlockablesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateUserChildrenUnlockablesByPkArgs {
  _inc?: InputMaybe<IUserChildrenUnlockablesIncInput>;
  _set?: InputMaybe<IUserChildrenUnlockablesSetInput>;
  pk_columns: IUserChildrenUnlockablesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateUserLessonPartsProgressesArgs {
  _set?: InputMaybe<IUserLessonPartsProgressesSetInput>;
  where: IUserLessonPartsProgressesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateUserLessonPartsProgressesByPkArgs {
  _set?: InputMaybe<IUserLessonPartsProgressesSetInput>;
  pk_columns: IUserLessonPartsProgressesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateUserLessonProgressesArgs {
  _inc?: InputMaybe<IUserLessonProgressesIncInput>;
  _set?: InputMaybe<IUserLessonProgressesSetInput>;
  where: IUserLessonProgressesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateUserLessonProgressesByPkArgs {
  _inc?: InputMaybe<IUserLessonProgressesIncInput>;
  _set?: InputMaybe<IUserLessonProgressesSetInput>;
  pk_columns: IUserLessonProgressesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateUserQaProgressesArgs {
  _set?: InputMaybe<IUserQaProgressesSetInput>;
  where: IUserQaProgressesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateUserQaProgressesByPkArgs {
  _set?: InputMaybe<IUserQaProgressesSetInput>;
  pk_columns: IUserQaProgressesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateUserThirdPartsProgressesArgs {
  _set?: InputMaybe<IUserThirdPartsProgressesSetInput>;
  where: IUserThirdPartsProgressesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateUserThirdPartsProgressesByPkArgs {
  _set?: InputMaybe<IUserThirdPartsProgressesSetInput>;
  pk_columns: IUserThirdPartsProgressesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateUsersArgs {
  _inc?: InputMaybe<IUsersIncInput>;
  _set?: InputMaybe<IUsersSetInput>;
  where: IUsersBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateUsersByPkArgs {
  _inc?: InputMaybe<IUsersIncInput>;
  _set?: InputMaybe<IUsersSetInput>;
  pk_columns: IUsersPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateUsersChildDetailsArgs {
  _inc?: InputMaybe<IUsersChildDetailsIncInput>;
  _set?: InputMaybe<IUsersChildDetailsSetInput>;
  where: IUsersChildDetailsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateUsersChildDetailsByPkArgs {
  _inc?: InputMaybe<IUsersChildDetailsIncInput>;
  _set?: InputMaybe<IUsersChildDetailsSetInput>;
  pk_columns: IUsersChildDetailsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateUsersChildrenArgs {
  _inc?: InputMaybe<IUsersChildrenIncInput>;
  _set?: InputMaybe<IUsersChildrenSetInput>;
  where: IUsersChildrenBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateUsersChildrenByPkArgs {
  _inc?: InputMaybe<IUsersChildrenIncInput>;
  _set?: InputMaybe<IUsersChildrenSetInput>;
  pk_columns: IUsersChildrenPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateUsersSessionsArgs {
  _set?: InputMaybe<IUsersSessionsSetInput>;
  where: IUsersSessionsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateUsersSessionsByPkArgs {
  _set?: InputMaybe<IUsersSessionsSetInput>;
  pk_columns: IUsersSessionsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateVacationGroupTypesArgs {
  _set?: InputMaybe<IVacationGroupTypesSetInput>;
  where: IVacationGroupTypesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateVacationGroupTypesByPkArgs {
  _set?: InputMaybe<IVacationGroupTypesSetInput>;
  pk_columns: IVacationGroupTypesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateVacationsArgs {
  _set?: InputMaybe<IVacationsSetInput>;
  where: IVacationsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateVacationsByPkArgs {
  _set?: InputMaybe<IVacationsSetInput>;
  pk_columns: IVacationsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateWeeklyFactsArgs {
  _inc?: InputMaybe<IWeeklyFactsIncInput>;
  _set?: InputMaybe<IWeeklyFactsSetInput>;
  where: IWeeklyFactsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateWeeklyFactsByPkArgs {
  _inc?: InputMaybe<IWeeklyFactsIncInput>;
  _set?: InputMaybe<IWeeklyFactsSetInput>;
  pk_columns: IWeeklyFactsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateWpOrdersArgs {
  _append?: InputMaybe<IWpOrdersAppendInput>;
  _delete_at_path?: InputMaybe<IWpOrdersDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IWpOrdersDeleteElemInput>;
  _delete_key?: InputMaybe<IWpOrdersDeleteKeyInput>;
  _inc?: InputMaybe<IWpOrdersIncInput>;
  _prepend?: InputMaybe<IWpOrdersPrependInput>;
  _set?: InputMaybe<IWpOrdersSetInput>;
  where: IWpOrdersBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateWpOrdersByPkArgs {
  _append?: InputMaybe<IWpOrdersAppendInput>;
  _delete_at_path?: InputMaybe<IWpOrdersDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IWpOrdersDeleteElemInput>;
  _delete_key?: InputMaybe<IWpOrdersDeleteKeyInput>;
  _inc?: InputMaybe<IWpOrdersIncInput>;
  _prepend?: InputMaybe<IWpOrdersPrependInput>;
  _set?: InputMaybe<IWpOrdersSetInput>;
  pk_columns: IWpOrdersPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateWpProductsArgs {
  _inc?: InputMaybe<IWpProductsIncInput>;
  _set?: InputMaybe<IWpProductsSetInput>;
  where: IWpProductsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateWpProductsByPkArgs {
  _inc?: InputMaybe<IWpProductsIncInput>;
  _set?: InputMaybe<IWpProductsSetInput>;
  pk_columns: IWpProductsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateWpSubscriptionOrdersArgs {
  _inc?: InputMaybe<IWpSubscriptionOrdersIncInput>;
  _set?: InputMaybe<IWpSubscriptionOrdersSetInput>;
  where: IWpSubscriptionOrdersBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateWpSubscriptionOrdersByPkArgs {
  _inc?: InputMaybe<IWpSubscriptionOrdersIncInput>;
  _set?: InputMaybe<IWpSubscriptionOrdersSetInput>;
  pk_columns: IWpSubscriptionOrdersPkColumnsInput;
}

/** columns and relationships of "news_articles" */
export interface INewsArticles {
  __typename?: 'news_articles';
  /** An object relationship */
  attachment?: Maybe<INewsArticlesAttachments>;
  content: Scalars['String'];
  created_at: Scalars['timestamptz'];
  ends_on?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  sequence: Scalars['Int'];
  starts_on?: Maybe<Scalars['date']>;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
}

/** aggregated selection of "news_articles" */
export interface INewsArticlesAggregate {
  __typename?: 'news_articles_aggregate';
  aggregate?: Maybe<INewsArticlesAggregateFields>;
  nodes: Array<INewsArticles>;
}

/** aggregate fields of "news_articles" */
export interface INewsArticlesAggregateFields {
  __typename?: 'news_articles_aggregate_fields';
  avg?: Maybe<INewsArticlesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<INewsArticlesMaxFields>;
  min?: Maybe<INewsArticlesMinFields>;
  stddev?: Maybe<INewsArticlesStddevFields>;
  stddev_pop?: Maybe<INewsArticlesStddevPopFields>;
  stddev_samp?: Maybe<INewsArticlesStddevSampFields>;
  sum?: Maybe<INewsArticlesSumFields>;
  var_pop?: Maybe<INewsArticlesVarPopFields>;
  var_samp?: Maybe<INewsArticlesVarSampFields>;
  variance?: Maybe<INewsArticlesVarianceFields>;
}


/** aggregate fields of "news_articles" */
export interface INewsArticlesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<INewsArticlesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** columns and relationships of "news_articles_attachments" */
export interface INewsArticlesAttachments {
  __typename?: 'news_articles_attachments';
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  /** An object relationship */
  news_article?: Maybe<INewsArticles>;
  news_article_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "news_articles_attachments" */
export interface INewsArticlesAttachmentsAggregate {
  __typename?: 'news_articles_attachments_aggregate';
  aggregate?: Maybe<INewsArticlesAttachmentsAggregateFields>;
  nodes: Array<INewsArticlesAttachments>;
}

/** aggregate fields of "news_articles_attachments" */
export interface INewsArticlesAttachmentsAggregateFields {
  __typename?: 'news_articles_attachments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<INewsArticlesAttachmentsMaxFields>;
  min?: Maybe<INewsArticlesAttachmentsMinFields>;
}


/** aggregate fields of "news_articles_attachments" */
export interface INewsArticlesAttachmentsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<INewsArticlesAttachmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "news_articles_attachments" */
export interface INewsArticlesAttachmentsAggregateOrderBy {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<INewsArticlesAttachmentsMaxOrderBy>;
  min?: InputMaybe<INewsArticlesAttachmentsMinOrderBy>;
}

/** input type for inserting array relation for remote table "news_articles_attachments" */
export interface INewsArticlesAttachmentsArrRelInsertInput {
  data: Array<INewsArticlesAttachmentsInsertInput>;
}

/** Boolean expression to filter rows from the table "news_articles_attachments". All fields are combined with a logical 'AND'. */
export interface INewsArticlesAttachmentsBoolExp {
  _and?: InputMaybe<Array<INewsArticlesAttachmentsBoolExp>>;
  _not?: InputMaybe<INewsArticlesAttachmentsBoolExp>;
  _or?: InputMaybe<Array<INewsArticlesAttachmentsBoolExp>>;
  created_at?: InputMaybe<ITimestampComparisonExp>;
  filename?: InputMaybe<IStringComparisonExp>;
  key?: InputMaybe<IStringComparisonExp>;
  news_article?: InputMaybe<INewsArticlesBoolExp>;
  news_article_id?: InputMaybe<IUuidComparisonExp>;
}

/** input type for inserting data into table "news_articles_attachments" */
export interface INewsArticlesAttachmentsInsertInput {
  created_at?: InputMaybe<Scalars['timestamp']>;
  filename?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  news_article?: InputMaybe<INewsArticlesObjRelInsertInput>;
  news_article_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface INewsArticlesAttachmentsMaxFields {
  __typename?: 'news_articles_attachments_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  news_article_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "news_articles_attachments" */
export interface INewsArticlesAttachmentsMaxOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  filename?: InputMaybe<IOrderBy>;
  key?: InputMaybe<IOrderBy>;
  news_article_id?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface INewsArticlesAttachmentsMinFields {
  __typename?: 'news_articles_attachments_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  news_article_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "news_articles_attachments" */
export interface INewsArticlesAttachmentsMinOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  filename?: InputMaybe<IOrderBy>;
  key?: InputMaybe<IOrderBy>;
  news_article_id?: InputMaybe<IOrderBy>;
}

/** input type for inserting object relation for remote table "news_articles_attachments" */
export interface INewsArticlesAttachmentsObjRelInsertInput {
  data: INewsArticlesAttachmentsInsertInput;
}

/** Ordering options when selecting data from "news_articles_attachments". */
export interface INewsArticlesAttachmentsOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  filename?: InputMaybe<IOrderBy>;
  key?: InputMaybe<IOrderBy>;
  news_article?: InputMaybe<INewsArticlesOrderBy>;
  news_article_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "news_articles_attachments" */
export enum INewsArticlesAttachmentsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  FILENAME = 'filename',
  /** column name */
  KEY = 'key',
  /** column name */
  NEWS_ARTICLE_ID = 'news_article_id'
}

/** aggregate avg on columns */
export interface INewsArticlesAvgFields {
  __typename?: 'news_articles_avg_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "news_articles". All fields are combined with a logical 'AND'. */
export interface INewsArticlesBoolExp {
  _and?: InputMaybe<Array<INewsArticlesBoolExp>>;
  _not?: InputMaybe<INewsArticlesBoolExp>;
  _or?: InputMaybe<Array<INewsArticlesBoolExp>>;
  attachment?: InputMaybe<INewsArticlesAttachmentsBoolExp>;
  content?: InputMaybe<IStringComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  ends_on?: InputMaybe<IDateComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  sequence?: InputMaybe<IIntComparisonExp>;
  starts_on?: InputMaybe<IDateComparisonExp>;
  title?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "news_articles" */
export enum INewsArticlesConstraint {
  /** unique or primary key constraint */
  NEWS_ARTICLES_PKEY = 'news_articles_pkey'
}

/** input type for incrementing numeric columns in table "news_articles" */
export interface INewsArticlesIncInput {
  sequence?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "news_articles" */
export interface INewsArticlesInsertInput {
  attachment?: InputMaybe<INewsArticlesAttachmentsObjRelInsertInput>;
  content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ends_on?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  sequence?: InputMaybe<Scalars['Int']>;
  starts_on?: InputMaybe<Scalars['date']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface INewsArticlesMaxFields {
  __typename?: 'news_articles_max_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  ends_on?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['Int']>;
  starts_on?: Maybe<Scalars['date']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface INewsArticlesMinFields {
  __typename?: 'news_articles_min_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  ends_on?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['Int']>;
  starts_on?: Maybe<Scalars['date']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** response of any mutation on the table "news_articles" */
export interface INewsArticlesMutationResponse {
  __typename?: 'news_articles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<INewsArticles>;
}

/** input type for inserting object relation for remote table "news_articles" */
export interface INewsArticlesObjRelInsertInput {
  data: INewsArticlesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<INewsArticlesOnConflict>;
}

/** on_conflict condition type for table "news_articles" */
export interface INewsArticlesOnConflict {
  constraint: INewsArticlesConstraint;
  update_columns?: Array<INewsArticlesUpdateColumn>;
  where?: InputMaybe<INewsArticlesBoolExp>;
}

/** Ordering options when selecting data from "news_articles". */
export interface INewsArticlesOrderBy {
  attachment?: InputMaybe<INewsArticlesAttachmentsOrderBy>;
  content?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  ends_on?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  starts_on?: InputMaybe<IOrderBy>;
  title?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: news_articles */
export interface INewsArticlesPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "news_articles" */
export enum INewsArticlesSelectColumn {
  /** column name */
  CONTENT = 'content',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ENDS_ON = 'ends_on',
  /** column name */
  ID = 'id',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  STARTS_ON = 'starts_on',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "news_articles" */
export interface INewsArticlesSetInput {
  content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ends_on?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  sequence?: InputMaybe<Scalars['Int']>;
  starts_on?: InputMaybe<Scalars['date']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface INewsArticlesStddevFields {
  __typename?: 'news_articles_stddev_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface INewsArticlesStddevPopFields {
  __typename?: 'news_articles_stddev_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface INewsArticlesStddevSampFields {
  __typename?: 'news_articles_stddev_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface INewsArticlesSumFields {
  __typename?: 'news_articles_sum_fields';
  sequence?: Maybe<Scalars['Int']>;
}

/** update columns of table "news_articles" */
export enum INewsArticlesUpdateColumn {
  /** column name */
  CONTENT = 'content',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ENDS_ON = 'ends_on',
  /** column name */
  ID = 'id',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  STARTS_ON = 'starts_on',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface INewsArticlesVarPopFields {
  __typename?: 'news_articles_var_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface INewsArticlesVarSampFields {
  __typename?: 'news_articles_var_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface INewsArticlesVarianceFields {
  __typename?: 'news_articles_variance_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "news_articles_view" */
export interface INewsArticlesView {
  __typename?: 'news_articles_view';
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  news_articles_attachments: Array<INewsArticlesAttachments>;
  /** An aggregate relationship */
  news_articles_attachments_aggregate: INewsArticlesAttachmentsAggregate;
  sequence?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
}


/** columns and relationships of "news_articles_view" */
export interface INewsArticlesViewNewsArticlesAttachmentsArgs {
  distinct_on?: InputMaybe<Array<INewsArticlesAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INewsArticlesAttachmentsOrderBy>>;
  where?: InputMaybe<INewsArticlesAttachmentsBoolExp>;
}


/** columns and relationships of "news_articles_view" */
export interface INewsArticlesViewNewsArticlesAttachmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<INewsArticlesAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INewsArticlesAttachmentsOrderBy>>;
  where?: InputMaybe<INewsArticlesAttachmentsBoolExp>;
}

/** aggregated selection of "news_articles_view" */
export interface INewsArticlesViewAggregate {
  __typename?: 'news_articles_view_aggregate';
  aggregate?: Maybe<INewsArticlesViewAggregateFields>;
  nodes: Array<INewsArticlesView>;
}

/** aggregate fields of "news_articles_view" */
export interface INewsArticlesViewAggregateFields {
  __typename?: 'news_articles_view_aggregate_fields';
  avg?: Maybe<INewsArticlesViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<INewsArticlesViewMaxFields>;
  min?: Maybe<INewsArticlesViewMinFields>;
  stddev?: Maybe<INewsArticlesViewStddevFields>;
  stddev_pop?: Maybe<INewsArticlesViewStddevPopFields>;
  stddev_samp?: Maybe<INewsArticlesViewStddevSampFields>;
  sum?: Maybe<INewsArticlesViewSumFields>;
  var_pop?: Maybe<INewsArticlesViewVarPopFields>;
  var_samp?: Maybe<INewsArticlesViewVarSampFields>;
  variance?: Maybe<INewsArticlesViewVarianceFields>;
}


/** aggregate fields of "news_articles_view" */
export interface INewsArticlesViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<INewsArticlesViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface INewsArticlesViewAvgFields {
  __typename?: 'news_articles_view_avg_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "news_articles_view". All fields are combined with a logical 'AND'. */
export interface INewsArticlesViewBoolExp {
  _and?: InputMaybe<Array<INewsArticlesViewBoolExp>>;
  _not?: InputMaybe<INewsArticlesViewBoolExp>;
  _or?: InputMaybe<Array<INewsArticlesViewBoolExp>>;
  content?: InputMaybe<IStringComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  news_articles_attachments?: InputMaybe<INewsArticlesAttachmentsBoolExp>;
  sequence?: InputMaybe<IIntComparisonExp>;
  title?: InputMaybe<IStringComparisonExp>;
}

/** input type for incrementing numeric columns in table "news_articles_view" */
export interface INewsArticlesViewIncInput {
  sequence?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "news_articles_view" */
export interface INewsArticlesViewInsertInput {
  content?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  news_articles_attachments?: InputMaybe<INewsArticlesAttachmentsArrRelInsertInput>;
  sequence?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface INewsArticlesViewMaxFields {
  __typename?: 'news_articles_view_max_fields';
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface INewsArticlesViewMinFields {
  __typename?: 'news_articles_view_min_fields';
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "news_articles_view" */
export interface INewsArticlesViewMutationResponse {
  __typename?: 'news_articles_view_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<INewsArticlesView>;
}

/** Ordering options when selecting data from "news_articles_view". */
export interface INewsArticlesViewOrderBy {
  content?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  news_articles_attachments_aggregate?: InputMaybe<INewsArticlesAttachmentsAggregateOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  title?: InputMaybe<IOrderBy>;
}

/** select columns of table "news_articles_view" */
export enum INewsArticlesViewSelectColumn {
  /** column name */
  CONTENT = 'content',
  /** column name */
  ID = 'id',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  TITLE = 'title'
}

/** input type for updating data in table "news_articles_view" */
export interface INewsArticlesViewSetInput {
  content?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  sequence?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface INewsArticlesViewStddevFields {
  __typename?: 'news_articles_view_stddev_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface INewsArticlesViewStddevPopFields {
  __typename?: 'news_articles_view_stddev_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface INewsArticlesViewStddevSampFields {
  __typename?: 'news_articles_view_stddev_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface INewsArticlesViewSumFields {
  __typename?: 'news_articles_view_sum_fields';
  sequence?: Maybe<Scalars['Int']>;
}

/** aggregate var_pop on columns */
export interface INewsArticlesViewVarPopFields {
  __typename?: 'news_articles_view_var_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface INewsArticlesViewVarSampFields {
  __typename?: 'news_articles_view_var_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface INewsArticlesViewVarianceFields {
  __typename?: 'news_articles_view_variance_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "notification_group_types" */
export interface INotificationGroupTypes {
  __typename?: 'notification_group_types';
  /** An object relationship */
  group_type: IGroupTypes;
  group_type_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  notification: INotifications;
  notification_id: Scalars['uuid'];
}

/** aggregated selection of "notification_group_types" */
export interface INotificationGroupTypesAggregate {
  __typename?: 'notification_group_types_aggregate';
  aggregate?: Maybe<INotificationGroupTypesAggregateFields>;
  nodes: Array<INotificationGroupTypes>;
}

/** aggregate fields of "notification_group_types" */
export interface INotificationGroupTypesAggregateFields {
  __typename?: 'notification_group_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<INotificationGroupTypesMaxFields>;
  min?: Maybe<INotificationGroupTypesMinFields>;
}


/** aggregate fields of "notification_group_types" */
export interface INotificationGroupTypesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<INotificationGroupTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "notification_group_types" */
export interface INotificationGroupTypesAggregateOrderBy {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<INotificationGroupTypesMaxOrderBy>;
  min?: InputMaybe<INotificationGroupTypesMinOrderBy>;
}

/** input type for inserting array relation for remote table "notification_group_types" */
export interface INotificationGroupTypesArrRelInsertInput {
  data: Array<INotificationGroupTypesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<INotificationGroupTypesOnConflict>;
}

/** Boolean expression to filter rows from the table "notification_group_types". All fields are combined with a logical 'AND'. */
export interface INotificationGroupTypesBoolExp {
  _and?: InputMaybe<Array<INotificationGroupTypesBoolExp>>;
  _not?: InputMaybe<INotificationGroupTypesBoolExp>;
  _or?: InputMaybe<Array<INotificationGroupTypesBoolExp>>;
  group_type?: InputMaybe<IGroupTypesBoolExp>;
  group_type_id?: InputMaybe<IUuidComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  notification?: InputMaybe<INotificationsBoolExp>;
  notification_id?: InputMaybe<IUuidComparisonExp>;
}

/** unique or primary key constraints on table "notification_group_types" */
export enum INotificationGroupTypesConstraint {
  /** unique or primary key constraint */
  NOTIFICATION_GROUP_TYPES_GROUP_TYPE_ID_NOTIFICATION_ID_KEY = 'notification_group_types_group_type_id_notification_id_key',
  /** unique or primary key constraint */
  NOTIFICATION_GROUP_TYPES_PKEY = 'notification_group_types_pkey'
}

/** input type for inserting data into table "notification_group_types" */
export interface INotificationGroupTypesInsertInput {
  group_type?: InputMaybe<IGroupTypesObjRelInsertInput>;
  group_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  notification?: InputMaybe<INotificationsObjRelInsertInput>;
  notification_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface INotificationGroupTypesMaxFields {
  __typename?: 'notification_group_types_max_fields';
  group_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  notification_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "notification_group_types" */
export interface INotificationGroupTypesMaxOrderBy {
  group_type_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  notification_id?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface INotificationGroupTypesMinFields {
  __typename?: 'notification_group_types_min_fields';
  group_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  notification_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "notification_group_types" */
export interface INotificationGroupTypesMinOrderBy {
  group_type_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  notification_id?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "notification_group_types" */
export interface INotificationGroupTypesMutationResponse {
  __typename?: 'notification_group_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<INotificationGroupTypes>;
}

/** on_conflict condition type for table "notification_group_types" */
export interface INotificationGroupTypesOnConflict {
  constraint: INotificationGroupTypesConstraint;
  update_columns?: Array<INotificationGroupTypesUpdateColumn>;
  where?: InputMaybe<INotificationGroupTypesBoolExp>;
}

/** Ordering options when selecting data from "notification_group_types". */
export interface INotificationGroupTypesOrderBy {
  group_type?: InputMaybe<IGroupTypesOrderBy>;
  group_type_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  notification?: InputMaybe<INotificationsOrderBy>;
  notification_id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: notification_group_types */
export interface INotificationGroupTypesPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "notification_group_types" */
export enum INotificationGroupTypesSelectColumn {
  /** column name */
  GROUP_TYPE_ID = 'group_type_id',
  /** column name */
  ID = 'id',
  /** column name */
  NOTIFICATION_ID = 'notification_id'
}

/** input type for updating data in table "notification_group_types" */
export interface INotificationGroupTypesSetInput {
  group_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  notification_id?: InputMaybe<Scalars['uuid']>;
}

/** update columns of table "notification_group_types" */
export enum INotificationGroupTypesUpdateColumn {
  /** column name */
  GROUP_TYPE_ID = 'group_type_id',
  /** column name */
  ID = 'id',
  /** column name */
  NOTIFICATION_ID = 'notification_id'
}

/** columns and relationships of "notifications" */
export interface INotifications {
  __typename?: 'notifications';
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  /** An array relationship */
  notification_group_types: Array<INotificationGroupTypes>;
  /** An aggregate relationship */
  notification_group_types_aggregate: INotificationGroupTypesAggregate;
  published: Scalars['Boolean'];
  title: Scalars['String'];
}


/** columns and relationships of "notifications" */
export interface INotificationsNotificationGroupTypesArgs {
  distinct_on?: InputMaybe<Array<INotificationGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INotificationGroupTypesOrderBy>>;
  where?: InputMaybe<INotificationGroupTypesBoolExp>;
}


/** columns and relationships of "notifications" */
export interface INotificationsNotificationGroupTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<INotificationGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INotificationGroupTypesOrderBy>>;
  where?: InputMaybe<INotificationGroupTypesBoolExp>;
}

/** aggregated selection of "notifications" */
export interface INotificationsAggregate {
  __typename?: 'notifications_aggregate';
  aggregate?: Maybe<INotificationsAggregateFields>;
  nodes: Array<INotifications>;
}

/** aggregate fields of "notifications" */
export interface INotificationsAggregateFields {
  __typename?: 'notifications_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<INotificationsMaxFields>;
  min?: Maybe<INotificationsMinFields>;
}


/** aggregate fields of "notifications" */
export interface INotificationsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<INotificationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "notifications". All fields are combined with a logical 'AND'. */
export interface INotificationsBoolExp {
  _and?: InputMaybe<Array<INotificationsBoolExp>>;
  _not?: InputMaybe<INotificationsBoolExp>;
  _or?: InputMaybe<Array<INotificationsBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  notification_group_types?: InputMaybe<INotificationGroupTypesBoolExp>;
  published?: InputMaybe<IBooleanComparisonExp>;
  title?: InputMaybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "notifications" */
export enum INotificationsConstraint {
  /** unique or primary key constraint */
  NOTIFICATIONS_PKEY = 'notifications_pkey'
}

/** input type for inserting data into table "notifications" */
export interface INotificationsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  notification_group_types?: InputMaybe<INotificationGroupTypesArrRelInsertInput>;
  published?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface INotificationsMaxFields {
  __typename?: 'notifications_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface INotificationsMinFields {
  __typename?: 'notifications_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "notifications" */
export interface INotificationsMutationResponse {
  __typename?: 'notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<INotifications>;
}

/** input type for inserting object relation for remote table "notifications" */
export interface INotificationsObjRelInsertInput {
  data: INotificationsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<INotificationsOnConflict>;
}

/** on_conflict condition type for table "notifications" */
export interface INotificationsOnConflict {
  constraint: INotificationsConstraint;
  update_columns?: Array<INotificationsUpdateColumn>;
  where?: InputMaybe<INotificationsBoolExp>;
}

/** Ordering options when selecting data from "notifications". */
export interface INotificationsOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  notification_group_types_aggregate?: InputMaybe<INotificationGroupTypesAggregateOrderBy>;
  published?: InputMaybe<IOrderBy>;
  title?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: notifications */
export interface INotificationsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "notifications" */
export enum INotificationsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  ID = 'id',
  /** column name */
  PUBLISHED = 'published',
  /** column name */
  TITLE = 'title'
}

/** input type for updating data in table "notifications" */
export interface INotificationsSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  published?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
}

/** update columns of table "notifications" */
export enum INotificationsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  ID = 'id',
  /** column name */
  PUBLISHED = 'published',
  /** column name */
  TITLE = 'title'
}

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export interface INumericComparisonExp {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
}

/** column ordering options */
export enum IOrderBy {
  /** in ascending order, nulls last */
  ASC = 'asc',
  /** in ascending order, nulls first */
  ASC_NULLS_FIRST = 'asc_nulls_first',
  /** in ascending order, nulls last */
  ASC_NULLS_LAST = 'asc_nulls_last',
  /** in descending order, nulls first */
  DESC = 'desc',
  /** in descending order, nulls first */
  DESC_NULLS_FIRST = 'desc_nulls_first',
  /** in descending order, nulls last */
  DESC_NULLS_LAST = 'desc_nulls_last'
}

/** columns and relationships of "personal_developments" */
export interface IPersonalDevelopments {
  __typename?: 'personal_developments';
  /** An object relationship */
  attachment?: Maybe<IPersonalDevelopmentsAttachments>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  image: Scalars['String'];
  sequence: Scalars['Int'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  vimeo: Scalars['String'];
}

/** aggregated selection of "personal_developments" */
export interface IPersonalDevelopmentsAggregate {
  __typename?: 'personal_developments_aggregate';
  aggregate?: Maybe<IPersonalDevelopmentsAggregateFields>;
  nodes: Array<IPersonalDevelopments>;
}

/** aggregate fields of "personal_developments" */
export interface IPersonalDevelopmentsAggregateFields {
  __typename?: 'personal_developments_aggregate_fields';
  avg?: Maybe<IPersonalDevelopmentsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IPersonalDevelopmentsMaxFields>;
  min?: Maybe<IPersonalDevelopmentsMinFields>;
  stddev?: Maybe<IPersonalDevelopmentsStddevFields>;
  stddev_pop?: Maybe<IPersonalDevelopmentsStddevPopFields>;
  stddev_samp?: Maybe<IPersonalDevelopmentsStddevSampFields>;
  sum?: Maybe<IPersonalDevelopmentsSumFields>;
  var_pop?: Maybe<IPersonalDevelopmentsVarPopFields>;
  var_samp?: Maybe<IPersonalDevelopmentsVarSampFields>;
  variance?: Maybe<IPersonalDevelopmentsVarianceFields>;
}


/** aggregate fields of "personal_developments" */
export interface IPersonalDevelopmentsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IPersonalDevelopmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** columns and relationships of "personal_developments_attachments" */
export interface IPersonalDevelopmentsAttachments {
  __typename?: 'personal_developments_attachments';
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  personal_development_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "personal_developments_attachments" */
export interface IPersonalDevelopmentsAttachmentsAggregate {
  __typename?: 'personal_developments_attachments_aggregate';
  aggregate?: Maybe<IPersonalDevelopmentsAttachmentsAggregateFields>;
  nodes: Array<IPersonalDevelopmentsAttachments>;
}

/** aggregate fields of "personal_developments_attachments" */
export interface IPersonalDevelopmentsAttachmentsAggregateFields {
  __typename?: 'personal_developments_attachments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IPersonalDevelopmentsAttachmentsMaxFields>;
  min?: Maybe<IPersonalDevelopmentsAttachmentsMinFields>;
}


/** aggregate fields of "personal_developments_attachments" */
export interface IPersonalDevelopmentsAttachmentsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IPersonalDevelopmentsAttachmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "personal_developments_attachments". All fields are combined with a logical 'AND'. */
export interface IPersonalDevelopmentsAttachmentsBoolExp {
  _and?: InputMaybe<Array<IPersonalDevelopmentsAttachmentsBoolExp>>;
  _not?: InputMaybe<IPersonalDevelopmentsAttachmentsBoolExp>;
  _or?: InputMaybe<Array<IPersonalDevelopmentsAttachmentsBoolExp>>;
  created_at?: InputMaybe<ITimestampComparisonExp>;
  filename?: InputMaybe<IStringComparisonExp>;
  key?: InputMaybe<IStringComparisonExp>;
  personal_development_id?: InputMaybe<IUuidComparisonExp>;
}

/** input type for inserting data into table "personal_developments_attachments" */
export interface IPersonalDevelopmentsAttachmentsInsertInput {
  created_at?: InputMaybe<Scalars['timestamp']>;
  filename?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  personal_development_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface IPersonalDevelopmentsAttachmentsMaxFields {
  __typename?: 'personal_developments_attachments_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  personal_development_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IPersonalDevelopmentsAttachmentsMinFields {
  __typename?: 'personal_developments_attachments_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  personal_development_id?: Maybe<Scalars['uuid']>;
}

/** input type for inserting object relation for remote table "personal_developments_attachments" */
export interface IPersonalDevelopmentsAttachmentsObjRelInsertInput {
  data: IPersonalDevelopmentsAttachmentsInsertInput;
}

/** Ordering options when selecting data from "personal_developments_attachments". */
export interface IPersonalDevelopmentsAttachmentsOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  filename?: InputMaybe<IOrderBy>;
  key?: InputMaybe<IOrderBy>;
  personal_development_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "personal_developments_attachments" */
export enum IPersonalDevelopmentsAttachmentsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  FILENAME = 'filename',
  /** column name */
  KEY = 'key',
  /** column name */
  PERSONAL_DEVELOPMENT_ID = 'personal_development_id'
}

/** aggregate avg on columns */
export interface IPersonalDevelopmentsAvgFields {
  __typename?: 'personal_developments_avg_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "personal_developments". All fields are combined with a logical 'AND'. */
export interface IPersonalDevelopmentsBoolExp {
  _and?: InputMaybe<Array<IPersonalDevelopmentsBoolExp>>;
  _not?: InputMaybe<IPersonalDevelopmentsBoolExp>;
  _or?: InputMaybe<Array<IPersonalDevelopmentsBoolExp>>;
  attachment?: InputMaybe<IPersonalDevelopmentsAttachmentsBoolExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  image?: InputMaybe<IStringComparisonExp>;
  sequence?: InputMaybe<IIntComparisonExp>;
  subtitle?: InputMaybe<IStringComparisonExp>;
  title?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  vimeo?: InputMaybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "personal_developments" */
export enum IPersonalDevelopmentsConstraint {
  /** unique or primary key constraint */
  PERSONAL_DEVELOPMENTS_PKEY = 'personal_developments_pkey'
}

/** input type for incrementing numeric columns in table "personal_developments" */
export interface IPersonalDevelopmentsIncInput {
  sequence?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "personal_developments" */
export interface IPersonalDevelopmentsInsertInput {
  attachment?: InputMaybe<IPersonalDevelopmentsAttachmentsObjRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vimeo?: InputMaybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface IPersonalDevelopmentsMaxFields {
  __typename?: 'personal_developments_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vimeo?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IPersonalDevelopmentsMinFields {
  __typename?: 'personal_developments_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vimeo?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "personal_developments" */
export interface IPersonalDevelopmentsMutationResponse {
  __typename?: 'personal_developments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IPersonalDevelopments>;
}

/** on_conflict condition type for table "personal_developments" */
export interface IPersonalDevelopmentsOnConflict {
  constraint: IPersonalDevelopmentsConstraint;
  update_columns?: Array<IPersonalDevelopmentsUpdateColumn>;
  where?: InputMaybe<IPersonalDevelopmentsBoolExp>;
}

/** Ordering options when selecting data from "personal_developments". */
export interface IPersonalDevelopmentsOrderBy {
  attachment?: InputMaybe<IPersonalDevelopmentsAttachmentsOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  image?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  subtitle?: InputMaybe<IOrderBy>;
  title?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  vimeo?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: personal_developments */
export interface IPersonalDevelopmentsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "personal_developments" */
export enum IPersonalDevelopmentsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  IMAGE = 'image',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  SUBTITLE = 'subtitle',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  VIMEO = 'vimeo'
}

/** input type for updating data in table "personal_developments" */
export interface IPersonalDevelopmentsSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vimeo?: InputMaybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface IPersonalDevelopmentsStddevFields {
  __typename?: 'personal_developments_stddev_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IPersonalDevelopmentsStddevPopFields {
  __typename?: 'personal_developments_stddev_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IPersonalDevelopmentsStddevSampFields {
  __typename?: 'personal_developments_stddev_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IPersonalDevelopmentsSumFields {
  __typename?: 'personal_developments_sum_fields';
  sequence?: Maybe<Scalars['Int']>;
}

/** update columns of table "personal_developments" */
export enum IPersonalDevelopmentsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  IMAGE = 'image',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  SUBTITLE = 'subtitle',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  VIMEO = 'vimeo'
}

/** aggregate var_pop on columns */
export interface IPersonalDevelopmentsVarPopFields {
  __typename?: 'personal_developments_var_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IPersonalDevelopmentsVarSampFields {
  __typename?: 'personal_developments_var_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IPersonalDevelopmentsVarianceFields {
  __typename?: 'personal_developments_variance_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "qa_videos" */
export interface IQaVideos {
  __typename?: 'qa_videos';
  content: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  published: Scalars['Boolean'];
  sequence: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
}

/** aggregated selection of "qa_videos" */
export interface IQaVideosAggregate {
  __typename?: 'qa_videos_aggregate';
  aggregate?: Maybe<IQaVideosAggregateFields>;
  nodes: Array<IQaVideos>;
}

/** aggregate fields of "qa_videos" */
export interface IQaVideosAggregateFields {
  __typename?: 'qa_videos_aggregate_fields';
  avg?: Maybe<IQaVideosAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IQaVideosMaxFields>;
  min?: Maybe<IQaVideosMinFields>;
  stddev?: Maybe<IQaVideosStddevFields>;
  stddev_pop?: Maybe<IQaVideosStddevPopFields>;
  stddev_samp?: Maybe<IQaVideosStddevSampFields>;
  sum?: Maybe<IQaVideosSumFields>;
  var_pop?: Maybe<IQaVideosVarPopFields>;
  var_samp?: Maybe<IQaVideosVarSampFields>;
  variance?: Maybe<IQaVideosVarianceFields>;
}


/** aggregate fields of "qa_videos" */
export interface IQaVideosAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IQaVideosSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IQaVideosAvgFields {
  __typename?: 'qa_videos_avg_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "qa_videos". All fields are combined with a logical 'AND'. */
export interface IQaVideosBoolExp {
  _and?: InputMaybe<Array<IQaVideosBoolExp>>;
  _not?: InputMaybe<IQaVideosBoolExp>;
  _or?: InputMaybe<Array<IQaVideosBoolExp>>;
  content?: InputMaybe<IStringComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  published?: InputMaybe<IBooleanComparisonExp>;
  sequence?: InputMaybe<IIntComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "qa_videos" */
export enum IQaVideosConstraint {
  /** unique or primary key constraint */
  QA_VIDEOS_PKEY = 'qa_videos_pkey'
}

/** input type for incrementing numeric columns in table "qa_videos" */
export interface IQaVideosIncInput {
  sequence?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "qa_videos" */
export interface IQaVideosInsertInput {
  content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sequence?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface IQaVideosMaxFields {
  __typename?: 'qa_videos_max_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface IQaVideosMinFields {
  __typename?: 'qa_videos_min_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** response of any mutation on the table "qa_videos" */
export interface IQaVideosMutationResponse {
  __typename?: 'qa_videos_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IQaVideos>;
}

/** on_conflict condition type for table "qa_videos" */
export interface IQaVideosOnConflict {
  constraint: IQaVideosConstraint;
  update_columns?: Array<IQaVideosUpdateColumn>;
  where?: InputMaybe<IQaVideosBoolExp>;
}

/** Ordering options when selecting data from "qa_videos". */
export interface IQaVideosOrderBy {
  content?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  published?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: qa_videos */
export interface IQaVideosPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "qa_videos" */
export enum IQaVideosSelectColumn {
  /** column name */
  CONTENT = 'content',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  PUBLISHED = 'published',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "qa_videos" */
export interface IQaVideosSetInput {
  content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  sequence?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface IQaVideosStddevFields {
  __typename?: 'qa_videos_stddev_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IQaVideosStddevPopFields {
  __typename?: 'qa_videos_stddev_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IQaVideosStddevSampFields {
  __typename?: 'qa_videos_stddev_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IQaVideosSumFields {
  __typename?: 'qa_videos_sum_fields';
  sequence?: Maybe<Scalars['Int']>;
}

/** update columns of table "qa_videos" */
export enum IQaVideosUpdateColumn {
  /** column name */
  CONTENT = 'content',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  PUBLISHED = 'published',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IQaVideosVarPopFields {
  __typename?: 'qa_videos_var_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IQaVideosVarSampFields {
  __typename?: 'qa_videos_var_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IQaVideosVarianceFields {
  __typename?: 'qa_videos_variance_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** 4 */
export interface IQueJobs {
  __typename?: 'que_jobs';
  args: Scalars['jsonb'];
  data: Scalars['jsonb'];
  error_count: Scalars['Int'];
  expired_at?: Maybe<Scalars['timestamptz']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  job_class: Scalars['String'];
  last_error_backtrace?: Maybe<Scalars['String']>;
  last_error_message?: Maybe<Scalars['String']>;
  priority: Scalars['smallint'];
  queue: Scalars['String'];
  run_at: Scalars['timestamptz'];
}


/** 4 */
export interface IQueJobsArgsArgs {
  path?: InputMaybe<Scalars['String']>;
}


/** 4 */
export interface IQueJobsDataArgs {
  path?: InputMaybe<Scalars['String']>;
}

/** aggregated selection of "que_jobs" */
export interface IQueJobsAggregate {
  __typename?: 'que_jobs_aggregate';
  aggregate?: Maybe<IQueJobsAggregateFields>;
  nodes: Array<IQueJobs>;
}

/** aggregate fields of "que_jobs" */
export interface IQueJobsAggregateFields {
  __typename?: 'que_jobs_aggregate_fields';
  avg?: Maybe<IQueJobsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IQueJobsMaxFields>;
  min?: Maybe<IQueJobsMinFields>;
  stddev?: Maybe<IQueJobsStddevFields>;
  stddev_pop?: Maybe<IQueJobsStddevPopFields>;
  stddev_samp?: Maybe<IQueJobsStddevSampFields>;
  sum?: Maybe<IQueJobsSumFields>;
  var_pop?: Maybe<IQueJobsVarPopFields>;
  var_samp?: Maybe<IQueJobsVarSampFields>;
  variance?: Maybe<IQueJobsVarianceFields>;
}


/** aggregate fields of "que_jobs" */
export interface IQueJobsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IQueJobsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface IQueJobsAppendInput {
  args?: InputMaybe<Scalars['jsonb']>;
  data?: InputMaybe<Scalars['jsonb']>;
}

/** aggregate avg on columns */
export interface IQueJobsAvgFields {
  __typename?: 'que_jobs_avg_fields';
  error_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "que_jobs". All fields are combined with a logical 'AND'. */
export interface IQueJobsBoolExp {
  _and?: InputMaybe<Array<IQueJobsBoolExp>>;
  _not?: InputMaybe<IQueJobsBoolExp>;
  _or?: InputMaybe<Array<IQueJobsBoolExp>>;
  args?: InputMaybe<IJsonbComparisonExp>;
  data?: InputMaybe<IJsonbComparisonExp>;
  error_count?: InputMaybe<IIntComparisonExp>;
  expired_at?: InputMaybe<ITimestamptzComparisonExp>;
  finished_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IBigintComparisonExp>;
  job_class?: InputMaybe<IStringComparisonExp>;
  last_error_backtrace?: InputMaybe<IStringComparisonExp>;
  last_error_message?: InputMaybe<IStringComparisonExp>;
  priority?: InputMaybe<ISmallintComparisonExp>;
  queue?: InputMaybe<IStringComparisonExp>;
  run_at?: InputMaybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "que_jobs" */
export enum IQueJobsConstraint {
  /** unique or primary key constraint */
  QUE_JOBS_PKEY = 'que_jobs_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface IQueJobsDeleteAtPathInput {
  args?: InputMaybe<Array<Scalars['String']>>;
  data?: InputMaybe<Array<Scalars['String']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface IQueJobsDeleteElemInput {
  args?: InputMaybe<Scalars['Int']>;
  data?: InputMaybe<Scalars['Int']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface IQueJobsDeleteKeyInput {
  args?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<Scalars['String']>;
}

/** input type for incrementing numeric columns in table "que_jobs" */
export interface IQueJobsIncInput {
  error_count?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  priority?: InputMaybe<Scalars['smallint']>;
}

/** input type for inserting data into table "que_jobs" */
export interface IQueJobsInsertInput {
  args?: InputMaybe<Scalars['jsonb']>;
  data?: InputMaybe<Scalars['jsonb']>;
  error_count?: InputMaybe<Scalars['Int']>;
  expired_at?: InputMaybe<Scalars['timestamptz']>;
  finished_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  job_class?: InputMaybe<Scalars['String']>;
  last_error_backtrace?: InputMaybe<Scalars['String']>;
  last_error_message?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['smallint']>;
  queue?: InputMaybe<Scalars['String']>;
  run_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface IQueJobsMaxFields {
  __typename?: 'que_jobs_max_fields';
  error_count?: Maybe<Scalars['Int']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  job_class?: Maybe<Scalars['String']>;
  last_error_backtrace?: Maybe<Scalars['String']>;
  last_error_message?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['smallint']>;
  queue?: Maybe<Scalars['String']>;
  run_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface IQueJobsMinFields {
  __typename?: 'que_jobs_min_fields';
  error_count?: Maybe<Scalars['Int']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  job_class?: Maybe<Scalars['String']>;
  last_error_backtrace?: Maybe<Scalars['String']>;
  last_error_message?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['smallint']>;
  queue?: Maybe<Scalars['String']>;
  run_at?: Maybe<Scalars['timestamptz']>;
}

/** response of any mutation on the table "que_jobs" */
export interface IQueJobsMutationResponse {
  __typename?: 'que_jobs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IQueJobs>;
}

/** on_conflict condition type for table "que_jobs" */
export interface IQueJobsOnConflict {
  constraint: IQueJobsConstraint;
  update_columns?: Array<IQueJobsUpdateColumn>;
  where?: InputMaybe<IQueJobsBoolExp>;
}

/** Ordering options when selecting data from "que_jobs". */
export interface IQueJobsOrderBy {
  args?: InputMaybe<IOrderBy>;
  data?: InputMaybe<IOrderBy>;
  error_count?: InputMaybe<IOrderBy>;
  expired_at?: InputMaybe<IOrderBy>;
  finished_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  job_class?: InputMaybe<IOrderBy>;
  last_error_backtrace?: InputMaybe<IOrderBy>;
  last_error_message?: InputMaybe<IOrderBy>;
  priority?: InputMaybe<IOrderBy>;
  queue?: InputMaybe<IOrderBy>;
  run_at?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: que_jobs */
export interface IQueJobsPkColumnsInput {
  id: Scalars['bigint'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface IQueJobsPrependInput {
  args?: InputMaybe<Scalars['jsonb']>;
  data?: InputMaybe<Scalars['jsonb']>;
}

/** select columns of table "que_jobs" */
export enum IQueJobsSelectColumn {
  /** column name */
  ARGS = 'args',
  /** column name */
  DATA = 'data',
  /** column name */
  ERROR_COUNT = 'error_count',
  /** column name */
  EXPIRED_AT = 'expired_at',
  /** column name */
  FINISHED_AT = 'finished_at',
  /** column name */
  ID = 'id',
  /** column name */
  JOB_CLASS = 'job_class',
  /** column name */
  LAST_ERROR_BACKTRACE = 'last_error_backtrace',
  /** column name */
  LAST_ERROR_MESSAGE = 'last_error_message',
  /** column name */
  PRIORITY = 'priority',
  /** column name */
  QUEUE = 'queue',
  /** column name */
  RUN_AT = 'run_at'
}

/** input type for updating data in table "que_jobs" */
export interface IQueJobsSetInput {
  args?: InputMaybe<Scalars['jsonb']>;
  data?: InputMaybe<Scalars['jsonb']>;
  error_count?: InputMaybe<Scalars['Int']>;
  expired_at?: InputMaybe<Scalars['timestamptz']>;
  finished_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  job_class?: InputMaybe<Scalars['String']>;
  last_error_backtrace?: InputMaybe<Scalars['String']>;
  last_error_message?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['smallint']>;
  queue?: InputMaybe<Scalars['String']>;
  run_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface IQueJobsStddevFields {
  __typename?: 'que_jobs_stddev_fields';
  error_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IQueJobsStddevPopFields {
  __typename?: 'que_jobs_stddev_pop_fields';
  error_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IQueJobsStddevSampFields {
  __typename?: 'que_jobs_stddev_samp_fields';
  error_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IQueJobsSumFields {
  __typename?: 'que_jobs_sum_fields';
  error_count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  priority?: Maybe<Scalars['smallint']>;
}

/** update columns of table "que_jobs" */
export enum IQueJobsUpdateColumn {
  /** column name */
  ARGS = 'args',
  /** column name */
  DATA = 'data',
  /** column name */
  ERROR_COUNT = 'error_count',
  /** column name */
  EXPIRED_AT = 'expired_at',
  /** column name */
  FINISHED_AT = 'finished_at',
  /** column name */
  ID = 'id',
  /** column name */
  JOB_CLASS = 'job_class',
  /** column name */
  LAST_ERROR_BACKTRACE = 'last_error_backtrace',
  /** column name */
  LAST_ERROR_MESSAGE = 'last_error_message',
  /** column name */
  PRIORITY = 'priority',
  /** column name */
  QUEUE = 'queue',
  /** column name */
  RUN_AT = 'run_at'
}

/** aggregate var_pop on columns */
export interface IQueJobsVarPopFields {
  __typename?: 'que_jobs_var_pop_fields';
  error_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IQueJobsVarSampFields {
  __typename?: 'que_jobs_var_samp_fields';
  error_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IQueJobsVarianceFields {
  __typename?: 'que_jobs_variance_fields';
  error_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
}

export interface IQueryRoot {
  __typename?: 'query_root';
  /** fetch data from the table: "achievement_types" */
  achievement_types: Array<IAchievementTypes>;
  /** fetch aggregated fields from the table: "achievement_types" */
  achievement_types_aggregate: IAchievementTypesAggregate;
  /** fetch data from the table: "achievement_types" using primary key columns */
  achievement_types_by_pk?: Maybe<IAchievementTypes>;
  /** An array relationship */
  achievements: Array<IAchievements>;
  /** fetch data from the table: "achievements_accomplished" */
  achievements_accomplished: Array<IAchievementsAccomplished>;
  /** fetch aggregated fields from the table: "achievements_accomplished" */
  achievements_accomplished_aggregate: IAchievementsAccomplishedAggregate;
  /** fetch data from the table: "achievements_accomplished" using primary key columns */
  achievements_accomplished_by_pk?: Maybe<IAchievementsAccomplished>;
  /** An aggregate relationship */
  achievements_aggregate: IAchievementsAggregate;
  /** fetch data from the table: "achievements" using primary key columns */
  achievements_by_pk?: Maybe<IAchievements>;
  /** fetch data from the table: "achievements_view" */
  achievements_view: Array<IAchievementsView>;
  /** fetch aggregated fields from the table: "achievements_view" */
  achievements_view_aggregate: IAchievementsViewAggregate;
  /** fetch data from the table: "active_storage_attachments" */
  active_storage_attachments: Array<IActiveStorageAttachments>;
  /** fetch aggregated fields from the table: "active_storage_attachments" */
  active_storage_attachments_aggregate: IActiveStorageAttachmentsAggregate;
  /** fetch data from the table: "active_storage_attachments" using primary key columns */
  active_storage_attachments_by_pk?: Maybe<IActiveStorageAttachments>;
  /** fetch data from the table: "active_storage_blobs" */
  active_storage_blobs: Array<IActiveStorageBlobs>;
  /** fetch aggregated fields from the table: "active_storage_blobs" */
  active_storage_blobs_aggregate: IActiveStorageBlobsAggregate;
  /** fetch data from the table: "active_storage_blobs" using primary key columns */
  active_storage_blobs_by_pk?: Maybe<IActiveStorageBlobs>;
  /** fetch data from the table: "additional_lessons" */
  additional_lessons: Array<IAdditionalLessons>;
  /** fetch aggregated fields from the table: "additional_lessons" */
  additional_lessons_aggregate: IAdditionalLessonsAggregate;
  /** fetch data from the table: "additional_lessons" using primary key columns */
  additional_lessons_by_pk?: Maybe<IAdditionalLessons>;
  /** An array relationship */
  additional_lessons_group_types: Array<IAdditionalLessonsGroupTypes>;
  /** An aggregate relationship */
  additional_lessons_group_types_aggregate: IAdditionalLessonsGroupTypesAggregate;
  /** fetch data from the table: "additional_lessons_group_types" using primary key columns */
  additional_lessons_group_types_by_pk?: Maybe<IAdditionalLessonsGroupTypes>;
  /** fetch data from the table: "additional_lessons_view" */
  additional_lessons_view: Array<IAdditionalLessonsView>;
  /** fetch aggregated fields from the table: "additional_lessons_view" */
  additional_lessons_view_aggregate: IAdditionalLessonsViewAggregate;
  /** fetch data from the table: "ambassador_lessons_view" */
  ambassador_lessons_view: Array<IAmbassadorLessonsView>;
  /** fetch aggregated fields from the table: "ambassador_lessons_view" */
  ambassador_lessons_view_aggregate: IAmbassadorLessonsViewAggregate;
  /** fetch data from the table: "ambassadors" */
  ambassadors: Array<IAmbassadors>;
  /** fetch aggregated fields from the table: "ambassadors" */
  ambassadors_aggregate: IAmbassadorsAggregate;
  /** fetch data from the table: "ambassadors" using primary key columns */
  ambassadors_by_pk?: Maybe<IAmbassadors>;
  /** An array relationship */
  annual_schedule_attachments: Array<IAnnualScheduleAttachments>;
  /** An aggregate relationship */
  annual_schedule_attachments_aggregate: IAnnualScheduleAttachmentsAggregate;
  /** fetch data from the table: "annual_schedules" */
  annual_schedules: Array<IAnnualSchedules>;
  /** fetch aggregated fields from the table: "annual_schedules" */
  annual_schedules_aggregate: IAnnualSchedulesAggregate;
  /** fetch data from the table: "annual_schedules" using primary key columns */
  annual_schedules_by_pk?: Maybe<IAnnualSchedules>;
  /** An array relationship */
  answers: Array<IAnswers>;
  /** An aggregate relationship */
  answers_aggregate: IAnswersAggregate;
  /** fetch data from the table: "answers" using primary key columns */
  answers_by_pk?: Maybe<IAnswers>;
  /** fetch data from the table: "available_second_parts_view" */
  available_second_parts_view: Array<IAvailableSecondPartsView>;
  /** fetch aggregated fields from the table: "available_second_parts_view" */
  available_second_parts_view_aggregate: IAvailableSecondPartsViewAggregate;
  /** An array relationship */
  course_groups: Array<ICourseGroups>;
  /** An aggregate relationship */
  course_groups_aggregate: ICourseGroupsAggregate;
  /** fetch data from the table: "course_groups" using primary key columns */
  course_groups_by_pk?: Maybe<ICourseGroups>;
  /** fetch data from the table: "course_types" */
  course_types: Array<ICourseTypes>;
  /** fetch aggregated fields from the table: "course_types" */
  course_types_aggregate: ICourseTypesAggregate;
  /** fetch data from the table: "course_types" using primary key columns */
  course_types_by_pk?: Maybe<ICourseTypes>;
  /** fetch data from the table: "courses" */
  courses: Array<ICourses>;
  /** fetch aggregated fields from the table: "courses" */
  courses_aggregate: ICoursesAggregate;
  /** fetch data from the table: "courses" using primary key columns */
  courses_by_pk?: Maybe<ICourses>;
  /** fetch data from the table: "dictionary_words" */
  dictionary_words: Array<IDictionaryWords>;
  /** fetch aggregated fields from the table: "dictionary_words" */
  dictionary_words_aggregate: IDictionaryWordsAggregate;
  /** fetch data from the table: "dictionary_words" using primary key columns */
  dictionary_words_by_pk?: Maybe<IDictionaryWords>;
  /** fetch data from the table: "event_locations" */
  event_locations: Array<IEventLocations>;
  /** fetch aggregated fields from the table: "event_locations" */
  event_locations_aggregate: IEventLocationsAggregate;
  /** fetch data from the table: "event_locations" using primary key columns */
  event_locations_by_pk?: Maybe<IEventLocations>;
  /** An array relationship */
  event_registrations: Array<IEventRegistrations>;
  /** An aggregate relationship */
  event_registrations_aggregate: IEventRegistrationsAggregate;
  /** fetch data from the table: "event_registrations" using primary key columns */
  event_registrations_by_pk?: Maybe<IEventRegistrations>;
  /** fetch data from the table: "events" */
  events: Array<IEvents>;
  /** fetch aggregated fields from the table: "events" */
  events_aggregate: IEventsAggregate;
  /** fetch data from the table: "events" using primary key columns */
  events_by_pk?: Maybe<IEvents>;
  /** An array relationship */
  events_group_types: Array<IEventsGroupTypes>;
  /** An aggregate relationship */
  events_group_types_aggregate: IEventsGroupTypesAggregate;
  /** fetch data from the table: "events_group_types" using primary key columns */
  events_group_types_by_pk?: Maybe<IEventsGroupTypes>;
  /** fetch data from the table: "events_view" */
  events_view: Array<IEventsView>;
  /** fetch aggregated fields from the table: "events_view" */
  events_view_aggregate: IEventsViewAggregate;
  /** fetch data from the table: "favorite_lesson_moments" */
  favorite_lesson_moments: Array<IFavoriteLessonMoments>;
  /** fetch aggregated fields from the table: "favorite_lesson_moments" */
  favorite_lesson_moments_aggregate: IFavoriteLessonMomentsAggregate;
  /** fetch data from the table: "favorite_lesson_moments" using primary key columns */
  favorite_lesson_moments_by_pk?: Maybe<IFavoriteLessonMoments>;
  /** fetch data from the table: "final_test_questions" */
  final_test_questions: Array<IFinalTestQuestions>;
  /** fetch aggregated fields from the table: "final_test_questions" */
  final_test_questions_aggregate: IFinalTestQuestionsAggregate;
  /** fetch data from the table: "final_test_questions" using primary key columns */
  final_test_questions_by_pk?: Maybe<IFinalTestQuestions>;
  /** An array relationship */
  final_test_results: Array<IFinalTestResults>;
  /** An aggregate relationship */
  final_test_results_aggregate: IFinalTestResultsAggregate;
  /** fetch data from the table: "final_test_results" using primary key columns */
  final_test_results_by_pk?: Maybe<IFinalTestResults>;
  /** fetch data from the table: "final_tests_view" */
  final_tests_view: Array<IFinalTestsView>;
  /** fetch aggregated fields from the table: "final_tests_view" */
  final_tests_view_aggregate: IFinalTestsViewAggregate;
  /** fetch data from the table: "first_lesson_part_mail_reminders" */
  first_lesson_part_mail_reminders: Array<IFirstLessonPartMailReminders>;
  /** fetch aggregated fields from the table: "first_lesson_part_mail_reminders" */
  first_lesson_part_mail_reminders_aggregate: IFirstLessonPartMailRemindersAggregate;
  /** fetch data from the table: "friend_requests" */
  friend_requests: Array<IFriendRequests>;
  /** fetch aggregated fields from the table: "friend_requests" */
  friend_requests_aggregate: IFriendRequestsAggregate;
  /** fetch data from the table: "friend_requests" using primary key columns */
  friend_requests_by_pk?: Maybe<IFriendRequests>;
  /** fetch data from the table: "friend_requests_view" */
  friend_requests_view: Array<IFriendRequestsView>;
  /** fetch aggregated fields from the table: "friend_requests_view" */
  friend_requests_view_aggregate: IFriendRequestsViewAggregate;
  /** fetch data from the table: "friends_view" */
  friends_view: Array<IFriendsView>;
  /** fetch aggregated fields from the table: "friends_view" */
  friends_view_aggregate: IFriendsViewAggregate;
  /** fetch data from the table: "group_type_attachments" */
  group_type_attachments: Array<IGroupTypeAttachments>;
  /** fetch aggregated fields from the table: "group_type_attachments" */
  group_type_attachments_aggregate: IGroupTypeAttachmentsAggregate;
  /** fetch data from the table: "group_types" */
  group_types: Array<IGroupTypes>;
  /** fetch aggregated fields from the table: "group_types" */
  group_types_aggregate: IGroupTypesAggregate;
  /** fetch data from the table: "group_types" using primary key columns */
  group_types_by_pk?: Maybe<IGroupTypes>;
  /** fetch data from the table: "kids_maaida" */
  kids_maaida: Array<IKidsMaaida>;
  /** fetch aggregated fields from the table: "kids_maaida" */
  kids_maaida_aggregate: IKidsMaaidaAggregate;
  /** An array relationship */
  knowledge_test_questions: Array<IKnowledgeTestQuestions>;
  /** An aggregate relationship */
  knowledge_test_questions_aggregate: IKnowledgeTestQuestionsAggregate;
  /** fetch data from the table: "knowledge_test_questions" using primary key columns */
  knowledge_test_questions_by_pk?: Maybe<IKnowledgeTestQuestions>;
  /** fetch data from the table: "knowledge_tests" */
  knowledge_tests: Array<IKnowledgeTests>;
  /** fetch aggregated fields from the table: "knowledge_tests" */
  knowledge_tests_aggregate: IKnowledgeTestsAggregate;
  /** fetch data from the table: "knowledge_tests" using primary key columns */
  knowledge_tests_by_pk?: Maybe<IKnowledgeTests>;
  /** fetch data from the table: "knowledge_tests_view" */
  knowledge_tests_view: Array<IKnowledgeTestsView>;
  /** fetch aggregated fields from the table: "knowledge_tests_view" */
  knowledge_tests_view_aggregate: IKnowledgeTestsViewAggregate;
  /** fetch data from the table: "learning_assignment_attachments" */
  learning_assignment_attachments: Array<ILearningAssignmentAttachments>;
  /** fetch aggregated fields from the table: "learning_assignment_attachments" */
  learning_assignment_attachments_aggregate: ILearningAssignmentAttachmentsAggregate;
  /** fetch data from the table: "learning_assignments" */
  learning_assignments: Array<ILearningAssignments>;
  /** fetch aggregated fields from the table: "learning_assignments" */
  learning_assignments_aggregate: ILearningAssignmentsAggregate;
  /** fetch data from the table: "learning_assignments" using primary key columns */
  learning_assignments_by_pk?: Maybe<ILearningAssignments>;
  /** fetch data from the table: "learning_assignments_view" */
  learning_assignments_view: Array<ILearningAssignmentsView>;
  /** fetch aggregated fields from the table: "learning_assignments_view" */
  learning_assignments_view_aggregate: ILearningAssignmentsViewAggregate;
  /** fetch data from the table: "lesson_attachments" */
  lesson_attachments: Array<ILessonAttachments>;
  /** fetch aggregated fields from the table: "lesson_attachments" */
  lesson_attachments_aggregate: ILessonAttachmentsAggregate;
  /** An array relationship */
  lesson_days: Array<ILessonDays>;
  /** An aggregate relationship */
  lesson_days_aggregate: ILessonDaysAggregate;
  /** fetch data from the table: "lesson_days" using primary key columns */
  lesson_days_by_pk?: Maybe<ILessonDays>;
  /** fetch data from the table: "lesson_messages" */
  lesson_messages: Array<ILessonMessages>;
  /** fetch aggregated fields from the table: "lesson_messages" */
  lesson_messages_aggregate: ILessonMessagesAggregate;
  /** fetch data from the table: "lesson_messages" using primary key columns */
  lesson_messages_by_pk?: Maybe<ILessonMessages>;
  /** An array relationship */
  lesson_partitions: Array<ILessonPartitions>;
  /** An aggregate relationship */
  lesson_partitions_aggregate: ILessonPartitionsAggregate;
  /** fetch data from the table: "lesson_partitions" using primary key columns */
  lesson_partitions_by_pk?: Maybe<ILessonPartitions>;
  /** fetch data from the table: "lesson_reactions" */
  lesson_reactions: Array<ILessonReactions>;
  /** fetch aggregated fields from the table: "lesson_reactions" */
  lesson_reactions_aggregate: ILessonReactionsAggregate;
  /** fetch data from the table: "lesson_reactions" using primary key columns */
  lesson_reactions_by_pk?: Maybe<ILessonReactions>;
  /** fetch data from the table: "lesson_reminders" */
  lesson_reminders: Array<ILessonReminders>;
  /** fetch aggregated fields from the table: "lesson_reminders" */
  lesson_reminders_aggregate: ILessonRemindersAggregate;
  /** fetch data from the table: "lesson_second_parts_view" */
  lesson_second_parts_view: Array<ILessonSecondPartsView>;
  /** fetch aggregated fields from the table: "lesson_second_parts_view" */
  lesson_second_parts_view_aggregate: ILessonSecondPartsViewAggregate;
  /** fetch data from the table: "lesson_third_parts_view" */
  lesson_third_parts_view: Array<ILessonThirdPartsView>;
  /** fetch aggregated fields from the table: "lesson_third_parts_view" */
  lesson_third_parts_view_aggregate: ILessonThirdPartsViewAggregate;
  /** fetch data from the table: "lesson_unlock_keys" */
  lesson_unlock_keys: Array<ILessonUnlockKeys>;
  /** fetch aggregated fields from the table: "lesson_unlock_keys" */
  lesson_unlock_keys_aggregate: ILessonUnlockKeysAggregate;
  /** fetch data from the table: "lesson_unlock_keys" using primary key columns */
  lesson_unlock_keys_by_pk?: Maybe<ILessonUnlockKeys>;
  /** An array relationship */
  lesson_verses: Array<ILessonVerses>;
  /** An aggregate relationship */
  lesson_verses_aggregate: ILessonVersesAggregate;
  /** fetch data from the table: "lesson_verses_attachments" */
  lesson_verses_attachments: Array<ILessonVersesAttachments>;
  /** fetch aggregated fields from the table: "lesson_verses_attachments" */
  lesson_verses_attachments_aggregate: ILessonVersesAttachmentsAggregate;
  /** fetch data from the table: "lesson_verses" using primary key columns */
  lesson_verses_by_pk?: Maybe<ILessonVerses>;
  /** An array relationship */
  lesson_words: Array<ILessonWords>;
  /** An aggregate relationship */
  lesson_words_aggregate: ILessonWordsAggregate;
  /** fetch data from the table: "lesson_words" using primary key columns */
  lesson_words_by_pk?: Maybe<ILessonWords>;
  /** An array relationship */
  lessons: Array<ILessons>;
  /** An aggregate relationship */
  lessons_aggregate: ILessonsAggregate;
  /** fetch data from the table: "lessons" using primary key columns */
  lessons_by_pk?: Maybe<ILessons>;
  /** fetch data from the table: "lessons_unlocked" */
  lessons_unlocked: Array<ILessonsUnlocked>;
  /** fetch aggregated fields from the table: "lessons_unlocked" */
  lessons_unlocked_aggregate: ILessonsUnlockedAggregate;
  /** fetch data from the table: "lessons_unlocked" using primary key columns */
  lessons_unlocked_by_pk?: Maybe<ILessonsUnlocked>;
  /** An array relationship */
  lessons_view: Array<ILessonsView>;
  /** An aggregate relationship */
  lessons_view_aggregate: ILessonsViewAggregate;
  /** An array relationship */
  lessons_with_lock: Array<ILessonsWithLock>;
  /** An aggregate relationship */
  lessons_with_lock_aggregate: ILessonsWithLockAggregate;
  /** fetch data from the table: "lessons_with_progress" */
  lessons_with_progress: Array<ILessonsWithProgress>;
  /** fetch aggregated fields from the table: "lessons_with_progress" */
  lessons_with_progress_aggregate: ILessonsWithProgressAggregate;
  /** fetch data from the table: "library_downloads" */
  library_downloads: Array<ILibraryDownloads>;
  /** fetch aggregated fields from the table: "library_downloads" */
  library_downloads_aggregate: ILibraryDownloadsAggregate;
  /** fetch data from the table: "library_downloads_attachments" */
  library_downloads_attachments: Array<ILibraryDownloadsAttachments>;
  /** fetch aggregated fields from the table: "library_downloads_attachments" */
  library_downloads_attachments_aggregate: ILibraryDownloadsAttachmentsAggregate;
  /** fetch data from the table: "library_downloads" using primary key columns */
  library_downloads_by_pk?: Maybe<ILibraryDownloads>;
  /** fetch data from the table: "message_types" */
  message_types: Array<IMessageTypes>;
  /** fetch aggregated fields from the table: "message_types" */
  message_types_aggregate: IMessageTypesAggregate;
  /** fetch data from the table: "message_types" using primary key columns */
  message_types_by_pk?: Maybe<IMessageTypes>;
  /** fetch data from the table: "messages" */
  messages: Array<IMessages>;
  /** fetch aggregated fields from the table: "messages" */
  messages_aggregate: IMessagesAggregate;
  /** fetch data from the table: "messages" using primary key columns */
  messages_by_pk?: Maybe<IMessages>;
  /** fetch data from the table: "missed_lessons_view" */
  missed_lessons_view: Array<IMissedLessonsView>;
  /** fetch aggregated fields from the table: "missed_lessons_view" */
  missed_lessons_view_aggregate: IMissedLessonsViewAggregate;
  /** fetch data from the table: "missed_qa_view" */
  missed_qa_view: Array<IMissedQaView>;
  /** fetch aggregated fields from the table: "missed_qa_view" */
  missed_qa_view_aggregate: IMissedQaViewAggregate;
  /** fetch data from the table: "missed_second_part_view" */
  missed_second_part_view: Array<IMissedSecondPartView>;
  /** fetch aggregated fields from the table: "missed_second_part_view" */
  missed_second_part_view_aggregate: IMissedSecondPartViewAggregate;
  /** fetch data from the table: "missed_third_part_view" */
  missed_third_part_view: Array<IMissedThirdPartView>;
  /** fetch aggregated fields from the table: "missed_third_part_view" */
  missed_third_part_view_aggregate: IMissedThirdPartViewAggregate;
  /** fetch data from the table: "news_articles" */
  news_articles: Array<INewsArticles>;
  /** fetch aggregated fields from the table: "news_articles" */
  news_articles_aggregate: INewsArticlesAggregate;
  /** An array relationship */
  news_articles_attachments: Array<INewsArticlesAttachments>;
  /** An aggregate relationship */
  news_articles_attachments_aggregate: INewsArticlesAttachmentsAggregate;
  /** fetch data from the table: "news_articles" using primary key columns */
  news_articles_by_pk?: Maybe<INewsArticles>;
  /** fetch data from the table: "news_articles_view" */
  news_articles_view: Array<INewsArticlesView>;
  /** fetch aggregated fields from the table: "news_articles_view" */
  news_articles_view_aggregate: INewsArticlesViewAggregate;
  /** An array relationship */
  notification_group_types: Array<INotificationGroupTypes>;
  /** An aggregate relationship */
  notification_group_types_aggregate: INotificationGroupTypesAggregate;
  /** fetch data from the table: "notification_group_types" using primary key columns */
  notification_group_types_by_pk?: Maybe<INotificationGroupTypes>;
  /** fetch data from the table: "notifications" */
  notifications: Array<INotifications>;
  /** fetch aggregated fields from the table: "notifications" */
  notifications_aggregate: INotificationsAggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<INotifications>;
  /** fetch data from the table: "personal_developments" */
  personal_developments: Array<IPersonalDevelopments>;
  /** fetch aggregated fields from the table: "personal_developments" */
  personal_developments_aggregate: IPersonalDevelopmentsAggregate;
  /** fetch data from the table: "personal_developments_attachments" */
  personal_developments_attachments: Array<IPersonalDevelopmentsAttachments>;
  /** fetch aggregated fields from the table: "personal_developments_attachments" */
  personal_developments_attachments_aggregate: IPersonalDevelopmentsAttachmentsAggregate;
  /** fetch data from the table: "personal_developments" using primary key columns */
  personal_developments_by_pk?: Maybe<IPersonalDevelopments>;
  /** fetch data from the table: "qa_videos" */
  qa_videos: Array<IQaVideos>;
  /** fetch aggregated fields from the table: "qa_videos" */
  qa_videos_aggregate: IQaVideosAggregate;
  /** fetch data from the table: "qa_videos" using primary key columns */
  qa_videos_by_pk?: Maybe<IQaVideos>;
  /** fetch data from the table: "que_jobs" */
  que_jobs: Array<IQueJobs>;
  /** fetch aggregated fields from the table: "que_jobs" */
  que_jobs_aggregate: IQueJobsAggregate;
  /** fetch data from the table: "que_jobs" using primary key columns */
  que_jobs_by_pk?: Maybe<IQueJobs>;
  /** An array relationship */
  questions: Array<IQuestions>;
  /** An aggregate relationship */
  questions_aggregate: IQuestionsAggregate;
  /** fetch data from the table: "questions" using primary key columns */
  questions_by_pk?: Maybe<IQuestions>;
  /** fetch data from the table: "reactions_grouped_by_all_parts" */
  reactions_grouped_by_all_parts: Array<IReactionsGroupedByAllParts>;
  /** fetch aggregated fields from the table: "reactions_grouped_by_all_parts" */
  reactions_grouped_by_all_parts_aggregate: IReactionsGroupedByAllPartsAggregate;
  /** fetch data from the table: "reactivation_subscriptions" */
  reactivation_subscriptions: Array<IReactivationSubscriptions>;
  /** fetch aggregated fields from the table: "reactivation_subscriptions" */
  reactivation_subscriptions_aggregate: IReactivationSubscriptionsAggregate;
  /** fetch data from the table: "recitations" */
  recitations: Array<IRecitations>;
  /** fetch aggregated fields from the table: "recitations" */
  recitations_aggregate: IRecitationsAggregate;
  /** fetch data from the table: "recitations_attachments" */
  recitations_attachments: Array<IRecitationsAttachments>;
  /** fetch aggregated fields from the table: "recitations_attachments" */
  recitations_attachments_aggregate: IRecitationsAttachmentsAggregate;
  /** fetch data from the table: "recitations" using primary key columns */
  recitations_by_pk?: Maybe<IRecitations>;
  /** fetch data from the table: "repeat_lesson_progresses" */
  repeat_lesson_progresses: Array<IRepeatLessonProgresses>;
  /** fetch aggregated fields from the table: "repeat_lesson_progresses" */
  repeat_lesson_progresses_aggregate: IRepeatLessonProgressesAggregate;
  /** fetch data from the table: "repeat_lesson_progresses" using primary key columns */
  repeat_lesson_progresses_by_pk?: Maybe<IRepeatLessonProgresses>;
  /** fetch data from the table: "repeat_lessons_view" */
  repeat_lessons_view: Array<IRepeatLessonsView>;
  /** fetch aggregated fields from the table: "repeat_lessons_view" */
  repeat_lessons_view_aggregate: IRepeatLessonsViewAggregate;
  /** fetch data from the table: "repeat_second_parts_progresses" */
  repeat_second_parts_progresses: Array<IRepeatSecondPartsProgresses>;
  /** fetch aggregated fields from the table: "repeat_second_parts_progresses" */
  repeat_second_parts_progresses_aggregate: IRepeatSecondPartsProgressesAggregate;
  /** fetch data from the table: "repeat_second_parts_progresses" using primary key columns */
  repeat_second_parts_progresses_by_pk?: Maybe<IRepeatSecondPartsProgresses>;
  /** fetch data from the table: "repeat_second_parts_view" */
  repeat_second_parts_view: Array<IRepeatSecondPartsView>;
  /** fetch aggregated fields from the table: "repeat_second_parts_view" */
  repeat_second_parts_view_aggregate: IRepeatSecondPartsViewAggregate;
  /** fetch data from the table: "repeat_third_parts_progresses" */
  repeat_third_parts_progresses: Array<IRepeatThirdPartsProgresses>;
  /** fetch aggregated fields from the table: "repeat_third_parts_progresses" */
  repeat_third_parts_progresses_aggregate: IRepeatThirdPartsProgressesAggregate;
  /** fetch data from the table: "repeat_third_parts_progresses" using primary key columns */
  repeat_third_parts_progresses_by_pk?: Maybe<IRepeatThirdPartsProgresses>;
  /** fetch data from the table: "repeat_third_parts_view" */
  repeat_third_parts_view: Array<IRepeatThirdPartsView>;
  /** fetch aggregated fields from the table: "repeat_third_parts_view" */
  repeat_third_parts_view_aggregate: IRepeatThirdPartsViewAggregate;
  /** fetch data from the table: "schema_migrations" */
  schema_migrations: Array<ISchemaMigrations>;
  /** fetch aggregated fields from the table: "schema_migrations" */
  schema_migrations_aggregate: ISchemaMigrationsAggregate;
  /** fetch data from the table: "schema_migrations" using primary key columns */
  schema_migrations_by_pk?: Maybe<ISchemaMigrations>;
  /** fetch data from the table: "second_lesson_part_mail_reminders" */
  second_lesson_part_mail_reminders: Array<ISecondLessonPartMailReminders>;
  /** fetch aggregated fields from the table: "second_lesson_part_mail_reminders" */
  second_lesson_part_mail_reminders_aggregate: ISecondLessonPartMailRemindersAggregate;
  /** An array relationship */
  semi_stream_lessons: Array<ISemiStreamLessons>;
  /** An aggregate relationship */
  semi_stream_lessons_aggregate: ISemiStreamLessonsAggregate;
  /** fetch data from the table: "semi_stream_second_parts_view" */
  semi_stream_second_parts_view: Array<ISemiStreamSecondPartsView>;
  /** fetch aggregated fields from the table: "semi_stream_second_parts_view" */
  semi_stream_second_parts_view_aggregate: ISemiStreamSecondPartsViewAggregate;
  /** fetch data from the table: "semi_stream_third_parts_view" */
  semi_stream_third_parts_view: Array<ISemiStreamThirdPartsView>;
  /** fetch aggregated fields from the table: "semi_stream_third_parts_view" */
  semi_stream_third_parts_view_aggregate: ISemiStreamThirdPartsViewAggregate;
  /** fetch data from the table: "stars_view" */
  stars_view: Array<IStarsView>;
  /** fetch aggregated fields from the table: "stars_view" */
  stars_view_aggregate: IStarsViewAggregate;
  /** fetch data from the table: "study_reminders" */
  study_reminders: Array<IStudyReminders>;
  /** fetch aggregated fields from the table: "study_reminders" */
  study_reminders_aggregate: IStudyRemindersAggregate;
  /** fetch data from the table: "subscription_configs" */
  subscription_configs: Array<ISubscriptionConfigs>;
  /** fetch aggregated fields from the table: "subscription_configs" */
  subscription_configs_aggregate: ISubscriptionConfigsAggregate;
  /** fetch data from the table: "subscription_configs" using primary key columns */
  subscription_configs_by_pk?: Maybe<ISubscriptionConfigs>;
  /** fetch data from the table: "subscription_end_reminders" */
  subscription_end_reminders: Array<ISubscriptionEndReminders>;
  /** fetch aggregated fields from the table: "subscription_end_reminders" */
  subscription_end_reminders_aggregate: ISubscriptionEndRemindersAggregate;
  /** fetch data from the table: "subscription_mail_reminders" */
  subscription_mail_reminders: Array<ISubscriptionMailReminders>;
  /** fetch aggregated fields from the table: "subscription_mail_reminders" */
  subscription_mail_reminders_aggregate: ISubscriptionMailRemindersAggregate;
  /** An array relationship */
  subscriptions: Array<ISubscriptions>;
  /** An aggregate relationship */
  subscriptions_aggregate: ISubscriptionsAggregate;
  /** fetch data from the table: "subscriptions" using primary key columns */
  subscriptions_by_pk?: Maybe<ISubscriptions>;
  /** fetch data from the table: "subscriptions_by_user" */
  subscriptions_by_user: Array<ISubscriptionsByUser>;
  /** fetch aggregated fields from the table: "subscriptions_by_user" */
  subscriptions_by_user_aggregate: ISubscriptionsByUserAggregate;
  /** An array relationship */
  support_topic_entries: Array<ISupportTopicEntries>;
  /** An aggregate relationship */
  support_topic_entries_aggregate: ISupportTopicEntriesAggregate;
  /** fetch data from the table: "support_topic_entries" using primary key columns */
  support_topic_entries_by_pk?: Maybe<ISupportTopicEntries>;
  /** fetch data from the table: "support_topics" */
  support_topics: Array<ISupportTopics>;
  /** fetch aggregated fields from the table: "support_topics" */
  support_topics_aggregate: ISupportTopicsAggregate;
  /** fetch data from the table: "support_topics" using primary key columns */
  support_topics_by_pk?: Maybe<ISupportTopics>;
  /** fetch data from the table: "suspensions" */
  suspensions: Array<ISuspensions>;
  /** fetch aggregated fields from the table: "suspensions" */
  suspensions_aggregate: ISuspensionsAggregate;
  /** fetch data from the table: "suspensions" using primary key columns */
  suspensions_by_pk?: Maybe<ISuspensions>;
  /** fetch data from the table: "tmp_lessons" */
  tmp_lessons: Array<ITmpLessons>;
  /** fetch aggregated fields from the table: "tmp_lessons" */
  tmp_lessons_aggregate: ITmpLessonsAggregate;
  /** fetch data from the table: "tmp_lessons" using primary key columns */
  tmp_lessons_by_pk?: Maybe<ITmpLessons>;
  /** fetch data from the table: "tmp_progress" */
  tmp_progress: Array<ITmpProgress>;
  /** fetch aggregated fields from the table: "tmp_progress" */
  tmp_progress_aggregate: ITmpProgressAggregate;
  /** fetch data from the table: "tmp_progress" using primary key columns */
  tmp_progress_by_pk?: Maybe<ITmpProgress>;
  /** fetch data from the table: "tmp_stream_lessons" */
  tmp_stream_lessons: Array<ITmpStreamLessons>;
  /** fetch aggregated fields from the table: "tmp_stream_lessons" */
  tmp_stream_lessons_aggregate: ITmpStreamLessonsAggregate;
  /** fetch data from the table: "unique_user_sessions" */
  unique_user_sessions: Array<IUniqueUserSessions>;
  /** fetch aggregated fields from the table: "unique_user_sessions" */
  unique_user_sessions_aggregate: IUniqueUserSessionsAggregate;
  /** fetch data from the table: "unlockables" */
  unlockables: Array<IUnlockables>;
  /** fetch aggregated fields from the table: "unlockables" */
  unlockables_aggregate: IUnlockablesAggregate;
  /** fetch data from the table: "unlockables" using primary key columns */
  unlockables_by_pk?: Maybe<IUnlockables>;
  /** An array relationship */
  user_children_unlockables: Array<IUserChildrenUnlockables>;
  /** An aggregate relationship */
  user_children_unlockables_aggregate: IUserChildrenUnlockablesAggregate;
  /** fetch data from the table: "user_children_unlockables" using primary key columns */
  user_children_unlockables_by_pk?: Maybe<IUserChildrenUnlockables>;
  /** fetch data from the table: "user_course_usage" */
  user_course_usage: Array<IUserCourseUsage>;
  /** fetch aggregated fields from the table: "user_course_usage" */
  user_course_usage_aggregate: IUserCourseUsageAggregate;
  /** fetch data from the table: "user_lesson_messages_view" */
  user_lesson_messages_view: Array<IUserLessonMessagesView>;
  /** fetch aggregated fields from the table: "user_lesson_messages_view" */
  user_lesson_messages_view_aggregate: IUserLessonMessagesViewAggregate;
  /** fetch data from the table: "user_lesson_parts_progresses" */
  user_lesson_parts_progresses: Array<IUserLessonPartsProgresses>;
  /** fetch aggregated fields from the table: "user_lesson_parts_progresses" */
  user_lesson_parts_progresses_aggregate: IUserLessonPartsProgressesAggregate;
  /** fetch data from the table: "user_lesson_parts_progresses" using primary key columns */
  user_lesson_parts_progresses_by_pk?: Maybe<IUserLessonPartsProgresses>;
  /** An array relationship */
  user_lesson_progresses: Array<IUserLessonProgresses>;
  /** An aggregate relationship */
  user_lesson_progresses_aggregate: IUserLessonProgressesAggregate;
  /** fetch data from the table: "user_lesson_progresses" using primary key columns */
  user_lesson_progresses_by_pk?: Maybe<IUserLessonProgresses>;
  /** fetch data from the table: "user_qa_progresses" */
  user_qa_progresses: Array<IUserQaProgresses>;
  /** fetch aggregated fields from the table: "user_qa_progresses" */
  user_qa_progresses_aggregate: IUserQaProgressesAggregate;
  /** fetch data from the table: "user_qa_progresses" using primary key columns */
  user_qa_progresses_by_pk?: Maybe<IUserQaProgresses>;
  /** fetch data from the table: "user_third_parts_progresses" */
  user_third_parts_progresses: Array<IUserThirdPartsProgresses>;
  /** fetch aggregated fields from the table: "user_third_parts_progresses" */
  user_third_parts_progresses_aggregate: IUserThirdPartsProgressesAggregate;
  /** fetch data from the table: "user_third_parts_progresses" using primary key columns */
  user_third_parts_progresses_by_pk?: Maybe<IUserThirdPartsProgresses>;
  /** fetch data from the table: "users" */
  users: Array<IUsers>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: IUsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<IUsers>;
  /** fetch data from the table: "users_child_details" */
  users_child_details: Array<IUsersChildDetails>;
  /** fetch aggregated fields from the table: "users_child_details" */
  users_child_details_aggregate: IUsersChildDetailsAggregate;
  /** fetch data from the table: "users_child_details" using primary key columns */
  users_child_details_by_pk?: Maybe<IUsersChildDetails>;
  /** An array relationship */
  users_children: Array<IUsersChildren>;
  /** An aggregate relationship */
  users_children_aggregate: IUsersChildrenAggregate;
  /** fetch data from the table: "users_children" using primary key columns */
  users_children_by_pk?: Maybe<IUsersChildren>;
  /** fetch data from the table: "users_sessions" */
  users_sessions: Array<IUsersSessions>;
  /** fetch aggregated fields from the table: "users_sessions" */
  users_sessions_aggregate: IUsersSessionsAggregate;
  /** fetch data from the table: "users_sessions" using primary key columns */
  users_sessions_by_pk?: Maybe<IUsersSessions>;
  /** An array relationship */
  vacation_group_types: Array<IVacationGroupTypes>;
  /** An aggregate relationship */
  vacation_group_types_aggregate: IVacationGroupTypesAggregate;
  /** fetch data from the table: "vacation_group_types" using primary key columns */
  vacation_group_types_by_pk?: Maybe<IVacationGroupTypes>;
  /** fetch data from the table: "vacations" */
  vacations: Array<IVacations>;
  /** fetch aggregated fields from the table: "vacations" */
  vacations_aggregate: IVacationsAggregate;
  /** fetch data from the table: "vacations" using primary key columns */
  vacations_by_pk?: Maybe<IVacations>;
  /** fetch data from the table: "weekly_facts" */
  weekly_facts: Array<IWeeklyFacts>;
  /** fetch aggregated fields from the table: "weekly_facts" */
  weekly_facts_aggregate: IWeeklyFactsAggregate;
  /** fetch data from the table: "weekly_facts" using primary key columns */
  weekly_facts_by_pk?: Maybe<IWeeklyFacts>;
  /** An array relationship */
  wp_orders: Array<IWpOrders>;
  /** An aggregate relationship */
  wp_orders_aggregate: IWpOrdersAggregate;
  /** fetch data from the table: "wp_orders" using primary key columns */
  wp_orders_by_pk?: Maybe<IWpOrders>;
  /** fetch data from the table: "wp_products" */
  wp_products: Array<IWpProducts>;
  /** fetch aggregated fields from the table: "wp_products" */
  wp_products_aggregate: IWpProductsAggregate;
  /** fetch data from the table: "wp_products" using primary key columns */
  wp_products_by_pk?: Maybe<IWpProducts>;
  /** An array relationship */
  wp_subscription_orders: Array<IWpSubscriptionOrders>;
  /** An aggregate relationship */
  wp_subscription_orders_aggregate: IWpSubscriptionOrdersAggregate;
  /** fetch data from the table: "wp_subscription_orders" using primary key columns */
  wp_subscription_orders_by_pk?: Maybe<IWpSubscriptionOrders>;
}


export interface IQueryRootAchievementTypesArgs {
  distinct_on?: InputMaybe<Array<IAchievementTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAchievementTypesOrderBy>>;
  where?: InputMaybe<IAchievementTypesBoolExp>;
}


export interface IQueryRootAchievementTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<IAchievementTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAchievementTypesOrderBy>>;
  where?: InputMaybe<IAchievementTypesBoolExp>;
}


export interface IQueryRootAchievementTypesByPkArgs {
  name: Scalars['String'];
}


export interface IQueryRootAchievementsArgs {
  distinct_on?: InputMaybe<Array<IAchievementsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAchievementsOrderBy>>;
  where?: InputMaybe<IAchievementsBoolExp>;
}


export interface IQueryRootAchievementsAccomplishedArgs {
  distinct_on?: InputMaybe<Array<IAchievementsAccomplishedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAchievementsAccomplishedOrderBy>>;
  where?: InputMaybe<IAchievementsAccomplishedBoolExp>;
}


export interface IQueryRootAchievementsAccomplishedAggregateArgs {
  distinct_on?: InputMaybe<Array<IAchievementsAccomplishedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAchievementsAccomplishedOrderBy>>;
  where?: InputMaybe<IAchievementsAccomplishedBoolExp>;
}


export interface IQueryRootAchievementsAccomplishedByPkArgs {
  achievement_id: Scalars['uuid'];
  user_child_id: Scalars['uuid'];
}


export interface IQueryRootAchievementsAggregateArgs {
  distinct_on?: InputMaybe<Array<IAchievementsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAchievementsOrderBy>>;
  where?: InputMaybe<IAchievementsBoolExp>;
}


export interface IQueryRootAchievementsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootAchievementsViewArgs {
  distinct_on?: InputMaybe<Array<IAchievementsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAchievementsViewOrderBy>>;
  where?: InputMaybe<IAchievementsViewBoolExp>;
}


export interface IQueryRootAchievementsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IAchievementsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAchievementsViewOrderBy>>;
  where?: InputMaybe<IAchievementsViewBoolExp>;
}


export interface IQueryRootActiveStorageAttachmentsArgs {
  distinct_on?: InputMaybe<Array<IActiveStorageAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IActiveStorageAttachmentsOrderBy>>;
  where?: InputMaybe<IActiveStorageAttachmentsBoolExp>;
}


export interface IQueryRootActiveStorageAttachmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<IActiveStorageAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IActiveStorageAttachmentsOrderBy>>;
  where?: InputMaybe<IActiveStorageAttachmentsBoolExp>;
}


export interface IQueryRootActiveStorageAttachmentsByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootActiveStorageBlobsArgs {
  distinct_on?: InputMaybe<Array<IActiveStorageBlobsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IActiveStorageBlobsOrderBy>>;
  where?: InputMaybe<IActiveStorageBlobsBoolExp>;
}


export interface IQueryRootActiveStorageBlobsAggregateArgs {
  distinct_on?: InputMaybe<Array<IActiveStorageBlobsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IActiveStorageBlobsOrderBy>>;
  where?: InputMaybe<IActiveStorageBlobsBoolExp>;
}


export interface IQueryRootActiveStorageBlobsByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootAdditionalLessonsArgs {
  distinct_on?: InputMaybe<Array<IAdditionalLessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAdditionalLessonsOrderBy>>;
  where?: InputMaybe<IAdditionalLessonsBoolExp>;
}


export interface IQueryRootAdditionalLessonsAggregateArgs {
  distinct_on?: InputMaybe<Array<IAdditionalLessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAdditionalLessonsOrderBy>>;
  where?: InputMaybe<IAdditionalLessonsBoolExp>;
}


export interface IQueryRootAdditionalLessonsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootAdditionalLessonsGroupTypesArgs {
  distinct_on?: InputMaybe<Array<IAdditionalLessonsGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAdditionalLessonsGroupTypesOrderBy>>;
  where?: InputMaybe<IAdditionalLessonsGroupTypesBoolExp>;
}


export interface IQueryRootAdditionalLessonsGroupTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<IAdditionalLessonsGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAdditionalLessonsGroupTypesOrderBy>>;
  where?: InputMaybe<IAdditionalLessonsGroupTypesBoolExp>;
}


export interface IQueryRootAdditionalLessonsGroupTypesByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootAdditionalLessonsViewArgs {
  distinct_on?: InputMaybe<Array<IAdditionalLessonsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAdditionalLessonsViewOrderBy>>;
  where?: InputMaybe<IAdditionalLessonsViewBoolExp>;
}


export interface IQueryRootAdditionalLessonsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IAdditionalLessonsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAdditionalLessonsViewOrderBy>>;
  where?: InputMaybe<IAdditionalLessonsViewBoolExp>;
}


export interface IQueryRootAmbassadorLessonsViewArgs {
  distinct_on?: InputMaybe<Array<IAmbassadorLessonsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAmbassadorLessonsViewOrderBy>>;
  where?: InputMaybe<IAmbassadorLessonsViewBoolExp>;
}


export interface IQueryRootAmbassadorLessonsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IAmbassadorLessonsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAmbassadorLessonsViewOrderBy>>;
  where?: InputMaybe<IAmbassadorLessonsViewBoolExp>;
}


export interface IQueryRootAmbassadorsArgs {
  distinct_on?: InputMaybe<Array<IAmbassadorsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAmbassadorsOrderBy>>;
  where?: InputMaybe<IAmbassadorsBoolExp>;
}


export interface IQueryRootAmbassadorsAggregateArgs {
  distinct_on?: InputMaybe<Array<IAmbassadorsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAmbassadorsOrderBy>>;
  where?: InputMaybe<IAmbassadorsBoolExp>;
}


export interface IQueryRootAmbassadorsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootAnnualScheduleAttachmentsArgs {
  distinct_on?: InputMaybe<Array<IAnnualScheduleAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAnnualScheduleAttachmentsOrderBy>>;
  where?: InputMaybe<IAnnualScheduleAttachmentsBoolExp>;
}


export interface IQueryRootAnnualScheduleAttachmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<IAnnualScheduleAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAnnualScheduleAttachmentsOrderBy>>;
  where?: InputMaybe<IAnnualScheduleAttachmentsBoolExp>;
}


export interface IQueryRootAnnualSchedulesArgs {
  distinct_on?: InputMaybe<Array<IAnnualSchedulesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAnnualSchedulesOrderBy>>;
  where?: InputMaybe<IAnnualSchedulesBoolExp>;
}


export interface IQueryRootAnnualSchedulesAggregateArgs {
  distinct_on?: InputMaybe<Array<IAnnualSchedulesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAnnualSchedulesOrderBy>>;
  where?: InputMaybe<IAnnualSchedulesBoolExp>;
}


export interface IQueryRootAnnualSchedulesByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootAnswersArgs {
  distinct_on?: InputMaybe<Array<IAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAnswersOrderBy>>;
  where?: InputMaybe<IAnswersBoolExp>;
}


export interface IQueryRootAnswersAggregateArgs {
  distinct_on?: InputMaybe<Array<IAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAnswersOrderBy>>;
  where?: InputMaybe<IAnswersBoolExp>;
}


export interface IQueryRootAnswersByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootAvailableSecondPartsViewArgs {
  distinct_on?: InputMaybe<Array<IAvailableSecondPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAvailableSecondPartsViewOrderBy>>;
  where?: InputMaybe<IAvailableSecondPartsViewBoolExp>;
}


export interface IQueryRootAvailableSecondPartsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IAvailableSecondPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAvailableSecondPartsViewOrderBy>>;
  where?: InputMaybe<IAvailableSecondPartsViewBoolExp>;
}


export interface IQueryRootCourseGroupsArgs {
  distinct_on?: InputMaybe<Array<ICourseGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ICourseGroupsOrderBy>>;
  where?: InputMaybe<ICourseGroupsBoolExp>;
}


export interface IQueryRootCourseGroupsAggregateArgs {
  distinct_on?: InputMaybe<Array<ICourseGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ICourseGroupsOrderBy>>;
  where?: InputMaybe<ICourseGroupsBoolExp>;
}


export interface IQueryRootCourseGroupsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootCourseTypesArgs {
  distinct_on?: InputMaybe<Array<ICourseTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ICourseTypesOrderBy>>;
  where?: InputMaybe<ICourseTypesBoolExp>;
}


export interface IQueryRootCourseTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<ICourseTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ICourseTypesOrderBy>>;
  where?: InputMaybe<ICourseTypesBoolExp>;
}


export interface IQueryRootCourseTypesByPkArgs {
  name: Scalars['String'];
}


export interface IQueryRootCoursesArgs {
  distinct_on?: InputMaybe<Array<ICoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ICoursesOrderBy>>;
  where?: InputMaybe<ICoursesBoolExp>;
}


export interface IQueryRootCoursesAggregateArgs {
  distinct_on?: InputMaybe<Array<ICoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ICoursesOrderBy>>;
  where?: InputMaybe<ICoursesBoolExp>;
}


export interface IQueryRootCoursesByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootDictionaryWordsArgs {
  distinct_on?: InputMaybe<Array<IDictionaryWordsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IDictionaryWordsOrderBy>>;
  where?: InputMaybe<IDictionaryWordsBoolExp>;
}


export interface IQueryRootDictionaryWordsAggregateArgs {
  distinct_on?: InputMaybe<Array<IDictionaryWordsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IDictionaryWordsOrderBy>>;
  where?: InputMaybe<IDictionaryWordsBoolExp>;
}


export interface IQueryRootDictionaryWordsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootEventLocationsArgs {
  distinct_on?: InputMaybe<Array<IEventLocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventLocationsOrderBy>>;
  where?: InputMaybe<IEventLocationsBoolExp>;
}


export interface IQueryRootEventLocationsAggregateArgs {
  distinct_on?: InputMaybe<Array<IEventLocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventLocationsOrderBy>>;
  where?: InputMaybe<IEventLocationsBoolExp>;
}


export interface IQueryRootEventLocationsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootEventRegistrationsArgs {
  distinct_on?: InputMaybe<Array<IEventRegistrationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventRegistrationsOrderBy>>;
  where?: InputMaybe<IEventRegistrationsBoolExp>;
}


export interface IQueryRootEventRegistrationsAggregateArgs {
  distinct_on?: InputMaybe<Array<IEventRegistrationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventRegistrationsOrderBy>>;
  where?: InputMaybe<IEventRegistrationsBoolExp>;
}


export interface IQueryRootEventRegistrationsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootEventsArgs {
  distinct_on?: InputMaybe<Array<IEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventsOrderBy>>;
  where?: InputMaybe<IEventsBoolExp>;
}


export interface IQueryRootEventsAggregateArgs {
  distinct_on?: InputMaybe<Array<IEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventsOrderBy>>;
  where?: InputMaybe<IEventsBoolExp>;
}


export interface IQueryRootEventsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootEventsGroupTypesArgs {
  distinct_on?: InputMaybe<Array<IEventsGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventsGroupTypesOrderBy>>;
  where?: InputMaybe<IEventsGroupTypesBoolExp>;
}


export interface IQueryRootEventsGroupTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<IEventsGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventsGroupTypesOrderBy>>;
  where?: InputMaybe<IEventsGroupTypesBoolExp>;
}


export interface IQueryRootEventsGroupTypesByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootEventsViewArgs {
  distinct_on?: InputMaybe<Array<IEventsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventsViewOrderBy>>;
  where?: InputMaybe<IEventsViewBoolExp>;
}


export interface IQueryRootEventsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IEventsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventsViewOrderBy>>;
  where?: InputMaybe<IEventsViewBoolExp>;
}


export interface IQueryRootFavoriteLessonMomentsArgs {
  distinct_on?: InputMaybe<Array<IFavoriteLessonMomentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFavoriteLessonMomentsOrderBy>>;
  where?: InputMaybe<IFavoriteLessonMomentsBoolExp>;
}


export interface IQueryRootFavoriteLessonMomentsAggregateArgs {
  distinct_on?: InputMaybe<Array<IFavoriteLessonMomentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFavoriteLessonMomentsOrderBy>>;
  where?: InputMaybe<IFavoriteLessonMomentsBoolExp>;
}


export interface IQueryRootFavoriteLessonMomentsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootFinalTestQuestionsArgs {
  distinct_on?: InputMaybe<Array<IFinalTestQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFinalTestQuestionsOrderBy>>;
  where?: InputMaybe<IFinalTestQuestionsBoolExp>;
}


export interface IQueryRootFinalTestQuestionsAggregateArgs {
  distinct_on?: InputMaybe<Array<IFinalTestQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFinalTestQuestionsOrderBy>>;
  where?: InputMaybe<IFinalTestQuestionsBoolExp>;
}


export interface IQueryRootFinalTestQuestionsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootFinalTestResultsArgs {
  distinct_on?: InputMaybe<Array<IFinalTestResultsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFinalTestResultsOrderBy>>;
  where?: InputMaybe<IFinalTestResultsBoolExp>;
}


export interface IQueryRootFinalTestResultsAggregateArgs {
  distinct_on?: InputMaybe<Array<IFinalTestResultsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFinalTestResultsOrderBy>>;
  where?: InputMaybe<IFinalTestResultsBoolExp>;
}


export interface IQueryRootFinalTestResultsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootFinalTestsViewArgs {
  distinct_on?: InputMaybe<Array<IFinalTestsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFinalTestsViewOrderBy>>;
  where?: InputMaybe<IFinalTestsViewBoolExp>;
}


export interface IQueryRootFinalTestsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IFinalTestsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFinalTestsViewOrderBy>>;
  where?: InputMaybe<IFinalTestsViewBoolExp>;
}


export interface IQueryRootFirstLessonPartMailRemindersArgs {
  distinct_on?: InputMaybe<Array<IFirstLessonPartMailRemindersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFirstLessonPartMailRemindersOrderBy>>;
  where?: InputMaybe<IFirstLessonPartMailRemindersBoolExp>;
}


export interface IQueryRootFirstLessonPartMailRemindersAggregateArgs {
  distinct_on?: InputMaybe<Array<IFirstLessonPartMailRemindersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFirstLessonPartMailRemindersOrderBy>>;
  where?: InputMaybe<IFirstLessonPartMailRemindersBoolExp>;
}


export interface IQueryRootFriendRequestsArgs {
  distinct_on?: InputMaybe<Array<IFriendRequestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFriendRequestsOrderBy>>;
  where?: InputMaybe<IFriendRequestsBoolExp>;
}


export interface IQueryRootFriendRequestsAggregateArgs {
  distinct_on?: InputMaybe<Array<IFriendRequestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFriendRequestsOrderBy>>;
  where?: InputMaybe<IFriendRequestsBoolExp>;
}


export interface IQueryRootFriendRequestsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootFriendRequestsViewArgs {
  distinct_on?: InputMaybe<Array<IFriendRequestsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFriendRequestsViewOrderBy>>;
  where?: InputMaybe<IFriendRequestsViewBoolExp>;
}


export interface IQueryRootFriendRequestsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IFriendRequestsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFriendRequestsViewOrderBy>>;
  where?: InputMaybe<IFriendRequestsViewBoolExp>;
}


export interface IQueryRootFriendsViewArgs {
  distinct_on?: InputMaybe<Array<IFriendsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFriendsViewOrderBy>>;
  where?: InputMaybe<IFriendsViewBoolExp>;
}


export interface IQueryRootFriendsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IFriendsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFriendsViewOrderBy>>;
  where?: InputMaybe<IFriendsViewBoolExp>;
}


export interface IQueryRootGroupTypeAttachmentsArgs {
  distinct_on?: InputMaybe<Array<IGroupTypeAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IGroupTypeAttachmentsOrderBy>>;
  where?: InputMaybe<IGroupTypeAttachmentsBoolExp>;
}


export interface IQueryRootGroupTypeAttachmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<IGroupTypeAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IGroupTypeAttachmentsOrderBy>>;
  where?: InputMaybe<IGroupTypeAttachmentsBoolExp>;
}


export interface IQueryRootGroupTypesArgs {
  distinct_on?: InputMaybe<Array<IGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IGroupTypesOrderBy>>;
  where?: InputMaybe<IGroupTypesBoolExp>;
}


export interface IQueryRootGroupTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<IGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IGroupTypesOrderBy>>;
  where?: InputMaybe<IGroupTypesBoolExp>;
}


export interface IQueryRootGroupTypesByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootKidsMaaidaArgs {
  distinct_on?: InputMaybe<Array<IKidsMaaidaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IKidsMaaidaOrderBy>>;
  where?: InputMaybe<IKidsMaaidaBoolExp>;
}


export interface IQueryRootKidsMaaidaAggregateArgs {
  distinct_on?: InputMaybe<Array<IKidsMaaidaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IKidsMaaidaOrderBy>>;
  where?: InputMaybe<IKidsMaaidaBoolExp>;
}


export interface IQueryRootKnowledgeTestQuestionsArgs {
  distinct_on?: InputMaybe<Array<IKnowledgeTestQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IKnowledgeTestQuestionsOrderBy>>;
  where?: InputMaybe<IKnowledgeTestQuestionsBoolExp>;
}


export interface IQueryRootKnowledgeTestQuestionsAggregateArgs {
  distinct_on?: InputMaybe<Array<IKnowledgeTestQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IKnowledgeTestQuestionsOrderBy>>;
  where?: InputMaybe<IKnowledgeTestQuestionsBoolExp>;
}


export interface IQueryRootKnowledgeTestQuestionsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootKnowledgeTestsArgs {
  distinct_on?: InputMaybe<Array<IKnowledgeTestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IKnowledgeTestsOrderBy>>;
  where?: InputMaybe<IKnowledgeTestsBoolExp>;
}


export interface IQueryRootKnowledgeTestsAggregateArgs {
  distinct_on?: InputMaybe<Array<IKnowledgeTestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IKnowledgeTestsOrderBy>>;
  where?: InputMaybe<IKnowledgeTestsBoolExp>;
}


export interface IQueryRootKnowledgeTestsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootKnowledgeTestsViewArgs {
  distinct_on?: InputMaybe<Array<IKnowledgeTestsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IKnowledgeTestsViewOrderBy>>;
  where?: InputMaybe<IKnowledgeTestsViewBoolExp>;
}


export interface IQueryRootKnowledgeTestsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IKnowledgeTestsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IKnowledgeTestsViewOrderBy>>;
  where?: InputMaybe<IKnowledgeTestsViewBoolExp>;
}


export interface IQueryRootLearningAssignmentAttachmentsArgs {
  distinct_on?: InputMaybe<Array<ILearningAssignmentAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILearningAssignmentAttachmentsOrderBy>>;
  where?: InputMaybe<ILearningAssignmentAttachmentsBoolExp>;
}


export interface IQueryRootLearningAssignmentAttachmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILearningAssignmentAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILearningAssignmentAttachmentsOrderBy>>;
  where?: InputMaybe<ILearningAssignmentAttachmentsBoolExp>;
}


export interface IQueryRootLearningAssignmentsArgs {
  distinct_on?: InputMaybe<Array<ILearningAssignmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILearningAssignmentsOrderBy>>;
  where?: InputMaybe<ILearningAssignmentsBoolExp>;
}


export interface IQueryRootLearningAssignmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILearningAssignmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILearningAssignmentsOrderBy>>;
  where?: InputMaybe<ILearningAssignmentsBoolExp>;
}


export interface IQueryRootLearningAssignmentsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootLearningAssignmentsViewArgs {
  distinct_on?: InputMaybe<Array<ILearningAssignmentsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILearningAssignmentsViewOrderBy>>;
  where?: InputMaybe<ILearningAssignmentsViewBoolExp>;
}


export interface IQueryRootLearningAssignmentsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<ILearningAssignmentsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILearningAssignmentsViewOrderBy>>;
  where?: InputMaybe<ILearningAssignmentsViewBoolExp>;
}


export interface IQueryRootLessonAttachmentsArgs {
  distinct_on?: InputMaybe<Array<ILessonAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonAttachmentsOrderBy>>;
  where?: InputMaybe<ILessonAttachmentsBoolExp>;
}


export interface IQueryRootLessonAttachmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonAttachmentsOrderBy>>;
  where?: InputMaybe<ILessonAttachmentsBoolExp>;
}


export interface IQueryRootLessonDaysArgs {
  distinct_on?: InputMaybe<Array<ILessonDaysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonDaysOrderBy>>;
  where?: InputMaybe<ILessonDaysBoolExp>;
}


export interface IQueryRootLessonDaysAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonDaysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonDaysOrderBy>>;
  where?: InputMaybe<ILessonDaysBoolExp>;
}


export interface IQueryRootLessonDaysByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootLessonMessagesArgs {
  distinct_on?: InputMaybe<Array<ILessonMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonMessagesOrderBy>>;
  where?: InputMaybe<ILessonMessagesBoolExp>;
}


export interface IQueryRootLessonMessagesAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonMessagesOrderBy>>;
  where?: InputMaybe<ILessonMessagesBoolExp>;
}


export interface IQueryRootLessonMessagesByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootLessonPartitionsArgs {
  distinct_on?: InputMaybe<Array<ILessonPartitionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonPartitionsOrderBy>>;
  where?: InputMaybe<ILessonPartitionsBoolExp>;
}


export interface IQueryRootLessonPartitionsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonPartitionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonPartitionsOrderBy>>;
  where?: InputMaybe<ILessonPartitionsBoolExp>;
}


export interface IQueryRootLessonPartitionsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootLessonReactionsArgs {
  distinct_on?: InputMaybe<Array<ILessonReactionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonReactionsOrderBy>>;
  where?: InputMaybe<ILessonReactionsBoolExp>;
}


export interface IQueryRootLessonReactionsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonReactionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonReactionsOrderBy>>;
  where?: InputMaybe<ILessonReactionsBoolExp>;
}


export interface IQueryRootLessonReactionsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootLessonRemindersArgs {
  distinct_on?: InputMaybe<Array<ILessonRemindersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonRemindersOrderBy>>;
  where?: InputMaybe<ILessonRemindersBoolExp>;
}


export interface IQueryRootLessonRemindersAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonRemindersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonRemindersOrderBy>>;
  where?: InputMaybe<ILessonRemindersBoolExp>;
}


export interface IQueryRootLessonSecondPartsViewArgs {
  distinct_on?: InputMaybe<Array<ILessonSecondPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonSecondPartsViewOrderBy>>;
  where?: InputMaybe<ILessonSecondPartsViewBoolExp>;
}


export interface IQueryRootLessonSecondPartsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonSecondPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonSecondPartsViewOrderBy>>;
  where?: InputMaybe<ILessonSecondPartsViewBoolExp>;
}


export interface IQueryRootLessonThirdPartsViewArgs {
  distinct_on?: InputMaybe<Array<ILessonThirdPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonThirdPartsViewOrderBy>>;
  where?: InputMaybe<ILessonThirdPartsViewBoolExp>;
}


export interface IQueryRootLessonThirdPartsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonThirdPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonThirdPartsViewOrderBy>>;
  where?: InputMaybe<ILessonThirdPartsViewBoolExp>;
}


export interface IQueryRootLessonUnlockKeysArgs {
  distinct_on?: InputMaybe<Array<ILessonUnlockKeysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonUnlockKeysOrderBy>>;
  where?: InputMaybe<ILessonUnlockKeysBoolExp>;
}


export interface IQueryRootLessonUnlockKeysAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonUnlockKeysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonUnlockKeysOrderBy>>;
  where?: InputMaybe<ILessonUnlockKeysBoolExp>;
}


export interface IQueryRootLessonUnlockKeysByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootLessonVersesArgs {
  distinct_on?: InputMaybe<Array<ILessonVersesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonVersesOrderBy>>;
  where?: InputMaybe<ILessonVersesBoolExp>;
}


export interface IQueryRootLessonVersesAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonVersesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonVersesOrderBy>>;
  where?: InputMaybe<ILessonVersesBoolExp>;
}


export interface IQueryRootLessonVersesAttachmentsArgs {
  distinct_on?: InputMaybe<Array<ILessonVersesAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonVersesAttachmentsOrderBy>>;
  where?: InputMaybe<ILessonVersesAttachmentsBoolExp>;
}


export interface IQueryRootLessonVersesAttachmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonVersesAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonVersesAttachmentsOrderBy>>;
  where?: InputMaybe<ILessonVersesAttachmentsBoolExp>;
}


export interface IQueryRootLessonVersesByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootLessonWordsArgs {
  distinct_on?: InputMaybe<Array<ILessonWordsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonWordsOrderBy>>;
  where?: InputMaybe<ILessonWordsBoolExp>;
}


export interface IQueryRootLessonWordsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonWordsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonWordsOrderBy>>;
  where?: InputMaybe<ILessonWordsBoolExp>;
}


export interface IQueryRootLessonWordsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootLessonsArgs {
  distinct_on?: InputMaybe<Array<ILessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsOrderBy>>;
  where?: InputMaybe<ILessonsBoolExp>;
}


export interface IQueryRootLessonsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsOrderBy>>;
  where?: InputMaybe<ILessonsBoolExp>;
}


export interface IQueryRootLessonsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootLessonsUnlockedArgs {
  distinct_on?: InputMaybe<Array<ILessonsUnlockedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsUnlockedOrderBy>>;
  where?: InputMaybe<ILessonsUnlockedBoolExp>;
}


export interface IQueryRootLessonsUnlockedAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonsUnlockedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsUnlockedOrderBy>>;
  where?: InputMaybe<ILessonsUnlockedBoolExp>;
}


export interface IQueryRootLessonsUnlockedByPkArgs {
  lesson_id: Scalars['uuid'];
  user_child_id: Scalars['uuid'];
}


export interface IQueryRootLessonsViewArgs {
  distinct_on?: InputMaybe<Array<ILessonsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsViewOrderBy>>;
  where?: InputMaybe<ILessonsViewBoolExp>;
}


export interface IQueryRootLessonsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsViewOrderBy>>;
  where?: InputMaybe<ILessonsViewBoolExp>;
}


export interface IQueryRootLessonsWithLockArgs {
  distinct_on?: InputMaybe<Array<ILessonsWithLockSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsWithLockOrderBy>>;
  where?: InputMaybe<ILessonsWithLockBoolExp>;
}


export interface IQueryRootLessonsWithLockAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonsWithLockSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsWithLockOrderBy>>;
  where?: InputMaybe<ILessonsWithLockBoolExp>;
}


export interface IQueryRootLessonsWithProgressArgs {
  distinct_on?: InputMaybe<Array<ILessonsWithProgressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsWithProgressOrderBy>>;
  where?: InputMaybe<ILessonsWithProgressBoolExp>;
}


export interface IQueryRootLessonsWithProgressAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonsWithProgressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsWithProgressOrderBy>>;
  where?: InputMaybe<ILessonsWithProgressBoolExp>;
}


export interface IQueryRootLibraryDownloadsArgs {
  distinct_on?: InputMaybe<Array<ILibraryDownloadsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILibraryDownloadsOrderBy>>;
  where?: InputMaybe<ILibraryDownloadsBoolExp>;
}


export interface IQueryRootLibraryDownloadsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILibraryDownloadsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILibraryDownloadsOrderBy>>;
  where?: InputMaybe<ILibraryDownloadsBoolExp>;
}


export interface IQueryRootLibraryDownloadsAttachmentsArgs {
  distinct_on?: InputMaybe<Array<ILibraryDownloadsAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILibraryDownloadsAttachmentsOrderBy>>;
  where?: InputMaybe<ILibraryDownloadsAttachmentsBoolExp>;
}


export interface IQueryRootLibraryDownloadsAttachmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILibraryDownloadsAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILibraryDownloadsAttachmentsOrderBy>>;
  where?: InputMaybe<ILibraryDownloadsAttachmentsBoolExp>;
}


export interface IQueryRootLibraryDownloadsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootMessageTypesArgs {
  distinct_on?: InputMaybe<Array<IMessageTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IMessageTypesOrderBy>>;
  where?: InputMaybe<IMessageTypesBoolExp>;
}


export interface IQueryRootMessageTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<IMessageTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IMessageTypesOrderBy>>;
  where?: InputMaybe<IMessageTypesBoolExp>;
}


export interface IQueryRootMessageTypesByPkArgs {
  name: Scalars['String'];
}


export interface IQueryRootMessagesArgs {
  distinct_on?: InputMaybe<Array<IMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IMessagesOrderBy>>;
  where?: InputMaybe<IMessagesBoolExp>;
}


export interface IQueryRootMessagesAggregateArgs {
  distinct_on?: InputMaybe<Array<IMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IMessagesOrderBy>>;
  where?: InputMaybe<IMessagesBoolExp>;
}


export interface IQueryRootMessagesByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootMissedLessonsViewArgs {
  distinct_on?: InputMaybe<Array<IMissedLessonsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IMissedLessonsViewOrderBy>>;
  where?: InputMaybe<IMissedLessonsViewBoolExp>;
}


export interface IQueryRootMissedLessonsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IMissedLessonsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IMissedLessonsViewOrderBy>>;
  where?: InputMaybe<IMissedLessonsViewBoolExp>;
}


export interface IQueryRootMissedQaViewArgs {
  distinct_on?: InputMaybe<Array<IMissedQaViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IMissedQaViewOrderBy>>;
  where?: InputMaybe<IMissedQaViewBoolExp>;
}


export interface IQueryRootMissedQaViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IMissedQaViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IMissedQaViewOrderBy>>;
  where?: InputMaybe<IMissedQaViewBoolExp>;
}


export interface IQueryRootMissedSecondPartViewArgs {
  distinct_on?: InputMaybe<Array<IMissedSecondPartViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IMissedSecondPartViewOrderBy>>;
  where?: InputMaybe<IMissedSecondPartViewBoolExp>;
}


export interface IQueryRootMissedSecondPartViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IMissedSecondPartViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IMissedSecondPartViewOrderBy>>;
  where?: InputMaybe<IMissedSecondPartViewBoolExp>;
}


export interface IQueryRootMissedThirdPartViewArgs {
  distinct_on?: InputMaybe<Array<IMissedThirdPartViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IMissedThirdPartViewOrderBy>>;
  where?: InputMaybe<IMissedThirdPartViewBoolExp>;
}


export interface IQueryRootMissedThirdPartViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IMissedThirdPartViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IMissedThirdPartViewOrderBy>>;
  where?: InputMaybe<IMissedThirdPartViewBoolExp>;
}


export interface IQueryRootNewsArticlesArgs {
  distinct_on?: InputMaybe<Array<INewsArticlesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INewsArticlesOrderBy>>;
  where?: InputMaybe<INewsArticlesBoolExp>;
}


export interface IQueryRootNewsArticlesAggregateArgs {
  distinct_on?: InputMaybe<Array<INewsArticlesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INewsArticlesOrderBy>>;
  where?: InputMaybe<INewsArticlesBoolExp>;
}


export interface IQueryRootNewsArticlesAttachmentsArgs {
  distinct_on?: InputMaybe<Array<INewsArticlesAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INewsArticlesAttachmentsOrderBy>>;
  where?: InputMaybe<INewsArticlesAttachmentsBoolExp>;
}


export interface IQueryRootNewsArticlesAttachmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<INewsArticlesAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INewsArticlesAttachmentsOrderBy>>;
  where?: InputMaybe<INewsArticlesAttachmentsBoolExp>;
}


export interface IQueryRootNewsArticlesByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootNewsArticlesViewArgs {
  distinct_on?: InputMaybe<Array<INewsArticlesViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INewsArticlesViewOrderBy>>;
  where?: InputMaybe<INewsArticlesViewBoolExp>;
}


export interface IQueryRootNewsArticlesViewAggregateArgs {
  distinct_on?: InputMaybe<Array<INewsArticlesViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INewsArticlesViewOrderBy>>;
  where?: InputMaybe<INewsArticlesViewBoolExp>;
}


export interface IQueryRootNotificationGroupTypesArgs {
  distinct_on?: InputMaybe<Array<INotificationGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INotificationGroupTypesOrderBy>>;
  where?: InputMaybe<INotificationGroupTypesBoolExp>;
}


export interface IQueryRootNotificationGroupTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<INotificationGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INotificationGroupTypesOrderBy>>;
  where?: InputMaybe<INotificationGroupTypesBoolExp>;
}


export interface IQueryRootNotificationGroupTypesByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootNotificationsArgs {
  distinct_on?: InputMaybe<Array<INotificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INotificationsOrderBy>>;
  where?: InputMaybe<INotificationsBoolExp>;
}


export interface IQueryRootNotificationsAggregateArgs {
  distinct_on?: InputMaybe<Array<INotificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INotificationsOrderBy>>;
  where?: InputMaybe<INotificationsBoolExp>;
}


export interface IQueryRootNotificationsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootPersonalDevelopmentsArgs {
  distinct_on?: InputMaybe<Array<IPersonalDevelopmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IPersonalDevelopmentsOrderBy>>;
  where?: InputMaybe<IPersonalDevelopmentsBoolExp>;
}


export interface IQueryRootPersonalDevelopmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<IPersonalDevelopmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IPersonalDevelopmentsOrderBy>>;
  where?: InputMaybe<IPersonalDevelopmentsBoolExp>;
}


export interface IQueryRootPersonalDevelopmentsAttachmentsArgs {
  distinct_on?: InputMaybe<Array<IPersonalDevelopmentsAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IPersonalDevelopmentsAttachmentsOrderBy>>;
  where?: InputMaybe<IPersonalDevelopmentsAttachmentsBoolExp>;
}


export interface IQueryRootPersonalDevelopmentsAttachmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<IPersonalDevelopmentsAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IPersonalDevelopmentsAttachmentsOrderBy>>;
  where?: InputMaybe<IPersonalDevelopmentsAttachmentsBoolExp>;
}


export interface IQueryRootPersonalDevelopmentsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootQaVideosArgs {
  distinct_on?: InputMaybe<Array<IQaVideosSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IQaVideosOrderBy>>;
  where?: InputMaybe<IQaVideosBoolExp>;
}


export interface IQueryRootQaVideosAggregateArgs {
  distinct_on?: InputMaybe<Array<IQaVideosSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IQaVideosOrderBy>>;
  where?: InputMaybe<IQaVideosBoolExp>;
}


export interface IQueryRootQaVideosByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootQueJobsArgs {
  distinct_on?: InputMaybe<Array<IQueJobsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IQueJobsOrderBy>>;
  where?: InputMaybe<IQueJobsBoolExp>;
}


export interface IQueryRootQueJobsAggregateArgs {
  distinct_on?: InputMaybe<Array<IQueJobsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IQueJobsOrderBy>>;
  where?: InputMaybe<IQueJobsBoolExp>;
}


export interface IQueryRootQueJobsByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootQuestionsArgs {
  distinct_on?: InputMaybe<Array<IQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IQuestionsOrderBy>>;
  where?: InputMaybe<IQuestionsBoolExp>;
}


export interface IQueryRootQuestionsAggregateArgs {
  distinct_on?: InputMaybe<Array<IQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IQuestionsOrderBy>>;
  where?: InputMaybe<IQuestionsBoolExp>;
}


export interface IQueryRootQuestionsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootReactionsGroupedByAllPartsArgs {
  distinct_on?: InputMaybe<Array<IReactionsGroupedByAllPartsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IReactionsGroupedByAllPartsOrderBy>>;
  where?: InputMaybe<IReactionsGroupedByAllPartsBoolExp>;
}


export interface IQueryRootReactionsGroupedByAllPartsAggregateArgs {
  distinct_on?: InputMaybe<Array<IReactionsGroupedByAllPartsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IReactionsGroupedByAllPartsOrderBy>>;
  where?: InputMaybe<IReactionsGroupedByAllPartsBoolExp>;
}


export interface IQueryRootReactivationSubscriptionsArgs {
  distinct_on?: InputMaybe<Array<IReactivationSubscriptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IReactivationSubscriptionsOrderBy>>;
  where?: InputMaybe<IReactivationSubscriptionsBoolExp>;
}


export interface IQueryRootReactivationSubscriptionsAggregateArgs {
  distinct_on?: InputMaybe<Array<IReactivationSubscriptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IReactivationSubscriptionsOrderBy>>;
  where?: InputMaybe<IReactivationSubscriptionsBoolExp>;
}


export interface IQueryRootRecitationsArgs {
  distinct_on?: InputMaybe<Array<IRecitationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRecitationsOrderBy>>;
  where?: InputMaybe<IRecitationsBoolExp>;
}


export interface IQueryRootRecitationsAggregateArgs {
  distinct_on?: InputMaybe<Array<IRecitationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRecitationsOrderBy>>;
  where?: InputMaybe<IRecitationsBoolExp>;
}


export interface IQueryRootRecitationsAttachmentsArgs {
  distinct_on?: InputMaybe<Array<IRecitationsAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRecitationsAttachmentsOrderBy>>;
  where?: InputMaybe<IRecitationsAttachmentsBoolExp>;
}


export interface IQueryRootRecitationsAttachmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<IRecitationsAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRecitationsAttachmentsOrderBy>>;
  where?: InputMaybe<IRecitationsAttachmentsBoolExp>;
}


export interface IQueryRootRecitationsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootRepeatLessonProgressesArgs {
  distinct_on?: InputMaybe<Array<IRepeatLessonProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRepeatLessonProgressesOrderBy>>;
  where?: InputMaybe<IRepeatLessonProgressesBoolExp>;
}


export interface IQueryRootRepeatLessonProgressesAggregateArgs {
  distinct_on?: InputMaybe<Array<IRepeatLessonProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRepeatLessonProgressesOrderBy>>;
  where?: InputMaybe<IRepeatLessonProgressesBoolExp>;
}


export interface IQueryRootRepeatLessonProgressesByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootRepeatLessonsViewArgs {
  distinct_on?: InputMaybe<Array<IRepeatLessonsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRepeatLessonsViewOrderBy>>;
  where?: InputMaybe<IRepeatLessonsViewBoolExp>;
}


export interface IQueryRootRepeatLessonsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IRepeatLessonsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRepeatLessonsViewOrderBy>>;
  where?: InputMaybe<IRepeatLessonsViewBoolExp>;
}


export interface IQueryRootRepeatSecondPartsProgressesArgs {
  distinct_on?: InputMaybe<Array<IRepeatSecondPartsProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRepeatSecondPartsProgressesOrderBy>>;
  where?: InputMaybe<IRepeatSecondPartsProgressesBoolExp>;
}


export interface IQueryRootRepeatSecondPartsProgressesAggregateArgs {
  distinct_on?: InputMaybe<Array<IRepeatSecondPartsProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRepeatSecondPartsProgressesOrderBy>>;
  where?: InputMaybe<IRepeatSecondPartsProgressesBoolExp>;
}


export interface IQueryRootRepeatSecondPartsProgressesByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootRepeatSecondPartsViewArgs {
  distinct_on?: InputMaybe<Array<IRepeatSecondPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRepeatSecondPartsViewOrderBy>>;
  where?: InputMaybe<IRepeatSecondPartsViewBoolExp>;
}


export interface IQueryRootRepeatSecondPartsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IRepeatSecondPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRepeatSecondPartsViewOrderBy>>;
  where?: InputMaybe<IRepeatSecondPartsViewBoolExp>;
}


export interface IQueryRootRepeatThirdPartsProgressesArgs {
  distinct_on?: InputMaybe<Array<IRepeatThirdPartsProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRepeatThirdPartsProgressesOrderBy>>;
  where?: InputMaybe<IRepeatThirdPartsProgressesBoolExp>;
}


export interface IQueryRootRepeatThirdPartsProgressesAggregateArgs {
  distinct_on?: InputMaybe<Array<IRepeatThirdPartsProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRepeatThirdPartsProgressesOrderBy>>;
  where?: InputMaybe<IRepeatThirdPartsProgressesBoolExp>;
}


export interface IQueryRootRepeatThirdPartsProgressesByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootRepeatThirdPartsViewArgs {
  distinct_on?: InputMaybe<Array<IRepeatThirdPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRepeatThirdPartsViewOrderBy>>;
  where?: InputMaybe<IRepeatThirdPartsViewBoolExp>;
}


export interface IQueryRootRepeatThirdPartsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IRepeatThirdPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRepeatThirdPartsViewOrderBy>>;
  where?: InputMaybe<IRepeatThirdPartsViewBoolExp>;
}


export interface IQueryRootSchemaMigrationsArgs {
  distinct_on?: InputMaybe<Array<ISchemaMigrationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISchemaMigrationsOrderBy>>;
  where?: InputMaybe<ISchemaMigrationsBoolExp>;
}


export interface IQueryRootSchemaMigrationsAggregateArgs {
  distinct_on?: InputMaybe<Array<ISchemaMigrationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISchemaMigrationsOrderBy>>;
  where?: InputMaybe<ISchemaMigrationsBoolExp>;
}


export interface IQueryRootSchemaMigrationsByPkArgs {
  version: Scalars['String'];
}


export interface IQueryRootSecondLessonPartMailRemindersArgs {
  distinct_on?: InputMaybe<Array<ISecondLessonPartMailRemindersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISecondLessonPartMailRemindersOrderBy>>;
  where?: InputMaybe<ISecondLessonPartMailRemindersBoolExp>;
}


export interface IQueryRootSecondLessonPartMailRemindersAggregateArgs {
  distinct_on?: InputMaybe<Array<ISecondLessonPartMailRemindersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISecondLessonPartMailRemindersOrderBy>>;
  where?: InputMaybe<ISecondLessonPartMailRemindersBoolExp>;
}


export interface IQueryRootSemiStreamLessonsArgs {
  distinct_on?: InputMaybe<Array<ISemiStreamLessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISemiStreamLessonsOrderBy>>;
  where?: InputMaybe<ISemiStreamLessonsBoolExp>;
}


export interface IQueryRootSemiStreamLessonsAggregateArgs {
  distinct_on?: InputMaybe<Array<ISemiStreamLessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISemiStreamLessonsOrderBy>>;
  where?: InputMaybe<ISemiStreamLessonsBoolExp>;
}


export interface IQueryRootSemiStreamSecondPartsViewArgs {
  distinct_on?: InputMaybe<Array<ISemiStreamSecondPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISemiStreamSecondPartsViewOrderBy>>;
  where?: InputMaybe<ISemiStreamSecondPartsViewBoolExp>;
}


export interface IQueryRootSemiStreamSecondPartsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<ISemiStreamSecondPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISemiStreamSecondPartsViewOrderBy>>;
  where?: InputMaybe<ISemiStreamSecondPartsViewBoolExp>;
}


export interface IQueryRootSemiStreamThirdPartsViewArgs {
  distinct_on?: InputMaybe<Array<ISemiStreamThirdPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISemiStreamThirdPartsViewOrderBy>>;
  where?: InputMaybe<ISemiStreamThirdPartsViewBoolExp>;
}


export interface IQueryRootSemiStreamThirdPartsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<ISemiStreamThirdPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISemiStreamThirdPartsViewOrderBy>>;
  where?: InputMaybe<ISemiStreamThirdPartsViewBoolExp>;
}


export interface IQueryRootStarsViewArgs {
  distinct_on?: InputMaybe<Array<IStarsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IStarsViewOrderBy>>;
  where?: InputMaybe<IStarsViewBoolExp>;
}


export interface IQueryRootStarsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IStarsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IStarsViewOrderBy>>;
  where?: InputMaybe<IStarsViewBoolExp>;
}


export interface IQueryRootStudyRemindersArgs {
  distinct_on?: InputMaybe<Array<IStudyRemindersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IStudyRemindersOrderBy>>;
  where?: InputMaybe<IStudyRemindersBoolExp>;
}


export interface IQueryRootStudyRemindersAggregateArgs {
  distinct_on?: InputMaybe<Array<IStudyRemindersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IStudyRemindersOrderBy>>;
  where?: InputMaybe<IStudyRemindersBoolExp>;
}


export interface IQueryRootSubscriptionConfigsArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionConfigsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionConfigsOrderBy>>;
  where?: InputMaybe<ISubscriptionConfigsBoolExp>;
}


export interface IQueryRootSubscriptionConfigsAggregateArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionConfigsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionConfigsOrderBy>>;
  where?: InputMaybe<ISubscriptionConfigsBoolExp>;
}


export interface IQueryRootSubscriptionConfigsByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootSubscriptionEndRemindersArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionEndRemindersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionEndRemindersOrderBy>>;
  where?: InputMaybe<ISubscriptionEndRemindersBoolExp>;
}


export interface IQueryRootSubscriptionEndRemindersAggregateArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionEndRemindersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionEndRemindersOrderBy>>;
  where?: InputMaybe<ISubscriptionEndRemindersBoolExp>;
}


export interface IQueryRootSubscriptionMailRemindersArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionMailRemindersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionMailRemindersOrderBy>>;
  where?: InputMaybe<ISubscriptionMailRemindersBoolExp>;
}


export interface IQueryRootSubscriptionMailRemindersAggregateArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionMailRemindersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionMailRemindersOrderBy>>;
  where?: InputMaybe<ISubscriptionMailRemindersBoolExp>;
}


export interface IQueryRootSubscriptionsArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionsOrderBy>>;
  where?: InputMaybe<ISubscriptionsBoolExp>;
}


export interface IQueryRootSubscriptionsAggregateArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionsOrderBy>>;
  where?: InputMaybe<ISubscriptionsBoolExp>;
}


export interface IQueryRootSubscriptionsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootSubscriptionsByUserArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionsByUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionsByUserOrderBy>>;
  where?: InputMaybe<ISubscriptionsByUserBoolExp>;
}


export interface IQueryRootSubscriptionsByUserAggregateArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionsByUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionsByUserOrderBy>>;
  where?: InputMaybe<ISubscriptionsByUserBoolExp>;
}


export interface IQueryRootSupportTopicEntriesArgs {
  distinct_on?: InputMaybe<Array<ISupportTopicEntriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISupportTopicEntriesOrderBy>>;
  where?: InputMaybe<ISupportTopicEntriesBoolExp>;
}


export interface IQueryRootSupportTopicEntriesAggregateArgs {
  distinct_on?: InputMaybe<Array<ISupportTopicEntriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISupportTopicEntriesOrderBy>>;
  where?: InputMaybe<ISupportTopicEntriesBoolExp>;
}


export interface IQueryRootSupportTopicEntriesByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootSupportTopicsArgs {
  distinct_on?: InputMaybe<Array<ISupportTopicsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISupportTopicsOrderBy>>;
  where?: InputMaybe<ISupportTopicsBoolExp>;
}


export interface IQueryRootSupportTopicsAggregateArgs {
  distinct_on?: InputMaybe<Array<ISupportTopicsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISupportTopicsOrderBy>>;
  where?: InputMaybe<ISupportTopicsBoolExp>;
}


export interface IQueryRootSupportTopicsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootSuspensionsArgs {
  distinct_on?: InputMaybe<Array<ISuspensionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISuspensionsOrderBy>>;
  where?: InputMaybe<ISuspensionsBoolExp>;
}


export interface IQueryRootSuspensionsAggregateArgs {
  distinct_on?: InputMaybe<Array<ISuspensionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISuspensionsOrderBy>>;
  where?: InputMaybe<ISuspensionsBoolExp>;
}


export interface IQueryRootSuspensionsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootTmpLessonsArgs {
  distinct_on?: InputMaybe<Array<ITmpLessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ITmpLessonsOrderBy>>;
  where?: InputMaybe<ITmpLessonsBoolExp>;
}


export interface IQueryRootTmpLessonsAggregateArgs {
  distinct_on?: InputMaybe<Array<ITmpLessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ITmpLessonsOrderBy>>;
  where?: InputMaybe<ITmpLessonsBoolExp>;
}


export interface IQueryRootTmpLessonsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootTmpProgressArgs {
  distinct_on?: InputMaybe<Array<ITmpProgressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ITmpProgressOrderBy>>;
  where?: InputMaybe<ITmpProgressBoolExp>;
}


export interface IQueryRootTmpProgressAggregateArgs {
  distinct_on?: InputMaybe<Array<ITmpProgressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ITmpProgressOrderBy>>;
  where?: InputMaybe<ITmpProgressBoolExp>;
}


export interface IQueryRootTmpProgressByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootTmpStreamLessonsArgs {
  distinct_on?: InputMaybe<Array<ITmpStreamLessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ITmpStreamLessonsOrderBy>>;
  where?: InputMaybe<ITmpStreamLessonsBoolExp>;
}


export interface IQueryRootTmpStreamLessonsAggregateArgs {
  distinct_on?: InputMaybe<Array<ITmpStreamLessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ITmpStreamLessonsOrderBy>>;
  where?: InputMaybe<ITmpStreamLessonsBoolExp>;
}


export interface IQueryRootUniqueUserSessionsArgs {
  distinct_on?: InputMaybe<Array<IUniqueUserSessionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUniqueUserSessionsOrderBy>>;
  where?: InputMaybe<IUniqueUserSessionsBoolExp>;
}


export interface IQueryRootUniqueUserSessionsAggregateArgs {
  distinct_on?: InputMaybe<Array<IUniqueUserSessionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUniqueUserSessionsOrderBy>>;
  where?: InputMaybe<IUniqueUserSessionsBoolExp>;
}


export interface IQueryRootUnlockablesArgs {
  distinct_on?: InputMaybe<Array<IUnlockablesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUnlockablesOrderBy>>;
  where?: InputMaybe<IUnlockablesBoolExp>;
}


export interface IQueryRootUnlockablesAggregateArgs {
  distinct_on?: InputMaybe<Array<IUnlockablesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUnlockablesOrderBy>>;
  where?: InputMaybe<IUnlockablesBoolExp>;
}


export interface IQueryRootUnlockablesByPkArgs {
  type: Scalars['String'];
}


export interface IQueryRootUserChildrenUnlockablesArgs {
  distinct_on?: InputMaybe<Array<IUserChildrenUnlockablesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserChildrenUnlockablesOrderBy>>;
  where?: InputMaybe<IUserChildrenUnlockablesBoolExp>;
}


export interface IQueryRootUserChildrenUnlockablesAggregateArgs {
  distinct_on?: InputMaybe<Array<IUserChildrenUnlockablesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserChildrenUnlockablesOrderBy>>;
  where?: InputMaybe<IUserChildrenUnlockablesBoolExp>;
}


export interface IQueryRootUserChildrenUnlockablesByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootUserCourseUsageArgs {
  distinct_on?: InputMaybe<Array<IUserCourseUsageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserCourseUsageOrderBy>>;
  where?: InputMaybe<IUserCourseUsageBoolExp>;
}


export interface IQueryRootUserCourseUsageAggregateArgs {
  distinct_on?: InputMaybe<Array<IUserCourseUsageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserCourseUsageOrderBy>>;
  where?: InputMaybe<IUserCourseUsageBoolExp>;
}


export interface IQueryRootUserLessonMessagesViewArgs {
  distinct_on?: InputMaybe<Array<IUserLessonMessagesViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserLessonMessagesViewOrderBy>>;
  where?: InputMaybe<IUserLessonMessagesViewBoolExp>;
}


export interface IQueryRootUserLessonMessagesViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IUserLessonMessagesViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserLessonMessagesViewOrderBy>>;
  where?: InputMaybe<IUserLessonMessagesViewBoolExp>;
}


export interface IQueryRootUserLessonPartsProgressesArgs {
  distinct_on?: InputMaybe<Array<IUserLessonPartsProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserLessonPartsProgressesOrderBy>>;
  where?: InputMaybe<IUserLessonPartsProgressesBoolExp>;
}


export interface IQueryRootUserLessonPartsProgressesAggregateArgs {
  distinct_on?: InputMaybe<Array<IUserLessonPartsProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserLessonPartsProgressesOrderBy>>;
  where?: InputMaybe<IUserLessonPartsProgressesBoolExp>;
}


export interface IQueryRootUserLessonPartsProgressesByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootUserLessonProgressesArgs {
  distinct_on?: InputMaybe<Array<IUserLessonProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserLessonProgressesOrderBy>>;
  where?: InputMaybe<IUserLessonProgressesBoolExp>;
}


export interface IQueryRootUserLessonProgressesAggregateArgs {
  distinct_on?: InputMaybe<Array<IUserLessonProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserLessonProgressesOrderBy>>;
  where?: InputMaybe<IUserLessonProgressesBoolExp>;
}


export interface IQueryRootUserLessonProgressesByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootUserQaProgressesArgs {
  distinct_on?: InputMaybe<Array<IUserQaProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserQaProgressesOrderBy>>;
  where?: InputMaybe<IUserQaProgressesBoolExp>;
}


export interface IQueryRootUserQaProgressesAggregateArgs {
  distinct_on?: InputMaybe<Array<IUserQaProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserQaProgressesOrderBy>>;
  where?: InputMaybe<IUserQaProgressesBoolExp>;
}


export interface IQueryRootUserQaProgressesByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootUserThirdPartsProgressesArgs {
  distinct_on?: InputMaybe<Array<IUserThirdPartsProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserThirdPartsProgressesOrderBy>>;
  where?: InputMaybe<IUserThirdPartsProgressesBoolExp>;
}


export interface IQueryRootUserThirdPartsProgressesAggregateArgs {
  distinct_on?: InputMaybe<Array<IUserThirdPartsProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserThirdPartsProgressesOrderBy>>;
  where?: InputMaybe<IUserThirdPartsProgressesBoolExp>;
}


export interface IQueryRootUserThirdPartsProgressesByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootUsersArgs {
  distinct_on?: InputMaybe<Array<IUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUsersOrderBy>>;
  where?: InputMaybe<IUsersBoolExp>;
}


export interface IQueryRootUsersAggregateArgs {
  distinct_on?: InputMaybe<Array<IUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUsersOrderBy>>;
  where?: InputMaybe<IUsersBoolExp>;
}


export interface IQueryRootUsersByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootUsersChildDetailsArgs {
  distinct_on?: InputMaybe<Array<IUsersChildDetailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUsersChildDetailsOrderBy>>;
  where?: InputMaybe<IUsersChildDetailsBoolExp>;
}


export interface IQueryRootUsersChildDetailsAggregateArgs {
  distinct_on?: InputMaybe<Array<IUsersChildDetailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUsersChildDetailsOrderBy>>;
  where?: InputMaybe<IUsersChildDetailsBoolExp>;
}


export interface IQueryRootUsersChildDetailsByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootUsersChildrenArgs {
  distinct_on?: InputMaybe<Array<IUsersChildrenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUsersChildrenOrderBy>>;
  where?: InputMaybe<IUsersChildrenBoolExp>;
}


export interface IQueryRootUsersChildrenAggregateArgs {
  distinct_on?: InputMaybe<Array<IUsersChildrenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUsersChildrenOrderBy>>;
  where?: InputMaybe<IUsersChildrenBoolExp>;
}


export interface IQueryRootUsersChildrenByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootUsersSessionsArgs {
  distinct_on?: InputMaybe<Array<IUsersSessionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUsersSessionsOrderBy>>;
  where?: InputMaybe<IUsersSessionsBoolExp>;
}


export interface IQueryRootUsersSessionsAggregateArgs {
  distinct_on?: InputMaybe<Array<IUsersSessionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUsersSessionsOrderBy>>;
  where?: InputMaybe<IUsersSessionsBoolExp>;
}


export interface IQueryRootUsersSessionsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootVacationGroupTypesArgs {
  distinct_on?: InputMaybe<Array<IVacationGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IVacationGroupTypesOrderBy>>;
  where?: InputMaybe<IVacationGroupTypesBoolExp>;
}


export interface IQueryRootVacationGroupTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<IVacationGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IVacationGroupTypesOrderBy>>;
  where?: InputMaybe<IVacationGroupTypesBoolExp>;
}


export interface IQueryRootVacationGroupTypesByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootVacationsArgs {
  distinct_on?: InputMaybe<Array<IVacationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IVacationsOrderBy>>;
  where?: InputMaybe<IVacationsBoolExp>;
}


export interface IQueryRootVacationsAggregateArgs {
  distinct_on?: InputMaybe<Array<IVacationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IVacationsOrderBy>>;
  where?: InputMaybe<IVacationsBoolExp>;
}


export interface IQueryRootVacationsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootWeeklyFactsArgs {
  distinct_on?: InputMaybe<Array<IWeeklyFactsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IWeeklyFactsOrderBy>>;
  where?: InputMaybe<IWeeklyFactsBoolExp>;
}


export interface IQueryRootWeeklyFactsAggregateArgs {
  distinct_on?: InputMaybe<Array<IWeeklyFactsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IWeeklyFactsOrderBy>>;
  where?: InputMaybe<IWeeklyFactsBoolExp>;
}


export interface IQueryRootWeeklyFactsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootWpOrdersArgs {
  distinct_on?: InputMaybe<Array<IWpOrdersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IWpOrdersOrderBy>>;
  where?: InputMaybe<IWpOrdersBoolExp>;
}


export interface IQueryRootWpOrdersAggregateArgs {
  distinct_on?: InputMaybe<Array<IWpOrdersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IWpOrdersOrderBy>>;
  where?: InputMaybe<IWpOrdersBoolExp>;
}


export interface IQueryRootWpOrdersByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootWpProductsArgs {
  distinct_on?: InputMaybe<Array<IWpProductsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IWpProductsOrderBy>>;
  where?: InputMaybe<IWpProductsBoolExp>;
}


export interface IQueryRootWpProductsAggregateArgs {
  distinct_on?: InputMaybe<Array<IWpProductsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IWpProductsOrderBy>>;
  where?: InputMaybe<IWpProductsBoolExp>;
}


export interface IQueryRootWpProductsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootWpSubscriptionOrdersArgs {
  distinct_on?: InputMaybe<Array<IWpSubscriptionOrdersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IWpSubscriptionOrdersOrderBy>>;
  where?: InputMaybe<IWpSubscriptionOrdersBoolExp>;
}


export interface IQueryRootWpSubscriptionOrdersAggregateArgs {
  distinct_on?: InputMaybe<Array<IWpSubscriptionOrdersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IWpSubscriptionOrdersOrderBy>>;
  where?: InputMaybe<IWpSubscriptionOrdersBoolExp>;
}


export interface IQueryRootWpSubscriptionOrdersByPkArgs {
  id: Scalars['Int'];
}

/** columns and relationships of "questions" */
export interface IQuestions {
  __typename?: 'questions';
  /** An array relationship */
  answers: Array<IAnswers>;
  /** An aggregate relationship */
  answers_aggregate: IAnswersAggregate;
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  /** An object relationship */
  group_type?: Maybe<IGroupTypes>;
  group_type_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  is_answered: Scalars['Boolean'];
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  lesson_id: Scalars['uuid'];
  lesson_part?: Maybe<Scalars['Int']>;
  lesson_time: Scalars['String'];
  sequence: Scalars['Int'];
  subject: Scalars['String'];
  surah_number: Scalars['Int'];
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id: Scalars['uuid'];
  /** An object relationship */
  users_child: IUsersChildren;
  users_child_id: Scalars['uuid'];
  verse_number: Scalars['Int'];
}


/** columns and relationships of "questions" */
export interface IQuestionsAnswersArgs {
  distinct_on?: InputMaybe<Array<IAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAnswersOrderBy>>;
  where?: InputMaybe<IAnswersBoolExp>;
}


/** columns and relationships of "questions" */
export interface IQuestionsAnswersAggregateArgs {
  distinct_on?: InputMaybe<Array<IAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAnswersOrderBy>>;
  where?: InputMaybe<IAnswersBoolExp>;
}

/** aggregated selection of "questions" */
export interface IQuestionsAggregate {
  __typename?: 'questions_aggregate';
  aggregate?: Maybe<IQuestionsAggregateFields>;
  nodes: Array<IQuestions>;
}

/** aggregate fields of "questions" */
export interface IQuestionsAggregateFields {
  __typename?: 'questions_aggregate_fields';
  avg?: Maybe<IQuestionsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IQuestionsMaxFields>;
  min?: Maybe<IQuestionsMinFields>;
  stddev?: Maybe<IQuestionsStddevFields>;
  stddev_pop?: Maybe<IQuestionsStddevPopFields>;
  stddev_samp?: Maybe<IQuestionsStddevSampFields>;
  sum?: Maybe<IQuestionsSumFields>;
  var_pop?: Maybe<IQuestionsVarPopFields>;
  var_samp?: Maybe<IQuestionsVarSampFields>;
  variance?: Maybe<IQuestionsVarianceFields>;
}


/** aggregate fields of "questions" */
export interface IQuestionsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IQuestionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "questions" */
export interface IQuestionsAggregateOrderBy {
  avg?: InputMaybe<IQuestionsAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IQuestionsMaxOrderBy>;
  min?: InputMaybe<IQuestionsMinOrderBy>;
  stddev?: InputMaybe<IQuestionsStddevOrderBy>;
  stddev_pop?: InputMaybe<IQuestionsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IQuestionsStddevSampOrderBy>;
  sum?: InputMaybe<IQuestionsSumOrderBy>;
  var_pop?: InputMaybe<IQuestionsVarPopOrderBy>;
  var_samp?: InputMaybe<IQuestionsVarSampOrderBy>;
  variance?: InputMaybe<IQuestionsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "questions" */
export interface IQuestionsArrRelInsertInput {
  data: Array<IQuestionsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IQuestionsOnConflict>;
}

/** aggregate avg on columns */
export interface IQuestionsAvgFields {
  __typename?: 'questions_avg_fields';
  lesson_part?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  surah_number?: Maybe<Scalars['Float']>;
  verse_number?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "questions" */
export interface IQuestionsAvgOrderBy {
  lesson_part?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  surah_number?: InputMaybe<IOrderBy>;
  verse_number?: InputMaybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "questions". All fields are combined with a logical 'AND'. */
export interface IQuestionsBoolExp {
  _and?: InputMaybe<Array<IQuestionsBoolExp>>;
  _not?: InputMaybe<IQuestionsBoolExp>;
  _or?: InputMaybe<Array<IQuestionsBoolExp>>;
  answers?: InputMaybe<IAnswersBoolExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  group_type?: InputMaybe<IGroupTypesBoolExp>;
  group_type_id?: InputMaybe<IUuidComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  is_answered?: InputMaybe<IBooleanComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  lesson_part?: InputMaybe<IIntComparisonExp>;
  lesson_time?: InputMaybe<IStringComparisonExp>;
  sequence?: InputMaybe<IIntComparisonExp>;
  subject?: InputMaybe<IStringComparisonExp>;
  surah_number?: InputMaybe<IIntComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
  users_child?: InputMaybe<IUsersChildrenBoolExp>;
  users_child_id?: InputMaybe<IUuidComparisonExp>;
  verse_number?: InputMaybe<IIntComparisonExp>;
}

/** unique or primary key constraints on table "questions" */
export enum IQuestionsConstraint {
  /** unique or primary key constraint */
  QUESTIONS_PKEY = 'questions_pkey'
}

/** input type for incrementing numeric columns in table "questions" */
export interface IQuestionsIncInput {
  lesson_part?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  surah_number?: InputMaybe<Scalars['Int']>;
  verse_number?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "questions" */
export interface IQuestionsInsertInput {
  answers?: InputMaybe<IAnswersArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  group_type?: InputMaybe<IGroupTypesObjRelInsertInput>;
  group_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_answered?: InputMaybe<Scalars['Boolean']>;
  lesson?: InputMaybe<ILessonsObjRelInsertInput>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  lesson_part?: InputMaybe<Scalars['Int']>;
  lesson_time?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  subject?: InputMaybe<Scalars['String']>;
  surah_number?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<IUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']>;
  users_child?: InputMaybe<IUsersChildrenObjRelInsertInput>;
  users_child_id?: InputMaybe<Scalars['uuid']>;
  verse_number?: InputMaybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface IQuestionsMaxFields {
  __typename?: 'questions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  group_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  lesson_part?: Maybe<Scalars['Int']>;
  lesson_time?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  surah_number?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
  users_child_id?: Maybe<Scalars['uuid']>;
  verse_number?: Maybe<Scalars['Int']>;
}

/** order by max() on columns of table "questions" */
export interface IQuestionsMaxOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  group_type_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  lesson_part?: InputMaybe<IOrderBy>;
  lesson_time?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  subject?: InputMaybe<IOrderBy>;
  surah_number?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  users_child_id?: InputMaybe<IOrderBy>;
  verse_number?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IQuestionsMinFields {
  __typename?: 'questions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  group_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  lesson_part?: Maybe<Scalars['Int']>;
  lesson_time?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  surah_number?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
  users_child_id?: Maybe<Scalars['uuid']>;
  verse_number?: Maybe<Scalars['Int']>;
}

/** order by min() on columns of table "questions" */
export interface IQuestionsMinOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  group_type_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  lesson_part?: InputMaybe<IOrderBy>;
  lesson_time?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  subject?: InputMaybe<IOrderBy>;
  surah_number?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  users_child_id?: InputMaybe<IOrderBy>;
  verse_number?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "questions" */
export interface IQuestionsMutationResponse {
  __typename?: 'questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IQuestions>;
}

/** input type for inserting object relation for remote table "questions" */
export interface IQuestionsObjRelInsertInput {
  data: IQuestionsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IQuestionsOnConflict>;
}

/** on_conflict condition type for table "questions" */
export interface IQuestionsOnConflict {
  constraint: IQuestionsConstraint;
  update_columns?: Array<IQuestionsUpdateColumn>;
  where?: InputMaybe<IQuestionsBoolExp>;
}

/** Ordering options when selecting data from "questions". */
export interface IQuestionsOrderBy {
  answers_aggregate?: InputMaybe<IAnswersAggregateOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  group_type?: InputMaybe<IGroupTypesOrderBy>;
  group_type_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  is_answered?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  lesson_part?: InputMaybe<IOrderBy>;
  lesson_time?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  subject?: InputMaybe<IOrderBy>;
  surah_number?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  users_child?: InputMaybe<IUsersChildrenOrderBy>;
  users_child_id?: InputMaybe<IOrderBy>;
  verse_number?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: questions */
export interface IQuestionsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "questions" */
export enum IQuestionsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  GROUP_TYPE_ID = 'group_type_id',
  /** column name */
  ID = 'id',
  /** column name */
  IS_ANSWERED = 'is_answered',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  LESSON_PART = 'lesson_part',
  /** column name */
  LESSON_TIME = 'lesson_time',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  SUBJECT = 'subject',
  /** column name */
  SURAH_NUMBER = 'surah_number',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  USERS_CHILD_ID = 'users_child_id',
  /** column name */
  VERSE_NUMBER = 'verse_number'
}

/** input type for updating data in table "questions" */
export interface IQuestionsSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  group_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_answered?: InputMaybe<Scalars['Boolean']>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  lesson_part?: InputMaybe<Scalars['Int']>;
  lesson_time?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  subject?: InputMaybe<Scalars['String']>;
  surah_number?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  users_child_id?: InputMaybe<Scalars['uuid']>;
  verse_number?: InputMaybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface IQuestionsStddevFields {
  __typename?: 'questions_stddev_fields';
  lesson_part?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  surah_number?: Maybe<Scalars['Float']>;
  verse_number?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "questions" */
export interface IQuestionsStddevOrderBy {
  lesson_part?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  surah_number?: InputMaybe<IOrderBy>;
  verse_number?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IQuestionsStddevPopFields {
  __typename?: 'questions_stddev_pop_fields';
  lesson_part?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  surah_number?: Maybe<Scalars['Float']>;
  verse_number?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "questions" */
export interface IQuestionsStddevPopOrderBy {
  lesson_part?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  surah_number?: InputMaybe<IOrderBy>;
  verse_number?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IQuestionsStddevSampFields {
  __typename?: 'questions_stddev_samp_fields';
  lesson_part?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  surah_number?: Maybe<Scalars['Float']>;
  verse_number?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "questions" */
export interface IQuestionsStddevSampOrderBy {
  lesson_part?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  surah_number?: InputMaybe<IOrderBy>;
  verse_number?: InputMaybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IQuestionsSumFields {
  __typename?: 'questions_sum_fields';
  lesson_part?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  surah_number?: Maybe<Scalars['Int']>;
  verse_number?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "questions" */
export interface IQuestionsSumOrderBy {
  lesson_part?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  surah_number?: InputMaybe<IOrderBy>;
  verse_number?: InputMaybe<IOrderBy>;
}

/** update columns of table "questions" */
export enum IQuestionsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  GROUP_TYPE_ID = 'group_type_id',
  /** column name */
  ID = 'id',
  /** column name */
  IS_ANSWERED = 'is_answered',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  LESSON_PART = 'lesson_part',
  /** column name */
  LESSON_TIME = 'lesson_time',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  SUBJECT = 'subject',
  /** column name */
  SURAH_NUMBER = 'surah_number',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  USERS_CHILD_ID = 'users_child_id',
  /** column name */
  VERSE_NUMBER = 'verse_number'
}

/** aggregate var_pop on columns */
export interface IQuestionsVarPopFields {
  __typename?: 'questions_var_pop_fields';
  lesson_part?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  surah_number?: Maybe<Scalars['Float']>;
  verse_number?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "questions" */
export interface IQuestionsVarPopOrderBy {
  lesson_part?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  surah_number?: InputMaybe<IOrderBy>;
  verse_number?: InputMaybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IQuestionsVarSampFields {
  __typename?: 'questions_var_samp_fields';
  lesson_part?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  surah_number?: Maybe<Scalars['Float']>;
  verse_number?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "questions" */
export interface IQuestionsVarSampOrderBy {
  lesson_part?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  surah_number?: InputMaybe<IOrderBy>;
  verse_number?: InputMaybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IQuestionsVarianceFields {
  __typename?: 'questions_variance_fields';
  lesson_part?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  surah_number?: Maybe<Scalars['Float']>;
  verse_number?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "questions" */
export interface IQuestionsVarianceOrderBy {
  lesson_part?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  surah_number?: InputMaybe<IOrderBy>;
  verse_number?: InputMaybe<IOrderBy>;
}

/** columns and relationships of "reactions_grouped_by_all_parts" */
export interface IReactionsGroupedByAllParts {
  __typename?: 'reactions_grouped_by_all_parts';
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_part?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
  student_name?: Maybe<Scalars['String']>;
  vimeo_url?: Maybe<Scalars['String']>;
}

/** aggregated selection of "reactions_grouped_by_all_parts" */
export interface IReactionsGroupedByAllPartsAggregate {
  __typename?: 'reactions_grouped_by_all_parts_aggregate';
  aggregate?: Maybe<IReactionsGroupedByAllPartsAggregateFields>;
  nodes: Array<IReactionsGroupedByAllParts>;
}

/** aggregate fields of "reactions_grouped_by_all_parts" */
export interface IReactionsGroupedByAllPartsAggregateFields {
  __typename?: 'reactions_grouped_by_all_parts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IReactionsGroupedByAllPartsMaxFields>;
  min?: Maybe<IReactionsGroupedByAllPartsMinFields>;
}


/** aggregate fields of "reactions_grouped_by_all_parts" */
export interface IReactionsGroupedByAllPartsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IReactionsGroupedByAllPartsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "reactions_grouped_by_all_parts". All fields are combined with a logical 'AND'. */
export interface IReactionsGroupedByAllPartsBoolExp {
  _and?: InputMaybe<Array<IReactionsGroupedByAllPartsBoolExp>>;
  _not?: InputMaybe<IReactionsGroupedByAllPartsBoolExp>;
  _or?: InputMaybe<Array<IReactionsGroupedByAllPartsBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  lesson_part?: InputMaybe<IStringComparisonExp>;
  reaction?: InputMaybe<IStringComparisonExp>;
  student_name?: InputMaybe<IStringComparisonExp>;
  vimeo_url?: InputMaybe<IStringComparisonExp>;
}

/** aggregate max on columns */
export interface IReactionsGroupedByAllPartsMaxFields {
  __typename?: 'reactions_grouped_by_all_parts_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_part?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
  student_name?: Maybe<Scalars['String']>;
  vimeo_url?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IReactionsGroupedByAllPartsMinFields {
  __typename?: 'reactions_grouped_by_all_parts_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_part?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
  student_name?: Maybe<Scalars['String']>;
  vimeo_url?: Maybe<Scalars['String']>;
}

/** Ordering options when selecting data from "reactions_grouped_by_all_parts". */
export interface IReactionsGroupedByAllPartsOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  lesson_part?: InputMaybe<IOrderBy>;
  reaction?: InputMaybe<IOrderBy>;
  student_name?: InputMaybe<IOrderBy>;
  vimeo_url?: InputMaybe<IOrderBy>;
}

/** select columns of table "reactions_grouped_by_all_parts" */
export enum IReactionsGroupedByAllPartsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  LESSON_PART = 'lesson_part',
  /** column name */
  REACTION = 'reaction',
  /** column name */
  STUDENT_NAME = 'student_name',
  /** column name */
  VIMEO_URL = 'vimeo_url'
}

/** columns and relationships of "reactivation_subscriptions" */
export interface IReactivationSubscriptions {
  __typename?: 'reactivation_subscriptions';
  change_count?: Maybe<Scalars['Int']>;
  datediff?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  on_hold?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wp_subscription_id?: Maybe<Scalars['Int']>;
}

/** aggregated selection of "reactivation_subscriptions" */
export interface IReactivationSubscriptionsAggregate {
  __typename?: 'reactivation_subscriptions_aggregate';
  aggregate?: Maybe<IReactivationSubscriptionsAggregateFields>;
  nodes: Array<IReactivationSubscriptions>;
}

/** aggregate fields of "reactivation_subscriptions" */
export interface IReactivationSubscriptionsAggregateFields {
  __typename?: 'reactivation_subscriptions_aggregate_fields';
  avg?: Maybe<IReactivationSubscriptionsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IReactivationSubscriptionsMaxFields>;
  min?: Maybe<IReactivationSubscriptionsMinFields>;
  stddev?: Maybe<IReactivationSubscriptionsStddevFields>;
  stddev_pop?: Maybe<IReactivationSubscriptionsStddevPopFields>;
  stddev_samp?: Maybe<IReactivationSubscriptionsStddevSampFields>;
  sum?: Maybe<IReactivationSubscriptionsSumFields>;
  var_pop?: Maybe<IReactivationSubscriptionsVarPopFields>;
  var_samp?: Maybe<IReactivationSubscriptionsVarSampFields>;
  variance?: Maybe<IReactivationSubscriptionsVarianceFields>;
}


/** aggregate fields of "reactivation_subscriptions" */
export interface IReactivationSubscriptionsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IReactivationSubscriptionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IReactivationSubscriptionsAvgFields {
  __typename?: 'reactivation_subscriptions_avg_fields';
  change_count?: Maybe<Scalars['Float']>;
  datediff?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "reactivation_subscriptions". All fields are combined with a logical 'AND'. */
export interface IReactivationSubscriptionsBoolExp {
  _and?: InputMaybe<Array<IReactivationSubscriptionsBoolExp>>;
  _not?: InputMaybe<IReactivationSubscriptionsBoolExp>;
  _or?: InputMaybe<Array<IReactivationSubscriptionsBoolExp>>;
  change_count?: InputMaybe<IIntComparisonExp>;
  datediff?: InputMaybe<IFloat8ComparisonExp>;
  id?: InputMaybe<IIntComparisonExp>;
  on_hold?: InputMaybe<IBooleanComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  wp_subscription_id?: InputMaybe<IIntComparisonExp>;
}

/** aggregate max on columns */
export interface IReactivationSubscriptionsMaxFields {
  __typename?: 'reactivation_subscriptions_max_fields';
  change_count?: Maybe<Scalars['Int']>;
  datediff?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wp_subscription_id?: Maybe<Scalars['Int']>;
}

/** aggregate min on columns */
export interface IReactivationSubscriptionsMinFields {
  __typename?: 'reactivation_subscriptions_min_fields';
  change_count?: Maybe<Scalars['Int']>;
  datediff?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wp_subscription_id?: Maybe<Scalars['Int']>;
}

/** Ordering options when selecting data from "reactivation_subscriptions". */
export interface IReactivationSubscriptionsOrderBy {
  change_count?: InputMaybe<IOrderBy>;
  datediff?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  on_hold?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "reactivation_subscriptions" */
export enum IReactivationSubscriptionsSelectColumn {
  /** column name */
  CHANGE_COUNT = 'change_count',
  /** column name */
  DATEDIFF = 'datediff',
  /** column name */
  ID = 'id',
  /** column name */
  ON_HOLD = 'on_hold',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  WP_SUBSCRIPTION_ID = 'wp_subscription_id'
}

/** aggregate stddev on columns */
export interface IReactivationSubscriptionsStddevFields {
  __typename?: 'reactivation_subscriptions_stddev_fields';
  change_count?: Maybe<Scalars['Float']>;
  datediff?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IReactivationSubscriptionsStddevPopFields {
  __typename?: 'reactivation_subscriptions_stddev_pop_fields';
  change_count?: Maybe<Scalars['Float']>;
  datediff?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IReactivationSubscriptionsStddevSampFields {
  __typename?: 'reactivation_subscriptions_stddev_samp_fields';
  change_count?: Maybe<Scalars['Float']>;
  datediff?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IReactivationSubscriptionsSumFields {
  __typename?: 'reactivation_subscriptions_sum_fields';
  change_count?: Maybe<Scalars['Int']>;
  datediff?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  wp_subscription_id?: Maybe<Scalars['Int']>;
}

/** aggregate var_pop on columns */
export interface IReactivationSubscriptionsVarPopFields {
  __typename?: 'reactivation_subscriptions_var_pop_fields';
  change_count?: Maybe<Scalars['Float']>;
  datediff?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IReactivationSubscriptionsVarSampFields {
  __typename?: 'reactivation_subscriptions_var_samp_fields';
  change_count?: Maybe<Scalars['Float']>;
  datediff?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IReactivationSubscriptionsVarianceFields {
  __typename?: 'reactivation_subscriptions_variance_fields';
  change_count?: Maybe<Scalars['Float']>;
  datediff?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "recitations" */
export interface IRecitations {
  __typename?: 'recitations';
  /** An object relationship */
  courseTypeByCourseType?: Maybe<ICourseTypes>;
  course_type: ICourseTypesEnum;
  created_at: Scalars['timestamptz'];
  dua?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  image?: Maybe<Scalars['String']>;
  sequence: Scalars['Int'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  vimeo_url?: Maybe<Scalars['String']>;
}

/** aggregated selection of "recitations" */
export interface IRecitationsAggregate {
  __typename?: 'recitations_aggregate';
  aggregate?: Maybe<IRecitationsAggregateFields>;
  nodes: Array<IRecitations>;
}

/** aggregate fields of "recitations" */
export interface IRecitationsAggregateFields {
  __typename?: 'recitations_aggregate_fields';
  avg?: Maybe<IRecitationsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IRecitationsMaxFields>;
  min?: Maybe<IRecitationsMinFields>;
  stddev?: Maybe<IRecitationsStddevFields>;
  stddev_pop?: Maybe<IRecitationsStddevPopFields>;
  stddev_samp?: Maybe<IRecitationsStddevSampFields>;
  sum?: Maybe<IRecitationsSumFields>;
  var_pop?: Maybe<IRecitationsVarPopFields>;
  var_samp?: Maybe<IRecitationsVarSampFields>;
  variance?: Maybe<IRecitationsVarianceFields>;
}


/** aggregate fields of "recitations" */
export interface IRecitationsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IRecitationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** columns and relationships of "recitations_attachments" */
export interface IRecitationsAttachments {
  __typename?: 'recitations_attachments';
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  recitation_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "recitations_attachments" */
export interface IRecitationsAttachmentsAggregate {
  __typename?: 'recitations_attachments_aggregate';
  aggregate?: Maybe<IRecitationsAttachmentsAggregateFields>;
  nodes: Array<IRecitationsAttachments>;
}

/** aggregate fields of "recitations_attachments" */
export interface IRecitationsAttachmentsAggregateFields {
  __typename?: 'recitations_attachments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IRecitationsAttachmentsMaxFields>;
  min?: Maybe<IRecitationsAttachmentsMinFields>;
}


/** aggregate fields of "recitations_attachments" */
export interface IRecitationsAttachmentsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IRecitationsAttachmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "recitations_attachments". All fields are combined with a logical 'AND'. */
export interface IRecitationsAttachmentsBoolExp {
  _and?: InputMaybe<Array<IRecitationsAttachmentsBoolExp>>;
  _not?: InputMaybe<IRecitationsAttachmentsBoolExp>;
  _or?: InputMaybe<Array<IRecitationsAttachmentsBoolExp>>;
  content_type?: InputMaybe<IStringComparisonExp>;
  created_at?: InputMaybe<ITimestampComparisonExp>;
  filename?: InputMaybe<IStringComparisonExp>;
  key?: InputMaybe<IStringComparisonExp>;
  recitation_id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface IRecitationsAttachmentsMaxFields {
  __typename?: 'recitations_attachments_max_fields';
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  recitation_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IRecitationsAttachmentsMinFields {
  __typename?: 'recitations_attachments_min_fields';
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  recitation_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "recitations_attachments". */
export interface IRecitationsAttachmentsOrderBy {
  content_type?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  filename?: InputMaybe<IOrderBy>;
  key?: InputMaybe<IOrderBy>;
  recitation_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "recitations_attachments" */
export enum IRecitationsAttachmentsSelectColumn {
  /** column name */
  CONTENT_TYPE = 'content_type',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  FILENAME = 'filename',
  /** column name */
  KEY = 'key',
  /** column name */
  RECITATION_ID = 'recitation_id'
}

/** aggregate avg on columns */
export interface IRecitationsAvgFields {
  __typename?: 'recitations_avg_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "recitations". All fields are combined with a logical 'AND'. */
export interface IRecitationsBoolExp {
  _and?: InputMaybe<Array<IRecitationsBoolExp>>;
  _not?: InputMaybe<IRecitationsBoolExp>;
  _or?: InputMaybe<Array<IRecitationsBoolExp>>;
  courseTypeByCourseType?: InputMaybe<ICourseTypesBoolExp>;
  course_type?: InputMaybe<ICourseTypesEnumComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  dua?: InputMaybe<IStringComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  image?: InputMaybe<IStringComparisonExp>;
  sequence?: InputMaybe<IIntComparisonExp>;
  title?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  vimeo_url?: InputMaybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "recitations" */
export enum IRecitationsConstraint {
  /** unique or primary key constraint */
  RECITATIONS_PKEY = 'recitations_pkey'
}

/** input type for incrementing numeric columns in table "recitations" */
export interface IRecitationsIncInput {
  sequence?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "recitations" */
export interface IRecitationsInsertInput {
  courseTypeByCourseType?: InputMaybe<ICourseTypesObjRelInsertInput>;
  course_type?: InputMaybe<ICourseTypesEnum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dua?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vimeo_url?: InputMaybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface IRecitationsMaxFields {
  __typename?: 'recitations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  dua?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vimeo_url?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IRecitationsMinFields {
  __typename?: 'recitations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  dua?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vimeo_url?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "recitations" */
export interface IRecitationsMutationResponse {
  __typename?: 'recitations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IRecitations>;
}

/** on_conflict condition type for table "recitations" */
export interface IRecitationsOnConflict {
  constraint: IRecitationsConstraint;
  update_columns?: Array<IRecitationsUpdateColumn>;
  where?: InputMaybe<IRecitationsBoolExp>;
}

/** Ordering options when selecting data from "recitations". */
export interface IRecitationsOrderBy {
  courseTypeByCourseType?: InputMaybe<ICourseTypesOrderBy>;
  course_type?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  dua?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  image?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  title?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  vimeo_url?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: recitations */
export interface IRecitationsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "recitations" */
export enum IRecitationsSelectColumn {
  /** column name */
  COURSE_TYPE = 'course_type',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DUA = 'dua',
  /** column name */
  ID = 'id',
  /** column name */
  IMAGE = 'image',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  VIMEO_URL = 'vimeo_url'
}

/** input type for updating data in table "recitations" */
export interface IRecitationsSetInput {
  course_type?: InputMaybe<ICourseTypesEnum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dua?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vimeo_url?: InputMaybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface IRecitationsStddevFields {
  __typename?: 'recitations_stddev_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IRecitationsStddevPopFields {
  __typename?: 'recitations_stddev_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IRecitationsStddevSampFields {
  __typename?: 'recitations_stddev_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IRecitationsSumFields {
  __typename?: 'recitations_sum_fields';
  sequence?: Maybe<Scalars['Int']>;
}

/** update columns of table "recitations" */
export enum IRecitationsUpdateColumn {
  /** column name */
  COURSE_TYPE = 'course_type',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DUA = 'dua',
  /** column name */
  ID = 'id',
  /** column name */
  IMAGE = 'image',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  VIMEO_URL = 'vimeo_url'
}

/** aggregate var_pop on columns */
export interface IRecitationsVarPopFields {
  __typename?: 'recitations_var_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IRecitationsVarSampFields {
  __typename?: 'recitations_var_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IRecitationsVarianceFields {
  __typename?: 'recitations_variance_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "repeat_lesson_progresses" */
export interface IRepeatLessonProgresses {
  __typename?: 'repeat_lesson_progresses';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  lesson: ILessons;
  lesson_id: Scalars['uuid'];
  /** An object relationship */
  user: IUsers;
  user_id: Scalars['uuid'];
}

/** aggregated selection of "repeat_lesson_progresses" */
export interface IRepeatLessonProgressesAggregate {
  __typename?: 'repeat_lesson_progresses_aggregate';
  aggregate?: Maybe<IRepeatLessonProgressesAggregateFields>;
  nodes: Array<IRepeatLessonProgresses>;
}

/** aggregate fields of "repeat_lesson_progresses" */
export interface IRepeatLessonProgressesAggregateFields {
  __typename?: 'repeat_lesson_progresses_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IRepeatLessonProgressesMaxFields>;
  min?: Maybe<IRepeatLessonProgressesMinFields>;
}


/** aggregate fields of "repeat_lesson_progresses" */
export interface IRepeatLessonProgressesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IRepeatLessonProgressesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "repeat_lesson_progresses". All fields are combined with a logical 'AND'. */
export interface IRepeatLessonProgressesBoolExp {
  _and?: InputMaybe<Array<IRepeatLessonProgressesBoolExp>>;
  _not?: InputMaybe<IRepeatLessonProgressesBoolExp>;
  _or?: InputMaybe<Array<IRepeatLessonProgressesBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** unique or primary key constraints on table "repeat_lesson_progresses" */
export enum IRepeatLessonProgressesConstraint {
  /** unique or primary key constraint */
  REPEAT_LESSON_PROGRESSES_PKEY = 'repeat_lesson_progresses_pkey',
  /** unique or primary key constraint */
  REPEAT_LESSON_PROGRESSES_USER_ID_LESSON_ID_KEY = 'repeat_lesson_progresses_user_id_lesson_id_key'
}

/** input type for inserting data into table "repeat_lesson_progresses" */
export interface IRepeatLessonProgressesInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson?: InputMaybe<ILessonsObjRelInsertInput>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<IUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface IRepeatLessonProgressesMaxFields {
  __typename?: 'repeat_lesson_progresses_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IRepeatLessonProgressesMinFields {
  __typename?: 'repeat_lesson_progresses_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** response of any mutation on the table "repeat_lesson_progresses" */
export interface IRepeatLessonProgressesMutationResponse {
  __typename?: 'repeat_lesson_progresses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IRepeatLessonProgresses>;
}

/** on_conflict condition type for table "repeat_lesson_progresses" */
export interface IRepeatLessonProgressesOnConflict {
  constraint: IRepeatLessonProgressesConstraint;
  update_columns?: Array<IRepeatLessonProgressesUpdateColumn>;
  where?: InputMaybe<IRepeatLessonProgressesBoolExp>;
}

/** Ordering options when selecting data from "repeat_lesson_progresses". */
export interface IRepeatLessonProgressesOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: repeat_lesson_progresses */
export interface IRepeatLessonProgressesPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "repeat_lesson_progresses" */
export enum IRepeatLessonProgressesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  USER_ID = 'user_id'
}

/** input type for updating data in table "repeat_lesson_progresses" */
export interface IRepeatLessonProgressesSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** update columns of table "repeat_lesson_progresses" */
export enum IRepeatLessonProgressesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  USER_ID = 'user_id'
}

/** columns and relationships of "repeat_lessons_view" */
export interface IRepeatLessonsView {
  __typename?: 'repeat_lessons_view';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  /** An object relationship */
  course_group?: Maybe<ICourseGroups>;
  course_group_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['interval']>;
  end_dt?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  origin_end_dt?: Maybe<Scalars['timestamp']>;
  questions_enabled?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "repeat_lessons_view" */
export interface IRepeatLessonsViewAggregate {
  __typename?: 'repeat_lessons_view_aggregate';
  aggregate?: Maybe<IRepeatLessonsViewAggregateFields>;
  nodes: Array<IRepeatLessonsView>;
}

/** aggregate fields of "repeat_lessons_view" */
export interface IRepeatLessonsViewAggregateFields {
  __typename?: 'repeat_lessons_view_aggregate_fields';
  avg?: Maybe<IRepeatLessonsViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IRepeatLessonsViewMaxFields>;
  min?: Maybe<IRepeatLessonsViewMinFields>;
  stddev?: Maybe<IRepeatLessonsViewStddevFields>;
  stddev_pop?: Maybe<IRepeatLessonsViewStddevPopFields>;
  stddev_samp?: Maybe<IRepeatLessonsViewStddevSampFields>;
  sum?: Maybe<IRepeatLessonsViewSumFields>;
  var_pop?: Maybe<IRepeatLessonsViewVarPopFields>;
  var_samp?: Maybe<IRepeatLessonsViewVarSampFields>;
  variance?: Maybe<IRepeatLessonsViewVarianceFields>;
}


/** aggregate fields of "repeat_lessons_view" */
export interface IRepeatLessonsViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IRepeatLessonsViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IRepeatLessonsViewAvgFields {
  __typename?: 'repeat_lessons_view_avg_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "repeat_lessons_view". All fields are combined with a logical 'AND'. */
export interface IRepeatLessonsViewBoolExp {
  _and?: InputMaybe<Array<IRepeatLessonsViewBoolExp>>;
  _not?: InputMaybe<IRepeatLessonsViewBoolExp>;
  _or?: InputMaybe<Array<IRepeatLessonsViewBoolExp>>;
  content?: InputMaybe<IStringComparisonExp>;
  content_key?: InputMaybe<IIntComparisonExp>;
  course_group?: InputMaybe<ICourseGroupsBoolExp>;
  course_group_id?: InputMaybe<IUuidComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  duration?: InputMaybe<IIntervalComparisonExp>;
  end_dt?: InputMaybe<ITimestamptzComparisonExp>;
  end_time?: InputMaybe<ITimestamptzComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  origin_end_dt?: InputMaybe<ITimestampComparisonExp>;
  questions_enabled?: InputMaybe<IBooleanComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface IRepeatLessonsViewMaxFields {
  __typename?: 'repeat_lessons_view_max_fields';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  course_group_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end_dt?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  origin_end_dt?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IRepeatLessonsViewMinFields {
  __typename?: 'repeat_lessons_view_min_fields';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  course_group_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end_dt?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  origin_end_dt?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "repeat_lessons_view". */
export interface IRepeatLessonsViewOrderBy {
  content?: InputMaybe<IOrderBy>;
  content_key?: InputMaybe<IOrderBy>;
  course_group?: InputMaybe<ICourseGroupsOrderBy>;
  course_group_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  duration?: InputMaybe<IOrderBy>;
  end_dt?: InputMaybe<IOrderBy>;
  end_time?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  origin_end_dt?: InputMaybe<IOrderBy>;
  questions_enabled?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "repeat_lessons_view" */
export enum IRepeatLessonsViewSelectColumn {
  /** column name */
  CONTENT = 'content',
  /** column name */
  CONTENT_KEY = 'content_key',
  /** column name */
  COURSE_GROUP_ID = 'course_group_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  DURATION = 'duration',
  /** column name */
  END_DT = 'end_dt',
  /** column name */
  END_TIME = 'end_time',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  NAME = 'name',
  /** column name */
  ORIGIN_END_DT = 'origin_end_dt',
  /** column name */
  QUESTIONS_ENABLED = 'questions_enabled',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate stddev on columns */
export interface IRepeatLessonsViewStddevFields {
  __typename?: 'repeat_lessons_view_stddev_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IRepeatLessonsViewStddevPopFields {
  __typename?: 'repeat_lessons_view_stddev_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IRepeatLessonsViewStddevSampFields {
  __typename?: 'repeat_lessons_view_stddev_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IRepeatLessonsViewSumFields {
  __typename?: 'repeat_lessons_view_sum_fields';
  content_key?: Maybe<Scalars['Int']>;
}

/** aggregate var_pop on columns */
export interface IRepeatLessonsViewVarPopFields {
  __typename?: 'repeat_lessons_view_var_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IRepeatLessonsViewVarSampFields {
  __typename?: 'repeat_lessons_view_var_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IRepeatLessonsViewVarianceFields {
  __typename?: 'repeat_lessons_view_variance_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "repeat_second_parts_progresses" */
export interface IRepeatSecondPartsProgresses {
  __typename?: 'repeat_second_parts_progresses';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  lesson: ILessons;
  lesson_id: Scalars['uuid'];
  /** An object relationship */
  user: IUsers;
  user_id: Scalars['uuid'];
}

/** aggregated selection of "repeat_second_parts_progresses" */
export interface IRepeatSecondPartsProgressesAggregate {
  __typename?: 'repeat_second_parts_progresses_aggregate';
  aggregate?: Maybe<IRepeatSecondPartsProgressesAggregateFields>;
  nodes: Array<IRepeatSecondPartsProgresses>;
}

/** aggregate fields of "repeat_second_parts_progresses" */
export interface IRepeatSecondPartsProgressesAggregateFields {
  __typename?: 'repeat_second_parts_progresses_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IRepeatSecondPartsProgressesMaxFields>;
  min?: Maybe<IRepeatSecondPartsProgressesMinFields>;
}


/** aggregate fields of "repeat_second_parts_progresses" */
export interface IRepeatSecondPartsProgressesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IRepeatSecondPartsProgressesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "repeat_second_parts_progresses". All fields are combined with a logical 'AND'. */
export interface IRepeatSecondPartsProgressesBoolExp {
  _and?: InputMaybe<Array<IRepeatSecondPartsProgressesBoolExp>>;
  _not?: InputMaybe<IRepeatSecondPartsProgressesBoolExp>;
  _or?: InputMaybe<Array<IRepeatSecondPartsProgressesBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** unique or primary key constraints on table "repeat_second_parts_progresses" */
export enum IRepeatSecondPartsProgressesConstraint {
  /** unique or primary key constraint */
  REPEAT_SECOND_PARTS_PROGRESSES_PKEY = 'repeat_second_parts_progresses_pkey',
  /** unique or primary key constraint */
  REPEAT_SECOND_PARTS_PROGRESSES_USER_ID_LESSON_ID_KEY = 'repeat_second_parts_progresses_user_id_lesson_id_key'
}

/** input type for inserting data into table "repeat_second_parts_progresses" */
export interface IRepeatSecondPartsProgressesInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson?: InputMaybe<ILessonsObjRelInsertInput>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<IUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface IRepeatSecondPartsProgressesMaxFields {
  __typename?: 'repeat_second_parts_progresses_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IRepeatSecondPartsProgressesMinFields {
  __typename?: 'repeat_second_parts_progresses_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** response of any mutation on the table "repeat_second_parts_progresses" */
export interface IRepeatSecondPartsProgressesMutationResponse {
  __typename?: 'repeat_second_parts_progresses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IRepeatSecondPartsProgresses>;
}

/** on_conflict condition type for table "repeat_second_parts_progresses" */
export interface IRepeatSecondPartsProgressesOnConflict {
  constraint: IRepeatSecondPartsProgressesConstraint;
  update_columns?: Array<IRepeatSecondPartsProgressesUpdateColumn>;
  where?: InputMaybe<IRepeatSecondPartsProgressesBoolExp>;
}

/** Ordering options when selecting data from "repeat_second_parts_progresses". */
export interface IRepeatSecondPartsProgressesOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: repeat_second_parts_progresses */
export interface IRepeatSecondPartsProgressesPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "repeat_second_parts_progresses" */
export enum IRepeatSecondPartsProgressesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  USER_ID = 'user_id'
}

/** input type for updating data in table "repeat_second_parts_progresses" */
export interface IRepeatSecondPartsProgressesSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** update columns of table "repeat_second_parts_progresses" */
export enum IRepeatSecondPartsProgressesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  USER_ID = 'user_id'
}

/** columns and relationships of "repeat_second_parts_view" */
export interface IRepeatSecondPartsView {
  __typename?: 'repeat_second_parts_view';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  /** An object relationship */
  course_group?: Maybe<ICourseGroups>;
  course_group_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['interval']>;
  end_dt?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  origin_end_dt?: Maybe<Scalars['timestamp']>;
  questions_enabled?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "repeat_second_parts_view" */
export interface IRepeatSecondPartsViewAggregate {
  __typename?: 'repeat_second_parts_view_aggregate';
  aggregate?: Maybe<IRepeatSecondPartsViewAggregateFields>;
  nodes: Array<IRepeatSecondPartsView>;
}

/** aggregate fields of "repeat_second_parts_view" */
export interface IRepeatSecondPartsViewAggregateFields {
  __typename?: 'repeat_second_parts_view_aggregate_fields';
  avg?: Maybe<IRepeatSecondPartsViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IRepeatSecondPartsViewMaxFields>;
  min?: Maybe<IRepeatSecondPartsViewMinFields>;
  stddev?: Maybe<IRepeatSecondPartsViewStddevFields>;
  stddev_pop?: Maybe<IRepeatSecondPartsViewStddevPopFields>;
  stddev_samp?: Maybe<IRepeatSecondPartsViewStddevSampFields>;
  sum?: Maybe<IRepeatSecondPartsViewSumFields>;
  var_pop?: Maybe<IRepeatSecondPartsViewVarPopFields>;
  var_samp?: Maybe<IRepeatSecondPartsViewVarSampFields>;
  variance?: Maybe<IRepeatSecondPartsViewVarianceFields>;
}


/** aggregate fields of "repeat_second_parts_view" */
export interface IRepeatSecondPartsViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IRepeatSecondPartsViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IRepeatSecondPartsViewAvgFields {
  __typename?: 'repeat_second_parts_view_avg_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "repeat_second_parts_view". All fields are combined with a logical 'AND'. */
export interface IRepeatSecondPartsViewBoolExp {
  _and?: InputMaybe<Array<IRepeatSecondPartsViewBoolExp>>;
  _not?: InputMaybe<IRepeatSecondPartsViewBoolExp>;
  _or?: InputMaybe<Array<IRepeatSecondPartsViewBoolExp>>;
  content?: InputMaybe<IStringComparisonExp>;
  content_key?: InputMaybe<IIntComparisonExp>;
  course_group?: InputMaybe<ICourseGroupsBoolExp>;
  course_group_id?: InputMaybe<IUuidComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  duration?: InputMaybe<IIntervalComparisonExp>;
  end_dt?: InputMaybe<ITimestamptzComparisonExp>;
  end_time?: InputMaybe<ITimestamptzComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  origin_end_dt?: InputMaybe<ITimestampComparisonExp>;
  questions_enabled?: InputMaybe<IBooleanComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface IRepeatSecondPartsViewMaxFields {
  __typename?: 'repeat_second_parts_view_max_fields';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  course_group_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end_dt?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  origin_end_dt?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IRepeatSecondPartsViewMinFields {
  __typename?: 'repeat_second_parts_view_min_fields';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  course_group_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end_dt?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  origin_end_dt?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "repeat_second_parts_view". */
export interface IRepeatSecondPartsViewOrderBy {
  content?: InputMaybe<IOrderBy>;
  content_key?: InputMaybe<IOrderBy>;
  course_group?: InputMaybe<ICourseGroupsOrderBy>;
  course_group_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  duration?: InputMaybe<IOrderBy>;
  end_dt?: InputMaybe<IOrderBy>;
  end_time?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  origin_end_dt?: InputMaybe<IOrderBy>;
  questions_enabled?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "repeat_second_parts_view" */
export enum IRepeatSecondPartsViewSelectColumn {
  /** column name */
  CONTENT = 'content',
  /** column name */
  CONTENT_KEY = 'content_key',
  /** column name */
  COURSE_GROUP_ID = 'course_group_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  DURATION = 'duration',
  /** column name */
  END_DT = 'end_dt',
  /** column name */
  END_TIME = 'end_time',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  NAME = 'name',
  /** column name */
  ORIGIN_END_DT = 'origin_end_dt',
  /** column name */
  QUESTIONS_ENABLED = 'questions_enabled',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate stddev on columns */
export interface IRepeatSecondPartsViewStddevFields {
  __typename?: 'repeat_second_parts_view_stddev_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IRepeatSecondPartsViewStddevPopFields {
  __typename?: 'repeat_second_parts_view_stddev_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IRepeatSecondPartsViewStddevSampFields {
  __typename?: 'repeat_second_parts_view_stddev_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IRepeatSecondPartsViewSumFields {
  __typename?: 'repeat_second_parts_view_sum_fields';
  content_key?: Maybe<Scalars['Int']>;
}

/** aggregate var_pop on columns */
export interface IRepeatSecondPartsViewVarPopFields {
  __typename?: 'repeat_second_parts_view_var_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IRepeatSecondPartsViewVarSampFields {
  __typename?: 'repeat_second_parts_view_var_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IRepeatSecondPartsViewVarianceFields {
  __typename?: 'repeat_second_parts_view_variance_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "repeat_third_parts_progresses" */
export interface IRepeatThirdPartsProgresses {
  __typename?: 'repeat_third_parts_progresses';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  lesson: ILessons;
  lesson_id: Scalars['uuid'];
  /** An object relationship */
  user: IUsers;
  user_id: Scalars['uuid'];
}

/** aggregated selection of "repeat_third_parts_progresses" */
export interface IRepeatThirdPartsProgressesAggregate {
  __typename?: 'repeat_third_parts_progresses_aggregate';
  aggregate?: Maybe<IRepeatThirdPartsProgressesAggregateFields>;
  nodes: Array<IRepeatThirdPartsProgresses>;
}

/** aggregate fields of "repeat_third_parts_progresses" */
export interface IRepeatThirdPartsProgressesAggregateFields {
  __typename?: 'repeat_third_parts_progresses_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IRepeatThirdPartsProgressesMaxFields>;
  min?: Maybe<IRepeatThirdPartsProgressesMinFields>;
}


/** aggregate fields of "repeat_third_parts_progresses" */
export interface IRepeatThirdPartsProgressesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IRepeatThirdPartsProgressesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "repeat_third_parts_progresses". All fields are combined with a logical 'AND'. */
export interface IRepeatThirdPartsProgressesBoolExp {
  _and?: InputMaybe<Array<IRepeatThirdPartsProgressesBoolExp>>;
  _not?: InputMaybe<IRepeatThirdPartsProgressesBoolExp>;
  _or?: InputMaybe<Array<IRepeatThirdPartsProgressesBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** unique or primary key constraints on table "repeat_third_parts_progresses" */
export enum IRepeatThirdPartsProgressesConstraint {
  /** unique or primary key constraint */
  REPEAT_THIRD_PARTS_PROGRESSES_PKEY = 'repeat_third_parts_progresses_pkey',
  /** unique or primary key constraint */
  REPEAT_THIRD_PARTS_PROGRESSES_USER_ID_LESSON_ID_KEY = 'repeat_third_parts_progresses_user_id_lesson_id_key'
}

/** input type for inserting data into table "repeat_third_parts_progresses" */
export interface IRepeatThirdPartsProgressesInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson?: InputMaybe<ILessonsObjRelInsertInput>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<IUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface IRepeatThirdPartsProgressesMaxFields {
  __typename?: 'repeat_third_parts_progresses_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IRepeatThirdPartsProgressesMinFields {
  __typename?: 'repeat_third_parts_progresses_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** response of any mutation on the table "repeat_third_parts_progresses" */
export interface IRepeatThirdPartsProgressesMutationResponse {
  __typename?: 'repeat_third_parts_progresses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IRepeatThirdPartsProgresses>;
}

/** on_conflict condition type for table "repeat_third_parts_progresses" */
export interface IRepeatThirdPartsProgressesOnConflict {
  constraint: IRepeatThirdPartsProgressesConstraint;
  update_columns?: Array<IRepeatThirdPartsProgressesUpdateColumn>;
  where?: InputMaybe<IRepeatThirdPartsProgressesBoolExp>;
}

/** Ordering options when selecting data from "repeat_third_parts_progresses". */
export interface IRepeatThirdPartsProgressesOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: repeat_third_parts_progresses */
export interface IRepeatThirdPartsProgressesPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "repeat_third_parts_progresses" */
export enum IRepeatThirdPartsProgressesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  USER_ID = 'user_id'
}

/** input type for updating data in table "repeat_third_parts_progresses" */
export interface IRepeatThirdPartsProgressesSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** update columns of table "repeat_third_parts_progresses" */
export enum IRepeatThirdPartsProgressesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  USER_ID = 'user_id'
}

/** columns and relationships of "repeat_third_parts_view" */
export interface IRepeatThirdPartsView {
  __typename?: 'repeat_third_parts_view';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  /** An object relationship */
  course_group?: Maybe<ICourseGroups>;
  course_group_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['interval']>;
  end_dt?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  origin_end_dt?: Maybe<Scalars['timestamp']>;
  questions_enabled?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "repeat_third_parts_view" */
export interface IRepeatThirdPartsViewAggregate {
  __typename?: 'repeat_third_parts_view_aggregate';
  aggregate?: Maybe<IRepeatThirdPartsViewAggregateFields>;
  nodes: Array<IRepeatThirdPartsView>;
}

/** aggregate fields of "repeat_third_parts_view" */
export interface IRepeatThirdPartsViewAggregateFields {
  __typename?: 'repeat_third_parts_view_aggregate_fields';
  avg?: Maybe<IRepeatThirdPartsViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IRepeatThirdPartsViewMaxFields>;
  min?: Maybe<IRepeatThirdPartsViewMinFields>;
  stddev?: Maybe<IRepeatThirdPartsViewStddevFields>;
  stddev_pop?: Maybe<IRepeatThirdPartsViewStddevPopFields>;
  stddev_samp?: Maybe<IRepeatThirdPartsViewStddevSampFields>;
  sum?: Maybe<IRepeatThirdPartsViewSumFields>;
  var_pop?: Maybe<IRepeatThirdPartsViewVarPopFields>;
  var_samp?: Maybe<IRepeatThirdPartsViewVarSampFields>;
  variance?: Maybe<IRepeatThirdPartsViewVarianceFields>;
}


/** aggregate fields of "repeat_third_parts_view" */
export interface IRepeatThirdPartsViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IRepeatThirdPartsViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IRepeatThirdPartsViewAvgFields {
  __typename?: 'repeat_third_parts_view_avg_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "repeat_third_parts_view". All fields are combined with a logical 'AND'. */
export interface IRepeatThirdPartsViewBoolExp {
  _and?: InputMaybe<Array<IRepeatThirdPartsViewBoolExp>>;
  _not?: InputMaybe<IRepeatThirdPartsViewBoolExp>;
  _or?: InputMaybe<Array<IRepeatThirdPartsViewBoolExp>>;
  content?: InputMaybe<IStringComparisonExp>;
  content_key?: InputMaybe<IIntComparisonExp>;
  course_group?: InputMaybe<ICourseGroupsBoolExp>;
  course_group_id?: InputMaybe<IUuidComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  duration?: InputMaybe<IIntervalComparisonExp>;
  end_dt?: InputMaybe<ITimestamptzComparisonExp>;
  end_time?: InputMaybe<ITimestamptzComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  origin_end_dt?: InputMaybe<ITimestampComparisonExp>;
  questions_enabled?: InputMaybe<IBooleanComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface IRepeatThirdPartsViewMaxFields {
  __typename?: 'repeat_third_parts_view_max_fields';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  course_group_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end_dt?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  origin_end_dt?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IRepeatThirdPartsViewMinFields {
  __typename?: 'repeat_third_parts_view_min_fields';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  course_group_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end_dt?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  origin_end_dt?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "repeat_third_parts_view". */
export interface IRepeatThirdPartsViewOrderBy {
  content?: InputMaybe<IOrderBy>;
  content_key?: InputMaybe<IOrderBy>;
  course_group?: InputMaybe<ICourseGroupsOrderBy>;
  course_group_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  duration?: InputMaybe<IOrderBy>;
  end_dt?: InputMaybe<IOrderBy>;
  end_time?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  origin_end_dt?: InputMaybe<IOrderBy>;
  questions_enabled?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "repeat_third_parts_view" */
export enum IRepeatThirdPartsViewSelectColumn {
  /** column name */
  CONTENT = 'content',
  /** column name */
  CONTENT_KEY = 'content_key',
  /** column name */
  COURSE_GROUP_ID = 'course_group_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  DURATION = 'duration',
  /** column name */
  END_DT = 'end_dt',
  /** column name */
  END_TIME = 'end_time',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  NAME = 'name',
  /** column name */
  ORIGIN_END_DT = 'origin_end_dt',
  /** column name */
  QUESTIONS_ENABLED = 'questions_enabled',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate stddev on columns */
export interface IRepeatThirdPartsViewStddevFields {
  __typename?: 'repeat_third_parts_view_stddev_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IRepeatThirdPartsViewStddevPopFields {
  __typename?: 'repeat_third_parts_view_stddev_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IRepeatThirdPartsViewStddevSampFields {
  __typename?: 'repeat_third_parts_view_stddev_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IRepeatThirdPartsViewSumFields {
  __typename?: 'repeat_third_parts_view_sum_fields';
  content_key?: Maybe<Scalars['Int']>;
}

/** aggregate var_pop on columns */
export interface IRepeatThirdPartsViewVarPopFields {
  __typename?: 'repeat_third_parts_view_var_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IRepeatThirdPartsViewVarSampFields {
  __typename?: 'repeat_third_parts_view_var_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IRepeatThirdPartsViewVarianceFields {
  __typename?: 'repeat_third_parts_view_variance_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "schema_migrations" */
export interface ISchemaMigrations {
  __typename?: 'schema_migrations';
  version: Scalars['String'];
}

/** aggregated selection of "schema_migrations" */
export interface ISchemaMigrationsAggregate {
  __typename?: 'schema_migrations_aggregate';
  aggregate?: Maybe<ISchemaMigrationsAggregateFields>;
  nodes: Array<ISchemaMigrations>;
}

/** aggregate fields of "schema_migrations" */
export interface ISchemaMigrationsAggregateFields {
  __typename?: 'schema_migrations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ISchemaMigrationsMaxFields>;
  min?: Maybe<ISchemaMigrationsMinFields>;
}


/** aggregate fields of "schema_migrations" */
export interface ISchemaMigrationsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ISchemaMigrationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "schema_migrations". All fields are combined with a logical 'AND'. */
export interface ISchemaMigrationsBoolExp {
  _and?: InputMaybe<Array<ISchemaMigrationsBoolExp>>;
  _not?: InputMaybe<ISchemaMigrationsBoolExp>;
  _or?: InputMaybe<Array<ISchemaMigrationsBoolExp>>;
  version?: InputMaybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "schema_migrations" */
export enum ISchemaMigrationsConstraint {
  /** unique or primary key constraint */
  SCHEMA_MIGRATIONS_PKEY = 'schema_migrations_pkey'
}

/** input type for inserting data into table "schema_migrations" */
export interface ISchemaMigrationsInsertInput {
  version?: InputMaybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface ISchemaMigrationsMaxFields {
  __typename?: 'schema_migrations_max_fields';
  version?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface ISchemaMigrationsMinFields {
  __typename?: 'schema_migrations_min_fields';
  version?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "schema_migrations" */
export interface ISchemaMigrationsMutationResponse {
  __typename?: 'schema_migrations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ISchemaMigrations>;
}

/** on_conflict condition type for table "schema_migrations" */
export interface ISchemaMigrationsOnConflict {
  constraint: ISchemaMigrationsConstraint;
  update_columns?: Array<ISchemaMigrationsUpdateColumn>;
  where?: InputMaybe<ISchemaMigrationsBoolExp>;
}

/** Ordering options when selecting data from "schema_migrations". */
export interface ISchemaMigrationsOrderBy {
  version?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: schema_migrations */
export interface ISchemaMigrationsPkColumnsInput {
  version: Scalars['String'];
}

/** select columns of table "schema_migrations" */
export enum ISchemaMigrationsSelectColumn {
  /** column name */
  VERSION = 'version'
}

/** input type for updating data in table "schema_migrations" */
export interface ISchemaMigrationsSetInput {
  version?: InputMaybe<Scalars['String']>;
}

/** update columns of table "schema_migrations" */
export enum ISchemaMigrationsUpdateColumn {
  /** column name */
  VERSION = 'version'
}

/** columns and relationships of "second_lesson_part_mail_reminders" */
export interface ISecondLessonPartMailReminders {
  __typename?: 'second_lesson_part_mail_reminders';
  datediff?: Maybe<Scalars['float8']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "second_lesson_part_mail_reminders" */
export interface ISecondLessonPartMailRemindersAggregate {
  __typename?: 'second_lesson_part_mail_reminders_aggregate';
  aggregate?: Maybe<ISecondLessonPartMailRemindersAggregateFields>;
  nodes: Array<ISecondLessonPartMailReminders>;
}

/** aggregate fields of "second_lesson_part_mail_reminders" */
export interface ISecondLessonPartMailRemindersAggregateFields {
  __typename?: 'second_lesson_part_mail_reminders_aggregate_fields';
  avg?: Maybe<ISecondLessonPartMailRemindersAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ISecondLessonPartMailRemindersMaxFields>;
  min?: Maybe<ISecondLessonPartMailRemindersMinFields>;
  stddev?: Maybe<ISecondLessonPartMailRemindersStddevFields>;
  stddev_pop?: Maybe<ISecondLessonPartMailRemindersStddevPopFields>;
  stddev_samp?: Maybe<ISecondLessonPartMailRemindersStddevSampFields>;
  sum?: Maybe<ISecondLessonPartMailRemindersSumFields>;
  var_pop?: Maybe<ISecondLessonPartMailRemindersVarPopFields>;
  var_samp?: Maybe<ISecondLessonPartMailRemindersVarSampFields>;
  variance?: Maybe<ISecondLessonPartMailRemindersVarianceFields>;
}


/** aggregate fields of "second_lesson_part_mail_reminders" */
export interface ISecondLessonPartMailRemindersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ISecondLessonPartMailRemindersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface ISecondLessonPartMailRemindersAvgFields {
  __typename?: 'second_lesson_part_mail_reminders_avg_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "second_lesson_part_mail_reminders". All fields are combined with a logical 'AND'. */
export interface ISecondLessonPartMailRemindersBoolExp {
  _and?: InputMaybe<Array<ISecondLessonPartMailRemindersBoolExp>>;
  _not?: InputMaybe<ISecondLessonPartMailRemindersBoolExp>;
  _or?: InputMaybe<Array<ISecondLessonPartMailRemindersBoolExp>>;
  datediff?: InputMaybe<IFloat8ComparisonExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface ISecondLessonPartMailRemindersMaxFields {
  __typename?: 'second_lesson_part_mail_reminders_max_fields';
  datediff?: Maybe<Scalars['float8']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface ISecondLessonPartMailRemindersMinFields {
  __typename?: 'second_lesson_part_mail_reminders_min_fields';
  datediff?: Maybe<Scalars['float8']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "second_lesson_part_mail_reminders". */
export interface ISecondLessonPartMailRemindersOrderBy {
  datediff?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "second_lesson_part_mail_reminders" */
export enum ISecondLessonPartMailRemindersSelectColumn {
  /** column name */
  DATEDIFF = 'datediff',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate stddev on columns */
export interface ISecondLessonPartMailRemindersStddevFields {
  __typename?: 'second_lesson_part_mail_reminders_stddev_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface ISecondLessonPartMailRemindersStddevPopFields {
  __typename?: 'second_lesson_part_mail_reminders_stddev_pop_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface ISecondLessonPartMailRemindersStddevSampFields {
  __typename?: 'second_lesson_part_mail_reminders_stddev_samp_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface ISecondLessonPartMailRemindersSumFields {
  __typename?: 'second_lesson_part_mail_reminders_sum_fields';
  datediff?: Maybe<Scalars['float8']>;
}

/** aggregate var_pop on columns */
export interface ISecondLessonPartMailRemindersVarPopFields {
  __typename?: 'second_lesson_part_mail_reminders_var_pop_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface ISecondLessonPartMailRemindersVarSampFields {
  __typename?: 'second_lesson_part_mail_reminders_var_samp_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface ISecondLessonPartMailRemindersVarianceFields {
  __typename?: 'second_lesson_part_mail_reminders_variance_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "semi_stream_lessons" */
export interface ISemiStreamLessons {
  __typename?: 'semi_stream_lessons';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  /** An object relationship */
  course?: Maybe<ICourses>;
  /** An object relationship */
  course_group?: Maybe<ICourseGroups>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  current_progress_time?: Maybe<Scalars['float8']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['interval']>;
  duration_in_seconds?: Maybe<Scalars['Int']>;
  end_dt?: Maybe<Scalars['timestamp']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  hide_controls?: Maybe<Scalars['Boolean']>;
  is_standby?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  not_available?: Maybe<Scalars['Boolean']>;
  published?: Maybe<Scalars['Boolean']>;
  qa_content?: Maybe<Scalars['String']>;
  second_part?: Maybe<Scalars['String']>;
  start_dt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  subscription?: Maybe<ISubscriptions>;
  subscription_id?: Maybe<Scalars['uuid']>;
  third_part?: Maybe<Scalars['String']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  watchable?: Maybe<Scalars['Boolean']>;
}

/** aggregated selection of "semi_stream_lessons" */
export interface ISemiStreamLessonsAggregate {
  __typename?: 'semi_stream_lessons_aggregate';
  aggregate?: Maybe<ISemiStreamLessonsAggregateFields>;
  nodes: Array<ISemiStreamLessons>;
}

/** aggregate fields of "semi_stream_lessons" */
export interface ISemiStreamLessonsAggregateFields {
  __typename?: 'semi_stream_lessons_aggregate_fields';
  avg?: Maybe<ISemiStreamLessonsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ISemiStreamLessonsMaxFields>;
  min?: Maybe<ISemiStreamLessonsMinFields>;
  stddev?: Maybe<ISemiStreamLessonsStddevFields>;
  stddev_pop?: Maybe<ISemiStreamLessonsStddevPopFields>;
  stddev_samp?: Maybe<ISemiStreamLessonsStddevSampFields>;
  sum?: Maybe<ISemiStreamLessonsSumFields>;
  var_pop?: Maybe<ISemiStreamLessonsVarPopFields>;
  var_samp?: Maybe<ISemiStreamLessonsVarSampFields>;
  variance?: Maybe<ISemiStreamLessonsVarianceFields>;
}


/** aggregate fields of "semi_stream_lessons" */
export interface ISemiStreamLessonsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ISemiStreamLessonsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "semi_stream_lessons" */
export interface ISemiStreamLessonsAggregateOrderBy {
  avg?: InputMaybe<ISemiStreamLessonsAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<ISemiStreamLessonsMaxOrderBy>;
  min?: InputMaybe<ISemiStreamLessonsMinOrderBy>;
  stddev?: InputMaybe<ISemiStreamLessonsStddevOrderBy>;
  stddev_pop?: InputMaybe<ISemiStreamLessonsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ISemiStreamLessonsStddevSampOrderBy>;
  sum?: InputMaybe<ISemiStreamLessonsSumOrderBy>;
  var_pop?: InputMaybe<ISemiStreamLessonsVarPopOrderBy>;
  var_samp?: InputMaybe<ISemiStreamLessonsVarSampOrderBy>;
  variance?: InputMaybe<ISemiStreamLessonsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "semi_stream_lessons" */
export interface ISemiStreamLessonsArrRelInsertInput {
  data: Array<ISemiStreamLessonsInsertInput>;
}

/** aggregate avg on columns */
export interface ISemiStreamLessonsAvgFields {
  __typename?: 'semi_stream_lessons_avg_fields';
  content_key?: Maybe<Scalars['Float']>;
  current_progress_time?: Maybe<Scalars['Float']>;
  duration_in_seconds?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "semi_stream_lessons" */
export interface ISemiStreamLessonsAvgOrderBy {
  content_key?: InputMaybe<IOrderBy>;
  current_progress_time?: InputMaybe<IOrderBy>;
  duration_in_seconds?: InputMaybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "semi_stream_lessons". All fields are combined with a logical 'AND'. */
export interface ISemiStreamLessonsBoolExp {
  _and?: InputMaybe<Array<ISemiStreamLessonsBoolExp>>;
  _not?: InputMaybe<ISemiStreamLessonsBoolExp>;
  _or?: InputMaybe<Array<ISemiStreamLessonsBoolExp>>;
  content?: InputMaybe<IStringComparisonExp>;
  content_key?: InputMaybe<IIntComparisonExp>;
  course?: InputMaybe<ICoursesBoolExp>;
  course_group?: InputMaybe<ICourseGroupsBoolExp>;
  course_group_id?: InputMaybe<IUuidComparisonExp>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  current_progress_time?: InputMaybe<IFloat8ComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  duration?: InputMaybe<IIntervalComparisonExp>;
  duration_in_seconds?: InputMaybe<IIntComparisonExp>;
  end_dt?: InputMaybe<ITimestampComparisonExp>;
  end_time?: InputMaybe<ITimestamptzComparisonExp>;
  hide_controls?: InputMaybe<IBooleanComparisonExp>;
  is_standby?: InputMaybe<IBooleanComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  not_available?: InputMaybe<IBooleanComparisonExp>;
  published?: InputMaybe<IBooleanComparisonExp>;
  qa_content?: InputMaybe<IStringComparisonExp>;
  second_part?: InputMaybe<IStringComparisonExp>;
  start_dt?: InputMaybe<ITimestampComparisonExp>;
  subscription?: InputMaybe<ISubscriptionsBoolExp>;
  subscription_id?: InputMaybe<IUuidComparisonExp>;
  third_part?: InputMaybe<IStringComparisonExp>;
  unlocked_at?: InputMaybe<ITimestamptzComparisonExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
  watchable?: InputMaybe<IBooleanComparisonExp>;
}

/** input type for inserting data into table "semi_stream_lessons" */
export interface ISemiStreamLessonsInsertInput {
  content?: InputMaybe<Scalars['String']>;
  content_key?: InputMaybe<Scalars['Int']>;
  course?: InputMaybe<ICoursesObjRelInsertInput>;
  course_group?: InputMaybe<ICourseGroupsObjRelInsertInput>;
  course_group_id?: InputMaybe<Scalars['uuid']>;
  course_id?: InputMaybe<Scalars['uuid']>;
  current_progress_time?: InputMaybe<Scalars['float8']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['interval']>;
  duration_in_seconds?: InputMaybe<Scalars['Int']>;
  end_dt?: InputMaybe<Scalars['timestamp']>;
  end_time?: InputMaybe<Scalars['timestamptz']>;
  hide_controls?: InputMaybe<Scalars['Boolean']>;
  is_standby?: InputMaybe<Scalars['Boolean']>;
  lesson?: InputMaybe<ILessonsObjRelInsertInput>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  not_available?: InputMaybe<Scalars['Boolean']>;
  published?: InputMaybe<Scalars['Boolean']>;
  qa_content?: InputMaybe<Scalars['String']>;
  second_part?: InputMaybe<Scalars['String']>;
  start_dt?: InputMaybe<Scalars['timestamp']>;
  subscription?: InputMaybe<ISubscriptionsObjRelInsertInput>;
  subscription_id?: InputMaybe<Scalars['uuid']>;
  third_part?: InputMaybe<Scalars['String']>;
  unlocked_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  watchable?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate max on columns */
export interface ISemiStreamLessonsMaxFields {
  __typename?: 'semi_stream_lessons_max_fields';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  current_progress_time?: Maybe<Scalars['float8']>;
  description?: Maybe<Scalars['String']>;
  duration_in_seconds?: Maybe<Scalars['Int']>;
  end_dt?: Maybe<Scalars['timestamp']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  qa_content?: Maybe<Scalars['String']>;
  second_part?: Maybe<Scalars['String']>;
  start_dt?: Maybe<Scalars['timestamp']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  third_part?: Maybe<Scalars['String']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "semi_stream_lessons" */
export interface ISemiStreamLessonsMaxOrderBy {
  content?: InputMaybe<IOrderBy>;
  content_key?: InputMaybe<IOrderBy>;
  course_group_id?: InputMaybe<IOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  current_progress_time?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  duration_in_seconds?: InputMaybe<IOrderBy>;
  end_dt?: InputMaybe<IOrderBy>;
  end_time?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  qa_content?: InputMaybe<IOrderBy>;
  second_part?: InputMaybe<IOrderBy>;
  start_dt?: InputMaybe<IOrderBy>;
  subscription_id?: InputMaybe<IOrderBy>;
  third_part?: InputMaybe<IOrderBy>;
  unlocked_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface ISemiStreamLessonsMinFields {
  __typename?: 'semi_stream_lessons_min_fields';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  current_progress_time?: Maybe<Scalars['float8']>;
  description?: Maybe<Scalars['String']>;
  duration_in_seconds?: Maybe<Scalars['Int']>;
  end_dt?: Maybe<Scalars['timestamp']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  qa_content?: Maybe<Scalars['String']>;
  second_part?: Maybe<Scalars['String']>;
  start_dt?: Maybe<Scalars['timestamp']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  third_part?: Maybe<Scalars['String']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "semi_stream_lessons" */
export interface ISemiStreamLessonsMinOrderBy {
  content?: InputMaybe<IOrderBy>;
  content_key?: InputMaybe<IOrderBy>;
  course_group_id?: InputMaybe<IOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  current_progress_time?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  duration_in_seconds?: InputMaybe<IOrderBy>;
  end_dt?: InputMaybe<IOrderBy>;
  end_time?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  qa_content?: InputMaybe<IOrderBy>;
  second_part?: InputMaybe<IOrderBy>;
  start_dt?: InputMaybe<IOrderBy>;
  subscription_id?: InputMaybe<IOrderBy>;
  third_part?: InputMaybe<IOrderBy>;
  unlocked_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** Ordering options when selecting data from "semi_stream_lessons". */
export interface ISemiStreamLessonsOrderBy {
  content?: InputMaybe<IOrderBy>;
  content_key?: InputMaybe<IOrderBy>;
  course?: InputMaybe<ICoursesOrderBy>;
  course_group?: InputMaybe<ICourseGroupsOrderBy>;
  course_group_id?: InputMaybe<IOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  current_progress_time?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  duration?: InputMaybe<IOrderBy>;
  duration_in_seconds?: InputMaybe<IOrderBy>;
  end_dt?: InputMaybe<IOrderBy>;
  end_time?: InputMaybe<IOrderBy>;
  hide_controls?: InputMaybe<IOrderBy>;
  is_standby?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  not_available?: InputMaybe<IOrderBy>;
  published?: InputMaybe<IOrderBy>;
  qa_content?: InputMaybe<IOrderBy>;
  second_part?: InputMaybe<IOrderBy>;
  start_dt?: InputMaybe<IOrderBy>;
  subscription?: InputMaybe<ISubscriptionsOrderBy>;
  subscription_id?: InputMaybe<IOrderBy>;
  third_part?: InputMaybe<IOrderBy>;
  unlocked_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  watchable?: InputMaybe<IOrderBy>;
}

/** select columns of table "semi_stream_lessons" */
export enum ISemiStreamLessonsSelectColumn {
  /** column name */
  CONTENT = 'content',
  /** column name */
  CONTENT_KEY = 'content_key',
  /** column name */
  COURSE_GROUP_ID = 'course_group_id',
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  CURRENT_PROGRESS_TIME = 'current_progress_time',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  DURATION = 'duration',
  /** column name */
  DURATION_IN_SECONDS = 'duration_in_seconds',
  /** column name */
  END_DT = 'end_dt',
  /** column name */
  END_TIME = 'end_time',
  /** column name */
  HIDE_CONTROLS = 'hide_controls',
  /** column name */
  IS_STANDBY = 'is_standby',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  NAME = 'name',
  /** column name */
  NOT_AVAILABLE = 'not_available',
  /** column name */
  PUBLISHED = 'published',
  /** column name */
  QA_CONTENT = 'qa_content',
  /** column name */
  SECOND_PART = 'second_part',
  /** column name */
  START_DT = 'start_dt',
  /** column name */
  SUBSCRIPTION_ID = 'subscription_id',
  /** column name */
  THIRD_PART = 'third_part',
  /** column name */
  UNLOCKED_AT = 'unlocked_at',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  WATCHABLE = 'watchable'
}

/** aggregate stddev on columns */
export interface ISemiStreamLessonsStddevFields {
  __typename?: 'semi_stream_lessons_stddev_fields';
  content_key?: Maybe<Scalars['Float']>;
  current_progress_time?: Maybe<Scalars['Float']>;
  duration_in_seconds?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "semi_stream_lessons" */
export interface ISemiStreamLessonsStddevOrderBy {
  content_key?: InputMaybe<IOrderBy>;
  current_progress_time?: InputMaybe<IOrderBy>;
  duration_in_seconds?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface ISemiStreamLessonsStddevPopFields {
  __typename?: 'semi_stream_lessons_stddev_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
  current_progress_time?: Maybe<Scalars['Float']>;
  duration_in_seconds?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "semi_stream_lessons" */
export interface ISemiStreamLessonsStddevPopOrderBy {
  content_key?: InputMaybe<IOrderBy>;
  current_progress_time?: InputMaybe<IOrderBy>;
  duration_in_seconds?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface ISemiStreamLessonsStddevSampFields {
  __typename?: 'semi_stream_lessons_stddev_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
  current_progress_time?: Maybe<Scalars['Float']>;
  duration_in_seconds?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "semi_stream_lessons" */
export interface ISemiStreamLessonsStddevSampOrderBy {
  content_key?: InputMaybe<IOrderBy>;
  current_progress_time?: InputMaybe<IOrderBy>;
  duration_in_seconds?: InputMaybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface ISemiStreamLessonsSumFields {
  __typename?: 'semi_stream_lessons_sum_fields';
  content_key?: Maybe<Scalars['Int']>;
  current_progress_time?: Maybe<Scalars['float8']>;
  duration_in_seconds?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "semi_stream_lessons" */
export interface ISemiStreamLessonsSumOrderBy {
  content_key?: InputMaybe<IOrderBy>;
  current_progress_time?: InputMaybe<IOrderBy>;
  duration_in_seconds?: InputMaybe<IOrderBy>;
}

/** aggregate var_pop on columns */
export interface ISemiStreamLessonsVarPopFields {
  __typename?: 'semi_stream_lessons_var_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
  current_progress_time?: Maybe<Scalars['Float']>;
  duration_in_seconds?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "semi_stream_lessons" */
export interface ISemiStreamLessonsVarPopOrderBy {
  content_key?: InputMaybe<IOrderBy>;
  current_progress_time?: InputMaybe<IOrderBy>;
  duration_in_seconds?: InputMaybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface ISemiStreamLessonsVarSampFields {
  __typename?: 'semi_stream_lessons_var_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
  current_progress_time?: Maybe<Scalars['Float']>;
  duration_in_seconds?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "semi_stream_lessons" */
export interface ISemiStreamLessonsVarSampOrderBy {
  content_key?: InputMaybe<IOrderBy>;
  current_progress_time?: InputMaybe<IOrderBy>;
  duration_in_seconds?: InputMaybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface ISemiStreamLessonsVarianceFields {
  __typename?: 'semi_stream_lessons_variance_fields';
  content_key?: Maybe<Scalars['Float']>;
  current_progress_time?: Maybe<Scalars['Float']>;
  duration_in_seconds?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "semi_stream_lessons" */
export interface ISemiStreamLessonsVarianceOrderBy {
  content_key?: InputMaybe<IOrderBy>;
  current_progress_time?: InputMaybe<IOrderBy>;
  duration_in_seconds?: InputMaybe<IOrderBy>;
}

/** columns and relationships of "semi_stream_second_parts_view" */
export interface ISemiStreamSecondPartsView {
  __typename?: 'semi_stream_second_parts_view';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  /** An object relationship */
  course?: Maybe<ICourses>;
  /** An object relationship */
  course_group?: Maybe<ICourseGroups>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['interval']>;
  duration_in_seconds?: Maybe<Scalars['Int']>;
  end_dt?: Maybe<Scalars['timestamp']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  is_standby?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  not_available?: Maybe<Scalars['Boolean']>;
  published?: Maybe<Scalars['Boolean']>;
  second_part?: Maybe<Scalars['String']>;
  start_dt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  subscription?: Maybe<ISubscriptions>;
  subscription_id?: Maybe<Scalars['uuid']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id?: Maybe<Scalars['uuid']>;
  watchable?: Maybe<Scalars['Boolean']>;
}

/** aggregated selection of "semi_stream_second_parts_view" */
export interface ISemiStreamSecondPartsViewAggregate {
  __typename?: 'semi_stream_second_parts_view_aggregate';
  aggregate?: Maybe<ISemiStreamSecondPartsViewAggregateFields>;
  nodes: Array<ISemiStreamSecondPartsView>;
}

/** aggregate fields of "semi_stream_second_parts_view" */
export interface ISemiStreamSecondPartsViewAggregateFields {
  __typename?: 'semi_stream_second_parts_view_aggregate_fields';
  avg?: Maybe<ISemiStreamSecondPartsViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ISemiStreamSecondPartsViewMaxFields>;
  min?: Maybe<ISemiStreamSecondPartsViewMinFields>;
  stddev?: Maybe<ISemiStreamSecondPartsViewStddevFields>;
  stddev_pop?: Maybe<ISemiStreamSecondPartsViewStddevPopFields>;
  stddev_samp?: Maybe<ISemiStreamSecondPartsViewStddevSampFields>;
  sum?: Maybe<ISemiStreamSecondPartsViewSumFields>;
  var_pop?: Maybe<ISemiStreamSecondPartsViewVarPopFields>;
  var_samp?: Maybe<ISemiStreamSecondPartsViewVarSampFields>;
  variance?: Maybe<ISemiStreamSecondPartsViewVarianceFields>;
}


/** aggregate fields of "semi_stream_second_parts_view" */
export interface ISemiStreamSecondPartsViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ISemiStreamSecondPartsViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface ISemiStreamSecondPartsViewAvgFields {
  __typename?: 'semi_stream_second_parts_view_avg_fields';
  content_key?: Maybe<Scalars['Float']>;
  duration_in_seconds?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "semi_stream_second_parts_view". All fields are combined with a logical 'AND'. */
export interface ISemiStreamSecondPartsViewBoolExp {
  _and?: InputMaybe<Array<ISemiStreamSecondPartsViewBoolExp>>;
  _not?: InputMaybe<ISemiStreamSecondPartsViewBoolExp>;
  _or?: InputMaybe<Array<ISemiStreamSecondPartsViewBoolExp>>;
  content?: InputMaybe<IStringComparisonExp>;
  content_key?: InputMaybe<IIntComparisonExp>;
  course?: InputMaybe<ICoursesBoolExp>;
  course_group?: InputMaybe<ICourseGroupsBoolExp>;
  course_group_id?: InputMaybe<IUuidComparisonExp>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  duration?: InputMaybe<IIntervalComparisonExp>;
  duration_in_seconds?: InputMaybe<IIntComparisonExp>;
  end_dt?: InputMaybe<ITimestampComparisonExp>;
  end_time?: InputMaybe<ITimestamptzComparisonExp>;
  is_standby?: InputMaybe<IBooleanComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  not_available?: InputMaybe<IBooleanComparisonExp>;
  published?: InputMaybe<IBooleanComparisonExp>;
  second_part?: InputMaybe<IStringComparisonExp>;
  start_dt?: InputMaybe<ITimestampComparisonExp>;
  subscription?: InputMaybe<ISubscriptionsBoolExp>;
  subscription_id?: InputMaybe<IUuidComparisonExp>;
  unlocked_at?: InputMaybe<ITimestamptzComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
  watchable?: InputMaybe<IBooleanComparisonExp>;
}

/** aggregate max on columns */
export interface ISemiStreamSecondPartsViewMaxFields {
  __typename?: 'semi_stream_second_parts_view_max_fields';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  duration_in_seconds?: Maybe<Scalars['Int']>;
  end_dt?: Maybe<Scalars['timestamp']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  second_part?: Maybe<Scalars['String']>;
  start_dt?: Maybe<Scalars['timestamp']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface ISemiStreamSecondPartsViewMinFields {
  __typename?: 'semi_stream_second_parts_view_min_fields';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  duration_in_seconds?: Maybe<Scalars['Int']>;
  end_dt?: Maybe<Scalars['timestamp']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  second_part?: Maybe<Scalars['String']>;
  start_dt?: Maybe<Scalars['timestamp']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "semi_stream_second_parts_view". */
export interface ISemiStreamSecondPartsViewOrderBy {
  content?: InputMaybe<IOrderBy>;
  content_key?: InputMaybe<IOrderBy>;
  course?: InputMaybe<ICoursesOrderBy>;
  course_group?: InputMaybe<ICourseGroupsOrderBy>;
  course_group_id?: InputMaybe<IOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  duration?: InputMaybe<IOrderBy>;
  duration_in_seconds?: InputMaybe<IOrderBy>;
  end_dt?: InputMaybe<IOrderBy>;
  end_time?: InputMaybe<IOrderBy>;
  is_standby?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  not_available?: InputMaybe<IOrderBy>;
  published?: InputMaybe<IOrderBy>;
  second_part?: InputMaybe<IOrderBy>;
  start_dt?: InputMaybe<IOrderBy>;
  subscription?: InputMaybe<ISubscriptionsOrderBy>;
  subscription_id?: InputMaybe<IOrderBy>;
  unlocked_at?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  watchable?: InputMaybe<IOrderBy>;
}

/** select columns of table "semi_stream_second_parts_view" */
export enum ISemiStreamSecondPartsViewSelectColumn {
  /** column name */
  CONTENT = 'content',
  /** column name */
  CONTENT_KEY = 'content_key',
  /** column name */
  COURSE_GROUP_ID = 'course_group_id',
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  DURATION = 'duration',
  /** column name */
  DURATION_IN_SECONDS = 'duration_in_seconds',
  /** column name */
  END_DT = 'end_dt',
  /** column name */
  END_TIME = 'end_time',
  /** column name */
  IS_STANDBY = 'is_standby',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  NAME = 'name',
  /** column name */
  NOT_AVAILABLE = 'not_available',
  /** column name */
  PUBLISHED = 'published',
  /** column name */
  SECOND_PART = 'second_part',
  /** column name */
  START_DT = 'start_dt',
  /** column name */
  SUBSCRIPTION_ID = 'subscription_id',
  /** column name */
  UNLOCKED_AT = 'unlocked_at',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  WATCHABLE = 'watchable'
}

/** aggregate stddev on columns */
export interface ISemiStreamSecondPartsViewStddevFields {
  __typename?: 'semi_stream_second_parts_view_stddev_fields';
  content_key?: Maybe<Scalars['Float']>;
  duration_in_seconds?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface ISemiStreamSecondPartsViewStddevPopFields {
  __typename?: 'semi_stream_second_parts_view_stddev_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
  duration_in_seconds?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface ISemiStreamSecondPartsViewStddevSampFields {
  __typename?: 'semi_stream_second_parts_view_stddev_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
  duration_in_seconds?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface ISemiStreamSecondPartsViewSumFields {
  __typename?: 'semi_stream_second_parts_view_sum_fields';
  content_key?: Maybe<Scalars['Int']>;
  duration_in_seconds?: Maybe<Scalars['Int']>;
}

/** aggregate var_pop on columns */
export interface ISemiStreamSecondPartsViewVarPopFields {
  __typename?: 'semi_stream_second_parts_view_var_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
  duration_in_seconds?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface ISemiStreamSecondPartsViewVarSampFields {
  __typename?: 'semi_stream_second_parts_view_var_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
  duration_in_seconds?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface ISemiStreamSecondPartsViewVarianceFields {
  __typename?: 'semi_stream_second_parts_view_variance_fields';
  content_key?: Maybe<Scalars['Float']>;
  duration_in_seconds?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "semi_stream_third_parts_view" */
export interface ISemiStreamThirdPartsView {
  __typename?: 'semi_stream_third_parts_view';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  /** An object relationship */
  course?: Maybe<ICourses>;
  /** An object relationship */
  course_group?: Maybe<ICourseGroups>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['interval']>;
  duration_in_seconds?: Maybe<Scalars['Int']>;
  end_dt?: Maybe<Scalars['timestamp']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  is_standby?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  not_available?: Maybe<Scalars['Boolean']>;
  published?: Maybe<Scalars['Boolean']>;
  start_dt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  subscription?: Maybe<ISubscriptions>;
  subscription_id?: Maybe<Scalars['uuid']>;
  third_part?: Maybe<Scalars['String']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id?: Maybe<Scalars['uuid']>;
  watchable?: Maybe<Scalars['Boolean']>;
}

/** aggregated selection of "semi_stream_third_parts_view" */
export interface ISemiStreamThirdPartsViewAggregate {
  __typename?: 'semi_stream_third_parts_view_aggregate';
  aggregate?: Maybe<ISemiStreamThirdPartsViewAggregateFields>;
  nodes: Array<ISemiStreamThirdPartsView>;
}

/** aggregate fields of "semi_stream_third_parts_view" */
export interface ISemiStreamThirdPartsViewAggregateFields {
  __typename?: 'semi_stream_third_parts_view_aggregate_fields';
  avg?: Maybe<ISemiStreamThirdPartsViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ISemiStreamThirdPartsViewMaxFields>;
  min?: Maybe<ISemiStreamThirdPartsViewMinFields>;
  stddev?: Maybe<ISemiStreamThirdPartsViewStddevFields>;
  stddev_pop?: Maybe<ISemiStreamThirdPartsViewStddevPopFields>;
  stddev_samp?: Maybe<ISemiStreamThirdPartsViewStddevSampFields>;
  sum?: Maybe<ISemiStreamThirdPartsViewSumFields>;
  var_pop?: Maybe<ISemiStreamThirdPartsViewVarPopFields>;
  var_samp?: Maybe<ISemiStreamThirdPartsViewVarSampFields>;
  variance?: Maybe<ISemiStreamThirdPartsViewVarianceFields>;
}


/** aggregate fields of "semi_stream_third_parts_view" */
export interface ISemiStreamThirdPartsViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ISemiStreamThirdPartsViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface ISemiStreamThirdPartsViewAvgFields {
  __typename?: 'semi_stream_third_parts_view_avg_fields';
  content_key?: Maybe<Scalars['Float']>;
  duration_in_seconds?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "semi_stream_third_parts_view". All fields are combined with a logical 'AND'. */
export interface ISemiStreamThirdPartsViewBoolExp {
  _and?: InputMaybe<Array<ISemiStreamThirdPartsViewBoolExp>>;
  _not?: InputMaybe<ISemiStreamThirdPartsViewBoolExp>;
  _or?: InputMaybe<Array<ISemiStreamThirdPartsViewBoolExp>>;
  content?: InputMaybe<IStringComparisonExp>;
  content_key?: InputMaybe<IIntComparisonExp>;
  course?: InputMaybe<ICoursesBoolExp>;
  course_group?: InputMaybe<ICourseGroupsBoolExp>;
  course_group_id?: InputMaybe<IUuidComparisonExp>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  duration?: InputMaybe<IIntervalComparisonExp>;
  duration_in_seconds?: InputMaybe<IIntComparisonExp>;
  end_dt?: InputMaybe<ITimestampComparisonExp>;
  end_time?: InputMaybe<ITimestamptzComparisonExp>;
  is_standby?: InputMaybe<IBooleanComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  not_available?: InputMaybe<IBooleanComparisonExp>;
  published?: InputMaybe<IBooleanComparisonExp>;
  start_dt?: InputMaybe<ITimestampComparisonExp>;
  subscription?: InputMaybe<ISubscriptionsBoolExp>;
  subscription_id?: InputMaybe<IUuidComparisonExp>;
  third_part?: InputMaybe<IStringComparisonExp>;
  unlocked_at?: InputMaybe<ITimestamptzComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
  watchable?: InputMaybe<IBooleanComparisonExp>;
}

/** aggregate max on columns */
export interface ISemiStreamThirdPartsViewMaxFields {
  __typename?: 'semi_stream_third_parts_view_max_fields';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  duration_in_seconds?: Maybe<Scalars['Int']>;
  end_dt?: Maybe<Scalars['timestamp']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  start_dt?: Maybe<Scalars['timestamp']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  third_part?: Maybe<Scalars['String']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface ISemiStreamThirdPartsViewMinFields {
  __typename?: 'semi_stream_third_parts_view_min_fields';
  content?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  duration_in_seconds?: Maybe<Scalars['Int']>;
  end_dt?: Maybe<Scalars['timestamp']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  start_dt?: Maybe<Scalars['timestamp']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  third_part?: Maybe<Scalars['String']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "semi_stream_third_parts_view". */
export interface ISemiStreamThirdPartsViewOrderBy {
  content?: InputMaybe<IOrderBy>;
  content_key?: InputMaybe<IOrderBy>;
  course?: InputMaybe<ICoursesOrderBy>;
  course_group?: InputMaybe<ICourseGroupsOrderBy>;
  course_group_id?: InputMaybe<IOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  duration?: InputMaybe<IOrderBy>;
  duration_in_seconds?: InputMaybe<IOrderBy>;
  end_dt?: InputMaybe<IOrderBy>;
  end_time?: InputMaybe<IOrderBy>;
  is_standby?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  not_available?: InputMaybe<IOrderBy>;
  published?: InputMaybe<IOrderBy>;
  start_dt?: InputMaybe<IOrderBy>;
  subscription?: InputMaybe<ISubscriptionsOrderBy>;
  subscription_id?: InputMaybe<IOrderBy>;
  third_part?: InputMaybe<IOrderBy>;
  unlocked_at?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  watchable?: InputMaybe<IOrderBy>;
}

/** select columns of table "semi_stream_third_parts_view" */
export enum ISemiStreamThirdPartsViewSelectColumn {
  /** column name */
  CONTENT = 'content',
  /** column name */
  CONTENT_KEY = 'content_key',
  /** column name */
  COURSE_GROUP_ID = 'course_group_id',
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  DURATION = 'duration',
  /** column name */
  DURATION_IN_SECONDS = 'duration_in_seconds',
  /** column name */
  END_DT = 'end_dt',
  /** column name */
  END_TIME = 'end_time',
  /** column name */
  IS_STANDBY = 'is_standby',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  NAME = 'name',
  /** column name */
  NOT_AVAILABLE = 'not_available',
  /** column name */
  PUBLISHED = 'published',
  /** column name */
  START_DT = 'start_dt',
  /** column name */
  SUBSCRIPTION_ID = 'subscription_id',
  /** column name */
  THIRD_PART = 'third_part',
  /** column name */
  UNLOCKED_AT = 'unlocked_at',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  WATCHABLE = 'watchable'
}

/** aggregate stddev on columns */
export interface ISemiStreamThirdPartsViewStddevFields {
  __typename?: 'semi_stream_third_parts_view_stddev_fields';
  content_key?: Maybe<Scalars['Float']>;
  duration_in_seconds?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface ISemiStreamThirdPartsViewStddevPopFields {
  __typename?: 'semi_stream_third_parts_view_stddev_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
  duration_in_seconds?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface ISemiStreamThirdPartsViewStddevSampFields {
  __typename?: 'semi_stream_third_parts_view_stddev_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
  duration_in_seconds?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface ISemiStreamThirdPartsViewSumFields {
  __typename?: 'semi_stream_third_parts_view_sum_fields';
  content_key?: Maybe<Scalars['Int']>;
  duration_in_seconds?: Maybe<Scalars['Int']>;
}

/** aggregate var_pop on columns */
export interface ISemiStreamThirdPartsViewVarPopFields {
  __typename?: 'semi_stream_third_parts_view_var_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
  duration_in_seconds?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface ISemiStreamThirdPartsViewVarSampFields {
  __typename?: 'semi_stream_third_parts_view_var_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
  duration_in_seconds?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface ISemiStreamThirdPartsViewVarianceFields {
  __typename?: 'semi_stream_third_parts_view_variance_fields';
  content_key?: Maybe<Scalars['Float']>;
  duration_in_seconds?: Maybe<Scalars['Float']>;
}

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export interface ISmallintComparisonExp {
  _eq?: InputMaybe<Scalars['smallint']>;
  _gt?: InputMaybe<Scalars['smallint']>;
  _gte?: InputMaybe<Scalars['smallint']>;
  _in?: InputMaybe<Array<Scalars['smallint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['smallint']>;
  _lte?: InputMaybe<Scalars['smallint']>;
  _neq?: InputMaybe<Scalars['smallint']>;
  _nin?: InputMaybe<Array<Scalars['smallint']>>;
}

/** columns and relationships of "stars_view" */
export interface IStarsView {
  __typename?: 'stars_view';
  course_id?: Maybe<Scalars['uuid']>;
  stars?: Maybe<Scalars['bigint']>;
  user_child_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user_children?: Maybe<IUsersChildren>;
  username?: Maybe<Scalars['String']>;
}

/** aggregated selection of "stars_view" */
export interface IStarsViewAggregate {
  __typename?: 'stars_view_aggregate';
  aggregate?: Maybe<IStarsViewAggregateFields>;
  nodes: Array<IStarsView>;
}

/** aggregate fields of "stars_view" */
export interface IStarsViewAggregateFields {
  __typename?: 'stars_view_aggregate_fields';
  avg?: Maybe<IStarsViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IStarsViewMaxFields>;
  min?: Maybe<IStarsViewMinFields>;
  stddev?: Maybe<IStarsViewStddevFields>;
  stddev_pop?: Maybe<IStarsViewStddevPopFields>;
  stddev_samp?: Maybe<IStarsViewStddevSampFields>;
  sum?: Maybe<IStarsViewSumFields>;
  var_pop?: Maybe<IStarsViewVarPopFields>;
  var_samp?: Maybe<IStarsViewVarSampFields>;
  variance?: Maybe<IStarsViewVarianceFields>;
}


/** aggregate fields of "stars_view" */
export interface IStarsViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IStarsViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IStarsViewAvgFields {
  __typename?: 'stars_view_avg_fields';
  stars?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "stars_view". All fields are combined with a logical 'AND'. */
export interface IStarsViewBoolExp {
  _and?: InputMaybe<Array<IStarsViewBoolExp>>;
  _not?: InputMaybe<IStarsViewBoolExp>;
  _or?: InputMaybe<Array<IStarsViewBoolExp>>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  stars?: InputMaybe<IBigintComparisonExp>;
  user_child_id?: InputMaybe<IUuidComparisonExp>;
  user_children?: InputMaybe<IUsersChildrenBoolExp>;
  username?: InputMaybe<IStringComparisonExp>;
}

/** input type for inserting data into table "stars_view" */
export interface IStarsViewInsertInput {
  course_id?: InputMaybe<Scalars['uuid']>;
  stars?: InputMaybe<Scalars['bigint']>;
  user_child_id?: InputMaybe<Scalars['uuid']>;
  user_children?: InputMaybe<IUsersChildrenObjRelInsertInput>;
  username?: InputMaybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface IStarsViewMaxFields {
  __typename?: 'stars_view_max_fields';
  course_id?: Maybe<Scalars['uuid']>;
  stars?: Maybe<Scalars['bigint']>;
  user_child_id?: Maybe<Scalars['uuid']>;
  username?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IStarsViewMinFields {
  __typename?: 'stars_view_min_fields';
  course_id?: Maybe<Scalars['uuid']>;
  stars?: Maybe<Scalars['bigint']>;
  user_child_id?: Maybe<Scalars['uuid']>;
  username?: Maybe<Scalars['String']>;
}

/** input type for inserting object relation for remote table "stars_view" */
export interface IStarsViewObjRelInsertInput {
  data: IStarsViewInsertInput;
}

/** Ordering options when selecting data from "stars_view". */
export interface IStarsViewOrderBy {
  course_id?: InputMaybe<IOrderBy>;
  stars?: InputMaybe<IOrderBy>;
  user_child_id?: InputMaybe<IOrderBy>;
  user_children?: InputMaybe<IUsersChildrenOrderBy>;
  username?: InputMaybe<IOrderBy>;
}

/** select columns of table "stars_view" */
export enum IStarsViewSelectColumn {
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  STARS = 'stars',
  /** column name */
  USER_CHILD_ID = 'user_child_id',
  /** column name */
  USERNAME = 'username'
}

/** aggregate stddev on columns */
export interface IStarsViewStddevFields {
  __typename?: 'stars_view_stddev_fields';
  stars?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IStarsViewStddevPopFields {
  __typename?: 'stars_view_stddev_pop_fields';
  stars?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IStarsViewStddevSampFields {
  __typename?: 'stars_view_stddev_samp_fields';
  stars?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IStarsViewSumFields {
  __typename?: 'stars_view_sum_fields';
  stars?: Maybe<Scalars['bigint']>;
}

/** aggregate var_pop on columns */
export interface IStarsViewVarPopFields {
  __typename?: 'stars_view_var_pop_fields';
  stars?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IStarsViewVarSampFields {
  __typename?: 'stars_view_var_samp_fields';
  stars?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IStarsViewVarianceFields {
  __typename?: 'stars_view_variance_fields';
  stars?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "study_reminders" */
export interface IStudyReminders {
  __typename?: 'study_reminders';
  datediff?: Maybe<Scalars['float8']>;
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  lesson_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "study_reminders" */
export interface IStudyRemindersAggregate {
  __typename?: 'study_reminders_aggregate';
  aggregate?: Maybe<IStudyRemindersAggregateFields>;
  nodes: Array<IStudyReminders>;
}

/** aggregate fields of "study_reminders" */
export interface IStudyRemindersAggregateFields {
  __typename?: 'study_reminders_aggregate_fields';
  avg?: Maybe<IStudyRemindersAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IStudyRemindersMaxFields>;
  min?: Maybe<IStudyRemindersMinFields>;
  stddev?: Maybe<IStudyRemindersStddevFields>;
  stddev_pop?: Maybe<IStudyRemindersStddevPopFields>;
  stddev_samp?: Maybe<IStudyRemindersStddevSampFields>;
  sum?: Maybe<IStudyRemindersSumFields>;
  var_pop?: Maybe<IStudyRemindersVarPopFields>;
  var_samp?: Maybe<IStudyRemindersVarSampFields>;
  variance?: Maybe<IStudyRemindersVarianceFields>;
}


/** aggregate fields of "study_reminders" */
export interface IStudyRemindersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IStudyRemindersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IStudyRemindersAvgFields {
  __typename?: 'study_reminders_avg_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "study_reminders". All fields are combined with a logical 'AND'. */
export interface IStudyRemindersBoolExp {
  _and?: InputMaybe<Array<IStudyRemindersBoolExp>>;
  _not?: InputMaybe<IStudyRemindersBoolExp>;
  _or?: InputMaybe<Array<IStudyRemindersBoolExp>>;
  datediff?: InputMaybe<IFloat8ComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface IStudyRemindersMaxFields {
  __typename?: 'study_reminders_max_fields';
  datediff?: Maybe<Scalars['float8']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IStudyRemindersMinFields {
  __typename?: 'study_reminders_min_fields';
  datediff?: Maybe<Scalars['float8']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "study_reminders". */
export interface IStudyRemindersOrderBy {
  datediff?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "study_reminders" */
export enum IStudyRemindersSelectColumn {
  /** column name */
  DATEDIFF = 'datediff',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate stddev on columns */
export interface IStudyRemindersStddevFields {
  __typename?: 'study_reminders_stddev_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IStudyRemindersStddevPopFields {
  __typename?: 'study_reminders_stddev_pop_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IStudyRemindersStddevSampFields {
  __typename?: 'study_reminders_stddev_samp_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IStudyRemindersSumFields {
  __typename?: 'study_reminders_sum_fields';
  datediff?: Maybe<Scalars['float8']>;
}

/** aggregate var_pop on columns */
export interface IStudyRemindersVarPopFields {
  __typename?: 'study_reminders_var_pop_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IStudyRemindersVarSampFields {
  __typename?: 'study_reminders_var_samp_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IStudyRemindersVarianceFields {
  __typename?: 'study_reminders_variance_fields';
  datediff?: Maybe<Scalars['Float']>;
}

/** Additional config per subscription */
export interface ISubscriptionConfigs {
  __typename?: 'subscription_configs';
  id: Scalars['Int'];
  max_profile_count: Scalars['Int'];
  /** An object relationship */
  subscription?: Maybe<ISubscriptions>;
  subscription_id: Scalars['uuid'];
}

/** aggregated selection of "subscription_configs" */
export interface ISubscriptionConfigsAggregate {
  __typename?: 'subscription_configs_aggregate';
  aggregate?: Maybe<ISubscriptionConfigsAggregateFields>;
  nodes: Array<ISubscriptionConfigs>;
}

/** aggregate fields of "subscription_configs" */
export interface ISubscriptionConfigsAggregateFields {
  __typename?: 'subscription_configs_aggregate_fields';
  avg?: Maybe<ISubscriptionConfigsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ISubscriptionConfigsMaxFields>;
  min?: Maybe<ISubscriptionConfigsMinFields>;
  stddev?: Maybe<ISubscriptionConfigsStddevFields>;
  stddev_pop?: Maybe<ISubscriptionConfigsStddevPopFields>;
  stddev_samp?: Maybe<ISubscriptionConfigsStddevSampFields>;
  sum?: Maybe<ISubscriptionConfigsSumFields>;
  var_pop?: Maybe<ISubscriptionConfigsVarPopFields>;
  var_samp?: Maybe<ISubscriptionConfigsVarSampFields>;
  variance?: Maybe<ISubscriptionConfigsVarianceFields>;
}


/** aggregate fields of "subscription_configs" */
export interface ISubscriptionConfigsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ISubscriptionConfigsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface ISubscriptionConfigsAvgFields {
  __typename?: 'subscription_configs_avg_fields';
  id?: Maybe<Scalars['Float']>;
  max_profile_count?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "subscription_configs". All fields are combined with a logical 'AND'. */
export interface ISubscriptionConfigsBoolExp {
  _and?: InputMaybe<Array<ISubscriptionConfigsBoolExp>>;
  _not?: InputMaybe<ISubscriptionConfigsBoolExp>;
  _or?: InputMaybe<Array<ISubscriptionConfigsBoolExp>>;
  id?: InputMaybe<IIntComparisonExp>;
  max_profile_count?: InputMaybe<IIntComparisonExp>;
  subscription?: InputMaybe<ISubscriptionsBoolExp>;
  subscription_id?: InputMaybe<IUuidComparisonExp>;
}

/** unique or primary key constraints on table "subscription_configs" */
export enum ISubscriptionConfigsConstraint {
  /** unique or primary key constraint */
  SUBSCRIPTION_CONFIG_PKEY = 'subscription_config_pkey'
}

/** input type for incrementing numeric columns in table "subscription_configs" */
export interface ISubscriptionConfigsIncInput {
  id?: InputMaybe<Scalars['Int']>;
  max_profile_count?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "subscription_configs" */
export interface ISubscriptionConfigsInsertInput {
  id?: InputMaybe<Scalars['Int']>;
  max_profile_count?: InputMaybe<Scalars['Int']>;
  subscription?: InputMaybe<ISubscriptionsObjRelInsertInput>;
  subscription_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface ISubscriptionConfigsMaxFields {
  __typename?: 'subscription_configs_max_fields';
  id?: Maybe<Scalars['Int']>;
  max_profile_count?: Maybe<Scalars['Int']>;
  subscription_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface ISubscriptionConfigsMinFields {
  __typename?: 'subscription_configs_min_fields';
  id?: Maybe<Scalars['Int']>;
  max_profile_count?: Maybe<Scalars['Int']>;
  subscription_id?: Maybe<Scalars['uuid']>;
}

/** response of any mutation on the table "subscription_configs" */
export interface ISubscriptionConfigsMutationResponse {
  __typename?: 'subscription_configs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ISubscriptionConfigs>;
}

/** input type for inserting object relation for remote table "subscription_configs" */
export interface ISubscriptionConfigsObjRelInsertInput {
  data: ISubscriptionConfigsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ISubscriptionConfigsOnConflict>;
}

/** on_conflict condition type for table "subscription_configs" */
export interface ISubscriptionConfigsOnConflict {
  constraint: ISubscriptionConfigsConstraint;
  update_columns?: Array<ISubscriptionConfigsUpdateColumn>;
  where?: InputMaybe<ISubscriptionConfigsBoolExp>;
}

/** Ordering options when selecting data from "subscription_configs". */
export interface ISubscriptionConfigsOrderBy {
  id?: InputMaybe<IOrderBy>;
  max_profile_count?: InputMaybe<IOrderBy>;
  subscription?: InputMaybe<ISubscriptionsOrderBy>;
  subscription_id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: subscription_configs */
export interface ISubscriptionConfigsPkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "subscription_configs" */
export enum ISubscriptionConfigsSelectColumn {
  /** column name */
  ID = 'id',
  /** column name */
  MAX_PROFILE_COUNT = 'max_profile_count',
  /** column name */
  SUBSCRIPTION_ID = 'subscription_id'
}

/** input type for updating data in table "subscription_configs" */
export interface ISubscriptionConfigsSetInput {
  id?: InputMaybe<Scalars['Int']>;
  max_profile_count?: InputMaybe<Scalars['Int']>;
  subscription_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate stddev on columns */
export interface ISubscriptionConfigsStddevFields {
  __typename?: 'subscription_configs_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  max_profile_count?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface ISubscriptionConfigsStddevPopFields {
  __typename?: 'subscription_configs_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  max_profile_count?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface ISubscriptionConfigsStddevSampFields {
  __typename?: 'subscription_configs_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  max_profile_count?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface ISubscriptionConfigsSumFields {
  __typename?: 'subscription_configs_sum_fields';
  id?: Maybe<Scalars['Int']>;
  max_profile_count?: Maybe<Scalars['Int']>;
}

/** update columns of table "subscription_configs" */
export enum ISubscriptionConfigsUpdateColumn {
  /** column name */
  ID = 'id',
  /** column name */
  MAX_PROFILE_COUNT = 'max_profile_count',
  /** column name */
  SUBSCRIPTION_ID = 'subscription_id'
}

/** aggregate var_pop on columns */
export interface ISubscriptionConfigsVarPopFields {
  __typename?: 'subscription_configs_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  max_profile_count?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface ISubscriptionConfigsVarSampFields {
  __typename?: 'subscription_configs_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  max_profile_count?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface ISubscriptionConfigsVarianceFields {
  __typename?: 'subscription_configs_variance_fields';
  id?: Maybe<Scalars['Float']>;
  max_profile_count?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "subscription_end_reminders" */
export interface ISubscriptionEndReminders {
  __typename?: 'subscription_end_reminders';
  course_id?: Maybe<Scalars['uuid']>;
  datediff?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['uuid']>;
  wp_subscription_id?: Maybe<Scalars['Int']>;
}

/** aggregated selection of "subscription_end_reminders" */
export interface ISubscriptionEndRemindersAggregate {
  __typename?: 'subscription_end_reminders_aggregate';
  aggregate?: Maybe<ISubscriptionEndRemindersAggregateFields>;
  nodes: Array<ISubscriptionEndReminders>;
}

/** aggregate fields of "subscription_end_reminders" */
export interface ISubscriptionEndRemindersAggregateFields {
  __typename?: 'subscription_end_reminders_aggregate_fields';
  avg?: Maybe<ISubscriptionEndRemindersAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ISubscriptionEndRemindersMaxFields>;
  min?: Maybe<ISubscriptionEndRemindersMinFields>;
  stddev?: Maybe<ISubscriptionEndRemindersStddevFields>;
  stddev_pop?: Maybe<ISubscriptionEndRemindersStddevPopFields>;
  stddev_samp?: Maybe<ISubscriptionEndRemindersStddevSampFields>;
  sum?: Maybe<ISubscriptionEndRemindersSumFields>;
  var_pop?: Maybe<ISubscriptionEndRemindersVarPopFields>;
  var_samp?: Maybe<ISubscriptionEndRemindersVarSampFields>;
  variance?: Maybe<ISubscriptionEndRemindersVarianceFields>;
}


/** aggregate fields of "subscription_end_reminders" */
export interface ISubscriptionEndRemindersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ISubscriptionEndRemindersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface ISubscriptionEndRemindersAvgFields {
  __typename?: 'subscription_end_reminders_avg_fields';
  datediff?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "subscription_end_reminders". All fields are combined with a logical 'AND'. */
export interface ISubscriptionEndRemindersBoolExp {
  _and?: InputMaybe<Array<ISubscriptionEndRemindersBoolExp>>;
  _not?: InputMaybe<ISubscriptionEndRemindersBoolExp>;
  _or?: InputMaybe<Array<ISubscriptionEndRemindersBoolExp>>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  datediff?: InputMaybe<IFloat8ComparisonExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
  wp_subscription_id?: InputMaybe<IIntComparisonExp>;
}

/** aggregate max on columns */
export interface ISubscriptionEndRemindersMaxFields {
  __typename?: 'subscription_end_reminders_max_fields';
  course_id?: Maybe<Scalars['uuid']>;
  datediff?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['uuid']>;
  wp_subscription_id?: Maybe<Scalars['Int']>;
}

/** aggregate min on columns */
export interface ISubscriptionEndRemindersMinFields {
  __typename?: 'subscription_end_reminders_min_fields';
  course_id?: Maybe<Scalars['uuid']>;
  datediff?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['uuid']>;
  wp_subscription_id?: Maybe<Scalars['Int']>;
}

/** Ordering options when selecting data from "subscription_end_reminders". */
export interface ISubscriptionEndRemindersOrderBy {
  course_id?: InputMaybe<IOrderBy>;
  datediff?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "subscription_end_reminders" */
export enum ISubscriptionEndRemindersSelectColumn {
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  DATEDIFF = 'datediff',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  WP_SUBSCRIPTION_ID = 'wp_subscription_id'
}

/** aggregate stddev on columns */
export interface ISubscriptionEndRemindersStddevFields {
  __typename?: 'subscription_end_reminders_stddev_fields';
  datediff?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface ISubscriptionEndRemindersStddevPopFields {
  __typename?: 'subscription_end_reminders_stddev_pop_fields';
  datediff?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface ISubscriptionEndRemindersStddevSampFields {
  __typename?: 'subscription_end_reminders_stddev_samp_fields';
  datediff?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface ISubscriptionEndRemindersSumFields {
  __typename?: 'subscription_end_reminders_sum_fields';
  datediff?: Maybe<Scalars['float8']>;
  wp_subscription_id?: Maybe<Scalars['Int']>;
}

/** aggregate var_pop on columns */
export interface ISubscriptionEndRemindersVarPopFields {
  __typename?: 'subscription_end_reminders_var_pop_fields';
  datediff?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface ISubscriptionEndRemindersVarSampFields {
  __typename?: 'subscription_end_reminders_var_samp_fields';
  datediff?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface ISubscriptionEndRemindersVarianceFields {
  __typename?: 'subscription_end_reminders_variance_fields';
  datediff?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "subscription_mail_reminders" */
export interface ISubscriptionMailReminders {
  __typename?: 'subscription_mail_reminders';
  /** An object relationship */
  course?: Maybe<ICourses>;
  course_id?: Maybe<Scalars['uuid']>;
  datediff?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['uuid']>;
  wp_subscription_id?: Maybe<Scalars['Int']>;
}

/** aggregated selection of "subscription_mail_reminders" */
export interface ISubscriptionMailRemindersAggregate {
  __typename?: 'subscription_mail_reminders_aggregate';
  aggregate?: Maybe<ISubscriptionMailRemindersAggregateFields>;
  nodes: Array<ISubscriptionMailReminders>;
}

/** aggregate fields of "subscription_mail_reminders" */
export interface ISubscriptionMailRemindersAggregateFields {
  __typename?: 'subscription_mail_reminders_aggregate_fields';
  avg?: Maybe<ISubscriptionMailRemindersAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ISubscriptionMailRemindersMaxFields>;
  min?: Maybe<ISubscriptionMailRemindersMinFields>;
  stddev?: Maybe<ISubscriptionMailRemindersStddevFields>;
  stddev_pop?: Maybe<ISubscriptionMailRemindersStddevPopFields>;
  stddev_samp?: Maybe<ISubscriptionMailRemindersStddevSampFields>;
  sum?: Maybe<ISubscriptionMailRemindersSumFields>;
  var_pop?: Maybe<ISubscriptionMailRemindersVarPopFields>;
  var_samp?: Maybe<ISubscriptionMailRemindersVarSampFields>;
  variance?: Maybe<ISubscriptionMailRemindersVarianceFields>;
}


/** aggregate fields of "subscription_mail_reminders" */
export interface ISubscriptionMailRemindersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ISubscriptionMailRemindersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface ISubscriptionMailRemindersAvgFields {
  __typename?: 'subscription_mail_reminders_avg_fields';
  datediff?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "subscription_mail_reminders". All fields are combined with a logical 'AND'. */
export interface ISubscriptionMailRemindersBoolExp {
  _and?: InputMaybe<Array<ISubscriptionMailRemindersBoolExp>>;
  _not?: InputMaybe<ISubscriptionMailRemindersBoolExp>;
  _or?: InputMaybe<Array<ISubscriptionMailRemindersBoolExp>>;
  course?: InputMaybe<ICoursesBoolExp>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  datediff?: InputMaybe<IFloat8ComparisonExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
  wp_subscription_id?: InputMaybe<IIntComparisonExp>;
}

/** aggregate max on columns */
export interface ISubscriptionMailRemindersMaxFields {
  __typename?: 'subscription_mail_reminders_max_fields';
  course_id?: Maybe<Scalars['uuid']>;
  datediff?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['uuid']>;
  wp_subscription_id?: Maybe<Scalars['Int']>;
}

/** aggregate min on columns */
export interface ISubscriptionMailRemindersMinFields {
  __typename?: 'subscription_mail_reminders_min_fields';
  course_id?: Maybe<Scalars['uuid']>;
  datediff?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['uuid']>;
  wp_subscription_id?: Maybe<Scalars['Int']>;
}

/** Ordering options when selecting data from "subscription_mail_reminders". */
export interface ISubscriptionMailRemindersOrderBy {
  course?: InputMaybe<ICoursesOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  datediff?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "subscription_mail_reminders" */
export enum ISubscriptionMailRemindersSelectColumn {
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  DATEDIFF = 'datediff',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  WP_SUBSCRIPTION_ID = 'wp_subscription_id'
}

/** aggregate stddev on columns */
export interface ISubscriptionMailRemindersStddevFields {
  __typename?: 'subscription_mail_reminders_stddev_fields';
  datediff?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface ISubscriptionMailRemindersStddevPopFields {
  __typename?: 'subscription_mail_reminders_stddev_pop_fields';
  datediff?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface ISubscriptionMailRemindersStddevSampFields {
  __typename?: 'subscription_mail_reminders_stddev_samp_fields';
  datediff?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface ISubscriptionMailRemindersSumFields {
  __typename?: 'subscription_mail_reminders_sum_fields';
  datediff?: Maybe<Scalars['float8']>;
  wp_subscription_id?: Maybe<Scalars['Int']>;
}

/** aggregate var_pop on columns */
export interface ISubscriptionMailRemindersVarPopFields {
  __typename?: 'subscription_mail_reminders_var_pop_fields';
  datediff?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface ISubscriptionMailRemindersVarSampFields {
  __typename?: 'subscription_mail_reminders_var_samp_fields';
  datediff?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface ISubscriptionMailRemindersVarianceFields {
  __typename?: 'subscription_mail_reminders_variance_fields';
  datediff?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

export interface ISubscriptionRoot {
  __typename?: 'subscription_root';
  /** fetch data from the table: "achievement_types" */
  achievement_types: Array<IAchievementTypes>;
  /** fetch aggregated fields from the table: "achievement_types" */
  achievement_types_aggregate: IAchievementTypesAggregate;
  /** fetch data from the table: "achievement_types" using primary key columns */
  achievement_types_by_pk?: Maybe<IAchievementTypes>;
  /** An array relationship */
  achievements: Array<IAchievements>;
  /** fetch data from the table: "achievements_accomplished" */
  achievements_accomplished: Array<IAchievementsAccomplished>;
  /** fetch aggregated fields from the table: "achievements_accomplished" */
  achievements_accomplished_aggregate: IAchievementsAccomplishedAggregate;
  /** fetch data from the table: "achievements_accomplished" using primary key columns */
  achievements_accomplished_by_pk?: Maybe<IAchievementsAccomplished>;
  /** An aggregate relationship */
  achievements_aggregate: IAchievementsAggregate;
  /** fetch data from the table: "achievements" using primary key columns */
  achievements_by_pk?: Maybe<IAchievements>;
  /** fetch data from the table: "achievements_view" */
  achievements_view: Array<IAchievementsView>;
  /** fetch aggregated fields from the table: "achievements_view" */
  achievements_view_aggregate: IAchievementsViewAggregate;
  /** fetch data from the table: "active_storage_attachments" */
  active_storage_attachments: Array<IActiveStorageAttachments>;
  /** fetch aggregated fields from the table: "active_storage_attachments" */
  active_storage_attachments_aggregate: IActiveStorageAttachmentsAggregate;
  /** fetch data from the table: "active_storage_attachments" using primary key columns */
  active_storage_attachments_by_pk?: Maybe<IActiveStorageAttachments>;
  /** fetch data from the table: "active_storage_blobs" */
  active_storage_blobs: Array<IActiveStorageBlobs>;
  /** fetch aggregated fields from the table: "active_storage_blobs" */
  active_storage_blobs_aggregate: IActiveStorageBlobsAggregate;
  /** fetch data from the table: "active_storage_blobs" using primary key columns */
  active_storage_blobs_by_pk?: Maybe<IActiveStorageBlobs>;
  /** fetch data from the table: "additional_lessons" */
  additional_lessons: Array<IAdditionalLessons>;
  /** fetch aggregated fields from the table: "additional_lessons" */
  additional_lessons_aggregate: IAdditionalLessonsAggregate;
  /** fetch data from the table: "additional_lessons" using primary key columns */
  additional_lessons_by_pk?: Maybe<IAdditionalLessons>;
  /** An array relationship */
  additional_lessons_group_types: Array<IAdditionalLessonsGroupTypes>;
  /** An aggregate relationship */
  additional_lessons_group_types_aggregate: IAdditionalLessonsGroupTypesAggregate;
  /** fetch data from the table: "additional_lessons_group_types" using primary key columns */
  additional_lessons_group_types_by_pk?: Maybe<IAdditionalLessonsGroupTypes>;
  /** fetch data from the table: "additional_lessons_view" */
  additional_lessons_view: Array<IAdditionalLessonsView>;
  /** fetch aggregated fields from the table: "additional_lessons_view" */
  additional_lessons_view_aggregate: IAdditionalLessonsViewAggregate;
  /** fetch data from the table: "ambassador_lessons_view" */
  ambassador_lessons_view: Array<IAmbassadorLessonsView>;
  /** fetch aggregated fields from the table: "ambassador_lessons_view" */
  ambassador_lessons_view_aggregate: IAmbassadorLessonsViewAggregate;
  /** fetch data from the table: "ambassadors" */
  ambassadors: Array<IAmbassadors>;
  /** fetch aggregated fields from the table: "ambassadors" */
  ambassadors_aggregate: IAmbassadorsAggregate;
  /** fetch data from the table: "ambassadors" using primary key columns */
  ambassadors_by_pk?: Maybe<IAmbassadors>;
  /** An array relationship */
  annual_schedule_attachments: Array<IAnnualScheduleAttachments>;
  /** An aggregate relationship */
  annual_schedule_attachments_aggregate: IAnnualScheduleAttachmentsAggregate;
  /** fetch data from the table: "annual_schedules" */
  annual_schedules: Array<IAnnualSchedules>;
  /** fetch aggregated fields from the table: "annual_schedules" */
  annual_schedules_aggregate: IAnnualSchedulesAggregate;
  /** fetch data from the table: "annual_schedules" using primary key columns */
  annual_schedules_by_pk?: Maybe<IAnnualSchedules>;
  /** An array relationship */
  answers: Array<IAnswers>;
  /** An aggregate relationship */
  answers_aggregate: IAnswersAggregate;
  /** fetch data from the table: "answers" using primary key columns */
  answers_by_pk?: Maybe<IAnswers>;
  /** fetch data from the table: "available_second_parts_view" */
  available_second_parts_view: Array<IAvailableSecondPartsView>;
  /** fetch aggregated fields from the table: "available_second_parts_view" */
  available_second_parts_view_aggregate: IAvailableSecondPartsViewAggregate;
  /** An array relationship */
  course_groups: Array<ICourseGroups>;
  /** An aggregate relationship */
  course_groups_aggregate: ICourseGroupsAggregate;
  /** fetch data from the table: "course_groups" using primary key columns */
  course_groups_by_pk?: Maybe<ICourseGroups>;
  /** fetch data from the table: "course_types" */
  course_types: Array<ICourseTypes>;
  /** fetch aggregated fields from the table: "course_types" */
  course_types_aggregate: ICourseTypesAggregate;
  /** fetch data from the table: "course_types" using primary key columns */
  course_types_by_pk?: Maybe<ICourseTypes>;
  /** fetch data from the table: "courses" */
  courses: Array<ICourses>;
  /** fetch aggregated fields from the table: "courses" */
  courses_aggregate: ICoursesAggregate;
  /** fetch data from the table: "courses" using primary key columns */
  courses_by_pk?: Maybe<ICourses>;
  /** fetch data from the table: "dictionary_words" */
  dictionary_words: Array<IDictionaryWords>;
  /** fetch aggregated fields from the table: "dictionary_words" */
  dictionary_words_aggregate: IDictionaryWordsAggregate;
  /** fetch data from the table: "dictionary_words" using primary key columns */
  dictionary_words_by_pk?: Maybe<IDictionaryWords>;
  /** fetch data from the table: "event_locations" */
  event_locations: Array<IEventLocations>;
  /** fetch aggregated fields from the table: "event_locations" */
  event_locations_aggregate: IEventLocationsAggregate;
  /** fetch data from the table: "event_locations" using primary key columns */
  event_locations_by_pk?: Maybe<IEventLocations>;
  /** An array relationship */
  event_registrations: Array<IEventRegistrations>;
  /** An aggregate relationship */
  event_registrations_aggregate: IEventRegistrationsAggregate;
  /** fetch data from the table: "event_registrations" using primary key columns */
  event_registrations_by_pk?: Maybe<IEventRegistrations>;
  /** fetch data from the table: "events" */
  events: Array<IEvents>;
  /** fetch aggregated fields from the table: "events" */
  events_aggregate: IEventsAggregate;
  /** fetch data from the table: "events" using primary key columns */
  events_by_pk?: Maybe<IEvents>;
  /** An array relationship */
  events_group_types: Array<IEventsGroupTypes>;
  /** An aggregate relationship */
  events_group_types_aggregate: IEventsGroupTypesAggregate;
  /** fetch data from the table: "events_group_types" using primary key columns */
  events_group_types_by_pk?: Maybe<IEventsGroupTypes>;
  /** fetch data from the table: "events_view" */
  events_view: Array<IEventsView>;
  /** fetch aggregated fields from the table: "events_view" */
  events_view_aggregate: IEventsViewAggregate;
  /** fetch data from the table: "favorite_lesson_moments" */
  favorite_lesson_moments: Array<IFavoriteLessonMoments>;
  /** fetch aggregated fields from the table: "favorite_lesson_moments" */
  favorite_lesson_moments_aggregate: IFavoriteLessonMomentsAggregate;
  /** fetch data from the table: "favorite_lesson_moments" using primary key columns */
  favorite_lesson_moments_by_pk?: Maybe<IFavoriteLessonMoments>;
  /** fetch data from the table: "final_test_questions" */
  final_test_questions: Array<IFinalTestQuestions>;
  /** fetch aggregated fields from the table: "final_test_questions" */
  final_test_questions_aggregate: IFinalTestQuestionsAggregate;
  /** fetch data from the table: "final_test_questions" using primary key columns */
  final_test_questions_by_pk?: Maybe<IFinalTestQuestions>;
  /** An array relationship */
  final_test_results: Array<IFinalTestResults>;
  /** An aggregate relationship */
  final_test_results_aggregate: IFinalTestResultsAggregate;
  /** fetch data from the table: "final_test_results" using primary key columns */
  final_test_results_by_pk?: Maybe<IFinalTestResults>;
  /** fetch data from the table: "final_tests_view" */
  final_tests_view: Array<IFinalTestsView>;
  /** fetch aggregated fields from the table: "final_tests_view" */
  final_tests_view_aggregate: IFinalTestsViewAggregate;
  /** fetch data from the table: "first_lesson_part_mail_reminders" */
  first_lesson_part_mail_reminders: Array<IFirstLessonPartMailReminders>;
  /** fetch aggregated fields from the table: "first_lesson_part_mail_reminders" */
  first_lesson_part_mail_reminders_aggregate: IFirstLessonPartMailRemindersAggregate;
  /** fetch data from the table: "friend_requests" */
  friend_requests: Array<IFriendRequests>;
  /** fetch aggregated fields from the table: "friend_requests" */
  friend_requests_aggregate: IFriendRequestsAggregate;
  /** fetch data from the table: "friend_requests" using primary key columns */
  friend_requests_by_pk?: Maybe<IFriendRequests>;
  /** fetch data from the table: "friend_requests_view" */
  friend_requests_view: Array<IFriendRequestsView>;
  /** fetch aggregated fields from the table: "friend_requests_view" */
  friend_requests_view_aggregate: IFriendRequestsViewAggregate;
  /** fetch data from the table: "friends_view" */
  friends_view: Array<IFriendsView>;
  /** fetch aggregated fields from the table: "friends_view" */
  friends_view_aggregate: IFriendsViewAggregate;
  /** fetch data from the table: "group_type_attachments" */
  group_type_attachments: Array<IGroupTypeAttachments>;
  /** fetch aggregated fields from the table: "group_type_attachments" */
  group_type_attachments_aggregate: IGroupTypeAttachmentsAggregate;
  /** fetch data from the table: "group_types" */
  group_types: Array<IGroupTypes>;
  /** fetch aggregated fields from the table: "group_types" */
  group_types_aggregate: IGroupTypesAggregate;
  /** fetch data from the table: "group_types" using primary key columns */
  group_types_by_pk?: Maybe<IGroupTypes>;
  /** fetch data from the table: "kids_maaida" */
  kids_maaida: Array<IKidsMaaida>;
  /** fetch aggregated fields from the table: "kids_maaida" */
  kids_maaida_aggregate: IKidsMaaidaAggregate;
  /** An array relationship */
  knowledge_test_questions: Array<IKnowledgeTestQuestions>;
  /** An aggregate relationship */
  knowledge_test_questions_aggregate: IKnowledgeTestQuestionsAggregate;
  /** fetch data from the table: "knowledge_test_questions" using primary key columns */
  knowledge_test_questions_by_pk?: Maybe<IKnowledgeTestQuestions>;
  /** fetch data from the table: "knowledge_tests" */
  knowledge_tests: Array<IKnowledgeTests>;
  /** fetch aggregated fields from the table: "knowledge_tests" */
  knowledge_tests_aggregate: IKnowledgeTestsAggregate;
  /** fetch data from the table: "knowledge_tests" using primary key columns */
  knowledge_tests_by_pk?: Maybe<IKnowledgeTests>;
  /** fetch data from the table: "knowledge_tests_view" */
  knowledge_tests_view: Array<IKnowledgeTestsView>;
  /** fetch aggregated fields from the table: "knowledge_tests_view" */
  knowledge_tests_view_aggregate: IKnowledgeTestsViewAggregate;
  /** fetch data from the table: "learning_assignment_attachments" */
  learning_assignment_attachments: Array<ILearningAssignmentAttachments>;
  /** fetch aggregated fields from the table: "learning_assignment_attachments" */
  learning_assignment_attachments_aggregate: ILearningAssignmentAttachmentsAggregate;
  /** fetch data from the table: "learning_assignments" */
  learning_assignments: Array<ILearningAssignments>;
  /** fetch aggregated fields from the table: "learning_assignments" */
  learning_assignments_aggregate: ILearningAssignmentsAggregate;
  /** fetch data from the table: "learning_assignments" using primary key columns */
  learning_assignments_by_pk?: Maybe<ILearningAssignments>;
  /** fetch data from the table: "learning_assignments_view" */
  learning_assignments_view: Array<ILearningAssignmentsView>;
  /** fetch aggregated fields from the table: "learning_assignments_view" */
  learning_assignments_view_aggregate: ILearningAssignmentsViewAggregate;
  /** fetch data from the table: "lesson_attachments" */
  lesson_attachments: Array<ILessonAttachments>;
  /** fetch aggregated fields from the table: "lesson_attachments" */
  lesson_attachments_aggregate: ILessonAttachmentsAggregate;
  /** An array relationship */
  lesson_days: Array<ILessonDays>;
  /** An aggregate relationship */
  lesson_days_aggregate: ILessonDaysAggregate;
  /** fetch data from the table: "lesson_days" using primary key columns */
  lesson_days_by_pk?: Maybe<ILessonDays>;
  /** fetch data from the table: "lesson_messages" */
  lesson_messages: Array<ILessonMessages>;
  /** fetch aggregated fields from the table: "lesson_messages" */
  lesson_messages_aggregate: ILessonMessagesAggregate;
  /** fetch data from the table: "lesson_messages" using primary key columns */
  lesson_messages_by_pk?: Maybe<ILessonMessages>;
  /** An array relationship */
  lesson_partitions: Array<ILessonPartitions>;
  /** An aggregate relationship */
  lesson_partitions_aggregate: ILessonPartitionsAggregate;
  /** fetch data from the table: "lesson_partitions" using primary key columns */
  lesson_partitions_by_pk?: Maybe<ILessonPartitions>;
  /** fetch data from the table: "lesson_reactions" */
  lesson_reactions: Array<ILessonReactions>;
  /** fetch aggregated fields from the table: "lesson_reactions" */
  lesson_reactions_aggregate: ILessonReactionsAggregate;
  /** fetch data from the table: "lesson_reactions" using primary key columns */
  lesson_reactions_by_pk?: Maybe<ILessonReactions>;
  /** fetch data from the table: "lesson_reminders" */
  lesson_reminders: Array<ILessonReminders>;
  /** fetch aggregated fields from the table: "lesson_reminders" */
  lesson_reminders_aggregate: ILessonRemindersAggregate;
  /** fetch data from the table: "lesson_second_parts_view" */
  lesson_second_parts_view: Array<ILessonSecondPartsView>;
  /** fetch aggregated fields from the table: "lesson_second_parts_view" */
  lesson_second_parts_view_aggregate: ILessonSecondPartsViewAggregate;
  /** fetch data from the table: "lesson_third_parts_view" */
  lesson_third_parts_view: Array<ILessonThirdPartsView>;
  /** fetch aggregated fields from the table: "lesson_third_parts_view" */
  lesson_third_parts_view_aggregate: ILessonThirdPartsViewAggregate;
  /** fetch data from the table: "lesson_unlock_keys" */
  lesson_unlock_keys: Array<ILessonUnlockKeys>;
  /** fetch aggregated fields from the table: "lesson_unlock_keys" */
  lesson_unlock_keys_aggregate: ILessonUnlockKeysAggregate;
  /** fetch data from the table: "lesson_unlock_keys" using primary key columns */
  lesson_unlock_keys_by_pk?: Maybe<ILessonUnlockKeys>;
  /** An array relationship */
  lesson_verses: Array<ILessonVerses>;
  /** An aggregate relationship */
  lesson_verses_aggregate: ILessonVersesAggregate;
  /** fetch data from the table: "lesson_verses_attachments" */
  lesson_verses_attachments: Array<ILessonVersesAttachments>;
  /** fetch aggregated fields from the table: "lesson_verses_attachments" */
  lesson_verses_attachments_aggregate: ILessonVersesAttachmentsAggregate;
  /** fetch data from the table: "lesson_verses" using primary key columns */
  lesson_verses_by_pk?: Maybe<ILessonVerses>;
  /** An array relationship */
  lesson_words: Array<ILessonWords>;
  /** An aggregate relationship */
  lesson_words_aggregate: ILessonWordsAggregate;
  /** fetch data from the table: "lesson_words" using primary key columns */
  lesson_words_by_pk?: Maybe<ILessonWords>;
  /** An array relationship */
  lessons: Array<ILessons>;
  /** An aggregate relationship */
  lessons_aggregate: ILessonsAggregate;
  /** fetch data from the table: "lessons" using primary key columns */
  lessons_by_pk?: Maybe<ILessons>;
  /** fetch data from the table: "lessons_unlocked" */
  lessons_unlocked: Array<ILessonsUnlocked>;
  /** fetch aggregated fields from the table: "lessons_unlocked" */
  lessons_unlocked_aggregate: ILessonsUnlockedAggregate;
  /** fetch data from the table: "lessons_unlocked" using primary key columns */
  lessons_unlocked_by_pk?: Maybe<ILessonsUnlocked>;
  /** An array relationship */
  lessons_view: Array<ILessonsView>;
  /** An aggregate relationship */
  lessons_view_aggregate: ILessonsViewAggregate;
  /** An array relationship */
  lessons_with_lock: Array<ILessonsWithLock>;
  /** An aggregate relationship */
  lessons_with_lock_aggregate: ILessonsWithLockAggregate;
  /** fetch data from the table: "lessons_with_progress" */
  lessons_with_progress: Array<ILessonsWithProgress>;
  /** fetch aggregated fields from the table: "lessons_with_progress" */
  lessons_with_progress_aggregate: ILessonsWithProgressAggregate;
  /** fetch data from the table: "library_downloads" */
  library_downloads: Array<ILibraryDownloads>;
  /** fetch aggregated fields from the table: "library_downloads" */
  library_downloads_aggregate: ILibraryDownloadsAggregate;
  /** fetch data from the table: "library_downloads_attachments" */
  library_downloads_attachments: Array<ILibraryDownloadsAttachments>;
  /** fetch aggregated fields from the table: "library_downloads_attachments" */
  library_downloads_attachments_aggregate: ILibraryDownloadsAttachmentsAggregate;
  /** fetch data from the table: "library_downloads" using primary key columns */
  library_downloads_by_pk?: Maybe<ILibraryDownloads>;
  /** fetch data from the table: "message_types" */
  message_types: Array<IMessageTypes>;
  /** fetch aggregated fields from the table: "message_types" */
  message_types_aggregate: IMessageTypesAggregate;
  /** fetch data from the table: "message_types" using primary key columns */
  message_types_by_pk?: Maybe<IMessageTypes>;
  /** fetch data from the table: "messages" */
  messages: Array<IMessages>;
  /** fetch aggregated fields from the table: "messages" */
  messages_aggregate: IMessagesAggregate;
  /** fetch data from the table: "messages" using primary key columns */
  messages_by_pk?: Maybe<IMessages>;
  /** fetch data from the table: "missed_lessons_view" */
  missed_lessons_view: Array<IMissedLessonsView>;
  /** fetch aggregated fields from the table: "missed_lessons_view" */
  missed_lessons_view_aggregate: IMissedLessonsViewAggregate;
  /** fetch data from the table: "missed_qa_view" */
  missed_qa_view: Array<IMissedQaView>;
  /** fetch aggregated fields from the table: "missed_qa_view" */
  missed_qa_view_aggregate: IMissedQaViewAggregate;
  /** fetch data from the table: "missed_second_part_view" */
  missed_second_part_view: Array<IMissedSecondPartView>;
  /** fetch aggregated fields from the table: "missed_second_part_view" */
  missed_second_part_view_aggregate: IMissedSecondPartViewAggregate;
  /** fetch data from the table: "missed_third_part_view" */
  missed_third_part_view: Array<IMissedThirdPartView>;
  /** fetch aggregated fields from the table: "missed_third_part_view" */
  missed_third_part_view_aggregate: IMissedThirdPartViewAggregate;
  /** fetch data from the table: "news_articles" */
  news_articles: Array<INewsArticles>;
  /** fetch aggregated fields from the table: "news_articles" */
  news_articles_aggregate: INewsArticlesAggregate;
  /** An array relationship */
  news_articles_attachments: Array<INewsArticlesAttachments>;
  /** An aggregate relationship */
  news_articles_attachments_aggregate: INewsArticlesAttachmentsAggregate;
  /** fetch data from the table: "news_articles" using primary key columns */
  news_articles_by_pk?: Maybe<INewsArticles>;
  /** fetch data from the table: "news_articles_view" */
  news_articles_view: Array<INewsArticlesView>;
  /** fetch aggregated fields from the table: "news_articles_view" */
  news_articles_view_aggregate: INewsArticlesViewAggregate;
  /** An array relationship */
  notification_group_types: Array<INotificationGroupTypes>;
  /** An aggregate relationship */
  notification_group_types_aggregate: INotificationGroupTypesAggregate;
  /** fetch data from the table: "notification_group_types" using primary key columns */
  notification_group_types_by_pk?: Maybe<INotificationGroupTypes>;
  /** fetch data from the table: "notifications" */
  notifications: Array<INotifications>;
  /** fetch aggregated fields from the table: "notifications" */
  notifications_aggregate: INotificationsAggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<INotifications>;
  /** fetch data from the table: "personal_developments" */
  personal_developments: Array<IPersonalDevelopments>;
  /** fetch aggregated fields from the table: "personal_developments" */
  personal_developments_aggregate: IPersonalDevelopmentsAggregate;
  /** fetch data from the table: "personal_developments_attachments" */
  personal_developments_attachments: Array<IPersonalDevelopmentsAttachments>;
  /** fetch aggregated fields from the table: "personal_developments_attachments" */
  personal_developments_attachments_aggregate: IPersonalDevelopmentsAttachmentsAggregate;
  /** fetch data from the table: "personal_developments" using primary key columns */
  personal_developments_by_pk?: Maybe<IPersonalDevelopments>;
  /** fetch data from the table: "qa_videos" */
  qa_videos: Array<IQaVideos>;
  /** fetch aggregated fields from the table: "qa_videos" */
  qa_videos_aggregate: IQaVideosAggregate;
  /** fetch data from the table: "qa_videos" using primary key columns */
  qa_videos_by_pk?: Maybe<IQaVideos>;
  /** fetch data from the table: "que_jobs" */
  que_jobs: Array<IQueJobs>;
  /** fetch aggregated fields from the table: "que_jobs" */
  que_jobs_aggregate: IQueJobsAggregate;
  /** fetch data from the table: "que_jobs" using primary key columns */
  que_jobs_by_pk?: Maybe<IQueJobs>;
  /** An array relationship */
  questions: Array<IQuestions>;
  /** An aggregate relationship */
  questions_aggregate: IQuestionsAggregate;
  /** fetch data from the table: "questions" using primary key columns */
  questions_by_pk?: Maybe<IQuestions>;
  /** fetch data from the table: "reactions_grouped_by_all_parts" */
  reactions_grouped_by_all_parts: Array<IReactionsGroupedByAllParts>;
  /** fetch aggregated fields from the table: "reactions_grouped_by_all_parts" */
  reactions_grouped_by_all_parts_aggregate: IReactionsGroupedByAllPartsAggregate;
  /** fetch data from the table: "reactivation_subscriptions" */
  reactivation_subscriptions: Array<IReactivationSubscriptions>;
  /** fetch aggregated fields from the table: "reactivation_subscriptions" */
  reactivation_subscriptions_aggregate: IReactivationSubscriptionsAggregate;
  /** fetch data from the table: "recitations" */
  recitations: Array<IRecitations>;
  /** fetch aggregated fields from the table: "recitations" */
  recitations_aggregate: IRecitationsAggregate;
  /** fetch data from the table: "recitations_attachments" */
  recitations_attachments: Array<IRecitationsAttachments>;
  /** fetch aggregated fields from the table: "recitations_attachments" */
  recitations_attachments_aggregate: IRecitationsAttachmentsAggregate;
  /** fetch data from the table: "recitations" using primary key columns */
  recitations_by_pk?: Maybe<IRecitations>;
  /** fetch data from the table: "repeat_lesson_progresses" */
  repeat_lesson_progresses: Array<IRepeatLessonProgresses>;
  /** fetch aggregated fields from the table: "repeat_lesson_progresses" */
  repeat_lesson_progresses_aggregate: IRepeatLessonProgressesAggregate;
  /** fetch data from the table: "repeat_lesson_progresses" using primary key columns */
  repeat_lesson_progresses_by_pk?: Maybe<IRepeatLessonProgresses>;
  /** fetch data from the table: "repeat_lessons_view" */
  repeat_lessons_view: Array<IRepeatLessonsView>;
  /** fetch aggregated fields from the table: "repeat_lessons_view" */
  repeat_lessons_view_aggregate: IRepeatLessonsViewAggregate;
  /** fetch data from the table: "repeat_second_parts_progresses" */
  repeat_second_parts_progresses: Array<IRepeatSecondPartsProgresses>;
  /** fetch aggregated fields from the table: "repeat_second_parts_progresses" */
  repeat_second_parts_progresses_aggregate: IRepeatSecondPartsProgressesAggregate;
  /** fetch data from the table: "repeat_second_parts_progresses" using primary key columns */
  repeat_second_parts_progresses_by_pk?: Maybe<IRepeatSecondPartsProgresses>;
  /** fetch data from the table: "repeat_second_parts_view" */
  repeat_second_parts_view: Array<IRepeatSecondPartsView>;
  /** fetch aggregated fields from the table: "repeat_second_parts_view" */
  repeat_second_parts_view_aggregate: IRepeatSecondPartsViewAggregate;
  /** fetch data from the table: "repeat_third_parts_progresses" */
  repeat_third_parts_progresses: Array<IRepeatThirdPartsProgresses>;
  /** fetch aggregated fields from the table: "repeat_third_parts_progresses" */
  repeat_third_parts_progresses_aggregate: IRepeatThirdPartsProgressesAggregate;
  /** fetch data from the table: "repeat_third_parts_progresses" using primary key columns */
  repeat_third_parts_progresses_by_pk?: Maybe<IRepeatThirdPartsProgresses>;
  /** fetch data from the table: "repeat_third_parts_view" */
  repeat_third_parts_view: Array<IRepeatThirdPartsView>;
  /** fetch aggregated fields from the table: "repeat_third_parts_view" */
  repeat_third_parts_view_aggregate: IRepeatThirdPartsViewAggregate;
  /** fetch data from the table: "schema_migrations" */
  schema_migrations: Array<ISchemaMigrations>;
  /** fetch aggregated fields from the table: "schema_migrations" */
  schema_migrations_aggregate: ISchemaMigrationsAggregate;
  /** fetch data from the table: "schema_migrations" using primary key columns */
  schema_migrations_by_pk?: Maybe<ISchemaMigrations>;
  /** fetch data from the table: "second_lesson_part_mail_reminders" */
  second_lesson_part_mail_reminders: Array<ISecondLessonPartMailReminders>;
  /** fetch aggregated fields from the table: "second_lesson_part_mail_reminders" */
  second_lesson_part_mail_reminders_aggregate: ISecondLessonPartMailRemindersAggregate;
  /** An array relationship */
  semi_stream_lessons: Array<ISemiStreamLessons>;
  /** An aggregate relationship */
  semi_stream_lessons_aggregate: ISemiStreamLessonsAggregate;
  /** fetch data from the table: "semi_stream_second_parts_view" */
  semi_stream_second_parts_view: Array<ISemiStreamSecondPartsView>;
  /** fetch aggregated fields from the table: "semi_stream_second_parts_view" */
  semi_stream_second_parts_view_aggregate: ISemiStreamSecondPartsViewAggregate;
  /** fetch data from the table: "semi_stream_third_parts_view" */
  semi_stream_third_parts_view: Array<ISemiStreamThirdPartsView>;
  /** fetch aggregated fields from the table: "semi_stream_third_parts_view" */
  semi_stream_third_parts_view_aggregate: ISemiStreamThirdPartsViewAggregate;
  /** fetch data from the table: "stars_view" */
  stars_view: Array<IStarsView>;
  /** fetch aggregated fields from the table: "stars_view" */
  stars_view_aggregate: IStarsViewAggregate;
  /** fetch data from the table: "study_reminders" */
  study_reminders: Array<IStudyReminders>;
  /** fetch aggregated fields from the table: "study_reminders" */
  study_reminders_aggregate: IStudyRemindersAggregate;
  /** fetch data from the table: "subscription_configs" */
  subscription_configs: Array<ISubscriptionConfigs>;
  /** fetch aggregated fields from the table: "subscription_configs" */
  subscription_configs_aggregate: ISubscriptionConfigsAggregate;
  /** fetch data from the table: "subscription_configs" using primary key columns */
  subscription_configs_by_pk?: Maybe<ISubscriptionConfigs>;
  /** fetch data from the table: "subscription_end_reminders" */
  subscription_end_reminders: Array<ISubscriptionEndReminders>;
  /** fetch aggregated fields from the table: "subscription_end_reminders" */
  subscription_end_reminders_aggregate: ISubscriptionEndRemindersAggregate;
  /** fetch data from the table: "subscription_mail_reminders" */
  subscription_mail_reminders: Array<ISubscriptionMailReminders>;
  /** fetch aggregated fields from the table: "subscription_mail_reminders" */
  subscription_mail_reminders_aggregate: ISubscriptionMailRemindersAggregate;
  /** An array relationship */
  subscriptions: Array<ISubscriptions>;
  /** An aggregate relationship */
  subscriptions_aggregate: ISubscriptionsAggregate;
  /** fetch data from the table: "subscriptions" using primary key columns */
  subscriptions_by_pk?: Maybe<ISubscriptions>;
  /** fetch data from the table: "subscriptions_by_user" */
  subscriptions_by_user: Array<ISubscriptionsByUser>;
  /** fetch aggregated fields from the table: "subscriptions_by_user" */
  subscriptions_by_user_aggregate: ISubscriptionsByUserAggregate;
  /** An array relationship */
  support_topic_entries: Array<ISupportTopicEntries>;
  /** An aggregate relationship */
  support_topic_entries_aggregate: ISupportTopicEntriesAggregate;
  /** fetch data from the table: "support_topic_entries" using primary key columns */
  support_topic_entries_by_pk?: Maybe<ISupportTopicEntries>;
  /** fetch data from the table: "support_topics" */
  support_topics: Array<ISupportTopics>;
  /** fetch aggregated fields from the table: "support_topics" */
  support_topics_aggregate: ISupportTopicsAggregate;
  /** fetch data from the table: "support_topics" using primary key columns */
  support_topics_by_pk?: Maybe<ISupportTopics>;
  /** fetch data from the table: "suspensions" */
  suspensions: Array<ISuspensions>;
  /** fetch aggregated fields from the table: "suspensions" */
  suspensions_aggregate: ISuspensionsAggregate;
  /** fetch data from the table: "suspensions" using primary key columns */
  suspensions_by_pk?: Maybe<ISuspensions>;
  /** fetch data from the table: "tmp_lessons" */
  tmp_lessons: Array<ITmpLessons>;
  /** fetch aggregated fields from the table: "tmp_lessons" */
  tmp_lessons_aggregate: ITmpLessonsAggregate;
  /** fetch data from the table: "tmp_lessons" using primary key columns */
  tmp_lessons_by_pk?: Maybe<ITmpLessons>;
  /** fetch data from the table: "tmp_progress" */
  tmp_progress: Array<ITmpProgress>;
  /** fetch aggregated fields from the table: "tmp_progress" */
  tmp_progress_aggregate: ITmpProgressAggregate;
  /** fetch data from the table: "tmp_progress" using primary key columns */
  tmp_progress_by_pk?: Maybe<ITmpProgress>;
  /** fetch data from the table: "tmp_stream_lessons" */
  tmp_stream_lessons: Array<ITmpStreamLessons>;
  /** fetch aggregated fields from the table: "tmp_stream_lessons" */
  tmp_stream_lessons_aggregate: ITmpStreamLessonsAggregate;
  /** fetch data from the table: "unique_user_sessions" */
  unique_user_sessions: Array<IUniqueUserSessions>;
  /** fetch aggregated fields from the table: "unique_user_sessions" */
  unique_user_sessions_aggregate: IUniqueUserSessionsAggregate;
  /** fetch data from the table: "unlockables" */
  unlockables: Array<IUnlockables>;
  /** fetch aggregated fields from the table: "unlockables" */
  unlockables_aggregate: IUnlockablesAggregate;
  /** fetch data from the table: "unlockables" using primary key columns */
  unlockables_by_pk?: Maybe<IUnlockables>;
  /** An array relationship */
  user_children_unlockables: Array<IUserChildrenUnlockables>;
  /** An aggregate relationship */
  user_children_unlockables_aggregate: IUserChildrenUnlockablesAggregate;
  /** fetch data from the table: "user_children_unlockables" using primary key columns */
  user_children_unlockables_by_pk?: Maybe<IUserChildrenUnlockables>;
  /** fetch data from the table: "user_course_usage" */
  user_course_usage: Array<IUserCourseUsage>;
  /** fetch aggregated fields from the table: "user_course_usage" */
  user_course_usage_aggregate: IUserCourseUsageAggregate;
  /** fetch data from the table: "user_lesson_messages_view" */
  user_lesson_messages_view: Array<IUserLessonMessagesView>;
  /** fetch aggregated fields from the table: "user_lesson_messages_view" */
  user_lesson_messages_view_aggregate: IUserLessonMessagesViewAggregate;
  /** fetch data from the table: "user_lesson_parts_progresses" */
  user_lesson_parts_progresses: Array<IUserLessonPartsProgresses>;
  /** fetch aggregated fields from the table: "user_lesson_parts_progresses" */
  user_lesson_parts_progresses_aggregate: IUserLessonPartsProgressesAggregate;
  /** fetch data from the table: "user_lesson_parts_progresses" using primary key columns */
  user_lesson_parts_progresses_by_pk?: Maybe<IUserLessonPartsProgresses>;
  /** An array relationship */
  user_lesson_progresses: Array<IUserLessonProgresses>;
  /** An aggregate relationship */
  user_lesson_progresses_aggregate: IUserLessonProgressesAggregate;
  /** fetch data from the table: "user_lesson_progresses" using primary key columns */
  user_lesson_progresses_by_pk?: Maybe<IUserLessonProgresses>;
  /** fetch data from the table: "user_qa_progresses" */
  user_qa_progresses: Array<IUserQaProgresses>;
  /** fetch aggregated fields from the table: "user_qa_progresses" */
  user_qa_progresses_aggregate: IUserQaProgressesAggregate;
  /** fetch data from the table: "user_qa_progresses" using primary key columns */
  user_qa_progresses_by_pk?: Maybe<IUserQaProgresses>;
  /** fetch data from the table: "user_third_parts_progresses" */
  user_third_parts_progresses: Array<IUserThirdPartsProgresses>;
  /** fetch aggregated fields from the table: "user_third_parts_progresses" */
  user_third_parts_progresses_aggregate: IUserThirdPartsProgressesAggregate;
  /** fetch data from the table: "user_third_parts_progresses" using primary key columns */
  user_third_parts_progresses_by_pk?: Maybe<IUserThirdPartsProgresses>;
  /** fetch data from the table: "users" */
  users: Array<IUsers>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: IUsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<IUsers>;
  /** fetch data from the table: "users_child_details" */
  users_child_details: Array<IUsersChildDetails>;
  /** fetch aggregated fields from the table: "users_child_details" */
  users_child_details_aggregate: IUsersChildDetailsAggregate;
  /** fetch data from the table: "users_child_details" using primary key columns */
  users_child_details_by_pk?: Maybe<IUsersChildDetails>;
  /** An array relationship */
  users_children: Array<IUsersChildren>;
  /** An aggregate relationship */
  users_children_aggregate: IUsersChildrenAggregate;
  /** fetch data from the table: "users_children" using primary key columns */
  users_children_by_pk?: Maybe<IUsersChildren>;
  /** fetch data from the table: "users_sessions" */
  users_sessions: Array<IUsersSessions>;
  /** fetch aggregated fields from the table: "users_sessions" */
  users_sessions_aggregate: IUsersSessionsAggregate;
  /** fetch data from the table: "users_sessions" using primary key columns */
  users_sessions_by_pk?: Maybe<IUsersSessions>;
  /** An array relationship */
  vacation_group_types: Array<IVacationGroupTypes>;
  /** An aggregate relationship */
  vacation_group_types_aggregate: IVacationGroupTypesAggregate;
  /** fetch data from the table: "vacation_group_types" using primary key columns */
  vacation_group_types_by_pk?: Maybe<IVacationGroupTypes>;
  /** fetch data from the table: "vacations" */
  vacations: Array<IVacations>;
  /** fetch aggregated fields from the table: "vacations" */
  vacations_aggregate: IVacationsAggregate;
  /** fetch data from the table: "vacations" using primary key columns */
  vacations_by_pk?: Maybe<IVacations>;
  /** fetch data from the table: "weekly_facts" */
  weekly_facts: Array<IWeeklyFacts>;
  /** fetch aggregated fields from the table: "weekly_facts" */
  weekly_facts_aggregate: IWeeklyFactsAggregate;
  /** fetch data from the table: "weekly_facts" using primary key columns */
  weekly_facts_by_pk?: Maybe<IWeeklyFacts>;
  /** An array relationship */
  wp_orders: Array<IWpOrders>;
  /** An aggregate relationship */
  wp_orders_aggregate: IWpOrdersAggregate;
  /** fetch data from the table: "wp_orders" using primary key columns */
  wp_orders_by_pk?: Maybe<IWpOrders>;
  /** fetch data from the table: "wp_products" */
  wp_products: Array<IWpProducts>;
  /** fetch aggregated fields from the table: "wp_products" */
  wp_products_aggregate: IWpProductsAggregate;
  /** fetch data from the table: "wp_products" using primary key columns */
  wp_products_by_pk?: Maybe<IWpProducts>;
  /** An array relationship */
  wp_subscription_orders: Array<IWpSubscriptionOrders>;
  /** An aggregate relationship */
  wp_subscription_orders_aggregate: IWpSubscriptionOrdersAggregate;
  /** fetch data from the table: "wp_subscription_orders" using primary key columns */
  wp_subscription_orders_by_pk?: Maybe<IWpSubscriptionOrders>;
}


export interface ISubscriptionRootAchievementTypesArgs {
  distinct_on?: InputMaybe<Array<IAchievementTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAchievementTypesOrderBy>>;
  where?: InputMaybe<IAchievementTypesBoolExp>;
}


export interface ISubscriptionRootAchievementTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<IAchievementTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAchievementTypesOrderBy>>;
  where?: InputMaybe<IAchievementTypesBoolExp>;
}


export interface ISubscriptionRootAchievementTypesByPkArgs {
  name: Scalars['String'];
}


export interface ISubscriptionRootAchievementsArgs {
  distinct_on?: InputMaybe<Array<IAchievementsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAchievementsOrderBy>>;
  where?: InputMaybe<IAchievementsBoolExp>;
}


export interface ISubscriptionRootAchievementsAccomplishedArgs {
  distinct_on?: InputMaybe<Array<IAchievementsAccomplishedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAchievementsAccomplishedOrderBy>>;
  where?: InputMaybe<IAchievementsAccomplishedBoolExp>;
}


export interface ISubscriptionRootAchievementsAccomplishedAggregateArgs {
  distinct_on?: InputMaybe<Array<IAchievementsAccomplishedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAchievementsAccomplishedOrderBy>>;
  where?: InputMaybe<IAchievementsAccomplishedBoolExp>;
}


export interface ISubscriptionRootAchievementsAccomplishedByPkArgs {
  achievement_id: Scalars['uuid'];
  user_child_id: Scalars['uuid'];
}


export interface ISubscriptionRootAchievementsAggregateArgs {
  distinct_on?: InputMaybe<Array<IAchievementsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAchievementsOrderBy>>;
  where?: InputMaybe<IAchievementsBoolExp>;
}


export interface ISubscriptionRootAchievementsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootAchievementsViewArgs {
  distinct_on?: InputMaybe<Array<IAchievementsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAchievementsViewOrderBy>>;
  where?: InputMaybe<IAchievementsViewBoolExp>;
}


export interface ISubscriptionRootAchievementsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IAchievementsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAchievementsViewOrderBy>>;
  where?: InputMaybe<IAchievementsViewBoolExp>;
}


export interface ISubscriptionRootActiveStorageAttachmentsArgs {
  distinct_on?: InputMaybe<Array<IActiveStorageAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IActiveStorageAttachmentsOrderBy>>;
  where?: InputMaybe<IActiveStorageAttachmentsBoolExp>;
}


export interface ISubscriptionRootActiveStorageAttachmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<IActiveStorageAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IActiveStorageAttachmentsOrderBy>>;
  where?: InputMaybe<IActiveStorageAttachmentsBoolExp>;
}


export interface ISubscriptionRootActiveStorageAttachmentsByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootActiveStorageBlobsArgs {
  distinct_on?: InputMaybe<Array<IActiveStorageBlobsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IActiveStorageBlobsOrderBy>>;
  where?: InputMaybe<IActiveStorageBlobsBoolExp>;
}


export interface ISubscriptionRootActiveStorageBlobsAggregateArgs {
  distinct_on?: InputMaybe<Array<IActiveStorageBlobsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IActiveStorageBlobsOrderBy>>;
  where?: InputMaybe<IActiveStorageBlobsBoolExp>;
}


export interface ISubscriptionRootActiveStorageBlobsByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootAdditionalLessonsArgs {
  distinct_on?: InputMaybe<Array<IAdditionalLessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAdditionalLessonsOrderBy>>;
  where?: InputMaybe<IAdditionalLessonsBoolExp>;
}


export interface ISubscriptionRootAdditionalLessonsAggregateArgs {
  distinct_on?: InputMaybe<Array<IAdditionalLessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAdditionalLessonsOrderBy>>;
  where?: InputMaybe<IAdditionalLessonsBoolExp>;
}


export interface ISubscriptionRootAdditionalLessonsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootAdditionalLessonsGroupTypesArgs {
  distinct_on?: InputMaybe<Array<IAdditionalLessonsGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAdditionalLessonsGroupTypesOrderBy>>;
  where?: InputMaybe<IAdditionalLessonsGroupTypesBoolExp>;
}


export interface ISubscriptionRootAdditionalLessonsGroupTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<IAdditionalLessonsGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAdditionalLessonsGroupTypesOrderBy>>;
  where?: InputMaybe<IAdditionalLessonsGroupTypesBoolExp>;
}


export interface ISubscriptionRootAdditionalLessonsGroupTypesByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootAdditionalLessonsViewArgs {
  distinct_on?: InputMaybe<Array<IAdditionalLessonsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAdditionalLessonsViewOrderBy>>;
  where?: InputMaybe<IAdditionalLessonsViewBoolExp>;
}


export interface ISubscriptionRootAdditionalLessonsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IAdditionalLessonsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAdditionalLessonsViewOrderBy>>;
  where?: InputMaybe<IAdditionalLessonsViewBoolExp>;
}


export interface ISubscriptionRootAmbassadorLessonsViewArgs {
  distinct_on?: InputMaybe<Array<IAmbassadorLessonsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAmbassadorLessonsViewOrderBy>>;
  where?: InputMaybe<IAmbassadorLessonsViewBoolExp>;
}


export interface ISubscriptionRootAmbassadorLessonsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IAmbassadorLessonsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAmbassadorLessonsViewOrderBy>>;
  where?: InputMaybe<IAmbassadorLessonsViewBoolExp>;
}


export interface ISubscriptionRootAmbassadorsArgs {
  distinct_on?: InputMaybe<Array<IAmbassadorsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAmbassadorsOrderBy>>;
  where?: InputMaybe<IAmbassadorsBoolExp>;
}


export interface ISubscriptionRootAmbassadorsAggregateArgs {
  distinct_on?: InputMaybe<Array<IAmbassadorsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAmbassadorsOrderBy>>;
  where?: InputMaybe<IAmbassadorsBoolExp>;
}


export interface ISubscriptionRootAmbassadorsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootAnnualScheduleAttachmentsArgs {
  distinct_on?: InputMaybe<Array<IAnnualScheduleAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAnnualScheduleAttachmentsOrderBy>>;
  where?: InputMaybe<IAnnualScheduleAttachmentsBoolExp>;
}


export interface ISubscriptionRootAnnualScheduleAttachmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<IAnnualScheduleAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAnnualScheduleAttachmentsOrderBy>>;
  where?: InputMaybe<IAnnualScheduleAttachmentsBoolExp>;
}


export interface ISubscriptionRootAnnualSchedulesArgs {
  distinct_on?: InputMaybe<Array<IAnnualSchedulesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAnnualSchedulesOrderBy>>;
  where?: InputMaybe<IAnnualSchedulesBoolExp>;
}


export interface ISubscriptionRootAnnualSchedulesAggregateArgs {
  distinct_on?: InputMaybe<Array<IAnnualSchedulesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAnnualSchedulesOrderBy>>;
  where?: InputMaybe<IAnnualSchedulesBoolExp>;
}


export interface ISubscriptionRootAnnualSchedulesByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootAnswersArgs {
  distinct_on?: InputMaybe<Array<IAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAnswersOrderBy>>;
  where?: InputMaybe<IAnswersBoolExp>;
}


export interface ISubscriptionRootAnswersAggregateArgs {
  distinct_on?: InputMaybe<Array<IAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAnswersOrderBy>>;
  where?: InputMaybe<IAnswersBoolExp>;
}


export interface ISubscriptionRootAnswersByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootAvailableSecondPartsViewArgs {
  distinct_on?: InputMaybe<Array<IAvailableSecondPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAvailableSecondPartsViewOrderBy>>;
  where?: InputMaybe<IAvailableSecondPartsViewBoolExp>;
}


export interface ISubscriptionRootAvailableSecondPartsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IAvailableSecondPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IAvailableSecondPartsViewOrderBy>>;
  where?: InputMaybe<IAvailableSecondPartsViewBoolExp>;
}


export interface ISubscriptionRootCourseGroupsArgs {
  distinct_on?: InputMaybe<Array<ICourseGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ICourseGroupsOrderBy>>;
  where?: InputMaybe<ICourseGroupsBoolExp>;
}


export interface ISubscriptionRootCourseGroupsAggregateArgs {
  distinct_on?: InputMaybe<Array<ICourseGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ICourseGroupsOrderBy>>;
  where?: InputMaybe<ICourseGroupsBoolExp>;
}


export interface ISubscriptionRootCourseGroupsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootCourseTypesArgs {
  distinct_on?: InputMaybe<Array<ICourseTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ICourseTypesOrderBy>>;
  where?: InputMaybe<ICourseTypesBoolExp>;
}


export interface ISubscriptionRootCourseTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<ICourseTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ICourseTypesOrderBy>>;
  where?: InputMaybe<ICourseTypesBoolExp>;
}


export interface ISubscriptionRootCourseTypesByPkArgs {
  name: Scalars['String'];
}


export interface ISubscriptionRootCoursesArgs {
  distinct_on?: InputMaybe<Array<ICoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ICoursesOrderBy>>;
  where?: InputMaybe<ICoursesBoolExp>;
}


export interface ISubscriptionRootCoursesAggregateArgs {
  distinct_on?: InputMaybe<Array<ICoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ICoursesOrderBy>>;
  where?: InputMaybe<ICoursesBoolExp>;
}


export interface ISubscriptionRootCoursesByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootDictionaryWordsArgs {
  distinct_on?: InputMaybe<Array<IDictionaryWordsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IDictionaryWordsOrderBy>>;
  where?: InputMaybe<IDictionaryWordsBoolExp>;
}


export interface ISubscriptionRootDictionaryWordsAggregateArgs {
  distinct_on?: InputMaybe<Array<IDictionaryWordsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IDictionaryWordsOrderBy>>;
  where?: InputMaybe<IDictionaryWordsBoolExp>;
}


export interface ISubscriptionRootDictionaryWordsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootEventLocationsArgs {
  distinct_on?: InputMaybe<Array<IEventLocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventLocationsOrderBy>>;
  where?: InputMaybe<IEventLocationsBoolExp>;
}


export interface ISubscriptionRootEventLocationsAggregateArgs {
  distinct_on?: InputMaybe<Array<IEventLocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventLocationsOrderBy>>;
  where?: InputMaybe<IEventLocationsBoolExp>;
}


export interface ISubscriptionRootEventLocationsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootEventRegistrationsArgs {
  distinct_on?: InputMaybe<Array<IEventRegistrationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventRegistrationsOrderBy>>;
  where?: InputMaybe<IEventRegistrationsBoolExp>;
}


export interface ISubscriptionRootEventRegistrationsAggregateArgs {
  distinct_on?: InputMaybe<Array<IEventRegistrationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventRegistrationsOrderBy>>;
  where?: InputMaybe<IEventRegistrationsBoolExp>;
}


export interface ISubscriptionRootEventRegistrationsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootEventsArgs {
  distinct_on?: InputMaybe<Array<IEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventsOrderBy>>;
  where?: InputMaybe<IEventsBoolExp>;
}


export interface ISubscriptionRootEventsAggregateArgs {
  distinct_on?: InputMaybe<Array<IEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventsOrderBy>>;
  where?: InputMaybe<IEventsBoolExp>;
}


export interface ISubscriptionRootEventsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootEventsGroupTypesArgs {
  distinct_on?: InputMaybe<Array<IEventsGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventsGroupTypesOrderBy>>;
  where?: InputMaybe<IEventsGroupTypesBoolExp>;
}


export interface ISubscriptionRootEventsGroupTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<IEventsGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventsGroupTypesOrderBy>>;
  where?: InputMaybe<IEventsGroupTypesBoolExp>;
}


export interface ISubscriptionRootEventsGroupTypesByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootEventsViewArgs {
  distinct_on?: InputMaybe<Array<IEventsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventsViewOrderBy>>;
  where?: InputMaybe<IEventsViewBoolExp>;
}


export interface ISubscriptionRootEventsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IEventsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IEventsViewOrderBy>>;
  where?: InputMaybe<IEventsViewBoolExp>;
}


export interface ISubscriptionRootFavoriteLessonMomentsArgs {
  distinct_on?: InputMaybe<Array<IFavoriteLessonMomentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFavoriteLessonMomentsOrderBy>>;
  where?: InputMaybe<IFavoriteLessonMomentsBoolExp>;
}


export interface ISubscriptionRootFavoriteLessonMomentsAggregateArgs {
  distinct_on?: InputMaybe<Array<IFavoriteLessonMomentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFavoriteLessonMomentsOrderBy>>;
  where?: InputMaybe<IFavoriteLessonMomentsBoolExp>;
}


export interface ISubscriptionRootFavoriteLessonMomentsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootFinalTestQuestionsArgs {
  distinct_on?: InputMaybe<Array<IFinalTestQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFinalTestQuestionsOrderBy>>;
  where?: InputMaybe<IFinalTestQuestionsBoolExp>;
}


export interface ISubscriptionRootFinalTestQuestionsAggregateArgs {
  distinct_on?: InputMaybe<Array<IFinalTestQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFinalTestQuestionsOrderBy>>;
  where?: InputMaybe<IFinalTestQuestionsBoolExp>;
}


export interface ISubscriptionRootFinalTestQuestionsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootFinalTestResultsArgs {
  distinct_on?: InputMaybe<Array<IFinalTestResultsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFinalTestResultsOrderBy>>;
  where?: InputMaybe<IFinalTestResultsBoolExp>;
}


export interface ISubscriptionRootFinalTestResultsAggregateArgs {
  distinct_on?: InputMaybe<Array<IFinalTestResultsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFinalTestResultsOrderBy>>;
  where?: InputMaybe<IFinalTestResultsBoolExp>;
}


export interface ISubscriptionRootFinalTestResultsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootFinalTestsViewArgs {
  distinct_on?: InputMaybe<Array<IFinalTestsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFinalTestsViewOrderBy>>;
  where?: InputMaybe<IFinalTestsViewBoolExp>;
}


export interface ISubscriptionRootFinalTestsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IFinalTestsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFinalTestsViewOrderBy>>;
  where?: InputMaybe<IFinalTestsViewBoolExp>;
}


export interface ISubscriptionRootFirstLessonPartMailRemindersArgs {
  distinct_on?: InputMaybe<Array<IFirstLessonPartMailRemindersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFirstLessonPartMailRemindersOrderBy>>;
  where?: InputMaybe<IFirstLessonPartMailRemindersBoolExp>;
}


export interface ISubscriptionRootFirstLessonPartMailRemindersAggregateArgs {
  distinct_on?: InputMaybe<Array<IFirstLessonPartMailRemindersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFirstLessonPartMailRemindersOrderBy>>;
  where?: InputMaybe<IFirstLessonPartMailRemindersBoolExp>;
}


export interface ISubscriptionRootFriendRequestsArgs {
  distinct_on?: InputMaybe<Array<IFriendRequestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFriendRequestsOrderBy>>;
  where?: InputMaybe<IFriendRequestsBoolExp>;
}


export interface ISubscriptionRootFriendRequestsAggregateArgs {
  distinct_on?: InputMaybe<Array<IFriendRequestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFriendRequestsOrderBy>>;
  where?: InputMaybe<IFriendRequestsBoolExp>;
}


export interface ISubscriptionRootFriendRequestsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootFriendRequestsViewArgs {
  distinct_on?: InputMaybe<Array<IFriendRequestsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFriendRequestsViewOrderBy>>;
  where?: InputMaybe<IFriendRequestsViewBoolExp>;
}


export interface ISubscriptionRootFriendRequestsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IFriendRequestsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFriendRequestsViewOrderBy>>;
  where?: InputMaybe<IFriendRequestsViewBoolExp>;
}


export interface ISubscriptionRootFriendsViewArgs {
  distinct_on?: InputMaybe<Array<IFriendsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFriendsViewOrderBy>>;
  where?: InputMaybe<IFriendsViewBoolExp>;
}


export interface ISubscriptionRootFriendsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IFriendsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFriendsViewOrderBy>>;
  where?: InputMaybe<IFriendsViewBoolExp>;
}


export interface ISubscriptionRootGroupTypeAttachmentsArgs {
  distinct_on?: InputMaybe<Array<IGroupTypeAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IGroupTypeAttachmentsOrderBy>>;
  where?: InputMaybe<IGroupTypeAttachmentsBoolExp>;
}


export interface ISubscriptionRootGroupTypeAttachmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<IGroupTypeAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IGroupTypeAttachmentsOrderBy>>;
  where?: InputMaybe<IGroupTypeAttachmentsBoolExp>;
}


export interface ISubscriptionRootGroupTypesArgs {
  distinct_on?: InputMaybe<Array<IGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IGroupTypesOrderBy>>;
  where?: InputMaybe<IGroupTypesBoolExp>;
}


export interface ISubscriptionRootGroupTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<IGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IGroupTypesOrderBy>>;
  where?: InputMaybe<IGroupTypesBoolExp>;
}


export interface ISubscriptionRootGroupTypesByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootKidsMaaidaArgs {
  distinct_on?: InputMaybe<Array<IKidsMaaidaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IKidsMaaidaOrderBy>>;
  where?: InputMaybe<IKidsMaaidaBoolExp>;
}


export interface ISubscriptionRootKidsMaaidaAggregateArgs {
  distinct_on?: InputMaybe<Array<IKidsMaaidaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IKidsMaaidaOrderBy>>;
  where?: InputMaybe<IKidsMaaidaBoolExp>;
}


export interface ISubscriptionRootKnowledgeTestQuestionsArgs {
  distinct_on?: InputMaybe<Array<IKnowledgeTestQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IKnowledgeTestQuestionsOrderBy>>;
  where?: InputMaybe<IKnowledgeTestQuestionsBoolExp>;
}


export interface ISubscriptionRootKnowledgeTestQuestionsAggregateArgs {
  distinct_on?: InputMaybe<Array<IKnowledgeTestQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IKnowledgeTestQuestionsOrderBy>>;
  where?: InputMaybe<IKnowledgeTestQuestionsBoolExp>;
}


export interface ISubscriptionRootKnowledgeTestQuestionsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootKnowledgeTestsArgs {
  distinct_on?: InputMaybe<Array<IKnowledgeTestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IKnowledgeTestsOrderBy>>;
  where?: InputMaybe<IKnowledgeTestsBoolExp>;
}


export interface ISubscriptionRootKnowledgeTestsAggregateArgs {
  distinct_on?: InputMaybe<Array<IKnowledgeTestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IKnowledgeTestsOrderBy>>;
  where?: InputMaybe<IKnowledgeTestsBoolExp>;
}


export interface ISubscriptionRootKnowledgeTestsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootKnowledgeTestsViewArgs {
  distinct_on?: InputMaybe<Array<IKnowledgeTestsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IKnowledgeTestsViewOrderBy>>;
  where?: InputMaybe<IKnowledgeTestsViewBoolExp>;
}


export interface ISubscriptionRootKnowledgeTestsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IKnowledgeTestsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IKnowledgeTestsViewOrderBy>>;
  where?: InputMaybe<IKnowledgeTestsViewBoolExp>;
}


export interface ISubscriptionRootLearningAssignmentAttachmentsArgs {
  distinct_on?: InputMaybe<Array<ILearningAssignmentAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILearningAssignmentAttachmentsOrderBy>>;
  where?: InputMaybe<ILearningAssignmentAttachmentsBoolExp>;
}


export interface ISubscriptionRootLearningAssignmentAttachmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILearningAssignmentAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILearningAssignmentAttachmentsOrderBy>>;
  where?: InputMaybe<ILearningAssignmentAttachmentsBoolExp>;
}


export interface ISubscriptionRootLearningAssignmentsArgs {
  distinct_on?: InputMaybe<Array<ILearningAssignmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILearningAssignmentsOrderBy>>;
  where?: InputMaybe<ILearningAssignmentsBoolExp>;
}


export interface ISubscriptionRootLearningAssignmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILearningAssignmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILearningAssignmentsOrderBy>>;
  where?: InputMaybe<ILearningAssignmentsBoolExp>;
}


export interface ISubscriptionRootLearningAssignmentsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootLearningAssignmentsViewArgs {
  distinct_on?: InputMaybe<Array<ILearningAssignmentsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILearningAssignmentsViewOrderBy>>;
  where?: InputMaybe<ILearningAssignmentsViewBoolExp>;
}


export interface ISubscriptionRootLearningAssignmentsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<ILearningAssignmentsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILearningAssignmentsViewOrderBy>>;
  where?: InputMaybe<ILearningAssignmentsViewBoolExp>;
}


export interface ISubscriptionRootLessonAttachmentsArgs {
  distinct_on?: InputMaybe<Array<ILessonAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonAttachmentsOrderBy>>;
  where?: InputMaybe<ILessonAttachmentsBoolExp>;
}


export interface ISubscriptionRootLessonAttachmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonAttachmentsOrderBy>>;
  where?: InputMaybe<ILessonAttachmentsBoolExp>;
}


export interface ISubscriptionRootLessonDaysArgs {
  distinct_on?: InputMaybe<Array<ILessonDaysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonDaysOrderBy>>;
  where?: InputMaybe<ILessonDaysBoolExp>;
}


export interface ISubscriptionRootLessonDaysAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonDaysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonDaysOrderBy>>;
  where?: InputMaybe<ILessonDaysBoolExp>;
}


export interface ISubscriptionRootLessonDaysByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootLessonMessagesArgs {
  distinct_on?: InputMaybe<Array<ILessonMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonMessagesOrderBy>>;
  where?: InputMaybe<ILessonMessagesBoolExp>;
}


export interface ISubscriptionRootLessonMessagesAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonMessagesOrderBy>>;
  where?: InputMaybe<ILessonMessagesBoolExp>;
}


export interface ISubscriptionRootLessonMessagesByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootLessonPartitionsArgs {
  distinct_on?: InputMaybe<Array<ILessonPartitionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonPartitionsOrderBy>>;
  where?: InputMaybe<ILessonPartitionsBoolExp>;
}


export interface ISubscriptionRootLessonPartitionsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonPartitionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonPartitionsOrderBy>>;
  where?: InputMaybe<ILessonPartitionsBoolExp>;
}


export interface ISubscriptionRootLessonPartitionsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootLessonReactionsArgs {
  distinct_on?: InputMaybe<Array<ILessonReactionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonReactionsOrderBy>>;
  where?: InputMaybe<ILessonReactionsBoolExp>;
}


export interface ISubscriptionRootLessonReactionsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonReactionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonReactionsOrderBy>>;
  where?: InputMaybe<ILessonReactionsBoolExp>;
}


export interface ISubscriptionRootLessonReactionsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootLessonRemindersArgs {
  distinct_on?: InputMaybe<Array<ILessonRemindersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonRemindersOrderBy>>;
  where?: InputMaybe<ILessonRemindersBoolExp>;
}


export interface ISubscriptionRootLessonRemindersAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonRemindersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonRemindersOrderBy>>;
  where?: InputMaybe<ILessonRemindersBoolExp>;
}


export interface ISubscriptionRootLessonSecondPartsViewArgs {
  distinct_on?: InputMaybe<Array<ILessonSecondPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonSecondPartsViewOrderBy>>;
  where?: InputMaybe<ILessonSecondPartsViewBoolExp>;
}


export interface ISubscriptionRootLessonSecondPartsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonSecondPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonSecondPartsViewOrderBy>>;
  where?: InputMaybe<ILessonSecondPartsViewBoolExp>;
}


export interface ISubscriptionRootLessonThirdPartsViewArgs {
  distinct_on?: InputMaybe<Array<ILessonThirdPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonThirdPartsViewOrderBy>>;
  where?: InputMaybe<ILessonThirdPartsViewBoolExp>;
}


export interface ISubscriptionRootLessonThirdPartsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonThirdPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonThirdPartsViewOrderBy>>;
  where?: InputMaybe<ILessonThirdPartsViewBoolExp>;
}


export interface ISubscriptionRootLessonUnlockKeysArgs {
  distinct_on?: InputMaybe<Array<ILessonUnlockKeysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonUnlockKeysOrderBy>>;
  where?: InputMaybe<ILessonUnlockKeysBoolExp>;
}


export interface ISubscriptionRootLessonUnlockKeysAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonUnlockKeysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonUnlockKeysOrderBy>>;
  where?: InputMaybe<ILessonUnlockKeysBoolExp>;
}


export interface ISubscriptionRootLessonUnlockKeysByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootLessonVersesArgs {
  distinct_on?: InputMaybe<Array<ILessonVersesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonVersesOrderBy>>;
  where?: InputMaybe<ILessonVersesBoolExp>;
}


export interface ISubscriptionRootLessonVersesAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonVersesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonVersesOrderBy>>;
  where?: InputMaybe<ILessonVersesBoolExp>;
}


export interface ISubscriptionRootLessonVersesAttachmentsArgs {
  distinct_on?: InputMaybe<Array<ILessonVersesAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonVersesAttachmentsOrderBy>>;
  where?: InputMaybe<ILessonVersesAttachmentsBoolExp>;
}


export interface ISubscriptionRootLessonVersesAttachmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonVersesAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonVersesAttachmentsOrderBy>>;
  where?: InputMaybe<ILessonVersesAttachmentsBoolExp>;
}


export interface ISubscriptionRootLessonVersesByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootLessonWordsArgs {
  distinct_on?: InputMaybe<Array<ILessonWordsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonWordsOrderBy>>;
  where?: InputMaybe<ILessonWordsBoolExp>;
}


export interface ISubscriptionRootLessonWordsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonWordsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonWordsOrderBy>>;
  where?: InputMaybe<ILessonWordsBoolExp>;
}


export interface ISubscriptionRootLessonWordsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootLessonsArgs {
  distinct_on?: InputMaybe<Array<ILessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsOrderBy>>;
  where?: InputMaybe<ILessonsBoolExp>;
}


export interface ISubscriptionRootLessonsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsOrderBy>>;
  where?: InputMaybe<ILessonsBoolExp>;
}


export interface ISubscriptionRootLessonsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootLessonsUnlockedArgs {
  distinct_on?: InputMaybe<Array<ILessonsUnlockedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsUnlockedOrderBy>>;
  where?: InputMaybe<ILessonsUnlockedBoolExp>;
}


export interface ISubscriptionRootLessonsUnlockedAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonsUnlockedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsUnlockedOrderBy>>;
  where?: InputMaybe<ILessonsUnlockedBoolExp>;
}


export interface ISubscriptionRootLessonsUnlockedByPkArgs {
  lesson_id: Scalars['uuid'];
  user_child_id: Scalars['uuid'];
}


export interface ISubscriptionRootLessonsViewArgs {
  distinct_on?: InputMaybe<Array<ILessonsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsViewOrderBy>>;
  where?: InputMaybe<ILessonsViewBoolExp>;
}


export interface ISubscriptionRootLessonsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsViewOrderBy>>;
  where?: InputMaybe<ILessonsViewBoolExp>;
}


export interface ISubscriptionRootLessonsWithLockArgs {
  distinct_on?: InputMaybe<Array<ILessonsWithLockSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsWithLockOrderBy>>;
  where?: InputMaybe<ILessonsWithLockBoolExp>;
}


export interface ISubscriptionRootLessonsWithLockAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonsWithLockSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsWithLockOrderBy>>;
  where?: InputMaybe<ILessonsWithLockBoolExp>;
}


export interface ISubscriptionRootLessonsWithProgressArgs {
  distinct_on?: InputMaybe<Array<ILessonsWithProgressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsWithProgressOrderBy>>;
  where?: InputMaybe<ILessonsWithProgressBoolExp>;
}


export interface ISubscriptionRootLessonsWithProgressAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonsWithProgressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsWithProgressOrderBy>>;
  where?: InputMaybe<ILessonsWithProgressBoolExp>;
}


export interface ISubscriptionRootLibraryDownloadsArgs {
  distinct_on?: InputMaybe<Array<ILibraryDownloadsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILibraryDownloadsOrderBy>>;
  where?: InputMaybe<ILibraryDownloadsBoolExp>;
}


export interface ISubscriptionRootLibraryDownloadsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILibraryDownloadsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILibraryDownloadsOrderBy>>;
  where?: InputMaybe<ILibraryDownloadsBoolExp>;
}


export interface ISubscriptionRootLibraryDownloadsAttachmentsArgs {
  distinct_on?: InputMaybe<Array<ILibraryDownloadsAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILibraryDownloadsAttachmentsOrderBy>>;
  where?: InputMaybe<ILibraryDownloadsAttachmentsBoolExp>;
}


export interface ISubscriptionRootLibraryDownloadsAttachmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILibraryDownloadsAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILibraryDownloadsAttachmentsOrderBy>>;
  where?: InputMaybe<ILibraryDownloadsAttachmentsBoolExp>;
}


export interface ISubscriptionRootLibraryDownloadsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootMessageTypesArgs {
  distinct_on?: InputMaybe<Array<IMessageTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IMessageTypesOrderBy>>;
  where?: InputMaybe<IMessageTypesBoolExp>;
}


export interface ISubscriptionRootMessageTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<IMessageTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IMessageTypesOrderBy>>;
  where?: InputMaybe<IMessageTypesBoolExp>;
}


export interface ISubscriptionRootMessageTypesByPkArgs {
  name: Scalars['String'];
}


export interface ISubscriptionRootMessagesArgs {
  distinct_on?: InputMaybe<Array<IMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IMessagesOrderBy>>;
  where?: InputMaybe<IMessagesBoolExp>;
}


export interface ISubscriptionRootMessagesAggregateArgs {
  distinct_on?: InputMaybe<Array<IMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IMessagesOrderBy>>;
  where?: InputMaybe<IMessagesBoolExp>;
}


export interface ISubscriptionRootMessagesByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootMissedLessonsViewArgs {
  distinct_on?: InputMaybe<Array<IMissedLessonsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IMissedLessonsViewOrderBy>>;
  where?: InputMaybe<IMissedLessonsViewBoolExp>;
}


export interface ISubscriptionRootMissedLessonsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IMissedLessonsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IMissedLessonsViewOrderBy>>;
  where?: InputMaybe<IMissedLessonsViewBoolExp>;
}


export interface ISubscriptionRootMissedQaViewArgs {
  distinct_on?: InputMaybe<Array<IMissedQaViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IMissedQaViewOrderBy>>;
  where?: InputMaybe<IMissedQaViewBoolExp>;
}


export interface ISubscriptionRootMissedQaViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IMissedQaViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IMissedQaViewOrderBy>>;
  where?: InputMaybe<IMissedQaViewBoolExp>;
}


export interface ISubscriptionRootMissedSecondPartViewArgs {
  distinct_on?: InputMaybe<Array<IMissedSecondPartViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IMissedSecondPartViewOrderBy>>;
  where?: InputMaybe<IMissedSecondPartViewBoolExp>;
}


export interface ISubscriptionRootMissedSecondPartViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IMissedSecondPartViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IMissedSecondPartViewOrderBy>>;
  where?: InputMaybe<IMissedSecondPartViewBoolExp>;
}


export interface ISubscriptionRootMissedThirdPartViewArgs {
  distinct_on?: InputMaybe<Array<IMissedThirdPartViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IMissedThirdPartViewOrderBy>>;
  where?: InputMaybe<IMissedThirdPartViewBoolExp>;
}


export interface ISubscriptionRootMissedThirdPartViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IMissedThirdPartViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IMissedThirdPartViewOrderBy>>;
  where?: InputMaybe<IMissedThirdPartViewBoolExp>;
}


export interface ISubscriptionRootNewsArticlesArgs {
  distinct_on?: InputMaybe<Array<INewsArticlesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INewsArticlesOrderBy>>;
  where?: InputMaybe<INewsArticlesBoolExp>;
}


export interface ISubscriptionRootNewsArticlesAggregateArgs {
  distinct_on?: InputMaybe<Array<INewsArticlesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INewsArticlesOrderBy>>;
  where?: InputMaybe<INewsArticlesBoolExp>;
}


export interface ISubscriptionRootNewsArticlesAttachmentsArgs {
  distinct_on?: InputMaybe<Array<INewsArticlesAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INewsArticlesAttachmentsOrderBy>>;
  where?: InputMaybe<INewsArticlesAttachmentsBoolExp>;
}


export interface ISubscriptionRootNewsArticlesAttachmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<INewsArticlesAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INewsArticlesAttachmentsOrderBy>>;
  where?: InputMaybe<INewsArticlesAttachmentsBoolExp>;
}


export interface ISubscriptionRootNewsArticlesByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootNewsArticlesViewArgs {
  distinct_on?: InputMaybe<Array<INewsArticlesViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INewsArticlesViewOrderBy>>;
  where?: InputMaybe<INewsArticlesViewBoolExp>;
}


export interface ISubscriptionRootNewsArticlesViewAggregateArgs {
  distinct_on?: InputMaybe<Array<INewsArticlesViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INewsArticlesViewOrderBy>>;
  where?: InputMaybe<INewsArticlesViewBoolExp>;
}


export interface ISubscriptionRootNotificationGroupTypesArgs {
  distinct_on?: InputMaybe<Array<INotificationGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INotificationGroupTypesOrderBy>>;
  where?: InputMaybe<INotificationGroupTypesBoolExp>;
}


export interface ISubscriptionRootNotificationGroupTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<INotificationGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INotificationGroupTypesOrderBy>>;
  where?: InputMaybe<INotificationGroupTypesBoolExp>;
}


export interface ISubscriptionRootNotificationGroupTypesByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootNotificationsArgs {
  distinct_on?: InputMaybe<Array<INotificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INotificationsOrderBy>>;
  where?: InputMaybe<INotificationsBoolExp>;
}


export interface ISubscriptionRootNotificationsAggregateArgs {
  distinct_on?: InputMaybe<Array<INotificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<INotificationsOrderBy>>;
  where?: InputMaybe<INotificationsBoolExp>;
}


export interface ISubscriptionRootNotificationsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootPersonalDevelopmentsArgs {
  distinct_on?: InputMaybe<Array<IPersonalDevelopmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IPersonalDevelopmentsOrderBy>>;
  where?: InputMaybe<IPersonalDevelopmentsBoolExp>;
}


export interface ISubscriptionRootPersonalDevelopmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<IPersonalDevelopmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IPersonalDevelopmentsOrderBy>>;
  where?: InputMaybe<IPersonalDevelopmentsBoolExp>;
}


export interface ISubscriptionRootPersonalDevelopmentsAttachmentsArgs {
  distinct_on?: InputMaybe<Array<IPersonalDevelopmentsAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IPersonalDevelopmentsAttachmentsOrderBy>>;
  where?: InputMaybe<IPersonalDevelopmentsAttachmentsBoolExp>;
}


export interface ISubscriptionRootPersonalDevelopmentsAttachmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<IPersonalDevelopmentsAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IPersonalDevelopmentsAttachmentsOrderBy>>;
  where?: InputMaybe<IPersonalDevelopmentsAttachmentsBoolExp>;
}


export interface ISubscriptionRootPersonalDevelopmentsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootQaVideosArgs {
  distinct_on?: InputMaybe<Array<IQaVideosSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IQaVideosOrderBy>>;
  where?: InputMaybe<IQaVideosBoolExp>;
}


export interface ISubscriptionRootQaVideosAggregateArgs {
  distinct_on?: InputMaybe<Array<IQaVideosSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IQaVideosOrderBy>>;
  where?: InputMaybe<IQaVideosBoolExp>;
}


export interface ISubscriptionRootQaVideosByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootQueJobsArgs {
  distinct_on?: InputMaybe<Array<IQueJobsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IQueJobsOrderBy>>;
  where?: InputMaybe<IQueJobsBoolExp>;
}


export interface ISubscriptionRootQueJobsAggregateArgs {
  distinct_on?: InputMaybe<Array<IQueJobsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IQueJobsOrderBy>>;
  where?: InputMaybe<IQueJobsBoolExp>;
}


export interface ISubscriptionRootQueJobsByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootQuestionsArgs {
  distinct_on?: InputMaybe<Array<IQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IQuestionsOrderBy>>;
  where?: InputMaybe<IQuestionsBoolExp>;
}


export interface ISubscriptionRootQuestionsAggregateArgs {
  distinct_on?: InputMaybe<Array<IQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IQuestionsOrderBy>>;
  where?: InputMaybe<IQuestionsBoolExp>;
}


export interface ISubscriptionRootQuestionsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootReactionsGroupedByAllPartsArgs {
  distinct_on?: InputMaybe<Array<IReactionsGroupedByAllPartsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IReactionsGroupedByAllPartsOrderBy>>;
  where?: InputMaybe<IReactionsGroupedByAllPartsBoolExp>;
}


export interface ISubscriptionRootReactionsGroupedByAllPartsAggregateArgs {
  distinct_on?: InputMaybe<Array<IReactionsGroupedByAllPartsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IReactionsGroupedByAllPartsOrderBy>>;
  where?: InputMaybe<IReactionsGroupedByAllPartsBoolExp>;
}


export interface ISubscriptionRootReactivationSubscriptionsArgs {
  distinct_on?: InputMaybe<Array<IReactivationSubscriptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IReactivationSubscriptionsOrderBy>>;
  where?: InputMaybe<IReactivationSubscriptionsBoolExp>;
}


export interface ISubscriptionRootReactivationSubscriptionsAggregateArgs {
  distinct_on?: InputMaybe<Array<IReactivationSubscriptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IReactivationSubscriptionsOrderBy>>;
  where?: InputMaybe<IReactivationSubscriptionsBoolExp>;
}


export interface ISubscriptionRootRecitationsArgs {
  distinct_on?: InputMaybe<Array<IRecitationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRecitationsOrderBy>>;
  where?: InputMaybe<IRecitationsBoolExp>;
}


export interface ISubscriptionRootRecitationsAggregateArgs {
  distinct_on?: InputMaybe<Array<IRecitationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRecitationsOrderBy>>;
  where?: InputMaybe<IRecitationsBoolExp>;
}


export interface ISubscriptionRootRecitationsAttachmentsArgs {
  distinct_on?: InputMaybe<Array<IRecitationsAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRecitationsAttachmentsOrderBy>>;
  where?: InputMaybe<IRecitationsAttachmentsBoolExp>;
}


export interface ISubscriptionRootRecitationsAttachmentsAggregateArgs {
  distinct_on?: InputMaybe<Array<IRecitationsAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRecitationsAttachmentsOrderBy>>;
  where?: InputMaybe<IRecitationsAttachmentsBoolExp>;
}


export interface ISubscriptionRootRecitationsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootRepeatLessonProgressesArgs {
  distinct_on?: InputMaybe<Array<IRepeatLessonProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRepeatLessonProgressesOrderBy>>;
  where?: InputMaybe<IRepeatLessonProgressesBoolExp>;
}


export interface ISubscriptionRootRepeatLessonProgressesAggregateArgs {
  distinct_on?: InputMaybe<Array<IRepeatLessonProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRepeatLessonProgressesOrderBy>>;
  where?: InputMaybe<IRepeatLessonProgressesBoolExp>;
}


export interface ISubscriptionRootRepeatLessonProgressesByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootRepeatLessonsViewArgs {
  distinct_on?: InputMaybe<Array<IRepeatLessonsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRepeatLessonsViewOrderBy>>;
  where?: InputMaybe<IRepeatLessonsViewBoolExp>;
}


export interface ISubscriptionRootRepeatLessonsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IRepeatLessonsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRepeatLessonsViewOrderBy>>;
  where?: InputMaybe<IRepeatLessonsViewBoolExp>;
}


export interface ISubscriptionRootRepeatSecondPartsProgressesArgs {
  distinct_on?: InputMaybe<Array<IRepeatSecondPartsProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRepeatSecondPartsProgressesOrderBy>>;
  where?: InputMaybe<IRepeatSecondPartsProgressesBoolExp>;
}


export interface ISubscriptionRootRepeatSecondPartsProgressesAggregateArgs {
  distinct_on?: InputMaybe<Array<IRepeatSecondPartsProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRepeatSecondPartsProgressesOrderBy>>;
  where?: InputMaybe<IRepeatSecondPartsProgressesBoolExp>;
}


export interface ISubscriptionRootRepeatSecondPartsProgressesByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootRepeatSecondPartsViewArgs {
  distinct_on?: InputMaybe<Array<IRepeatSecondPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRepeatSecondPartsViewOrderBy>>;
  where?: InputMaybe<IRepeatSecondPartsViewBoolExp>;
}


export interface ISubscriptionRootRepeatSecondPartsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IRepeatSecondPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRepeatSecondPartsViewOrderBy>>;
  where?: InputMaybe<IRepeatSecondPartsViewBoolExp>;
}


export interface ISubscriptionRootRepeatThirdPartsProgressesArgs {
  distinct_on?: InputMaybe<Array<IRepeatThirdPartsProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRepeatThirdPartsProgressesOrderBy>>;
  where?: InputMaybe<IRepeatThirdPartsProgressesBoolExp>;
}


export interface ISubscriptionRootRepeatThirdPartsProgressesAggregateArgs {
  distinct_on?: InputMaybe<Array<IRepeatThirdPartsProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRepeatThirdPartsProgressesOrderBy>>;
  where?: InputMaybe<IRepeatThirdPartsProgressesBoolExp>;
}


export interface ISubscriptionRootRepeatThirdPartsProgressesByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootRepeatThirdPartsViewArgs {
  distinct_on?: InputMaybe<Array<IRepeatThirdPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRepeatThirdPartsViewOrderBy>>;
  where?: InputMaybe<IRepeatThirdPartsViewBoolExp>;
}


export interface ISubscriptionRootRepeatThirdPartsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IRepeatThirdPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IRepeatThirdPartsViewOrderBy>>;
  where?: InputMaybe<IRepeatThirdPartsViewBoolExp>;
}


export interface ISubscriptionRootSchemaMigrationsArgs {
  distinct_on?: InputMaybe<Array<ISchemaMigrationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISchemaMigrationsOrderBy>>;
  where?: InputMaybe<ISchemaMigrationsBoolExp>;
}


export interface ISubscriptionRootSchemaMigrationsAggregateArgs {
  distinct_on?: InputMaybe<Array<ISchemaMigrationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISchemaMigrationsOrderBy>>;
  where?: InputMaybe<ISchemaMigrationsBoolExp>;
}


export interface ISubscriptionRootSchemaMigrationsByPkArgs {
  version: Scalars['String'];
}


export interface ISubscriptionRootSecondLessonPartMailRemindersArgs {
  distinct_on?: InputMaybe<Array<ISecondLessonPartMailRemindersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISecondLessonPartMailRemindersOrderBy>>;
  where?: InputMaybe<ISecondLessonPartMailRemindersBoolExp>;
}


export interface ISubscriptionRootSecondLessonPartMailRemindersAggregateArgs {
  distinct_on?: InputMaybe<Array<ISecondLessonPartMailRemindersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISecondLessonPartMailRemindersOrderBy>>;
  where?: InputMaybe<ISecondLessonPartMailRemindersBoolExp>;
}


export interface ISubscriptionRootSemiStreamLessonsArgs {
  distinct_on?: InputMaybe<Array<ISemiStreamLessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISemiStreamLessonsOrderBy>>;
  where?: InputMaybe<ISemiStreamLessonsBoolExp>;
}


export interface ISubscriptionRootSemiStreamLessonsAggregateArgs {
  distinct_on?: InputMaybe<Array<ISemiStreamLessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISemiStreamLessonsOrderBy>>;
  where?: InputMaybe<ISemiStreamLessonsBoolExp>;
}


export interface ISubscriptionRootSemiStreamSecondPartsViewArgs {
  distinct_on?: InputMaybe<Array<ISemiStreamSecondPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISemiStreamSecondPartsViewOrderBy>>;
  where?: InputMaybe<ISemiStreamSecondPartsViewBoolExp>;
}


export interface ISubscriptionRootSemiStreamSecondPartsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<ISemiStreamSecondPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISemiStreamSecondPartsViewOrderBy>>;
  where?: InputMaybe<ISemiStreamSecondPartsViewBoolExp>;
}


export interface ISubscriptionRootSemiStreamThirdPartsViewArgs {
  distinct_on?: InputMaybe<Array<ISemiStreamThirdPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISemiStreamThirdPartsViewOrderBy>>;
  where?: InputMaybe<ISemiStreamThirdPartsViewBoolExp>;
}


export interface ISubscriptionRootSemiStreamThirdPartsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<ISemiStreamThirdPartsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISemiStreamThirdPartsViewOrderBy>>;
  where?: InputMaybe<ISemiStreamThirdPartsViewBoolExp>;
}


export interface ISubscriptionRootStarsViewArgs {
  distinct_on?: InputMaybe<Array<IStarsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IStarsViewOrderBy>>;
  where?: InputMaybe<IStarsViewBoolExp>;
}


export interface ISubscriptionRootStarsViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IStarsViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IStarsViewOrderBy>>;
  where?: InputMaybe<IStarsViewBoolExp>;
}


export interface ISubscriptionRootStudyRemindersArgs {
  distinct_on?: InputMaybe<Array<IStudyRemindersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IStudyRemindersOrderBy>>;
  where?: InputMaybe<IStudyRemindersBoolExp>;
}


export interface ISubscriptionRootStudyRemindersAggregateArgs {
  distinct_on?: InputMaybe<Array<IStudyRemindersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IStudyRemindersOrderBy>>;
  where?: InputMaybe<IStudyRemindersBoolExp>;
}


export interface ISubscriptionRootSubscriptionConfigsArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionConfigsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionConfigsOrderBy>>;
  where?: InputMaybe<ISubscriptionConfigsBoolExp>;
}


export interface ISubscriptionRootSubscriptionConfigsAggregateArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionConfigsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionConfigsOrderBy>>;
  where?: InputMaybe<ISubscriptionConfigsBoolExp>;
}


export interface ISubscriptionRootSubscriptionConfigsByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootSubscriptionEndRemindersArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionEndRemindersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionEndRemindersOrderBy>>;
  where?: InputMaybe<ISubscriptionEndRemindersBoolExp>;
}


export interface ISubscriptionRootSubscriptionEndRemindersAggregateArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionEndRemindersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionEndRemindersOrderBy>>;
  where?: InputMaybe<ISubscriptionEndRemindersBoolExp>;
}


export interface ISubscriptionRootSubscriptionMailRemindersArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionMailRemindersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionMailRemindersOrderBy>>;
  where?: InputMaybe<ISubscriptionMailRemindersBoolExp>;
}


export interface ISubscriptionRootSubscriptionMailRemindersAggregateArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionMailRemindersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionMailRemindersOrderBy>>;
  where?: InputMaybe<ISubscriptionMailRemindersBoolExp>;
}


export interface ISubscriptionRootSubscriptionsArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionsOrderBy>>;
  where?: InputMaybe<ISubscriptionsBoolExp>;
}


export interface ISubscriptionRootSubscriptionsAggregateArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionsOrderBy>>;
  where?: InputMaybe<ISubscriptionsBoolExp>;
}


export interface ISubscriptionRootSubscriptionsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootSubscriptionsByUserArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionsByUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionsByUserOrderBy>>;
  where?: InputMaybe<ISubscriptionsByUserBoolExp>;
}


export interface ISubscriptionRootSubscriptionsByUserAggregateArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionsByUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionsByUserOrderBy>>;
  where?: InputMaybe<ISubscriptionsByUserBoolExp>;
}


export interface ISubscriptionRootSupportTopicEntriesArgs {
  distinct_on?: InputMaybe<Array<ISupportTopicEntriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISupportTopicEntriesOrderBy>>;
  where?: InputMaybe<ISupportTopicEntriesBoolExp>;
}


export interface ISubscriptionRootSupportTopicEntriesAggregateArgs {
  distinct_on?: InputMaybe<Array<ISupportTopicEntriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISupportTopicEntriesOrderBy>>;
  where?: InputMaybe<ISupportTopicEntriesBoolExp>;
}


export interface ISubscriptionRootSupportTopicEntriesByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootSupportTopicsArgs {
  distinct_on?: InputMaybe<Array<ISupportTopicsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISupportTopicsOrderBy>>;
  where?: InputMaybe<ISupportTopicsBoolExp>;
}


export interface ISubscriptionRootSupportTopicsAggregateArgs {
  distinct_on?: InputMaybe<Array<ISupportTopicsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISupportTopicsOrderBy>>;
  where?: InputMaybe<ISupportTopicsBoolExp>;
}


export interface ISubscriptionRootSupportTopicsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootSuspensionsArgs {
  distinct_on?: InputMaybe<Array<ISuspensionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISuspensionsOrderBy>>;
  where?: InputMaybe<ISuspensionsBoolExp>;
}


export interface ISubscriptionRootSuspensionsAggregateArgs {
  distinct_on?: InputMaybe<Array<ISuspensionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISuspensionsOrderBy>>;
  where?: InputMaybe<ISuspensionsBoolExp>;
}


export interface ISubscriptionRootSuspensionsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootTmpLessonsArgs {
  distinct_on?: InputMaybe<Array<ITmpLessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ITmpLessonsOrderBy>>;
  where?: InputMaybe<ITmpLessonsBoolExp>;
}


export interface ISubscriptionRootTmpLessonsAggregateArgs {
  distinct_on?: InputMaybe<Array<ITmpLessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ITmpLessonsOrderBy>>;
  where?: InputMaybe<ITmpLessonsBoolExp>;
}


export interface ISubscriptionRootTmpLessonsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootTmpProgressArgs {
  distinct_on?: InputMaybe<Array<ITmpProgressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ITmpProgressOrderBy>>;
  where?: InputMaybe<ITmpProgressBoolExp>;
}


export interface ISubscriptionRootTmpProgressAggregateArgs {
  distinct_on?: InputMaybe<Array<ITmpProgressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ITmpProgressOrderBy>>;
  where?: InputMaybe<ITmpProgressBoolExp>;
}


export interface ISubscriptionRootTmpProgressByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootTmpStreamLessonsArgs {
  distinct_on?: InputMaybe<Array<ITmpStreamLessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ITmpStreamLessonsOrderBy>>;
  where?: InputMaybe<ITmpStreamLessonsBoolExp>;
}


export interface ISubscriptionRootTmpStreamLessonsAggregateArgs {
  distinct_on?: InputMaybe<Array<ITmpStreamLessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ITmpStreamLessonsOrderBy>>;
  where?: InputMaybe<ITmpStreamLessonsBoolExp>;
}


export interface ISubscriptionRootUniqueUserSessionsArgs {
  distinct_on?: InputMaybe<Array<IUniqueUserSessionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUniqueUserSessionsOrderBy>>;
  where?: InputMaybe<IUniqueUserSessionsBoolExp>;
}


export interface ISubscriptionRootUniqueUserSessionsAggregateArgs {
  distinct_on?: InputMaybe<Array<IUniqueUserSessionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUniqueUserSessionsOrderBy>>;
  where?: InputMaybe<IUniqueUserSessionsBoolExp>;
}


export interface ISubscriptionRootUnlockablesArgs {
  distinct_on?: InputMaybe<Array<IUnlockablesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUnlockablesOrderBy>>;
  where?: InputMaybe<IUnlockablesBoolExp>;
}


export interface ISubscriptionRootUnlockablesAggregateArgs {
  distinct_on?: InputMaybe<Array<IUnlockablesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUnlockablesOrderBy>>;
  where?: InputMaybe<IUnlockablesBoolExp>;
}


export interface ISubscriptionRootUnlockablesByPkArgs {
  type: Scalars['String'];
}


export interface ISubscriptionRootUserChildrenUnlockablesArgs {
  distinct_on?: InputMaybe<Array<IUserChildrenUnlockablesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserChildrenUnlockablesOrderBy>>;
  where?: InputMaybe<IUserChildrenUnlockablesBoolExp>;
}


export interface ISubscriptionRootUserChildrenUnlockablesAggregateArgs {
  distinct_on?: InputMaybe<Array<IUserChildrenUnlockablesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserChildrenUnlockablesOrderBy>>;
  where?: InputMaybe<IUserChildrenUnlockablesBoolExp>;
}


export interface ISubscriptionRootUserChildrenUnlockablesByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootUserCourseUsageArgs {
  distinct_on?: InputMaybe<Array<IUserCourseUsageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserCourseUsageOrderBy>>;
  where?: InputMaybe<IUserCourseUsageBoolExp>;
}


export interface ISubscriptionRootUserCourseUsageAggregateArgs {
  distinct_on?: InputMaybe<Array<IUserCourseUsageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserCourseUsageOrderBy>>;
  where?: InputMaybe<IUserCourseUsageBoolExp>;
}


export interface ISubscriptionRootUserLessonMessagesViewArgs {
  distinct_on?: InputMaybe<Array<IUserLessonMessagesViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserLessonMessagesViewOrderBy>>;
  where?: InputMaybe<IUserLessonMessagesViewBoolExp>;
}


export interface ISubscriptionRootUserLessonMessagesViewAggregateArgs {
  distinct_on?: InputMaybe<Array<IUserLessonMessagesViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserLessonMessagesViewOrderBy>>;
  where?: InputMaybe<IUserLessonMessagesViewBoolExp>;
}


export interface ISubscriptionRootUserLessonPartsProgressesArgs {
  distinct_on?: InputMaybe<Array<IUserLessonPartsProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserLessonPartsProgressesOrderBy>>;
  where?: InputMaybe<IUserLessonPartsProgressesBoolExp>;
}


export interface ISubscriptionRootUserLessonPartsProgressesAggregateArgs {
  distinct_on?: InputMaybe<Array<IUserLessonPartsProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserLessonPartsProgressesOrderBy>>;
  where?: InputMaybe<IUserLessonPartsProgressesBoolExp>;
}


export interface ISubscriptionRootUserLessonPartsProgressesByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootUserLessonProgressesArgs {
  distinct_on?: InputMaybe<Array<IUserLessonProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserLessonProgressesOrderBy>>;
  where?: InputMaybe<IUserLessonProgressesBoolExp>;
}


export interface ISubscriptionRootUserLessonProgressesAggregateArgs {
  distinct_on?: InputMaybe<Array<IUserLessonProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserLessonProgressesOrderBy>>;
  where?: InputMaybe<IUserLessonProgressesBoolExp>;
}


export interface ISubscriptionRootUserLessonProgressesByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootUserQaProgressesArgs {
  distinct_on?: InputMaybe<Array<IUserQaProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserQaProgressesOrderBy>>;
  where?: InputMaybe<IUserQaProgressesBoolExp>;
}


export interface ISubscriptionRootUserQaProgressesAggregateArgs {
  distinct_on?: InputMaybe<Array<IUserQaProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserQaProgressesOrderBy>>;
  where?: InputMaybe<IUserQaProgressesBoolExp>;
}


export interface ISubscriptionRootUserQaProgressesByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootUserThirdPartsProgressesArgs {
  distinct_on?: InputMaybe<Array<IUserThirdPartsProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserThirdPartsProgressesOrderBy>>;
  where?: InputMaybe<IUserThirdPartsProgressesBoolExp>;
}


export interface ISubscriptionRootUserThirdPartsProgressesAggregateArgs {
  distinct_on?: InputMaybe<Array<IUserThirdPartsProgressesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserThirdPartsProgressesOrderBy>>;
  where?: InputMaybe<IUserThirdPartsProgressesBoolExp>;
}


export interface ISubscriptionRootUserThirdPartsProgressesByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootUsersArgs {
  distinct_on?: InputMaybe<Array<IUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUsersOrderBy>>;
  where?: InputMaybe<IUsersBoolExp>;
}


export interface ISubscriptionRootUsersAggregateArgs {
  distinct_on?: InputMaybe<Array<IUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUsersOrderBy>>;
  where?: InputMaybe<IUsersBoolExp>;
}


export interface ISubscriptionRootUsersByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootUsersChildDetailsArgs {
  distinct_on?: InputMaybe<Array<IUsersChildDetailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUsersChildDetailsOrderBy>>;
  where?: InputMaybe<IUsersChildDetailsBoolExp>;
}


export interface ISubscriptionRootUsersChildDetailsAggregateArgs {
  distinct_on?: InputMaybe<Array<IUsersChildDetailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUsersChildDetailsOrderBy>>;
  where?: InputMaybe<IUsersChildDetailsBoolExp>;
}


export interface ISubscriptionRootUsersChildDetailsByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootUsersChildrenArgs {
  distinct_on?: InputMaybe<Array<IUsersChildrenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUsersChildrenOrderBy>>;
  where?: InputMaybe<IUsersChildrenBoolExp>;
}


export interface ISubscriptionRootUsersChildrenAggregateArgs {
  distinct_on?: InputMaybe<Array<IUsersChildrenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUsersChildrenOrderBy>>;
  where?: InputMaybe<IUsersChildrenBoolExp>;
}


export interface ISubscriptionRootUsersChildrenByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootUsersSessionsArgs {
  distinct_on?: InputMaybe<Array<IUsersSessionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUsersSessionsOrderBy>>;
  where?: InputMaybe<IUsersSessionsBoolExp>;
}


export interface ISubscriptionRootUsersSessionsAggregateArgs {
  distinct_on?: InputMaybe<Array<IUsersSessionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUsersSessionsOrderBy>>;
  where?: InputMaybe<IUsersSessionsBoolExp>;
}


export interface ISubscriptionRootUsersSessionsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootVacationGroupTypesArgs {
  distinct_on?: InputMaybe<Array<IVacationGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IVacationGroupTypesOrderBy>>;
  where?: InputMaybe<IVacationGroupTypesBoolExp>;
}


export interface ISubscriptionRootVacationGroupTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<IVacationGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IVacationGroupTypesOrderBy>>;
  where?: InputMaybe<IVacationGroupTypesBoolExp>;
}


export interface ISubscriptionRootVacationGroupTypesByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootVacationsArgs {
  distinct_on?: InputMaybe<Array<IVacationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IVacationsOrderBy>>;
  where?: InputMaybe<IVacationsBoolExp>;
}


export interface ISubscriptionRootVacationsAggregateArgs {
  distinct_on?: InputMaybe<Array<IVacationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IVacationsOrderBy>>;
  where?: InputMaybe<IVacationsBoolExp>;
}


export interface ISubscriptionRootVacationsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootWeeklyFactsArgs {
  distinct_on?: InputMaybe<Array<IWeeklyFactsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IWeeklyFactsOrderBy>>;
  where?: InputMaybe<IWeeklyFactsBoolExp>;
}


export interface ISubscriptionRootWeeklyFactsAggregateArgs {
  distinct_on?: InputMaybe<Array<IWeeklyFactsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IWeeklyFactsOrderBy>>;
  where?: InputMaybe<IWeeklyFactsBoolExp>;
}


export interface ISubscriptionRootWeeklyFactsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootWpOrdersArgs {
  distinct_on?: InputMaybe<Array<IWpOrdersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IWpOrdersOrderBy>>;
  where?: InputMaybe<IWpOrdersBoolExp>;
}


export interface ISubscriptionRootWpOrdersAggregateArgs {
  distinct_on?: InputMaybe<Array<IWpOrdersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IWpOrdersOrderBy>>;
  where?: InputMaybe<IWpOrdersBoolExp>;
}


export interface ISubscriptionRootWpOrdersByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootWpProductsArgs {
  distinct_on?: InputMaybe<Array<IWpProductsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IWpProductsOrderBy>>;
  where?: InputMaybe<IWpProductsBoolExp>;
}


export interface ISubscriptionRootWpProductsAggregateArgs {
  distinct_on?: InputMaybe<Array<IWpProductsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IWpProductsOrderBy>>;
  where?: InputMaybe<IWpProductsBoolExp>;
}


export interface ISubscriptionRootWpProductsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootWpSubscriptionOrdersArgs {
  distinct_on?: InputMaybe<Array<IWpSubscriptionOrdersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IWpSubscriptionOrdersOrderBy>>;
  where?: InputMaybe<IWpSubscriptionOrdersBoolExp>;
}


export interface ISubscriptionRootWpSubscriptionOrdersAggregateArgs {
  distinct_on?: InputMaybe<Array<IWpSubscriptionOrdersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IWpSubscriptionOrdersOrderBy>>;
  where?: InputMaybe<IWpSubscriptionOrdersBoolExp>;
}


export interface ISubscriptionRootWpSubscriptionOrdersByPkArgs {
  id: Scalars['Int'];
}

/** columns and relationships of "subscriptions" */
export interface ISubscriptions {
  __typename?: 'subscriptions';
  /** An object relationship */
  course?: Maybe<ICourses>;
  /** An object relationship */
  course_group?: Maybe<ICourseGroups>;
  course_group_id?: Maybe<Scalars['uuid']>;
  course_id: Scalars['uuid'];
  created_at?: Maybe<Scalars['timestamptz']>;
  credits: Scalars['numeric'];
  ends_on?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  meta_data?: Maybe<Scalars['jsonb']>;
  starts_on?: Maybe<Scalars['date']>;
  /** An object relationship */
  subscription_config?: Maybe<ISubscriptionConfigs>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id: Scalars['uuid'];
  /** An array relationship */
  users_children: Array<IUsersChildren>;
  /** An aggregate relationship */
  users_children_aggregate: IUsersChildrenAggregate;
  /** An object relationship */
  wp_order?: Maybe<IWpOrders>;
  wp_order_id?: Maybe<Scalars['uuid']>;
  wp_subscription_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  wp_subscription_orders: Array<IWpSubscriptionOrders>;
  /** An aggregate relationship */
  wp_subscription_orders_aggregate: IWpSubscriptionOrdersAggregate;
}


/** columns and relationships of "subscriptions" */
export interface ISubscriptionsMetaDataArgs {
  path?: InputMaybe<Scalars['String']>;
}


/** columns and relationships of "subscriptions" */
export interface ISubscriptionsUsersChildrenArgs {
  distinct_on?: InputMaybe<Array<IUsersChildrenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUsersChildrenOrderBy>>;
  where?: InputMaybe<IUsersChildrenBoolExp>;
}


/** columns and relationships of "subscriptions" */
export interface ISubscriptionsUsersChildrenAggregateArgs {
  distinct_on?: InputMaybe<Array<IUsersChildrenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUsersChildrenOrderBy>>;
  where?: InputMaybe<IUsersChildrenBoolExp>;
}


/** columns and relationships of "subscriptions" */
export interface ISubscriptionsWpSubscriptionOrdersArgs {
  distinct_on?: InputMaybe<Array<IWpSubscriptionOrdersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IWpSubscriptionOrdersOrderBy>>;
  where?: InputMaybe<IWpSubscriptionOrdersBoolExp>;
}


/** columns and relationships of "subscriptions" */
export interface ISubscriptionsWpSubscriptionOrdersAggregateArgs {
  distinct_on?: InputMaybe<Array<IWpSubscriptionOrdersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IWpSubscriptionOrdersOrderBy>>;
  where?: InputMaybe<IWpSubscriptionOrdersBoolExp>;
}

/** aggregated selection of "subscriptions" */
export interface ISubscriptionsAggregate {
  __typename?: 'subscriptions_aggregate';
  aggregate?: Maybe<ISubscriptionsAggregateFields>;
  nodes: Array<ISubscriptions>;
}

/** aggregate fields of "subscriptions" */
export interface ISubscriptionsAggregateFields {
  __typename?: 'subscriptions_aggregate_fields';
  avg?: Maybe<ISubscriptionsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ISubscriptionsMaxFields>;
  min?: Maybe<ISubscriptionsMinFields>;
  stddev?: Maybe<ISubscriptionsStddevFields>;
  stddev_pop?: Maybe<ISubscriptionsStddevPopFields>;
  stddev_samp?: Maybe<ISubscriptionsStddevSampFields>;
  sum?: Maybe<ISubscriptionsSumFields>;
  var_pop?: Maybe<ISubscriptionsVarPopFields>;
  var_samp?: Maybe<ISubscriptionsVarSampFields>;
  variance?: Maybe<ISubscriptionsVarianceFields>;
}


/** aggregate fields of "subscriptions" */
export interface ISubscriptionsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ISubscriptionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "subscriptions" */
export interface ISubscriptionsAggregateOrderBy {
  avg?: InputMaybe<ISubscriptionsAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<ISubscriptionsMaxOrderBy>;
  min?: InputMaybe<ISubscriptionsMinOrderBy>;
  stddev?: InputMaybe<ISubscriptionsStddevOrderBy>;
  stddev_pop?: InputMaybe<ISubscriptionsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ISubscriptionsStddevSampOrderBy>;
  sum?: InputMaybe<ISubscriptionsSumOrderBy>;
  var_pop?: InputMaybe<ISubscriptionsVarPopOrderBy>;
  var_samp?: InputMaybe<ISubscriptionsVarSampOrderBy>;
  variance?: InputMaybe<ISubscriptionsVarianceOrderBy>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface ISubscriptionsAppendInput {
  meta_data?: InputMaybe<Scalars['jsonb']>;
}

/** input type for inserting array relation for remote table "subscriptions" */
export interface ISubscriptionsArrRelInsertInput {
  data: Array<ISubscriptionsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ISubscriptionsOnConflict>;
}

/** aggregate avg on columns */
export interface ISubscriptionsAvgFields {
  __typename?: 'subscriptions_avg_fields';
  credits?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "subscriptions" */
export interface ISubscriptionsAvgOrderBy {
  credits?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "subscriptions". All fields are combined with a logical 'AND'. */
export interface ISubscriptionsBoolExp {
  _and?: InputMaybe<Array<ISubscriptionsBoolExp>>;
  _not?: InputMaybe<ISubscriptionsBoolExp>;
  _or?: InputMaybe<Array<ISubscriptionsBoolExp>>;
  course?: InputMaybe<ICoursesBoolExp>;
  course_group?: InputMaybe<ICourseGroupsBoolExp>;
  course_group_id?: InputMaybe<IUuidComparisonExp>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  credits?: InputMaybe<INumericComparisonExp>;
  ends_on?: InputMaybe<IDateComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  meta_data?: InputMaybe<IJsonbComparisonExp>;
  starts_on?: InputMaybe<IDateComparisonExp>;
  subscription_config?: InputMaybe<ISubscriptionConfigsBoolExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
  users_children?: InputMaybe<IUsersChildrenBoolExp>;
  wp_order?: InputMaybe<IWpOrdersBoolExp>;
  wp_order_id?: InputMaybe<IUuidComparisonExp>;
  wp_subscription_id?: InputMaybe<IIntComparisonExp>;
  wp_subscription_orders?: InputMaybe<IWpSubscriptionOrdersBoolExp>;
}

/** columns and relationships of "subscriptions_by_user" */
export interface ISubscriptionsByUser {
  __typename?: 'subscriptions_by_user';
  course_id?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  other_auth0id?: Maybe<Scalars['String']>;
  subscribed_auth0id?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "subscriptions_by_user" */
export interface ISubscriptionsByUserAggregate {
  __typename?: 'subscriptions_by_user_aggregate';
  aggregate?: Maybe<ISubscriptionsByUserAggregateFields>;
  nodes: Array<ISubscriptionsByUser>;
}

/** aggregate fields of "subscriptions_by_user" */
export interface ISubscriptionsByUserAggregateFields {
  __typename?: 'subscriptions_by_user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ISubscriptionsByUserMaxFields>;
  min?: Maybe<ISubscriptionsByUserMinFields>;
}


/** aggregate fields of "subscriptions_by_user" */
export interface ISubscriptionsByUserAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ISubscriptionsByUserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "subscriptions_by_user". All fields are combined with a logical 'AND'. */
export interface ISubscriptionsByUserBoolExp {
  _and?: InputMaybe<Array<ISubscriptionsByUserBoolExp>>;
  _not?: InputMaybe<ISubscriptionsByUserBoolExp>;
  _or?: InputMaybe<Array<ISubscriptionsByUserBoolExp>>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  email?: InputMaybe<IStringComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  other_auth0id?: InputMaybe<IStringComparisonExp>;
  subscribed_auth0id?: InputMaybe<IStringComparisonExp>;
  subscription_id?: InputMaybe<IUuidComparisonExp>;
}

/** input type for inserting data into table "subscriptions_by_user" */
export interface ISubscriptionsByUserInsertInput {
  course_id?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  other_auth0id?: InputMaybe<Scalars['String']>;
  subscribed_auth0id?: InputMaybe<Scalars['String']>;
  subscription_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface ISubscriptionsByUserMaxFields {
  __typename?: 'subscriptions_by_user_max_fields';
  course_id?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  other_auth0id?: Maybe<Scalars['String']>;
  subscribed_auth0id?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface ISubscriptionsByUserMinFields {
  __typename?: 'subscriptions_by_user_min_fields';
  course_id?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  other_auth0id?: Maybe<Scalars['String']>;
  subscribed_auth0id?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['uuid']>;
}

/** input type for inserting object relation for remote table "subscriptions_by_user" */
export interface ISubscriptionsByUserObjRelInsertInput {
  data: ISubscriptionsByUserInsertInput;
}

/** Ordering options when selecting data from "subscriptions_by_user". */
export interface ISubscriptionsByUserOrderBy {
  course_id?: InputMaybe<IOrderBy>;
  email?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  other_auth0id?: InputMaybe<IOrderBy>;
  subscribed_auth0id?: InputMaybe<IOrderBy>;
  subscription_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "subscriptions_by_user" */
export enum ISubscriptionsByUserSelectColumn {
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  EMAIL = 'email',
  /** column name */
  NAME = 'name',
  /** column name */
  OTHER_AUTH0ID = 'other_auth0id',
  /** column name */
  SUBSCRIBED_AUTH0ID = 'subscribed_auth0id',
  /** column name */
  SUBSCRIPTION_ID = 'subscription_id'
}

/** unique or primary key constraints on table "subscriptions" */
export enum ISubscriptionsConstraint {
  /** unique or primary key constraint */
  SUBSCRIPTION_PKEY = 'subscription_pkey',
  /** unique or primary key constraint */
  SUBSCRIPTIONS_USER_ID_WP_ORDER_ID_KEY = 'subscriptions_user_id_wp_order_id_key',
  /** unique or primary key constraint */
  SUBSCRIPTIONS_WP_ORDER_ID_KEY = 'subscriptions_wp_order_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface ISubscriptionsDeleteAtPathInput {
  meta_data?: InputMaybe<Array<Scalars['String']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface ISubscriptionsDeleteElemInput {
  meta_data?: InputMaybe<Scalars['Int']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface ISubscriptionsDeleteKeyInput {
  meta_data?: InputMaybe<Scalars['String']>;
}

/** input type for incrementing numeric columns in table "subscriptions" */
export interface ISubscriptionsIncInput {
  credits?: InputMaybe<Scalars['numeric']>;
  wp_subscription_id?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "subscriptions" */
export interface ISubscriptionsInsertInput {
  course?: InputMaybe<ICoursesObjRelInsertInput>;
  course_group?: InputMaybe<ICourseGroupsObjRelInsertInput>;
  course_group_id?: InputMaybe<Scalars['uuid']>;
  course_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  credits?: InputMaybe<Scalars['numeric']>;
  ends_on?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  meta_data?: InputMaybe<Scalars['jsonb']>;
  starts_on?: InputMaybe<Scalars['date']>;
  subscription_config?: InputMaybe<ISubscriptionConfigsObjRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<IUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']>;
  users_children?: InputMaybe<IUsersChildrenArrRelInsertInput>;
  wp_order?: InputMaybe<IWpOrdersObjRelInsertInput>;
  wp_order_id?: InputMaybe<Scalars['uuid']>;
  wp_subscription_id?: InputMaybe<Scalars['Int']>;
  wp_subscription_orders?: InputMaybe<IWpSubscriptionOrdersArrRelInsertInput>;
}

/** aggregate max on columns */
export interface ISubscriptionsMaxFields {
  __typename?: 'subscriptions_max_fields';
  course_group_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  credits?: Maybe<Scalars['numeric']>;
  ends_on?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  starts_on?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  wp_order_id?: Maybe<Scalars['uuid']>;
  wp_subscription_id?: Maybe<Scalars['Int']>;
}

/** order by max() on columns of table "subscriptions" */
export interface ISubscriptionsMaxOrderBy {
  course_group_id?: InputMaybe<IOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  credits?: InputMaybe<IOrderBy>;
  ends_on?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  starts_on?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  wp_order_id?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface ISubscriptionsMinFields {
  __typename?: 'subscriptions_min_fields';
  course_group_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  credits?: Maybe<Scalars['numeric']>;
  ends_on?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  starts_on?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  wp_order_id?: Maybe<Scalars['uuid']>;
  wp_subscription_id?: Maybe<Scalars['Int']>;
}

/** order by min() on columns of table "subscriptions" */
export interface ISubscriptionsMinOrderBy {
  course_group_id?: InputMaybe<IOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  credits?: InputMaybe<IOrderBy>;
  ends_on?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  starts_on?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  wp_order_id?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "subscriptions" */
export interface ISubscriptionsMutationResponse {
  __typename?: 'subscriptions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ISubscriptions>;
}

/** input type for inserting object relation for remote table "subscriptions" */
export interface ISubscriptionsObjRelInsertInput {
  data: ISubscriptionsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ISubscriptionsOnConflict>;
}

/** on_conflict condition type for table "subscriptions" */
export interface ISubscriptionsOnConflict {
  constraint: ISubscriptionsConstraint;
  update_columns?: Array<ISubscriptionsUpdateColumn>;
  where?: InputMaybe<ISubscriptionsBoolExp>;
}

/** Ordering options when selecting data from "subscriptions". */
export interface ISubscriptionsOrderBy {
  course?: InputMaybe<ICoursesOrderBy>;
  course_group?: InputMaybe<ICourseGroupsOrderBy>;
  course_group_id?: InputMaybe<IOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  credits?: InputMaybe<IOrderBy>;
  ends_on?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  meta_data?: InputMaybe<IOrderBy>;
  starts_on?: InputMaybe<IOrderBy>;
  subscription_config?: InputMaybe<ISubscriptionConfigsOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  users_children_aggregate?: InputMaybe<IUsersChildrenAggregateOrderBy>;
  wp_order?: InputMaybe<IWpOrdersOrderBy>;
  wp_order_id?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
  wp_subscription_orders_aggregate?: InputMaybe<IWpSubscriptionOrdersAggregateOrderBy>;
}

/** primary key columns input for table: subscriptions */
export interface ISubscriptionsPkColumnsInput {
  id: Scalars['uuid'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface ISubscriptionsPrependInput {
  meta_data?: InputMaybe<Scalars['jsonb']>;
}

/** select columns of table "subscriptions" */
export enum ISubscriptionsSelectColumn {
  /** column name */
  COURSE_GROUP_ID = 'course_group_id',
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  CREDITS = 'credits',
  /** column name */
  ENDS_ON = 'ends_on',
  /** column name */
  ID = 'id',
  /** column name */
  META_DATA = 'meta_data',
  /** column name */
  STARTS_ON = 'starts_on',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  WP_ORDER_ID = 'wp_order_id',
  /** column name */
  WP_SUBSCRIPTION_ID = 'wp_subscription_id'
}

/** input type for updating data in table "subscriptions" */
export interface ISubscriptionsSetInput {
  course_group_id?: InputMaybe<Scalars['uuid']>;
  course_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  credits?: InputMaybe<Scalars['numeric']>;
  ends_on?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  meta_data?: InputMaybe<Scalars['jsonb']>;
  starts_on?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  wp_order_id?: InputMaybe<Scalars['uuid']>;
  wp_subscription_id?: InputMaybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface ISubscriptionsStddevFields {
  __typename?: 'subscriptions_stddev_fields';
  credits?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "subscriptions" */
export interface ISubscriptionsStddevOrderBy {
  credits?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface ISubscriptionsStddevPopFields {
  __typename?: 'subscriptions_stddev_pop_fields';
  credits?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "subscriptions" */
export interface ISubscriptionsStddevPopOrderBy {
  credits?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface ISubscriptionsStddevSampFields {
  __typename?: 'subscriptions_stddev_samp_fields';
  credits?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "subscriptions" */
export interface ISubscriptionsStddevSampOrderBy {
  credits?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface ISubscriptionsSumFields {
  __typename?: 'subscriptions_sum_fields';
  credits?: Maybe<Scalars['numeric']>;
  wp_subscription_id?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "subscriptions" */
export interface ISubscriptionsSumOrderBy {
  credits?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
}

/** update columns of table "subscriptions" */
export enum ISubscriptionsUpdateColumn {
  /** column name */
  COURSE_GROUP_ID = 'course_group_id',
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  CREDITS = 'credits',
  /** column name */
  ENDS_ON = 'ends_on',
  /** column name */
  ID = 'id',
  /** column name */
  META_DATA = 'meta_data',
  /** column name */
  STARTS_ON = 'starts_on',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  WP_ORDER_ID = 'wp_order_id',
  /** column name */
  WP_SUBSCRIPTION_ID = 'wp_subscription_id'
}

/** aggregate var_pop on columns */
export interface ISubscriptionsVarPopFields {
  __typename?: 'subscriptions_var_pop_fields';
  credits?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "subscriptions" */
export interface ISubscriptionsVarPopOrderBy {
  credits?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface ISubscriptionsVarSampFields {
  __typename?: 'subscriptions_var_samp_fields';
  credits?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "subscriptions" */
export interface ISubscriptionsVarSampOrderBy {
  credits?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface ISubscriptionsVarianceFields {
  __typename?: 'subscriptions_variance_fields';
  credits?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "subscriptions" */
export interface ISubscriptionsVarianceOrderBy {
  credits?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
}

/** columns and relationships of "support_topic_entries" */
export interface ISupportTopicEntries {
  __typename?: 'support_topic_entries';
  answer: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  question: Scalars['String'];
  /** An object relationship */
  support_topic?: Maybe<ISupportTopics>;
  support_topic_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
}

/** aggregated selection of "support_topic_entries" */
export interface ISupportTopicEntriesAggregate {
  __typename?: 'support_topic_entries_aggregate';
  aggregate?: Maybe<ISupportTopicEntriesAggregateFields>;
  nodes: Array<ISupportTopicEntries>;
}

/** aggregate fields of "support_topic_entries" */
export interface ISupportTopicEntriesAggregateFields {
  __typename?: 'support_topic_entries_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ISupportTopicEntriesMaxFields>;
  min?: Maybe<ISupportTopicEntriesMinFields>;
}


/** aggregate fields of "support_topic_entries" */
export interface ISupportTopicEntriesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ISupportTopicEntriesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "support_topic_entries" */
export interface ISupportTopicEntriesAggregateOrderBy {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<ISupportTopicEntriesMaxOrderBy>;
  min?: InputMaybe<ISupportTopicEntriesMinOrderBy>;
}

/** input type for inserting array relation for remote table "support_topic_entries" */
export interface ISupportTopicEntriesArrRelInsertInput {
  data: Array<ISupportTopicEntriesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ISupportTopicEntriesOnConflict>;
}

/** Boolean expression to filter rows from the table "support_topic_entries". All fields are combined with a logical 'AND'. */
export interface ISupportTopicEntriesBoolExp {
  _and?: InputMaybe<Array<ISupportTopicEntriesBoolExp>>;
  _not?: InputMaybe<ISupportTopicEntriesBoolExp>;
  _or?: InputMaybe<Array<ISupportTopicEntriesBoolExp>>;
  answer?: InputMaybe<IStringComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  question?: InputMaybe<IStringComparisonExp>;
  support_topic?: InputMaybe<ISupportTopicsBoolExp>;
  support_topic_id?: InputMaybe<IUuidComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "support_topic_entries" */
export enum ISupportTopicEntriesConstraint {
  /** unique or primary key constraint */
  SUPPORT_TOPIC_ENTRIES_PKEY = 'support_topic_entries_pkey'
}

/** input type for inserting data into table "support_topic_entries" */
export interface ISupportTopicEntriesInsertInput {
  answer?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  question?: InputMaybe<Scalars['String']>;
  support_topic?: InputMaybe<ISupportTopicsObjRelInsertInput>;
  support_topic_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface ISupportTopicEntriesMaxFields {
  __typename?: 'support_topic_entries_max_fields';
  answer?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  question?: Maybe<Scalars['String']>;
  support_topic_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by max() on columns of table "support_topic_entries" */
export interface ISupportTopicEntriesMaxOrderBy {
  answer?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  question?: InputMaybe<IOrderBy>;
  support_topic_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface ISupportTopicEntriesMinFields {
  __typename?: 'support_topic_entries_min_fields';
  answer?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  question?: Maybe<Scalars['String']>;
  support_topic_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by min() on columns of table "support_topic_entries" */
export interface ISupportTopicEntriesMinOrderBy {
  answer?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  question?: InputMaybe<IOrderBy>;
  support_topic_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "support_topic_entries" */
export interface ISupportTopicEntriesMutationResponse {
  __typename?: 'support_topic_entries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ISupportTopicEntries>;
}

/** on_conflict condition type for table "support_topic_entries" */
export interface ISupportTopicEntriesOnConflict {
  constraint: ISupportTopicEntriesConstraint;
  update_columns?: Array<ISupportTopicEntriesUpdateColumn>;
  where?: InputMaybe<ISupportTopicEntriesBoolExp>;
}

/** Ordering options when selecting data from "support_topic_entries". */
export interface ISupportTopicEntriesOrderBy {
  answer?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  question?: InputMaybe<IOrderBy>;
  support_topic?: InputMaybe<ISupportTopicsOrderBy>;
  support_topic_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: support_topic_entries */
export interface ISupportTopicEntriesPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "support_topic_entries" */
export enum ISupportTopicEntriesSelectColumn {
  /** column name */
  ANSWER = 'answer',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  QUESTION = 'question',
  /** column name */
  SUPPORT_TOPIC_ID = 'support_topic_id',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "support_topic_entries" */
export interface ISupportTopicEntriesSetInput {
  answer?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  question?: InputMaybe<Scalars['String']>;
  support_topic_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** update columns of table "support_topic_entries" */
export enum ISupportTopicEntriesUpdateColumn {
  /** column name */
  ANSWER = 'answer',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  QUESTION = 'question',
  /** column name */
  SUPPORT_TOPIC_ID = 'support_topic_id',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** columns and relationships of "support_topics" */
export interface ISupportTopics {
  __typename?: 'support_topics';
  course: ICourseTypesEnum;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  support_topic_entries: Array<ISupportTopicEntries>;
  /** An aggregate relationship */
  support_topic_entries_aggregate: ISupportTopicEntriesAggregate;
  topic: Scalars['String'];
  updated_at: Scalars['timestamptz'];
}


/** columns and relationships of "support_topics" */
export interface ISupportTopicsSupportTopicEntriesArgs {
  distinct_on?: InputMaybe<Array<ISupportTopicEntriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISupportTopicEntriesOrderBy>>;
  where?: InputMaybe<ISupportTopicEntriesBoolExp>;
}


/** columns and relationships of "support_topics" */
export interface ISupportTopicsSupportTopicEntriesAggregateArgs {
  distinct_on?: InputMaybe<Array<ISupportTopicEntriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISupportTopicEntriesOrderBy>>;
  where?: InputMaybe<ISupportTopicEntriesBoolExp>;
}

/** aggregated selection of "support_topics" */
export interface ISupportTopicsAggregate {
  __typename?: 'support_topics_aggregate';
  aggregate?: Maybe<ISupportTopicsAggregateFields>;
  nodes: Array<ISupportTopics>;
}

/** aggregate fields of "support_topics" */
export interface ISupportTopicsAggregateFields {
  __typename?: 'support_topics_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ISupportTopicsMaxFields>;
  min?: Maybe<ISupportTopicsMinFields>;
}


/** aggregate fields of "support_topics" */
export interface ISupportTopicsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ISupportTopicsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "support_topics". All fields are combined with a logical 'AND'. */
export interface ISupportTopicsBoolExp {
  _and?: InputMaybe<Array<ISupportTopicsBoolExp>>;
  _not?: InputMaybe<ISupportTopicsBoolExp>;
  _or?: InputMaybe<Array<ISupportTopicsBoolExp>>;
  course?: InputMaybe<ICourseTypesEnumComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  support_topic_entries?: InputMaybe<ISupportTopicEntriesBoolExp>;
  topic?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "support_topics" */
export enum ISupportTopicsConstraint {
  /** unique or primary key constraint */
  SUPPORT_TOPICS_PKEY = 'support_topics_pkey'
}

/** input type for inserting data into table "support_topics" */
export interface ISupportTopicsInsertInput {
  course?: InputMaybe<ICourseTypesEnum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  support_topic_entries?: InputMaybe<ISupportTopicEntriesArrRelInsertInput>;
  topic?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface ISupportTopicsMaxFields {
  __typename?: 'support_topics_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  topic?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface ISupportTopicsMinFields {
  __typename?: 'support_topics_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  topic?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** response of any mutation on the table "support_topics" */
export interface ISupportTopicsMutationResponse {
  __typename?: 'support_topics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ISupportTopics>;
}

/** input type for inserting object relation for remote table "support_topics" */
export interface ISupportTopicsObjRelInsertInput {
  data: ISupportTopicsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ISupportTopicsOnConflict>;
}

/** on_conflict condition type for table "support_topics" */
export interface ISupportTopicsOnConflict {
  constraint: ISupportTopicsConstraint;
  update_columns?: Array<ISupportTopicsUpdateColumn>;
  where?: InputMaybe<ISupportTopicsBoolExp>;
}

/** Ordering options when selecting data from "support_topics". */
export interface ISupportTopicsOrderBy {
  course?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  support_topic_entries_aggregate?: InputMaybe<ISupportTopicEntriesAggregateOrderBy>;
  topic?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: support_topics */
export interface ISupportTopicsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "support_topics" */
export enum ISupportTopicsSelectColumn {
  /** column name */
  COURSE = 'course',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  TOPIC = 'topic',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "support_topics" */
export interface ISupportTopicsSetInput {
  course?: InputMaybe<ICourseTypesEnum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  topic?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** update columns of table "support_topics" */
export enum ISupportTopicsUpdateColumn {
  /** column name */
  COURSE = 'course',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  TOPIC = 'topic',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** columns and relationships of "suspensions" */
export interface ISuspensions {
  __typename?: 'suspensions';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  subscription_deactivated: Scalars['Boolean'];
  subscription_deactivated_on: Scalars['timestamptz'];
  subscription_reactivated: Scalars['Boolean'];
  subscription_reactivated_on: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
}

/** aggregated selection of "suspensions" */
export interface ISuspensionsAggregate {
  __typename?: 'suspensions_aggregate';
  aggregate?: Maybe<ISuspensionsAggregateFields>;
  nodes: Array<ISuspensions>;
}

/** aggregate fields of "suspensions" */
export interface ISuspensionsAggregateFields {
  __typename?: 'suspensions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ISuspensionsMaxFields>;
  min?: Maybe<ISuspensionsMinFields>;
}


/** aggregate fields of "suspensions" */
export interface ISuspensionsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ISuspensionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "suspensions". All fields are combined with a logical 'AND'. */
export interface ISuspensionsBoolExp {
  _and?: InputMaybe<Array<ISuspensionsBoolExp>>;
  _not?: InputMaybe<ISuspensionsBoolExp>;
  _or?: InputMaybe<Array<ISuspensionsBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  subscription_deactivated?: InputMaybe<IBooleanComparisonExp>;
  subscription_deactivated_on?: InputMaybe<ITimestamptzComparisonExp>;
  subscription_reactivated?: InputMaybe<IBooleanComparisonExp>;
  subscription_reactivated_on?: InputMaybe<ITimestamptzComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** unique or primary key constraints on table "suspensions" */
export enum ISuspensionsConstraint {
  /** unique or primary key constraint */
  SUSPENSIONS_PKEY = 'suspensions_pkey'
}

/** input type for inserting data into table "suspensions" */
export interface ISuspensionsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  subscription_deactivated?: InputMaybe<Scalars['Boolean']>;
  subscription_deactivated_on?: InputMaybe<Scalars['timestamptz']>;
  subscription_reactivated?: InputMaybe<Scalars['Boolean']>;
  subscription_reactivated_on?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface ISuspensionsMaxFields {
  __typename?: 'suspensions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  subscription_deactivated_on?: Maybe<Scalars['timestamptz']>;
  subscription_reactivated_on?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface ISuspensionsMinFields {
  __typename?: 'suspensions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  subscription_deactivated_on?: Maybe<Scalars['timestamptz']>;
  subscription_reactivated_on?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** response of any mutation on the table "suspensions" */
export interface ISuspensionsMutationResponse {
  __typename?: 'suspensions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ISuspensions>;
}

/** on_conflict condition type for table "suspensions" */
export interface ISuspensionsOnConflict {
  constraint: ISuspensionsConstraint;
  update_columns?: Array<ISuspensionsUpdateColumn>;
  where?: InputMaybe<ISuspensionsBoolExp>;
}

/** Ordering options when selecting data from "suspensions". */
export interface ISuspensionsOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  subscription_deactivated?: InputMaybe<IOrderBy>;
  subscription_deactivated_on?: InputMaybe<IOrderBy>;
  subscription_reactivated?: InputMaybe<IOrderBy>;
  subscription_reactivated_on?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: suspensions */
export interface ISuspensionsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "suspensions" */
export enum ISuspensionsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  SUBSCRIPTION_DEACTIVATED = 'subscription_deactivated',
  /** column name */
  SUBSCRIPTION_DEACTIVATED_ON = 'subscription_deactivated_on',
  /** column name */
  SUBSCRIPTION_REACTIVATED = 'subscription_reactivated',
  /** column name */
  SUBSCRIPTION_REACTIVATED_ON = 'subscription_reactivated_on',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id'
}

/** input type for updating data in table "suspensions" */
export interface ISuspensionsSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  subscription_deactivated?: InputMaybe<Scalars['Boolean']>;
  subscription_deactivated_on?: InputMaybe<Scalars['timestamptz']>;
  subscription_reactivated?: InputMaybe<Scalars['Boolean']>;
  subscription_reactivated_on?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** update columns of table "suspensions" */
export enum ISuspensionsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  SUBSCRIPTION_DEACTIVATED = 'subscription_deactivated',
  /** column name */
  SUBSCRIPTION_DEACTIVATED_ON = 'subscription_deactivated_on',
  /** column name */
  SUBSCRIPTION_REACTIVATED = 'subscription_reactivated',
  /** column name */
  SUBSCRIPTION_REACTIVATED_ON = 'subscription_reactivated_on',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id'
}

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export interface ITimeComparisonExp {
  _eq?: InputMaybe<Scalars['time']>;
  _gt?: InputMaybe<Scalars['time']>;
  _gte?: InputMaybe<Scalars['time']>;
  _in?: InputMaybe<Array<Scalars['time']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['time']>;
  _lte?: InputMaybe<Scalars['time']>;
  _neq?: InputMaybe<Scalars['time']>;
  _nin?: InputMaybe<Array<Scalars['time']>>;
}

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export interface ITimestampComparisonExp {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export interface ITimestamptzComparisonExp {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
}

/** Boolean expression to compare columns of type "timetz". All fields are combined with logical 'AND'. */
export interface ITimetzComparisonExp {
  _eq?: InputMaybe<Scalars['timetz']>;
  _gt?: InputMaybe<Scalars['timetz']>;
  _gte?: InputMaybe<Scalars['timetz']>;
  _in?: InputMaybe<Array<Scalars['timetz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timetz']>;
  _lte?: InputMaybe<Scalars['timetz']>;
  _neq?: InputMaybe<Scalars['timetz']>;
  _nin?: InputMaybe<Array<Scalars['timetz']>>;
}

/** columns and relationships of "tmp_lessons" */
export interface ITmpLessons {
  __typename?: 'tmp_lessons';
  content: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
}

/** aggregated selection of "tmp_lessons" */
export interface ITmpLessonsAggregate {
  __typename?: 'tmp_lessons_aggregate';
  aggregate?: Maybe<ITmpLessonsAggregateFields>;
  nodes: Array<ITmpLessons>;
}

/** aggregate fields of "tmp_lessons" */
export interface ITmpLessonsAggregateFields {
  __typename?: 'tmp_lessons_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ITmpLessonsMaxFields>;
  min?: Maybe<ITmpLessonsMinFields>;
}


/** aggregate fields of "tmp_lessons" */
export interface ITmpLessonsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ITmpLessonsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "tmp_lessons". All fields are combined with a logical 'AND'. */
export interface ITmpLessonsBoolExp {
  _and?: InputMaybe<Array<ITmpLessonsBoolExp>>;
  _not?: InputMaybe<ITmpLessonsBoolExp>;
  _or?: InputMaybe<Array<ITmpLessonsBoolExp>>;
  content?: InputMaybe<IStringComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "tmp_lessons" */
export enum ITmpLessonsConstraint {
  /** unique or primary key constraint */
  TMP_LESSONS_PKEY = 'tmp_lessons_pkey'
}

/** input type for inserting data into table "tmp_lessons" */
export interface ITmpLessonsInsertInput {
  content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface ITmpLessonsMaxFields {
  __typename?: 'tmp_lessons_max_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface ITmpLessonsMinFields {
  __typename?: 'tmp_lessons_min_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** response of any mutation on the table "tmp_lessons" */
export interface ITmpLessonsMutationResponse {
  __typename?: 'tmp_lessons_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ITmpLessons>;
}

/** on_conflict condition type for table "tmp_lessons" */
export interface ITmpLessonsOnConflict {
  constraint: ITmpLessonsConstraint;
  update_columns?: Array<ITmpLessonsUpdateColumn>;
  where?: InputMaybe<ITmpLessonsBoolExp>;
}

/** Ordering options when selecting data from "tmp_lessons". */
export interface ITmpLessonsOrderBy {
  content?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: tmp_lessons */
export interface ITmpLessonsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "tmp_lessons" */
export enum ITmpLessonsSelectColumn {
  /** column name */
  CONTENT = 'content',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "tmp_lessons" */
export interface ITmpLessonsSetInput {
  content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** update columns of table "tmp_lessons" */
export enum ITmpLessonsUpdateColumn {
  /** column name */
  CONTENT = 'content',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** columns and relationships of "tmp_progress" */
export interface ITmpProgress {
  __typename?: 'tmp_progress';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  tmp_lesson_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
}

/** aggregated selection of "tmp_progress" */
export interface ITmpProgressAggregate {
  __typename?: 'tmp_progress_aggregate';
  aggregate?: Maybe<ITmpProgressAggregateFields>;
  nodes: Array<ITmpProgress>;
}

/** aggregate fields of "tmp_progress" */
export interface ITmpProgressAggregateFields {
  __typename?: 'tmp_progress_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ITmpProgressMaxFields>;
  min?: Maybe<ITmpProgressMinFields>;
}


/** aggregate fields of "tmp_progress" */
export interface ITmpProgressAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ITmpProgressSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "tmp_progress". All fields are combined with a logical 'AND'. */
export interface ITmpProgressBoolExp {
  _and?: InputMaybe<Array<ITmpProgressBoolExp>>;
  _not?: InputMaybe<ITmpProgressBoolExp>;
  _or?: InputMaybe<Array<ITmpProgressBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  tmp_lesson_id?: InputMaybe<IUuidComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** unique or primary key constraints on table "tmp_progress" */
export enum ITmpProgressConstraint {
  /** unique or primary key constraint */
  TMP_PROGRESS_PKEY = 'tmp_progress_pkey',
  /** unique or primary key constraint */
  TMP_PROGRESS_USER_ID_TMP_LESSON_ID_KEY = 'tmp_progress_user_id_tmp_lesson_id_key'
}

/** input type for inserting data into table "tmp_progress" */
export interface ITmpProgressInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  tmp_lesson_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface ITmpProgressMaxFields {
  __typename?: 'tmp_progress_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  tmp_lesson_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface ITmpProgressMinFields {
  __typename?: 'tmp_progress_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  tmp_lesson_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** response of any mutation on the table "tmp_progress" */
export interface ITmpProgressMutationResponse {
  __typename?: 'tmp_progress_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ITmpProgress>;
}

/** on_conflict condition type for table "tmp_progress" */
export interface ITmpProgressOnConflict {
  constraint: ITmpProgressConstraint;
  update_columns?: Array<ITmpProgressUpdateColumn>;
  where?: InputMaybe<ITmpProgressBoolExp>;
}

/** Ordering options when selecting data from "tmp_progress". */
export interface ITmpProgressOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  tmp_lesson_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: tmp_progress */
export interface ITmpProgressPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "tmp_progress" */
export enum ITmpProgressSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  TMP_LESSON_ID = 'tmp_lesson_id',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id'
}

/** input type for updating data in table "tmp_progress" */
export interface ITmpProgressSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  tmp_lesson_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** update columns of table "tmp_progress" */
export enum ITmpProgressUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  TMP_LESSON_ID = 'tmp_lesson_id',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id'
}

/** columns and relationships of "tmp_stream_lessons" */
export interface ITmpStreamLessons {
  __typename?: 'tmp_stream_lessons';
  /** An object relationship */
  course?: Maybe<ICourses>;
  course_id?: Maybe<Scalars['uuid']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  lesson?: Maybe<ITmpLessons>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  subscription?: Maybe<ISubscriptions>;
  subscription_id?: Maybe<Scalars['uuid']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id?: Maybe<Scalars['uuid']>;
  watchable?: Maybe<Scalars['Boolean']>;
}

/** aggregated selection of "tmp_stream_lessons" */
export interface ITmpStreamLessonsAggregate {
  __typename?: 'tmp_stream_lessons_aggregate';
  aggregate?: Maybe<ITmpStreamLessonsAggregateFields>;
  nodes: Array<ITmpStreamLessons>;
}

/** aggregate fields of "tmp_stream_lessons" */
export interface ITmpStreamLessonsAggregateFields {
  __typename?: 'tmp_stream_lessons_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ITmpStreamLessonsMaxFields>;
  min?: Maybe<ITmpStreamLessonsMinFields>;
}


/** aggregate fields of "tmp_stream_lessons" */
export interface ITmpStreamLessonsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ITmpStreamLessonsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "tmp_stream_lessons". All fields are combined with a logical 'AND'. */
export interface ITmpStreamLessonsBoolExp {
  _and?: InputMaybe<Array<ITmpStreamLessonsBoolExp>>;
  _not?: InputMaybe<ITmpStreamLessonsBoolExp>;
  _or?: InputMaybe<Array<ITmpStreamLessonsBoolExp>>;
  course?: InputMaybe<ICoursesBoolExp>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  end_time?: InputMaybe<ITimestamptzComparisonExp>;
  lesson?: InputMaybe<ITmpLessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  subscription?: InputMaybe<ISubscriptionsBoolExp>;
  subscription_id?: InputMaybe<IUuidComparisonExp>;
  unlocked_at?: InputMaybe<ITimestamptzComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
  watchable?: InputMaybe<IBooleanComparisonExp>;
}

/** aggregate max on columns */
export interface ITmpStreamLessonsMaxFields {
  __typename?: 'tmp_stream_lessons_max_fields';
  course_id?: Maybe<Scalars['uuid']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface ITmpStreamLessonsMinFields {
  __typename?: 'tmp_stream_lessons_min_fields';
  course_id?: Maybe<Scalars['uuid']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "tmp_stream_lessons". */
export interface ITmpStreamLessonsOrderBy {
  course?: InputMaybe<ICoursesOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  end_time?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ITmpLessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  subscription?: InputMaybe<ISubscriptionsOrderBy>;
  subscription_id?: InputMaybe<IOrderBy>;
  unlocked_at?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  watchable?: InputMaybe<IOrderBy>;
}

/** select columns of table "tmp_stream_lessons" */
export enum ITmpStreamLessonsSelectColumn {
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  END_TIME = 'end_time',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  NAME = 'name',
  /** column name */
  SUBSCRIPTION_ID = 'subscription_id',
  /** column name */
  UNLOCKED_AT = 'unlocked_at',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  WATCHABLE = 'watchable'
}

/** columns and relationships of "unique_user_sessions" */
export interface IUniqueUserSessions {
  __typename?: 'unique_user_sessions';
  auth0id?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  user?: Maybe<IUsers>;
}

/** aggregated selection of "unique_user_sessions" */
export interface IUniqueUserSessionsAggregate {
  __typename?: 'unique_user_sessions_aggregate';
  aggregate?: Maybe<IUniqueUserSessionsAggregateFields>;
  nodes: Array<IUniqueUserSessions>;
}

/** aggregate fields of "unique_user_sessions" */
export interface IUniqueUserSessionsAggregateFields {
  __typename?: 'unique_user_sessions_aggregate_fields';
  avg?: Maybe<IUniqueUserSessionsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IUniqueUserSessionsMaxFields>;
  min?: Maybe<IUniqueUserSessionsMinFields>;
  stddev?: Maybe<IUniqueUserSessionsStddevFields>;
  stddev_pop?: Maybe<IUniqueUserSessionsStddevPopFields>;
  stddev_samp?: Maybe<IUniqueUserSessionsStddevSampFields>;
  sum?: Maybe<IUniqueUserSessionsSumFields>;
  var_pop?: Maybe<IUniqueUserSessionsVarPopFields>;
  var_samp?: Maybe<IUniqueUserSessionsVarSampFields>;
  variance?: Maybe<IUniqueUserSessionsVarianceFields>;
}


/** aggregate fields of "unique_user_sessions" */
export interface IUniqueUserSessionsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IUniqueUserSessionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IUniqueUserSessionsAvgFields {
  __typename?: 'unique_user_sessions_avg_fields';
  count?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "unique_user_sessions". All fields are combined with a logical 'AND'. */
export interface IUniqueUserSessionsBoolExp {
  _and?: InputMaybe<Array<IUniqueUserSessionsBoolExp>>;
  _not?: InputMaybe<IUniqueUserSessionsBoolExp>;
  _or?: InputMaybe<Array<IUniqueUserSessionsBoolExp>>;
  auth0id?: InputMaybe<IStringComparisonExp>;
  count?: InputMaybe<IBigintComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
}

/** aggregate max on columns */
export interface IUniqueUserSessionsMaxFields {
  __typename?: 'unique_user_sessions_max_fields';
  auth0id?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['bigint']>;
}

/** aggregate min on columns */
export interface IUniqueUserSessionsMinFields {
  __typename?: 'unique_user_sessions_min_fields';
  auth0id?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['bigint']>;
}

/** Ordering options when selecting data from "unique_user_sessions". */
export interface IUniqueUserSessionsOrderBy {
  auth0id?: InputMaybe<IOrderBy>;
  count?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
}

/** select columns of table "unique_user_sessions" */
export enum IUniqueUserSessionsSelectColumn {
  /** column name */
  AUTH0ID = 'auth0id',
  /** column name */
  COUNT = 'count'
}

/** aggregate stddev on columns */
export interface IUniqueUserSessionsStddevFields {
  __typename?: 'unique_user_sessions_stddev_fields';
  count?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IUniqueUserSessionsStddevPopFields {
  __typename?: 'unique_user_sessions_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IUniqueUserSessionsStddevSampFields {
  __typename?: 'unique_user_sessions_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IUniqueUserSessionsSumFields {
  __typename?: 'unique_user_sessions_sum_fields';
  count?: Maybe<Scalars['bigint']>;
}

/** aggregate var_pop on columns */
export interface IUniqueUserSessionsVarPopFields {
  __typename?: 'unique_user_sessions_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IUniqueUserSessionsVarSampFields {
  __typename?: 'unique_user_sessions_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IUniqueUserSessionsVarianceFields {
  __typename?: 'unique_user_sessions_variance_fields';
  count?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "unlockables" */
export interface IUnlockables {
  __typename?: 'unlockables';
  type: Scalars['String'];
}

/** aggregated selection of "unlockables" */
export interface IUnlockablesAggregate {
  __typename?: 'unlockables_aggregate';
  aggregate?: Maybe<IUnlockablesAggregateFields>;
  nodes: Array<IUnlockables>;
}

/** aggregate fields of "unlockables" */
export interface IUnlockablesAggregateFields {
  __typename?: 'unlockables_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IUnlockablesMaxFields>;
  min?: Maybe<IUnlockablesMinFields>;
}


/** aggregate fields of "unlockables" */
export interface IUnlockablesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IUnlockablesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "unlockables". All fields are combined with a logical 'AND'. */
export interface IUnlockablesBoolExp {
  _and?: InputMaybe<Array<IUnlockablesBoolExp>>;
  _not?: InputMaybe<IUnlockablesBoolExp>;
  _or?: InputMaybe<Array<IUnlockablesBoolExp>>;
  type?: InputMaybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "unlockables" */
export enum IUnlockablesConstraint {
  /** unique or primary key constraint */
  UNLOCKABLES_PKEY = 'unlockables_pkey'
}

export enum IUnlockablesEnum {
  CERTIFICATE = 'CERTIFICATE',
  FINAL_MESSAGE = 'FINAL_MESSAGE',
  FINAL_TEST = 'FINAL_TEST'
}

/** Boolean expression to compare columns of type "unlockables_enum". All fields are combined with logical 'AND'. */
export interface IUnlockablesEnumComparisonExp {
  _eq?: InputMaybe<IUnlockablesEnum>;
  _in?: InputMaybe<Array<IUnlockablesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<IUnlockablesEnum>;
  _nin?: InputMaybe<Array<IUnlockablesEnum>>;
}

/** input type for inserting data into table "unlockables" */
export interface IUnlockablesInsertInput {
  type?: InputMaybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface IUnlockablesMaxFields {
  __typename?: 'unlockables_max_fields';
  type?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IUnlockablesMinFields {
  __typename?: 'unlockables_min_fields';
  type?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "unlockables" */
export interface IUnlockablesMutationResponse {
  __typename?: 'unlockables_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IUnlockables>;
}

/** on_conflict condition type for table "unlockables" */
export interface IUnlockablesOnConflict {
  constraint: IUnlockablesConstraint;
  update_columns?: Array<IUnlockablesUpdateColumn>;
  where?: InputMaybe<IUnlockablesBoolExp>;
}

/** Ordering options when selecting data from "unlockables". */
export interface IUnlockablesOrderBy {
  type?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: unlockables */
export interface IUnlockablesPkColumnsInput {
  type: Scalars['String'];
}

/** select columns of table "unlockables" */
export enum IUnlockablesSelectColumn {
  /** column name */
  TYPE = 'type'
}

/** input type for updating data in table "unlockables" */
export interface IUnlockablesSetInput {
  type?: InputMaybe<Scalars['String']>;
}

/** update columns of table "unlockables" */
export enum IUnlockablesUpdateColumn {
  /** column name */
  TYPE = 'type'
}

/** columns and relationships of "user_children_unlockables" */
export interface IUserChildrenUnlockables {
  __typename?: 'user_children_unlockables';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  type: IUnlockablesEnum;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  users_child: IUsersChildren;
  users_child_id: Scalars['uuid'];
}

/** aggregated selection of "user_children_unlockables" */
export interface IUserChildrenUnlockablesAggregate {
  __typename?: 'user_children_unlockables_aggregate';
  aggregate?: Maybe<IUserChildrenUnlockablesAggregateFields>;
  nodes: Array<IUserChildrenUnlockables>;
}

/** aggregate fields of "user_children_unlockables" */
export interface IUserChildrenUnlockablesAggregateFields {
  __typename?: 'user_children_unlockables_aggregate_fields';
  avg?: Maybe<IUserChildrenUnlockablesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IUserChildrenUnlockablesMaxFields>;
  min?: Maybe<IUserChildrenUnlockablesMinFields>;
  stddev?: Maybe<IUserChildrenUnlockablesStddevFields>;
  stddev_pop?: Maybe<IUserChildrenUnlockablesStddevPopFields>;
  stddev_samp?: Maybe<IUserChildrenUnlockablesStddevSampFields>;
  sum?: Maybe<IUserChildrenUnlockablesSumFields>;
  var_pop?: Maybe<IUserChildrenUnlockablesVarPopFields>;
  var_samp?: Maybe<IUserChildrenUnlockablesVarSampFields>;
  variance?: Maybe<IUserChildrenUnlockablesVarianceFields>;
}


/** aggregate fields of "user_children_unlockables" */
export interface IUserChildrenUnlockablesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IUserChildrenUnlockablesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "user_children_unlockables" */
export interface IUserChildrenUnlockablesAggregateOrderBy {
  avg?: InputMaybe<IUserChildrenUnlockablesAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IUserChildrenUnlockablesMaxOrderBy>;
  min?: InputMaybe<IUserChildrenUnlockablesMinOrderBy>;
  stddev?: InputMaybe<IUserChildrenUnlockablesStddevOrderBy>;
  stddev_pop?: InputMaybe<IUserChildrenUnlockablesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IUserChildrenUnlockablesStddevSampOrderBy>;
  sum?: InputMaybe<IUserChildrenUnlockablesSumOrderBy>;
  var_pop?: InputMaybe<IUserChildrenUnlockablesVarPopOrderBy>;
  var_samp?: InputMaybe<IUserChildrenUnlockablesVarSampOrderBy>;
  variance?: InputMaybe<IUserChildrenUnlockablesVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "user_children_unlockables" */
export interface IUserChildrenUnlockablesArrRelInsertInput {
  data: Array<IUserChildrenUnlockablesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IUserChildrenUnlockablesOnConflict>;
}

/** aggregate avg on columns */
export interface IUserChildrenUnlockablesAvgFields {
  __typename?: 'user_children_unlockables_avg_fields';
  id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "user_children_unlockables" */
export interface IUserChildrenUnlockablesAvgOrderBy {
  id?: InputMaybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "user_children_unlockables". All fields are combined with a logical 'AND'. */
export interface IUserChildrenUnlockablesBoolExp {
  _and?: InputMaybe<Array<IUserChildrenUnlockablesBoolExp>>;
  _not?: InputMaybe<IUserChildrenUnlockablesBoolExp>;
  _or?: InputMaybe<Array<IUserChildrenUnlockablesBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IIntComparisonExp>;
  type?: InputMaybe<IUnlockablesEnumComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  users_child?: InputMaybe<IUsersChildrenBoolExp>;
  users_child_id?: InputMaybe<IUuidComparisonExp>;
}

/** unique or primary key constraints on table "user_children_unlockables" */
export enum IUserChildrenUnlockablesConstraint {
  /** unique or primary key constraint */
  USER_CHILDREN_UNLOCKABLES_PKEY = 'USER_CHILDREN_UNLOCKABLES_pkey',
  /** unique or primary key constraint */
  USER_CHILDREN_UNLOCKABLES_TYPE_USERS_CHILD_ID_KEY = 'user_children_unlockables_type_users_child_id_key'
}

/** input type for incrementing numeric columns in table "user_children_unlockables" */
export interface IUserChildrenUnlockablesIncInput {
  id?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "user_children_unlockables" */
export interface IUserChildrenUnlockablesInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<IUnlockablesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  users_child?: InputMaybe<IUsersChildrenObjRelInsertInput>;
  users_child_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface IUserChildrenUnlockablesMaxFields {
  __typename?: 'user_children_unlockables_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  users_child_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "user_children_unlockables" */
export interface IUserChildrenUnlockablesMaxOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  users_child_id?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IUserChildrenUnlockablesMinFields {
  __typename?: 'user_children_unlockables_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  users_child_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "user_children_unlockables" */
export interface IUserChildrenUnlockablesMinOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  users_child_id?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "user_children_unlockables" */
export interface IUserChildrenUnlockablesMutationResponse {
  __typename?: 'user_children_unlockables_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IUserChildrenUnlockables>;
}

/** on_conflict condition type for table "user_children_unlockables" */
export interface IUserChildrenUnlockablesOnConflict {
  constraint: IUserChildrenUnlockablesConstraint;
  update_columns?: Array<IUserChildrenUnlockablesUpdateColumn>;
  where?: InputMaybe<IUserChildrenUnlockablesBoolExp>;
}

/** Ordering options when selecting data from "user_children_unlockables". */
export interface IUserChildrenUnlockablesOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  type?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  users_child?: InputMaybe<IUsersChildrenOrderBy>;
  users_child_id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: user_children_unlockables */
export interface IUserChildrenUnlockablesPkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "user_children_unlockables" */
export enum IUserChildrenUnlockablesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  TYPE = 'type',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USERS_CHILD_ID = 'users_child_id'
}

/** input type for updating data in table "user_children_unlockables" */
export interface IUserChildrenUnlockablesSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<IUnlockablesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  users_child_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate stddev on columns */
export interface IUserChildrenUnlockablesStddevFields {
  __typename?: 'user_children_unlockables_stddev_fields';
  id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "user_children_unlockables" */
export interface IUserChildrenUnlockablesStddevOrderBy {
  id?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IUserChildrenUnlockablesStddevPopFields {
  __typename?: 'user_children_unlockables_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "user_children_unlockables" */
export interface IUserChildrenUnlockablesStddevPopOrderBy {
  id?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IUserChildrenUnlockablesStddevSampFields {
  __typename?: 'user_children_unlockables_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "user_children_unlockables" */
export interface IUserChildrenUnlockablesStddevSampOrderBy {
  id?: InputMaybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IUserChildrenUnlockablesSumFields {
  __typename?: 'user_children_unlockables_sum_fields';
  id?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "user_children_unlockables" */
export interface IUserChildrenUnlockablesSumOrderBy {
  id?: InputMaybe<IOrderBy>;
}

/** update columns of table "user_children_unlockables" */
export enum IUserChildrenUnlockablesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  TYPE = 'type',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USERS_CHILD_ID = 'users_child_id'
}

/** aggregate var_pop on columns */
export interface IUserChildrenUnlockablesVarPopFields {
  __typename?: 'user_children_unlockables_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "user_children_unlockables" */
export interface IUserChildrenUnlockablesVarPopOrderBy {
  id?: InputMaybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IUserChildrenUnlockablesVarSampFields {
  __typename?: 'user_children_unlockables_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "user_children_unlockables" */
export interface IUserChildrenUnlockablesVarSampOrderBy {
  id?: InputMaybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IUserChildrenUnlockablesVarianceFields {
  __typename?: 'user_children_unlockables_variance_fields';
  id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "user_children_unlockables" */
export interface IUserChildrenUnlockablesVarianceOrderBy {
  id?: InputMaybe<IOrderBy>;
}

/** columns and relationships of "user_course_usage" */
export interface IUserCourseUsage {
  __typename?: 'user_course_usage';
  /** An object relationship */
  course?: Maybe<ICourses>;
  course_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  credits?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  total?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  usage?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "user_course_usage" */
export interface IUserCourseUsageAggregate {
  __typename?: 'user_course_usage_aggregate';
  aggregate?: Maybe<IUserCourseUsageAggregateFields>;
  nodes: Array<IUserCourseUsage>;
}

/** aggregate fields of "user_course_usage" */
export interface IUserCourseUsageAggregateFields {
  __typename?: 'user_course_usage_aggregate_fields';
  avg?: Maybe<IUserCourseUsageAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IUserCourseUsageMaxFields>;
  min?: Maybe<IUserCourseUsageMinFields>;
  stddev?: Maybe<IUserCourseUsageStddevFields>;
  stddev_pop?: Maybe<IUserCourseUsageStddevPopFields>;
  stddev_samp?: Maybe<IUserCourseUsageStddevSampFields>;
  sum?: Maybe<IUserCourseUsageSumFields>;
  var_pop?: Maybe<IUserCourseUsageVarPopFields>;
  var_samp?: Maybe<IUserCourseUsageVarSampFields>;
  variance?: Maybe<IUserCourseUsageVarianceFields>;
}


/** aggregate fields of "user_course_usage" */
export interface IUserCourseUsageAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IUserCourseUsageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IUserCourseUsageAvgFields {
  __typename?: 'user_course_usage_avg_fields';
  credits?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  usage?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "user_course_usage". All fields are combined with a logical 'AND'. */
export interface IUserCourseUsageBoolExp {
  _and?: InputMaybe<Array<IUserCourseUsageBoolExp>>;
  _not?: InputMaybe<IUserCourseUsageBoolExp>;
  _or?: InputMaybe<Array<IUserCourseUsageBoolExp>>;
  course?: InputMaybe<ICoursesBoolExp>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  credits?: InputMaybe<INumericComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  subscription_id?: InputMaybe<IUuidComparisonExp>;
  total?: InputMaybe<INumericComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  usage?: InputMaybe<IBigintComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** input type for inserting data into table "user_course_usage" */
export interface IUserCourseUsageInsertInput {
  course?: InputMaybe<ICoursesObjRelInsertInput>;
  course_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  credits?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  subscription_id?: InputMaybe<Scalars['uuid']>;
  total?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  usage?: InputMaybe<Scalars['bigint']>;
  user?: InputMaybe<IUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface IUserCourseUsageMaxFields {
  __typename?: 'user_course_usage_max_fields';
  course_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  credits?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  total?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  usage?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IUserCourseUsageMinFields {
  __typename?: 'user_course_usage_min_fields';
  course_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  credits?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  total?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  usage?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** input type for inserting object relation for remote table "user_course_usage" */
export interface IUserCourseUsageObjRelInsertInput {
  data: IUserCourseUsageInsertInput;
}

/** Ordering options when selecting data from "user_course_usage". */
export interface IUserCourseUsageOrderBy {
  course?: InputMaybe<ICoursesOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  credits?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  subscription_id?: InputMaybe<IOrderBy>;
  total?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  usage?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "user_course_usage" */
export enum IUserCourseUsageSelectColumn {
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  CREDITS = 'credits',
  /** column name */
  ID = 'id',
  /** column name */
  SUBSCRIPTION_ID = 'subscription_id',
  /** column name */
  TOTAL = 'total',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USAGE = 'usage',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate stddev on columns */
export interface IUserCourseUsageStddevFields {
  __typename?: 'user_course_usage_stddev_fields';
  credits?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  usage?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IUserCourseUsageStddevPopFields {
  __typename?: 'user_course_usage_stddev_pop_fields';
  credits?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  usage?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IUserCourseUsageStddevSampFields {
  __typename?: 'user_course_usage_stddev_samp_fields';
  credits?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  usage?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IUserCourseUsageSumFields {
  __typename?: 'user_course_usage_sum_fields';
  credits?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
  usage?: Maybe<Scalars['bigint']>;
}

/** aggregate var_pop on columns */
export interface IUserCourseUsageVarPopFields {
  __typename?: 'user_course_usage_var_pop_fields';
  credits?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  usage?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IUserCourseUsageVarSampFields {
  __typename?: 'user_course_usage_var_samp_fields';
  credits?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  usage?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IUserCourseUsageVarianceFields {
  __typename?: 'user_course_usage_variance_fields';
  credits?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  usage?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "user_lesson_messages_view" */
export interface IUserLessonMessagesView {
  __typename?: 'user_lesson_messages_view';
  /** An object relationship */
  course?: Maybe<ICourses>;
  course_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  lesson_completed?: Maybe<Scalars['Boolean']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  opened?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  user_child?: Maybe<IUsersChildren>;
  user_child_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "user_lesson_messages_view" */
export interface IUserLessonMessagesViewAggregate {
  __typename?: 'user_lesson_messages_view_aggregate';
  aggregate?: Maybe<IUserLessonMessagesViewAggregateFields>;
  nodes: Array<IUserLessonMessagesView>;
}

/** aggregate fields of "user_lesson_messages_view" */
export interface IUserLessonMessagesViewAggregateFields {
  __typename?: 'user_lesson_messages_view_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IUserLessonMessagesViewMaxFields>;
  min?: Maybe<IUserLessonMessagesViewMinFields>;
}


/** aggregate fields of "user_lesson_messages_view" */
export interface IUserLessonMessagesViewAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IUserLessonMessagesViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "user_lesson_messages_view". All fields are combined with a logical 'AND'. */
export interface IUserLessonMessagesViewBoolExp {
  _and?: InputMaybe<Array<IUserLessonMessagesViewBoolExp>>;
  _not?: InputMaybe<IUserLessonMessagesViewBoolExp>;
  _or?: InputMaybe<Array<IUserLessonMessagesViewBoolExp>>;
  course?: InputMaybe<ICoursesBoolExp>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_completed?: InputMaybe<IBooleanComparisonExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  message?: InputMaybe<IStringComparisonExp>;
  opened?: InputMaybe<IBooleanComparisonExp>;
  user_child?: InputMaybe<IUsersChildrenBoolExp>;
  user_child_id?: InputMaybe<IUuidComparisonExp>;
}

/** aggregate max on columns */
export interface IUserLessonMessagesViewMaxFields {
  __typename?: 'user_lesson_messages_view_max_fields';
  course_id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  user_child_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IUserLessonMessagesViewMinFields {
  __typename?: 'user_lesson_messages_view_min_fields';
  course_id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  user_child_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "user_lesson_messages_view". */
export interface IUserLessonMessagesViewOrderBy {
  course?: InputMaybe<ICoursesOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_completed?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  message?: InputMaybe<IOrderBy>;
  opened?: InputMaybe<IOrderBy>;
  user_child?: InputMaybe<IUsersChildrenOrderBy>;
  user_child_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "user_lesson_messages_view" */
export enum IUserLessonMessagesViewSelectColumn {
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  LESSON_COMPLETED = 'lesson_completed',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  MESSAGE = 'message',
  /** column name */
  OPENED = 'opened',
  /** column name */
  USER_CHILD_ID = 'user_child_id'
}

/** columns and relationships of "user_lesson_parts_progresses" */
export interface IUserLessonPartsProgresses {
  __typename?: 'user_lesson_parts_progresses';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  lesson: ILessons;
  lesson_id: Scalars['uuid'];
  /** An object relationship */
  user: IUsers;
  user_id: Scalars['uuid'];
}

/** aggregated selection of "user_lesson_parts_progresses" */
export interface IUserLessonPartsProgressesAggregate {
  __typename?: 'user_lesson_parts_progresses_aggregate';
  aggregate?: Maybe<IUserLessonPartsProgressesAggregateFields>;
  nodes: Array<IUserLessonPartsProgresses>;
}

/** aggregate fields of "user_lesson_parts_progresses" */
export interface IUserLessonPartsProgressesAggregateFields {
  __typename?: 'user_lesson_parts_progresses_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IUserLessonPartsProgressesMaxFields>;
  min?: Maybe<IUserLessonPartsProgressesMinFields>;
}


/** aggregate fields of "user_lesson_parts_progresses" */
export interface IUserLessonPartsProgressesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IUserLessonPartsProgressesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "user_lesson_parts_progresses". All fields are combined with a logical 'AND'. */
export interface IUserLessonPartsProgressesBoolExp {
  _and?: InputMaybe<Array<IUserLessonPartsProgressesBoolExp>>;
  _not?: InputMaybe<IUserLessonPartsProgressesBoolExp>;
  _or?: InputMaybe<Array<IUserLessonPartsProgressesBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** unique or primary key constraints on table "user_lesson_parts_progresses" */
export enum IUserLessonPartsProgressesConstraint {
  /** unique or primary key constraint */
  USER_LESSON_PARTS_PROGRESSES_LESSON_ID_USER_ID_KEY = 'user_lesson_parts_progresses_lesson_id_user_id_key',
  /** unique or primary key constraint */
  USER_LESSON_PARTS_PROGRESSES_PKEY = 'user_lesson_parts_progresses_pkey'
}

/** input type for inserting data into table "user_lesson_parts_progresses" */
export interface IUserLessonPartsProgressesInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson?: InputMaybe<ILessonsObjRelInsertInput>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<IUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface IUserLessonPartsProgressesMaxFields {
  __typename?: 'user_lesson_parts_progresses_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IUserLessonPartsProgressesMinFields {
  __typename?: 'user_lesson_parts_progresses_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** response of any mutation on the table "user_lesson_parts_progresses" */
export interface IUserLessonPartsProgressesMutationResponse {
  __typename?: 'user_lesson_parts_progresses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IUserLessonPartsProgresses>;
}

/** on_conflict condition type for table "user_lesson_parts_progresses" */
export interface IUserLessonPartsProgressesOnConflict {
  constraint: IUserLessonPartsProgressesConstraint;
  update_columns?: Array<IUserLessonPartsProgressesUpdateColumn>;
  where?: InputMaybe<IUserLessonPartsProgressesBoolExp>;
}

/** Ordering options when selecting data from "user_lesson_parts_progresses". */
export interface IUserLessonPartsProgressesOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: user_lesson_parts_progresses */
export interface IUserLessonPartsProgressesPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "user_lesson_parts_progresses" */
export enum IUserLessonPartsProgressesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  USER_ID = 'user_id'
}

/** input type for updating data in table "user_lesson_parts_progresses" */
export interface IUserLessonPartsProgressesSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** update columns of table "user_lesson_parts_progresses" */
export enum IUserLessonPartsProgressesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  USER_ID = 'user_id'
}

/** columns and relationships of "user_lesson_progresses" */
export interface IUserLessonProgresses {
  __typename?: 'user_lesson_progresses';
  created_at: Scalars['timestamptz'];
  finished?: Maybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
  /** An object relationship */
  lesson?: Maybe<ILessons>;
  lesson_id: Scalars['uuid'];
  percentage?: Maybe<Scalars['numeric']>;
  time_progress?: Maybe<Scalars['numeric']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id: Scalars['uuid'];
  users_child_id?: Maybe<Scalars['uuid']>;
  view_count?: Maybe<Scalars['Int']>;
}

/** aggregated selection of "user_lesson_progresses" */
export interface IUserLessonProgressesAggregate {
  __typename?: 'user_lesson_progresses_aggregate';
  aggregate?: Maybe<IUserLessonProgressesAggregateFields>;
  nodes: Array<IUserLessonProgresses>;
}

/** aggregate fields of "user_lesson_progresses" */
export interface IUserLessonProgressesAggregateFields {
  __typename?: 'user_lesson_progresses_aggregate_fields';
  avg?: Maybe<IUserLessonProgressesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IUserLessonProgressesMaxFields>;
  min?: Maybe<IUserLessonProgressesMinFields>;
  stddev?: Maybe<IUserLessonProgressesStddevFields>;
  stddev_pop?: Maybe<IUserLessonProgressesStddevPopFields>;
  stddev_samp?: Maybe<IUserLessonProgressesStddevSampFields>;
  sum?: Maybe<IUserLessonProgressesSumFields>;
  var_pop?: Maybe<IUserLessonProgressesVarPopFields>;
  var_samp?: Maybe<IUserLessonProgressesVarSampFields>;
  variance?: Maybe<IUserLessonProgressesVarianceFields>;
}


/** aggregate fields of "user_lesson_progresses" */
export interface IUserLessonProgressesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IUserLessonProgressesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "user_lesson_progresses" */
export interface IUserLessonProgressesAggregateOrderBy {
  avg?: InputMaybe<IUserLessonProgressesAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IUserLessonProgressesMaxOrderBy>;
  min?: InputMaybe<IUserLessonProgressesMinOrderBy>;
  stddev?: InputMaybe<IUserLessonProgressesStddevOrderBy>;
  stddev_pop?: InputMaybe<IUserLessonProgressesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IUserLessonProgressesStddevSampOrderBy>;
  sum?: InputMaybe<IUserLessonProgressesSumOrderBy>;
  var_pop?: InputMaybe<IUserLessonProgressesVarPopOrderBy>;
  var_samp?: InputMaybe<IUserLessonProgressesVarSampOrderBy>;
  variance?: InputMaybe<IUserLessonProgressesVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "user_lesson_progresses" */
export interface IUserLessonProgressesArrRelInsertInput {
  data: Array<IUserLessonProgressesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IUserLessonProgressesOnConflict>;
}

/** aggregate avg on columns */
export interface IUserLessonProgressesAvgFields {
  __typename?: 'user_lesson_progresses_avg_fields';
  percentage?: Maybe<Scalars['Float']>;
  time_progress?: Maybe<Scalars['Float']>;
  view_count?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "user_lesson_progresses" */
export interface IUserLessonProgressesAvgOrderBy {
  percentage?: InputMaybe<IOrderBy>;
  time_progress?: InputMaybe<IOrderBy>;
  view_count?: InputMaybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "user_lesson_progresses". All fields are combined with a logical 'AND'. */
export interface IUserLessonProgressesBoolExp {
  _and?: InputMaybe<Array<IUserLessonProgressesBoolExp>>;
  _not?: InputMaybe<IUserLessonProgressesBoolExp>;
  _or?: InputMaybe<Array<IUserLessonProgressesBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  finished?: InputMaybe<IBooleanComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  percentage?: InputMaybe<INumericComparisonExp>;
  time_progress?: InputMaybe<INumericComparisonExp>;
  unlocked_at?: InputMaybe<ITimestamptzComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
  users_child_id?: InputMaybe<IUuidComparisonExp>;
  view_count?: InputMaybe<IIntComparisonExp>;
}

/** unique or primary key constraints on table "user_lesson_progresses" */
export enum IUserLessonProgressesConstraint {
  /** unique or primary key constraint */
  USER_LESSON_PROGRESS_PKEY = 'user_lesson_progress_pkey',
  /** unique or primary key constraint */
  USER_LESSON_PROGRESSES_USER_ID_LESSON_ID_USERS_CHILD_ID_KEY = 'user_lesson_progresses_user_id_lesson_id_users_child_id_key'
}

/** input type for incrementing numeric columns in table "user_lesson_progresses" */
export interface IUserLessonProgressesIncInput {
  percentage?: InputMaybe<Scalars['numeric']>;
  time_progress?: InputMaybe<Scalars['numeric']>;
  view_count?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "user_lesson_progresses" */
export interface IUserLessonProgressesInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  finished?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson?: InputMaybe<ILessonsObjRelInsertInput>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  percentage?: InputMaybe<Scalars['numeric']>;
  time_progress?: InputMaybe<Scalars['numeric']>;
  unlocked_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<IUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']>;
  users_child_id?: InputMaybe<Scalars['uuid']>;
  view_count?: InputMaybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface IUserLessonProgressesMaxFields {
  __typename?: 'user_lesson_progresses_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  percentage?: Maybe<Scalars['numeric']>;
  time_progress?: Maybe<Scalars['numeric']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  users_child_id?: Maybe<Scalars['uuid']>;
  view_count?: Maybe<Scalars['Int']>;
}

/** order by max() on columns of table "user_lesson_progresses" */
export interface IUserLessonProgressesMaxOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  percentage?: InputMaybe<IOrderBy>;
  time_progress?: InputMaybe<IOrderBy>;
  unlocked_at?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  users_child_id?: InputMaybe<IOrderBy>;
  view_count?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IUserLessonProgressesMinFields {
  __typename?: 'user_lesson_progresses_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  percentage?: Maybe<Scalars['numeric']>;
  time_progress?: Maybe<Scalars['numeric']>;
  unlocked_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  users_child_id?: Maybe<Scalars['uuid']>;
  view_count?: Maybe<Scalars['Int']>;
}

/** order by min() on columns of table "user_lesson_progresses" */
export interface IUserLessonProgressesMinOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  percentage?: InputMaybe<IOrderBy>;
  time_progress?: InputMaybe<IOrderBy>;
  unlocked_at?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  users_child_id?: InputMaybe<IOrderBy>;
  view_count?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "user_lesson_progresses" */
export interface IUserLessonProgressesMutationResponse {
  __typename?: 'user_lesson_progresses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IUserLessonProgresses>;
}

/** input type for inserting object relation for remote table "user_lesson_progresses" */
export interface IUserLessonProgressesObjRelInsertInput {
  data: IUserLessonProgressesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IUserLessonProgressesOnConflict>;
}

/** on_conflict condition type for table "user_lesson_progresses" */
export interface IUserLessonProgressesOnConflict {
  constraint: IUserLessonProgressesConstraint;
  update_columns?: Array<IUserLessonProgressesUpdateColumn>;
  where?: InputMaybe<IUserLessonProgressesBoolExp>;
}

/** Ordering options when selecting data from "user_lesson_progresses". */
export interface IUserLessonProgressesOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  finished?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  percentage?: InputMaybe<IOrderBy>;
  time_progress?: InputMaybe<IOrderBy>;
  unlocked_at?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  users_child_id?: InputMaybe<IOrderBy>;
  view_count?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: user_lesson_progresses */
export interface IUserLessonProgressesPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "user_lesson_progresses" */
export enum IUserLessonProgressesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  FINISHED = 'finished',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  PERCENTAGE = 'percentage',
  /** column name */
  TIME_PROGRESS = 'time_progress',
  /** column name */
  UNLOCKED_AT = 'unlocked_at',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  USERS_CHILD_ID = 'users_child_id',
  /** column name */
  VIEW_COUNT = 'view_count'
}

/** input type for updating data in table "user_lesson_progresses" */
export interface IUserLessonProgressesSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  finished?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  percentage?: InputMaybe<Scalars['numeric']>;
  time_progress?: InputMaybe<Scalars['numeric']>;
  unlocked_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  users_child_id?: InputMaybe<Scalars['uuid']>;
  view_count?: InputMaybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface IUserLessonProgressesStddevFields {
  __typename?: 'user_lesson_progresses_stddev_fields';
  percentage?: Maybe<Scalars['Float']>;
  time_progress?: Maybe<Scalars['Float']>;
  view_count?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "user_lesson_progresses" */
export interface IUserLessonProgressesStddevOrderBy {
  percentage?: InputMaybe<IOrderBy>;
  time_progress?: InputMaybe<IOrderBy>;
  view_count?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IUserLessonProgressesStddevPopFields {
  __typename?: 'user_lesson_progresses_stddev_pop_fields';
  percentage?: Maybe<Scalars['Float']>;
  time_progress?: Maybe<Scalars['Float']>;
  view_count?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "user_lesson_progresses" */
export interface IUserLessonProgressesStddevPopOrderBy {
  percentage?: InputMaybe<IOrderBy>;
  time_progress?: InputMaybe<IOrderBy>;
  view_count?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IUserLessonProgressesStddevSampFields {
  __typename?: 'user_lesson_progresses_stddev_samp_fields';
  percentage?: Maybe<Scalars['Float']>;
  time_progress?: Maybe<Scalars['Float']>;
  view_count?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "user_lesson_progresses" */
export interface IUserLessonProgressesStddevSampOrderBy {
  percentage?: InputMaybe<IOrderBy>;
  time_progress?: InputMaybe<IOrderBy>;
  view_count?: InputMaybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IUserLessonProgressesSumFields {
  __typename?: 'user_lesson_progresses_sum_fields';
  percentage?: Maybe<Scalars['numeric']>;
  time_progress?: Maybe<Scalars['numeric']>;
  view_count?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "user_lesson_progresses" */
export interface IUserLessonProgressesSumOrderBy {
  percentage?: InputMaybe<IOrderBy>;
  time_progress?: InputMaybe<IOrderBy>;
  view_count?: InputMaybe<IOrderBy>;
}

/** update columns of table "user_lesson_progresses" */
export enum IUserLessonProgressesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  FINISHED = 'finished',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  PERCENTAGE = 'percentage',
  /** column name */
  TIME_PROGRESS = 'time_progress',
  /** column name */
  UNLOCKED_AT = 'unlocked_at',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  USERS_CHILD_ID = 'users_child_id',
  /** column name */
  VIEW_COUNT = 'view_count'
}

/** aggregate var_pop on columns */
export interface IUserLessonProgressesVarPopFields {
  __typename?: 'user_lesson_progresses_var_pop_fields';
  percentage?: Maybe<Scalars['Float']>;
  time_progress?: Maybe<Scalars['Float']>;
  view_count?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "user_lesson_progresses" */
export interface IUserLessonProgressesVarPopOrderBy {
  percentage?: InputMaybe<IOrderBy>;
  time_progress?: InputMaybe<IOrderBy>;
  view_count?: InputMaybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IUserLessonProgressesVarSampFields {
  __typename?: 'user_lesson_progresses_var_samp_fields';
  percentage?: Maybe<Scalars['Float']>;
  time_progress?: Maybe<Scalars['Float']>;
  view_count?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "user_lesson_progresses" */
export interface IUserLessonProgressesVarSampOrderBy {
  percentage?: InputMaybe<IOrderBy>;
  time_progress?: InputMaybe<IOrderBy>;
  view_count?: InputMaybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IUserLessonProgressesVarianceFields {
  __typename?: 'user_lesson_progresses_variance_fields';
  percentage?: Maybe<Scalars['Float']>;
  time_progress?: Maybe<Scalars['Float']>;
  view_count?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "user_lesson_progresses" */
export interface IUserLessonProgressesVarianceOrderBy {
  percentage?: InputMaybe<IOrderBy>;
  time_progress?: InputMaybe<IOrderBy>;
  view_count?: InputMaybe<IOrderBy>;
}

/** columns and relationships of "user_qa_progresses" */
export interface IUserQaProgresses {
  __typename?: 'user_qa_progresses';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  lesson: ILessons;
  lesson_id: Scalars['uuid'];
  /** An object relationship */
  user: IUsers;
  user_id: Scalars['uuid'];
}

/** aggregated selection of "user_qa_progresses" */
export interface IUserQaProgressesAggregate {
  __typename?: 'user_qa_progresses_aggregate';
  aggregate?: Maybe<IUserQaProgressesAggregateFields>;
  nodes: Array<IUserQaProgresses>;
}

/** aggregate fields of "user_qa_progresses" */
export interface IUserQaProgressesAggregateFields {
  __typename?: 'user_qa_progresses_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IUserQaProgressesMaxFields>;
  min?: Maybe<IUserQaProgressesMinFields>;
}


/** aggregate fields of "user_qa_progresses" */
export interface IUserQaProgressesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IUserQaProgressesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "user_qa_progresses". All fields are combined with a logical 'AND'. */
export interface IUserQaProgressesBoolExp {
  _and?: InputMaybe<Array<IUserQaProgressesBoolExp>>;
  _not?: InputMaybe<IUserQaProgressesBoolExp>;
  _or?: InputMaybe<Array<IUserQaProgressesBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** unique or primary key constraints on table "user_qa_progresses" */
export enum IUserQaProgressesConstraint {
  /** unique or primary key constraint */
  USER_QA_PROGRESSES_PKEY = 'user_qa_progresses_pkey',
  /** unique or primary key constraint */
  USER_QA_PROGRESSES_USER_ID_LESSON_ID_KEY = 'user_qa_progresses_user_id_lesson_id_key'
}

/** input type for inserting data into table "user_qa_progresses" */
export interface IUserQaProgressesInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson?: InputMaybe<ILessonsObjRelInsertInput>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<IUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface IUserQaProgressesMaxFields {
  __typename?: 'user_qa_progresses_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IUserQaProgressesMinFields {
  __typename?: 'user_qa_progresses_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** response of any mutation on the table "user_qa_progresses" */
export interface IUserQaProgressesMutationResponse {
  __typename?: 'user_qa_progresses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IUserQaProgresses>;
}

/** on_conflict condition type for table "user_qa_progresses" */
export interface IUserQaProgressesOnConflict {
  constraint: IUserQaProgressesConstraint;
  update_columns?: Array<IUserQaProgressesUpdateColumn>;
  where?: InputMaybe<IUserQaProgressesBoolExp>;
}

/** Ordering options when selecting data from "user_qa_progresses". */
export interface IUserQaProgressesOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: user_qa_progresses */
export interface IUserQaProgressesPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "user_qa_progresses" */
export enum IUserQaProgressesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  USER_ID = 'user_id'
}

/** input type for updating data in table "user_qa_progresses" */
export interface IUserQaProgressesSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** update columns of table "user_qa_progresses" */
export enum IUserQaProgressesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  USER_ID = 'user_id'
}

/** columns and relationships of "user_third_parts_progresses" */
export interface IUserThirdPartsProgresses {
  __typename?: 'user_third_parts_progresses';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  lesson: ILessons;
  lesson_id: Scalars['uuid'];
  /** An object relationship */
  user: IUsers;
  user_id: Scalars['uuid'];
}

/** aggregated selection of "user_third_parts_progresses" */
export interface IUserThirdPartsProgressesAggregate {
  __typename?: 'user_third_parts_progresses_aggregate';
  aggregate?: Maybe<IUserThirdPartsProgressesAggregateFields>;
  nodes: Array<IUserThirdPartsProgresses>;
}

/** aggregate fields of "user_third_parts_progresses" */
export interface IUserThirdPartsProgressesAggregateFields {
  __typename?: 'user_third_parts_progresses_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IUserThirdPartsProgressesMaxFields>;
  min?: Maybe<IUserThirdPartsProgressesMinFields>;
}


/** aggregate fields of "user_third_parts_progresses" */
export interface IUserThirdPartsProgressesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IUserThirdPartsProgressesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "user_third_parts_progresses". All fields are combined with a logical 'AND'. */
export interface IUserThirdPartsProgressesBoolExp {
  _and?: InputMaybe<Array<IUserThirdPartsProgressesBoolExp>>;
  _not?: InputMaybe<IUserThirdPartsProgressesBoolExp>;
  _or?: InputMaybe<Array<IUserThirdPartsProgressesBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  lesson?: InputMaybe<ILessonsBoolExp>;
  lesson_id?: InputMaybe<IUuidComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
}

/** unique or primary key constraints on table "user_third_parts_progresses" */
export enum IUserThirdPartsProgressesConstraint {
  /** unique or primary key constraint */
  USER_THIRD_PARTS_PROGRESSES_LESSON_ID_USER_ID_KEY = 'user_third_parts_progresses_lesson_id_user_id_key',
  /** unique or primary key constraint */
  USER_THIRD_PARTS_PROGRESSES_PKEY = 'user_third_parts_progresses_pkey'
}

/** input type for inserting data into table "user_third_parts_progresses" */
export interface IUserThirdPartsProgressesInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson?: InputMaybe<ILessonsObjRelInsertInput>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<IUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface IUserThirdPartsProgressesMaxFields {
  __typename?: 'user_third_parts_progresses_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface IUserThirdPartsProgressesMinFields {
  __typename?: 'user_third_parts_progresses_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** response of any mutation on the table "user_third_parts_progresses" */
export interface IUserThirdPartsProgressesMutationResponse {
  __typename?: 'user_third_parts_progresses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IUserThirdPartsProgresses>;
}

/** on_conflict condition type for table "user_third_parts_progresses" */
export interface IUserThirdPartsProgressesOnConflict {
  constraint: IUserThirdPartsProgressesConstraint;
  update_columns?: Array<IUserThirdPartsProgressesUpdateColumn>;
  where?: InputMaybe<IUserThirdPartsProgressesBoolExp>;
}

/** Ordering options when selecting data from "user_third_parts_progresses". */
export interface IUserThirdPartsProgressesOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  lesson?: InputMaybe<ILessonsOrderBy>;
  lesson_id?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: user_third_parts_progresses */
export interface IUserThirdPartsProgressesPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "user_third_parts_progresses" */
export enum IUserThirdPartsProgressesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  USER_ID = 'user_id'
}

/** input type for updating data in table "user_third_parts_progresses" */
export interface IUserThirdPartsProgressesSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lesson_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
}

/** update columns of table "user_third_parts_progresses" */
export enum IUserThirdPartsProgressesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LESSON_ID = 'lesson_id',
  /** column name */
  USER_ID = 'user_id'
}

/** columns and relationships of "users" */
export interface IUsers {
  __typename?: 'users';
  auth0_id: Scalars['String'];
  /** An array relationship */
  children: Array<IUsersChildren>;
  /** An aggregate relationship */
  children_aggregate: IUsersChildrenAggregate;
  content_key: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  last_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  lessons: Array<ILessons>;
  /** An aggregate relationship */
  lessons_aggregate: ILessonsAggregate;
  parent_id?: Maybe<Scalars['uuid']>;
  pincode?: Maybe<Scalars['Int']>;
  relation_to_kid?: Maybe<Scalars['String']>;
  /** An array relationship */
  subscriptions: Array<ISubscriptions>;
  /** An aggregate relationship */
  subscriptions_aggregate: ISubscriptionsAggregate;
  updated_at: Scalars['timestamptz'];
  username?: Maybe<Scalars['String']>;
}


/** columns and relationships of "users" */
export interface IUsersChildrenArgs {
  distinct_on?: InputMaybe<Array<IUsersChildrenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUsersChildrenOrderBy>>;
  where?: InputMaybe<IUsersChildrenBoolExp>;
}


/** columns and relationships of "users" */
export interface IUsersChildrenAggregateArgs {
  distinct_on?: InputMaybe<Array<IUsersChildrenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUsersChildrenOrderBy>>;
  where?: InputMaybe<IUsersChildrenBoolExp>;
}


/** columns and relationships of "users" */
export interface IUsersLessonsArgs {
  distinct_on?: InputMaybe<Array<ILessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsOrderBy>>;
  where?: InputMaybe<ILessonsBoolExp>;
}


/** columns and relationships of "users" */
export interface IUsersLessonsAggregateArgs {
  distinct_on?: InputMaybe<Array<ILessonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ILessonsOrderBy>>;
  where?: InputMaybe<ILessonsBoolExp>;
}


/** columns and relationships of "users" */
export interface IUsersSubscriptionsArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionsOrderBy>>;
  where?: InputMaybe<ISubscriptionsBoolExp>;
}


/** columns and relationships of "users" */
export interface IUsersSubscriptionsAggregateArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionsOrderBy>>;
  where?: InputMaybe<ISubscriptionsBoolExp>;
}

/** aggregated selection of "users" */
export interface IUsersAggregate {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<IUsersAggregateFields>;
  nodes: Array<IUsers>;
}

/** aggregate fields of "users" */
export interface IUsersAggregateFields {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<IUsersAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IUsersMaxFields>;
  min?: Maybe<IUsersMinFields>;
  stddev?: Maybe<IUsersStddevFields>;
  stddev_pop?: Maybe<IUsersStddevPopFields>;
  stddev_samp?: Maybe<IUsersStddevSampFields>;
  sum?: Maybe<IUsersSumFields>;
  var_pop?: Maybe<IUsersVarPopFields>;
  var_samp?: Maybe<IUsersVarSampFields>;
  variance?: Maybe<IUsersVarianceFields>;
}


/** aggregate fields of "users" */
export interface IUsersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IUsersAvgFields {
  __typename?: 'users_avg_fields';
  content_key?: Maybe<Scalars['Float']>;
  pincode?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export interface IUsersBoolExp {
  _and?: InputMaybe<Array<IUsersBoolExp>>;
  _not?: InputMaybe<IUsersBoolExp>;
  _or?: InputMaybe<Array<IUsersBoolExp>>;
  auth0_id?: InputMaybe<IStringComparisonExp>;
  children?: InputMaybe<IUsersChildrenBoolExp>;
  content_key?: InputMaybe<IIntComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  email?: InputMaybe<IStringComparisonExp>;
  first_name?: InputMaybe<IStringComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  last_name?: InputMaybe<IStringComparisonExp>;
  lessons?: InputMaybe<ILessonsBoolExp>;
  parent_id?: InputMaybe<IUuidComparisonExp>;
  pincode?: InputMaybe<IIntComparisonExp>;
  relation_to_kid?: InputMaybe<IStringComparisonExp>;
  subscriptions?: InputMaybe<ISubscriptionsBoolExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  username?: InputMaybe<IStringComparisonExp>;
}

/** columns and relationships of "users_child_details" */
export interface IUsersChildDetails {
  __typename?: 'users_child_details';
  about_me?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  dream_job?: Maybe<Scalars['String']>;
  favorite_animal?: Maybe<Scalars['String']>;
  favorite_book?: Maybe<Scalars['String']>;
  favorite_color?: Maybe<Scalars['String']>;
  favorite_film?: Maybe<Scalars['String']>;
  favorite_food?: Maybe<Scalars['String']>;
  favorite_lesson?: Maybe<Scalars['String']>;
  favorite_school_subject?: Maybe<Scalars['String']>;
  favorite_soerah?: Maybe<Scalars['String']>;
  favorite_sport?: Maybe<Scalars['String']>;
  final_message?: Maybe<Scalars['String']>;
  hero?: Maybe<Scalars['String']>;
  hobbies?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  learned_achievements?: Maybe<Scalars['String']>;
  my_dream?: Maybe<Scalars['String']>;
  thankfull_for?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  users_child: IUsersChildren;
  users_child_id: Scalars['uuid'];
  wish?: Maybe<Scalars['String']>;
}

/** aggregated selection of "users_child_details" */
export interface IUsersChildDetailsAggregate {
  __typename?: 'users_child_details_aggregate';
  aggregate?: Maybe<IUsersChildDetailsAggregateFields>;
  nodes: Array<IUsersChildDetails>;
}

/** aggregate fields of "users_child_details" */
export interface IUsersChildDetailsAggregateFields {
  __typename?: 'users_child_details_aggregate_fields';
  avg?: Maybe<IUsersChildDetailsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IUsersChildDetailsMaxFields>;
  min?: Maybe<IUsersChildDetailsMinFields>;
  stddev?: Maybe<IUsersChildDetailsStddevFields>;
  stddev_pop?: Maybe<IUsersChildDetailsStddevPopFields>;
  stddev_samp?: Maybe<IUsersChildDetailsStddevSampFields>;
  sum?: Maybe<IUsersChildDetailsSumFields>;
  var_pop?: Maybe<IUsersChildDetailsVarPopFields>;
  var_samp?: Maybe<IUsersChildDetailsVarSampFields>;
  variance?: Maybe<IUsersChildDetailsVarianceFields>;
}


/** aggregate fields of "users_child_details" */
export interface IUsersChildDetailsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IUsersChildDetailsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IUsersChildDetailsAvgFields {
  __typename?: 'users_child_details_avg_fields';
  id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "users_child_details". All fields are combined with a logical 'AND'. */
export interface IUsersChildDetailsBoolExp {
  _and?: InputMaybe<Array<IUsersChildDetailsBoolExp>>;
  _not?: InputMaybe<IUsersChildDetailsBoolExp>;
  _or?: InputMaybe<Array<IUsersChildDetailsBoolExp>>;
  about_me?: InputMaybe<IStringComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  dream_job?: InputMaybe<IStringComparisonExp>;
  favorite_animal?: InputMaybe<IStringComparisonExp>;
  favorite_book?: InputMaybe<IStringComparisonExp>;
  favorite_color?: InputMaybe<IStringComparisonExp>;
  favorite_film?: InputMaybe<IStringComparisonExp>;
  favorite_food?: InputMaybe<IStringComparisonExp>;
  favorite_lesson?: InputMaybe<IStringComparisonExp>;
  favorite_school_subject?: InputMaybe<IStringComparisonExp>;
  favorite_soerah?: InputMaybe<IStringComparisonExp>;
  favorite_sport?: InputMaybe<IStringComparisonExp>;
  final_message?: InputMaybe<IStringComparisonExp>;
  hero?: InputMaybe<IStringComparisonExp>;
  hobbies?: InputMaybe<IStringComparisonExp>;
  id?: InputMaybe<IIntComparisonExp>;
  learned_achievements?: InputMaybe<IStringComparisonExp>;
  my_dream?: InputMaybe<IStringComparisonExp>;
  thankfull_for?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  users_child?: InputMaybe<IUsersChildrenBoolExp>;
  users_child_id?: InputMaybe<IUuidComparisonExp>;
  wish?: InputMaybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "users_child_details" */
export enum IUsersChildDetailsConstraint {
  /** unique or primary key constraint */
  USERS_CHILD_DETAILS_PKEY = 'users_child_details_pkey',
  /** unique or primary key constraint */
  USERS_CHILD_DETAILS_USERS_CHILD_ID_KEY = 'users_child_details_users_child_id_key'
}

/** input type for incrementing numeric columns in table "users_child_details" */
export interface IUsersChildDetailsIncInput {
  id?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "users_child_details" */
export interface IUsersChildDetailsInsertInput {
  about_me?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dream_job?: InputMaybe<Scalars['String']>;
  favorite_animal?: InputMaybe<Scalars['String']>;
  favorite_book?: InputMaybe<Scalars['String']>;
  favorite_color?: InputMaybe<Scalars['String']>;
  favorite_film?: InputMaybe<Scalars['String']>;
  favorite_food?: InputMaybe<Scalars['String']>;
  favorite_lesson?: InputMaybe<Scalars['String']>;
  favorite_school_subject?: InputMaybe<Scalars['String']>;
  favorite_soerah?: InputMaybe<Scalars['String']>;
  favorite_sport?: InputMaybe<Scalars['String']>;
  final_message?: InputMaybe<Scalars['String']>;
  hero?: InputMaybe<Scalars['String']>;
  hobbies?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  learned_achievements?: InputMaybe<Scalars['String']>;
  my_dream?: InputMaybe<Scalars['String']>;
  thankfull_for?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  users_child?: InputMaybe<IUsersChildrenObjRelInsertInput>;
  users_child_id?: InputMaybe<Scalars['uuid']>;
  wish?: InputMaybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface IUsersChildDetailsMaxFields {
  __typename?: 'users_child_details_max_fields';
  about_me?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dream_job?: Maybe<Scalars['String']>;
  favorite_animal?: Maybe<Scalars['String']>;
  favorite_book?: Maybe<Scalars['String']>;
  favorite_color?: Maybe<Scalars['String']>;
  favorite_film?: Maybe<Scalars['String']>;
  favorite_food?: Maybe<Scalars['String']>;
  favorite_lesson?: Maybe<Scalars['String']>;
  favorite_school_subject?: Maybe<Scalars['String']>;
  favorite_soerah?: Maybe<Scalars['String']>;
  favorite_sport?: Maybe<Scalars['String']>;
  final_message?: Maybe<Scalars['String']>;
  hero?: Maybe<Scalars['String']>;
  hobbies?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  learned_achievements?: Maybe<Scalars['String']>;
  my_dream?: Maybe<Scalars['String']>;
  thankfull_for?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  users_child_id?: Maybe<Scalars['uuid']>;
  wish?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IUsersChildDetailsMinFields {
  __typename?: 'users_child_details_min_fields';
  about_me?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dream_job?: Maybe<Scalars['String']>;
  favorite_animal?: Maybe<Scalars['String']>;
  favorite_book?: Maybe<Scalars['String']>;
  favorite_color?: Maybe<Scalars['String']>;
  favorite_film?: Maybe<Scalars['String']>;
  favorite_food?: Maybe<Scalars['String']>;
  favorite_lesson?: Maybe<Scalars['String']>;
  favorite_school_subject?: Maybe<Scalars['String']>;
  favorite_soerah?: Maybe<Scalars['String']>;
  favorite_sport?: Maybe<Scalars['String']>;
  final_message?: Maybe<Scalars['String']>;
  hero?: Maybe<Scalars['String']>;
  hobbies?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  learned_achievements?: Maybe<Scalars['String']>;
  my_dream?: Maybe<Scalars['String']>;
  thankfull_for?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  users_child_id?: Maybe<Scalars['uuid']>;
  wish?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "users_child_details" */
export interface IUsersChildDetailsMutationResponse {
  __typename?: 'users_child_details_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IUsersChildDetails>;
}

/** input type for inserting object relation for remote table "users_child_details" */
export interface IUsersChildDetailsObjRelInsertInput {
  data: IUsersChildDetailsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IUsersChildDetailsOnConflict>;
}

/** on_conflict condition type for table "users_child_details" */
export interface IUsersChildDetailsOnConflict {
  constraint: IUsersChildDetailsConstraint;
  update_columns?: Array<IUsersChildDetailsUpdateColumn>;
  where?: InputMaybe<IUsersChildDetailsBoolExp>;
}

/** Ordering options when selecting data from "users_child_details". */
export interface IUsersChildDetailsOrderBy {
  about_me?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  dream_job?: InputMaybe<IOrderBy>;
  favorite_animal?: InputMaybe<IOrderBy>;
  favorite_book?: InputMaybe<IOrderBy>;
  favorite_color?: InputMaybe<IOrderBy>;
  favorite_film?: InputMaybe<IOrderBy>;
  favorite_food?: InputMaybe<IOrderBy>;
  favorite_lesson?: InputMaybe<IOrderBy>;
  favorite_school_subject?: InputMaybe<IOrderBy>;
  favorite_soerah?: InputMaybe<IOrderBy>;
  favorite_sport?: InputMaybe<IOrderBy>;
  final_message?: InputMaybe<IOrderBy>;
  hero?: InputMaybe<IOrderBy>;
  hobbies?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  learned_achievements?: InputMaybe<IOrderBy>;
  my_dream?: InputMaybe<IOrderBy>;
  thankfull_for?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  users_child?: InputMaybe<IUsersChildrenOrderBy>;
  users_child_id?: InputMaybe<IOrderBy>;
  wish?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: users_child_details */
export interface IUsersChildDetailsPkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "users_child_details" */
export enum IUsersChildDetailsSelectColumn {
  /** column name */
  ABOUT_ME = 'about_me',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DREAM_JOB = 'dream_job',
  /** column name */
  FAVORITE_ANIMAL = 'favorite_animal',
  /** column name */
  FAVORITE_BOOK = 'favorite_book',
  /** column name */
  FAVORITE_COLOR = 'favorite_color',
  /** column name */
  FAVORITE_FILM = 'favorite_film',
  /** column name */
  FAVORITE_FOOD = 'favorite_food',
  /** column name */
  FAVORITE_LESSON = 'favorite_lesson',
  /** column name */
  FAVORITE_SCHOOL_SUBJECT = 'favorite_school_subject',
  /** column name */
  FAVORITE_SOERAH = 'favorite_soerah',
  /** column name */
  FAVORITE_SPORT = 'favorite_sport',
  /** column name */
  FINAL_MESSAGE = 'final_message',
  /** column name */
  HERO = 'hero',
  /** column name */
  HOBBIES = 'hobbies',
  /** column name */
  ID = 'id',
  /** column name */
  LEARNED_ACHIEVEMENTS = 'learned_achievements',
  /** column name */
  MY_DREAM = 'my_dream',
  /** column name */
  THANKFULL_FOR = 'thankfull_for',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USERS_CHILD_ID = 'users_child_id',
  /** column name */
  WISH = 'wish'
}

/** input type for updating data in table "users_child_details" */
export interface IUsersChildDetailsSetInput {
  about_me?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dream_job?: InputMaybe<Scalars['String']>;
  favorite_animal?: InputMaybe<Scalars['String']>;
  favorite_book?: InputMaybe<Scalars['String']>;
  favorite_color?: InputMaybe<Scalars['String']>;
  favorite_film?: InputMaybe<Scalars['String']>;
  favorite_food?: InputMaybe<Scalars['String']>;
  favorite_lesson?: InputMaybe<Scalars['String']>;
  favorite_school_subject?: InputMaybe<Scalars['String']>;
  favorite_soerah?: InputMaybe<Scalars['String']>;
  favorite_sport?: InputMaybe<Scalars['String']>;
  final_message?: InputMaybe<Scalars['String']>;
  hero?: InputMaybe<Scalars['String']>;
  hobbies?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  learned_achievements?: InputMaybe<Scalars['String']>;
  my_dream?: InputMaybe<Scalars['String']>;
  thankfull_for?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  users_child_id?: InputMaybe<Scalars['uuid']>;
  wish?: InputMaybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface IUsersChildDetailsStddevFields {
  __typename?: 'users_child_details_stddev_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IUsersChildDetailsStddevPopFields {
  __typename?: 'users_child_details_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IUsersChildDetailsStddevSampFields {
  __typename?: 'users_child_details_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IUsersChildDetailsSumFields {
  __typename?: 'users_child_details_sum_fields';
  id?: Maybe<Scalars['Int']>;
}

/** update columns of table "users_child_details" */
export enum IUsersChildDetailsUpdateColumn {
  /** column name */
  ABOUT_ME = 'about_me',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DREAM_JOB = 'dream_job',
  /** column name */
  FAVORITE_ANIMAL = 'favorite_animal',
  /** column name */
  FAVORITE_BOOK = 'favorite_book',
  /** column name */
  FAVORITE_COLOR = 'favorite_color',
  /** column name */
  FAVORITE_FILM = 'favorite_film',
  /** column name */
  FAVORITE_FOOD = 'favorite_food',
  /** column name */
  FAVORITE_LESSON = 'favorite_lesson',
  /** column name */
  FAVORITE_SCHOOL_SUBJECT = 'favorite_school_subject',
  /** column name */
  FAVORITE_SOERAH = 'favorite_soerah',
  /** column name */
  FAVORITE_SPORT = 'favorite_sport',
  /** column name */
  FINAL_MESSAGE = 'final_message',
  /** column name */
  HERO = 'hero',
  /** column name */
  HOBBIES = 'hobbies',
  /** column name */
  ID = 'id',
  /** column name */
  LEARNED_ACHIEVEMENTS = 'learned_achievements',
  /** column name */
  MY_DREAM = 'my_dream',
  /** column name */
  THANKFULL_FOR = 'thankfull_for',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USERS_CHILD_ID = 'users_child_id',
  /** column name */
  WISH = 'wish'
}

/** aggregate var_pop on columns */
export interface IUsersChildDetailsVarPopFields {
  __typename?: 'users_child_details_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IUsersChildDetailsVarSampFields {
  __typename?: 'users_child_details_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IUsersChildDetailsVarianceFields {
  __typename?: 'users_child_details_variance_fields';
  id?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "users_children" */
export interface IUsersChildren {
  __typename?: 'users_children';
  auth0_id?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  date_of_birth?: Maybe<Scalars['date']>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  open_profile: Scalars['Boolean'];
  /** An object relationship */
  parent?: Maybe<IUsers>;
  /** An array relationship */
  received_friend_requests: Array<IFriendRequests>;
  /** An aggregate relationship */
  received_friend_requests_aggregate: IFriendRequestsAggregate;
  /** An array relationship */
  send_friend_requests: Array<IFriendRequests>;
  /** An aggregate relationship */
  send_friend_requests_aggregate: IFriendRequestsAggregate;
  /** An object relationship */
  stars?: Maybe<IStarsView>;
  /** An object relationship */
  subscription?: Maybe<ISubscriptions>;
  subscription_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  user_children_unlockables: Array<IUserChildrenUnlockables>;
  /** An aggregate relationship */
  user_children_unlockables_aggregate: IUserChildrenUnlockablesAggregate;
  username: Scalars['String'];
  /** An object relationship */
  users_child_detail?: Maybe<IUsersChildDetails>;
}


/** columns and relationships of "users_children" */
export interface IUsersChildrenReceivedFriendRequestsArgs {
  distinct_on?: InputMaybe<Array<IFriendRequestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFriendRequestsOrderBy>>;
  where?: InputMaybe<IFriendRequestsBoolExp>;
}


/** columns and relationships of "users_children" */
export interface IUsersChildrenReceivedFriendRequestsAggregateArgs {
  distinct_on?: InputMaybe<Array<IFriendRequestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFriendRequestsOrderBy>>;
  where?: InputMaybe<IFriendRequestsBoolExp>;
}


/** columns and relationships of "users_children" */
export interface IUsersChildrenSendFriendRequestsArgs {
  distinct_on?: InputMaybe<Array<IFriendRequestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFriendRequestsOrderBy>>;
  where?: InputMaybe<IFriendRequestsBoolExp>;
}


/** columns and relationships of "users_children" */
export interface IUsersChildrenSendFriendRequestsAggregateArgs {
  distinct_on?: InputMaybe<Array<IFriendRequestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IFriendRequestsOrderBy>>;
  where?: InputMaybe<IFriendRequestsBoolExp>;
}


/** columns and relationships of "users_children" */
export interface IUsersChildrenUserChildrenUnlockablesArgs {
  distinct_on?: InputMaybe<Array<IUserChildrenUnlockablesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserChildrenUnlockablesOrderBy>>;
  where?: InputMaybe<IUserChildrenUnlockablesBoolExp>;
}


/** columns and relationships of "users_children" */
export interface IUsersChildrenUserChildrenUnlockablesAggregateArgs {
  distinct_on?: InputMaybe<Array<IUserChildrenUnlockablesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IUserChildrenUnlockablesOrderBy>>;
  where?: InputMaybe<IUserChildrenUnlockablesBoolExp>;
}

/** aggregated selection of "users_children" */
export interface IUsersChildrenAggregate {
  __typename?: 'users_children_aggregate';
  aggregate?: Maybe<IUsersChildrenAggregateFields>;
  nodes: Array<IUsersChildren>;
}

/** aggregate fields of "users_children" */
export interface IUsersChildrenAggregateFields {
  __typename?: 'users_children_aggregate_fields';
  avg?: Maybe<IUsersChildrenAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IUsersChildrenMaxFields>;
  min?: Maybe<IUsersChildrenMinFields>;
  stddev?: Maybe<IUsersChildrenStddevFields>;
  stddev_pop?: Maybe<IUsersChildrenStddevPopFields>;
  stddev_samp?: Maybe<IUsersChildrenStddevSampFields>;
  sum?: Maybe<IUsersChildrenSumFields>;
  var_pop?: Maybe<IUsersChildrenVarPopFields>;
  var_samp?: Maybe<IUsersChildrenVarSampFields>;
  variance?: Maybe<IUsersChildrenVarianceFields>;
}


/** aggregate fields of "users_children" */
export interface IUsersChildrenAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IUsersChildrenSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "users_children" */
export interface IUsersChildrenAggregateOrderBy {
  avg?: InputMaybe<IUsersChildrenAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IUsersChildrenMaxOrderBy>;
  min?: InputMaybe<IUsersChildrenMinOrderBy>;
  stddev?: InputMaybe<IUsersChildrenStddevOrderBy>;
  stddev_pop?: InputMaybe<IUsersChildrenStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IUsersChildrenStddevSampOrderBy>;
  sum?: InputMaybe<IUsersChildrenSumOrderBy>;
  var_pop?: InputMaybe<IUsersChildrenVarPopOrderBy>;
  var_samp?: InputMaybe<IUsersChildrenVarSampOrderBy>;
  variance?: InputMaybe<IUsersChildrenVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "users_children" */
export interface IUsersChildrenArrRelInsertInput {
  data: Array<IUsersChildrenInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IUsersChildrenOnConflict>;
}

/** aggregate avg on columns */
export interface IUsersChildrenAvgFields {
  __typename?: 'users_children_avg_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "users_children" */
export interface IUsersChildrenAvgOrderBy {
  content_key?: InputMaybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "users_children". All fields are combined with a logical 'AND'. */
export interface IUsersChildrenBoolExp {
  _and?: InputMaybe<Array<IUsersChildrenBoolExp>>;
  _not?: InputMaybe<IUsersChildrenBoolExp>;
  _or?: InputMaybe<Array<IUsersChildrenBoolExp>>;
  auth0_id?: InputMaybe<IStringComparisonExp>;
  avatar?: InputMaybe<IStringComparisonExp>;
  content_key?: InputMaybe<IIntComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  date_of_birth?: InputMaybe<IDateComparisonExp>;
  gender?: InputMaybe<IStringComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  name?: InputMaybe<IStringComparisonExp>;
  open_profile?: InputMaybe<IBooleanComparisonExp>;
  parent?: InputMaybe<IUsersBoolExp>;
  received_friend_requests?: InputMaybe<IFriendRequestsBoolExp>;
  send_friend_requests?: InputMaybe<IFriendRequestsBoolExp>;
  stars?: InputMaybe<IStarsViewBoolExp>;
  subscription?: InputMaybe<ISubscriptionsBoolExp>;
  subscription_id?: InputMaybe<IUuidComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  user_children_unlockables?: InputMaybe<IUserChildrenUnlockablesBoolExp>;
  username?: InputMaybe<IStringComparisonExp>;
  users_child_detail?: InputMaybe<IUsersChildDetailsBoolExp>;
}

/** unique or primary key constraints on table "users_children" */
export enum IUsersChildrenConstraint {
  /** unique or primary key constraint */
  USERS_CHILDREN_CONTENT_KEY_KEY = 'users_children_content_key_key',
  /** unique or primary key constraint */
  USERS_CHILDREN_NAME_KEY = 'users_children_name_key',
  /** unique or primary key constraint */
  USERS_CHILDREN_PKEY = 'users_children_pkey',
  /** unique or primary key constraint */
  USERS_CHILDREN_SUBSCRIPTION_ID_USERNAME_KEY = 'users_children_subscription_id_username_key'
}

/** input type for incrementing numeric columns in table "users_children" */
export interface IUsersChildrenIncInput {
  content_key?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "users_children" */
export interface IUsersChildrenInsertInput {
  auth0_id?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<Scalars['String']>;
  content_key?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date_of_birth?: InputMaybe<Scalars['date']>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  open_profile?: InputMaybe<Scalars['Boolean']>;
  parent?: InputMaybe<IUsersObjRelInsertInput>;
  received_friend_requests?: InputMaybe<IFriendRequestsArrRelInsertInput>;
  send_friend_requests?: InputMaybe<IFriendRequestsArrRelInsertInput>;
  stars?: InputMaybe<IStarsViewObjRelInsertInput>;
  subscription?: InputMaybe<ISubscriptionsObjRelInsertInput>;
  subscription_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_children_unlockables?: InputMaybe<IUserChildrenUnlockablesArrRelInsertInput>;
  username?: InputMaybe<Scalars['String']>;
  users_child_detail?: InputMaybe<IUsersChildDetailsObjRelInsertInput>;
}

/** aggregate max on columns */
export interface IUsersChildrenMaxFields {
  __typename?: 'users_children_max_fields';
  auth0_id?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "users_children" */
export interface IUsersChildrenMaxOrderBy {
  auth0_id?: InputMaybe<IOrderBy>;
  avatar?: InputMaybe<IOrderBy>;
  content_key?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  date_of_birth?: InputMaybe<IOrderBy>;
  gender?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  subscription_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  username?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IUsersChildrenMinFields {
  __typename?: 'users_children_min_fields';
  auth0_id?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "users_children" */
export interface IUsersChildrenMinOrderBy {
  auth0_id?: InputMaybe<IOrderBy>;
  avatar?: InputMaybe<IOrderBy>;
  content_key?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  date_of_birth?: InputMaybe<IOrderBy>;
  gender?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  subscription_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  username?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "users_children" */
export interface IUsersChildrenMutationResponse {
  __typename?: 'users_children_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IUsersChildren>;
}

/** input type for inserting object relation for remote table "users_children" */
export interface IUsersChildrenObjRelInsertInput {
  data: IUsersChildrenInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IUsersChildrenOnConflict>;
}

/** on_conflict condition type for table "users_children" */
export interface IUsersChildrenOnConflict {
  constraint: IUsersChildrenConstraint;
  update_columns?: Array<IUsersChildrenUpdateColumn>;
  where?: InputMaybe<IUsersChildrenBoolExp>;
}

/** Ordering options when selecting data from "users_children". */
export interface IUsersChildrenOrderBy {
  auth0_id?: InputMaybe<IOrderBy>;
  avatar?: InputMaybe<IOrderBy>;
  content_key?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  date_of_birth?: InputMaybe<IOrderBy>;
  gender?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  name?: InputMaybe<IOrderBy>;
  open_profile?: InputMaybe<IOrderBy>;
  parent?: InputMaybe<IUsersOrderBy>;
  received_friend_requests_aggregate?: InputMaybe<IFriendRequestsAggregateOrderBy>;
  send_friend_requests_aggregate?: InputMaybe<IFriendRequestsAggregateOrderBy>;
  stars?: InputMaybe<IStarsViewOrderBy>;
  subscription?: InputMaybe<ISubscriptionsOrderBy>;
  subscription_id?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user_children_unlockables_aggregate?: InputMaybe<IUserChildrenUnlockablesAggregateOrderBy>;
  username?: InputMaybe<IOrderBy>;
  users_child_detail?: InputMaybe<IUsersChildDetailsOrderBy>;
}

/** primary key columns input for table: users_children */
export interface IUsersChildrenPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "users_children" */
export enum IUsersChildrenSelectColumn {
  /** column name */
  AUTH0_ID = 'auth0_id',
  /** column name */
  AVATAR = 'avatar',
  /** column name */
  CONTENT_KEY = 'content_key',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DATE_OF_BIRTH = 'date_of_birth',
  /** column name */
  GENDER = 'gender',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  OPEN_PROFILE = 'open_profile',
  /** column name */
  SUBSCRIPTION_ID = 'subscription_id',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USERNAME = 'username'
}

/** input type for updating data in table "users_children" */
export interface IUsersChildrenSetInput {
  auth0_id?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<Scalars['String']>;
  content_key?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date_of_birth?: InputMaybe<Scalars['date']>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  open_profile?: InputMaybe<Scalars['Boolean']>;
  subscription_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface IUsersChildrenStddevFields {
  __typename?: 'users_children_stddev_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "users_children" */
export interface IUsersChildrenStddevOrderBy {
  content_key?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IUsersChildrenStddevPopFields {
  __typename?: 'users_children_stddev_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "users_children" */
export interface IUsersChildrenStddevPopOrderBy {
  content_key?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IUsersChildrenStddevSampFields {
  __typename?: 'users_children_stddev_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "users_children" */
export interface IUsersChildrenStddevSampOrderBy {
  content_key?: InputMaybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IUsersChildrenSumFields {
  __typename?: 'users_children_sum_fields';
  content_key?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "users_children" */
export interface IUsersChildrenSumOrderBy {
  content_key?: InputMaybe<IOrderBy>;
}

/** update columns of table "users_children" */
export enum IUsersChildrenUpdateColumn {
  /** column name */
  AUTH0_ID = 'auth0_id',
  /** column name */
  AVATAR = 'avatar',
  /** column name */
  CONTENT_KEY = 'content_key',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DATE_OF_BIRTH = 'date_of_birth',
  /** column name */
  GENDER = 'gender',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  OPEN_PROFILE = 'open_profile',
  /** column name */
  SUBSCRIPTION_ID = 'subscription_id',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USERNAME = 'username'
}

/** aggregate var_pop on columns */
export interface IUsersChildrenVarPopFields {
  __typename?: 'users_children_var_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "users_children" */
export interface IUsersChildrenVarPopOrderBy {
  content_key?: InputMaybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IUsersChildrenVarSampFields {
  __typename?: 'users_children_var_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "users_children" */
export interface IUsersChildrenVarSampOrderBy {
  content_key?: InputMaybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IUsersChildrenVarianceFields {
  __typename?: 'users_children_variance_fields';
  content_key?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "users_children" */
export interface IUsersChildrenVarianceOrderBy {
  content_key?: InputMaybe<IOrderBy>;
}

/** unique or primary key constraints on table "users" */
export enum IUsersConstraint {
  /** unique or primary key constraint */
  USERS_AUTH0_ID_KEY = 'users_auth0_id_key',
  /** unique or primary key constraint */
  USERS_PKEY = 'users_pkey'
}

/** input type for incrementing numeric columns in table "users" */
export interface IUsersIncInput {
  content_key?: InputMaybe<Scalars['Int']>;
  pincode?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "users" */
export interface IUsersInsertInput {
  auth0_id?: InputMaybe<Scalars['String']>;
  children?: InputMaybe<IUsersChildrenArrRelInsertInput>;
  content_key?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  lessons?: InputMaybe<ILessonsArrRelInsertInput>;
  parent_id?: InputMaybe<Scalars['uuid']>;
  pincode?: InputMaybe<Scalars['Int']>;
  relation_to_kid?: InputMaybe<Scalars['String']>;
  subscriptions?: InputMaybe<ISubscriptionsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface IUsersMaxFields {
  __typename?: 'users_max_fields';
  auth0_id?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
  pincode?: Maybe<Scalars['Int']>;
  relation_to_kid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IUsersMinFields {
  __typename?: 'users_min_fields';
  auth0_id?: Maybe<Scalars['String']>;
  content_key?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
  pincode?: Maybe<Scalars['Int']>;
  relation_to_kid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "users" */
export interface IUsersMutationResponse {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IUsers>;
}

/** input type for inserting object relation for remote table "users" */
export interface IUsersObjRelInsertInput {
  data: IUsersInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IUsersOnConflict>;
}

/** on_conflict condition type for table "users" */
export interface IUsersOnConflict {
  constraint: IUsersConstraint;
  update_columns?: Array<IUsersUpdateColumn>;
  where?: InputMaybe<IUsersBoolExp>;
}

/** Ordering options when selecting data from "users". */
export interface IUsersOrderBy {
  auth0_id?: InputMaybe<IOrderBy>;
  children_aggregate?: InputMaybe<IUsersChildrenAggregateOrderBy>;
  content_key?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  email?: InputMaybe<IOrderBy>;
  first_name?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  last_name?: InputMaybe<IOrderBy>;
  lessons_aggregate?: InputMaybe<ILessonsAggregateOrderBy>;
  parent_id?: InputMaybe<IOrderBy>;
  pincode?: InputMaybe<IOrderBy>;
  relation_to_kid?: InputMaybe<IOrderBy>;
  subscriptions_aggregate?: InputMaybe<ISubscriptionsAggregateOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  username?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: users */
export interface IUsersPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "users" */
export enum IUsersSelectColumn {
  /** column name */
  AUTH0_ID = 'auth0_id',
  /** column name */
  CONTENT_KEY = 'content_key',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  EMAIL = 'email',
  /** column name */
  FIRST_NAME = 'first_name',
  /** column name */
  ID = 'id',
  /** column name */
  LAST_NAME = 'last_name',
  /** column name */
  PARENT_ID = 'parent_id',
  /** column name */
  PINCODE = 'pincode',
  /** column name */
  RELATION_TO_KID = 'relation_to_kid',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USERNAME = 'username'
}

/** columns and relationships of "users_sessions" */
export interface IUsersSessions {
  __typename?: 'users_sessions';
  auth0id: Scalars['String'];
  id: Scalars['uuid'];
  ip: Scalars['String'];
  last_seen_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<IUsers>;
}

/** aggregated selection of "users_sessions" */
export interface IUsersSessionsAggregate {
  __typename?: 'users_sessions_aggregate';
  aggregate?: Maybe<IUsersSessionsAggregateFields>;
  nodes: Array<IUsersSessions>;
}

/** aggregate fields of "users_sessions" */
export interface IUsersSessionsAggregateFields {
  __typename?: 'users_sessions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IUsersSessionsMaxFields>;
  min?: Maybe<IUsersSessionsMinFields>;
}


/** aggregate fields of "users_sessions" */
export interface IUsersSessionsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IUsersSessionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "users_sessions". All fields are combined with a logical 'AND'. */
export interface IUsersSessionsBoolExp {
  _and?: InputMaybe<Array<IUsersSessionsBoolExp>>;
  _not?: InputMaybe<IUsersSessionsBoolExp>;
  _or?: InputMaybe<Array<IUsersSessionsBoolExp>>;
  auth0id?: InputMaybe<IStringComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  ip?: InputMaybe<IStringComparisonExp>;
  last_seen_at?: InputMaybe<ITimestamptzComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
}

/** unique or primary key constraints on table "users_sessions" */
export enum IUsersSessionsConstraint {
  /** unique or primary key constraint */
  USERS_SESSIONS_AUTH0ID_IP_KEY = 'users_sessions_auth0id_ip_key',
  /** unique or primary key constraint */
  USERS_SESSIONS_PKEY = 'users_sessions_pkey'
}

/** input type for inserting data into table "users_sessions" */
export interface IUsersSessionsInsertInput {
  auth0id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  ip?: InputMaybe<Scalars['String']>;
  last_seen_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<IUsersObjRelInsertInput>;
}

/** aggregate max on columns */
export interface IUsersSessionsMaxFields {
  __typename?: 'users_sessions_max_fields';
  auth0id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ip?: Maybe<Scalars['String']>;
  last_seen_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface IUsersSessionsMinFields {
  __typename?: 'users_sessions_min_fields';
  auth0id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ip?: Maybe<Scalars['String']>;
  last_seen_at?: Maybe<Scalars['timestamptz']>;
}

/** response of any mutation on the table "users_sessions" */
export interface IUsersSessionsMutationResponse {
  __typename?: 'users_sessions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IUsersSessions>;
}

/** on_conflict condition type for table "users_sessions" */
export interface IUsersSessionsOnConflict {
  constraint: IUsersSessionsConstraint;
  update_columns?: Array<IUsersSessionsUpdateColumn>;
  where?: InputMaybe<IUsersSessionsBoolExp>;
}

/** Ordering options when selecting data from "users_sessions". */
export interface IUsersSessionsOrderBy {
  auth0id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  ip?: InputMaybe<IOrderBy>;
  last_seen_at?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
}

/** primary key columns input for table: users_sessions */
export interface IUsersSessionsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "users_sessions" */
export enum IUsersSessionsSelectColumn {
  /** column name */
  AUTH0ID = 'auth0id',
  /** column name */
  ID = 'id',
  /** column name */
  IP = 'ip',
  /** column name */
  LAST_SEEN_AT = 'last_seen_at'
}

/** input type for updating data in table "users_sessions" */
export interface IUsersSessionsSetInput {
  auth0id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  ip?: InputMaybe<Scalars['String']>;
  last_seen_at?: InputMaybe<Scalars['timestamptz']>;
}

/** update columns of table "users_sessions" */
export enum IUsersSessionsUpdateColumn {
  /** column name */
  AUTH0ID = 'auth0id',
  /** column name */
  ID = 'id',
  /** column name */
  IP = 'ip',
  /** column name */
  LAST_SEEN_AT = 'last_seen_at'
}

/** input type for updating data in table "users" */
export interface IUsersSetInput {
  auth0_id?: InputMaybe<Scalars['String']>;
  content_key?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  parent_id?: InputMaybe<Scalars['uuid']>;
  pincode?: InputMaybe<Scalars['Int']>;
  relation_to_kid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface IUsersStddevFields {
  __typename?: 'users_stddev_fields';
  content_key?: Maybe<Scalars['Float']>;
  pincode?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IUsersStddevPopFields {
  __typename?: 'users_stddev_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
  pincode?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IUsersStddevSampFields {
  __typename?: 'users_stddev_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
  pincode?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IUsersSumFields {
  __typename?: 'users_sum_fields';
  content_key?: Maybe<Scalars['Int']>;
  pincode?: Maybe<Scalars['Int']>;
}

/** update columns of table "users" */
export enum IUsersUpdateColumn {
  /** column name */
  AUTH0_ID = 'auth0_id',
  /** column name */
  CONTENT_KEY = 'content_key',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  EMAIL = 'email',
  /** column name */
  FIRST_NAME = 'first_name',
  /** column name */
  ID = 'id',
  /** column name */
  LAST_NAME = 'last_name',
  /** column name */
  PARENT_ID = 'parent_id',
  /** column name */
  PINCODE = 'pincode',
  /** column name */
  RELATION_TO_KID = 'relation_to_kid',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USERNAME = 'username'
}

/** aggregate var_pop on columns */
export interface IUsersVarPopFields {
  __typename?: 'users_var_pop_fields';
  content_key?: Maybe<Scalars['Float']>;
  pincode?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IUsersVarSampFields {
  __typename?: 'users_var_samp_fields';
  content_key?: Maybe<Scalars['Float']>;
  pincode?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IUsersVarianceFields {
  __typename?: 'users_variance_fields';
  content_key?: Maybe<Scalars['Float']>;
  pincode?: Maybe<Scalars['Float']>;
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export interface IUuidComparisonExp {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
}

/** columns and relationships of "vacation_group_types" */
export interface IVacationGroupTypes {
  __typename?: 'vacation_group_types';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  group_type: IGroupTypes;
  group_type_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  vacation: IVacations;
  vacation_id: Scalars['uuid'];
}

/** aggregated selection of "vacation_group_types" */
export interface IVacationGroupTypesAggregate {
  __typename?: 'vacation_group_types_aggregate';
  aggregate?: Maybe<IVacationGroupTypesAggregateFields>;
  nodes: Array<IVacationGroupTypes>;
}

/** aggregate fields of "vacation_group_types" */
export interface IVacationGroupTypesAggregateFields {
  __typename?: 'vacation_group_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IVacationGroupTypesMaxFields>;
  min?: Maybe<IVacationGroupTypesMinFields>;
}


/** aggregate fields of "vacation_group_types" */
export interface IVacationGroupTypesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IVacationGroupTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "vacation_group_types" */
export interface IVacationGroupTypesAggregateOrderBy {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IVacationGroupTypesMaxOrderBy>;
  min?: InputMaybe<IVacationGroupTypesMinOrderBy>;
}

/** input type for inserting array relation for remote table "vacation_group_types" */
export interface IVacationGroupTypesArrRelInsertInput {
  data: Array<IVacationGroupTypesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IVacationGroupTypesOnConflict>;
}

/** Boolean expression to filter rows from the table "vacation_group_types". All fields are combined with a logical 'AND'. */
export interface IVacationGroupTypesBoolExp {
  _and?: InputMaybe<Array<IVacationGroupTypesBoolExp>>;
  _not?: InputMaybe<IVacationGroupTypesBoolExp>;
  _or?: InputMaybe<Array<IVacationGroupTypesBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  group_type?: InputMaybe<IGroupTypesBoolExp>;
  group_type_id?: InputMaybe<IUuidComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  vacation?: InputMaybe<IVacationsBoolExp>;
  vacation_id?: InputMaybe<IUuidComparisonExp>;
}

/** unique or primary key constraints on table "vacation_group_types" */
export enum IVacationGroupTypesConstraint {
  /** unique or primary key constraint */
  VACATION_GROUP_TYPES_GROUP_TYPE_ID_VACATION_ID_KEY = 'vacation_group_types_group_type_id_vacation_id_key',
  /** unique or primary key constraint */
  VACATION_GROUP_TYPES_PKEY = 'vacation_group_types_pkey'
}

/** input type for inserting data into table "vacation_group_types" */
export interface IVacationGroupTypesInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group_type?: InputMaybe<IGroupTypesObjRelInsertInput>;
  group_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  vacation?: InputMaybe<IVacationsObjRelInsertInput>;
  vacation_id?: InputMaybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface IVacationGroupTypesMaxFields {
  __typename?: 'vacation_group_types_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  vacation_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "vacation_group_types" */
export interface IVacationGroupTypesMaxOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  group_type_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  vacation_id?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IVacationGroupTypesMinFields {
  __typename?: 'vacation_group_types_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  vacation_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "vacation_group_types" */
export interface IVacationGroupTypesMinOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  group_type_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  vacation_id?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "vacation_group_types" */
export interface IVacationGroupTypesMutationResponse {
  __typename?: 'vacation_group_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IVacationGroupTypes>;
}

/** on_conflict condition type for table "vacation_group_types" */
export interface IVacationGroupTypesOnConflict {
  constraint: IVacationGroupTypesConstraint;
  update_columns?: Array<IVacationGroupTypesUpdateColumn>;
  where?: InputMaybe<IVacationGroupTypesBoolExp>;
}

/** Ordering options when selecting data from "vacation_group_types". */
export interface IVacationGroupTypesOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  group_type?: InputMaybe<IGroupTypesOrderBy>;
  group_type_id?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  vacation?: InputMaybe<IVacationsOrderBy>;
  vacation_id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: vacation_group_types */
export interface IVacationGroupTypesPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "vacation_group_types" */
export enum IVacationGroupTypesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  GROUP_TYPE_ID = 'group_type_id',
  /** column name */
  ID = 'id',
  /** column name */
  VACATION_ID = 'vacation_id'
}

/** input type for updating data in table "vacation_group_types" */
export interface IVacationGroupTypesSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  vacation_id?: InputMaybe<Scalars['uuid']>;
}

/** update columns of table "vacation_group_types" */
export enum IVacationGroupTypesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  GROUP_TYPE_ID = 'group_type_id',
  /** column name */
  ID = 'id',
  /** column name */
  VACATION_ID = 'vacation_id'
}

/** columns and relationships of "vacations" */
export interface IVacations {
  __typename?: 'vacations';
  created_at: Scalars['timestamptz'];
  ends_on: Scalars['date'];
  id: Scalars['uuid'];
  starts_on: Scalars['date'];
  /** An array relationship */
  vacation_group_types: Array<IVacationGroupTypes>;
  /** An aggregate relationship */
  vacation_group_types_aggregate: IVacationGroupTypesAggregate;
}


/** columns and relationships of "vacations" */
export interface IVacationsVacationGroupTypesArgs {
  distinct_on?: InputMaybe<Array<IVacationGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IVacationGroupTypesOrderBy>>;
  where?: InputMaybe<IVacationGroupTypesBoolExp>;
}


/** columns and relationships of "vacations" */
export interface IVacationsVacationGroupTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<IVacationGroupTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IVacationGroupTypesOrderBy>>;
  where?: InputMaybe<IVacationGroupTypesBoolExp>;
}

/** aggregated selection of "vacations" */
export interface IVacationsAggregate {
  __typename?: 'vacations_aggregate';
  aggregate?: Maybe<IVacationsAggregateFields>;
  nodes: Array<IVacations>;
}

/** aggregate fields of "vacations" */
export interface IVacationsAggregateFields {
  __typename?: 'vacations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IVacationsMaxFields>;
  min?: Maybe<IVacationsMinFields>;
}


/** aggregate fields of "vacations" */
export interface IVacationsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IVacationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "vacations". All fields are combined with a logical 'AND'. */
export interface IVacationsBoolExp {
  _and?: InputMaybe<Array<IVacationsBoolExp>>;
  _not?: InputMaybe<IVacationsBoolExp>;
  _or?: InputMaybe<Array<IVacationsBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  ends_on?: InputMaybe<IDateComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  starts_on?: InputMaybe<IDateComparisonExp>;
  vacation_group_types?: InputMaybe<IVacationGroupTypesBoolExp>;
}

/** unique or primary key constraints on table "vacations" */
export enum IVacationsConstraint {
  /** unique or primary key constraint */
  VACATIONS_PKEY = 'vacations_pkey'
}

/** input type for inserting data into table "vacations" */
export interface IVacationsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ends_on?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  starts_on?: InputMaybe<Scalars['date']>;
  vacation_group_types?: InputMaybe<IVacationGroupTypesArrRelInsertInput>;
}

/** aggregate max on columns */
export interface IVacationsMaxFields {
  __typename?: 'vacations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ends_on?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  starts_on?: Maybe<Scalars['date']>;
}

/** aggregate min on columns */
export interface IVacationsMinFields {
  __typename?: 'vacations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ends_on?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  starts_on?: Maybe<Scalars['date']>;
}

/** response of any mutation on the table "vacations" */
export interface IVacationsMutationResponse {
  __typename?: 'vacations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IVacations>;
}

/** input type for inserting object relation for remote table "vacations" */
export interface IVacationsObjRelInsertInput {
  data: IVacationsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IVacationsOnConflict>;
}

/** on_conflict condition type for table "vacations" */
export interface IVacationsOnConflict {
  constraint: IVacationsConstraint;
  update_columns?: Array<IVacationsUpdateColumn>;
  where?: InputMaybe<IVacationsBoolExp>;
}

/** Ordering options when selecting data from "vacations". */
export interface IVacationsOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  ends_on?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  starts_on?: InputMaybe<IOrderBy>;
  vacation_group_types_aggregate?: InputMaybe<IVacationGroupTypesAggregateOrderBy>;
}

/** primary key columns input for table: vacations */
export interface IVacationsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "vacations" */
export enum IVacationsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ENDS_ON = 'ends_on',
  /** column name */
  ID = 'id',
  /** column name */
  STARTS_ON = 'starts_on'
}

/** input type for updating data in table "vacations" */
export interface IVacationsSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ends_on?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  starts_on?: InputMaybe<Scalars['date']>;
}

/** update columns of table "vacations" */
export enum IVacationsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ENDS_ON = 'ends_on',
  /** column name */
  ID = 'id',
  /** column name */
  STARTS_ON = 'starts_on'
}

/** columns and relationships of "weekly_facts" */
export interface IWeeklyFacts {
  __typename?: 'weekly_facts';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  message: Scalars['String'];
  sequence?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
}

/** aggregated selection of "weekly_facts" */
export interface IWeeklyFactsAggregate {
  __typename?: 'weekly_facts_aggregate';
  aggregate?: Maybe<IWeeklyFactsAggregateFields>;
  nodes: Array<IWeeklyFacts>;
}

/** aggregate fields of "weekly_facts" */
export interface IWeeklyFactsAggregateFields {
  __typename?: 'weekly_facts_aggregate_fields';
  avg?: Maybe<IWeeklyFactsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IWeeklyFactsMaxFields>;
  min?: Maybe<IWeeklyFactsMinFields>;
  stddev?: Maybe<IWeeklyFactsStddevFields>;
  stddev_pop?: Maybe<IWeeklyFactsStddevPopFields>;
  stddev_samp?: Maybe<IWeeklyFactsStddevSampFields>;
  sum?: Maybe<IWeeklyFactsSumFields>;
  var_pop?: Maybe<IWeeklyFactsVarPopFields>;
  var_samp?: Maybe<IWeeklyFactsVarSampFields>;
  variance?: Maybe<IWeeklyFactsVarianceFields>;
}


/** aggregate fields of "weekly_facts" */
export interface IWeeklyFactsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IWeeklyFactsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IWeeklyFactsAvgFields {
  __typename?: 'weekly_facts_avg_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "weekly_facts". All fields are combined with a logical 'AND'. */
export interface IWeeklyFactsBoolExp {
  _and?: InputMaybe<Array<IWeeklyFactsBoolExp>>;
  _not?: InputMaybe<IWeeklyFactsBoolExp>;
  _or?: InputMaybe<Array<IWeeklyFactsBoolExp>>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  message?: InputMaybe<IStringComparisonExp>;
  sequence?: InputMaybe<IIntComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "weekly_facts" */
export enum IWeeklyFactsConstraint {
  /** unique or primary key constraint */
  WEEKLY_FACTS_PKEY = 'weekly_facts_pkey'
}

/** input type for incrementing numeric columns in table "weekly_facts" */
export interface IWeeklyFactsIncInput {
  sequence?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "weekly_facts" */
export interface IWeeklyFactsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface IWeeklyFactsMaxFields {
  __typename?: 'weekly_facts_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface IWeeklyFactsMinFields {
  __typename?: 'weekly_facts_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** response of any mutation on the table "weekly_facts" */
export interface IWeeklyFactsMutationResponse {
  __typename?: 'weekly_facts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IWeeklyFacts>;
}

/** on_conflict condition type for table "weekly_facts" */
export interface IWeeklyFactsOnConflict {
  constraint: IWeeklyFactsConstraint;
  update_columns?: Array<IWeeklyFactsUpdateColumn>;
  where?: InputMaybe<IWeeklyFactsBoolExp>;
}

/** Ordering options when selecting data from "weekly_facts". */
export interface IWeeklyFactsOrderBy {
  created_at?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  message?: InputMaybe<IOrderBy>;
  sequence?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: weekly_facts */
export interface IWeeklyFactsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "weekly_facts" */
export enum IWeeklyFactsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  MESSAGE = 'message',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "weekly_facts" */
export interface IWeeklyFactsSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface IWeeklyFactsStddevFields {
  __typename?: 'weekly_facts_stddev_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IWeeklyFactsStddevPopFields {
  __typename?: 'weekly_facts_stddev_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IWeeklyFactsStddevSampFields {
  __typename?: 'weekly_facts_stddev_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IWeeklyFactsSumFields {
  __typename?: 'weekly_facts_sum_fields';
  sequence?: Maybe<Scalars['Int']>;
}

/** update columns of table "weekly_facts" */
export enum IWeeklyFactsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  MESSAGE = 'message',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IWeeklyFactsVarPopFields {
  __typename?: 'weekly_facts_var_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IWeeklyFactsVarSampFields {
  __typename?: 'weekly_facts_var_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IWeeklyFactsVarianceFields {
  __typename?: 'weekly_facts_variance_fields';
  sequence?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "wp_orders" */
export interface IWpOrders {
  __typename?: 'wp_orders';
  confirmation_sent_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  course?: Maybe<ICourses>;
  created_at: Scalars['timestamptz'];
  credits: Scalars['numeric'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['uuid'];
  last_name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  raw_payload?: Maybe<Scalars['jsonb']>;
  resend_confirmation?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  /** An array relationship */
  subscriptions: Array<ISubscriptions>;
  /** An aggregate relationship */
  subscriptions_aggregate: ISubscriptionsAggregate;
  updated_at: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['uuid']>;
  wp_order_id: Scalars['Int'];
  wp_order_key: Scalars['String'];
  wp_order_number: Scalars['String'];
  /** An object relationship */
  wp_product?: Maybe<IWpProducts>;
  wp_product_id: Scalars['Int'];
}


/** columns and relationships of "wp_orders" */
export interface IWpOrdersRawPayloadArgs {
  path?: InputMaybe<Scalars['String']>;
}


/** columns and relationships of "wp_orders" */
export interface IWpOrdersSubscriptionsArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionsOrderBy>>;
  where?: InputMaybe<ISubscriptionsBoolExp>;
}


/** columns and relationships of "wp_orders" */
export interface IWpOrdersSubscriptionsAggregateArgs {
  distinct_on?: InputMaybe<Array<ISubscriptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ISubscriptionsOrderBy>>;
  where?: InputMaybe<ISubscriptionsBoolExp>;
}

/** aggregated selection of "wp_orders" */
export interface IWpOrdersAggregate {
  __typename?: 'wp_orders_aggregate';
  aggregate?: Maybe<IWpOrdersAggregateFields>;
  nodes: Array<IWpOrders>;
}

/** aggregate fields of "wp_orders" */
export interface IWpOrdersAggregateFields {
  __typename?: 'wp_orders_aggregate_fields';
  avg?: Maybe<IWpOrdersAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IWpOrdersMaxFields>;
  min?: Maybe<IWpOrdersMinFields>;
  stddev?: Maybe<IWpOrdersStddevFields>;
  stddev_pop?: Maybe<IWpOrdersStddevPopFields>;
  stddev_samp?: Maybe<IWpOrdersStddevSampFields>;
  sum?: Maybe<IWpOrdersSumFields>;
  var_pop?: Maybe<IWpOrdersVarPopFields>;
  var_samp?: Maybe<IWpOrdersVarSampFields>;
  variance?: Maybe<IWpOrdersVarianceFields>;
}


/** aggregate fields of "wp_orders" */
export interface IWpOrdersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IWpOrdersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "wp_orders" */
export interface IWpOrdersAggregateOrderBy {
  avg?: InputMaybe<IWpOrdersAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IWpOrdersMaxOrderBy>;
  min?: InputMaybe<IWpOrdersMinOrderBy>;
  stddev?: InputMaybe<IWpOrdersStddevOrderBy>;
  stddev_pop?: InputMaybe<IWpOrdersStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IWpOrdersStddevSampOrderBy>;
  sum?: InputMaybe<IWpOrdersSumOrderBy>;
  var_pop?: InputMaybe<IWpOrdersVarPopOrderBy>;
  var_samp?: InputMaybe<IWpOrdersVarSampOrderBy>;
  variance?: InputMaybe<IWpOrdersVarianceOrderBy>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface IWpOrdersAppendInput {
  raw_payload?: InputMaybe<Scalars['jsonb']>;
}

/** input type for inserting array relation for remote table "wp_orders" */
export interface IWpOrdersArrRelInsertInput {
  data: Array<IWpOrdersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IWpOrdersOnConflict>;
}

/** aggregate avg on columns */
export interface IWpOrdersAvgFields {
  __typename?: 'wp_orders_avg_fields';
  credits?: Maybe<Scalars['Float']>;
  wp_order_id?: Maybe<Scalars['Float']>;
  wp_product_id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "wp_orders" */
export interface IWpOrdersAvgOrderBy {
  credits?: InputMaybe<IOrderBy>;
  wp_order_id?: InputMaybe<IOrderBy>;
  wp_product_id?: InputMaybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "wp_orders". All fields are combined with a logical 'AND'. */
export interface IWpOrdersBoolExp {
  _and?: InputMaybe<Array<IWpOrdersBoolExp>>;
  _not?: InputMaybe<IWpOrdersBoolExp>;
  _or?: InputMaybe<Array<IWpOrdersBoolExp>>;
  confirmation_sent_at?: InputMaybe<ITimestamptzComparisonExp>;
  course?: InputMaybe<ICoursesBoolExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  credits?: InputMaybe<INumericComparisonExp>;
  email?: InputMaybe<IStringComparisonExp>;
  first_name?: InputMaybe<IStringComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  last_name?: InputMaybe<IStringComparisonExp>;
  phone?: InputMaybe<IStringComparisonExp>;
  raw_payload?: InputMaybe<IJsonbComparisonExp>;
  resend_confirmation?: InputMaybe<IBooleanComparisonExp>;
  status?: InputMaybe<IStringComparisonExp>;
  subscriptions?: InputMaybe<ISubscriptionsBoolExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  user_id?: InputMaybe<IUuidComparisonExp>;
  wp_order_id?: InputMaybe<IIntComparisonExp>;
  wp_order_key?: InputMaybe<IStringComparisonExp>;
  wp_order_number?: InputMaybe<IStringComparisonExp>;
  wp_product?: InputMaybe<IWpProductsBoolExp>;
  wp_product_id?: InputMaybe<IIntComparisonExp>;
}

/** unique or primary key constraints on table "wp_orders" */
export enum IWpOrdersConstraint {
  /** unique or primary key constraint */
  WP_ORDERS_PKEY = 'wp_orders_pkey',
  /** unique or primary key constraint */
  WP_ORDERS_WP_ORDER_ID_WP_PRODUCT_ID_KEY = 'wp_orders_wp_order_id_wp_product_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface IWpOrdersDeleteAtPathInput {
  raw_payload?: InputMaybe<Array<Scalars['String']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface IWpOrdersDeleteElemInput {
  raw_payload?: InputMaybe<Scalars['Int']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface IWpOrdersDeleteKeyInput {
  raw_payload?: InputMaybe<Scalars['String']>;
}

/** input type for incrementing numeric columns in table "wp_orders" */
export interface IWpOrdersIncInput {
  credits?: InputMaybe<Scalars['numeric']>;
  wp_order_id?: InputMaybe<Scalars['Int']>;
  wp_product_id?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "wp_orders" */
export interface IWpOrdersInsertInput {
  confirmation_sent_at?: InputMaybe<Scalars['timestamptz']>;
  course?: InputMaybe<ICoursesObjRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  credits?: InputMaybe<Scalars['numeric']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  raw_payload?: InputMaybe<Scalars['jsonb']>;
  resend_confirmation?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
  subscriptions?: InputMaybe<ISubscriptionsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  wp_order_id?: InputMaybe<Scalars['Int']>;
  wp_order_key?: InputMaybe<Scalars['String']>;
  wp_order_number?: InputMaybe<Scalars['String']>;
  wp_product?: InputMaybe<IWpProductsObjRelInsertInput>;
  wp_product_id?: InputMaybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface IWpOrdersMaxFields {
  __typename?: 'wp_orders_max_fields';
  confirmation_sent_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  credits?: Maybe<Scalars['numeric']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  wp_order_id?: Maybe<Scalars['Int']>;
  wp_order_key?: Maybe<Scalars['String']>;
  wp_order_number?: Maybe<Scalars['String']>;
  wp_product_id?: Maybe<Scalars['Int']>;
}

/** order by max() on columns of table "wp_orders" */
export interface IWpOrdersMaxOrderBy {
  confirmation_sent_at?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  credits?: InputMaybe<IOrderBy>;
  email?: InputMaybe<IOrderBy>;
  first_name?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  last_name?: InputMaybe<IOrderBy>;
  phone?: InputMaybe<IOrderBy>;
  status?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  wp_order_id?: InputMaybe<IOrderBy>;
  wp_order_key?: InputMaybe<IOrderBy>;
  wp_order_number?: InputMaybe<IOrderBy>;
  wp_product_id?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IWpOrdersMinFields {
  __typename?: 'wp_orders_min_fields';
  confirmation_sent_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  credits?: Maybe<Scalars['numeric']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  wp_order_id?: Maybe<Scalars['Int']>;
  wp_order_key?: Maybe<Scalars['String']>;
  wp_order_number?: Maybe<Scalars['String']>;
  wp_product_id?: Maybe<Scalars['Int']>;
}

/** order by min() on columns of table "wp_orders" */
export interface IWpOrdersMinOrderBy {
  confirmation_sent_at?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  credits?: InputMaybe<IOrderBy>;
  email?: InputMaybe<IOrderBy>;
  first_name?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  last_name?: InputMaybe<IOrderBy>;
  phone?: InputMaybe<IOrderBy>;
  status?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  wp_order_id?: InputMaybe<IOrderBy>;
  wp_order_key?: InputMaybe<IOrderBy>;
  wp_order_number?: InputMaybe<IOrderBy>;
  wp_product_id?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "wp_orders" */
export interface IWpOrdersMutationResponse {
  __typename?: 'wp_orders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IWpOrders>;
}

/** input type for inserting object relation for remote table "wp_orders" */
export interface IWpOrdersObjRelInsertInput {
  data: IWpOrdersInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IWpOrdersOnConflict>;
}

/** on_conflict condition type for table "wp_orders" */
export interface IWpOrdersOnConflict {
  constraint: IWpOrdersConstraint;
  update_columns?: Array<IWpOrdersUpdateColumn>;
  where?: InputMaybe<IWpOrdersBoolExp>;
}

/** Ordering options when selecting data from "wp_orders". */
export interface IWpOrdersOrderBy {
  confirmation_sent_at?: InputMaybe<IOrderBy>;
  course?: InputMaybe<ICoursesOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  credits?: InputMaybe<IOrderBy>;
  email?: InputMaybe<IOrderBy>;
  first_name?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  last_name?: InputMaybe<IOrderBy>;
  phone?: InputMaybe<IOrderBy>;
  raw_payload?: InputMaybe<IOrderBy>;
  resend_confirmation?: InputMaybe<IOrderBy>;
  status?: InputMaybe<IOrderBy>;
  subscriptions_aggregate?: InputMaybe<ISubscriptionsAggregateOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  user_id?: InputMaybe<IOrderBy>;
  wp_order_id?: InputMaybe<IOrderBy>;
  wp_order_key?: InputMaybe<IOrderBy>;
  wp_order_number?: InputMaybe<IOrderBy>;
  wp_product?: InputMaybe<IWpProductsOrderBy>;
  wp_product_id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: wp_orders */
export interface IWpOrdersPkColumnsInput {
  id: Scalars['uuid'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface IWpOrdersPrependInput {
  raw_payload?: InputMaybe<Scalars['jsonb']>;
}

/** select columns of table "wp_orders" */
export enum IWpOrdersSelectColumn {
  /** column name */
  CONFIRMATION_SENT_AT = 'confirmation_sent_at',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  CREDITS = 'credits',
  /** column name */
  EMAIL = 'email',
  /** column name */
  FIRST_NAME = 'first_name',
  /** column name */
  ID = 'id',
  /** column name */
  LAST_NAME = 'last_name',
  /** column name */
  PHONE = 'phone',
  /** column name */
  RAW_PAYLOAD = 'raw_payload',
  /** column name */
  RESEND_CONFIRMATION = 'resend_confirmation',
  /** column name */
  STATUS = 'status',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  WP_ORDER_ID = 'wp_order_id',
  /** column name */
  WP_ORDER_KEY = 'wp_order_key',
  /** column name */
  WP_ORDER_NUMBER = 'wp_order_number',
  /** column name */
  WP_PRODUCT_ID = 'wp_product_id'
}

/** input type for updating data in table "wp_orders" */
export interface IWpOrdersSetInput {
  confirmation_sent_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  credits?: InputMaybe<Scalars['numeric']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  raw_payload?: InputMaybe<Scalars['jsonb']>;
  resend_confirmation?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  wp_order_id?: InputMaybe<Scalars['Int']>;
  wp_order_key?: InputMaybe<Scalars['String']>;
  wp_order_number?: InputMaybe<Scalars['String']>;
  wp_product_id?: InputMaybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface IWpOrdersStddevFields {
  __typename?: 'wp_orders_stddev_fields';
  credits?: Maybe<Scalars['Float']>;
  wp_order_id?: Maybe<Scalars['Float']>;
  wp_product_id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "wp_orders" */
export interface IWpOrdersStddevOrderBy {
  credits?: InputMaybe<IOrderBy>;
  wp_order_id?: InputMaybe<IOrderBy>;
  wp_product_id?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IWpOrdersStddevPopFields {
  __typename?: 'wp_orders_stddev_pop_fields';
  credits?: Maybe<Scalars['Float']>;
  wp_order_id?: Maybe<Scalars['Float']>;
  wp_product_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "wp_orders" */
export interface IWpOrdersStddevPopOrderBy {
  credits?: InputMaybe<IOrderBy>;
  wp_order_id?: InputMaybe<IOrderBy>;
  wp_product_id?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IWpOrdersStddevSampFields {
  __typename?: 'wp_orders_stddev_samp_fields';
  credits?: Maybe<Scalars['Float']>;
  wp_order_id?: Maybe<Scalars['Float']>;
  wp_product_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "wp_orders" */
export interface IWpOrdersStddevSampOrderBy {
  credits?: InputMaybe<IOrderBy>;
  wp_order_id?: InputMaybe<IOrderBy>;
  wp_product_id?: InputMaybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IWpOrdersSumFields {
  __typename?: 'wp_orders_sum_fields';
  credits?: Maybe<Scalars['numeric']>;
  wp_order_id?: Maybe<Scalars['Int']>;
  wp_product_id?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "wp_orders" */
export interface IWpOrdersSumOrderBy {
  credits?: InputMaybe<IOrderBy>;
  wp_order_id?: InputMaybe<IOrderBy>;
  wp_product_id?: InputMaybe<IOrderBy>;
}

/** update columns of table "wp_orders" */
export enum IWpOrdersUpdateColumn {
  /** column name */
  CONFIRMATION_SENT_AT = 'confirmation_sent_at',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  CREDITS = 'credits',
  /** column name */
  EMAIL = 'email',
  /** column name */
  FIRST_NAME = 'first_name',
  /** column name */
  ID = 'id',
  /** column name */
  LAST_NAME = 'last_name',
  /** column name */
  PHONE = 'phone',
  /** column name */
  RAW_PAYLOAD = 'raw_payload',
  /** column name */
  RESEND_CONFIRMATION = 'resend_confirmation',
  /** column name */
  STATUS = 'status',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  WP_ORDER_ID = 'wp_order_id',
  /** column name */
  WP_ORDER_KEY = 'wp_order_key',
  /** column name */
  WP_ORDER_NUMBER = 'wp_order_number',
  /** column name */
  WP_PRODUCT_ID = 'wp_product_id'
}

/** aggregate var_pop on columns */
export interface IWpOrdersVarPopFields {
  __typename?: 'wp_orders_var_pop_fields';
  credits?: Maybe<Scalars['Float']>;
  wp_order_id?: Maybe<Scalars['Float']>;
  wp_product_id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "wp_orders" */
export interface IWpOrdersVarPopOrderBy {
  credits?: InputMaybe<IOrderBy>;
  wp_order_id?: InputMaybe<IOrderBy>;
  wp_product_id?: InputMaybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IWpOrdersVarSampFields {
  __typename?: 'wp_orders_var_samp_fields';
  credits?: Maybe<Scalars['Float']>;
  wp_order_id?: Maybe<Scalars['Float']>;
  wp_product_id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "wp_orders" */
export interface IWpOrdersVarSampOrderBy {
  credits?: InputMaybe<IOrderBy>;
  wp_order_id?: InputMaybe<IOrderBy>;
  wp_product_id?: InputMaybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IWpOrdersVarianceFields {
  __typename?: 'wp_orders_variance_fields';
  credits?: Maybe<Scalars['Float']>;
  wp_order_id?: Maybe<Scalars['Float']>;
  wp_product_id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "wp_orders" */
export interface IWpOrdersVarianceOrderBy {
  credits?: InputMaybe<IOrderBy>;
  wp_order_id?: InputMaybe<IOrderBy>;
  wp_product_id?: InputMaybe<IOrderBy>;
}

/** columns and relationships of "wp_products" */
export interface IWpProducts {
  __typename?: 'wp_products';
  course_group_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  course_months?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  ends_on?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  image_url?: Maybe<Scalars['String']>;
  is_online_product: Scalars['Boolean'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  wp_orders: Array<IWpOrders>;
  /** An aggregate relationship */
  wp_orders_aggregate: IWpOrdersAggregate;
  wp_product_id: Scalars['Int'];
}


/** columns and relationships of "wp_products" */
export interface IWpProductsWpOrdersArgs {
  distinct_on?: InputMaybe<Array<IWpOrdersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IWpOrdersOrderBy>>;
  where?: InputMaybe<IWpOrdersBoolExp>;
}


/** columns and relationships of "wp_products" */
export interface IWpProductsWpOrdersAggregateArgs {
  distinct_on?: InputMaybe<Array<IWpOrdersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IWpOrdersOrderBy>>;
  where?: InputMaybe<IWpOrdersBoolExp>;
}

/** aggregated selection of "wp_products" */
export interface IWpProductsAggregate {
  __typename?: 'wp_products_aggregate';
  aggregate?: Maybe<IWpProductsAggregateFields>;
  nodes: Array<IWpProducts>;
}

/** aggregate fields of "wp_products" */
export interface IWpProductsAggregateFields {
  __typename?: 'wp_products_aggregate_fields';
  avg?: Maybe<IWpProductsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IWpProductsMaxFields>;
  min?: Maybe<IWpProductsMinFields>;
  stddev?: Maybe<IWpProductsStddevFields>;
  stddev_pop?: Maybe<IWpProductsStddevPopFields>;
  stddev_samp?: Maybe<IWpProductsStddevSampFields>;
  sum?: Maybe<IWpProductsSumFields>;
  var_pop?: Maybe<IWpProductsVarPopFields>;
  var_samp?: Maybe<IWpProductsVarSampFields>;
  variance?: Maybe<IWpProductsVarianceFields>;
}


/** aggregate fields of "wp_products" */
export interface IWpProductsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IWpProductsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IWpProductsAvgFields {
  __typename?: 'wp_products_avg_fields';
  course_months?: Maybe<Scalars['Float']>;
  wp_product_id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "wp_products". All fields are combined with a logical 'AND'. */
export interface IWpProductsBoolExp {
  _and?: InputMaybe<Array<IWpProductsBoolExp>>;
  _not?: InputMaybe<IWpProductsBoolExp>;
  _or?: InputMaybe<Array<IWpProductsBoolExp>>;
  course_group_id?: InputMaybe<IUuidComparisonExp>;
  course_id?: InputMaybe<IUuidComparisonExp>;
  course_months?: InputMaybe<IIntComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  ends_on?: InputMaybe<IDateComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  image_url?: InputMaybe<IStringComparisonExp>;
  is_online_product?: InputMaybe<IBooleanComparisonExp>;
  title?: InputMaybe<IStringComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  wp_orders?: InputMaybe<IWpOrdersBoolExp>;
  wp_product_id?: InputMaybe<IIntComparisonExp>;
}

/** unique or primary key constraints on table "wp_products" */
export enum IWpProductsConstraint {
  /** unique or primary key constraint */
  WP_PRODUCTS_PKEY = 'wp_products_pkey'
}

/** input type for incrementing numeric columns in table "wp_products" */
export interface IWpProductsIncInput {
  course_months?: InputMaybe<Scalars['Int']>;
  wp_product_id?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "wp_products" */
export interface IWpProductsInsertInput {
  course_group_id?: InputMaybe<Scalars['uuid']>;
  course_id?: InputMaybe<Scalars['uuid']>;
  course_months?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ends_on?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_url?: InputMaybe<Scalars['String']>;
  is_online_product?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wp_orders?: InputMaybe<IWpOrdersArrRelInsertInput>;
  wp_product_id?: InputMaybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface IWpProductsMaxFields {
  __typename?: 'wp_products_max_fields';
  course_group_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  course_months?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  ends_on?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  image_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wp_product_id?: Maybe<Scalars['Int']>;
}

/** aggregate min on columns */
export interface IWpProductsMinFields {
  __typename?: 'wp_products_min_fields';
  course_group_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['uuid']>;
  course_months?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  ends_on?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  image_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wp_product_id?: Maybe<Scalars['Int']>;
}

/** response of any mutation on the table "wp_products" */
export interface IWpProductsMutationResponse {
  __typename?: 'wp_products_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IWpProducts>;
}

/** input type for inserting object relation for remote table "wp_products" */
export interface IWpProductsObjRelInsertInput {
  data: IWpProductsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IWpProductsOnConflict>;
}

/** on_conflict condition type for table "wp_products" */
export interface IWpProductsOnConflict {
  constraint: IWpProductsConstraint;
  update_columns?: Array<IWpProductsUpdateColumn>;
  where?: InputMaybe<IWpProductsBoolExp>;
}

/** Ordering options when selecting data from "wp_products". */
export interface IWpProductsOrderBy {
  course_group_id?: InputMaybe<IOrderBy>;
  course_id?: InputMaybe<IOrderBy>;
  course_months?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  ends_on?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  image_url?: InputMaybe<IOrderBy>;
  is_online_product?: InputMaybe<IOrderBy>;
  title?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  wp_orders_aggregate?: InputMaybe<IWpOrdersAggregateOrderBy>;
  wp_product_id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: wp_products */
export interface IWpProductsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "wp_products" */
export enum IWpProductsSelectColumn {
  /** column name */
  COURSE_GROUP_ID = 'course_group_id',
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  COURSE_MONTHS = 'course_months',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ENDS_ON = 'ends_on',
  /** column name */
  ID = 'id',
  /** column name */
  IMAGE_URL = 'image_url',
  /** column name */
  IS_ONLINE_PRODUCT = 'is_online_product',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  WP_PRODUCT_ID = 'wp_product_id'
}

/** input type for updating data in table "wp_products" */
export interface IWpProductsSetInput {
  course_group_id?: InputMaybe<Scalars['uuid']>;
  course_id?: InputMaybe<Scalars['uuid']>;
  course_months?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ends_on?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_url?: InputMaybe<Scalars['String']>;
  is_online_product?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wp_product_id?: InputMaybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface IWpProductsStddevFields {
  __typename?: 'wp_products_stddev_fields';
  course_months?: Maybe<Scalars['Float']>;
  wp_product_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IWpProductsStddevPopFields {
  __typename?: 'wp_products_stddev_pop_fields';
  course_months?: Maybe<Scalars['Float']>;
  wp_product_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IWpProductsStddevSampFields {
  __typename?: 'wp_products_stddev_samp_fields';
  course_months?: Maybe<Scalars['Float']>;
  wp_product_id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IWpProductsSumFields {
  __typename?: 'wp_products_sum_fields';
  course_months?: Maybe<Scalars['Int']>;
  wp_product_id?: Maybe<Scalars['Int']>;
}

/** update columns of table "wp_products" */
export enum IWpProductsUpdateColumn {
  /** column name */
  COURSE_GROUP_ID = 'course_group_id',
  /** column name */
  COURSE_ID = 'course_id',
  /** column name */
  COURSE_MONTHS = 'course_months',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ENDS_ON = 'ends_on',
  /** column name */
  ID = 'id',
  /** column name */
  IMAGE_URL = 'image_url',
  /** column name */
  IS_ONLINE_PRODUCT = 'is_online_product',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  WP_PRODUCT_ID = 'wp_product_id'
}

/** aggregate var_pop on columns */
export interface IWpProductsVarPopFields {
  __typename?: 'wp_products_var_pop_fields';
  course_months?: Maybe<Scalars['Float']>;
  wp_product_id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IWpProductsVarSampFields {
  __typename?: 'wp_products_var_samp_fields';
  course_months?: Maybe<Scalars['Float']>;
  wp_product_id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IWpProductsVarianceFields {
  __typename?: 'wp_products_variance_fields';
  course_months?: Maybe<Scalars['Float']>;
  wp_product_id?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "wp_subscription_orders" */
export interface IWpSubscriptionOrders {
  __typename?: 'wp_subscription_orders';
  _mollie_customer_id?: Maybe<Scalars['String']>;
  _mollie_mandate_id?: Maybe<Scalars['String']>;
  change_count?: Maybe<Scalars['Int']>;
  course_type: Scalars['String'];
  created_at: Scalars['timestamptz'];
  date_created: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['Int'];
  on_hold: Scalars['Boolean'];
  status: Scalars['String'];
  /** An object relationship */
  subscription?: Maybe<ISubscriptions>;
  subscription_extended: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  wp_subscription_id: Scalars['Int'];
}

/** aggregated selection of "wp_subscription_orders" */
export interface IWpSubscriptionOrdersAggregate {
  __typename?: 'wp_subscription_orders_aggregate';
  aggregate?: Maybe<IWpSubscriptionOrdersAggregateFields>;
  nodes: Array<IWpSubscriptionOrders>;
}

/** aggregate fields of "wp_subscription_orders" */
export interface IWpSubscriptionOrdersAggregateFields {
  __typename?: 'wp_subscription_orders_aggregate_fields';
  avg?: Maybe<IWpSubscriptionOrdersAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IWpSubscriptionOrdersMaxFields>;
  min?: Maybe<IWpSubscriptionOrdersMinFields>;
  stddev?: Maybe<IWpSubscriptionOrdersStddevFields>;
  stddev_pop?: Maybe<IWpSubscriptionOrdersStddevPopFields>;
  stddev_samp?: Maybe<IWpSubscriptionOrdersStddevSampFields>;
  sum?: Maybe<IWpSubscriptionOrdersSumFields>;
  var_pop?: Maybe<IWpSubscriptionOrdersVarPopFields>;
  var_samp?: Maybe<IWpSubscriptionOrdersVarSampFields>;
  variance?: Maybe<IWpSubscriptionOrdersVarianceFields>;
}


/** aggregate fields of "wp_subscription_orders" */
export interface IWpSubscriptionOrdersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IWpSubscriptionOrdersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "wp_subscription_orders" */
export interface IWpSubscriptionOrdersAggregateOrderBy {
  avg?: InputMaybe<IWpSubscriptionOrdersAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IWpSubscriptionOrdersMaxOrderBy>;
  min?: InputMaybe<IWpSubscriptionOrdersMinOrderBy>;
  stddev?: InputMaybe<IWpSubscriptionOrdersStddevOrderBy>;
  stddev_pop?: InputMaybe<IWpSubscriptionOrdersStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IWpSubscriptionOrdersStddevSampOrderBy>;
  sum?: InputMaybe<IWpSubscriptionOrdersSumOrderBy>;
  var_pop?: InputMaybe<IWpSubscriptionOrdersVarPopOrderBy>;
  var_samp?: InputMaybe<IWpSubscriptionOrdersVarSampOrderBy>;
  variance?: InputMaybe<IWpSubscriptionOrdersVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "wp_subscription_orders" */
export interface IWpSubscriptionOrdersArrRelInsertInput {
  data: Array<IWpSubscriptionOrdersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IWpSubscriptionOrdersOnConflict>;
}

/** aggregate avg on columns */
export interface IWpSubscriptionOrdersAvgFields {
  __typename?: 'wp_subscription_orders_avg_fields';
  change_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "wp_subscription_orders" */
export interface IWpSubscriptionOrdersAvgOrderBy {
  change_count?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "wp_subscription_orders". All fields are combined with a logical 'AND'. */
export interface IWpSubscriptionOrdersBoolExp {
  _and?: InputMaybe<Array<IWpSubscriptionOrdersBoolExp>>;
  _mollie_customer_id?: InputMaybe<IStringComparisonExp>;
  _mollie_mandate_id?: InputMaybe<IStringComparisonExp>;
  _not?: InputMaybe<IWpSubscriptionOrdersBoolExp>;
  _or?: InputMaybe<Array<IWpSubscriptionOrdersBoolExp>>;
  change_count?: InputMaybe<IIntComparisonExp>;
  course_type?: InputMaybe<IStringComparisonExp>;
  created_at?: InputMaybe<ITimestamptzComparisonExp>;
  date_created?: InputMaybe<ITimestamptzComparisonExp>;
  email?: InputMaybe<IStringComparisonExp>;
  id?: InputMaybe<IIntComparisonExp>;
  on_hold?: InputMaybe<IBooleanComparisonExp>;
  status?: InputMaybe<IStringComparisonExp>;
  subscription?: InputMaybe<ISubscriptionsBoolExp>;
  subscription_extended?: InputMaybe<IBooleanComparisonExp>;
  updated_at?: InputMaybe<ITimestamptzComparisonExp>;
  wp_subscription_id?: InputMaybe<IIntComparisonExp>;
}

/** unique or primary key constraints on table "wp_subscription_orders" */
export enum IWpSubscriptionOrdersConstraint {
  /** unique or primary key constraint */
  WP_SUBSCRIPTION_ORDERS_PKEY = 'wp_subscription_orders_pkey'
}

/** input type for incrementing numeric columns in table "wp_subscription_orders" */
export interface IWpSubscriptionOrdersIncInput {
  change_count?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  wp_subscription_id?: InputMaybe<Scalars['Int']>;
}

/** input type for inserting data into table "wp_subscription_orders" */
export interface IWpSubscriptionOrdersInsertInput {
  _mollie_customer_id?: InputMaybe<Scalars['String']>;
  _mollie_mandate_id?: InputMaybe<Scalars['String']>;
  change_count?: InputMaybe<Scalars['Int']>;
  course_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date_created?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  on_hold?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
  subscription?: InputMaybe<ISubscriptionsObjRelInsertInput>;
  subscription_extended?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wp_subscription_id?: InputMaybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface IWpSubscriptionOrdersMaxFields {
  __typename?: 'wp_subscription_orders_max_fields';
  _mollie_customer_id?: Maybe<Scalars['String']>;
  _mollie_mandate_id?: Maybe<Scalars['String']>;
  change_count?: Maybe<Scalars['Int']>;
  course_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_created?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wp_subscription_id?: Maybe<Scalars['Int']>;
}

/** order by max() on columns of table "wp_subscription_orders" */
export interface IWpSubscriptionOrdersMaxOrderBy {
  _mollie_customer_id?: InputMaybe<IOrderBy>;
  _mollie_mandate_id?: InputMaybe<IOrderBy>;
  change_count?: InputMaybe<IOrderBy>;
  course_type?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  date_created?: InputMaybe<IOrderBy>;
  email?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  status?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IWpSubscriptionOrdersMinFields {
  __typename?: 'wp_subscription_orders_min_fields';
  _mollie_customer_id?: Maybe<Scalars['String']>;
  _mollie_mandate_id?: Maybe<Scalars['String']>;
  change_count?: Maybe<Scalars['Int']>;
  course_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_created?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wp_subscription_id?: Maybe<Scalars['Int']>;
}

/** order by min() on columns of table "wp_subscription_orders" */
export interface IWpSubscriptionOrdersMinOrderBy {
  _mollie_customer_id?: InputMaybe<IOrderBy>;
  _mollie_mandate_id?: InputMaybe<IOrderBy>;
  change_count?: InputMaybe<IOrderBy>;
  course_type?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  date_created?: InputMaybe<IOrderBy>;
  email?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  status?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "wp_subscription_orders" */
export interface IWpSubscriptionOrdersMutationResponse {
  __typename?: 'wp_subscription_orders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IWpSubscriptionOrders>;
}

/** on_conflict condition type for table "wp_subscription_orders" */
export interface IWpSubscriptionOrdersOnConflict {
  constraint: IWpSubscriptionOrdersConstraint;
  update_columns?: Array<IWpSubscriptionOrdersUpdateColumn>;
  where?: InputMaybe<IWpSubscriptionOrdersBoolExp>;
}

/** Ordering options when selecting data from "wp_subscription_orders". */
export interface IWpSubscriptionOrdersOrderBy {
  _mollie_customer_id?: InputMaybe<IOrderBy>;
  _mollie_mandate_id?: InputMaybe<IOrderBy>;
  change_count?: InputMaybe<IOrderBy>;
  course_type?: InputMaybe<IOrderBy>;
  created_at?: InputMaybe<IOrderBy>;
  date_created?: InputMaybe<IOrderBy>;
  email?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  on_hold?: InputMaybe<IOrderBy>;
  status?: InputMaybe<IOrderBy>;
  subscription?: InputMaybe<ISubscriptionsOrderBy>;
  subscription_extended?: InputMaybe<IOrderBy>;
  updated_at?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: wp_subscription_orders */
export interface IWpSubscriptionOrdersPkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "wp_subscription_orders" */
export enum IWpSubscriptionOrdersSelectColumn {
  /** column name */
  _MOLLIE_CUSTOMER_ID = '_mollie_customer_id',
  /** column name */
  _MOLLIE_MANDATE_ID = '_mollie_mandate_id',
  /** column name */
  CHANGE_COUNT = 'change_count',
  /** column name */
  COURSE_TYPE = 'course_type',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DATE_CREATED = 'date_created',
  /** column name */
  EMAIL = 'email',
  /** column name */
  ID = 'id',
  /** column name */
  ON_HOLD = 'on_hold',
  /** column name */
  STATUS = 'status',
  /** column name */
  SUBSCRIPTION_EXTENDED = 'subscription_extended',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  WP_SUBSCRIPTION_ID = 'wp_subscription_id'
}

/** input type for updating data in table "wp_subscription_orders" */
export interface IWpSubscriptionOrdersSetInput {
  _mollie_customer_id?: InputMaybe<Scalars['String']>;
  _mollie_mandate_id?: InputMaybe<Scalars['String']>;
  change_count?: InputMaybe<Scalars['Int']>;
  course_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date_created?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  on_hold?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
  subscription_extended?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wp_subscription_id?: InputMaybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface IWpSubscriptionOrdersStddevFields {
  __typename?: 'wp_subscription_orders_stddev_fields';
  change_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "wp_subscription_orders" */
export interface IWpSubscriptionOrdersStddevOrderBy {
  change_count?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IWpSubscriptionOrdersStddevPopFields {
  __typename?: 'wp_subscription_orders_stddev_pop_fields';
  change_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "wp_subscription_orders" */
export interface IWpSubscriptionOrdersStddevPopOrderBy {
  change_count?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IWpSubscriptionOrdersStddevSampFields {
  __typename?: 'wp_subscription_orders_stddev_samp_fields';
  change_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "wp_subscription_orders" */
export interface IWpSubscriptionOrdersStddevSampOrderBy {
  change_count?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IWpSubscriptionOrdersSumFields {
  __typename?: 'wp_subscription_orders_sum_fields';
  change_count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  wp_subscription_id?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "wp_subscription_orders" */
export interface IWpSubscriptionOrdersSumOrderBy {
  change_count?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
}

/** update columns of table "wp_subscription_orders" */
export enum IWpSubscriptionOrdersUpdateColumn {
  /** column name */
  _MOLLIE_CUSTOMER_ID = '_mollie_customer_id',
  /** column name */
  _MOLLIE_MANDATE_ID = '_mollie_mandate_id',
  /** column name */
  CHANGE_COUNT = 'change_count',
  /** column name */
  COURSE_TYPE = 'course_type',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DATE_CREATED = 'date_created',
  /** column name */
  EMAIL = 'email',
  /** column name */
  ID = 'id',
  /** column name */
  ON_HOLD = 'on_hold',
  /** column name */
  STATUS = 'status',
  /** column name */
  SUBSCRIPTION_EXTENDED = 'subscription_extended',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  WP_SUBSCRIPTION_ID = 'wp_subscription_id'
}

/** aggregate var_pop on columns */
export interface IWpSubscriptionOrdersVarPopFields {
  __typename?: 'wp_subscription_orders_var_pop_fields';
  change_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "wp_subscription_orders" */
export interface IWpSubscriptionOrdersVarPopOrderBy {
  change_count?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IWpSubscriptionOrdersVarSampFields {
  __typename?: 'wp_subscription_orders_var_samp_fields';
  change_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "wp_subscription_orders" */
export interface IWpSubscriptionOrdersVarSampOrderBy {
  change_count?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IWpSubscriptionOrdersVarianceFields {
  __typename?: 'wp_subscription_orders_variance_fields';
  change_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wp_subscription_id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "wp_subscription_orders" */
export interface IWpSubscriptionOrdersVarianceOrderBy {
  change_count?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  wp_subscription_id?: InputMaybe<IOrderBy>;
}

export type IGetUserSessionQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetUserSessionQuery = (
  { __typename?: 'query_root' }
  & { unique_user_sessions: Array<(
    { __typename?: 'unique_user_sessions' }
    & Pick<IUniqueUserSessions, 'count'>
  )> }
);

export type IUpsertSessionMutationVariables = Exact<{ [key: string]: never; }>;


export type IUpsertSessionMutation = (
  { __typename?: 'mutation_root' }
  & { insert_users_sessions_one?: Maybe<(
    { __typename?: 'users_sessions' }
    & Pick<IUsersSessions, 'last_seen_at'>
  )> }
);

export type ICreateUserChildMutationVariables = Exact<{
  input: IUsersChildrenInsertInput;
}>;


export type ICreateUserChildMutation = (
  { __typename?: 'mutation_root' }
  & { insert_users_children_one?: Maybe<(
    { __typename?: 'users_children' }
    & Pick<IUsersChildren, 'id'>
  )> }
);

export type IGetSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetSubscriptionsQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & { subscriptions: Array<(
      { __typename?: 'subscriptions' }
      & Pick<ISubscriptions, 'id'>
      & { course_group?: Maybe<(
        { __typename?: 'course_groups' }
        & Pick<ICourseGroups, 'id' | 'questions_enabled'>
      )>, course?: Maybe<(
        { __typename?: 'courses' }
        & Pick<ICourses, 'id' | 'name'>
      )>, users_children: Array<(
        { __typename?: 'users_children' }
        & Pick<IUsersChildren, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type IUpdateLessonProgressionMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['uuid']>;
  lessonId?: InputMaybe<Scalars['uuid']>;
  userChildId?: InputMaybe<Scalars['uuid']>;
  timeProgress?: InputMaybe<Scalars['numeric']>;
  percentage?: InputMaybe<Scalars['numeric']>;
  finished?: InputMaybe<Scalars['Boolean']>;
}>;


export type IUpdateLessonProgressionMutation = (
  { __typename?: 'mutation_root' }
  & { insert_user_lesson_progresses?: Maybe<(
    { __typename?: 'user_lesson_progresses_mutation_response' }
    & Pick<IUserLessonProgressesMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'user_lesson_progresses' }
      & Pick<IUserLessonProgresses, 'percentage' | 'time_progress' | 'finished'>
    )> }
  )> }
);

export type IUpdateViewCountMutationVariables = Exact<{
  userId: Scalars['uuid'];
  userChildId?: InputMaybe<Scalars['uuid']>;
  lessonId: Scalars['uuid'];
}>;


export type IUpdateViewCountMutation = (
  { __typename?: 'mutation_root' }
  & { update_user_lesson_progresses?: Maybe<(
    { __typename?: 'user_lesson_progresses_mutation_response' }
    & Pick<IUserLessonProgressesMutationResponse, 'affected_rows'>
  )> }
);

export type IInsertFavoriteLessonMomentMutationVariables = Exact<{
  object: IFavoriteLessonMomentsInsertInput;
}>;


export type IInsertFavoriteLessonMomentMutation = (
  { __typename?: 'mutation_root' }
  & { insert_favorite_lesson_moments_one?: Maybe<(
    { __typename?: 'favorite_lesson_moments' }
    & Pick<IFavoriteLessonMoments, 'id'>
  )> }
);

export type IMessageFragment = (
  { __typename?: 'messages' }
  & Pick<IMessages, 'id' | 'title' | 'message' | 'from' | 'opened' | 'created_at'>
);

export type IUsersChildDetailsFragmentFragment = (
  { __typename?: 'users_child_details' }
  & Pick<IUsersChildDetails, 'id' | 'about_me'>
);

export type IGetAttachmentsByLessonIdQueryVariables = Exact<{
  lessonId: Scalars['uuid'];
}>;


export type IGetAttachmentsByLessonIdQuery = (
  { __typename?: 'query_root' }
  & { lesson_attachments: Array<(
    { __typename?: 'lesson_attachments' }
    & Pick<ILessonAttachments, 'key' | 'filename' | 'lesson_id' | 'created_at'>
  )> }
);

export type IGetSubscriptionStatusQueryVariables = Exact<{
  subscriptionId: Scalars['uuid'];
}>;


export type IGetSubscriptionStatusQuery = (
  { __typename?: 'query_root' }
  & { subscriptions_by_pk?: Maybe<(
    { __typename?: 'subscriptions' }
    & Pick<ISubscriptions, 'id' | 'ends_on'>
  )> }
);

export type IGetNotificationsQueryVariables = Exact<{
  courseGroupId: Scalars['uuid'];
}>;


export type IGetNotificationsQuery = (
  { __typename?: 'query_root' }
  & { notifications: Array<(
    { __typename?: 'notifications' }
    & Pick<INotifications, 'id' | 'title' | 'description' | 'created_at'>
  )> }
);

export type IGetHomeDataQueryVariables = Exact<{
  subscriptionId: Scalars['uuid'];
}>;


export type IGetHomeDataQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<IUsers, 'id' | 'first_name' | 'last_name'>
  )>, subscriptions_aggregate: (
    { __typename?: 'subscriptions_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'subscriptions_aggregate_fields' }
      & Pick<ISubscriptionsAggregateFields, 'count'>
    )> }
  ), subscriptions_by_pk?: Maybe<(
    { __typename?: 'subscriptions' }
    & Pick<ISubscriptions, 'id' | 'starts_on' | 'ends_on'>
    & { users_children: Array<(
      { __typename?: 'users_children' }
      & Pick<IUsersChildren, 'id' | 'username'>
    )>, course_group?: Maybe<(
      { __typename?: 'course_groups' }
      & Pick<ICourseGroups, 'id'>
    )>, course?: Maybe<(
      { __typename?: 'courses' }
      & Pick<ICourses, 'id' | 'name'>
      & { lessons_view: Array<(
        { __typename?: 'lessons_view' }
        & Pick<ILessonsView, 'name' | 'is_standby'>
        & { lesson?: Maybe<(
          { __typename?: 'lessons' }
          & Pick<ILessons, 'sequence'>
        )> }
      )>, annual_schedule?: Maybe<(
        { __typename?: 'annual_schedules' }
        & Pick<IAnnualSchedules, 'id' | 'title'>
        & { annual_schedule_attachments: Array<(
          { __typename?: 'annual_schedule_attachments' }
          & Pick<IAnnualScheduleAttachments, 'filename'>
          & { id: IAnnualScheduleAttachments['key'] }
        )> }
      )>, lessons_aggregate: (
        { __typename?: 'lessons_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'lessons_aggregate_fields' }
          & Pick<ILessonsAggregateFields, 'count'>
        )> }
      ), semi_stream_lessons: Array<(
        { __typename?: 'semi_stream_lessons' }
        & { lesson?: Maybe<(
          { __typename?: 'lessons' }
          & Pick<ILessons, 'sequence'>
        )> }
      )> }
    )> }
  )> }
);

export type IGetLessonDetailsQueryVariables = Exact<{
  lessonId: Scalars['uuid'];
}>;


export type IGetLessonDetailsQuery = (
  { __typename?: 'query_root' }
  & { lessons_by_pk?: Maybe<(
    { __typename?: 'lessons' }
    & Pick<ILessons, 'id' | 'name' | 'label' | 'content'>
  )> }
);

export type IGetCourseQueryVariables = Exact<{
  subscriptionId: Scalars['uuid'];
}>;


export type IGetCourseQuery = (
  { __typename?: 'query_root' }
  & { subscriptions_by_pk?: Maybe<(
    { __typename?: 'subscriptions' }
    & { course?: Maybe<(
      { __typename?: 'courses' }
      & Pick<ICourses, 'id' | 'name'>
      & { lessons: Array<(
        { __typename?: 'lessons' }
        & Pick<ILessons, 'id' | 'name' | 'label' | 'description' | 'content' | 'sequence'>
      )> }
    )> }
  )> }
);

export type IGetUserChildQueryVariables = Exact<{
  subscriptionId: Scalars['uuid'];
}>;


export type IGetUserChildQuery = (
  { __typename?: 'query_root' }
  & { users_children: Array<(
    { __typename?: 'users_children' }
    & Pick<IUsersChildren, 'id' | 'name' | 'content_key'>
    & { subscription?: Maybe<(
      { __typename?: 'subscriptions' }
      & { course_group?: Maybe<(
        { __typename?: 'course_groups' }
        & Pick<ICourseGroups, 'group_type_id' | 'questions_enabled'>
      )> }
    )> }
  )> }
);

export type IHoldSubscriptionMutationVariables = Exact<{
  id: Scalars['Int'];
  hold: Scalars['Boolean'];
}>;


export type IHoldSubscriptionMutation = (
  { __typename?: 'mutation_root' }
  & { update_wp_subscription_orders_by_pk?: Maybe<(
    { __typename?: 'wp_subscription_orders' }
    & Pick<IWpSubscriptionOrders, 'id' | 'on_hold' | 'change_count' | 'status'>
  )> }
);

export type IUpdateContactInformationMutationVariables = Exact<{
  id: Scalars['uuid'];
  rawPayload: Scalars['jsonb'];
}>;


export type IUpdateContactInformationMutation = (
  { __typename?: 'mutation_root' }
  & { update_wp_orders_by_pk?: Maybe<(
    { __typename?: 'wp_orders' }
    & Pick<IWpOrders, 'id' | 'raw_payload'>
  )> }
);

export type IGetAvailabilityQueryVariables = Exact<{
  subscriptionId: Scalars['uuid'];
}>;


export type IGetAvailabilityQuery = (
  { __typename?: 'query_root' }
  & { subscriptions_by_pk?: Maybe<(
    { __typename?: 'subscriptions' }
    & Pick<ISubscriptions, 'id' | 'starts_on' | 'ends_on' | 'user_id'>
    & { user?: Maybe<(
      { __typename?: 'users' }
      & Pick<IUsers, 'relation_to_kid'>
    )>, wp_order?: Maybe<(
      { __typename?: 'wp_orders' }
      & Pick<IWpOrders, 'id' | 'user_id' | 'first_name' | 'last_name' | 'raw_payload'>
    )>, wp_subscription_orders: Array<(
      { __typename?: 'wp_subscription_orders' }
      & Pick<IWpSubscriptionOrders, 'id' | 'on_hold' | 'change_count' | 'status'>
    )> }
  )> }
);

export type IGetSupportDataQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetSupportDataQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<IUsers, 'id'>
  )>, support_topics: Array<(
    { __typename?: 'support_topics' }
    & Pick<ISupportTopics, 'id' | 'topic'>
    & { support_topic_entries: Array<(
      { __typename?: 'support_topic_entries' }
      & Pick<ISupportTopicEntries, 'id' | 'question' | 'answer'>
    )> }
  )> }
);

export type IGetNewsQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetNewsQuery = (
  { __typename?: 'query_root' }
  & { news_articles_view: Array<(
    { __typename?: 'news_articles_view' }
    & Pick<INewsArticlesView, 'id' | 'title' | 'content'>
    & { news_articles_attachments: Array<(
      { __typename?: 'news_articles_attachments' }
      & Pick<INewsArticlesAttachments, 'key' | 'filename'>
    )> }
  )> }
);

export type IGetSubscriptionQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type IGetSubscriptionQuery = (
  { __typename?: 'query_root' }
  & { subscriptions_by_pk?: Maybe<(
    { __typename?: 'subscriptions' }
    & Pick<ISubscriptions, 'id'>
    & { course_group?: Maybe<(
      { __typename?: 'course_groups' }
      & Pick<ICourseGroups, 'id' | 'questions_enabled'>
    )> }
  )> }
);

export type IGetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetUserQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<IUsers, 'id' | 'first_name' | 'last_name' | 'email'>
  )> }
);

export const MessageFragmentDoc = gql`
    fragment Message on messages {
  id
  title
  message
  from
  opened
  created_at
}
    `;
export const UsersChildDetailsFragmentFragmentDoc = gql`
    fragment UsersChildDetailsFragment on users_child_details {
  id
  about_me
}
    `;
export const GetUserSessionDocument = gql`
    query getUserSession {
  unique_user_sessions {
    count
  }
}
    `;

/**
 * __useGetUserSessionQuery__
 *
 * To run a query within a React component, call `useGetUserSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserSessionQuery(baseOptions?: Apollo.QueryHookOptions<IGetUserSessionQuery, IGetUserSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetUserSessionQuery, IGetUserSessionQueryVariables>(GetUserSessionDocument, options);
      }
export function useGetUserSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetUserSessionQuery, IGetUserSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetUserSessionQuery, IGetUserSessionQueryVariables>(GetUserSessionDocument, options);
        }
export type GetUserSessionQueryHookResult = ReturnType<typeof useGetUserSessionQuery>;
export type GetUserSessionLazyQueryHookResult = ReturnType<typeof useGetUserSessionLazyQuery>;
export type GetUserSessionQueryResult = Apollo.QueryResult<IGetUserSessionQuery, IGetUserSessionQueryVariables>;
export const UpsertSessionDocument = gql`
    mutation UpsertSession {
  insert_users_sessions_one(
    object: {last_seen_at: "now()"}
    on_conflict: {constraint: users_sessions_auth0id_ip_key, update_columns: last_seen_at}
  ) {
    last_seen_at
  }
}
    `;

/**
 * __useUpsertSessionMutation__
 *
 * To run a mutation, you first call `useUpsertSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSessionMutation, { data, loading, error }] = useUpsertSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpsertSessionMutation(baseOptions?: Apollo.MutationHookOptions<IUpsertSessionMutation, IUpsertSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpsertSessionMutation, IUpsertSessionMutationVariables>(UpsertSessionDocument, options);
      }
export type UpsertSessionMutationHookResult = ReturnType<typeof useUpsertSessionMutation>;
export type UpsertSessionMutationResult = Apollo.MutationResult<IUpsertSessionMutation>;
export type UpsertSessionMutationOptions = Apollo.BaseMutationOptions<IUpsertSessionMutation, IUpsertSessionMutationVariables>;
export const CreateUserChildDocument = gql`
    mutation createUserChild($input: users_children_insert_input!) {
  insert_users_children_one(object: $input) {
    id
  }
}
    `;

/**
 * __useCreateUserChildMutation__
 *
 * To run a mutation, you first call `useCreateUserChildMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserChildMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserChildMutation, { data, loading, error }] = useCreateUserChildMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserChildMutation(baseOptions?: Apollo.MutationHookOptions<ICreateUserChildMutation, ICreateUserChildMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateUserChildMutation, ICreateUserChildMutationVariables>(CreateUserChildDocument, options);
      }
export type CreateUserChildMutationHookResult = ReturnType<typeof useCreateUserChildMutation>;
export type CreateUserChildMutationResult = Apollo.MutationResult<ICreateUserChildMutation>;
export type CreateUserChildMutationOptions = Apollo.BaseMutationOptions<ICreateUserChildMutation, ICreateUserChildMutationVariables>;
export const GetSubscriptionsDocument = gql`
    query getSubscriptions {
  users {
    subscriptions(
      where: {course: {course_type: {_eq: KORAN_LEREN_LEZEN}}}
      order_by: {ends_on: desc}
    ) {
      id
      course_group {
        id
        questions_enabled
      }
      course {
        id
        name
      }
      users_children {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<IGetSubscriptionsQuery, IGetSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetSubscriptionsQuery, IGetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
      }
export function useGetSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetSubscriptionsQuery, IGetSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetSubscriptionsQuery, IGetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
        }
export type GetSubscriptionsQueryHookResult = ReturnType<typeof useGetSubscriptionsQuery>;
export type GetSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetSubscriptionsLazyQuery>;
export type GetSubscriptionsQueryResult = Apollo.QueryResult<IGetSubscriptionsQuery, IGetSubscriptionsQueryVariables>;
export const UpdateLessonProgressionDocument = gql`
    mutation updateLessonProgression($userId: uuid, $lessonId: uuid, $userChildId: uuid, $timeProgress: numeric, $percentage: numeric, $finished: Boolean) {
  insert_user_lesson_progresses(
    objects: {user_id: $userId, users_child_id: $userChildId, lesson_id: $lessonId, time_progress: $timeProgress, percentage: $percentage, finished: $finished, unlocked_at: null}
    on_conflict: {constraint: user_lesson_progresses_user_id_lesson_id_users_child_id_key, update_columns: [time_progress, percentage, finished]}
  ) {
    affected_rows
    returning {
      percentage
      time_progress
      finished
    }
  }
}
    `;

/**
 * __useUpdateLessonProgressionMutation__
 *
 * To run a mutation, you first call `useUpdateLessonProgressionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLessonProgressionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLessonProgressionMutation, { data, loading, error }] = useUpdateLessonProgressionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      lessonId: // value for 'lessonId'
 *      userChildId: // value for 'userChildId'
 *      timeProgress: // value for 'timeProgress'
 *      percentage: // value for 'percentage'
 *      finished: // value for 'finished'
 *   },
 * });
 */
export function useUpdateLessonProgressionMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateLessonProgressionMutation, IUpdateLessonProgressionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateLessonProgressionMutation, IUpdateLessonProgressionMutationVariables>(UpdateLessonProgressionDocument, options);
      }
export type UpdateLessonProgressionMutationHookResult = ReturnType<typeof useUpdateLessonProgressionMutation>;
export type UpdateLessonProgressionMutationResult = Apollo.MutationResult<IUpdateLessonProgressionMutation>;
export type UpdateLessonProgressionMutationOptions = Apollo.BaseMutationOptions<IUpdateLessonProgressionMutation, IUpdateLessonProgressionMutationVariables>;
export const UpdateViewCountDocument = gql`
    mutation updateViewCount($userId: uuid!, $userChildId: uuid, $lessonId: uuid!) {
  update_user_lesson_progresses(
    where: {user_id: {_eq: $userId}, users_child_id: {_eq: $userChildId}, lesson_id: {_eq: $lessonId}}
    _inc: {view_count: 1}
  ) {
    affected_rows
  }
}
    `;

/**
 * __useUpdateViewCountMutation__
 *
 * To run a mutation, you first call `useUpdateViewCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateViewCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateViewCountMutation, { data, loading, error }] = useUpdateViewCountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      userChildId: // value for 'userChildId'
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useUpdateViewCountMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateViewCountMutation, IUpdateViewCountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateViewCountMutation, IUpdateViewCountMutationVariables>(UpdateViewCountDocument, options);
      }
export type UpdateViewCountMutationHookResult = ReturnType<typeof useUpdateViewCountMutation>;
export type UpdateViewCountMutationResult = Apollo.MutationResult<IUpdateViewCountMutation>;
export type UpdateViewCountMutationOptions = Apollo.BaseMutationOptions<IUpdateViewCountMutation, IUpdateViewCountMutationVariables>;
export const InsertFavoriteLessonMomentDocument = gql`
    mutation insertFavoriteLessonMoment($object: favorite_lesson_moments_insert_input!) {
  insert_favorite_lesson_moments_one(object: $object) {
    id
  }
}
    `;

/**
 * __useInsertFavoriteLessonMomentMutation__
 *
 * To run a mutation, you first call `useInsertFavoriteLessonMomentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertFavoriteLessonMomentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFavoriteLessonMomentMutation, { data, loading, error }] = useInsertFavoriteLessonMomentMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertFavoriteLessonMomentMutation(baseOptions?: Apollo.MutationHookOptions<IInsertFavoriteLessonMomentMutation, IInsertFavoriteLessonMomentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IInsertFavoriteLessonMomentMutation, IInsertFavoriteLessonMomentMutationVariables>(InsertFavoriteLessonMomentDocument, options);
      }
export type InsertFavoriteLessonMomentMutationHookResult = ReturnType<typeof useInsertFavoriteLessonMomentMutation>;
export type InsertFavoriteLessonMomentMutationResult = Apollo.MutationResult<IInsertFavoriteLessonMomentMutation>;
export type InsertFavoriteLessonMomentMutationOptions = Apollo.BaseMutationOptions<IInsertFavoriteLessonMomentMutation, IInsertFavoriteLessonMomentMutationVariables>;
export const GetAttachmentsByLessonIdDocument = gql`
    query getAttachmentsByLessonId($lessonId: uuid!) {
  lesson_attachments(where: {lesson_id: {_eq: $lessonId}}) {
    key
    filename
    lesson_id
    created_at
  }
}
    `;

/**
 * __useGetAttachmentsByLessonIdQuery__
 *
 * To run a query within a React component, call `useGetAttachmentsByLessonIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttachmentsByLessonIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttachmentsByLessonIdQuery({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useGetAttachmentsByLessonIdQuery(baseOptions: Apollo.QueryHookOptions<IGetAttachmentsByLessonIdQuery, IGetAttachmentsByLessonIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetAttachmentsByLessonIdQuery, IGetAttachmentsByLessonIdQueryVariables>(GetAttachmentsByLessonIdDocument, options);
      }
export function useGetAttachmentsByLessonIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetAttachmentsByLessonIdQuery, IGetAttachmentsByLessonIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetAttachmentsByLessonIdQuery, IGetAttachmentsByLessonIdQueryVariables>(GetAttachmentsByLessonIdDocument, options);
        }
export type GetAttachmentsByLessonIdQueryHookResult = ReturnType<typeof useGetAttachmentsByLessonIdQuery>;
export type GetAttachmentsByLessonIdLazyQueryHookResult = ReturnType<typeof useGetAttachmentsByLessonIdLazyQuery>;
export type GetAttachmentsByLessonIdQueryResult = Apollo.QueryResult<IGetAttachmentsByLessonIdQuery, IGetAttachmentsByLessonIdQueryVariables>;
export const GetSubscriptionStatusDocument = gql`
    query getSubscriptionStatus($subscriptionId: uuid!) {
  subscriptions_by_pk(id: $subscriptionId) {
    id
    ends_on
  }
}
    `;

/**
 * __useGetSubscriptionStatusQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionStatusQuery({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useGetSubscriptionStatusQuery(baseOptions: Apollo.QueryHookOptions<IGetSubscriptionStatusQuery, IGetSubscriptionStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetSubscriptionStatusQuery, IGetSubscriptionStatusQueryVariables>(GetSubscriptionStatusDocument, options);
      }
export function useGetSubscriptionStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetSubscriptionStatusQuery, IGetSubscriptionStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetSubscriptionStatusQuery, IGetSubscriptionStatusQueryVariables>(GetSubscriptionStatusDocument, options);
        }
export type GetSubscriptionStatusQueryHookResult = ReturnType<typeof useGetSubscriptionStatusQuery>;
export type GetSubscriptionStatusLazyQueryHookResult = ReturnType<typeof useGetSubscriptionStatusLazyQuery>;
export type GetSubscriptionStatusQueryResult = Apollo.QueryResult<IGetSubscriptionStatusQuery, IGetSubscriptionStatusQueryVariables>;
export const GetNotificationsDocument = gql`
    query getNotifications($courseGroupId: uuid!) {
  notifications(
    where: {notification_group_types: {group_type: {course_groups: {id: {_eq: $courseGroupId}}}}}
    order_by: {created_at: desc}
  ) {
    id
    title
    description
    created_at
  }
}
    `;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      courseGroupId: // value for 'courseGroupId'
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions: Apollo.QueryHookOptions<IGetNotificationsQuery, IGetNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetNotificationsQuery, IGetNotificationsQueryVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetNotificationsQuery, IGetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetNotificationsQuery, IGetNotificationsQueryVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<IGetNotificationsQuery, IGetNotificationsQueryVariables>;
export const GetHomeDataDocument = gql`
    query getHomeData($subscriptionId: uuid!) {
  users {
    id
    first_name
    last_name
  }
  subscriptions_aggregate(
    where: {ends_on: {_gte: "now()"}, course: {course_type: {_eq: KORAN_VAN_KAFT_TOT_KAFT}}}
  ) {
    aggregate {
      count
    }
  }
  subscriptions_by_pk(id: $subscriptionId) {
    id
    starts_on
    ends_on
    users_children {
      id
      username
    }
    course_group {
      id
    }
    course {
      id
      name
      lessons_view(
        limit: 1
        order_by: {lesson: {sequence: desc}}
        where: {is_standby: {_eq: false}}
      ) {
        name
        lesson {
          sequence
        }
        is_standby
      }
      annual_schedule {
        id
        title
        annual_schedule_attachments {
          id: key
          filename
        }
      }
      lessons_aggregate {
        aggregate {
          count
        }
      }
      semi_stream_lessons {
        lesson {
          sequence
        }
      }
    }
  }
}
    `;

/**
 * __useGetHomeDataQuery__
 *
 * To run a query within a React component, call `useGetHomeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeDataQuery({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useGetHomeDataQuery(baseOptions: Apollo.QueryHookOptions<IGetHomeDataQuery, IGetHomeDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetHomeDataQuery, IGetHomeDataQueryVariables>(GetHomeDataDocument, options);
      }
export function useGetHomeDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetHomeDataQuery, IGetHomeDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetHomeDataQuery, IGetHomeDataQueryVariables>(GetHomeDataDocument, options);
        }
export type GetHomeDataQueryHookResult = ReturnType<typeof useGetHomeDataQuery>;
export type GetHomeDataLazyQueryHookResult = ReturnType<typeof useGetHomeDataLazyQuery>;
export type GetHomeDataQueryResult = Apollo.QueryResult<IGetHomeDataQuery, IGetHomeDataQueryVariables>;
export const GetLessonDetailsDocument = gql`
    query getLessonDetails($lessonId: uuid!) {
  lessons_by_pk(id: $lessonId) {
    id
    name
    label
    content
  }
}
    `;

/**
 * __useGetLessonDetailsQuery__
 *
 * To run a query within a React component, call `useGetLessonDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLessonDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLessonDetailsQuery({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useGetLessonDetailsQuery(baseOptions: Apollo.QueryHookOptions<IGetLessonDetailsQuery, IGetLessonDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetLessonDetailsQuery, IGetLessonDetailsQueryVariables>(GetLessonDetailsDocument, options);
      }
export function useGetLessonDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetLessonDetailsQuery, IGetLessonDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetLessonDetailsQuery, IGetLessonDetailsQueryVariables>(GetLessonDetailsDocument, options);
        }
export type GetLessonDetailsQueryHookResult = ReturnType<typeof useGetLessonDetailsQuery>;
export type GetLessonDetailsLazyQueryHookResult = ReturnType<typeof useGetLessonDetailsLazyQuery>;
export type GetLessonDetailsQueryResult = Apollo.QueryResult<IGetLessonDetailsQuery, IGetLessonDetailsQueryVariables>;
export const GetCourseDocument = gql`
    query getCourse($subscriptionId: uuid!) {
  subscriptions_by_pk(id: $subscriptionId) {
    course {
      id
      name
      lessons(order_by: {sequence: asc}) {
        id
        name
        label
        description
        content
        sequence
      }
    }
  }
}
    `;

/**
 * __useGetCourseQuery__
 *
 * To run a query within a React component, call `useGetCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseQuery({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useGetCourseQuery(baseOptions: Apollo.QueryHookOptions<IGetCourseQuery, IGetCourseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetCourseQuery, IGetCourseQueryVariables>(GetCourseDocument, options);
      }
export function useGetCourseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetCourseQuery, IGetCourseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetCourseQuery, IGetCourseQueryVariables>(GetCourseDocument, options);
        }
export type GetCourseQueryHookResult = ReturnType<typeof useGetCourseQuery>;
export type GetCourseLazyQueryHookResult = ReturnType<typeof useGetCourseLazyQuery>;
export type GetCourseQueryResult = Apollo.QueryResult<IGetCourseQuery, IGetCourseQueryVariables>;
export const GetUserChildDocument = gql`
    query getUserChild($subscriptionId: uuid!) {
  users_children(where: {subscription_id: {_eq: $subscriptionId}}, limit: 1) {
    id
    name
    content_key
    subscription {
      course_group {
        group_type_id
        questions_enabled
      }
    }
  }
}
    `;

/**
 * __useGetUserChildQuery__
 *
 * To run a query within a React component, call `useGetUserChildQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserChildQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserChildQuery({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useGetUserChildQuery(baseOptions: Apollo.QueryHookOptions<IGetUserChildQuery, IGetUserChildQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetUserChildQuery, IGetUserChildQueryVariables>(GetUserChildDocument, options);
      }
export function useGetUserChildLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetUserChildQuery, IGetUserChildQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetUserChildQuery, IGetUserChildQueryVariables>(GetUserChildDocument, options);
        }
export type GetUserChildQueryHookResult = ReturnType<typeof useGetUserChildQuery>;
export type GetUserChildLazyQueryHookResult = ReturnType<typeof useGetUserChildLazyQuery>;
export type GetUserChildQueryResult = Apollo.QueryResult<IGetUserChildQuery, IGetUserChildQueryVariables>;
export const HoldSubscriptionDocument = gql`
    mutation holdSubscription($id: Int!, $hold: Boolean!) {
  update_wp_subscription_orders_by_pk(
    pk_columns: {id: $id}
    _set: {on_hold: $hold}
  ) {
    id
    on_hold
    change_count
    status
  }
}
    `;

/**
 * __useHoldSubscriptionMutation__
 *
 * To run a mutation, you first call `useHoldSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHoldSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [holdSubscriptionMutation, { data, loading, error }] = useHoldSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      hold: // value for 'hold'
 *   },
 * });
 */
export function useHoldSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<IHoldSubscriptionMutation, IHoldSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IHoldSubscriptionMutation, IHoldSubscriptionMutationVariables>(HoldSubscriptionDocument, options);
      }
export type HoldSubscriptionMutationHookResult = ReturnType<typeof useHoldSubscriptionMutation>;
export type HoldSubscriptionMutationResult = Apollo.MutationResult<IHoldSubscriptionMutation>;
export type HoldSubscriptionMutationOptions = Apollo.BaseMutationOptions<IHoldSubscriptionMutation, IHoldSubscriptionMutationVariables>;
export const UpdateContactInformationDocument = gql`
    mutation updateContactInformation($id: uuid!, $rawPayload: jsonb!) {
  update_wp_orders_by_pk(pk_columns: {id: $id}, _set: {raw_payload: $rawPayload}) {
    id
    raw_payload
  }
}
    `;

/**
 * __useUpdateContactInformationMutation__
 *
 * To run a mutation, you first call `useUpdateContactInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactInformationMutation, { data, loading, error }] = useUpdateContactInformationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rawPayload: // value for 'rawPayload'
 *   },
 * });
 */
export function useUpdateContactInformationMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateContactInformationMutation, IUpdateContactInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateContactInformationMutation, IUpdateContactInformationMutationVariables>(UpdateContactInformationDocument, options);
      }
export type UpdateContactInformationMutationHookResult = ReturnType<typeof useUpdateContactInformationMutation>;
export type UpdateContactInformationMutationResult = Apollo.MutationResult<IUpdateContactInformationMutation>;
export type UpdateContactInformationMutationOptions = Apollo.BaseMutationOptions<IUpdateContactInformationMutation, IUpdateContactInformationMutationVariables>;
export const GetAvailabilityDocument = gql`
    query getAvailability($subscriptionId: uuid!) {
  subscriptions_by_pk(id: $subscriptionId) {
    id
    starts_on
    ends_on
    user_id
    user {
      relation_to_kid
    }
    wp_order {
      id
      user_id
      first_name
      last_name
      raw_payload
    }
    wp_subscription_orders(order_by: {created_at: asc}, limit: 1) {
      id
      on_hold
      change_count
      status
    }
  }
}
    `;

/**
 * __useGetAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailabilityQuery({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useGetAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<IGetAvailabilityQuery, IGetAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetAvailabilityQuery, IGetAvailabilityQueryVariables>(GetAvailabilityDocument, options);
      }
export function useGetAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetAvailabilityQuery, IGetAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetAvailabilityQuery, IGetAvailabilityQueryVariables>(GetAvailabilityDocument, options);
        }
export type GetAvailabilityQueryHookResult = ReturnType<typeof useGetAvailabilityQuery>;
export type GetAvailabilityLazyQueryHookResult = ReturnType<typeof useGetAvailabilityLazyQuery>;
export type GetAvailabilityQueryResult = Apollo.QueryResult<IGetAvailabilityQuery, IGetAvailabilityQueryVariables>;
export const GetSupportDataDocument = gql`
    query getSupportData {
  users(limit: 1) {
    id
  }
  support_topics(where: {course: {_eq: KORAN_LEREN_LEZEN}}) {
    id
    topic
    support_topic_entries {
      id
      question
      answer
    }
  }
}
    `;

/**
 * __useGetSupportDataQuery__
 *
 * To run a query within a React component, call `useGetSupportDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSupportDataQuery(baseOptions?: Apollo.QueryHookOptions<IGetSupportDataQuery, IGetSupportDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetSupportDataQuery, IGetSupportDataQueryVariables>(GetSupportDataDocument, options);
      }
export function useGetSupportDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetSupportDataQuery, IGetSupportDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetSupportDataQuery, IGetSupportDataQueryVariables>(GetSupportDataDocument, options);
        }
export type GetSupportDataQueryHookResult = ReturnType<typeof useGetSupportDataQuery>;
export type GetSupportDataLazyQueryHookResult = ReturnType<typeof useGetSupportDataLazyQuery>;
export type GetSupportDataQueryResult = Apollo.QueryResult<IGetSupportDataQuery, IGetSupportDataQueryVariables>;
export const GetNewsDocument = gql`
    query getNews {
  news_articles_view {
    id
    title
    content
    news_articles_attachments {
      key
      filename
    }
  }
}
    `;

/**
 * __useGetNewsQuery__
 *
 * To run a query within a React component, call `useGetNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNewsQuery(baseOptions?: Apollo.QueryHookOptions<IGetNewsQuery, IGetNewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetNewsQuery, IGetNewsQueryVariables>(GetNewsDocument, options);
      }
export function useGetNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetNewsQuery, IGetNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetNewsQuery, IGetNewsQueryVariables>(GetNewsDocument, options);
        }
export type GetNewsQueryHookResult = ReturnType<typeof useGetNewsQuery>;
export type GetNewsLazyQueryHookResult = ReturnType<typeof useGetNewsLazyQuery>;
export type GetNewsQueryResult = Apollo.QueryResult<IGetNewsQuery, IGetNewsQueryVariables>;
export const GetSubscriptionDocument = gql`
    query getSubscription($id: uuid!) {
  subscriptions_by_pk(id: $id) {
    id
    course_group {
      id
      questions_enabled
    }
  }
}
    `;

/**
 * __useGetSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<IGetSubscriptionQuery, IGetSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetSubscriptionQuery, IGetSubscriptionQueryVariables>(GetSubscriptionDocument, options);
      }
export function useGetSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetSubscriptionQuery, IGetSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetSubscriptionQuery, IGetSubscriptionQueryVariables>(GetSubscriptionDocument, options);
        }
export type GetSubscriptionQueryHookResult = ReturnType<typeof useGetSubscriptionQuery>;
export type GetSubscriptionLazyQueryHookResult = ReturnType<typeof useGetSubscriptionLazyQuery>;
export type GetSubscriptionQueryResult = Apollo.QueryResult<IGetSubscriptionQuery, IGetSubscriptionQueryVariables>;
export const GetUserDocument = gql`
    query getUser {
  users {
    id
    first_name
    last_name
    email
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<IGetUserQuery, IGetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetUserQuery, IGetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetUserQuery, IGetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetUserQuery, IGetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<IGetUserQuery, IGetUserQueryVariables>;