export enum ROUTE_NAMES {
  DASHBOARD,
  MY_LESSONS,
  LESSON,
  SUPPORT,
  PROFILE,
}

export type TRoutes = {
  [route in ROUTE_NAMES]: string
}
