import { useAuth0 } from '@auth0/auth0-react'
import { isPast } from 'date-fns'
import React, { lazy, Suspense, useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { SuspenseLoading } from '../../Components'
import { Header } from '../../Components/PageContainer/Header'
import SessionGuard from '../../Components/SessionGuard'
import { useGetSubscriptionStatusQuery } from '../../Lib/graphql'
import PageNotFound from '../../Pages/PageNotFound'
import { CurrentUserContext, CurrentUserProvider } from '../../Providers/CurrentUserProvider'
import { ROUTE_NAMES } from './interfaces'
import { routes } from './routes'
import Styles from './styles.module.scss'

const MyLessons = lazy(() => import(/* webpackChunkName: "MyLessons" */ '../../Pages/MyLessons'))
const Renew = lazy(() => import(/* webpackChunkName: "Renew" */ '../../Pages/Renew'))
const Support = lazy(() => import(/* webpackChunkName: "Support" */ '../../Pages/Support'))
const Lesson = lazy(() => import(/* webpackChunkName: "Lesson" */ '../../Pages/MyLessons/Pages/Lesson'))
const Profile = lazy(() => import(/* webpackChunkName: "Profile" */ '../../Pages/Profile'))

const Router: React.FC = (): JSX.Element => {
  const { subscriptionId } = useContext(CurrentUserContext)
  const { data } = useGetSubscriptionStatusQuery({ variables: { subscriptionId }, skip: !subscriptionId })

  const subscriptionEndsOn = data?.subscriptions_by_pk?.ends_on
  const isExpired = !!subscriptionEndsOn && isPast(new Date(subscriptionEndsOn))

  if (isExpired) {
    return (
      <TransitionGroup className={Styles.appRoutesTransitionGroup}>
        <CSSTransition key={location.pathname} timeout={{ enter: 250, exit: 0 }} classNames="fade">
          <Routes>
            <Route path="*" element={<Renew />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    )
  }

  return (
    <TransitionGroup className={Styles.appRoutesTransitionGroup}>
      <CSSTransition key={location.pathname} timeout={{ enter: 250, exit: 0 }} classNames="fade">
        <Routes>
          <Route path={routes[ROUTE_NAMES.DASHBOARD]} element={<MyLessons />} />
          <Route path={routes[ROUTE_NAMES.SUPPORT]} element={<Support />} />
          <Route path={routes[ROUTE_NAMES.LESSON]} element={<Lesson />} />
          <Route path={routes[ROUTE_NAMES.PROFILE]} element={<Profile />} />

          {/* Keep as the last path! */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  )
}

export const AuthorizedLayout: React.FC = (): JSX.Element | null => {
  const { isAuthenticated } = useAuth0()

  if (!isAuthenticated) return null

  return (
    <div className={Styles.authorizedLayout}>
      <CurrentUserProvider>
        <SessionGuard />
        <Suspense fallback={<SuspenseLoading />}>
          <Header />
          <Router />
        </Suspense>
      </CurrentUserProvider>
    </div>
  )
}
